import {
  MenuElementsState,
  MenuElementsActionTypes,  
  SET_MENU_ELEMENTS,
} from '../types'

const initialState: MenuElementsState = {
  menuElements: []
}

export function menuElementsReducer(
  state = initialState,
  action: MenuElementsActionTypes
): any {
  switch (action.type) {
    case SET_MENU_ELEMENTS:
      return action.payload
    default:
      return state
  }
}