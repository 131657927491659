import { Dispatch } from "redux";
import {
  IGetPromptResponseRequestAction,
  IGetPromptResponseSuccessAction,
  IGetPromptResponseFailureAction,
  IClearPromptResponseAction,
  IAddPromptFeedbackRequestAction,
  IAddPromptFeedbackSuccessAction,
  IAddPromptFeedbackErrorAction,
  GET_PROMPT_RESPONSE_REQUEST,
  GET_PROMPT_RESPONSE_SUCCESS,
  GET_PROMPT_RESPONSE_FAILURE,
  CLEAR_PROMPT_RESPONSE,
  ADD_PROMPT_FEEDBACK_REQUEST,
  ADD_PROMPT_FEEDBACK_SUCCESS,
  ADD_PROMPT_FEEDBACK_ERROR,
  IAddPromptKXResultsAction,
  ADD_PROMPT_KX_RESULTS,
  GET_KX_RESULTS_ERROR,
  GET_KX_RESULTS_REQUEST,
  GET_KX_RESULTS_SUCCESS,
  IGetKxResultsErrorAction,
  IGetKxResultsRequestAction,
  IGetKxResultsSuccessAction,
  ISetPromptResponseAction,
  ISetModifyResponseClickedAction,
  SET_PROMPT_RESPONSE,
  SET_MODIFY_RESPONSE_CLICKED,
  ISetDPNImageLoading,
  SET_DPN_IMAGE_LOADING,
  RESET_PROMPT_RESPONSE_ERROR,
  IResetPromptResponseError,
  RESET_ADD_PROMPT_FEEDBACK_ERROR,
  IResetAddPromptFeedbackErrorAction,
  RESET_GET_KX_RESULTS_ERROR,
  IResetGetKxResultsErrorAction,
  IResetInfoMessages,
  RESET_INFO_MESSAGE,
  ISetContainSpecialistAction,
  SET_CONTAIN_SPECIALIST,
} from "../types";
import {
  IPromptResponseObj,
  IPromptRequestObj,
  IPromptFeedbackRequestObj,
  IPromptFeedbackResponseObj,
  IResultsResponse,
  IPromptKxResultRequestObj,
  IPromptKxResultResponseObj,
} from "../../models/KxPromptModels";
import { SharedService } from "../../services/SharedService";
import { AnalyticsService } from "../../services/AnalyticsService";
import { IPromptErrortracking } from "../../models/IAnalytics";

export type DataActionTypes =
  | IGetPromptResponseRequestAction
  | IGetPromptResponseSuccessAction
  | IGetPromptResponseFailureAction
  | IResetInfoMessages
  | ISetPromptResponseAction
  | ISetModifyResponseClickedAction
  | IClearPromptResponseAction
  | IAddPromptFeedbackRequestAction
  | IAddPromptFeedbackSuccessAction
  | IAddPromptFeedbackErrorAction
  | IAddPromptKXResultsAction
  | IGetKxResultsErrorAction
  | IGetKxResultsRequestAction
  | IGetKxResultsSuccessAction
  | IResetGetKxResultsErrorAction
  | ISetDPNImageLoading
  | IResetPromptResponseError
  | IResetAddPromptFeedbackErrorAction
  | ISetContainSpecialistAction;

const fetchPromptResponseRequest = (): IGetPromptResponseRequestAction => ({
  type: GET_PROMPT_RESPONSE_REQUEST,
});

const fetchPromptResponseSuccess = (
  data: IPromptResponseObj
): IGetPromptResponseSuccessAction => ({
  type: GET_PROMPT_RESPONSE_SUCCESS,
  payload: data,
});

const fetchPromptResponseFailure = (
  error: string
): IGetPromptResponseFailureAction => ({
  type: GET_PROMPT_RESPONSE_FAILURE,
  payload: error,
});

export const resetPromptResponseError = (): IResetPromptResponseError => ({
  type: RESET_PROMPT_RESPONSE_ERROR,
});
export const resetInfoMessage = (): IResetInfoMessages => ({
  type: RESET_INFO_MESSAGE,
});

export const setPromptResponse = (
  data: IPromptResponseObj | undefined
): ISetPromptResponseAction => ({
  type: SET_PROMPT_RESPONSE,
  payload: data,
});

export const setModifiedResponseSelected = (
  data: boolean
): ISetModifyResponseClickedAction => ({
  type: SET_MODIFY_RESPONSE_CLICKED,
  payload: data,
});

export const clearPromptResponse = (): IClearPromptResponseAction => ({
  type: CLEAR_PROMPT_RESPONSE,
});
export const addPromptKXResults = (
  data: IResultsResponse[]
): IAddPromptKXResultsAction => ({
  type: ADD_PROMPT_KX_RESULTS,
  payload: data,
});

const addPromptFeedbackRequest = (
  data: IPromptFeedbackRequestObj
): IAddPromptFeedbackRequestAction => ({
  type: ADD_PROMPT_FEEDBACK_REQUEST,
  payload: data,
});

const addPromptFeedbackSuccess = (
  data: IPromptFeedbackResponseObj
): IAddPromptFeedbackSuccessAction => ({
  type: ADD_PROMPT_FEEDBACK_SUCCESS,
  payload: data,
});

const addPromptFeedbackFailure = (
  data: string
): IAddPromptFeedbackErrorAction => ({
  type: ADD_PROMPT_FEEDBACK_ERROR,
  payload: data,
});
export const resetAddPromptFeedbackFailure =
  (): IResetAddPromptFeedbackErrorAction => ({
    type: RESET_ADD_PROMPT_FEEDBACK_ERROR,
  });

const getKxResultsRequest = (
  data: IPromptKxResultRequestObj
): IGetKxResultsRequestAction => ({
  type: GET_KX_RESULTS_REQUEST,
  payload: data,
});

const getKxResultsSuccess = (
  data: IPromptKxResultResponseObj
): IGetKxResultsSuccessAction => ({
  type: GET_KX_RESULTS_SUCCESS,
  payload: data,
});

const getKxResultsFailure = (data: string): IGetKxResultsErrorAction => ({
  type: GET_KX_RESULTS_ERROR,
  payload: data,
});

export const resetGetKxResultsError = (): IResetGetKxResultsErrorAction => ({
  type: RESET_GET_KX_RESULTS_ERROR,
});

export const setDPNImageLoading = (data: boolean): ISetDPNImageLoading => ({
  type: SET_DPN_IMAGE_LOADING,
  payload: data,
});

export const setContainSpecialist = (
  data: boolean
): ISetContainSpecialistAction => ({
  type: SET_CONTAIN_SPECIALIST,
  payload: data,
});

export const getPromptResponse = (payloadData: IPromptRequestObj) => {
  return async (dispatch: Dispatch<DataActionTypes>) => {
    dispatch(fetchPromptResponseRequest());

    try {
      const _sharedService = SharedService.GetInstance();
      const data = await _sharedService.getGenAIResponse(payloadData);
      if (data !== null && data !== undefined && !(data instanceof Error)) {
        dispatch(fetchPromptResponseSuccess(data));
      } else {
        if (data) {
          dispatch(fetchPromptResponseFailure(data.message));
          const errorTrackerObj: IPromptErrortracking = {
            action: "Get a prompt response",
            errorMessage: data.message,
          };
          AnalyticsService.trackPromptError(errorTrackerObj);
        }
      }
    } catch (error) {
      dispatch(fetchPromptResponseFailure(error as string));
      const errorTrackerObj: IPromptErrortracking = {
        action: "Get a prompt response",
        errorMessage: error as string,
      };
      AnalyticsService.trackPromptError(errorTrackerObj);
    }
  };
};

export const addPromptFeedback = (payloadData: IPromptFeedbackRequestObj) => {
  return async (dispatch: Dispatch<DataActionTypes>) => {
    dispatch(addPromptFeedbackRequest(payloadData));

    try {
      const _sharedService = SharedService.GetInstance();
      const data = await _sharedService.addGenAIPromptFeedback(payloadData);
      if (data !== null && data !== undefined && !(data instanceof Error)) {
        dispatch(addPromptFeedbackSuccess(data));
      } else {
        if (data) {
          dispatch(addPromptFeedbackFailure(data.message));
          const errorTrackerObj: IPromptErrortracking = {
            action: "Giving Feedback to a prompt",
            errorMessage: data.message,
          };
          AnalyticsService.trackPromptError(errorTrackerObj);
        }
      }
    } catch (error) {
      dispatch(addPromptFeedbackFailure(error as string));
      const errorTrackerObj: IPromptErrortracking = {
        action: "Giving Feedback to a prompt",
        errorMessage: error as string,
      };
      AnalyticsService.trackPromptError(errorTrackerObj);
    }
  };
};

export const getKxResults = (payloadData: IPromptKxResultRequestObj) => {
  return async (dispatch: Dispatch<DataActionTypes>) => {
    dispatch(getKxResultsRequest(payloadData));

    try {
      const _sharedService = SharedService.GetInstance();
      const data = await _sharedService.getGenAIKxResults(payloadData);
      if (data !== null && data !== undefined && !(data instanceof Error)) {
        dispatch(getKxResultsSuccess(data));
      } else {
        if (data) {
          dispatch(getKxResultsFailure(data.message));
          const errorTrackerObj: IPromptErrortracking = {
            action: "Getting KX Results for a particular conversation",
            errorMessage: data?.message as string,
          };
          AnalyticsService.trackPromptError(errorTrackerObj);
        }
      }
    } catch (error) {
      dispatch(getKxResultsFailure(error as string));
      const errorTrackerObj: IPromptErrortracking = {
        action: "Getting KX Results for a particular conversation",
        errorMessage: error as string,
      };
      AnalyticsService.trackPromptError(errorTrackerObj);
    }
  };
};
