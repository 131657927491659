import React from 'react';
import { Fabric, mergeStyles, ShimmerElementType, Shimmer, ShimmerElementsGroup } from 'office-ui-fabric-react';

export interface ShimmerProps {
  horizontal?: boolean;
  featured?: boolean;
  modernUi?: boolean;
  panel?: boolean;
  panelStart?: boolean;
  category?: boolean;
}
const wrapperClass = mergeStyles({
  padding: 6,
  selectors: {
    '& > .ms-Shimmer-container': {
      margin: '10px 0',
    },

  },
});
const wrapperClassHorizontal = mergeStyles({
  padding: 2,
  width: "100%",
  selectors: {
    '& > .ms-Shimmer-container': {
      margin: '5px 0',
    },
  },
});
const shimmerPic = [
  { type: ShimmerElementType.line, height: 144 },
];
const getCustomElementsCards = (): JSX.Element => {
  return (
    <div >
      <ShimmerElementsGroup
        shimmerElements={[
          { type: ShimmerElementType.line, height: 111, width: 196 },
        ]}
      />
      <div style={{ width: '196px', height: "99px" }}>

        <ShimmerElementsGroup
          width={'101%'}
          height={'99px'}
          flexWrap
          shimmerElements={[
            { type: ShimmerElementType.line, width: '70%', height: 10, },
            { type: ShimmerElementType.gap, width: '30%', height: 20 },
            { type: ShimmerElementType.line, width: '80%', height: 10, },
            { type: ShimmerElementType.gap, width: '20%', height: 20 },
            { type: ShimmerElementType.line, width: '60%', height: 10, },
            { type: ShimmerElementType.gap, width: '40%', height: 20 },

            { type: ShimmerElementType.line, width: '30%', height: 10, },
            { type: ShimmerElementType.gap, width: '70%', height: 20 },

            { type: ShimmerElementType.line, width: '90%', height: 10, },
            { type: ShimmerElementType.gap, width: '10%', height: 20 },

          ]}
        />
      </div>
    </div>
  );

};
const getCustomElementsPanel = (): JSX.Element => {
  return (
    <div >
      <ShimmerElementsGroup
        flexWrap
        shimmerElements={[
          { type: ShimmerElementType.line, width: 196, height: 10 },
        ]}
      />
      <div style={{ width: '200px', height: "99px" }}>

        <ShimmerElementsGroup
          width={'100%'}
          height={'99px'}
          flexWrap
          shimmerElements={[
            { type: ShimmerElementType.gap, width: '100%', height: 20, },
            { type: ShimmerElementType.line, width: '100%', height: 20, },
            { type: ShimmerElementType.gap, width: '100%', height: 20 },
            { type: ShimmerElementType.line, width: '10%', height: 10, },
            { type: ShimmerElementType.gap, width: '10%', height: 20 },
            { type: ShimmerElementType.line, width: '60%', height: 10, },
            { type: ShimmerElementType.gap, width: '20%', height: 20 },

            { type: ShimmerElementType.line, width: '10%', height: 10, },
            { type: ShimmerElementType.gap, width: '10%', height: 20 },
            { type: ShimmerElementType.line, width: '60%', height: 10, },
            { type: ShimmerElementType.gap, width: '20%', height: 20 },
            { type: ShimmerElementType.line, width: '10%', height: 10, },
            { type: ShimmerElementType.gap, width: '10%', height: 20 },
            { type: ShimmerElementType.line, width: '60%', height: 10, },
            { type: ShimmerElementType.gap, width: '20%', height: 20 },
            { type: ShimmerElementType.line, width: '10%', height: 10, },
            { type: ShimmerElementType.gap, width: '10%', height: 20 },
            { type: ShimmerElementType.line, width: '60%', height: 10, },
            { type: ShimmerElementType.gap, width: '20%', height: 20 },
          ]}
        />
      </div>
    </div>
  );

};
const getCustomElementsPanelStart = (): JSX.Element => {
  return (
    <div>
      <div style={{ width: '200px', height: "59px" }}>
        <ShimmerElementsGroup
          width={'100%'}
          height={'59px'}
          flexWrap
          shimmerElements={[
            { type: ShimmerElementType.line, width: '40%', height: 20, },
            { type: ShimmerElementType.gap, width: '20%', height: 20 },
            { type: ShimmerElementType.line, width: '40%', height: 20, },
            { type: ShimmerElementType.gap, width: '100%', height: 10 },
            { type: ShimmerElementType.line, width: '80%', height: 15, },
            { type: ShimmerElementType.gap, width: '20%', height: 15 },
          ]}
        />
      </div>
    </div>
  );

};
const getCustomElementsCategoryTitle = (): JSX.Element => {
  return (
    <div>
      <div style={{ width: '866px', height: "20px" }}>
        <ShimmerElementsGroup
          width={'100%'}
          height={'20px'}
          flexWrap
          shimmerElements={[
            { type: ShimmerElementType.line, width: '20%', height: 20, },
            { type: ShimmerElementType.gap, width: '60%', height: 20 },
            { type: ShimmerElementType.line, width: '20%', height: 20, },
          ]}
        />
      </div>
    </div>
  );

};
const getCustomElementsListView = (): JSX.Element => {
  return (
    <div style={wrapperStyle}>
      <ShimmerElementsGroup
        height={160}
        shimmerElements={[
          { type: ShimmerElementType.line, height: 83, width: 137, verticalAlign: 'top' },
          { type: ShimmerElementType.gap, width: 10, height: 126 },
        ]}
      />
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: "126px" }}>

        <ShimmerElementsGroup
          flexWrap
          width={'100%'}
          height={126}

          shimmerElements={[
            { type: ShimmerElementType.line, width: '30%', height: 10, },
            { type: ShimmerElementType.gap, width: '70%', height: 20 },
            { type: ShimmerElementType.line, width: '30%', height: 10 },
            { type: ShimmerElementType.gap, width: '70%', height: 20 },

            { type: ShimmerElementType.line, width: '70%', height: 10 },
            { type: ShimmerElementType.gap, width: '30%', height: 20 },
            { type: ShimmerElementType.line, width: '80%', height: 10 },
            { type: ShimmerElementType.gap, width: '20%', height: 30 },
            { type: ShimmerElementType.line, width: '70%', height: 10 },
            { type: ShimmerElementType.gap, width: '30%', height: 30 },


          ]}
        />
      </div>
    </div>
  );
};

const getCustomElementsFeatured = (): JSX.Element => {
  return (
    <div style={wrapperStyle}>
      <ShimmerElementsGroup
        shimmerElements={[
          { type: ShimmerElementType.line, height: 200, width: 200 },
          { type: ShimmerElementType.gap, width: 10, height: 200 },
        ]}
      />
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '190px',height:"100%" }}>
        
        <ShimmerElementsGroup
          flexWrap
          width={'100%'}
          height={'160px'}

          shimmerElements={[
            { type: ShimmerElementType.line, width: '30%', height: 10, },
            { type: ShimmerElementType.gap, width: '70%', height: 20 },
            { type: ShimmerElementType.line, width: '30%', height: 10},
            { type: ShimmerElementType.gap, width: '70%', height: 40 },
            { type: ShimmerElementType.line, width: '80%', height: 10 },
            { type: ShimmerElementType.gap, width: '20%', height: 40 },
            { type: ShimmerElementType.line, width: '70%', height: 10 },
            { type: ShimmerElementType.gap, width: '30%', height: 40 },
            { type: ShimmerElementType.line, width: '70%', height: 10, verticalAlign: 'bottom' },
            { type: ShimmerElementType.gap, width: '30%', height: 20 },

          ]}
        />
      </div>
    </div>
  );
};

const getModernElementsFeatured = (): JSX.Element => {
  return (
    <div style={wrapperStyle}>
      <ShimmerElementsGroup
        shimmerElements={[
          { type: ShimmerElementType.line, height: 180, width: 170 },
          { type: ShimmerElementType.gap, width: 12, height: 180 },
        ]}
      />
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '186px', height: "180px" }}>

        <ShimmerElementsGroup
          flexWrap
          width={'100%'}
          height={'150px'}

          shimmerElements={[
            { type: ShimmerElementType.line, width: '30%', height: 10, },
            { type: ShimmerElementType.gap, width: '70%', height: 20 },
            { type: ShimmerElementType.line, width: '30%', height: 10 },
            { type: ShimmerElementType.gap, width: '70%', height: 40 },
            { type: ShimmerElementType.line, width: '80%', height: 10 },
            { type: ShimmerElementType.gap, width: '20%', height: 40 },
            { type: ShimmerElementType.line, width: '70%', height: 10 },
            { type: ShimmerElementType.gap, width: '30%', height: 40 },
            { type: ShimmerElementType.line, width: '70%', height: 10, verticalAlign: 'bottom' },
            { type: ShimmerElementType.gap, width: '30%', height: 20 },

          ]}
        />
      </div>
    </div>
  );
};

const getModernElementsCategoryTitle = (): JSX.Element => {
  return (
    <div>
      <div style={{ width: '866px', height: "48px", borderRadius: "16px 16px 0px 0px" }}>
        <ShimmerElementsGroup
          width={'100%'}
          height={'20px'}
          flexWrap
          shimmerElements={[
            { type: ShimmerElementType.line, width: '100%', height: 48 },
          ]}
        />
      </div>
    </div>
  );
};

const wrapperStyle = { display: 'flex' };
export const ShimmerWrapper: React.FunctionComponent<ShimmerProps> = props => {
  return (

    <Fabric className={props.horizontal ? wrapperClassHorizontal : wrapperClass + " ms-Grid-col"}>
      {
        props.featured 
        ? 
          <Shimmer customElementsGroup={props.modernUi ? getModernElementsFeatured() : getCustomElementsFeatured()} width="100%" />
        : 
          props.horizontal 
          ?
            <Shimmer customElementsGroup={getCustomElementsListView()} width="100%" />
          : 
            props.panel 
            ? 
              <Shimmer customElementsGroup={getCustomElementsPanel()} width="100%" />
            : 
              props.panelStart 
              ? 
                <Shimmer customElementsGroup={getCustomElementsPanelStart()} width="100%" />
              : 
                props.category 
                ? 
                  <Shimmer customElementsGroup={props.modernUi ? getModernElementsCategoryTitle() : getCustomElementsCategoryTitle()} width="100%" />
                :  
                  <Shimmer customElementsGroup={getCustomElementsCards()} width="100%" />
      }



    </Fabric>
  )
}