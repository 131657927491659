import React, { useState, Fragment, useRef } from "react";
import { Stack } from "office-ui-fabric-react";
import { CardData } from "../../../../../models/SharedModels";
import { OverflowSetBase } from "../../../../shared/overflowSetBase/overflowSetBase";
import QuickViewModal from "./QuickViewModal";

/**
 * Interface for ImageModal component props
 */
export interface ImageModalProps {
  slider?: boolean;
  card: any;
  categoryMove?: (e: any, cardData: CardData) => void;
  chosenItems?: any[];
  openModalEdit?: any;
  explainData?: any;
}

/**
 * ImageModal Component
 *
 * This component represents the image section for Quick View popup 
 * based upon diff. sources within results section in 'Prompt by KX'.
 */
export const ImageModal: React.FunctionComponent<ImageModalProps> = (props) => {
  const target = useRef(null);
  const [isMenuOpened, setIsOpened] = useState(false);

  /**
   * Handler to show modal
   */
  const onShow = () => {
    setIsOpened(true);
  };

  /**
   * Handler to hide modal
   */
  const onHide = () => {
    setIsOpened(false);
  };

  return (
    <div className="ms-Grid-col" key={props.card.key} ref={target}>
      <Stack>
        {props.card.overflowItemsData &&
          props.card.overflowItemsData.length > 0 &&
          props.card.overflowItemsData[0].overflowItems && (
            <>
              <div className={"card-fade"}> </div>
              <div className={`card-menu ${isMenuOpened ? "is-opened" : ""}`}>
                <QuickViewModal
                  cardData={props.card}
                  items={props.card.overflowItemsData[0].items}
                  overflowItems={props.card.overflowItemsData[0].overflowItems}
                  onShow={onShow}
                  onHide={onHide}
                  type={props.card.type}
                  openModalEdit={props.openModalEdit}
                  explainData={props.explainData}
                />
              </div>
            </>
          )}
      </Stack>
    </div>
  );
};
