import { SET_SHOW_RECENT } from '../types'

const initialStateShowRecent: any = {
  showRecent: false,
  isHomePage: false
}

export default function showRecentReducer(state = initialStateShowRecent, action: any): any {
  switch (action.type) {
    case SET_SHOW_RECENT:
      return {
        ...state,
        showRecent: action.payload.showRecent,
        isHomePage: action.payload.isHomePage
      }

    default:
      return state
  }
}