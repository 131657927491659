import React, { createContext, useState, useContext } from "react";


const TagsDataContext = createContext({
  tableId: 0,
  setTags: (tagsData: []) => {},
});

function useTagsDataContext() {
  const context = useContext(TagsDataContext);
  if (!context)
    throw new Error(
      "useAttachementNotificationContext must be used within AttachmentNotificationProvider!"
    );
  return context;
}

function TagsDataProvider(props: any) {
  const [tagsData, setTagsData] = useState<any>(null);
  const value:any = { tagsData, setTagsData };
  return <TagsDataContext.Provider value={value} {...props} />;
}

export { TagsDataProvider, useTagsDataContext };
