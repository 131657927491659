import {  ADD_DATA_TO_COLLECTION, CollectionActionsTypeActions } from "../types";
import { CardData } from "../../models/SharedModels";

// TypeScript infers that this function is returning SendMessageAction

export function  CollectionYouFollowActions(type: any,cardsData: CardData[]):  CollectionActionsTypeActions{
  return {
    type: type,
    payload: cardsData
  }
}


