import { USER_NEEDS } from "../types";
import { IUserNeedDropdownOption } from "../../models/IShared";

const setUserNeeds = (userNeed: IUserNeedDropdownOption) => {
  return {
    type: USER_NEEDS,
    payload: userNeed
  }
}

export default setUserNeeds