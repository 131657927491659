import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  clearFollowupPrompt,
} from "../../../../../../../store/actions/promptConversationAction";
import {
  IConversationMessage,
  IPromptRequestObj,
} from "../../../../../../../models/KxPromptModels";
import { MessageSender } from "../../../../../../../store/types";
import { RootState } from "../../../../../../../store/reducers";
import { getPromptResponse } from "../../../../../../../store/actions/promptResponseAction";
import "./GeneralResponse.scss";

/**
 * Interface for GeneralResponse component props
 */
interface IGeneralResponseProps {
  text: string;
  isClarificationQuestion: boolean;
  isLastMessage: boolean;
  handleResultsMetricsClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  formatContent: (text: string) => string;
}

/**
 * GeneralResponse Component
 *
 * This component is responsible for rendering response messages in a chat interface.
 * It handles user input, session management, and formatting of push buttons for user interaction.
 */
const GeneralResponse: React.FunctionComponent<IGeneralResponseProps> = ({
  text,
  isClarificationQuestion,
  isLastMessage,
  handleResultsMetricsClick,
  formatContent,
}) => {
  const dispatch = useDispatch();
  const { promptResponse, loadingResponse } = useSelector(
    (state: RootState) => state.promptResponseReducer
  );
  const { messages } = useSelector(
    (state: RootState) => state.promptConversationReducer
  );

  /**
   * getSessionId - Retrieves the session ID from either the last two messages or the prompt response.
   * @returns {string} - The session ID, or an empty string if not found.
   */
  const getSessionId = () => {
    if (messages.length >= 2) {
      return messages[messages.length - 1].sessionId;
    } else if (promptResponse?.sessionid) return promptResponse?.sessionid;
    else return "";
  };

  /**
   * Handler function for handling send button click event.
   * It dispatches actions to update the conversation and request a prompt response.
   * @param text - The text input provided by the user.
   */
  const sendButtonClickHandler = (text: string) => {
    if (text.trim().length === 0) {
      return;
    }
    dispatch(clearFollowupPrompt());
    const updateMessage: IConversationMessage = {
      text,
      sender: MessageSender.USER,
      usageGuidelines: "",
      sessionId: getSessionId(),
      conversationId: "",
    };
    const payloadData: IPromptRequestObj = {
      userprompt: text,
      sessionid: getSessionId(),
      overridesettings: {
        top: 5,
        search_mode: "hybrid",
      },
    };
    dispatch(addMessage(updateMessage));
    dispatch(getPromptResponse(payloadData));
  };

  /**
   * Formats push button content based on the provided text.
   *
   * @param {string} text - The input text containing the heading and button information.
   * @returns {JSX.Element} - React JSX element containing the formatted push button content.
   */
  const formatPushButtonContent = (text: string) => {
    const splitText = text.split("\n");
    const pushButtonList = splitText[1].split("|").map((item) => item.trim());

    return (
      <>
        <div className="promptResponse_heading">{splitText[0]}</div>
        <div className="promptResponse_buttonWrapper">
          {pushButtonList.map((buttonText) => (
            <>
              <button
                onClick={() => sendButtonClickHandler(buttonText)}
                className="promptResponse_pushButton"
                disabled={loadingResponse}
              >
                {buttonText}
              </button>
            </>
          ))}
        </div>
      </>
    );
  };

  /**
   * Render the GeneralResponse component.
   *
   * @returns {JSX.Element} - The rendered GeneralResponse component.
   */
  return isClarificationQuestion && isLastMessage ? (
    <div className="response-text">{formatPushButtonContent(text)}</div>
  ) : (
    <div
      className="response-text"
      onClick={handleResultsMetricsClick}
      dangerouslySetInnerHTML={{ __html: formatContent(text) }}
    />
  );
};

/**
 * Export the GeneralResponse component as default
 */
export default GeneralResponse;
