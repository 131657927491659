import React from 'react';
import { Checkbox, ICheckboxProps } from 'office-ui-fabric-react/lib/Checkbox';
import './checkboxBase.scss';

// Optional extra props to pass through to the input element
const inputProps: ICheckboxProps['inputProps'] = {
  // onFocus: () => console.log('Checkbox is focused'),
  // onBlur: () => console.log('Checkbox is blurred'),
};
export interface CheckboxBaseProps {
  label: string,
  isChecked?: any,
  onGroupChange?: (arg:any) => void,
  name?: string,
  className?: string,
  innerList?: any[],
  disabled?:boolean
}

export const CheckboxBase: React.FunctionComponent<CheckboxBaseProps> = props => {
  return (
        <Checkbox label={props.label} checked={props.isChecked} onChange={props.onGroupChange} name={props.name} inputProps={inputProps} className={props.className} disabled={props.disabled}/>
    );
};

