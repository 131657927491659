import React, { createContext, useContext, useState } from "react";

const CollapsibleBaseContext = createContext({
  isRefreshFlag: 0,
  setIsRefreshFlag: (flag: number) => {},
});

function useCollapsibleBaseContext() {
  const context = useContext(CollapsibleBaseContext);
  if (!context) {
    throw new Error(
      "useCollapsibleBaseContext must be used within CollapsibleBaseProvider!"
    );
  }
  return context;
}

function CollapsibleBaseProvider(props: any) {
  const [isRefreshFlag, setIsRefreshFlag] = useState<number>();
  const value = { isRefreshFlag, setIsRefreshFlag };
  return <CollapsibleBaseContext.Provider value={value} {...props} />;
}

export { CollapsibleBaseProvider, useCollapsibleBaseContext };
