import {
  About,
  History,
  HistoryActive,
  Home,
  HomeActive,
  Kx,
  Tips,
  Feedback,
} from ".";

export const getSideBarIcons = (isActive, iconName, hovered) => {
  const isHovered = iconName === hovered;
  switch (iconName) {
    case "home":
      return isActive ? HomeActive : Home;
    case "history":
      return isActive ? HistoryActive : History;
    case "tips":
      return isActive ? Tips : Tips;
    case "about":
      return isActive ? About : About;
    case "feedback":
      return isActive ? Feedback : Feedback;
    default:
      return isActive ? Kx : Kx;
  }
};
