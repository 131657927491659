import React, { lazy, Suspense } from 'react';

const LazyManageMyInterestsPage = lazy(() => import(/* webpackChunkName: "personalcollection" */'./manageMyInterestsPage'));

const ManageMyInterestsPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyManageMyInterestsPage {...props} />
  </Suspense>
);

export default ManageMyInterestsPage;