import { isEmpty } from "lodash";
import { useState, useEffect } from "react";
import { Constants } from "../../../helpers/Constants";
import { IUserPermission, OverflowActions } from "../../../models/IShared";
import { SharedService } from "../../../services/SharedService";

function useProtectedActionButtons(
  listTitle: string,
  overflowButtons: OverflowActions[]
) {
  const [buttons, setButtons] = useState<OverflowActions[]>([]);

  useEffect(() => {
    let _sdInstance = SharedService.GetInstance();

    let getButtons = async () => {
      if (!isEmpty(listTitle)) {
        let permissions = await _sdInstance.Actions.Get.GetUserPermissionsOnList(
          [listTitle]
        );

        if (!isEmpty(permissions)) {
          let items = getFilteredOverflowItems(overflowButtons, permissions[0]);
          setButtons(items);
        }

      } else {
        setButtons(overflowButtons);
      }
    };
    getButtons();
  },[]);
  return buttons;
}

const getFilteredOverflowItems = (
  items: OverflowActions[],
  permissions: IUserPermission
) => {
  let filteredItems = items.filter((item) => {
    if (
      (item.key &&
        item.key === Constants.ActionsButtonsKeys.EditItem &&
        !permissions.CanEdit) ||
      (item.key === Constants.ActionsButtonsKeys.DeleteItem &&
        !permissions.CanDelete)
    ) {
      return false;
    }
    return true;
  });
  return filteredItems;
};

export default useProtectedActionButtons;
