export class Constants {
  public static readonly General = {
    GuideFormat: "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
    ShortGuidAlphabet: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    StatePending: "Pending",
    StateResolved: "Resolved",
    VersionsDropdownLimit: 10,
    PublishedWebApiUrl: "http://kxnextgen/",
    //DevWebApiUrl: "https://localhost:44345/",
    DevWebApiUrl: "https://kxnextgendev.deloitteresources.com/",
    INTWebApiUrl: "https://apiint.kx.deloitte/",
    //QAWebApiUrl: "https://localhost:44365/",
    QAWebApiUrl: "https://apiqa.kx.deloitte/",
    QA2WebApiUrl: "https://kxnextgenqa2api.kx.deloitte/",
    StageWebApiUrl: "https://apistage.kx.deloitte/",
    ProdWebApiUrl: "https://kxapi.kx.deloitte/",
    DevSpDomainUrl: "https://omniadevkor.sharepoint.com",
    DevSpSiteUrl: "/sites/dev-kx",
    StageSpSiteUrl: "/sites/stage-kx",
    INTSpSiteUrl: "/sites/devint-kx",
    QASpSiteUrl: "/sites/qa-kx",
    ProdSpSiteUrl: "/sites/kx",
    INTSpDomainUrl: "https://resources.deloitte.com",
    QASpDomainUrl: "https://resources.deloitte.com",
    StageSpDomainUrl: "https://resources.deloitte.com",
    ProdSpDomainUrl: "https://resources.deloitte.com",
    KXInternalResource: "https://kx.deloitteresources.com",
    ElasticUrl:
      "https://elasticupgradeint.deloitteresources.com/kxngnested/_search",

    DevUpdateActivityStatusUrl:
      "https://apidev.kxdash.deloitte/api/Assets/UpdateStatus",
    INTUpdateActivityStatusUrl:
      "https://apidev.kxdash.deloitte/api/Assets/UpdateStatus",
    QAUpdateActivityStatusUrl:
      "https://apiqa.kxdash.deloitte/api/Assets/UpdateStatus",
    StageUpdateActivityStatusUrl:
      "https://apistage.kxdash.deloitte/api/Assets/UpdateStatus",
    ProdUpdateActivityStatusUrl:
      "https://kxdashapi.kxdash.deloitte/api/Assets/UpdateStatus",

    DevAddDashActivityMapping:
      "https://apidev.kxdash.deloitte/api/Assets/AddMapping",
    INTAddDashActivityMapping:
      "https://apidev.kxdash.deloitte/api/Assets/AddMapping",
    QAAddDashActivityMapping:
      "https://apiqa.kxdash.deloitte/api/Assets/AddMapping",
    StageAddDashActivityMapping:
      "https://apistage.kxdash.deloitte/api/Assets/AddMapping",
    ProdAddDashActivityMapping:
      "https://kxdashapi.kxdash.deloitte/api/Assets/AddMapping",

    DevRemoveDashActivityMapping:
      "https://apidev.kxdash.deloitte/api/Assets/RemoveMapping",
    INTRemoveDashActivityMapping:
      "https://apidev.kxdash.deloitte/api/Assets/RemoveMapping",
    QARemoveDashActivityMapping:
      "https://apiqa.kxdash.deloitte/api/Assets/RemoveMapping",
    StageRemoveDashActivityMapping:
      "https://apistage.kxdash.deloitte/api/Assets/RemoveMapping",
    ProdRemoveDashActivityMapping:
      "https://kxdashapi.kxdash.deloitte/api/Assets/RemoveMapping",

    DevDashActivityByIdApiUrl:
      "https://apidev.kxdash.deloitte/Activities/getActivity/",
    INTDashActivityByIdApiUrl:
      "https://apidev.kxdash.deloitte/Activities/getActivity/",
    QADashActivityByIdApiUrl:
      "https://apiqa.kxdash.deloitte/Activities/getActivity/",
    StageDashActivityByIdApiUrl:
      "https://apistage.kxdash.deloitte/Activities/getActivity/",
    ProdDashActivityByIdApiUrl:
      "https://kxdashapi.kxdash.deloitte/Activities/getActivity/",

    DevDashApiUrl:
      "https://apidev.kxdash.deloitte/Activities/getActivityStatsByEmail?email=",
    INTDashApiUrl:
      "https://apidev.kxdash.deloitte/Activities/getActivityStatsByEmail?email=",
    QADashApiUrl:
      "https://apiqa.kxdash.deloitte/Activities/getActivityStatsByEmail?email=",
    StageDashApiUrl:
      "https://apistage.kxdash.deloitte/Activities/getActivityStatsByEmail?email=",
    ProdDashApiUrl:
      "https://kxdashapi.kxdash.deloitte/Activities/getActivityStatsByEmail?email=",

    DevGetDashActivityApiUrl:
      "https://apidev.kxdash.deloitte/Activities/getAssetsActivitiesByEmail?email=",
    INTGetDashActivityApiUrl:
      "https://apidev.kxdash.deloitte/Activities/getAssetsActivitiesByEmail?email=",
    QAGetDashActivityApiUrl:
      "https://apiqa.kxdash.deloitte/Activities/getAssetsActivitiesByEmail?email=",
    StageGetDashActivityApiUrl:
      "https://apistage.kxdash.deloitte/Activities/getAssetsActivitiesByEmail?email=",
    ProdGetDashActivityApiUrl:
      "https://kxdashapi.kxdash.deloitte/Activities/getAssetsActivitiesByEmail?email=",

    DevPeopleApiUrl:
      "https://kxpeopleint.deloitteresources.com/api/People/SearchPeopleByKeyword?keyword=",
    INTPeopleApiUrl:
      "https://kxpeopleint.deloitteresources.com/api/People/SearchPeopleByKeyword?keyword=",
    QAPeopleApiUrl:
      "https://kxpeopleint.deloitteresources.com/api/People/SearchPeopleByKeyword?keyword=",
    StagePeopleApiUrl:
      "https://kxpeoplestage.deloitteresources.com/api/People/SearchPeopleByKeyword?keyword=",
    ProdPeopleApiUrl:
      "https://kxpeople.deloitteresources.com/api/People/SearchPeopleByKeyword?keyword=",

    DevClientApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/clients?contains=",
    INTClientApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/clients?contains=",
    QAClientApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/clients?contains=",
    StageClientApiUrl:
      "https://stagequalsapps.deloitteresources.com/api/clients?contains=",
    ProdClientApiUrl:
      "https://qualsapps.deloitteresources.com/api/clients?contains=",
    DevBaseClientQualApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/",
    INTBaseClientQualApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/",
    QABaseClientQualApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/",
    StageBaseClientQualApiUrl:
      "https://stagequalsapps.deloitteresources.com/api/",
    ProdBaseClientQualApiUrl: "https://qualsapps.deloitteresources.com/api/",
    DevAddNewClientApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/clients/Create?",
    INTAddNewClientApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/clients/Create?",
    QAAddNewClientApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/clients/Create?",
    StageAddNewClientApiUrl:
      "https://stagequalsapps.deloitteresources.com/api/clients/Create?",
    ProdAddNewClientApiUrl:
      "https://qualsapps.deloitteresources.com/api/clients/Create?",
    DevGetRelatedQualApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/quals?clientId=",
    INTGetRelatedQualApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/quals?clientId=",
    QAGetRelatedQualApiUrl:
      "https://stage2qualsapps.deloitteresources.com/api/quals?clientId=",
    StageGetRelatedQualApiUrl:
      "https://stagequalsapps.deloitteresources.com/api/quals?clientId=",
    ProdGetRelatedQualApiUrl:
      "https://qualsapps.deloitteresources.com/api/quals?clientId=",
    DevLabelsKey: "__dkm__Labels_kxnextgendevui",
    INTLabelsKey: "__dkm__Labels_int",
    QALabelsKey: "__dkm__Labels_qa",
    StageLabelsKey: "__dkm__Labels_stage",
    ProdLabelsKey: "__dkm__Labels",
    GraphUsersApiUrl:
      "https://graph.microsoft.com/v1.0/me/people/?$filter=personType/class eq 'Person' and personType/subclass eq 'OrganizationUser' and givenName ne null &$top=102",
    DevDPNLink: "https://qa.people.deloitte/profile/",
    INTDPNLink: "https://qa.people.deloitte/profile/",
    QaDPNLink: "https://qa.people.deloitte/profile/",
    StageDPNLink: "https://stage.people.deloitte/profile/",
    ProdDPNLink: "https://people.deloitte/profile/",
    DevDPNImgApiUrl:
      "https://qa-apim-us.people.deloitte/dpn/photo_m?useralias=",
    INTDPNImgApiUrl:
      "https://qa-apim-us.people.deloitte/dpn/photo_m?useralias=",
    QADPNImgApiUrl: "https://qa-apim-us.people.deloitte/dpn/photo_m?useralias=",
    StageDPNImgApiUrl:
      "https://stageapi.people.deloitte/dpn/photo_m?useralias=",
    ProdDPNImgApiUrl: "https://api.people.deloitte/dpn/photo_m?useralias=",
    DevDPNApiUrl:
      "https://qa-apim-us.people.deloitte/dpn/profilecompliance?emailid=",
    INTDPNApiUrl:
      "https://qa-apim-us.people.deloitte/dpn/profilecompliance?emailid=",
    QADPNApiUrl:
      "https://qa-apim-us.people.deloitte/dpn/profilecompliance?emailid=",
    StageDPNApiUrl:
      "https://stageapi.people.deloitte/dpn/profilecompliance?emailid=",
    ProdDPNApiUrl: "https://api.people.deloitte/dpn/profilecompliance?emailid=",
    DevDpnUrl: "https://stage.people.deloitte/profile",
    INTDpnUrl: "https://stage.people.deloitte/profile",
    QADpnUrl: "https://stage.people.deloitte/profile",
    StageDpnUrl: "https://stage.people.deloitte/profile",
    ProdDpnUrl: "https://people.deloitte/profile",
    DevKXDashUrl: "https://dev.kxdash.deloitte/tasks/my-tasks",
    INTKXDashUrl: "https://dev.kxdash.deloitte/tasks/my-tasks",
    QAKXDashUrl: "https://qa.kxdash.deloitte/tasks/my-tasks",
    StageKXDashUrl: "https://stage.kxdash.deloitte/tasks/my-tasks",
    ProdKXDashUrl: "https://kxdash.deloitte/tasks/my-tasks",
    DevOcpApimSubscriptionKey: "10376242371f491db4a74f0b394d5a59",
    INTOcpApimSubscriptionKey: "10376242371f491db4a74f0b394d5a59",
    QAOcpApimSubscriptionKey: "10376242371f491db4a74f0b394d5a59",
    StageOcpApimSubscriptionKey: "c46d7955258143cc9e502faa73f6dd4e",
    ProdOcpApimSubscriptionKey: "aff1065ba79d43679008e90cc855818a",
    ContentTypeDisplayName: "Content Types",
    ContentTypeInternalName: "ContentType",
    NavMenuContentTypeLinksPrefix: "7:",
    SuggestionProd:
      "https://prod-apims-search-deloitte.azure-api.net/suggestions/api/2.0/Suggestions?email=",
    SuggestionStage:
      "https://stage-apims-search-deloitte.azure-api.net/suggestions/api/2.0/Suggestions?email=",
    SuggestionQA:
      "https://dev-apims-search-deloitte.azure-api.net/suggestions/api/2.0/Suggestions?email=",
    SuggestionINT:
      "https://dev-apims-search-deloitte.azure-api.net/suggestions/api/2.0/Suggestions?email=",
    SuggestionApimProd: "d74e6d92f1374be2997e596dfb807200",
    SuggestionApimStage: "682781d46ab44f678795072f8b051989",
    SuggestionApimQA: "2da0a9bcefcd40aeae351399858e1c74",
    SuggestionApimINT: "2da0a9bcefcd40aeae351399858e1c74",
    SuggestionApimDev: "2da0a9bcefcd40aeae351399858e1c74",
    BusinessDisplayName: "Businesses",
    BusinessInternalName: "Business",
    FileTypesDisplayName: "File Types",
    FileTypesInternalName: "FileTypes",
    DevGetImageURL:
      "https://devkxcentralapi.deloitteresources.com/api/AssetImages",
    QAGetImageURL: "https://qakxcentralapi.kx.deloitte/api/AssetImages",
    INTGetImageURL: "https://qakxcentralapi.kx.deloitte/api/AssetImages",
    StageGetImageURL: "https://stagekxcentralapi.kx.deloitte/api/AssetImages",
    ProdGetImageURL: "https://kxcentralapi.kx.deloitte/api/AssetImages",
    BadgesIconDevURL: "https://omniadevkor.sharepoint.com/sites/dev-kx",
    BadgesIconIntURL: "https://resources.deloitte.com/sites/qa-kx",
    BadgesIconQaURL: "https://resources.deloitte.com/sites/qa-kx",
    BadgesIconStageURL: "https://resources.deloitte.com/sites/stage-kx",
    BadgesIconProdURL: "https://resources.deloitte.com/sites/kx",
    ThumbnailDevURL: "https://omniadevkor.sharepoint.com/sites/dev-kx",
    ThumbnailIntURL: "https://resources.deloitte.com/sites/qa-kx",
    ThumbnailQaURL: "https://resources.deloitte.com/sites/qa-kx",
    ThumbnailStageURL: "https://resources.deloitte.com/sites/stage-kx",
    ThumbnailProdURL: "https://resources.deloitte.com/sites/kx",
    DevQualsUrl: "https://devquals.deloitteresources.com/ ",
    INTQualsUrl: "https://intquals.deloitteresources.com/home/myQuals/",
    QAQualsUrl: "https://stage2quals.deloitteresources.com/",
    StageQualsUrl: "https://stagequals.deloitteresources.com/home/myQuals/",
    ProdQualsUrl: "https://quals.deloitteresources.com/home/myQuals/",
    DevClientsUrlForDisplay:
      "https://stage2quals.deloitteresources.com/client/view/",
    INTClientsForDisplayUrl:
      "https://stage2quals.deloitteresources.com/client/view/",
    QAClientsForDisplayUrl:
      "https://stage2quals.deloitteresources.com/client/view/",
    StageClientsForDisplayUrl:
      "https://stagequals.deloitteresources.com/client/view/",
    ProdClientsForDisplayUrl:
      "https://quals.deloitteresources.com/client/view/",
    DevQualsForDisplayUrl: "https://devquals.deloitteresources.com/quals/view/",
    INTQualsForDisplayUrl: "https://intquals.deloitteresources.com/quals/view/",
    QAQualsForDisplayUrl:
      "https://stage2quals.deloitteresources.com/quals/view/",
    StageQualsForDisplayUrl:
      "https://stagequals.deloitteresources.com/quals/view/",
    ProdQualsForDisplayUrl: "https://quals.deloitteresources.com/quals/view/",
    DevSPAttachmentLibrary: "KX Documents",
    INTSPAttachmentLibrary: "KX Documents",
    QASPAttachmentLibrary: "KX Documents",
    StageSPAttachmentLibrary: "KXDocuments",
    ProdSPAttachmentLibrary: "KXDocumentsProd",
    DevSPThumbnailLibrary: "KX Documents Thumbnails",
    INTSPThumbnailLibrary: "KX Documents Thumbnails",
    QASPThumbnailLibrary: "KX Documents Thumbnails",
    StageSPThumbnailLibrary: "KX Documents Thumbnails",
    ProdSPThumbnailLibrary: "KX Documents Thumbnails",

    DevKXHelpUrl:
      "https://resources.deloitte.com/sites/stage-kx/SitePages/kx-help.aspx",
    INTKXHelpUrl:
      "https://resources.deloitte.com/sites/stage-kx/SitePages/kx-help.aspx",
    QAKXHelpUrl:
      "https://resources.deloitte.com/sites/stage-kx/SitePages/kx-help.aspx",
    StageKXHelpUrl:
      "https://resources.deloitte.com/sites/stage-kx/SitePages/kx-help.aspx",
    ProdKXHelpUrl:
      "https://resources.deloitte.com/sites/kx/SitePages/kx-help.aspx ",

    QAGenaiApiUrl: "https://qaai.kx.deloitte",
    QA2GenaiApiUrl: "https://qaai.kx.deloitte",
    DevGenaiApiUrl: "https://qaai.kx.deloitte",
    StageGenaiApiUrl: "https://stageai.kx.deloitte",
    ProdGenaiApiUrl: "https://ai.kx.deloitte",
  };
  public static readonly SpClientId = {
    Dev: "cc7f95c8-bec4-4f10-8037-152e3699aaa9",
    Int: "4abd6ee4-cd51-496c-a920-21e7d1448bfd",
    Qa: "5a9fa617-7c14-4d1f-b0c4-1adda0cfb7af",
    Qa2: "e59f456b-6909-4514-979d-ea0dd8594e97",
    Prod: "51a9631a-4c70-4ce2-97fd-5e2b73d9f190",
    Stage: "5d5cee89-5f37-49cb-9378-9d2d483e4112",
  };
  public static readonly SessionStorageItems = {
    SharedServiceDataState: "__dkm__SharedServiceDataState",
    Config: "__dkm__Config",
    Version: "Version",
    KXDocsVersion: "KXDocsVersion",
    SPAuth: "SPAuthCookie",
    SPAuthPrefix: "SPAuthCookie_",
    WhatsNewConfig: "__dkm__WhatsNewConfig",
    CtpMapping: "__dkm__CtpMapping",
    Application: "__dkm__Application",
    CategoryOrder: "__dkm__CategoryOrder",
    User: "__dkm__User",
    UserImg: "__dkm__UserImg",
    LabelsFR: "__dkm__LabelsFR",
    LabelsES: "__dkm__LabelsES",
    LabelsJA: "__dkm__LabelsJA",
    LabelsZH: "__dkm__LabelsZH",
    Labels: "__dkm__Labels",
    Lang: "__dkm__Lang",
    Badges: "__dkm__Badges",
    SearchHistory: "__dkm__SearchHistory",
    WebInfo: "__dkm__WebInfo",
    Synonyms: "__dkm__Synonyms",
    Pictures: "__dkm__Pictures",
    GlobalNavigationNodeCollection: "__dkm__GlobalNavigationNodeCollection",
    UserProperties: "__dkm__CurrentUserProperties",
    ContentTypesInfo: "__dkm__ContentTypesInfo",
    UserPermissions: "__dkm__UserPermissions",
    IndustryTaxonomyTree: "__dkm__IndustryTaxonomyTree",
    BusinessTaxonomyTree: "__dkm__BusinessTaxonomyTree",
    GeographyTaxonomyTree: "__dkm__GeographyTaxonomyTree",
    SelectedNeed: "__dkm__UserNeed",
    UserNeeds: "__dkm__UserNeeds",
    ResearchTools: "__dkm_ResearchTools",
    ManageInterestsFlag: "__dkm_ManageInterestsFlag",
    CompareContent: "__dkm_CompareContent",
    RoleGroupsAndPrivileges: "__dkm_RoleGroupsPrivileges",
    PageLevelRestrictions: "__dkm_PageLevelRestrictions",
    TagsData: "__dkm__TagsData",
    RawTagsData: "__dkm__RawTagsData",
    GetAllData: "__dkm__GetAllData",
    GetAllNSTagsData: "__dkm__GetAllNSTagsData",
    AccessToken: "__dkm__AccessToken",
    SearchResultsView: "searchResultsView"
  };

  public static readonly DevAPIScopes = {
    loginRequest: {
      scopes: [
        "openid",
        "https://dev.kx.deloitte/access_as_user",
        "offline_access",
      ],
    },
    tokenRequest: {
      scopes: [
        "openid",
        "https://dev.kx.deloitte/access_as_user",
        "offline_access",
      ],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    tokenRequestDPN: {
      scopes: ["edc97c37-e1c0-4f1c-87ff-d810945021e0/.default"],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    tokenRequestGraph: {
      scopes: ["User.Read"],
      forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    impersonation: {
      scopes: ["16020344-987b-4034-a3dc-13f2631dbc1d/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    people: {
      // scopes: ["people.read"],
      scopes: ["user.read.all"],
      forceRefresh: true,
      account: {},
    },
    kxpeople: {
      // 8e73c436-5776-406d-87a9-3eabb50c063a--prod
      // 4937e849-ad3d-4e90-9171-7ccd8ecf288f--dev
      // 962ab8df-0463-4d43-b2f1-10e2dd817d41--stage
      scopes: ["4937e849-ad3d-4e90-9171-7ccd8ecf288f/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    kxclientqual: {
      // 8e73c436-5776-406d-87a9-3eabb50c063a--prod
      // 4937e849-ad3d-4e90-9171-7ccd8ecf288f--dev
      scopes: ["4937e849-ad3d-4e90-9171-7ccd8ecf288f/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    suggestions: {
      scopes: ["38eb10a9-8dde-42f0-8d73-947d3ae655f1/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
  };

  public static readonly INTAPIScopes = {
    loginRequest: {
      scopes: [
        "openid",
        "https://dev.kx.deloitte/access_as_user",
        "offline_access",
      ],
    },
    tokenRequest: {
      scopes: [
        "openid",
        "https://dev.kx.deloitte/access_as_user",
        "offline_access",
      ],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    tokenRequestDPN: {
      scopes: ["edc97c37-e1c0-4f1c-87ff-d810945021e0/.default"],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    tokenRequestGraph: {
      scopes: ["User.Read"],
      forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    impersonation: {
      scopes: ["16020344-987b-4034-a3dc-13f2631dbc1d/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    people: {
      // scopes: ["people.read"],
      scopes: ["user.read.all"],
      forceRefresh: true,
      account: {},
    },
    kxpeople: {
      scopes: ["4937e849-ad3d-4e90-9171-7ccd8ecf288f/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    kxclientqual: {
      // 8e73c436-5776-406d-87a9-3eabb50c063a--prod
      // 4937e849-ad3d-4e90-9171-7ccd8ecf288f--dev
      scopes: ["4937e849-ad3d-4e90-9171-7ccd8ecf288f/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    suggestions: {
      scopes: ["38eb10a9-8dde-42f0-8d73-947d3ae655f1/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
  };

  public static readonly QAAPIScopes = {
    loginRequest: {
      scopes: [
        "openid",
        "https://qa.kx.deloitte/access_as_user",
        "offline_access",
      ],
    },
    tokenRequest: {
      scopes: [
        "openid",
        "https://qa.kx.deloitte/access_as_user",
        "offline_access",
      ],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    tokenRequestDPN: {
      scopes: ["edc97c37-e1c0-4f1c-87ff-d810945021e0/.default"],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    tokenRequestGraph: {
      scopes: ["User.Read"],
      forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    impersonation: {
      scopes: ["885ad874-8625-4ebe-ab68-67aefe17b2c5/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    people: {
      scopes: ["people.read"],
      forceRefresh: true,
      account: {},
    },
    kxpeople: {
      scopes: ["4937e849-ad3d-4e90-9171-7ccd8ecf288f/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    kxclientqual: {
      // 8e73c436-5776-406d-87a9-3eabb50c063a--prod
      // 4937e849-ad3d-4e90-9171-7ccd8ecf288f--dev
      scopes: ["4937e849-ad3d-4e90-9171-7ccd8ecf288f/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    suggestions: {
      scopes: ["38eb10a9-8dde-42f0-8d73-947d3ae655f1/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
  };

  public static readonly StageAPIScopes = {
    loginRequest: {
      scopes: [
        "openid",
        "https://stage.kx.deloitte/access_as_user",
        "offline_access",
      ],
    },
    tokenRequest: {
      scopes: [
        "openid",
        "https://stage.kx.deloitte/access_as_user",
        "offline_access",
      ],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    tokenRequestDPN: {
      scopes: ["37137f4e-90b9-4125-affd-0cc95cc5110a/.default"],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    tokenRequestGraph: {
      scopes: ["User.Read"],
      forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    impersonation: {
      scopes: ["ee7c6b3e-a19a-4480-8b65-cfcb91840fee/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    people: {
      scopes: ["people.read"],
      forceRefresh: true,
      account: {},
    },
    kxpeople: {
      scopes: ["962ab8df-0463-4d43-b2f1-10e2dd817d41/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    kxclientqual: {
      // 8e73c436-5776-406d-87a9-3eabb50c063a--prod
      // 4937e849-ad3d-4e90-9171-7ccd8ecf288f--dev
      // 962ab8df-0463-4d43-b2f1-10e2dd817d41--stage
      scopes: ["962ab8df-0463-4d43-b2f1-10e2dd817d41/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    suggestions: {
      scopes: [
        "https://stage.cognitive.enterprisesearch.deloitte/user_impersonation",
      ],
      forceRefresh: true,
      account: {},
    },
  };

  public static readonly ProdAPIScopes = {
    loginRequest: {
      scopes: [
        "openid",
        "https://kx.deloitte/access_as_user",
        "offline_access",
      ],
    },
    tokenRequest: {
      scopes: [
        "openid",
        "https://kx.deloitte/access_as_user",
        "offline_access",
      ],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    tokenRequestDPN: {
      scopes: ["4eb543ff-f106-4a1b-9c96-0304ced26181/.default"],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    tokenRequestGraph: {
      scopes: ["User.Read"],
      forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
      account: {},
    },
    impersonation: {
      scopes: ["0f4f82f3-be3e-4406-b72b-fd9f44e2137a/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    people: {
      scopes: ["people.read"],
      forceRefresh: true,
      account: {},
    },
    kxpeople: {
      scopes: ["8e73c436-5776-406d-87a9-3eabb50c063a/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    kxclientqual: {
      // 8e73c436-5776-406d-87a9-3eabb50c063a--prod
      // 4937e849-ad3d-4e90-9171-7ccd8ecf288f--dev
      scopes: ["8e73c436-5776-406d-87a9-3eabb50c063a/user_impersonation"],
      forceRefresh: true,
      account: {},
    },
    suggestions: {
      scopes: ["https://cognitive.enterprisesearch.deloitte/.default"],
      forceRefresh: true,
      account: {},
    },
  };

  //GenApi API Scopes
  public static readonly DevGenaiAPIScopes = {
    impersonation: {
      scopes: ["https://qaai.kx.deloitte/access_as_user"],
      forceRefresh: true,
      account: {},
    },
  };

  public static readonly QAGenaiAPIScopes = {
    impersonation: {
      scopes: ["https://qaai.kx.deloitte/access_as_user"],
      forceRefresh: true,
      account: {},
    },
  };

  public static readonly StageGenaiAPIScopes = {
    impersonation: {
      scopes: ["https://stageai.kx.deloitte/access_as_user"],
      forceRefresh: true,
      account: {},
    },
  };

  public static readonly ProdGenaiAPIScopes = {
    impersonation: {
      scopes: ["https://ai.kx.deloitte/access_as_user"],
      forceRefresh: true,
      account: {},
    },
  };

  public static readonly DevGenaiSubscriptionKey =
    "268949bfd2234a2eb124cb7e39013b6e";
  public static readonly QAGenaiSubscriptionKey =
    "268949bfd2234a2eb124cb7e39013b6e";
  public static readonly StageGenaiSubscriptionKey =
    "cca74376d543467db079af191e0420a7";
  public static readonly ProdGenaiSubscriptionKey =
    "72e7db3271ad424894d310312a187603";

  public static readonly DevMsalConfig = {
    auth: {
      clientId: "5d98cf3a-eb10-4a9f-bc84-1a55148de26e",
      authority:
        "https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
      redirectUri: "https://kxnextgendevui.deloitteresources.com",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      allowRedirectInIframe: true,
    },
  };

  public static readonly INTMsalConfig = {
    auth: {
      clientId: "5d98cf3a-eb10-4a9f-bc84-1a55148de26e",
      authority:
        "https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
      redirectUri: "https://int.kx.deloitte",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      allowRedirectInIframe: true,
    },
  };

  public static readonly QAMsalConfig = {
    auth: {
      clientId: "8afe286d-d2bf-42c3-8ecb-c27de6ddf8e3",
      authority:
        "https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
      redirectUri: "https://qa.kx.deloitte",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      allowRedirectInIframe: true,
    },
  };

  public static readonly QA2MsalConfig = {
    auth: {
      clientId: "8afe286d-d2bf-42c3-8ecb-c27de6ddf8e3",
      authority:
        "https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
      redirectUri: "https://qa2.kx.deloitte",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      allowRedirectInIframe: true,
    },
  };

  public static readonly StageMsalConfig = {
    auth: {
      clientId: "3b55b855-fa9a-4bc5-a2f0-68d3921cbf50",
      authority:
        "https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
      redirectUri: "https://stage.kx.deloitte",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      allowRedirectInIframe: true,
    },
  };

  public static readonly ProdMsalConfig = {
    auth: {
      clientId: "3b3534e2-f9a6-490a-b90f-9b5c71f82e7a",
      authority:
        "https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
      redirectUri: "https://kx.deloitte",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      allowRedirectInIframe: true,
    },
  };

  public static readonly ContentTypes = {
    Item: {
      Title: "Item",
      Id: "0x01",
    },
    Document: {
      Title: "Document",
      Id: "0x0101",
    },
    DKXCuratedCollection: {
      Title: "Curated Collection",
      SourceNameInElastic: "Collections",
      Id: "0x01003373A8E6E3E86C4E9D779DF717AFA5DA",
    },
    DKXCuratedCollectionFeatured: {
      Title: "Curated Collection Featured",
      SourceNameInElastic: undefined,
      Id: "0x0100CFB445D10DFB2449B58477463DB188AE",
    },
    DKXPracticeProfile: {
      Title: "DKX Practice Profile",
      SourceNameInElastic: "Practice Profiles",
      Id: "0x01004E6FA4AB2D50AF40AD7D0F3DAB6FC498",
    },
    DKXHomePage: {
      Title: "Home Page",
      Id: "0x0100CFB445D10DFB2449B58477463DB188AE",
    },
    DKXExploreCollection: {
      Title: "Explore Collection",
      Id: "0x01004D81D4ADF64FB243AF19F344848C0A1A",
    },
    DKXPractiseProfileLobbyPage: {
      Title: "Practice Profile Lobby Page",
      Id: "0x01000A5D68E9E6E7D84CBEBC2FAB2999103A",
    },
    KXNGPage: {
      Title: "KXNG Page",
      Id: "0x0100F12923B380CE184AA15A35E754E94D12",
    },
    DKXEnablingTeams: {
      Title: "Enabling Teams",
      Id: "0x0100593F343ABB75D448B1ACDBA3CAEDD819",
    },
    DKXMethod: {
      Title: "Method",
      Id: "0x01009900ACF8A923A04F9D11EC74CDD651AC",
    },
    DKXDeloitteAsset: {
      Title: "DKX Deloitte Asset",
      Id: "0x01007B03D2F90A65384B99295403423A1F8F",
    },
    DKXVideo: {
      Title: "Video",
      Id: "0x010006BF469C0EC85D4788FCFDA3BD7E7F1E",
    },
    DKMAnnouncement: {
      Title: "DKX Event_Announcement",
      Id: "0x01001658194B529311489DF7F27B99B6055A",
    },

    //collections export
    DKXQuals: {
      Title: "Quals",
      Id: "",
    },
    DKXPeopleProfiles: {
      Title: "People Profiles",
      Id: "",
    },
    DKXClients: {
      Title: "Clients",
      Id: "",
    },
  };
  public static readonly BannerImages = {
    exploreCollection:
      "/DKXKXImagesLibrary/KX%20Pages%20Images/Explore%20collections_banner.jpg",
    practiceProfiles:
      "/DKXKXImagesLibrary/KX%20Pages%20Images/Practice%20profiles_banner.jpg",
    personalCollection:
      "/DKXKXImagesLibrary/KX%20Pages%20Images/Personal%20collections_banner.jpg",
    homePage:
      "/DKXKXImagesLibrary/KX%20Pages%20Images/Alternate%20homepage%20option_banner.jpg",
    ComparePage:
      "/DKXKXImagesLibrary/KX%20Pages%20Images/compare%20page_banner.jpg",
  };
  public static readonly Columns = {
    DKXComponentKey: { fName: "dkxComponentKey", pName: "", rName: "" },
    DKXComponentLabel: { fName: "dkxComponentLabel", pName: "", rName: "" },
    DKXComponentLabelGER: {
      fName: "dkxComponentLabelGER",
      pName: "",
      rName: "",
    },
    DKXComponentLabelSPN: {
      fName: "dkxComponentLabelSPN",
      pName: "",
      rName: "",
    },
    DKXComponentLabelFR: { fName: "dkxComponentLabelFR", pName: "", rName: "" },
    DKXComponentLabelZH: { fName: "dkxComponentLabelZH", pName: "", rName: "" },
    DKXComponentLabelJA: { fName: "dkxComponentLabelJA", pName: "", rName: "" },
    DKXConfigurationKey: { fName: "dkxConfigurationKey", pName: "", rName: "" },
    DKXConfigurationValue: {
      fName: "dkxConfigurationValue",
      pName: "",
      rName: "",
    },
    DKXWhatsNewQueryKey: { fName: "dkxVQueryLabel", pName: "", rName: "" },
    DKXWhatsNewQueryValue: { fName: "dkxVWhatsNewQuery", pName: "", rName: "" },
  };

  public static readonly LabelKeys = {
    TermsOfUse: "TermsOfUse",
    TermsOfUseDPNlink: "TermsOfUseDPNlink",
    ReportConcern: "ReportConcern",
    Privacy: "Privacy",
    DeloitteDisclaimerLine1: "DeloitteDisclaimerLine1",
    DeloitteDisclaimerLine2: "DeloitteDisclaimerLine2",
    DeloitteDisclaimerLine3: "DeloitteDisclaimerLine3",
    Title401: "Title401",
    Subtitle401: "Subtitle401",
    Body401: "Body401",
    Title404: "Title404",
    Subtitle404: "Subtitle404",
    Body404: "Body404",
    PersonalCollectionHeaderBelowLabel: "PersonalCollectionHeaderBelowLabel",
    CookieSettings: "CookieSettings",
    //Header
    WebsiteName: "dkxWebsiteName",
    KeyExploreName: "dkxExplore",
    KeyExploreSubText: "dkxExploreLookAround",
    //Need to add all the Modal texts after Gilad will send them
    KeyExploreModalText: "dkxExploreModal",
    KeyLearnName: "dkxLearn",
    KeyLearnSubText: "dkxLearnGetSmart",
    KeyLearnModalText: "dkxLearnModal",
    KeySellName: "dkxSell",
    KeySellSubText: "dkxSellJumpStart",
    KeySellModalText: "dkxSellModal",
    KeyDeliverName: "dkxDeliver",
    KeyDeliverSubText: "dkxDeliverPrepareClient",
    KeyDeliverModalText: "dkxDeliverModal",
    KeyConnectName: "dkxConnect",
    KeyConnectSubText: "dkxConnectEngage",
    KeyConnectModalText: "dkxConnectModal",
    KeyGeneralTextModal: "dkxKeyGeneralTextModal",
    KeyGeneralTitleModal: "dkxKeyGeneralTitleModal",
    KeyJapaneseJA: "dkxJapaneseJA",
    KeyChineseZH: "dkxChineseZH",
    KeyEnglishUS: "dkxEnglishUS",
    KeySpanishES: "dkxSpanishES",
    KeyFrenchFR: "dkxFrenchFR",
    SearchDefaultText: "dkxSearchDefaultText",
    CookiesConsent: "cookiesConsent",

    //Banner
    SearchTipsButton: "dkxSearchTipsButton",
    ieMsgTitle: "ieMsgTitle",
    ieMsgBody: "ieMsgBody",
    welcomeBlessing: "dkxWelcomeBlessing",

    //Homepage sidebar area
    ContributeKnowledgeTitle: "contributeKnowledgeTitle",
    ContributeKnowledgeSubTitle: "contributeKnowledgeSubTitle",
    ContributeKnowledgeButtonText: "contributeKnowledgeButtonText",

    //Hompage Content area
    SectionFeaturedTitle: "sectionFeaturedTitle",
    SectionWhatsNewTitle: "sectionWhatsNewTitle",
    SectionResearchToolsTitle: "sectionResearchToolsTitle",
    PersonalCollectionTitle: "PersonalCollectionTitle",
    PersonalCollectionDesc: "PersonalCollectionDesc",
    CuratedCollectionBoxTitle: "CuratedCollectionBoxTitle",
    CuratedCollectionBtnTextBox: "CuratedCollectionBtnTextBox",
    RecommendedForYouTitle: "RecommendedForYouTitle",
    AllCollectionsTitle: "AllCollectionsTitle",
    PracticeProfileLobbyTitle: "PracticeProfileLobbyTitle",
    PracticeProfileLobbyDesc: "PracticeProfileLobbyDesc",
    ContribureToKX: "ContribureToKX",
    ReasearchToolLink: "ReasearchToolLink",
    //WhatsNewCarousels
    BasedOnInterests: "BasedOnInterests",
    BasedOnPractiseAlignment: "BasedOnPractiseAlignment",
    BasedOnWhatYouFollow: "BasedOnWhatYouFollow",
    InterestsSliderBlankMessage: "InterestsSliderBlankMessage",
    FollowedSliderBlankMessage: "FollowedSliderBlankMessage",
    PracticeAlignmentSliderBlankMessage: "PracticeAlignmentSliderBlankMessage",

    BasedOnInterestsInfo: "BasedOnInterestsInfo",
    BasedOnPractiseAlignmentInfo: "BasedOnPractiseAlignmentInfo",
    BasedOnWhatYouFollowInfo: "BasedOnWhatYouFollowInfo",

    //NotificationLabels
    AboutMeNotification: "AboutMeNotification",
    AskMeNotification: "AskMeNotification",
    ResumeNotification: "ResumeNotification",
    AboutToNotification: "AboutToNotification",
    PastDueNotification: "PastDueNotification",
    DPNNotificationHeader: "DPNNotificationHeader",
    Tasks: "Tasks",
    Task: "Task",
    VisitKXDash: "VisitKXDash",
    NoNotificationsText: "NoNotificationsText",

    ExploreCollectionDesc: "ExploreCollectionDesc",
    ExploreCollectionTitle: "ExploreCollectionTitle",

    // Edit & Delete
    ItemDeletionAlert: "ItemDeletionAlert",

    //Recent Activities
    RecentActivitiesSectionTitle: "RecentActivitiesSectionTitle",

    //General
    LoadingLabel: "LoadingLabel",
    SearchLabel: "SearchLabel",
    CloseMenuItemText: "CloseMenuItemText",
    SeeMoreLabel: "SeeMoreLabel",
    ClosePopupModal: "ClosePopupModal",
    ContextualMenuLabel: "ContextualMenuLabel",
    SortByLabel: "SortByLabel",
    MoreLinksLabel: "MoreLinksLabel",
    BreadcrumbItemsLabel: "BreadcrumbItemsLabel",
    NoResultsFoundLabel: "NoResultsFoundLabel",
    MoreOptionsLabel: "MoreOptionsLabel",
    NoButtonLabel: "NoButtonLabel",
    YesButtonLabel: "YesButtonLabel",
    ThereAreNoItemsLabel: "ThereAreNoItemsLabel",
    ThereAreNoItemsPersonalCollectionLabel:
      "ThereAreNoItemsPersonalCollectionLabel",
    ThereAreNoItemsPersonalCollectionTitle:
      "ThereAreNoItemsPersonalCollectionTitle",
    MyCollectionsLabel: "MyCollectionsLabel",
    ShowLabel: "ShowLabel",
    MoreLabel: "MoreLabel",
    LessLabel: "LessLabel",
    ClearAllLabel: "ClearAllLabel",
    FindLabel: "FindLabel",
    SearchAgainLabel: "SearchAgainLabel",
    SelectAnOptionLabel: "SelectAnOptionLabel",
    SearchKeyWordsLabel: "SearchKeyWordsLabel",
    SaveCapitalButton: "SaveCapitalButton",
    YesCapitalButton: "YesCapitalButton",
    NoCapitalButton: "NoCapitalButton",
    CancelCapitalButton: "CancelCapitalButton",
    CollectionBannerFindMoreBtn: "CollectionBannerFindMoreBtn",
    DidYouMeanLabel: "DidYouMeanLabel",
    SeeAllLabel: "SeeAllLabel",
    OkLabel: "OkLabel",
    DraftsLabel: "DraftsLabel",
    taxonomyManagmentLabel: "taxonomyManagmentLabel",
    EditLabel: "EditLabel",
    DeleteLabel: "DeleteLabel",
    PracticeProfileLabel: "PracticeProfileLabel",
    CuratedCollectionLabel: "CuratedCollectionLabel",
    CuratedCollectionlabelBannerLabel: "CuratedCollectionlabelBannerLabel",
    PersonalCollectionLabel: "PersonalCollectionLabel",
    OfferingsLabel: "OfferingsLabel",
    SectorsLabelLevel2: "SectorsLabelLevel2",
    ApplyButton: "ApplyButton",
    CuratedCollectionApplyButton: "CuratedCollectionApplyButton",
    FollowCollection: "FollowCollection",
    CopyLink: "CopyLink",
    PinToCollection: "PinToCollection",
    CopiedLink: "CopiedLink",
    Unpin: "Unpin",
    Unfollow: "Unfollow",
    Follow: "Follow",
    ExploreConcepts: "ExploreConcepts",
    ResourcesLabel: "ResourcesLabel",
    CollectionInfoLinkText: "CollectionInfoLinkText",
    CollectionInfoLinkURL: "CollectionInfoLinkURL",
    //attachment Download Component
    AttachmentDownloadMessageStart: "KXD_AttachmentDownloadMessageStart",
    AttachmentDownloadMessageProcess: "KXD_AttachmentDownloadMessageProcess",
    AttachmentDownloadMessageComplete: "KXD_AttachmentDownloadMessageComplete",
    AttachmentDownloadErrorRedirectMessage:
      "KXD_AttachmentDownloadErrorRedirectMessage",
    AttachmentDownloadSubTextMessage: "KXD_AttachmentDownloadSubTextMessage",
    //Url Redirect
    URLRedirectViewMessage: "KXD_URLRedirectViewMessage",
    URLRedirectContributeMessage: "KXD_URLRedirectContributeMessage",
    URLRediectDefaultMessage: "KXD_URLRediectDefaultMessage",
    // lastviewed Component
    RecentlyViewedLabel: "RecentlyViewedLabel",

    // manageMyInterests Component
    ManageMyInterestsLabel: "ManageMyInterestsLabel",
    ManageMyInterestsDesc: "ManageMyInterestsDesc",
    FirstLoadManageInterestsTitle: "FirstLoadManageInterestsTitle",
    AreYouSureCancelLabel: "AreYouSureCancelLabel",
    BackToMyInterestsLabel: "BackToMyInterestsLabel",
    SuccessfullySaveInterestsLabel: "SuccessfullySaveInterestsLabel",
    MyInterestsLabel: "MyInterestsLabel",
    MaxInterestsLabel: "MaxInterestsLabel",
    NoteInterestLabel: "NoteInterestLabel",
    ManageYourInterestsPersonalize: "ManageYourInterestsPersonalize",
    DoNotRemindMeAgainLabel: "DoNotRemindMeAgainLabel",
    SelectNewInterests: "SelectNewInterests",
    //manageMySubscription
    PageTitleManageMySubscription: "PageTitleManageMySubscription",
    ManageMySubscriptionLabel: "ManageMySubscriptionLabel",
    ManageMySubscriptionDesc: "ManageMySubscriptionDesc",
    BackToMySubscriptionLabel: "BackToMySubscriptionLabel",
    SuccessfullySaveSubscriptionLabel: "SuccessfullySaveSubscriptionLabel",
    MySubscriptionsLabel: "MySubscriptionsLabel",
    SubscriptionsContentQueryTypeLabel: "SubscriptionsContentQueryTypeLabel",
    SubscriptionsContentTypeLabel: "SubscriptionsContentTypeLabel",
    SubscriptionsContentQueryTypeExplanation:
      "SubscriptionsContentQueryTypeExplanation",
    SubscriptionsContentTypeExplanation: "SubscriptionsContentTypeExplanation",
    SubscriptionsFrequencyLabel: "SubscriptionsFrequencyLabel",
    WantToSubscribe: "WantToSubscribe",
    SubscriptionFooterTitle: "SubscriptionFooterTitle",
    SubscriptionFooterDailyText: "SubscriptionFooterDailyText",
    SubscriptionFooterWeeklyText: "SubscriptionFooterWeeklyText",
    SubscriptionFooterMonthlyText: "SubscriptionFooterMonthlyText",
    IncludedTags: "IncludedTags",
    ExcludedTags: "ExcludedTags",
    IncludedFollowerTags: "IncludedFollowerTags",
    ExcludedFollowerTags: "ExcludedFollowerTags",
    ClickingXInterests: "ClickingXInterests",
    ClickingXFollowers: "ClickingXFollowers",
    subscriptionExcludedInterestsMessage:
      "subscriptionExcludedInterestsMessage",
    subscriptionExcludedCollectionsMessage:
      "subscriptionExcludedCollectionsMessage",
    AppliedGlobalBusiness: "AppliedGlobalBusiness",
    AppliedGlobalIndustry: "AppliedGlobalIndustry",
    CollectionAddedToList: "CollectionAddedToList",
    CollectionAddToSubscription: "CollectionAddToSubscription",
    InterestsAddToSubscription: "InterestsAddToSubscription",

    SubscriptionsContentQueryTypeExplain:
      "SubscriptionsContentQueryTypeExplain",
    SubscriptionsContentTypeExplain: "SubscriptionsContentTypeExplain",

    FailedSaveSubscriptionLabel: "FailedSaveSubscriptionLabel",

    AboutSubscriptionTitle: "AboutSubscriptionTitle",
    AboutSubscriptionDesc: "AboutSubscriptionDesc",
    SubscriptionAddMyInterestPopUpTitle: "SubscriptionAddMyInterestPopUpTitle",

    // practiceProfileLobby Component
    AllPracticeProfileLabel: "AllPracticeProfileLabel",

    // searchBoxGlobal Component
    SearchPracticeProfilesByNameOrKeywordsLabel:
      "SearchPracticeProfilesByNameOrKeywordsLabel",
    SearchExploreCollectionsByNameOrKeywordsLabel:
      "SearchExploreCollectionsByNameOrKeywordsLabel",
    SearchCollectionsByNameOrKeywordsLabel:
      "SearchCollectionsByTopicOrKeywordsLabel",
    // collection Component
    CollectionsYouFollowLabel: "CollectionsYouFollowLabel",

    // relatedLinks Component
    RelatedLinksLabel: "RelatedLinksLabel",

    // relatedCommunities Component
    RelatedCommunitiesLabel: "RelatedCommunitiesLabel",

    // relatedCollections Component
    RelatedCollectionsLabel: "RelatedCollectionsLabel",

    // refinersPanel Component
    DeloitteInformationLabel: "DeloitteInformationLabel",

    // collectionLeaders Component
    CollectionLeadersLabel: "CollectionLeadersLabel",

    // practiceProfile Component
    PractitionersLabel: "PractitionersLabel",
    RevenueLabel: "RevenueLabel",
    UsageOfFactsExternallyLabel: "UsageOfFactsExternallyLabel",
    SearchPracticeProfilesByTopicOrKeywordsLabel:
      "SearchPracticeProfilesByTopicOrKeywordsLabel",
    ExploreResources: "ExploreResources",
    RelatedContent: "RelatedContent",

    // personalCollection Component
    MyPersonalCollectionLabel: "MyPersonalCollectionLabel",

    // peopleYouFollow Component
    PeopleYouFollowLabel: "PeopleYouFollowLabel",

    // DraftItemTable Copmonent
    LoadingDraftsLabel: "LoadingDraftsLabel",

    //Personal Collection Page
    ArchiveItemMessage: "ArchiveItemMessage",
    ArchiveItemUrl: "ArchiveItemUrl",
    UnpinFromCollection: "UnpinFromCollection",
    ArchiveItemHelpLabel: "ArchiveItemHelpLabel",
    // KeyContacts Copmonent
    KeyContactsLabel: "KeyContactsLabel",

    // AllCollection Copmonent
    AllCollectionsLabel: "AllCollectionsLabel",

    //External Search pop up in global search box
    ExternalSearchPopupCancelBtn: "ExternalSearchPopupCancelBtn",
    ExternalSearchPopupOkBtn: "ExternalSearchPopupOkBtn",
    SearchExternalSourceDPNMessage: "SearchExternalSourceDPNMessage",
    SearchExternalSourceDeloitteMessage: "SearchExternalSourceDeloitteMessage",
    SearchExternalSourceQualsMessage: "SearchExternalSourceQualsMessage",
    SearchExternalSourceMessage: "SearchExternalSourceMessage",
    //IDLE
    IdleHeader: "IdleHeader",
    IdlePriorMessage: "IdlePriorMessage",
    IdleMessage: "IdleMessage",
    IdleAcceptButton: "IdleAcceptButton",
    curatedCollectionShowMore: "curatedCollectionShowMore",
    curatedCollectionShowLess: "curatedCollectionShowLess",
    CuratedCollectionShowMoreN: "CuratedCollectionShowMoreN",
    CuratedCollectionViewAll: "CuratedCollectionViewAll",
    CuratedCollectionHideAll: "CuratedCollectionHideAll",
    personalCollectionSortByAlphabetical:
      "personalCollectionSortByAlphabetical",
    personalCollectionSortByMostRecent: "personalCollectionSortByMostRecent",
    searchResultsSortLabelsRelevant: "searchResultsSortLabelsRelevant",
    searchResultsSortLabelsAlphabetical: "searchResultsSortLabelsAlphabetical",
    searchResultsSortLabelsMostRecent: "searchResultsSortLabelsMostRecent",
    practiceProfileAccoladesAwards: "practiceProfileAccoladesAwards",
    practiceProfileWhyTopicImportant: "practiceProfileWhyTopicImportant",
    myProfileDPNLabel: "myProfileDPNLabel",
    myProfileMyPersonalCollection: "myProfileMyPersonalCollection",
    myProfileCreateContent: "myProfileCreateContent",
    practiceProfileOverviewMaterials: "practiceProfileOverviewMaterials",
    moreOnLabelRightPane: "moreOnLabelRightPane",
    resultsLabelSearchResults: "resultsLabelSearchResults",
    imageresultsLabelSearchResults: "imageresultsLabelSearchResults",
    allLabelSearchResults: "allLabelSearchResults",
    imagesLabelSearchResults: "imagesLabelSearchResults",
    imagesSource: "imagesSource",
    imagesTypes: "imagesTypes",
    imagesSourceInfo: "imagesSourceInfo",
    date: "date",
    contentFilterLabelSearchResults: "contentFilterLabelSearchResults",
    sourceOptionsAllKXContent: "sourceOptionsAllKXContent",
    sourceOptionsPeople: "sourceOptionsPeople",
    sourceOptionsKXQuals: "sourceOptionsKXQuals",
    sourceOptionsAllDeloitteContent: "sourceOptionsAllDeloitteContent",
    quickViewLabelMiniCard: "quickViewLabelMiniCard",
    viewFullDetailsLabelMiniCard: "viewFullDetailsLabelMiniCard",
    pinToPersonalCollectionLabelMiniCard:
      "pinToPersonalCollectionLabelMiniCard",
    copyLinkLabelMiniCard: "copyLinkLabelMiniCard",
    NoResultsFoundText: "NoResultsFoundText",
    NoResultsTextForStopWords: "NoResultsTextForStopWords",
    NoImageResultsFoundText: "NoImageResultsFoundText",
    exploreCollectionBannerImage: "exploreCollectionBannerImage",
    practiceProfilesBannerImage: "practiceProfilesBannerImage",
    personalCollectionBannerImage: "personalCollectionBannerImage",
    homePageBannerImage: "homePageBannerImage",
    taxonomyManagmentImage: "taxonomyManagmentImage",
    AllCategoriesLabelSearchResults: "AllCategoriesLabelSearchResults",
    PracticeTypeAllLabel: "PracticeTypeAllLabel",
    PracticeTypeIndustriesLabel: "PracticeTypeIndustriesLabel",
    PracticeTypeBusinessesLabel: "PracticeTypeBusinessesLabel",

    SearchPageContentTypeRefinerPlaceholder:
      "SearchPageContentTypeRefinerPlaceholder",
    SearchPageFileTypesRefinerPlaceholder:
      "SearchPageFileTypesRefinerPlaceholder",
    SearchPageImageTypeRefinerPlaceholder:
      "SearchPageImageTypeRefinerPlaceholder",
    SearchPageTopicsRefinerPlaceholder: "SearchPageTopicsRefinerPlaceholder",
    SearchPageTagsRefinerPlaceholder: "SearchPageTagsRefinerPlaceholder",
    SearchPageBadgesRefinerPlaceholder: "SearchPageBadgesRefinerPlaceholder",
    SearchPageBusinessRefinerPlaceholder:
      "SearchPageBusinessRefinerPlaceholder",
    SearchPageIndustriesRefinerPlaceholder:
      "SearchPageIndustriesRefinerPlaceholder",
    SearchPageGeographiesRefinerPlaceholder:
      "SearchPageGeographiesRefinerPlaceholder",

    SearchPageExportUpto50: "SearchPageExportUpto50",
    SearchPageExportUpto250: "SearchPageExportUpto250",

    ExploreCollectionPageBusinessRefinerPlaceholder:
      "ExploreCollectionPageBusinessRefinerPlaceholder",
    ExploreCollectionPageIndustriesRefinerPlaceholder:
      "ExploreCollectionPageIndustriesRefinerPlaceholder",
    ExploreCollectionPageGeographiesRefinerPlaceholder:
      "ExploreCollectionPageGeographiesRefinerPlaceholder",
    ExploreCollectionPageTopicsRefinerPlaceholder:
      "ExploreCollectionPageTopicsRefinerPlaceholder",

    CuratedCollectionPageBusinessRefinerPlaceholder:
      "CuratedCollectionPageBusinessRefinerPlaceholder",
    CuratedCollectionPageGeographiesRefinerPlaceholder:
      "CuratedCollectionPageGeographiesRefinerPlaceholder",
    CuratedCollectionPageIndustriesRefinerPlaceholder:
      "CuratedCollectionPageIndustriesRefinerPlaceholder",
    CuratedCollectionPageContentTypeRefinerPlaceholder:
      "CuratedCollectionPageContentTypeRefinerPlaceholder",
    CuratedCollectionPageConceptsRefinerPlaceholder:
      "CuratedCollectionPageConceptsRefinerPlaceholder",
    CuratedCollectionPageFileTypesRefinerPlaceholder:
      "CuratedCollectionPageFileTypesRefinerPlaceholder",

    PracticeProfileLobbyPageBusinessRefinerPlaceholder:
      "PracticeProfileLobbyPageBusinessRefinerPlaceholder",
    PracticeProfileLobbyPageIndustriesRefinerPlaceholder:
      "PracticeProfileLobbyPageIndustriesRefinerPlaceholder",
    PracticeProfileLobbyPageGeographiesRefinerPlaceholder:
      "PracticeProfileLobbyPageGeographiesRefinerPlaceholder",
    PracticeProfileLobbyPageBusinessLevelsRefinerPlaceholder:
      "PracticeProfileLobbyPageBusinessLevelsRefinerPlaceholder",
    PracticeProfileLobbyPageIndustryLevelsRefinerPlaceholder:
      "PracticeProfileLobbyPageIndustryLevelsRefinerPlaceholder",
    PageTitleHome: "PageTitleHome",
    PageTitleExploreCollection: "PageTitleExploreCollection",
    PageTitleExplorePracticeProfiles: "PageTitleExplorePracticeProfiles",
    PageTitleIndividualCollection: "PageTitleIndividualCollection",
    PageTitleIndividualPractice: "PageTitleIndividualPractice",
    PageTitleSearchResultsWithTerm: "PageTitleSearchResultsWithTerm",
    PageTitleSearchResultsWithoutTerm: "PageTitleSearchResultsWithoutTerm",
    PageTitleManageMyInterests: "PageTitleManageMyInterests",
    PageTitleMyPersonalCollection: "PageTitleMyPersonalCollection",
    PageTitleDraftsItems: "PageTitleDraftsItems",
    PageTitleCompareItems: "PageTitleCompareItems",
    PageTitleKXDEditForm: "PageTitleKXDEditForm",
    PageTitleKXDNewContribution: "PageTitleKXDNewContribution",
    PageTitleKXDPublisherDashboard: "PageTitleKXDPublisherDashboard",
    PageTitleKXDMyKX: "PageTitleKXDMyKX",

    CompareContentMaxItemValidationPopUpTitle:
      "CompareContentMaxItemValidationPopUpTitle",
    CompareContentMaxItemValidationPopUpMessage:
      "CompareContentMaxItemValidationPopUpMessage",
    ComapreAreaTitle: "ComapreAreaTitle",
    ComapreAreaNumberOfItemsText: "ComapreAreaNumberOfItemsText",
    ComapreAreaCompareSelectionText: "ComapreAreaCompareSelectionText",
    CompareAreaRemoveAllText: "CompareAreaRemoveAllText",
    ComparePageTitle: "ComparePageTitle",
    ComparePageNumberText: "ComparePageNumberText",
    ComparePageSubTitle: "ComparePageSubTitle",
    ComparePageDescriptionTitle: "ComparePageDescriptionTitle",
    ComparePageRemoveText: "ComparePageRemoveText",
    CompareEmailLinks: "CompareEmailLinks",
    PinNewItemPopUp: "PinNewItemPopUp",
    MoveToCategoryLabel: "MoveToCategoryLabel",
    DeleteEmptyCategoryConfirmation: "DeleteEmptyCategoryConfirmation",
    DeleteFullCategoryConfirmation: "DeleteFullCategoryConfirmation",

    //related links - sub-categories
    SubCategoryHeaderAlliances: "SubCategoryHeaderAlliances",
    SubCategoryHeaderDeloitteCom: "SubCategoryHeaderDeloitteCom",
    SubCategoryHeaderExternalResources: "SubCategoryHeaderExternalResources",
    SubCategoryHeaderLearningResources: "SubCategoryHeaderLearningResources",
    SubCategoryHeaderMedia: "SubCategoryHeaderMedia",
    SubCategoryHeaderRelatedCommunities: "SubCategoryHeaderRelatedCommunities",
    SubCategoryHeaderRelatedPractices: "SubCategoryHeaderRelatedPractices",
    TabOrderManagerOptionPrefix: "TabOrderManagerOptionPrefix",

    //cookies
    PrivacyLink: "PrivacyLink",

    //Collection Export Labels
    Export: "Export",
    ExportReportGenerated: "ExportReportGenerated",
    ExportExportedBy: "ExportExportedBy",
    ExportNumberOfRecords: "ExportNumberOfRecords",
    ExportNote: "ExportNote",
    ExportNoteLine1: "ExportNoteLine1",
    ExportNoteLine2: "ExportNoteLine2",
    ExportNoteLine3: "ExportNoteLine3",
    ExportNoteLineStatement1: "ExportNoteLineStatement1",
    ExportNoteLineStatement2: "ExportNoteLineStatement2",
    ExportNoteLineStatement3: "ExportNoteLineStatement3",
    ExportSearchCriteria: "ExportSearchCriteria",
    ExportCollectionURL: "ExportCollectionURL",
    ExportSortedBy: "ExportSortedBy",
    ExportHeaderPosition: "ExportHeaderPosition",
    ExportHeaderContentTypes: "ExportHeaderContentTypes",
    ExportHeaderURL: "ExportHeaderURL",
    ExportHeaderName: "ExportHeaderName",
    ExportHeaderLevel: "ExportHeaderLevel",
    ExportHeaderDescription: "ExportHeaderDescription",
    ExportHeaderGlobalClientService1: "ExportHeaderGlobalClientService1",
    ExportHeaderGlobalClientService2: "ExportHeaderGlobalClientService2",
    ExportHeaderGlobalIndustry1: "ExportHeaderGlobalIndustry1",
    ExportHeaderGlobalIndustry2: "ExportHeaderGlobalIndustry2",
    ExportHeaderDate: "ExportHeaderDate",
    ExportHeaderGeography: "ExportHeaderGeography",
    ExportHeaderConceptTags: "ExportHeaderConceptTags",
    ExportHeaderBadges: "ExportHeaderBadges",
    ExportHeaderRole: "ExportHeaderRole",
    ExportHeaderAskMeAboutTags: "ExportHeaderAskMeAboutTags",
    ExportHeaderTopicsCollections: "ExportHeaderTopicsCollections",
    ExportHeaderFileType: "ExportHeaderFileType",
    ExportErrorTitle: "ExportErrorTitle",
    ExportErrorText: "ExportErrorText",
    ExportConfirm: "ExportConfirm",
    ExportPopupCancel: "ExportPopupCancel",
    ExportPopupOk: "ExportPopupOk",
    ExportDisclaimerTitle: "ExportDisclaimerTitle",
    ExportDisclaimerText1: "ExportDisclaimerText1",
    ExportDisclaimerText2: "ExportDisclaimerText2",
    ExportDisclaimerText3: "ExportDisclaimerText3",
    ExportDisclaimerText4: "ExportDisclaimerText4",
    ResourcesRelevanceSorting: "ResourcesRelevanceSorting",
    ResourcesNameSorting: "ResourcesNameSorting",
    ResourcesRecentSorting: "ResourcesRecentSorting",
    ResourcesSortTitle: "ResourcesSortTitle",
    RHRbtn: "RHRbtn",
    DescriptionReadMore: "DescriptionReadMore",
    SearchFiltersTitle: "SearchFiltersTitle",
    SearchResourcesBtn: "SearchResourcesBtn",
    ResultsResourcesLabel: "ResultsResourcesLabel",
    collectionResourcesFiltersMobileShowMore:
      "collectionResourcesFiltersMobileShowMore",
    collectionResourcesFiltersMobileShowLess:
      "collectionResourcesFiltersMobileShowLess",

    //Prompt Labels
    KXPrompt_TopNav_Label: "KXPrompt_TopNav_Label",
    KXPrompt_ResultsLabel: "KXPrompt_ResultsLabel",
    KXPrompt_ResultsCitationNumber: "KXPrompt_ResultsCitationNumber",
    KXPrompt_RegenerateResponsePopup: "KXPrompt_RegenerateResponsePopup",
    KXPrompt_RegenerateResponsePopup_Specialist:
      "KXPrompt_RegenerateResponsePopup_Specialist",
    KXPrompt_ReviewRestrictionsPopup: "KXPrompt_ReviewRestrictionsPopup",
    KXPrompt_IncludeResultPopup: "KXPrompt_IncludeResultPopup",
    KXPrompt_ResultIncludedPopup: "KXPrompt_ResultIncludedPopup",
    KXPrompt_ResultNotAllowedSpecialist: "KXPrompt_ResultNotAllowedSpecialist",
    KXPrompt_WelcomeText1: "KXPrompt_WelcomeText1",
    KXPrompt_WelcomeText2: "KXPrompt_WelcomeText2",
    KXPrompt_WelcomeText3: "KXPrompt_WelcomeText3",
    KXPrompt_InitialInputPlaceholder: "KXPrompt_InitialInputPlaceholder",
    KXPrompt_ModifyInputPlaceholder: "KXPrompt_ModifyInputPlaceholder",
    KXPrompt_NewLabel: "KXPrompt_NewLabel",
    KXPrompt_NewPopupInfo: "KXPrompt_NewPopupInfo",
    KXPrompt_QuickView: "KXPrompt_QuickView",
    KXPrompt_CountryNotSupported: "KXPrompt_CountryNotSupported",
    KXPrompt_HomepageElementVisibility: "KXPrompt_HomepageElementVisibility",
    KXPrompt_QuickViewLoaderText: "KXPrompt_QuickViewLoaderText",
    KXPrompt_ConversationLoaderText: "KXPrompt_ConversationLoaderText",
    KXPrompt_NoResultsMsgLabel: "KXPrompt_NoResultsMsgLabel",
    KXPrompt_CharacterLimitLabel: "KXPrompt_CharacterLimitLabel",

    //TryGenAITile
    KXPrompt_TryGenAILAbel: "KXPrompt_TryGenAILAbel",
    KXPrompt_TryGenAIInfo: "KXPrompt_TryGenAIInfo",
    KXPrompt_TryPromptLabel: "KXPrompt_TryPromptLabel",
    //TryGenAIPopup
    KXPrompt_TryPromptPopupLabel: "KXPrompt_TryPromptPopupLabel",
    KXPrompt_TryPromptPopupSubtitle: "KXPrompt_TryPromptPopupSubtitle",
    KXPrompt_TryPromptPopupCardLabelOne: "KXPrompt_TryPromptPopupCardLabelOne",
    KXPrompt_TryPromptPopupCardLabelTwo: "KXPrompt_TryPromptPopupCardLabelTwo",
    KXPrompt_TryPromptPopupCardLabelThree:
      "KXPrompt_TryPromptPopupCardLabelThree",
    KXPrompt_NotNow: "KXPrompt_NotNow",
    //Tips Popup
    KXPrompt_TipsLabel: "KXPrompt_TipsLabel",
    KXPrompt_TipsQuestion: "KXPrompt_TipsQuestion",
    KXPrompt_GuidedTourLabel: "KXPrompt_GuidedTourLabel",
    KXPrompt_GuidedTourURL: "KXPrompt_GuidedTourURL",
    KXPrompt_TipsList: "KXPrompt_TipsList",
    //About Popup
    KXPrompt_AboutPromptByKxLabel: "KXPrompt_AboutPromptByKxLabel",
    KXPrompt_PromptByKxLabel: "KXPrompt_PromptByKxLabel",
    KXPrompt_AboutInfo: "KXPrompt_AboutInfo",
    //QuickTips
    KXPrompt_QuickTipsLabel: "KXPrompt_QuickTipsLabel",
    //Country Restrictions
    KXPrompt_RestrictedCountryList: "KXPrompt_RestrictedCountryList",
    //Sidebar
    KXPrompt_SIDEBAR_OPTIONS_TOP: "KXPrompt_SIDEBAR_OPTIONS_TOP",
    KXPrompt_SIDEBAR_OPTIONS_BOTTOM: "KXPrompt_SIDEBAR_OPTIONS_BOTTOM",
    KXPrompt_KX_HOMEPAGE_LINK: "KXPrompt_KX_HOMEPAGE_LINK",
    KXPrompt_KX_FEEDBACK_LINK: "KXPrompt_KX_FEEDBACK_LINK",
    KXPrompt_NoHistoryRecord: "KXPrompt_NoHistoryRecord",

    //Welcome Prompts
    KXPrompt_InitialPromptHeading: "KXPrompt_InitialPromptHeading",
    KXPrompt_ChatIntialSuggestedPrompts: "KXPrompt_ChatIntialSuggestedPrompts",
    KXPrompt_CurrentChatHistorySaved: "KXPrompt_CurrentChatHistorySaved",

    //Prompt Response
    KXPrompt_ResultsGenerating: "KXPrompt_ResultsGenerating",
    KXPrompt_ResponseFooter: "KXPrompt_ResponseFooter",
    KXPrompt_ResponseFooterExtension: "KXPrompt_ResponseFooterExtension",
    KXPrompt_GoodResponsePopup: "KXPrompt_GoodResponsePopup",
    KXPrompt_BadResponsePopup: "KXPrompt_BadResponsePopup",
    KXPrompt_CopyResponseLabel: "KXPrompt_CopyResponseLabel",
    KXPrompt_ModifyResponseLabel: "KXPrompt_ModifyResponseLabel",
    KXPrompt_ResponseCopied: "KXPrompt_ResponseCopied",
    KXPrompt_ResponseModifierMenuList: "KXPrompt_ResponseModifierMenuList",
    KXPrompt_BadResponseMenuOptions: "KXPrompt_BadResponseMenuOptions",
    KXPromot_FollowQuestionArrowToolTip: "KXPromot_FollowQuestionArrowToolTip",
    KXPrompt_FieldSpecialistsLabel: "KXPrompt_FieldSpecialistsLabel",

    //NewTopicPopup, RegenerateTopicPopup
    KXPrompt_NewTopic: "KXPrompt_NewTopic",
    KXPrompt_NewTopicSubtext: "KXPrompt_NewTopicSubtext",
    KXPrompt_NoButton: "KXPrompt_NoButton",
    KXPrompt_SaveAndProceedButton: "KXPrompt_SaveAndProceedButton",
    KXPrompt_RegenerateTopic: "KXPrompt_RegenerateTopic",
    KXPrompt_RegenerateOnHistorySavePopup:
      "KXPrompt_RegenerateOnHistorySavePopup",
    KXPrompt_RegenerateButton: "KXPrompt_RegenerateButton",
    KXPrompt_HistoryInfoIconTooltipText: "KXPrompt_HistoryInfoIconTooltipText",
    KXPrompt_WarningPopupTitle: "KXPrompt_WarningPopupTitle",
    KXPrompt_WarningPopupSubtext: "KXPrompt_WarningPopupSubtext",
    KXPrompt_UnableToAddCitation: "KXPrompt_UnableToAddCitation",
  };

  public static readonly Routing = {
    MySubscription: "/MySubscription",
    MyIntrests: "/MyInterests",
    Collections: "/Collections",
    Collection: "/Collection",
    PracticeProfiles: "/PracticeProfiles",
    PracticeProfile: "/PracticeProfile",
    Search: "/Search",
    Drafts: "/Drafts",
    TaxonomyManagment: "/TaxonomyManagement",
    PageNotFound: "/PageNotFound",
    PersonalCollection: "/PersonalCollection",
    CompareItems: "/Compare",
    NewContribution: "/documents/contribute",
    NewContributionID: "/documents/contribute/:draftid",
    TreePage: "/treepage",
    displayPage: "/documents/view/:id",
    displayPageID: "/documents/view/",
    MyContribution: "/documents/my_contributions",
    EditForm: "/documents/edit/:id",
    UnauthorizedPage: "/unauthorized",
    EditFormID: "/documents/edit/",
    myKxContribution: "/mykx",
    DocumentAdmin: "/documents/documentAdmin",
    publisherDashBoard: "/documents/publishing",
    collection: "/Collection?id=",
    CookiesConsent: "/CookiesConsent",
    Prompt: "/prompt",
    AttachmentDownloadPage: "/documents/view/:id/attachment/:fileId",
    oldUrlView: "/oldurl",
  };
  public static readonly DraftsPageLabelKeys = {
    DraftsPageTitle: "DraftsPageTitle",
    CollectionButtonLabel: "Collection Drafts Button Label",
    PracticeProfileButtonLabel: "Practice Profile Drafts Button Label",
    MiniCardButtonLabel: "KX Mini-card Drafts Button Label",
    KXPageButtonLabel: "KX Page Drafts Button Label",
    VideosDraftsLabel: "Mini-card Videos Drafts Label",
    ExternalPagesDraftsLabel: "Mini-card External Page Drafts Label",
    AnnouncementsDraftsLabel: "Mini-card Announcement Drafts Label",
    EnablingTeamsDraftsLabel: "Mini-card Enabling Teams Drafts Label",
    MethodsDraftsLabel: "Mini-card Methods Drafts Label",
    AssetsDraftsLabel: "Mini-card Assets Drafts Label",
    DraftsLoadingText: "DraftsLoadingText",
    NoDraftsFound: "NoDraftsFound",
    DraftsResultsFound: "DraftsResultsFound",
  };

  public static readonly MyKxContributionPageLabelKeys = {
    MyKXContributionPageContacts: "MyKXContribution_PageContacts",
    MyKXContributionFAQ: "MyKXContribution_FAQ",
    MyKXContributionWhatandWhentoShare: "MyKXContribution_WhatandWhentoShare",
    MyKXContributionRightPanel: "MyKXContribution_RightPanel",
    MyKXContributionDescription: "MyKXContribution_Description",
    MyKXContributionGuidance: "MyKXContribution_Guidance",
    MyKXContributionTableHeadingLabel: "MyKXContribution_TableHeadingLable",
  };

  public static readonly MyKXContributionDocumentStatus = {
    Draft: "Draft document",
    Contributed: "Contributed Document",
    Published: "Published Document",
    InProcess: "In Process Document",
    UpdateRequested: "Update Requested",
    Archived: "Archived Document",
    Assigned: "Assigned Document",
  };

  public static readonly AttachmentInstructionsLabelKeys = {
    EditPageInstructions:
      "KXDocument_Attachments Instruction button_UserEditForm",
    NewContributionInstructions:
      "KXDocument_Attachment instructions_NewContribution",
  };

  public static readonly HelpTextKeys = {
    SearchTips: "Search Tips",
    InstructionalTextGlobal: "Instructional Text Global",
    InstructionalTextCa: "Instructional Text CA",
    InstructionalTextUs: "Instructional Text US",
    InstructionalTextAu: "Instructional Text AU",
    InstructionalTextDe: "Instructional Text DE",
    InstructionalTextUk: "Instructional Text UK",
    WhyIsThisTopicImportant: "Why is this topic important",
  };

  public static CategoryTypeName = {
    SearchCategory: "SearchCategory",
    CollectionCategory: "CollectionCategory",
  };

  public static ContentTypeProps = {
    ContentTypeName: "contentTypeName",
    featured: "featured",
  };

  public static readonly DisplayView = {
    ItemsInSearchResults: 16,
    ItemsInCategoryViewLine: 5,
    ItemsInCategoryViewPage: 25,
    ItemsInCategoryExport: 1000,
    ItemsInContextualSearch: 20,
    ItemContextualSearchExplorePage: 32,
    ItemsInSearchResultsListView: 50,
    Grid: "grid",
    List: "list",
    Category: "category",
  };
  public static readonly SugestionType = {
    Refiners: "Refiners",
    Suggestions: "Suggestions",
    Match: "Match",
  };

  public static readonly PracticeProfileLabels = {
    PracticeTypeBusiness: "Business",
    PracticeTypeIdustry: "Industry",
    OfferingPortfolioLabel: "OfferingPortfolioLabel",
    SectorsLabel: "SectorsLabel",
    OfferingPortfolioLabelL2: "OfferingsLabel",
    SectorsLabelL2: "SectorsLabelLevel2",
    PracticeProfileOverviewLabel: "PracticeProfileOverviewLabel",
    QuickFactsLabel: "QuickFacts",
  };

  public static readonly SearchLabels = {
    SearchSearchFor: "SearchSearchFor",
    SearchViewAll: "SearchViewAll",
    SearchPlaceholder: "SearchPlaceholder",
    SearchAgain: "SearchAgain",
    UserNeedTitle: "UserNeedTitle",
  };

  public static readonly PageNames = {
    PracriceProfileLobby: "PracriceProfileLobby",
    ExploreCollection: "ExploreCollection",
    CuratedCollection: "CuratedCollection",
  };

  public static readonly QueryStringParameters = {
    requestUrl: "requestUrl",
    R: "r",
    Query: "q",
    Mode: "mode",
    SeeAll: "qu",
    Practice: "p",
    ID: "id",
    UserNeeds: "u",
    Sort: "sort",
    View: "view",
    Lang: "lang",
    Page: "page",
    From: "from",
    To: "to",
    isImages: "isImages",
    imageId: "imageId",
  };

  public static readonly ActionsButtonsNames = {
    follow: "FollowCollection",
    unFollow: "Unfollow",
    pin_to_Collection: "PinToCollection",
    unPin: "Unpin",
    copy_Link: "CopyLink",
    MetaData: "Get Metadata",
    ExplainData: "Get Explain Data",
    copied: "CopiedLink",
    Email: "Email",
    EditItem: "EditItem",
    DeleteItem: "DeleteItem",
    downloadAll: "Download All"
  };

  public static readonly ActionsButtonsKeys = {
    Follow: "Follow",
    CopyLink: "CopyLink",
    MetaData: "MetaData",
    ExplainData: "Explain",
    PinToCollection: "PinToCollection",
    QuickView: "QuickView",
    EditItem: "EditItem",
    DeleteItem: "DeleteItem",
    Email: "Email",
    Compare: "CompareItem",
    DownloadAll: "DownloadAll"
  };
  
  public static readonly SortLabels = {
    Alphabetical: "Alphabetical",
    MostRecent: "Most recent",
    Relevant: "Relevant",
  };
  public static readonly SortLabelsModernUi = {
    Name: "Name (A to Z)",
    MostRecent: "Most Recent",
    Relevance: "Relevance",
  };
  public static readonly ExportLabels = {
    Actions: 0,
    Export_Upto_50: 50,
    Export_Upto_250: 250,
  };

  public static readonly ExportFileNames = {
    SearchExport: "searchExport",
    CollectionsExport: "Collections Export",
  };

  public static readonly PracticeType = {
    Industries: "Industries",
    Businesses: "Businesses",
    Industry: "Industry",
    Business: "Business",
    All: "All",
  };

  public static readonly ImagesFormat = {
    squareFormat: "squareFormat",
    rectangleFormat: "rectangleFormat",
    topBannerFormat: "topBannerFormat",
  };

  public static readonly ElasticFields = {
    L1: "apptype",
    L2: "appsubtype",
    InternalCT: "internalcontenttype",
  };

  public static readonly RefinersEnum = {
    Industries: { id: "1", title: "Industries" },
    Badges: { id: "2", title: "Badges" },
    Tags: { id: "3", title: "Tags" },
    Business: { id: "4", title: "Businesses" },
    Topics: { id: "5", title: "Topics" },
    Geographies: { id: "6", title: "Geographies" },
    ContentTypes: { id: "7", title: "Content Types" },
    FileTypes: { id: "8", title: "File Types" },
    PracticeType: { id: "9", title: "Practice Types" },
    BusinessLevels: { id: "10", title: "Business Levels" },
    IndustryLevels: { id: "11", title: "Industry Levels" },
    Concepts: { id: "12", title: "Concepts" },
    Date: { id: "15", title: "Date" },
    ImageTypes: { id: "13", title: "Image Types" },
  };

  //#region Adobe Analytics

  public static readonly AnalyticsGenericform = {
    SearchContentType: "KX Next Gen",
  };

  public static readonly AnayticsEventKeys = {
    PageLoad: "PageLoad",
    SearchPerformed: "SearchPerformed",
    SearchBox: "SearchBox",
    SearchElsewhere: "SearchElsewhere",
    ContextualSearch: "ContextualSearch",
    MiniCard: "MiniCard",
    QuickView: "QuickView",
    Export: "Collection Export",
  };

  public static readonly TrackerKeys = {
    PageLoad: "page_load",
    Search: "search_performed",
    SearchBoxResult: "search_box_result",
    SearchElsewhere: "search_elsewhere",
    ContextualSearch: "contextual_search",
    MiniCard: "mini_card",
    QuickView: "quick_view",
    Compare: "compare",
    DocumentDownload: "document_download",
    DocumentContribution: "form_state",
    ExportError: "error",
    RightRail: "right_rail",
    ChatInteraction: "chat_interaction",
    Citation: "citation",
  };

  public static readonly PageTitles = {
    HomePage: "Home Page",
    Search: "Search Results",
    ExploreCollections: "Explore Collections",
    CuratedCollection: "Curated Collection",
    PersonalCollection: "Personal Collection",
    ExplorePracticeProfiles: "Explore Practice Profiles",
    PracticeProfile: "Practice Profile",
    ErrorPage: "Error Page",
    ManageMyInterests: "Manage My Interests",
    CompareItems: "Compare",
    DocumentDisplay: "Document Display",
    DocumentContribution: "Document Contribution",
    Contribute: "Contribution Dashboard",
    DocumentEdit: "Document Edit",
    DocumentEditPublishingInformation: "Document Edit - Publishing Information",
    DocumentDownload: "Document Download",
    ContributionToKX: "Contribution to KX",
    KXDocuments: "KX Documents",
    NewContribution: "New KX Contribution",
    ManageMySubscription: "Manage My Subscription",
  };

  public static readonly MiniCardActions = {
    ViewFullContent: "View Full Content",
    QuickView: "Quick View",
    Follow: "Follow",
    Unfollow: "Unfollow",
    Pin: "Pin",
    Unpin: "Unpin",
    CopyLink: "Copy Link",
    Download: "Download",
    Email: "Email",
    MetaData: "MetaData",
    Compare: "Compare",
  };
  public static readonly TrackerDownloadFiles = {
    Type: "type",
    DownloadUrls: [],
  };
  public static readonly DocumentType = {
    SingleDownload: "Single Download",
    DownloadAll: "Download All",
  };
  public static readonly TrackerDocumentContribution = {
    Start: "Start",
    Submit: "Submit",
    Name: "Document Contribution",
  };
  public static readonly DisplayAreas = {
    CuratedCollection: "Curated Collection",
    PracticeProfile: "Practice Profile",
    SearchResults: "Search Results",
    ExploreCollection: "Explore Collection",
    Featured: "Featured",
    PersonalCollection: "Personal Collection - Default Category",
    RecommendedForYou: "Recommended For You",
    WhatsNewPractiseAlignment: "What's New - Based on your practice alignment",
    WhatsNewInterests: "What's New - Based on your Interests",
    WhatsNewFollowers: "What's New - Based on what you follow",
    AllCollection: "All Collection",
    Resources: "Resources",
    PromotedResults: "Promoted Search Result",
    CategorizedPersonalCollection: "Personal Collection - Custom Category",
    RightRail: "Right rail",
    KeyImages: "Key Images",
  };

  public static readonly SearchActivityTypes = {
    Keyword: "Keyword",
    Filter: "Filter",
    DidYouMean: "DidYouMean",
    Pagination: "Pagination",
    Saved: "Saved",
    BrowserBack: "BrowserBack",
  };
  public static readonly searchPageSourceSystem = "KX Next Gen";
  public static readonly searchPageSourceContentType = "Search Results";
  public static readonly pageNotFoundAnalyticsErrorMessage =
    "The page you’re looking for can’t be found. Error code: 404";
  public static readonly viewAll = {
    cardsLimit: 4,
  };

  public static readonly DPN = "DPN";
  public static readonly KXNG = "KXNG";
  public static readonly Images = "Images";
  public static readonly ExplorePage = "Explore";
  public static readonly ContributeToKx = "Contribute to KX";
  public static readonly KXAssets = "KX Assets";

  public static readonly DPNKXNG = "DPN KXNG";

  public static readonly ExploreCollectionPagePath = "/Collections";
  public static readonly PracticeProfilesPagePath = "/PracticeProfiles";

  public static readonly SearchReferralMethods = {
    StandardSearch: {
      MethodName: "Standard Search",
      SeachActivityType: Constants.SearchActivityTypes.Keyword,
    },
    RecentSearchTerm: {
      MethodName: "Recent Search Term",
      SeachActivityType: Constants.SearchActivityTypes.Keyword,
    },
    SuggestedFilter: {
      MethodName: "Suggested Filter",
      SeachActivityType: Constants.SearchActivityTypes.Filter,
    },
    SuggestedSearchTerm: {
      MethodName: "Suggested Search Term",
      SeachActivityType: Constants.SearchActivityTypes.DidYouMean,
    },
    NavigationMenu: { MethodName: "Navigation Menu", SeachActivityType: "" },
    SeeAll: { MethodName: "See All", SeachActivityType: "" },
    CardTags: { MethodName: "Card Tags", SeachActivityType: "" },
    PageTags: { MethodName: "Page Tags", SeachActivityType: "" },
    Saved: {
      MethodName: "Saved",
      SeachActivityType: Constants.SearchActivityTypes.Saved,
    },
    BrowserBack: {
      MethodName: Constants.SearchActivityTypes.BrowserBack,
      SeachActivityType: Constants.SearchActivityTypes.BrowserBack,
    },
  };

  public static readonly RHRSections = {
    CollectionLeaders: "Collection Leaders",
    RelatedConcepts: "Related Concepts",
    RelatedCollections: "Related Collections",
    RelatedLinks: "Related Links",
    ContributeToKx: "Contribute content to KX",
    WhatIsACollection: "What is a collection?",
  };
  //#endregion

  public static readonly TableIdsForTree = {
    ContentType: { tableId: 9, memberFirmId: 1 },
    CountryOfOrigin: { tableId: 4, memberFirmId: 1 },
    BusinessGlobal: { tableId: 3, memberFirmId: 1 },
    BusinessLocalCA: { tableId: 3, memberFirmId: 2348 },
    BusinessLocalUK: { tableId: 3, memberFirmId: 2494 },
    BusinessLocalUS: { tableId: 3, memberFirmId: 2768 },
    BusinessLocalDE: { tableId: 3, memberFirmId: 2470 },
    BusinessLocalAU: { tableId: 3, memberFirmId: 2243 },
    Industry: { tableId: 1, memberFirmId: 1 },
    Topic: { tableId: 6, memberFirmId: 1 },
    ContentTags: { tableId: 8, memberFirmId: 1 },
    ConceptTags: { tableId: 7, memberFirmId: 1 },
  };

  public static readonly NonSynapticaTypes = {
    TopicTags: "topicTags",
    ConceptsTags: "conceptTags",
    ContentTags: "contentTags",
  };

  public static readonly docStatusOptions = [
    { key: 1, text: "Draft" },
    { key: 2, text: "Contributed" },
    { key: 4, text: "Assigned" },
    { key: 5, text: "In Process" },
    { key: 3, text: "Published" },
    { key: 6, text: "Update Requested" },
    { key: 7, text: "Archived" },
    { key: 8, text: "Unarchived" },
  ];

  public static readonly docPublishingStatus = [
    { key: 2, text: "Approval Requested" },
    { key: 3, text: "Approval Recieved" },
    { key: 4, text: "Pending Info" },
    { key: 5, text: "Ready to QA" },
    { key: 6, text: "QA in Process" },
    { key: 7, text: "Rework Needed" },
    { key: 8, text: "On Hold" },
    { key: 9, text: "Received Info" },
    { key: 10, text: "None" },
    { key: 11, text: "Images Ready for QA" },
  ];

  public static readonly dashActivityContentMap = [
    { key: 1, text: "Contribute Qual" },
    { key: 2, text: "Approve Qual" },
    { key: 3, text: "Proposals" },
    { key: 4, text: "Deliverables" },
    { key: 5, text: "CXO Presentations" },
  ];

  public static readonly dashContributionConstant = [
    { key: 0, text: "Not Started" },
    { key: 1, text: "Draft" },
    { key: 2, text: "Contribute" },
    { key: 3, text: "Publish" },
    { key: 4, text: "Archive" },
    { key: 5, text: "Delete" },
    { key: 6, text: "" },
    { key: 7, text: "Archive" },
  ];

  public static readonly errorDetails = {
    ErrorCode404: "404",
    ErrorCodeMessage404: "The page you’re looking for cannot be found.",
    ErrorCode401: "401",
    ErrorCodeMessage401: "You are unauthorized to access this page.",
  };

  public static readonly Search_Tooltips = {
    KXSearchContentType: "KXSearchContent_Type",
    KXSearchInfoFileTypes: "KXSearchInfoFile_Types",
//KXSearchnew: "KXSearch_New",
    KXSearchImageTypes: "KXSearch_ImageTypes",
    KXSearchTopics: "KXSearch_Topics",
    KXSearchBadges: "KXSearch_Badges",
    KXSearchTags: "KXSearch_Tags",
    KXSearchDate: "KXSearch_Date",
    KXSearchBusinesses: "KXSearch_Businesses",
    KXSearchIndustries: "KXSearch_Industries",
    KXSearchGeographies: "KXSearch_Geographies"
  };
}
