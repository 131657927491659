import { LOAD_TAGS_DATA, TagsElementState, loadTagDataTypeActions } from '../types'

const initialState: TagsElementState = {
    tagsElements:[]
}


export function loadTagsDataReducer( state = initialState, action: loadTagDataTypeActions) {
  switch (action.type) {
    case LOAD_TAGS_DATA:
    return {
        ...state,
        tagsElements: action.payload
        }
    default:
      return state
  }
}
