import React, { useState, useRef } from "react";
import { Stack } from "office-ui-fabric-react";
import { CardData } from "../../../../../models/SharedModels";
import { OverflowSetBase } from "../../../../shared/overflowSetBase/overflowSetBase";
import QuickViewModal from "./QuickViewModal";

/**
 * Interface for PeopleModal component props
 */
export interface PeopleCardContentProps {
  slider?: boolean;
  card: any;
  categoryMove?: (e: any, cardData: CardData) => void;
  chosenItems?: any[];
  openModalEdit?: any;
}

/**
 * PeopleModal Component
 *
 * This component represents the people detail section for Quick View popup 
 * based upon diff. sources within results section in 'Prompt by KX'.
 */
export const PeopleModal: React.FunctionComponent<PeopleCardContentProps> = (
  props
) => {
  const target = useRef(null);
  const [isMenuOpened, setIsOpened] = useState(false);

  /**
   * Handler to show modal
   */
  const onShow = () => {
    setIsOpened(true);
  };

  /**
   * Handler to hide modal
   */
  const onHide = () => {
    setIsOpened(false);
  };

  props.card.footer = props.card.isHorizontal
    ? [props.card.allData["kxp_recentactivitiesmodified"]]
    : props.card.footer;

  return (
    <div className="ms-Grid-col" key={props.card.key} ref={target}>
      <Stack>
        {props.card.overflowItemsData &&
          props.card.overflowItemsData.length > 0 &&
          props.card.overflowItemsData[0].overflowItems && (
            <div className={`card-menu ${isMenuOpened ? "is-opened" : ""}`}>
              <QuickViewModal
                cardData={props.card}
                items={props.card.overflowItemsData[0].items}
                onShow={onShow}
                onHide={onHide}
                type={props.card.type}
                openModalEdit={props.openModalEdit}
              />
            </div>
          )}
      </Stack>
    </div>
  );
};
