import React, {  useState, useEffect, useRef } from 'react';
import '../searchBoxGlobal/searchBoxGlobal.scss';
import { IRecentSearch } from  '../../../models/IShared';
import { SharedService } from '../../../services/SharedService';
import {Constants} from '../../../helpers/Constants';
import { useSelector, useDispatch } from 'react-redux';
import  setShowRecent from '../../../store/actions/searchBoxActions';
import styles from './recentSearches.module.scss';

interface IProps {  
  searches: IRecentSearch[],
  activeSearch: number,
  isHomePage?: boolean,
  isContextualSearch?: boolean,
  onRecentSearchSelected: (x: IRecentSearch) => void
}

const RecentSearches: React.FC<IProps> = (props: IProps) => {

    const _showRecent:any = useSelector(state => state);

    const dispatch = useDispatch();

    const wrapperRef = useRef(null);

    function optionsListOnClick (item: IRecentSearch):any {    
        
        dispatch(setShowRecent(false));

        props.onRecentSearchSelected(item);    
    }   
           
    var renderOptions = () => {
        
        var _sharedService = SharedService.GetInstance();
        
        if(props.searches.length){
            
            return (                  
                <div className={`search-suggestions ${!props.isContextualSearch ? "" : 'contexualSearch'}`} ref={wrapperRef}>
                    { <div className="search-suggestions--default">
                    <span className="search-title">{_sharedService.Data.Labels[Constants.SearchLabels.SearchAgain]}</span>
                    <ul className="search-suggestions__list search-suggestions__list--default">
                        {props.searches.map((item:IRecentSearch, index) => {  
                            return (  
                                <li className={styles[(index === props.activeSearch ? 'listItemActive': 'listItem')]}  
                                    onClick={()=>optionsListOnClick(item)} 
                                    key={index} >
                                    <i className="icon-kx-explore"></i>                                    
                                    <span className={styles['long-and-truncated']}>
                                        {item.Phrase}
                                    </span> 
                                </li>
                            )
                        })}
                    </ul>
                    </div> }
                </div>                  
            );
        }
        
    };
    
    return (            
        <div>{ _showRecent["showRecentReducer"].showRecent && _showRecent["showRecentReducer"].isHomePage == props.isHomePage  ?  renderOptions() : '' }</div>
    );
}

export default RecentSearches; 