import React, { useState, useEffect, useRef } from "react";
import { useBoolean, useId } from '@fluentui/react-hooks';
import {
  Link,
  IOverflowSetItemProps,
  Spinner,
  SpinnerSize,
  Callout,
  Stack,
  StackItem,
  Text,
  FontWeights,
  mergeStyleSets
} from "@fluentui/react";
import { SharedService } from "../../../services/SharedService";
import { CardData } from "../../../models/SharedModels";
import { Constants } from "../../../helpers/Constants";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { ModalVideo } from "./modalVideo";
import { ModalBasic } from "./modalBasic.";
import { ModalImages } from "./modalImages";

export const ModalGeneric: React.FunctionComponent<{
  cardData: any;
  className: string;
  openModalEdit?: any;
  ifPin?: any;
  explainData?: any;
}> = (props) => {
  const [bottomSpace, setBottomSpace] = useState(null);
  const [cardData, setCardData] = useState<any>(props.cardData);
  const [metadataQuickView, setMetadataQuickView] = useState<any>(null);
  const [indexKey, setIndexKey] = useState<any>(0);
  const [badgesLoading, setBadgesLoading] = useState<boolean>(true);
  const bodyRef = useRef(null);
  const barRef = useRef<any>(null);
  let _sharedService: SharedService;
  const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);
  const [calloutId, setCalloutId] = useState<string>("");

  useEffect(() => {
    _sharedService = SharedService.GetInstance();
    let _configurations = SharedService.GetInstance().Data.Configuration;
    let ctCorelationJson = SharedService.GetInstance().GetConfigurationMapping(
      cardData.allData
    );
    let configCT = JSON.parse(_configurations.ContentTypesMapping);

    let ctMapping = configCT[ctCorelationJson.JsonMapping]

    var metadataQuickView =
      ctMapping.metadataQuickView;
    setMetadataQuickView(metadataQuickView);
  }, []);

  useEffect(() => {
    if (barRef && barRef !== null && barRef.current !== null) {
      setBottomSpace(barRef.current.clientHeight);
    } else {
      setBottomSpace(null);
    }
  }, [bodyRef, barRef]);

  useEffect(() => {
    var badgesNew;
    if (props.cardData?.allData?.badges?.length > 0 && props.cardData?.allData?.badgesDB == null) {
      let asyncWrapper = async () => {
        var applications = await SharedService.GetInstance().Data.Applications;
        var application = applications.filter((app: any) => { return app.Title == props.cardData.allData.source });
        badgesNew = props.cardData.allData.badges.map((badge: any) => {
          if (badge.id && badge.id != " ")
            return {
              ExternalId: badge.id,
              BadgeSource: application.length > 0 ? application[0].IdApp : ''
            }
        });

        SharedService.GetInstance().SiteSettings.Read.GetBadgesFromApi(badgesNew).then((data) => {
          if (data && data.length > 0) {
            var badgesNew2 = props.cardData.allData.badges.slice(0).reverse().map((badge: any, index: number) => {
              if (index < 3) {

                var exist = data.filter((badgeApi: any) => badgeApi.title == badge.name);
                if (exist && exist.length > 0)
                  return exist[0]
              }
            }).filter((data: any) => data)
            props.cardData.allData.badgesDB = badgesNew2;
          }
          else props.cardData.allData.badgesDB = [];

          setCardData(props.cardData);
          setBadgesLoading(false)
        });
      }
      asyncWrapper();
    }
    else {
      setCardData(props.cardData);
      setBadgesLoading(false)
    }
  }, [props.cardData]);

  const onRenderItem = (
    item: IOverflowSetItemProps,
    cardData: any
  ): JSX.Element => {
    return (
      <div>
        <Link
          role="menuitem"
          id={item.key === Constants.ActionsButtonsKeys.DownloadAll ? calloutId : ""}
          onClick={(e: any) => {
            switch (item.key) {
              case Constants.ActionsButtonsKeys.PinToCollection:
                pinUnPin(e, item, cardData);
                break;
              case Constants.ActionsButtonsKeys.CopyLink:
                //copyLink(e, item);
                e.persist();
                setTimeout(() => copyLink(e, item, true, true), 500);
                setTimeout(() => copyLink(e, item, false, false), 3000);
                break;
              case Constants.ActionsButtonsKeys.Follow:
                followUnFllow(e, item, cardData);
                break;
              case Constants.ActionsButtonsKeys.Email:
                CommonFunctions.doTracking(Constants.MiniCardActions.Email, cardData);
                window.open("mailto:" + cardData.allData[metadataQuickView.field7[0].field])
                break;
              case Constants.ActionsButtonsKeys.EditItem:
                CommonFunctions.editItem(cardData);
                break;
              case Constants.ActionsButtonsKeys.DeleteItem:
                CommonFunctions.deleteItem(cardData);
                break;
              case Constants.ActionsButtonsKeys.MetaData:
                CommonFunctions.copyMetadata(e, item, cardData);
                cardData.isQuickView = true;
                CommonFunctions.doTracking(
                  Constants.MiniCardActions.MetaData,
                  cardData
                );
                break;
              case Constants.ActionsButtonsKeys.DownloadAll:
                CommonFunctions.downloadAll(cardData);
                setIsCalloutVisible(true);
                break;
              case Constants.ActionsButtonsKeys.ExplainData:
                CommonFunctions.copyExplainData(props.explainData);
                break;
            }
          }}
        >
          {item.iconProps && (
            <i className={item.iconProps.className + " icon-kx"}></i>
          )}
          {item.name}
        </Link>
      </div>
    );
  };

  const copyLink = (
    event: any,
    original: any,
    second?: boolean,
    doTracking: boolean = true
  ) => {
    var card = cardData;
    card.isQuickView = true;
    original = CommonFunctions.copyLink(event, original, card, doTracking);
    var index = card.overflowItemsData[0].overflowItems.findIndex(
      (data: any) => data.key == Constants.ActionsButtonsKeys.CopyLink
    );
    card.overflowItemsData[0].overflowItems[index] = original;
    setCardData(card);
    if (second) setIndexKey(indexKey + 2);
    else setIndexKey(indexKey + 1);
  };

  const followUnFllow = (event: any, original: any, card: any) => {
    card.isQuickView = true;
    original = CommonFunctions.followUnFllow(
      event,
      original,
      card.allData.internalcontenttype,
      card.sourceId,
      card,
      card.email,
      true
    );
    var cardData = card;
    var index = cardData.overflowItemsData[0].overflowItems.findIndex(
      (data: any) => data.key == Constants.ActionsButtonsKeys.Follow
    );
    cardData.overflowItemsData[0].overflowItems[index] = original;
    setCardData(card);
    setIndexKey(indexKey + 1);
  };

  const pinUnPin = async (event: any, original: any, card: CardData) => {
    card.isQuickView = true;
    original = await CommonFunctions.pinUnPin(
      event,
      original,
      card,
      props.openModalEdit
    );
    //props.ifPin(card.ifPinCard)
    setCardData(card);
    setIndexKey(indexKey + 1);
    if (window.location.href.indexOf(Constants.Routing.PersonalCollection) != -1) {
      window.location.href = Constants.Routing.PersonalCollection;
    }
  };

  return (
    <div className={cardData.allData.source === Constants.Images && !badgesLoading ? "modalImagesScroll" : "modalDefault"}>
      {cardData.allData.internalcontenttype == "Video" && !badgesLoading ?
        <ModalVideo
          metadataQuickView={metadataQuickView}
          cardData={cardData}
          indexKey={indexKey}
          onRenderItem={onRenderItem}
        ></ModalVideo>
        : cardData.allData.source === Constants.Images && !badgesLoading ?
          <ModalImages
            metadataQuickView={metadataQuickView}
            cardData={cardData}
            indexKey={indexKey}
            onRenderItem={onRenderItem}
          ></ModalImages>
          : !badgesLoading ?
            <ModalBasic
              metadataQuickView={metadataQuickView}
              cardData={cardData}
              indexKey={indexKey}
              onRenderItem={onRenderItem}
              className={props.className}
              callout={isCalloutVisible}
              resetCallout={() => setIsCalloutVisible(false)}
              calloutId={(val: string) => setCalloutId(val)}
            ></ModalBasic>
            : <Spinner size={SpinnerSize.small} />}
    </div>
  );
};