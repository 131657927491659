import {  ADD_DATA_TO_COLLECTION, CollectionActionsTypeActions, DPNActionsTypeActions } from "../types";
import { CardData } from "../../models/SharedModels";

// TypeScript infers that this function is returning SendMessageAction

export function  DPNActions(type: any,cardsData: CardData[]):  DPNActionsTypeActions{
  return {
    type: type,
    payload: cardsData
  }
}


