import { v4 as uuidv4 } from "uuid";
import { Attachment } from "../models/SharedModels";
import { SharedService } from "./SharedService";
import { CommonFunctions } from "../helpers/CommonFunctions";


const chunkSize = 1048576 * 3; //its 3MB, increase the number measure in mb

const _webAPIUrl = SharedService.documentsWebApiUrl;


export const uploadFile = async (file: File, kamDocumentId: number) => {
  let counter = 0;
  let beginingOfTheChunk = 0;
  let endOfTheChunk = chunkSize;
  let fileGuid = "";
  let fileSize = 0;
  let chunkCount = 0;
  const _file = file;
  fileSize = _file.size;
  const _totalCount = _file.size > 0 ?
    _file.size % chunkSize == 0
      ? _file.size / chunkSize
      : Math.floor(_file.size / chunkSize) + 1 : 1; // Total count of chunks will have been upload to finish the file
  chunkCount = _totalCount;
  const _fileID = uuidv4();
  //   + "." + _file.name.split(".").pop();
  fileGuid = _fileID;
  let fileName = _file.name
  const chunkUploadPromoises: Promise<void>[] = [];
  let request: any;
  let allFiles: { guid: string; name: string, fileSize: number, documentId: number, response?: string | undefined }[] = [];
  for (var i = 1; i <= chunkCount; i++) {
    let status;
    let fileChunkedOffsetIv;
    if (i === 1 && chunkCount > 1) {
      status = 0
      fileChunkedOffsetIv = 0
    } else if (i === chunkCount && chunkCount === 1) {
      status = 3
      fileChunkedOffsetIv = beginingOfTheChunk
    }
    else if (i === chunkCount) {
      status = 2
      fileChunkedOffsetIv = beginingOfTheChunk
    } else {
      status = 1
      fileChunkedOffsetIv = beginingOfTheChunk
    }
    if (fileSize > 0) {
      counter = counter + 1;
      console.log('endOfTheChunkb', beginingOfTheChunk)
      console.log('endOfTheChunk', endOfTheChunk);
      var chunk = _file.slice(beginingOfTheChunk, endOfTheChunk);
      const _sharedService = SharedService.GetInstance();
      request = _sharedService.uploadFileChunks(fileGuid,fileName,kamDocumentId,status,fileChunkedOffsetIv,chunk).then((response: any) => {
        return response.json()
      })



      // chunkUploadPromoises.push(await request);
      // const findFile = allFiles.length > 0 ? allFiles.find((o, i) => {
      //   if (o.name === fileName) {
      //     return true
      //   }
      // }) ? allFiles : allFiles.push(...allFiles, { name: fileName, guid: fileGuid, fileSize: fileSize, documentId: kamDocumentId }) : allFiles.push(...allFiles, { name: fileName, guid: fileGuid, fileSize: fileSize, documentId: kamDocumentId });
      // beginingOfTheChunk = endOfTheChunk;
      // endOfTheChunk = endOfTheChunk + chunkSize;

      // if (counter <= chunkCount) {
      //   chunkUploadPromoises.push(request);

      // }
    }else{
      counter = counter + 1;
      console.log('endOfTheChunkb', beginingOfTheChunk)
      console.log('endOfTheChunk', endOfTheChunk);
      var chunk = _file.slice();
      const _sharedService = SharedService.GetInstance();
      request = _sharedService.uploadFileChunks(fileGuid,fileName,kamDocumentId,status,fileChunkedOffsetIv,chunk).then((response: any) => {
        return response.json()
      })
    }
    chunkUploadPromoises.push(await request);
    const findFile = allFiles.length > 0 ? allFiles.find((o, i) => {
      if (o.name === fileName) {
        return true
      }
    }) ? allFiles : allFiles.push(...allFiles, { name: fileName, guid: fileGuid, fileSize: fileSize, documentId: kamDocumentId }) : allFiles.push(...allFiles, { name: fileName, guid: fileGuid, fileSize: fileSize, documentId: kamDocumentId });
    beginingOfTheChunk = endOfTheChunk;
    endOfTheChunk = endOfTheChunk + chunkSize;

    if (counter <= chunkCount) {
      chunkUploadPromoises.push(request);

    }

  }

  return Promise.all(chunkUploadPromoises).then((res: any) => {
    let SPOIDS: number[] = []
    Promise.all(res.map(function (response: any) {
      console.log('CheckWork-->', response);
      if (SPOIDS.indexOf(response.data)) {
        SPOIDS.push(response.data)
      }
    }))
    return { ...allFiles, SPOIDS }
  });

};

const uploadChunk = async (
  chunk: Blob,
  counter: number,
  fileGuid: string,
  kamDocumentId: number,
  status: number,
  fileName: string
) => {
  try {
    const documentsWebApiUrl = SharedService.documentsWebApiUrl;
    fileName = encodeURIComponent(CommonFunctions.ReplaceSpecialCharsInFileName(fileName));//.replace(/[~#%&*:\"<>\/\\\?+|]/g, '_');
    const response = await fetch(
      `${_webAPIUrl}/FileUpload/UploadChunksSPO?id=${fileGuid}&fileName=${fileName}&kamDocumentId=${kamDocumentId}&status=${status}`,
      {
        method: "POST",
        body: chunk,
        headers: { "Content-Type": "application/json" },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

export const uploadCompleted = async (opObject: any) => {
  const _sharedService = SharedService.GetInstance();
  // const documentsWebApiUrl = SharedService.documentsWebApiUrl;
  // let tokenRequest = SharedService.apiScopes.tokenRequest;
  //     tokenRequest.account = SharedService.GetInstance().account;
  //     let loginRes =
  //     await SharedService.GetInstance().msalClient.acquireTokenSilent(
  //       tokenRequest
  //     );
  //     let Headers = {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + loginRes.accessToken,
  //       Accept: "text/plain"
  //     };
  // // const documentsWebApiUrl = "https://localhost:44346"
  // const response = await fetch(
  //   `${documentsWebApiUrl}/FileUpload/UploadCompleteSPO`,
  //   {
  //     method: "POST",
  //     headers:Headers,
  //     body: JSON.stringify(opObject),
  //   }
  // );
  const response = await _sharedService.uploadCompleted(opObject)
  console.log("Upload complete", response);
};

export const updateAttachmentDetailInDB = async (attachments: Attachment[]) => {
  const _sharedService = SharedService.GetInstance();
  // const documentsWebApiUrl = SharedService.documentsWebApiUrl;
  // let tokenRequest = SharedService.apiScopes.tokenRequest;
  //     tokenRequest.account = SharedService.GetInstance().account;
  //     let loginRes =
  //     await SharedService.GetInstance().msalClient.acquireTokenSilent(
  //       tokenRequest
  //     );
  //     let Headers = {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + loginRes.accessToken,
  //       Accept: "text/plain"
  //     };
  // const response = await fetch(
  //   `${documentsWebApiUrl}/Contribution/AddDocumentAttachments`,
  //   {
  //     method: "POST",
  //     headers: Headers,
  //     body: JSON.stringify(attachments),
  //   }
  // );
  const response = await _sharedService.updateAttachmentDetailsInDb(attachments);
  return response
};

export const GenerateNotification = async (
  emailId: string,
  documentId: number,
  failedAttachments:boolean
) => {
  const _sharedService = SharedService.GetInstance();
  const response = await _sharedService.GenerateAttachmentNotification(emailId,documentId,failedAttachments).then((response) => {
    console.log("Notification is generated", response);
  });
};
