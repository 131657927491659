import { SINGLE_REFINER, REFINERS } from '../types'
import { IRefiner } from '../../models/IShared'

const initialState: IRefiner[] = []

export default function refinersReducer(state = initialState, action: any): any {
  switch (action.type) {

    case REFINERS:
      return action.payload;

    case SINGLE_REFINER: 
      let tmpstate: any = state;
      
      tmpstate.map((x:any) => {
        if(x.id == action.payload.id){
          x = action.payload;
        }        
      } );

      return tmpstate
      

    default:
      return state
  }
}