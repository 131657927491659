import React from "react";
import ResponseBackground from "../../../prompt/media/conversation/responseBackground.png";
import ChatSparkle from "../../../prompt/media/conversation/chatSparkle.svg";
import "./ChatBotTypingLoader.scss";

const ChatBotTypingLoader = () => {
  return (
    <div
      className="botLoader_container"
      style={{ background: `url(${ResponseBackground})` }}
    >
      <img src={ChatSparkle} alt="sparkle-icon" />
      <div className="dot_container flex-centered">
        <div className="dots">
          <span className="dot" />
          <span className="dot" />
          <span className="dot" />
        </div>
      </div>
    </div>
  );
};

export default ChatBotTypingLoader;
