import React, { useEffect, useState } from "react";
import { Constants } from "../../../helpers/Constants";
import { SharedService } from "../../../services/SharedService";

const UserNotAllowed: React.FC = () => {
    const _sharedService = SharedService.GetInstance();
    const [contentInfo, setContentInfo] = useState<string>("");

    useEffect(() => {
        _sharedService.GetPageLevelRestrictions().then((response: any) => {
            if (response) {
                let restrictedPage : any;
                const urlPathName = window.location.pathname.toLocaleLowerCase();
                if (urlPathName.indexOf(Constants.Routing.NewContribution) !== -1) {
                    restrictedPage = response.find((r: any) => r.page == "NewContribution");
                } else if (urlPathName.indexOf(Constants.Routing.EditFormID) !== -1) {
                    restrictedPage = response.find((r: any) => r.page == "EditForm");
                } else if (urlPathName.indexOf(Constants.Routing.displayPageID) !== -1) {
                    restrictedPage = response.find((r: any) => r.page == "DisplayPage");
                } else if (urlPathName.indexOf(Constants.Routing.publisherDashBoard) !== -1) {
                    restrictedPage = response.find((r: any) => r.page == "PublishingDashboard");
                } else if (urlPathName.indexOf(Constants.Routing.myKxContribution) !== -1) {
                    restrictedPage = response.find((r: any) => r.page == "MyKx");
                }

                if (restrictedPage) {
                    setContentInfo(restrictedPage.contentInfo);
                }
            }
        })
    }, [window.location.pathname])

    return (
        <div style={{ "padding": "120px", "textAlign": "center", "fontSize": "14px"}}>
            {contentInfo != "" && contentInfo != null ? 
            <p dangerouslySetInnerHTML={{
                __html: contentInfo
            }}></p> 
            : ""}
        </div>
    );
};

export default UserNotAllowed;
