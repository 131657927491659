export class KXDocumentConstants {
  public static readonly General = {
    //QAWebApiUrl: "https://localhost:44320",
    DevWebApiUrl: "https://kxdocumentapidev.deloitteresources.com",
    QAWebApiUrl: "https://kxdocumentapiqa.kx.deloitte",
    QA2WebApiUrl: "https://kxdocumentapiqa2.kx.deloitte",
    INTWebApiUrl: "https://kxdocumentapiint.kx.deloitte",
    StageWebApiUrl: "https://kxdocumentapistage.kx.deloitte",
    ProdWebApiUrl: "https://kxdocumentapi.kx.deloitte",
    DevkxcentralApiUrl: "https://devkxcentralapi.deloitteresources.com",
    QAkxcentralApiUrl: "https://qakxcentralapi.kx.deloitte",
    QA2kxcentralApiUrl: "https://qakxcentralapi.kx.deloitte",
    INTkxcentralApiUrl: "https://qakxcentralapi.kx.deloitte",
    StagekxcentralApiUrl: "https://stagekxcentralapi.kx.deloitte",
    ProdkxcentralApiUrl: "https://kxcentralapi.kx.deloitte",
    DevUiUrl: "https://kxnextgendevui.deloitteresources.com",
    QAUiUrl: "https://qa.kx.deloitte",
    QA2UiUrl: "https://qa2.kx.deloitte",
    INTUiUrl: "https://int.kx.deloitte",
    StageUiUrl: "https://stage.kx.deloitte",
    ProdUiUrl: "https://kx.deloitte",
    DevImgExtUrl: "https://devimageextractorapi.kx.deloitte",
    QAImgExtUrl: "https://qaimageextractorapi.kx.deloitte",
    QA2ImgExtUrl: "https://qaimageextractorapi.kx.deloitte",
    INTImgExtUrl: "https://devimageextractorapi.kx.deloitte",
    StageImgExtUrl: "https://stageimageextractorapi.kx.deloitte",
    ProdImgExtUrl: "https://imageextractorapi.kx.deloitte",
  };
}
