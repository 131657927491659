import React, { useState } from "react";
import { Link } from "office-ui-fabric-react";
import { TooltipBase } from "../tooltipBase/tooltipBase";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { SharedService } from "../../../services/SharedService";
import { Constants } from "../../../helpers/Constants";
import { IRHRTracker } from "../../../models/IAnalytics";
import { AnalyticsService } from "../../../services/AnalyticsService";

export interface TagListProps {
  tagListData: any;
  itemsVisible?: number;
  maxItemsToShow?: number;
  counter?: boolean;
  clickToSearch?: boolean;
  sectionName?: string;
}

export const TagList: React.FunctionComponent<TagListProps> = (props) => {
  const [isShown, setVisibility] = useState(false);
  const itemsVisible = props.itemsVisible ? props.itemsVisible : 1000;
  const maxItemsToShow = props.maxItemsToShow || 1000;
  const redirect = (tag: any) => {
    if (props.sectionName == Constants.RHRSections.RelatedConcepts) {
      let RHRTracker: IRHRTracker = {
        section: props.sectionName || "",
        subsection: "",
        title: tag.text,
        url: tag.url || "",
      };
      AnalyticsService.trackRHR(RHRTracker);
    }

    window.location.href = tag.url;
  };

  return (
    <div>
      <div className="tags-container">
        {props.tagListData.map((tag: any, i: any) => {
          if (tag.name || tag.text)
            if (i < itemsVisible) {
              return (
                <TooltipBase
                  content={tag.text ? tag.text : tag.name}
                  key={tag.key ? tag.key : tag.fullPath}
                >
                  {props.clickToSearch ? (
                    // undo filter by concepts filter
                    // <Link onClick={() => window.location.href = Constants.Routing.Search+'?r=12:' + encodeURIComponent(tag.text) + "&mode=PageTag"} className="tag-inline">{tag.text ? tag.text : tag.name}</Link> :
                    <Link onClick={() => redirect(tag)} className="tag-inline">
                      {tag.text ? tag.text : tag.name}
                    </Link>
                  ) : (
                    <Link
                      href={tag.url ? tag.url : tag.fullPath}
                      className="tag-inline notClickable"
                    >
                      {tag.text ? tag.text : tag.name}
                    </Link>
                  )}
                </TooltipBase>
              );
            } else {
              return (
                isShown &&
                i < maxItemsToShow && (
                  <TooltipBase
                    content={tag.text ? tag.text : tag.name}
                    key={tag.key ? tag.key : tag.fullPath}
                  >
                    {props.clickToSearch ? (
                      <Link
                        onClick={() => redirect(tag)}
                        className="tag-inline"
                      >
                        {tag.text ? tag.text : tag.name}
                      </Link>
                    ) : (
                      <Link
                        href={tag.url ? tag.url : tag.fullPath}
                        className="tag-inline notClickable"
                      >
                        {tag.text ? tag.text : tag.name}
                      </Link>
                    )}{" "}
                  </TooltipBase>
                )
              );
            }
        })}
      </div>
      {props.tagListData.length > itemsVisible && (
        <span className="more-link" onClick={() => setVisibility(!isShown)}>
          {isShown ? (
            <Icon iconName="CaretUpSolid8" />
          ) : (
            <Icon iconName="CaretDownSolid8" />
          )}
          {isShown
            ? SharedService.GetInstance().Data.Labels[
                Constants.LabelKeys.curatedCollectionShowLess
              ]
            : SharedService.GetInstance().Data.Labels[
                Constants.LabelKeys.curatedCollectionShowMore
              ]}
          {isShown && props.counter
            ? ""
            : ` (${
                Math.min(props.tagListData.length, maxItemsToShow) -
                itemsVisible
              })`}
        </span>
      )}
    </div>
  );
};
