import React, { useState, useRef, useEffect } from "react";
import styles from "./newContribution.module.scss";
import AdditionalNCForm from "./additionalNCForm/additionalNCForm";
import BasicNCForm from "./basicNCForm/basicNCForm";
import { useBoolean } from "@fluentui/react-hooks";
import { PopupDialog } from "../../shared/popupDialog/popupDialog";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { UploadableFile } from "../../shared/multipleFileUpload/multipleFileUpload";
import { ITag } from "@fluentui/react/lib/Pickers";
import { v4 as uuidv4 } from "uuid";
import {
  GenerateNotification,
  updateAttachmentDetailInDB,
  uploadFile,
  uploadCompleted,
} from "./../../../services/SharePointFileUploadService";
import { SharedService } from "../../../services/SharedService";
import { ToastNotification } from "../../shared/toastNotification/toastNotification";
import { useHistory } from "react-router-dom";
import { Constants } from "../../../helpers/Constants";
import { Loader } from "../../shared/loader/loader";
import { useAttachementNotificationContext } from "../../shared/contextAPIs/AttachmentNotificationContext";
import { useParams } from "react-router-dom";
import {
  IDocumentContributionTracker,
  IPageTracker,
  IPersonalizationTracker,
} from "../../../models/IAnalytics";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { useSelector } from "react-redux";
import ProgressToast from "../progressToast/progressToast";
import { PromptState } from "msal/lib-commonjs/utils/Constants";

export interface basicFormData {
  title: string;
  dashActivityId: number | null;
  dashActivityName: string;
  contentType: any;
  description?: string;
  publishingData: any;
  clientName?: string;
}

const NewContribution: React.FC = () => {
  let history = useHistory();
  const _userNeeds: any = useSelector((s: any) => s.userNeedsReducer);
  const { draftid }: { draftid: any } = useParams();
  const [draftData, setDraftData] = useState<any>(null);
  const [hideDialogSave, { toggle: toggleHideDialogSave }] = useBoolean(true);
  const [hideDialogCancel, { toggle: toggleHideDialogCancel }] =
    useBoolean(true);
  const [hideDialogSubmit, { toggle: toggleHideDialogSubmit }] =
    useBoolean(true);
  const [filesToBeUploaded, setfilesToBeUploaded] = useState<UploadableFile[]>(
    []
  );
  let labelsKey = SharedService.scopeNotesLabelKey;
  var labelsData: string | null = localStorage.getItem(labelsKey);
  var labels = labelsData != null ? JSON.parse(labelsData) : {};

  const [requiredAlert, setRequiredAlert] = useState(false);
  const [filesizeAlert, setFileSizeAlert] = useState(false);
  const [fileDuplicateNameAlert, setfileDuplicateNameAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { attachmentNotification, setattachmentNotification } =
    useAttachementNotificationContext();
  const _sharedService = SharedService.GetInstance();
  const [userRoleGroups, setUserRoleGroups] = useState<any>(null);
  const [userPrivileges, setUserPrivileges] = useState<any>(null);
  const userdetails = _sharedService.Data.CurrentUser;
  const [BasicFormData, setBasicFormData] = useState<basicFormData>();
  const [descriptionData, setdescriptionData] = useState<string>();
  const [AdditionalFormData, setAdditionalFormData] = useState<any>({
    contentdate: null,
    contentTypeId: null,
    clientId: null,
    clientName: "",
    qualId: null,
    qualName: null,
    countryofOriginId: null,
    authorandContactsforDocuments: null,
    publishInstructions: "",
    primaryClientServiceId: null,
    primaryLocalClientServiceId: null,
    primaryIndustryId: null,
    secondaryClientServiceId: null,
    secondaryLocalClientServiceId: null,
    secondaryIndustryId: null,
    secondaryCountryOfOriginId: null,
  });
  const defaultAuthorandContacts = [
    {
      user: {
        firstName: userdetails.kxp_pdiempattributespreffirstname,
        lastName: userdetails.kxp_pdiempattributespreflastname,
        middleName: "",
        email: userdetails.kxp_email,
        deloitteId: "",
        empGuid: "",
        displayName: userdetails.title,
        fullName: userdetails.fullName,
        lastModifiedDate: new Date(),
      },
      peopleType: 1,
    },
    {
      user: {
        firstName: userdetails.kxp_pdiempattributespreffirstname,
        lastName: userdetails.kxp_pdiempattributespreflastname,
        middleName: "",
        email: userdetails.kxp_email,
        deloitteId: "",
        empGuid: "",
        displayName: userdetails.title,
        fullName: userdetails.fullName,
        lastModifiedDate: new Date(),
      },
      peopleType: 2,
    },
    {
      user: {
        firstName: userdetails.kxp_pdiempattributespreffirstname,
        lastName: userdetails.kxp_pdiempattributespreflastname,
        middleName: "",
        email: userdetails.kxp_email,
        deloitteId: "",
        empGuid: "",
        displayName: userdetails.title,
        fullName: userdetails.fullName,
        lastModifiedDate: new Date(),
      },
      peopleType: 3,
    }
  ];
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);
  const [oldUserNeed, setOldUserNeed] = useState(_userNeeds);
  const [fileUploadToast, setFileUploadToast] = useState<boolean>(false);
  localStorage.setItem("IsErrorBannerVisible", "No");
  const [loadContributionForm, setLoadContributionForm] = useState<boolean>(false);
  const [authorInvalidText, setAuthorInvalidText] = useState<string>("");
  const [contactInvalidText, setContactInvalidText] = useState<string>("");
  const [invalidAuthorAlert, setInvalidAuthorAlert] = useState<boolean>(false);
  const [documentTitle, setDocumentTitle] = useState<string>("");
  let isDataLoaded: boolean = false;
  useEffect(() => {
    CommonFunctions.getAllTagsDataInASingleCall();
    CommonFunctions.GetAllApiTrigger();
    if (!loadContributionForm) {
      setInterval(() => {
        if (!isDataLoaded) {
          setTaxonomyData()
        }
      }, 1000)
    }
  }, [])

  const setTaxonomyData = () => {
    if (localStorage.getItem("__dkm__GetAllData")) {
      setLoadContributionForm(true);
      isDataLoaded = true;
    }
  }

  useEffect(() => {
    _sharedService.GetRawUserRoleGroupsAndPrivileges().then((response: any) => {
      if (response != null) {
        setUserRoleGroups(response.roleGroups);
        setUserPrivileges(response.userPrivileges);
      }
    });
    if (
      documentTitle ||
      descriptionData ||
      (filesToBeUploaded &&
        filesToBeUploaded.length) > 0
    ) {
      setRequiredAlert(false);
    }
  }, [descriptionData, documentTitle, filesToBeUploaded,]);

  useEffect(() => {
    setOldUserNeed(_userNeeds);
    if (draftid != undefined) {
      let documentId = parseInt(draftid);
      _sharedService.GetContributedDataById(documentId).then((response) => {
        if (response) {
          if (response.status == 1) {
            setDraftData(response);
            let authorandContactsobjarr: any[] = [];
            response.authorandContactsforDocuments?.map((item: any) => {
              var authorandContactsobj = {
                peopleType: item.peopleType,
                user: item.user,
              };
              authorandContactsobjarr.push(authorandContactsobj);
            });
            let exAuthorsString = response.externalAuthors !== null && response.externalAuthors !== "" ? response.externalAuthors.split(';') : [];
            if (exAuthorsString && exAuthorsString.length > 0) {
              exAuthorsString?.map((item: any) => {
                var authorandContactsobj = {
                  peopleType: 1,
                  user: {
                    firstName: "",
                    lastName: "",
                    middleName: "",
                    email: item,
                    deloitteId: "",
                    empGuid: "",
                    displayName: item,
                    fullName: "",
                    lastModifiedDate: new Date(),
                  },
                  isExternalAuthor: true
                };
                authorandContactsobjarr.push(authorandContactsobj);
              })
            }
            var basicNCdata = {
              title: response.title,
              description: response.description,
              dashActivityId: response.dashActivityId,
              dashActivityName: response.dashActivityName,
              contentType: response.contentType,
              publishingData: response.publishInstructions,
              clientName:response.clientName,
            };
            var AdditionalNCdata = {
              contentdate:
                response.contentDate != null ? response.contentDate : null,
              contentTypeId: response.contentTypeId,
              clientId: response.clientId,
              clientName: response.clientName,
              qualId: response.qualId,
              qualName: response.qualName,
              countryofOriginId: response.countryofOriginId,
              authorandContactsforDocuments: authorandContactsobjarr,
              publishInstructions: response.publishInstructions,
              primaryClientServiceId: response.primaryGlobalClientServiceId,
              primaryLocalClientServiceId: response.primaryLocalClientServiceId,
              primaryIndustryId: response.primaryGlobalIndustryId,
              secondaryClientServiceId:
                response.secondaryGlobalClientServiceIds,
              secondaryLocalClientServiceId:
                response.secondaryLocalClientServiceIds,
              secondaryIndustryId: response.secondaryGlobalIndustryIds,
              secondaryCountryOfOriginId: response.secondaryCountryOfOriginIds,
              contributedDate: response.contributedDate,
              contributors: response.contributors
            };
            setDocumentTitle(response.title);
            setBasicFormData(basicNCdata);
            setAdditionalFormData(AdditionalNCdata);
          } else {
            window.location.href = `/PageNotFound?${Constants.QueryStringParameters.requestUrl}=${window.location.href}`;
          }
        }
      });
    } else {
      let documentContributionTracker: IDocumentContributionTracker =
        {} as IDocumentContributionTracker;
      documentContributionTracker.name =
        Constants.TrackerDocumentContribution.Name;
      documentContributionTracker.state =
        Constants.TrackerDocumentContribution.Start;
      AnalyticsService.trackDocumentContribution(documentContributionTracker);
    }
    localStorage.setItem("IsErrorBannerVisible", "No");
  }, []);

  useEffect(() => {
    console.log("userNeeds", _userNeeds);
    if (_userNeeds !== false) {
      effectFunc();
    }
  }, [_userNeeds.userNeeds]);

  const addNewClient = async (client: ITag[] | undefined) => {
    const userData = {
      loggedUser: userdetails.kxp_email?.split("@")[0],
      firstName: userdetails.kxp_pdiempattributespreffirstname,
      lastName: userdetails.kxp_pdiempattributespreflastname,
      email: userdetails.kxp_email,
    };
    const newClient = client ? client[0].name : "";
    let addNewClient;
    if (newClient != "") {
      addNewClient = await _sharedService.AddNewClient(newClient, userData)
      return addNewClient;
    }
  };

  const removeAttachment = (attachment: any) => {
    console.log("remove attachment");
    let newDraftattachmentDetails: any[] = [];
    draftData.attachmentDetails?.map((item: any) => {
      if (attachment.attachmentId !== item.attachmentId) {
        newDraftattachmentDetails.push(item)
      }
    });
    draftData.attachmentDetails = newDraftattachmentDetails;
    console.log("draftData.attachmentDetails", draftData.attachmentDetails)
  }

  const onSubmitorSaveDraft = async (type: string) => {
    let totalAttachmentSize = 0;
    filesToBeUploaded?.map((fileValue: UploadableFile) => {
      let fileSize = fileValue.file.size;
      totalAttachmentSize = fileSize + totalAttachmentSize;
    });
    if (
      draftData &&
      draftData != null &&
      draftData.attachmentDetails?.length > 0 &&
      filesToBeUploaded.length > 0
    ) {
      for (let i = 0; i < filesToBeUploaded.length; i++) {
        const duplicate = draftData.attachmentDetails?.filter(
          (attachment: any) => {
            return attachment.attachmentName == filesToBeUploaded[i].file.name;
          }
        );
        if (duplicate && duplicate.length > 0) {
          setfileDuplicateNameAlert(true);
          return;
        }
      }
    }
    const uniquefilename = new Set(filesToBeUploaded?.map((v) => v.file.name));
    // if (
    //   AdditionalFormData.unSavedClient &&
    //   AdditionalFormData.clientName != AdditionalFormData.unSavedClient
    // ) {
    //   addNewClient([
    //     {
    //       name: AdditionalFormData.unSavedClient,
    //       key: "",
    //     },
    //   ]);
    // }
    if ((authorInvalidText && authorInvalidText.length > 0) || (contactInvalidText && contactInvalidText.length > 0)) {
      setInvalidAuthorAlert(true);
      return;
    } else {
      setInvalidAuthorAlert(false);
    }
    if (Math.ceil(totalAttachmentSize / (1024 * 1024)) > 500.1) {
      setFileSizeAlert(true);
    } else if (uniquefilename.size < filesToBeUploaded.length) {
      setfileDuplicateNameAlert(true);
    } else if (
      draftid &&
      draftData &&
      (!draftData.attachmentDetails ||
        (draftData.attachmentDetails &&
          draftData.attachmentDetails.length == 0)) &&
      filesToBeUploaded.length <= 0 &&
      !documentTitle &&
      !descriptionData
    ) {
      setRequiredAlert(true);
    } else if (
      !draftid &&
      !documentTitle &&
      !descriptionData &&
      filesToBeUploaded.length <= 0
    ) {
      setRequiredAlert(true);
    } else {
      // setRequiredAlert(BasicFormData?.dashActivityName != null && descriptionData && documentTitle != null ? false : true);
      if (
        documentTitle ||
        descriptionData ||
        filesToBeUploaded.length > 0 ||
        (draftData &&
          draftData.attachmentDetails &&
          draftData.attachmentDetails.length > 0)
      ) {
        if (
          documentTitle ||
          descriptionData ||
          filesToBeUploaded.length > 0 ||
          (draftData &&
            draftData.attachmentDetails &&
            draftData.attachmentDetails.length > 0)
        ) {
          setIsLoading(true);
          let newClientDetails: any
          if (AdditionalFormData.newClient) {
            newClientDetails = await addNewClient(AdditionalFormData.newClient)
            if (newClientDetails) {
              AdditionalFormData.clientName = newClientDetails[0].clientName;
              AdditionalFormData.clientId = newClientDetails[0].clientId;
            }
          }
          let externalAuthorsList = AdditionalFormData.authorandContactsforDocuments?.filter((item: any) => { return item?.isExternalAuthor });
          let externalAuthors: string[] = [];
          if (externalAuthorsList && externalAuthorsList.length > 0) {
            externalAuthorsList?.map((item: any) => { externalAuthors.push(item.user.displayName) });
          }
          AdditionalFormData.authorandContactsforDocuments = AdditionalFormData.authorandContactsforDocuments?.filter((item: any) => { return !item?.isExternalAuthor });

          var NCFormData: any = {
            documentId: draftid != undefined || draftid != null ? draftid : 0,
            title:
              documentTitle !== undefined ? documentTitle : "",
            description: descriptionData != undefined ? descriptionData : "",
            dashActivityId: BasicFormData?.dashActivityId,
            dashActivityName: BasicFormData?.dashActivityName,
            status: null,
            contributedBy: {
              firstName: userdetails.kxp_pdiempattributespreffirstname,
              lastName: userdetails.kxp_pdiempattributespreflastname,
              middleName: "",
              email: userdetails.kxp_email,
              deloitteId: "",
              empGuid: "",
              displayName: userdetails.title,
              fullName: userdetails.fullName,
              lastModifiedDate: new Date(),
            },
            contentTypeId: AdditionalFormData.contentTypeId,
            countryofOriginId: AdditionalFormData.countryofOriginId,
            clientId: AdditionalFormData.clientId,
            clientName: AdditionalFormData.clientName,
            qualId: AdditionalFormData.qualId,
            qualName: AdditionalFormData.qualName,
            authorandContactsforDocuments:
              AdditionalFormData.authorandContactsforDocuments == null
                ? defaultAuthorandContacts
                : AdditionalFormData.authorandContactsforDocuments,
            contentDate: AdditionalFormData.contentdate,
            publishInstructions: AdditionalFormData.publishInstructions,
            primaryGlobalClientServiceId:
              AdditionalFormData.primaryClientServiceId,
            primaryLocalClientServiceId:
              AdditionalFormData.countryofOriginId ? AdditionalFormData.primaryLocalClientServiceId : null,
            primaryGlobalIndustryId: AdditionalFormData.primaryIndustryId,
            secondaryGlobalClientServiceIds:
              AdditionalFormData.secondaryClientServiceId,
            secondaryLocalClientServiceIds:
              AdditionalFormData.secondaryLocalClientServiceId,
            secondaryGlobalIndustryIds: AdditionalFormData.secondaryIndustryId,
            secondaryCountryOfOriginIds: AdditionalFormData.secondaryCountryId,
            systemSource: "EndUserContribution",
            contributedDate: AdditionalFormData.contributedDate,
            externalAuthors: externalAuthors.join(';')
          };

          if (type == "submit") {
            if (userRoleGroups != null) {
              NCFormData.status =
                userRoleGroups.publishers ||
                  userRoleGroups.publishingTeamLeaders
                  ? 4
                  : 2;
              let priority = checkForWords(
                AdditionalFormData.publishInstructions
              )
                ? true
                : false;
              NCFormData.publishingPriority = priority ? "High" : null;
              NCFormData.contributingTeam = userRoleGroups.publishersDeliveryExcellence
                ? "Publishers - Delivery Excellence"
                : userRoleGroups.collectionCurators
                  ? "Collection Curators"
                  : userRoleGroups.knowledgeServicesProfessionals
                    ? "KS Professionals"
                    : null;
            }
          }
          if (type == "save") {
            NCFormData.status = 1;
          }

          if (draftData && draftData?.dashActivityId != BasicFormData?.dashActivityId) {
            removeActivity();
          }
          localStorage.setItem("IsErrorBannerVisible", "No");
          SharedService.GetInstance()
            .CreateDocument(NCFormData)
            .then((result) => {
              setIsLoading(false);
              if (type == "submit") {
                toggleHideDialogSubmit();
                setActivityStatus(result.documentId, 2);
                let documentContributionTracker: IDocumentContributionTracker =
                  {} as IDocumentContributionTracker;
                documentContributionTracker.name =
                  Constants.TrackerDocumentContribution.Name;
                documentContributionTracker.state =
                  Constants.TrackerDocumentContribution.Submit;
                AnalyticsService.trackDocumentContribution(
                  documentContributionTracker
                );
              }
              if (type == "save") {
                toggleHideDialogSave();
                setActivityStatus(result.documentId, 1);
              }
              const filesToBeUploadedPromises: any[] = [];

              if (filesToBeUploaded && filesToBeUploaded.length > 0) {
                setFileUploadToast(true);
                localStorage.setItem("UploadStatus", "InProcess");
                localStorage.setItem("IsErrorBannerVisible", "No");
                filesToBeUploaded.map((uploadableFile) => {
                  filesToBeUploadedPromises.push(
                    uploadFile(uploadableFile.file, result.documentId)
                  );
                });

                Promise.all(filesToBeUploadedPromises)
                  .then((response: any) => {
                    if (response) {
                      setUploadComplete(true);
                      setFileUploadToast(false);
                      let struct = [].concat.apply([], response);
                      let finalStruct: any = [].concat.apply([], struct);
                      let relativeUrls: string[] = [];
                      finalStruct.map((res: any) => {
                        let fileFormat = res[0].name.split(".");
                        if (
                          fileFormat[fileFormat.length - 1] == "pptx" ||
                          fileFormat[fileFormat.length - 1] == "docx" ||
                          fileFormat[fileFormat.length - 1] == "pdf"
                        ) {
                          let relativeUrl = `${SharedService.spSiteUrl}/${SharedService.SPAttachmentLibrary
                            }/${res[0].documentId.toString()}/${res[0].name}`;
                          relativeUrls.push(relativeUrl);
                        }
                      });
                      const guidData = uuidv4();
                      if (relativeUrls && relativeUrls.length > 0) {
                        let spoObject = {
                          queueGuid: guidData,
                          assetId:
                            finalStruct[0] && finalStruct.length > 0
                              ? finalStruct[0][0].documentId.toString()
                              : "",
                          docId: null,
                          docRelativeUrls: relativeUrls,
                          opType: 1,
                        };
                        if (NCFormData.status != 1) {
                          if (spoObject && spoObject.docRelativeUrls) {
                            uploadCompleted(spoObject).then((response) => { });
                          }
                        }
                      }

                      var attachments = finalStruct.map(
                        (item: any, index: number) => {
                          let data = item[0];
                          let fileName = CommonFunctions.ReplaceSpecialCharsInFileName(data.name)//.replace(/[~#%&*:\"<>\/\\\?+|]/g,"_");
                          const attachment: any = {
                            DocumentId: data.documentId,
                            AttachmentName: fileName,
                            TechnicalNameinBlob: data.guid,
                            //BlobUrl: `https://mypocs101.blob.core.windows.net/test/${item.documentId}/${item.guid}`,
                            BlobUrl: "",
                            IsDeleted: false,
                            IsDocumentPublished: false,
                            IsUploadSuccess: true,
                            CreatedBy: userdetails.kxp_email,
                            FileSize: data.fileSize,
                            // QueueGuid:guidData,
                            SpoId: item.SPOIDS[0],
                            SpoUrl:
                              SharedService.SpDomainUrl +
                              SharedService.spSiteUrl +
                              "/" +
                              SharedService.SPAttachmentLibrary +
                              "/" +
                              data.documentId +
                              "/" +
                              fileName,
                            orderforDisplay:
                              draftData != null &&
                                draftData.attachmentDetails &&
                                draftData.attachmentDetails.length > 0
                                ? draftData.attachmentDetails.length + index + 1
                                : index + 1,
                          };
                          return attachment;
                        }
                      );
                      if (NCFormData.status != 1 && relativeUrls.length > 0) {
                        let obj = {
                          documentId: result.documentId,
                          queueGuid: guidData,
                          requestedBy: "System",
                        };
                        _sharedService
                          .createAISuggestionsEntry(obj)
                          .then((res) => { });
                      };
                      let failedAttachments = false;
                      attachments.map((attachament:any,index:number)=>{
                        if(attachament.SpoId == 0){
                          failedAttachments = true
                          attachments.splice(index,1)
                        }
                      })
                      updateAttachmentDetailInDB(attachments).then(
                        async (response) => {
                          localStorage.setItem("UploadStatus", "Complete");
                          await GenerateNotification(
                            userdetails.kxp_email,
                            attachments[0].DocumentId,
                            failedAttachments
                          );
                          const updatedNotifications =
                            await _sharedService.GetAttachmentNotifications();
                          setattachmentNotification(updatedNotifications);
                        }
                      );
                      setFileUploadToast(false);
                    }
                  })
                  .catch((error) => {
                    if (error) {
                      localStorage.setItem("IsErrorBannerVisible", "Yes");
                    }
                  });
              } else {
                localStorage.setItem("UploadStatus", "Complete");
                localStorage.setItem("IsErrorBannerVisible", "No");
                setUploadComplete(true);
                let relativeUrls: string[] = [];
                if (
                  draftData != null &&
                  draftData.attachmentDetails &&
                  draftData.attachmentDetails.length > 0
                ) {
                  draftData.attachmentDetails.map((res: any) => {
                    let fileFormat = res.attachmentName.split(".");
                    if (
                      fileFormat[1] == "pptx" ||
                      fileFormat[1] == "docx" ||
                      fileFormat[1] == "pdf"
                    ) {
                      let relativeUrl = `${SharedService.spSiteUrl}/${SharedService.SPAttachmentLibrary
                        }/${draftid.toString()}/${res.attachmentName}`;
                      relativeUrls.push(relativeUrl);
                    }
                  });
                  const guidData = uuidv4();
                  if (relativeUrls.length > 0) {
                    let spoObject = {
                      queueGuid: guidData,
                      assetId: draftid.toString(),
                      docId: null,
                      docRelativeUrls: relativeUrls,
                      opType: 1,
                    };
                    if (spoObject && spoObject.docRelativeUrls) {
                      uploadCompleted(spoObject).then((response) => { });
                    }
                    let obj = {
                      documentId: draftid,
                      queueGuid: guidData,
                      requestedBy: "System",
                    };
                    _sharedService
                      .createAISuggestionsEntry(obj)
                      .then((res) => { });
                  }
                }
              }
            })
            .catch((error) => {
              if (error) {
                localStorage.setItem("IsErrorBannerVisible", "Yes");
              }
            });
        }
      } else {
        setRequiredAlert(true);
      }
    }
  };

  const removeActivity = async () => {
    let obj: any = {
      KMIP: Number(draftData.documentId),
      status: "Not Started",
      actid: draftData.dashActivityId
    }
    await _sharedService.removeActivityIntegratedDocument(obj);
  }

  const setActivityStatus = (value: any, status: any) => {
    if (BasicFormData?.dashActivityId) {
      let date = new Date();
      let dateData: string = date.toISOString();
      const contributionStatus: any = Constants.dashContributionConstant.find(
        (i) => i.key === status
      );
      let typo = {
        KMIP: value,
        status: contributionStatus.text,
        actid: BasicFormData?.dashActivityId,
        contributionurl: "",
        ContributionDate: dateData,
      };
      _sharedService.createActivityIntegratedDocument(typo);
    }
  };

  const onCancelDraft = () => {
    toggleHideDialogCancel();
  };

  const description = (value: string) => {
    let newrichtext: string = value
    let replaceDescData: any;
    if (newrichtext && newrichtext.length > 0) {
      replaceDescData = newrichtext.replace('﻿<span class="ql-cursor">﻿</span>', '');
    }
    setdescriptionData(replaceDescData);
  };

  const setNewTitle = (value: any) => {
    setDocumentTitle(value);
  }

  const getBasicForm = (value?: any) => {
    let data: basicFormData = {
      contentType: value.contentType,
      title: value.title,
      dashActivityId: value.dashActivityId,
      dashActivityName: value.dashActivityName,
      publishingData: value.publishingData,
    };
    setBasicFormData(data);
    if (value.contentType != null || undefined) {
      setAdditionalFormData({
        ...AdditionalFormData,
        contentTypeId: value.contentType.Id,
        publishInstructions: value.publishingData,
        clientName:value.clientName,
      });
    } else {
      setAdditionalFormData({
        ...AdditionalFormData,
        contentTypeId: null,
        publishInstructions: value.publishingData,
        clientName:value.clientName,
      });
    }
  };

  const getAdditionalForm = (value?: any) => {
    setAdditionalFormData(value);
  };

  const getfilesToBeUploaded = (files: any) => {
    setfilesToBeUploaded(files);
  };

  const onClickSaveOK = () => {
    history.push(Constants.Routing.myKxContribution);
    toggleHideDialogSave();
  };

  const onClickSubmitOK = () => {
    let gotoPublisherDashboard =
      userRoleGroups?.publishers ||
      userRoleGroups?.productAdministrators ||
      userRoleGroups?.publishingTeamLeaders;
    history.push(
      gotoPublisherDashboard
        ? Constants.Routing.publisherDashBoard
        : Constants.Routing.myKxContribution
    );
    toggleHideDialogSubmit();
  };

  const onClickCancelYes = () => {
    history.push(Constants.Routing.myKxContribution);
  };

  const onClickCancelNo = () => {
    toggleHideDialogCancel();
  };

  const toastNotificationClicked = () => {
    setRequiredAlert(false);
  };

  const filealertnotificationClicked = () => {
    setFileSizeAlert(false);
  };

  const duplicatefilenotificationClicked = () => {
    setfileDuplicateNameAlert(false);
  };

  const invalidAuthorAlertCicked = () => {
    setInvalidAuthorAlert(false);
  }

  const checkForWords = (statement: string) => {
    if (statement == null || statement == "")
      return false;
    const checkWords = ["high", "priority", "publish", "target", "date", "urgent"];
    let convertedStatement: any = statement.toLowerCase();
    convertedStatement = convertedStatement.replace(/[.,-_]/g, ' ').split(" ");
    for (let i = 0; i < checkWords.length; i++) {
      if (convertedStatement.includes(checkWords[i])) {
        return true;
      }
    }
    return false;
  };

  const effectFunc = () => {
    CommonFunctions.SetLanguage();

    let view = CommonFunctions.URLSearch().get(
      Constants.QueryStringParameters.View
    );
    let currentpage = +(
      CommonFunctions.URLSearch().get(Constants.QueryStringParameters.Page) ||
      "1"
    );

    _sharedService.Actions.Get.GetMyInterests().then((res) => {
      if (res != null && res.length > 0) {
        _sharedService.Data.CurrentUser.isInterest = true;
      }
    });
    //analytics
    if (_userNeeds && _userNeeds.userNeeds && _userNeeds.userNeeds.text) {
      AnalyticsService.resetPageContext();
      let pageTracker: IPageTracker = {} as IPageTracker;
      let personalizationTracker: IPersonalizationTracker =
        {} as IPersonalizationTracker;
      pageTracker = {
        pageInfo: {
          breadCrumbs: "",
          contentID: "",
          contentTitle: Constants.PageTitles.NewContribution,
          genericPageName: Constants.PageTitles.DocumentContribution,
          language: _sharedService.lang,
          sourceContentType: "",
          sourceSystem: "",
          docAge: `${AdditionalFormData.kxA_ContentDate !== null &&
            AdditionalFormData.kxA_ContentDate === ""
            ? CommonFunctions.monthDiff(
              new Date(AdditionalFormData.kxA_ContentDate),
              new Date()
            )
            : ""
            }`,
          isPublisher:
            userRoleGroups !== null
              ? userRoleGroups.publishers ||
              userRoleGroups.productAdministrators ||
              userRoleGroups.publishingTeamLeaders ||
              userRoleGroups.collectionCurators ||
              userRoleGroups.knowledgeServicesProfessionals ||
              userRoleGroups.publishersDeliveryExcellence
              : false,
          primaryCategory: Constants.PageTitles.KXDocuments,
          pageName: `KX|Document Contribution`,
        },
      };
      personalizationTracker = {
        need: _userNeeds.userNeeds.text,
        hasInterests: false,
      };

      AnalyticsService.trackPageLoad(pageTracker, personalizationTracker);
    }
  };

  useEffect(() => {
    if (
      _sharedService.Data.Labels[
      Constants.LabelKeys.PageTitleKXDNewContribution
      ]
    )
      document.title =
        _sharedService.Data.Labels[
        Constants.LabelKeys.PageTitleKXDNewContribution
        ];
    window.addEventListener("beforeunload", unloadCallback);
    // return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const unloadCallback = (event: any) => {
    if (
      localStorage.getItem("UploadStatus") &&
      localStorage.getItem("UploadStatus") == "InProcess"
    ) {
      event.preventDefault();
      event.returnValue = "Upload In Progress.Do you want to lose the data?";
      return event;
    } else {
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }
  };

  const dismissToastMessage = () => {
    setFileUploadToast(false);
  };

  return (
    <React.Fragment>
      {loadContributionForm && <>
        {fileUploadToast && (
          <ToastNotification
            message={_sharedService.Data.Labels["KXDocuments_NewContibution_FileUploadAlert"]}
            onCloseClick={() => dismissToastMessage()}
          />
        )}
        {requiredAlert && (
          <ToastNotification
            message={
              _sharedService.Data.Labels["KXDocument_Warning_NewContribution"]
            }
            onCloseClick={toastNotificationClicked}
          />
        )}
        {filesizeAlert && (
          <ToastNotification
            message={_sharedService.Data.Labels["KXDocuments_NewContribution_AttachmentAlert"]}
            onCloseClick={filealertnotificationClicked}
          />
        )}
        {fileDuplicateNameAlert && (
          <ToastNotification
            message={_sharedService.Data.Labels["KXDocuments_NewContribution_DuplicateFileAlert"]}
            onCloseClick={duplicatefilenotificationClicked}
          />
        )}
        {invalidAuthorAlert && (
          <ToastNotification
            message={_sharedService.Data.Labels["KXDocuments_NewContribution_InvalidAuthorAlert"]}
            onCloseClick={invalidAuthorAlertCicked}
          />
        )}
        {isLoading ? <Loader /> : ""}
        <div>
          <div className={styles.submitform}>
            <div>
              <h1>
                {
                  _sharedService.Data.Labels[
                  "KXDocument_Page Heading_NewContribution"
                  ]
                }
              </h1>
            </div>
            <div className="styles.btnLayout">
              <PrimaryButton
                className={styles.info}
                text={
                  _sharedService.Data.Labels[
                  "KXDocument_Cancel button_NewContribution"
                  ]
                }
                onClick={onCancelDraft}
                disabled={isLoading}
              />
              <PrimaryButton
                className={styles.secondary}
                text={
                  _sharedService.Data.Labels[
                  "KXDocument_Save button_NewContribution"
                  ]
                }
                onClick={() => {
                  onSubmitorSaveDraft("save");
                }}
                disabled={isLoading}
              />
              <PrimaryButton
                className={styles.primary}
                text={
                  _sharedService.Data.Labels[
                  "KXDocument_Submit button_NewContribution"
                  ]
                }
                onClick={() => {
                  onSubmitorSaveDraft("submit");
                }}
                disabled={isLoading}
              />
            </div>
          </div>

          <BasicNCForm
            getBasicForm={getBasicForm}
            setNewTitle={setNewTitle}
            description={description}
            getfilesToBeUploaded={getfilesToBeUploaded}
            BasicFormData={BasicFormData}
            draftData={draftData}
            updateParentAttachment={removeAttachment}
          />

          <AdditionalNCForm
            getAdditionalForm={getAdditionalForm}
            AdditionalFormData={AdditionalFormData}
            draftData={draftData}
            setAuthorInvalidText={(value: string) => setAuthorInvalidText(value)}
            setContactsInvalidText={(value: string) => setContactInvalidText(value)}
          />
        </div>
        <PopupDialog
          title={
            _sharedService.Data.Labels["KXDocuments_NewContribution_Save_Title"]
          }
          //title="Your draft has been saved succesfully!"
          subText={
            _sharedService.Data.Labels["KXDocuments_NewContribution_Save_Subtext"]
          }
          //subText="Please go to My KX contributions page to continue working on your drafts. Your attachments are being uploaded to the KX application. You will be notified of the upload status in the alert bell."
          hideDialog={hideDialogSave}
          toggleHideDialog={toggleHideDialogSave}
          btn1text="OK"
          onClickBtn1={onClickSaveOK}
          hideTopBtn={true}
        />

        <PopupDialog
          title={
            _sharedService.Data.Labels[
            "KXDocuments_NewContribution_Submit_My KX Contributions_Title"
            ]
          }
          subText={
            _sharedService.Data.Labels[
            "KXDocuments_NewContribution_Submit_My KX Contributions_Subtext"
            ]
          }
          hideDialog={hideDialogSubmit}
          toggleHideDialog={toggleHideDialogSubmit}
          btn1text="OK"
          onClickBtn1={onClickSubmitOK}
          hideTopBtn={true}
        />

        <PopupDialog
          title={
            _sharedService.Data.Labels[
            "KXDocuments_NewContribution_Cancel_Lose unsaved changes_Title"
            ]
          }
          subText={
            _sharedService.Data.Labels[
            "KXDocuments_NewContribution_Cancel_Lose unsaved changes_Subtext"
            ]
          }
          hideDialog={hideDialogCancel}
          toggleHideDialog={toggleHideDialogCancel}
          btn1text="Yes"
          onClickBtn1={onClickCancelYes}
          btn2text="No"
          onClickBtn2={onClickCancelNo}
          hideTopBtn={true}
        />
      </>}
    </React.Fragment>
  );
};

export default NewContribution;
