import { ADD_DATA_TO_COLLECTION, collectionState, CollectionActionsTypeActions, dpnState, ADD_DATA_TO_DPN, DPNActionsTypeActions } from '../types'
const initialState: dpnState = {
  cards: []
}


export function dpnReducer( state = initialState, action: DPNActionsTypeActions) {
  switch (action.type) {
    case ADD_DATA_TO_DPN:
    return {
        ...state,
        cards: action.payload
        }
    default:
      return state
  }
}
