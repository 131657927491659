import React, { useState } from "react";
import { useBoolean, useId } from '@fluentui/react-hooks';
import { Callout, FontWeights, Link, mergeStyleSets, Stack, StackItem, Text } from '@fluentui/react';
import { SharedService } from "../../../services/SharedService";

export interface calloutProps {
    keyValue: string;
    label?: string;
    className?: string
}


export const CalloutBase: React.FC<calloutProps> = (props) => {
    const styles = mergeStyleSets({
        callout: {
            width: 320,
            maxWidth: '90%',
            padding: '20px 24px',
        },
        title: {
            marginBottom: 12,
            fontWeight: FontWeights.semilight,
        }
    });

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [InfoIcon] = useState("icon-kx-info-circle text--Md text--Bold text--Link");
    const buttonId = useId('callout-button');
    const _sharedService = SharedService.GetInstance();

    const getData = (data: string, type: string) => {
        let val: number = data.indexOf("|");
        if (type === "label") {
            let opium: string = data.substring(0, val);
            return opium
        } else if (type === "desc") {
            let opium: string = data.substring(val + 2);
            return <span
                dangerouslySetInnerHTML={{
                    __html: opium,
                }}
            ></span>
        }
    }

    return (
        <>
            <Link>
                <i id={buttonId} className={InfoIcon} onClick={toggleIsCalloutVisible}></i>
            </Link>

            {isCalloutVisible ?
                <>
                    <Callout className={styles.callout} role="dialog" gapSpace={0} setInitialFocus target={`#${buttonId}`} onDismiss={toggleIsCalloutVisible}>
                        <Stack horizontal horizontalAlign="space-between">
                            <StackItem>
                                <Text variant="xLarge" className={styles.title}>
                                    {(_sharedService.Data.Labels[props.keyValue]) != null ? getData(_sharedService.Data.Labels[props.keyValue], "label") : ""}
                                </Text>
                            </StackItem>
                            <StackItem>
                                <i className="icon-kx-close text--Sm c-p" onClick={toggleIsCalloutVisible}></i>
                            </StackItem>
                        </Stack>
                        <Text variant="small">
                            {(_sharedService.Data.Labels[props.keyValue]) != null ? getData(_sharedService.Data.Labels[props.keyValue], "desc") : ""}
                        </Text>
                    </Callout>
                </>
                : ''}
        </>
    )
};

