import React, { createContext, useState, useContext } from "react";
import { CardSchema } from "../../../models/SharedModels";

const CompareContentContext = createContext({
  itemsToCompare: [],
  setItemsToCompare: (itemsToCompare: any) => {},
  showMaxItemValidationPopUp: false,
  setShowMaxItemValidationPopUp: (showMaxItemValidationPopUp: boolean) => {}
});
function useCompareContentContext() {
  const context = useContext(CompareContentContext);
  if (!context)
    throw new Error("useCompareContentContext must be used within CompareContentProvider!");
  return context;
}

function CompareContentProvider(props: any) {
  const [itemsToCompare, setItemsToCompare] = useState<CardSchema[]>([]);
  const [showMaxItemValidationPopUp, setShowMaxItemValidationPopUp] = useState<boolean>(false);
  const value = { itemsToCompare, setItemsToCompare, showMaxItemValidationPopUp, setShowMaxItemValidationPopUp };
  return <CompareContentContext.Provider value={value} {...props} />;
}

export { CompareContentProvider, useCompareContentContext };
