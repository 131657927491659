import React, { lazy, Suspense } from 'react';

const LazyPageNotFound = lazy(() => import(/* webpackChunkName: "pagenotfound" */'./pageNotFound'));

interface ICompProps{
  is404:boolean;
}

const PageNotFound = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; } & ICompProps) => (
  <Suspense fallback={null}>
    <LazyPageNotFound {...props} />
  </Suspense>
);

export default PageNotFound;
