import React from "react";
import { Link as RouteLink } from "react-router-dom";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { SharedService } from "../../../services/SharedService";

export interface HeaderDropdownProps {
  dropdownLinks: any;
  handler: (even: any) => void;
  navLinkName: string;
  // pass a prop from headerNav.tsx
}

export const HeaderDropdown: React.FunctionComponent<HeaderDropdownProps> = ({
  dropdownLinks,
  handler,
  navLinkName,
}) => {
  return (
    <div className="header-nav__drop">
      <div className="container">
        <div className="ms-Grid-row">
          {dropdownLinks.length>0?dropdownLinks.map((dropLink: any, i: number) => {
            return (
              <div className="ms-Grid-col" key={i.toString()}>
                <ul>
                  {dropLink.column.map((column: any, i: number) => {
                    return (
                      <li className={column.linkUrl?"":"titleChange"} key={i.toString()}>
                        <RouteLink
                          digitaldata-topnavmenu={`${navLinkName} > ${column.linkName}`}
                          to={CommonFunctions.ifExternalUrl(column.linkUrl)||column.linkUrl.indexOf(SharedService.SpDomainUrl)>-1?"": column.linkUrl}
                          onClick={()=>handler(column.linkUrl)}
                        >
                          {column.linkName}
                        </RouteLink>
                        {column.subLevel ? (
                          <ul>
                            {column.subLevel.map((subLevel: any, i: number) => {
                              return (
                                <li key={i.toString()}>
                                  <RouteLink
                                    digitaldata-topnavmenu={`${navLinkName} > ${column.linkName} > ${subLevel.linkName}`}
                                    to={CommonFunctions.ifExternalUrl(subLevel.linkUrl)||subLevel.linkUrl.indexOf(SharedService.SpDomainUrl)>-1?"": subLevel.linkUrl}
                                    onClick={()=>handler(subLevel.linkUrl)}
                                  >
                                    {subLevel.linkName}
                                  </RouteLink>
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          }):null}
        </div>
      </div>
    </div>
  );
};
