import * as React from "react";
import { Dialog, DialogType } from "@fluentui/react";
import "./TopicModal.scss";

const dialogStyles = {
  main: {
    selectors: {
      ".ms-Dialog-title": {
        display: "none",
      },
      "mc-Dialog-inner": {
        display: "none",
      },
    },
  },
};

/**
 * Interface for TopicModal component props
 */
interface TopicModalProps {
  isOpen: boolean;
  //   onDismiss: () => void;
  title: string;
  subText: string;
  icon: string;
  buttonIcon?: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
}

/**
 * TopicModal Component
 *
 * This component represents the modal which opens when the 'New' button is clicked 
 * in 'Prompt by KX'.
 */
const TopicModal: React.FC<TopicModalProps> = ({
  isOpen,
  //   onDismiss,
  title,
  subText,
  icon,
  buttonIcon,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) => {
  return (
    <Dialog
      modalProps={{
        containerClassName: "topic-modal-container-class",
      }}
      hidden={!isOpen}
      //   onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.normal,
        title: "",
        closeButtonAriaLabel: "Close",
        showCloseButton: false,
        className: "dialog-class",
      }}
      styles={dialogStyles}
    >
      <div className="dialog-header">
        <img src={icon} alt="Icon" />
        <div className="dialog-title">{title}</div>
      </div>
      <div className="dialog-content">
        <div
          className="dialog-subtext"
          dangerouslySetInnerHTML={{ __html: subText }}
        />
        <div className="button-container">
          <button className="primary-button" onClick={onPrimaryButtonClick}>
            <span className="primary-button-text">{primaryButtonText}</span>
          </button>
          <button className="secondary-button" onClick={onSecondaryButtonClick}>
            <span className="secondary-button-text">{secondaryButtonText}</span>
            {buttonIcon && <img src={buttonIcon} alt="Button Icon" />}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default TopicModal;
