import React, { lazy, Suspense } from 'react';

const LazyMyContribution = lazy(() => import(/* webpackChunkName: "homepage" */'./myContribution'));

const MyContribution = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyMyContribution {...props} />
  </Suspense>
);

export default MyContribution;