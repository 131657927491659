import React, { lazy, Suspense } from 'react';

const LazyExploreCollection = lazy(() => import(/* webpackChunkName: "explorecollection" */'./exploreCollection'));

const ExploreCollection = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyExploreCollection {...props} />
  </Suspense>
);

export default ExploreCollection;
