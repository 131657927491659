import { StorageHandler, StorageType } from "../helpers/StorageHandler";
import {
  ISharedData,
  ISuggestion,
  ISearchData,
  ILabel,
  IRecentSearch,
  FeaturedItem,
  ResearchToolsItem,
  IUserAlertItem,
  IGeneralSearch,
  IDPNNotification,
  IDashNotification,
  IDashActivity,
  ICategory,
  IUserPermission,
  IPracticeProfileData,
  IGeography,
  ISearchHistory,
  IRelatedPeople,
  IGraphPeopleResponse,
  IRefiner,
  ISearchResults,
  IMinicardSearchMetaConfig,
  INSTaxonomyData,
  PromiseWithCancel,
  IAttachementIconsImages,
  IAttachmentNotication,
  ISubscriptionModel,
  IContentType,
  IContentQueryType,
  IFollower,
} from "../models/IShared";
import { Constants } from "../helpers/Constants";
import {
  PersonalCollection,
  Follower,
  IFeaturedItem,
  IResearchToolsItem,
  ICuratedCollection,
  UserAlertItem,
  IUserNeedData,
  CardSchema,
  Practice,
  UserSchema,
  DraftItems,
} from "../models/SharedModels";
import { Suggestion, RecentSearch } from "../models/SearchBox";
import { CommonFunctions } from "../helpers/CommonFunctions";
import { isEmpty, method, now } from "lodash";
import { KXTaxonomyTreeBuilder } from "./KXTaxonomyTreeBuilder";
import initInterceptor from "../helpers/fetchInterceptor";
import { PublicClientApplication } from "@azure/msal-browser";
import { isConditionalExpression } from "typescript";
import { AnyAction } from "redux";
import { KXDocumentConstants } from "../helpers/KXDocumentConstants";
import { Attachment } from "../models/SharedModels";
import { stringify } from "querystring";
import {
  IConversationHistoryResponseObj,
  IPromptRequestObj,
  IPromptResponseObj,
  IPromptFeedbackRequestObj,
  IPromptFeedbackResponseObj,
  IConversationKxHistoryRequestObj,
  IConversationKxHistoryResponseObj,
  IPromptKxResultRequestObj,
  IPromptKxResultResponseObj,
  IQuickViewResultRequestObj,
  IQuickViewResultResponseObj,
  IGetDPNImageDataResponseObj,
} from "../models/KxPromptModels";

export enum Relatedtype {
  CollectionLeaders = "CollectionLeaders",
  ExploreConcept = "ExploreConcept",
  RelatedLinks = "RelatedLinks",
  PracticeProfileLink = "PracticeProfileLink",
  RelatedCollections = "RelatedCollections",
  RelatedCommunities = "RelatedCommunities",
  AllFields = "AllFields",
  KeyContacts = "KeyContacts",
  MoreAboutPracticeProfile = "MoreAboutPracticeProfile",
}
let LanguageList = [{
  key: "EN",
  text: "English",
},
{
  key: "ES",
  text: "Spanish",
},
{
  key: "FR",
  text: "French",
},
{
  key: "ZH",
  text: "Chinese",
},
{
  key: "JA",
  text: "Japanese",
}];

export class SharedService {
  private static instance: SharedService;

  public static webUrl: string;
  public static uiUrl: string = window.location.origin;
  public static spSiteUrl: string;
  public static spClientId: string;
  public static SpDomainUrl: string;
  public static kxDashUrl: string;
  public static AddDashActivityMapping: string;
  public static RemoveDashActivityMapping: string;
  public static dashActivityByIdApiUrl: string;
  public static updateDashActivityStatus: string;
  public static dashApiUrl: string;
  public static genaiApiUrl: string;
  public static getDashActivityApiUrl: string;
  public static peopleApiUrl: string;
  public static clientApiUrl: string;
  public static addNewClientApiUrl: string;
  public static baseClientsQualApiUrl: string;
  public static getRelatedQualApiUrl: string;
  public static scopeNotesLabelKey: string;
  public static dpnLink: string;
  public static dpnImgApiUrl: string;
  public static dpnApiUrl: string;
  public static dpnImageKey: string;
  public static suggestion: string;
  public static suggestionapim: string;
  public static dpnUrl: string;
  public static apiScopes: any;
  public static genaiAPIScopes: any;
  public static genaiSubscriptionKey: string;
  public static msalConfig: any;
  public static documentsWebApiUrl: string;
  public static documentsImgExtUrl: string;
  public static documentsUiUrl: string;
  public static kxcentralapiUrl: string;
  public static badgeIconURL: string;
  public static thumbnailBaseUrl: string;
  public lang: any;
  public imgUser: any;
  public Data: ISharedData = {} as ISharedData;
  public msalClient: any;
  public userName: any;
  public account: any;
  public claims: any;
  public badges: any;
  public static DPNToken: any;
  public static GetImageURL: string;
  public static MyQualsUrl: string;
  public static currentUserObj: any;
  public static ClientUrl: any;
  public static QualUrl: any;
  public static SPAttachmentLibrary: string;
  public static SPThumbnailLibrary: string;
  public static KXHelpUrl: string;

  public attachmentIcons: IAttachementIconsImages[] = [
    { AttachementType: "pdf", IconImageClass: "icon-kx-file-pdf" },
    { AttachementType: "ppt", IconImageClass: "icon-kx-file-powerpoint-o" },
    { AttachementType: "pptx", IconImageClass: "icon-kx-file-powerpoint-o" },
    { AttachementType: "jpg", IconImageClass: "icon-kx-file-picture" },
    { AttachementType: "png", IconImageClass: "icon-kx-file-picture" },
    { AttachementType: "xlsx", IconImageClass: "icon-kx-file-excel" },
    { AttachementType: "xls", IconImageClass: "icon-kx-file-excel" },
    { AttachementType: "general", IconImageClass: "icon-kx-file-empty" },
    { AttachementType: "doc", IconImageClass: "icon-kx-file-word" },
    { AttachementType: "docx", IconImageClass: "icon-kx-file-word" },
    { AttachementType: "csv", IconImageClass: "icon-kx-file-csv-regular" },
    { AttachementType: "zip", IconImageClass: "icon-kx-file-zip" },
    // here we can add different types of icons if required
  ];

  //#region init service
  private constructor() {
    switch (process.env.REACT_APP_ENV) {
      case "development":
        SharedService.webUrl = Constants.General.DevWebApiUrl;
        SharedService.spSiteUrl = Constants.General.DevSpSiteUrl;
        SharedService.spClientId = Constants.SpClientId.Dev;
        SharedService.SpDomainUrl = Constants.General.DevSpDomainUrl;
        SharedService.kxDashUrl = Constants.General.DevKXDashUrl;
        SharedService.AddDashActivityMapping =
          Constants.General.DevAddDashActivityMapping;
        SharedService.RemoveDashActivityMapping =
          Constants.General.DevRemoveDashActivityMapping;
        SharedService.updateDashActivityStatus =
          Constants.General.DevUpdateActivityStatusUrl;
        SharedService.dashActivityByIdApiUrl =
          Constants.General.DevDashActivityByIdApiUrl;
        SharedService.dashApiUrl = Constants.General.DevDashApiUrl;
        SharedService.genaiApiUrl = Constants.General.DevGenaiApiUrl;
        SharedService.getDashActivityApiUrl =
          Constants.General.DevGetDashActivityApiUrl;
        SharedService.peopleApiUrl = Constants.General.DevPeopleApiUrl;
        SharedService.clientApiUrl = Constants.General.DevClientApiUrl;
        SharedService.addNewClientApiUrl =
          Constants.General.DevAddNewClientApiUrl;
        SharedService.baseClientsQualApiUrl =
          Constants.General.DevBaseClientQualApiUrl;
        SharedService.scopeNotesLabelKey = Constants.General.DevLabelsKey;
        SharedService.getRelatedQualApiUrl =
          Constants.General.DevGetRelatedQualApiUrl;
        SharedService.dpnLink = Constants.General.DevDPNLink;
        SharedService.dpnImgApiUrl = Constants.General.DevDPNImgApiUrl;
        SharedService.dpnApiUrl = Constants.General.DevDPNApiUrl;
        SharedService.dpnUrl = Constants.General.DevDpnUrl;
        SharedService.dpnImageKey = Constants.General.DevOcpApimSubscriptionKey;
        SharedService.apiScopes = Constants.DevAPIScopes;
        SharedService.genaiAPIScopes = Constants.DevGenaiAPIScopes;
        SharedService.genaiSubscriptionKey = Constants.DevGenaiSubscriptionKey;
        SharedService.msalConfig = Constants.DevMsalConfig;
        SharedService.suggestion = Constants.General.SuggestionQA;
        SharedService.suggestionapim = Constants.General.SuggestionApimDev;
        SharedService.GetImageURL = Constants.General.DevGetImageURL;
        SharedService.documentsWebApiUrl =
          KXDocumentConstants.General.DevWebApiUrl;
        SharedService.documentsImgExtUrl =
          KXDocumentConstants.General.DevImgExtUrl;
        SharedService.documentsUiUrl = KXDocumentConstants.General.DevUiUrl;
        SharedService.kxcentralapiUrl =
          KXDocumentConstants.General.DevkxcentralApiUrl;
        SharedService.badgeIconURL = Constants.General.BadgesIconDevURL;
        SharedService.thumbnailBaseUrl = Constants.General.ThumbnailDevURL;
        SharedService.MyQualsUrl = Constants.General.DevQualsUrl;
        SharedService.ClientUrl = Constants.General.DevClientsUrlForDisplay;
        SharedService.QualUrl = Constants.General.DevQualsForDisplayUrl;
        SharedService.SPAttachmentLibrary =
          Constants.General.DevSPAttachmentLibrary;
        SharedService.SPThumbnailLibrary =
          Constants.General.DevSPThumbnailLibrary;
        SharedService.KXHelpUrl = Constants.General.DevKXHelpUrl;
        break;
      case "qa":
        SharedService.webUrl = Constants.General.QAWebApiUrl;
        SharedService.spSiteUrl = Constants.General.QASpSiteUrl;
        SharedService.spClientId = Constants.SpClientId.Qa;
        SharedService.SpDomainUrl = Constants.General.QASpDomainUrl;
        SharedService.kxDashUrl = Constants.General.QAKXDashUrl;
        SharedService.AddDashActivityMapping =
          Constants.General.QAAddDashActivityMapping;
        SharedService.RemoveDashActivityMapping =
          Constants.General.QARemoveDashActivityMapping;
        SharedService.updateDashActivityStatus =
          Constants.General.QAUpdateActivityStatusUrl;
        SharedService.dashActivityByIdApiUrl =
          Constants.General.QADashActivityByIdApiUrl;
        SharedService.dashApiUrl = Constants.General.QADashApiUrl;
        SharedService.genaiApiUrl = Constants.General.QAGenaiApiUrl;
        SharedService.getDashActivityApiUrl =
          Constants.General.QAGetDashActivityApiUrl;
        SharedService.peopleApiUrl = Constants.General.QAPeopleApiUrl;
        SharedService.clientApiUrl = Constants.General.QAClientApiUrl;
        SharedService.addNewClientApiUrl =
          Constants.General.QAAddNewClientApiUrl;
        SharedService.baseClientsQualApiUrl =
          Constants.General.QABaseClientQualApiUrl;
        SharedService.getRelatedQualApiUrl =
          Constants.General.QAGetRelatedQualApiUrl;
        SharedService.scopeNotesLabelKey = Constants.General.QALabelsKey;
        SharedService.apiScopes = Constants.QAAPIScopes;
        SharedService.genaiAPIScopes = Constants.QAGenaiAPIScopes;
        SharedService.genaiSubscriptionKey = Constants.QAGenaiSubscriptionKey;
        SharedService.msalConfig = Constants.QAMsalConfig;
        SharedService.dpnLink = Constants.General.QaDPNLink;
        SharedService.dpnImgApiUrl = Constants.General.QADPNImgApiUrl;
        SharedService.dpnUrl = Constants.General.QADpnUrl;
        SharedService.dpnApiUrl = Constants.General.QADPNApiUrl;
        SharedService.dpnImageKey = Constants.General.QAOcpApimSubscriptionKey;
        SharedService.suggestion = Constants.General.SuggestionQA;
        SharedService.suggestionapim = Constants.General.SuggestionApimQA;
        SharedService.GetImageURL = Constants.General.QAGetImageURL;
        SharedService.documentsWebApiUrl =
          KXDocumentConstants.General.QAWebApiUrl;
        SharedService.documentsImgExtUrl =
          KXDocumentConstants.General.QAImgExtUrl;
        SharedService.documentsUiUrl = KXDocumentConstants.General.QAUiUrl;
        SharedService.kxcentralapiUrl =
          KXDocumentConstants.General.QAkxcentralApiUrl;
        SharedService.badgeIconURL = Constants.General.BadgesIconQaURL;
        SharedService.thumbnailBaseUrl = Constants.General.ThumbnailQaURL;
        SharedService.MyQualsUrl = Constants.General.QAQualsUrl;
        SharedService.ClientUrl = Constants.General.QAClientsForDisplayUrl;
        SharedService.QualUrl = Constants.General.QAQualsForDisplayUrl;
        SharedService.SPAttachmentLibrary =
          Constants.General.QASPAttachmentLibrary;
        SharedService.SPThumbnailLibrary =
          Constants.General.QASPThumbnailLibrary;
        SharedService.KXHelpUrl = Constants.General.QAKXHelpUrl;
        break;
      case "qa2":
        SharedService.webUrl = Constants.General.QA2WebApiUrl;
        SharedService.spSiteUrl = Constants.General.QASpSiteUrl;
        SharedService.spClientId = Constants.SpClientId.Qa2;
        SharedService.SpDomainUrl = Constants.General.QASpDomainUrl;
        SharedService.kxDashUrl = Constants.General.QAKXDashUrl;
        SharedService.AddDashActivityMapping =
          Constants.General.QAAddDashActivityMapping;
        SharedService.RemoveDashActivityMapping =
          Constants.General.QARemoveDashActivityMapping;
        SharedService.updateDashActivityStatus =
          Constants.General.QAUpdateActivityStatusUrl;
        SharedService.dashActivityByIdApiUrl =
          Constants.General.QADashActivityByIdApiUrl;
        SharedService.dashApiUrl = Constants.General.QADashApiUrl;
        SharedService.genaiApiUrl = Constants.General.QA2GenaiApiUrl;
        SharedService.getDashActivityApiUrl =
          Constants.General.QAGetDashActivityApiUrl;
        SharedService.peopleApiUrl = Constants.General.QAPeopleApiUrl;
        SharedService.clientApiUrl = Constants.General.QAClientApiUrl;
        SharedService.addNewClientApiUrl =
          Constants.General.QAAddNewClientApiUrl;
        SharedService.baseClientsQualApiUrl =
          Constants.General.QABaseClientQualApiUrl;
        SharedService.getRelatedQualApiUrl =
          Constants.General.QAGetRelatedQualApiUrl;
        SharedService.scopeNotesLabelKey = Constants.General.QALabelsKey;
        SharedService.apiScopes = Constants.QAAPIScopes;
        SharedService.genaiAPIScopes = Constants.QAGenaiAPIScopes;
        SharedService.genaiSubscriptionKey = Constants.QAGenaiSubscriptionKey;
        SharedService.msalConfig = Constants.QA2MsalConfig;
        SharedService.dpnLink = Constants.General.QaDPNLink;
        SharedService.dpnImgApiUrl = Constants.General.QADPNImgApiUrl;
        SharedService.dpnUrl = Constants.General.QADpnUrl;
        SharedService.dpnApiUrl = Constants.General.QADPNApiUrl;
        SharedService.dpnImageKey = Constants.General.QAOcpApimSubscriptionKey;
        SharedService.suggestion = Constants.General.SuggestionQA;
        SharedService.suggestionapim = Constants.General.SuggestionApimQA;
        SharedService.GetImageURL = Constants.General.QAGetImageURL;
        SharedService.documentsWebApiUrl =
          KXDocumentConstants.General.QA2WebApiUrl;
        SharedService.documentsImgExtUrl =
          KXDocumentConstants.General.QA2ImgExtUrl;
        SharedService.documentsUiUrl = KXDocumentConstants.General.QA2UiUrl;
        SharedService.kxcentralapiUrl =
          KXDocumentConstants.General.QA2kxcentralApiUrl;
        SharedService.badgeIconURL = Constants.General.BadgesIconQaURL;
        SharedService.thumbnailBaseUrl = Constants.General.ThumbnailQaURL;
        SharedService.MyQualsUrl = Constants.General.QAQualsUrl;
        SharedService.ClientUrl = Constants.General.QAClientsForDisplayUrl;
        SharedService.QualUrl = Constants.General.QAQualsForDisplayUrl;
        SharedService.SPAttachmentLibrary =
          Constants.General.QASPAttachmentLibrary;
        SharedService.SPThumbnailLibrary =
          Constants.General.QASPThumbnailLibrary;
        SharedService.KXHelpUrl = Constants.General.QAKXHelpUrl;
        break;
      case "integration":
        SharedService.webUrl = Constants.General.INTWebApiUrl;
        SharedService.spSiteUrl = Constants.General.INTSpSiteUrl;
        SharedService.spClientId = Constants.SpClientId.Int;
        SharedService.SpDomainUrl = Constants.General.INTSpDomainUrl;
        SharedService.kxDashUrl = Constants.General.INTKXDashUrl;
        SharedService.AddDashActivityMapping =
          Constants.General.INTAddDashActivityMapping;
        SharedService.RemoveDashActivityMapping =
          Constants.General.INTRemoveDashActivityMapping;
        SharedService.updateDashActivityStatus =
          Constants.General.INTUpdateActivityStatusUrl;
        SharedService.dashActivityByIdApiUrl =
          Constants.General.INTDashActivityByIdApiUrl;
        SharedService.dashApiUrl = Constants.General.INTDashApiUrl;
        SharedService.getDashActivityApiUrl =
          Constants.General.INTGetDashActivityApiUrl;
        SharedService.peopleApiUrl = Constants.General.INTPeopleApiUrl;
        SharedService.clientApiUrl = Constants.General.INTClientApiUrl;
        SharedService.getRelatedQualApiUrl =
          Constants.General.INTGetRelatedQualApiUrl;
        SharedService.dpnLink = Constants.General.INTDPNLink;
        SharedService.addNewClientApiUrl =
          Constants.General.INTAddNewClientApiUrl;
        SharedService.baseClientsQualApiUrl =
          Constants.General.INTBaseClientQualApiUrl;
        SharedService.scopeNotesLabelKey = Constants.General.INTLabelsKey;
        SharedService.apiScopes = Constants.INTAPIScopes;
        SharedService.genaiAPIScopes = Constants.QAGenaiAPIScopes;
        SharedService.genaiSubscriptionKey = Constants.QAGenaiSubscriptionKey;
        SharedService.msalConfig = Constants.INTMsalConfig;
        SharedService.dpnImgApiUrl = Constants.General.INTDPNImgApiUrl;
        SharedService.dpnApiUrl = Constants.General.INTDPNApiUrl;
        SharedService.dpnUrl = Constants.General.INTDpnUrl;
        SharedService.dpnImageKey = Constants.General.INTOcpApimSubscriptionKey;
        SharedService.suggestion = Constants.General.SuggestionINT;
        SharedService.suggestionapim = Constants.General.SuggestionApimINT;
        SharedService.GetImageURL = Constants.General.INTGetImageURL;
        SharedService.documentsWebApiUrl =
          KXDocumentConstants.General.INTWebApiUrl;
        SharedService.documentsImgExtUrl =
          KXDocumentConstants.General.INTImgExtUrl;
        SharedService.documentsUiUrl = KXDocumentConstants.General.INTUiUrl;
        SharedService.kxcentralapiUrl =
          KXDocumentConstants.General.INTkxcentralApiUrl;
        SharedService.badgeIconURL = Constants.General.BadgesIconIntURL;
        SharedService.thumbnailBaseUrl = Constants.General.ThumbnailIntURL;
        SharedService.MyQualsUrl = Constants.General.INTQualsUrl;
        SharedService.ClientUrl = Constants.General.INTClientsForDisplayUrl;
        SharedService.QualUrl = Constants.General.INTQualsForDisplayUrl;
        SharedService.SPAttachmentLibrary =
          Constants.General.INTSPAttachmentLibrary;
        SharedService.SPThumbnailLibrary =
          Constants.General.INTSPThumbnailLibrary;
        SharedService.KXHelpUrl = Constants.General.QAKXHelpUrl;
        break;
      case "staging":
        SharedService.webUrl = Constants.General.StageWebApiUrl;
        SharedService.spSiteUrl = Constants.General.StageSpSiteUrl;
        SharedService.spClientId = Constants.SpClientId.Stage;
        SharedService.SpDomainUrl = Constants.General.StageSpDomainUrl;
        SharedService.kxDashUrl = Constants.General.StageKXDashUrl;
        SharedService.AddDashActivityMapping =
          Constants.General.StageAddDashActivityMapping;
        SharedService.RemoveDashActivityMapping =
          Constants.General.StageRemoveDashActivityMapping;
        SharedService.updateDashActivityStatus =
          Constants.General.StageUpdateActivityStatusUrl;
        SharedService.dashActivityByIdApiUrl =
          Constants.General.StageDashActivityByIdApiUrl;
        SharedService.dashApiUrl = Constants.General.StageDashApiUrl;
        SharedService.genaiApiUrl = Constants.General.StageGenaiApiUrl;
        SharedService.getDashActivityApiUrl =
          Constants.General.StageGetDashActivityApiUrl;
        SharedService.peopleApiUrl = Constants.General.StagePeopleApiUrl;
        SharedService.clientApiUrl = Constants.General.StageClientApiUrl;
        SharedService.addNewClientApiUrl =
          Constants.General.StageAddNewClientApiUrl;
        SharedService.baseClientsQualApiUrl =
          Constants.General.StageBaseClientQualApiUrl;
        SharedService.getRelatedQualApiUrl =
          Constants.General.StageGetRelatedQualApiUrl;
        SharedService.scopeNotesLabelKey = Constants.General.StageLabelsKey;
        SharedService.apiScopes = Constants.StageAPIScopes;
        SharedService.genaiAPIScopes = Constants.StageGenaiAPIScopes;
        SharedService.genaiSubscriptionKey =
          Constants.StageGenaiSubscriptionKey;
        SharedService.msalConfig = Constants.StageMsalConfig;
        SharedService.dpnImgApiUrl = Constants.General.StageDPNImgApiUrl;
        SharedService.dpnApiUrl = Constants.General.StageDPNApiUrl;
        SharedService.dpnUrl = Constants.General.StageDpnUrl;
        SharedService.dpnImageKey =
          Constants.General.StageOcpApimSubscriptionKey;
        SharedService.suggestion = Constants.General.SuggestionStage;
        SharedService.suggestionapim = Constants.General.SuggestionApimStage;
        SharedService.dpnLink = Constants.General.StageDPNLink;
        SharedService.GetImageURL = Constants.General.StageGetImageURL;
        SharedService.documentsWebApiUrl =
          KXDocumentConstants.General.StageWebApiUrl;
        SharedService.documentsImgExtUrl =
          KXDocumentConstants.General.StageImgExtUrl;
        SharedService.documentsUiUrl = KXDocumentConstants.General.StageUiUrl;
        SharedService.kxcentralapiUrl =
          KXDocumentConstants.General.StagekxcentralApiUrl;
        SharedService.badgeIconURL = Constants.General.BadgesIconStageURL;
        SharedService.thumbnailBaseUrl = Constants.General.ThumbnailStageURL;
        SharedService.MyQualsUrl = Constants.General.StageQualsUrl;
        SharedService.ClientUrl = Constants.General.StageClientsForDisplayUrl;
        SharedService.QualUrl = Constants.General.StageQualsForDisplayUrl;
        SharedService.SPAttachmentLibrary =
          Constants.General.StageSPAttachmentLibrary;
        SharedService.SPThumbnailLibrary =
          Constants.General.StageSPThumbnailLibrary;
        SharedService.KXHelpUrl = Constants.General.StageKXHelpUrl;
        break;
      case "production":
        SharedService.webUrl = Constants.General.ProdWebApiUrl;
        SharedService.spSiteUrl = Constants.General.ProdSpSiteUrl;
        SharedService.spClientId = Constants.SpClientId.Prod;
        SharedService.SpDomainUrl = Constants.General.ProdSpDomainUrl;
        SharedService.kxDashUrl = Constants.General.ProdKXDashUrl;
        SharedService.AddDashActivityMapping =
          Constants.General.ProdAddDashActivityMapping;
        SharedService.RemoveDashActivityMapping =
          Constants.General.ProdRemoveDashActivityMapping;
        SharedService.updateDashActivityStatus =
          Constants.General.ProdUpdateActivityStatusUrl;
        SharedService.dashActivityByIdApiUrl =
          Constants.General.ProdDashActivityByIdApiUrl;
        SharedService.dashApiUrl = Constants.General.ProdDashApiUrl;
        SharedService.genaiApiUrl = Constants.General.ProdGenaiApiUrl;
        SharedService.getDashActivityApiUrl =
          Constants.General.ProdGetDashActivityApiUrl;
        SharedService.peopleApiUrl = Constants.General.ProdPeopleApiUrl;
        SharedService.dpnLink = Constants.General.ProdDPNLink;
        SharedService.clientApiUrl = Constants.General.ProdClientApiUrl;
        SharedService.addNewClientApiUrl =
          Constants.General.ProdAddNewClientApiUrl;
        SharedService.baseClientsQualApiUrl =
          Constants.General.ProdBaseClientQualApiUrl;
        SharedService.getRelatedQualApiUrl =
          Constants.General.ProdGetRelatedQualApiUrl;
        SharedService.scopeNotesLabelKey = Constants.General.ProdLabelsKey;
        SharedService.apiScopes = Constants.ProdAPIScopes;
        SharedService.genaiAPIScopes = Constants.ProdGenaiAPIScopes;
        SharedService.genaiSubscriptionKey = Constants.ProdGenaiSubscriptionKey;
        SharedService.msalConfig = Constants.ProdMsalConfig;
        SharedService.dpnImgApiUrl = Constants.General.ProdDPNImgApiUrl;
        SharedService.dpnApiUrl = Constants.General.ProdDPNApiUrl;
        SharedService.dpnUrl = Constants.General.ProdDpnUrl;
        SharedService.dpnImageKey =
          Constants.General.ProdOcpApimSubscriptionKey;
        SharedService.suggestion = Constants.General.SuggestionProd;
        SharedService.suggestionapim = Constants.General.SuggestionApimProd;
        SharedService.GetImageURL = Constants.General.ProdGetImageURL;
        SharedService.documentsWebApiUrl =
          KXDocumentConstants.General.ProdWebApiUrl;
        SharedService.documentsImgExtUrl =
          KXDocumentConstants.General.ProdImgExtUrl;
        SharedService.kxcentralapiUrl =
          KXDocumentConstants.General.ProdkxcentralApiUrl;
        SharedService.documentsUiUrl = KXDocumentConstants.General.ProdUiUrl;
        SharedService.badgeIconURL = Constants.General.BadgesIconProdURL;
        SharedService.thumbnailBaseUrl = Constants.General.ThumbnailProdURL;
        SharedService.MyQualsUrl = Constants.General.ProdQualsUrl;
        SharedService.ClientUrl = Constants.General.ProdClientsForDisplayUrl;
        SharedService.QualUrl = Constants.General.ProdQualsForDisplayUrl;
        SharedService.SPAttachmentLibrary =
          Constants.General.ProdSPAttachmentLibrary;
        SharedService.SPThumbnailLibrary =
          Constants.General.ProdSPThumbnailLibrary;
        SharedService.KXHelpUrl = Constants.General.ProdKXHelpUrl;
        break;
    }
    Date.prototype.toJSON = function () {
      if(Number.isNaN(this.getDate()))
        return new Date(-8640000).toISOString();
      
      const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
      let minutesDiff = (this.getHours() - this.getTimezoneOffset()) % 60;
      this.setHours(hoursDiff);
      this.setMinutes(minutesDiff);
      return this.toISOString();
    }
  }



  public async setSharedServiceData(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      this.Data = {} as ISharedData;
      this.lang = await SharedService.instance.SiteSettings.Read.GetLang();

      let [
        currentUser,
        labels,
        ctpMapping,
        categoryOrder,
        applications,
        researchTools,
        version,
        kxDocsVersion,
        tokenImages,
        //dataRestrcitions
      ] = await Promise.all([
        //SharedService.instance.SiteSettings.Read.GetWebInfo(),

        SharedService.instance.SiteSettings.Read.GetCurrentUser(), //merge -> configuration
        SharedService.instance.SiteSettings.Read.GetLabels(), //merge
        SharedService.instance.SiteSettings.Read.GetCTPMapping(),
        SharedService.instance.SiteSettings.Read.GetCategoryOrder(), //merge
        SharedService.instance.SiteSettings.Read.GetApplications(), //merge
        SharedService.instance.SiteSettings.Read.GetResearchTools(), //merge
        SharedService.instance.SiteSettings.Read.GetVersion(), //merge
        SharedService.instance.SiteSettings.Read.GetKXDocsVersion(), //merge
        SharedService.instance.SiteSettings.Read.GetTokenImages(), //merge
        //SharedService.instance.SiteSettings.Read.GetUserRoleGroupsAndPrivileges(), //merge
        // SharedService.instance.SiteSettings.Read.GetBadges(),
      ]);
      // console.time("promisAll");
      // let [configuration2] = await Promise.all([SharedService.instance.SiteSettings.Read.GetConfiguration2FromWebAPI()])
      // console.timeEnd("promisAll");
      //this.Data.WebInfo = webInfo;
      this.Data.Labels = labels;
      this.Data.Version = version.version;
      this.Data.KXDocsVersion = kxDocsVersion;
      this.Data.CurrentUser = currentUser;
      this.Data.CTPMapping = ctpMapping;
      this.Data.CategoryOrder = categoryOrder;
      this.Data.Applications = applications;
      this.Data.SPAuth = tokenImages;
      //this.Data.UserRoleGroupsAndPrivileges = dataRestrcitions;
      sessionStorage.setItem(
        Constants.SessionStorageItems.SharedServiceDataState,
        Constants.General.StateResolved
      );
      resolve();
    });
  }

  private async UpdateLabels(lang: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      this.lang = lang;
      let [language, labels] = await Promise.all([
        //SharedService.instance.SiteSettings.Read.GetWebInfo(),
        SharedService.instance.SiteSettings.Read.GetLang(),
        SharedService.instance.SiteSettings.Read.GetLabels(),
      ]);
      //this.Data.WebInfo = webInfo;
      this.Data.Labels = labels;
      this.lang = language;

      sessionStorage.setItem(
        Constants.SessionStorageItems.SharedServiceDataState,
        Constants.General.StateResolved
      );
      resolve();
    });
  }

  public static UpdateLabelsLang(lang: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      SharedService.instance.RemoveLabels().then((_) => {
        StorageHandler.Add(
          Constants.SessionStorageItems.Lang,
          lang,
          StorageType.Local
        );
        SharedService.instance.UpdateLabels(lang).then((_) => {
          let lang = CommonFunctions.URLSearch().get(
            Constants.QueryStringParameters.Lang
          );
          if (lang) {
            var uri = new URLSearchParams(window.location.search);
            uri.delete(Constants.QueryStringParameters.Lang);
            if (uri.toString().length > 0)
              window.history.replaceState(
                {},
                document.title,
                window.location.origin +
                window.location.pathname +
                "?" +
                uri.toString()
              );
            else
              window.history.replaceState(
                {},
                document.title,
                window.location.origin + window.location.pathname
              );
          }
          window.location.reload();

          resolve();
        });
      });
    });
  }

  public async RemoveLabels(): Promise<any> {
    return await StorageHandler.Remove(
      this.SiteSettings.Read.GetSessionKeyByLang(SharedService.instance.lang),
      StorageType.Session
    );
  }

  public static InitializeInstance(): Promise<void> {
    let _sharedMemory = window as any;

    if (!_sharedMemory.StorageLock) {
      _sharedMemory.StorageLock = new Promise<void>((resolve, reject) => {
        SharedService.instance = _sharedMemory.SharedService =
          new SharedService();
        SharedService.instance.SiteSettings.Read.GetMsalToken().then((_) => {
          SharedService.instance.SiteSettings.Read.GetSPAuth();
          SharedService.instance.SiteSettings.Read.GetMsalTokenDpn().then(
            (data) => {
              SharedService.DPNToken = data;
            }
          );
          var postFix = window.location.host.substring(
            0,
            window.location.host.indexOf(".")
          );
          var SPAuthToken = localStorage.getItem("SPAuthCookie_" + postFix);
          SPAuthToken = JSON.parse(SPAuthToken ? SPAuthToken : "").data
            .SPAppToken;
          if (SPAuthToken) {
            var tokenArr = SPAuthToken.split(".");
            if (tokenArr.length > 1) {
              var tokenObj = JSON.parse(atob(tokenArr[1])); //0-header 1- payload 2-signture. furture reading https://auth0.com/learn/json-web-tokens/
              if (new Date().getTime() < tokenObj.exp * 1000)
                //*1000 for match type
                SharedService.instance.setSharedServiceData().then((_) => {
                  resolve();
                });
              else {
                StorageHandler.Remove(
                  Constants.SessionStorageItems.SPAuth,
                  StorageType.Local
                );
                SharedService.instance.SiteSettings.Read.GetSPAuth();
              }
            }
          }
        });
      });
    }
    return _sharedMemory.StorageLock.then(() => {
      SharedService.instance = _sharedMemory.SharedService;
    });
  }

  public static getWebUrl() {
    return SharedService.webUrl;
  }

  public static GetInstance(): SharedService {
    if (!SharedService.instance) {
      throw "SharedService.ts: This service must first be initialized using initializeInstance(context).";
    }
    return SharedService.instance;
  }

  public PersistUserNeed = async (userNeedId: number): Promise<any> => {
    StorageHandler.Add(
      Constants.SessionStorageItems.SelectedNeed,
      userNeedId,
      StorageType.Session
    );
  };

  public PersistIsInterestsHiddenFlag = (): any => {
    localStorage.setItem(
      Constants.SessionStorageItems.ManageInterestsFlag,
      "true"
    );
  };

  public PersistTagsData = async (tagsData: any): Promise<any> => {
    const hourstoexpire: number = Date.now();
    if (tagsData.length > 0) {
      const value = {
        exp: hourstoexpire,
        data: tagsData,
      };
      sessionStorage.setItem(
        Constants.SessionStorageItems.TagsData,
        JSON.stringify(value)
      );
    }
  };

  public PersistNSTagsData = async (NStagsData: any): Promise<any> => {
    const hourstoexpire: number = Date.now();
    if (NStagsData) {
      const value = {
        exp: hourstoexpire,
        data: NStagsData,
      };
      localStorage.setItem(
        Constants.SessionStorageItems.GetAllNSTagsData,
        JSON.stringify(value)
      );
    }
  };

  public PersistRawTagsData = async (rawTagsData: any): Promise<any> => {
    const hoursToExpire: number = Date.now();
    const value = {
      exp: hoursToExpire,
      data: rawTagsData,
    };
    localStorage.setItem(
      Constants.SessionStorageItems.RawTagsData,
      JSON.stringify(value)
    );
  };

  public PersistGetAllData = async (getAllData: any): Promise<any> => {
    const hoursToExpire: number = Date.now();
    const value = {
      exp: hoursToExpire,
      data: getAllData,
    };
    localStorage.setItem(
      Constants.SessionStorageItems.GetAllData,
      JSON.stringify(value)
    );
  };

  //#endregion init service

  //#region services

  public SiteSettings = new (class {
    public Read = new (class {
      public async GetCurrentUser(): Promise<any> {
        var Configuration =
          await SharedService.instance.SiteSettings.Read.GetConfiguration();
        SharedService.instance.Data.Configuration = Configuration;
        return await StorageHandler.Get(
          Constants.SessionStorageItems.User,
          StorageType.Local,
          this.GetCurrentUserFromWebAPI,
          Configuration.EditPersonalisation == "1" ? true : false,
          Configuration.EditPersonalisation == "1" ? true : false
        );
      }

      public GetCurrentUserFromWebAPI = (ifOverride: boolean): Promise<any> => {
        return new Promise(async (resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          let userDataObj = {};
          let userData = require("./DemiUserJson.json");
          let userSchema: UserSchema;
          if (window.location.href.indexOf("://localhost") != -1) {
            userData.kxp_email = SharedService.GetInstance().claims
              ? SharedService.GetInstance().claims.preferred_username
              : SharedService.GetInstance().userName;
            userData.title = SharedService.GetInstance().claims
              ? SharedService.GetInstance().claims.name
              : SharedService.GetInstance().userName;

            userSchema = new UserSchema(userData, true);
          } else {
            let user = await (
              await fetch(_webAPIUrl + "api/currentUser")
            ).json();
            if (user && user.userData && user.userData[0]) {
              user.userData[0]._source.kxp_email = SharedService.GetInstance()
                .claims
                ? SharedService.GetInstance().claims.preferred_username
                : SharedService.GetInstance().userName;
              user.userData[0]._source.title = SharedService.GetInstance()
                .claims
                ? SharedService.GetInstance().claims.name
                : SharedService.GetInstance().userName;
              if (ifOverride) {
                const urlParams = new URLSearchParams(window.location.search);
                var clientServices = urlParams.get("clientService");
                var Geography = urlParams.get("geography");
                if (Geography) {
                  var arrGeography = Geography.split("|");
                  var arrGeographyObject = arrGeography.map(
                    (Geography: string) => {
                      var newGeography = Geography.split(";");
                      return {
                        id: newGeography[0],
                        name: newGeography[2],
                        fullpath: newGeography[1],
                      };
                    }
                  );
                  user.userData[0]._source.geography = arrGeographyObject;
                }
                if (clientServices) {
                  var arrClientServices = clientServices.split("|");
                  var arrClientServicesObject = arrClientServices.map(
                    (clientservice: string) => {
                      var newClientService = clientservice.split(";");
                      return {
                        id: newClientService[0],
                        name: newClientService[2],
                        fullpath: newClientService[1],
                      };
                    }
                  );
                  user.userData[0]._source.clientservice =
                    arrClientServicesObject;
                }
              }
              let userDataSource = user.userData
                ? user.userData[0]?._source
                : null;
              let userIndustries =
                userDataSource &&
                  userDataSource.industry &&
                  userDataSource.industry.length > 0
                  ? userDataSource.industry.map((e: any) => e.id).join("|")
                  : null;
              let userClientServices =
                userDataSource &&
                  userDataSource.clientservice &&
                  userDataSource.clientservice.length > 0
                  ? userDataSource.clientservice.map((e: any) => e.id).join("|")
                  : null;
              userDataObj = {
                email: userDataSource?.kxp_email || userData?.kxp_email,
                geographyId:
                  userDataSource?.geography &&
                    userDataSource?.geography?.length > 0
                    ? userDataSource?.geography[0]?.id
                    : 0,
                industryIds: userIndustries,
                clientServicesIds: userClientServices,
              };
              userSchema = new UserSchema(
                userDataSource,
                user.isCurrentUserCurator,
                user.isCurrentUserTaxonomyAdmin
              );
            } else {
              userData.kxp_email = SharedService.GetInstance().claims
                ? SharedService.GetInstance().claims.preferred_username
                : SharedService.GetInstance().userName;
              userData.title = SharedService.GetInstance().claims
                ? SharedService.GetInstance().claims.name
                : SharedService.GetInstance().userName;
              if (ifOverride) {
                const urlParams = new URLSearchParams(window.location.search);
                var clientServices = urlParams.get("clientService");
                var Geography = urlParams.get("geography");
                if (Geography) {
                  var arrGeography = Geography.split("|");
                  var arrGeographyObject = arrGeography.map(
                    (Geography: string) => {
                      var newGeography = Geography.split(";");
                      return {
                        id: newGeography[0],
                        name: newGeography[2],
                        fullpath: newGeography[1],
                      };
                    }
                  );
                  user.userData[0]._source.geography = arrGeographyObject;
                }

                if (clientServices) {
                  var arrClientServices = clientServices.split("|");
                  var arrClientServicesObject = arrClientServices.map(
                    (clientservice: string) => {
                      var newClientService = clientservice.split(";");
                      return {
                        id: newClientService[0],
                        name: newClientService[2],
                        fullpath: newClientService[1],
                      };
                    }
                  );
                  user.userData[0]._source.clientservice =
                    arrClientServicesObject;
                }
              }
              let userDataSource = user.userData
                ? user.userData[0]?._source
                : null;
              let userIndustries =
                userDataSource &&
                  userDataSource.industry &&
                  userDataSource.industry.length > 0
                  ? userDataSource.industry.map((e: any) => e.id).join("|")
                  : null;
              let userClientServices =
                userDataSource &&
                  userDataSource.clientservice &&
                  userDataSource.clientservice.length > 0
                  ? userDataSource.clientservice.map((e: any) => e.id).join("|")
                  : null;
              userDataObj = {
                email: userDataSource?.kxp_email || userData?.kxp_email,
                geographyId:
                  userDataSource?.geography &&
                    userDataSource?.geography?.length > 0
                    ? userDataSource?.geography[0]?.id
                    : 0,
                industryIds: userIndustries,
                clientServicesIds: userClientServices,
              };
              userSchema = new UserSchema(
                userData,
                user?.isCurrentUserCurator || false,
                user.isCurrentUserTaxonomyAdmin || false
              );
            }
          }
          SharedService.currentUserObj = userDataObj;
          let userRoleInfo = await this.GetUserRoleGroupsAndPrivileges();
          await this.PageLevelRestrictions();
          await this.AuthorizeBasedOnUserRoleGroup(
            userRoleInfo?.roleGroups,
            userRoleInfo?.rolePrivileges
          );

          var fullName = userSchema.title.split(", ");
          if (fullName.length >= 1) {
            userSchema.kxp_pdiempattributespreflastname = fullName[0];
            userSchema.kxp_pdiempattributespreffirstname = fullName[1];
          }
          let data = await SharedService.GetInstance().Actions.Get.GetUserImage(
            SharedService.GetInstance().account.username
          );
          userSchema.kxp_pictureurl = data;
          resolve(userSchema);
        });
      };

      public async GetUserRoleGroupsAndPrivileges(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.RoleGroupsAndPrivileges,
          StorageType.Local,
          this.GetUserRolesGroupsPrivilegesFromAPI,
          false,
          true
        );
      }
      public GetUserRolesGroupsPrivilegesFromAPI = (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
          try {
            let currentUserData = SharedService.currentUserObj;
            let _webAPIUrl = SharedService.documentsWebApiUrl;
            let userEmail = currentUserData.email || "";

            await fetch(
              _webAPIUrl + "/User/GetRoleGroupsAndPrivileges/" + userEmail
            )
              .then((res) => res.json())
              .then((data) => {
                resolve(data);
              });
            //}
          } catch (result) {
            return null;
          }
        });
      };

      public async PageLevelRestrictions(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.PageLevelRestrictions,
          StorageType.Session,
          this.GetPageLevelRestrictionsFromAPI
        );
      }
      public GetPageLevelRestrictionsFromAPI = (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
          try {
            let userCountryId = 0,
              userIndustryIds = "",
              userClientserviceIds = "",
              currentUserData = SharedService.currentUserObj,
              _webAPIUrl = SharedService.documentsWebApiUrl;

            userCountryId =
              currentUserData && currentUserData.geographyId
                ? parseInt(currentUserData.geographyId.replace(/ /g, "")) ||
                userCountryId
                : userCountryId;
            userIndustryIds =
              currentUserData && currentUserData.industryIds
                ? currentUserData.industryIds.replace(/ /g, "") ||
                userIndustryIds
                : userIndustryIds;
            userClientserviceIds =
              currentUserData && currentUserData.clientServicesIds
                ? currentUserData.clientServicesIds.replace(/ /g, "") ||
                userClientserviceIds
                : userClientserviceIds;
            let params = {
              userCountryId: userCountryId,
              userIndustryIds: userIndustryIds,
              userClientserviceIds: userClientserviceIds,
            };
            await fetch(_webAPIUrl + "/User/GetPageLevelRestrictions", {
              method: "POST",
              body: JSON.stringify(params),
            })
              .then((res) => res.json())
              .then((data) => {
                resolve(data);
              });
            //}
          } catch (result) {
            return null;
          }
        });
      };

      public AuthorizeBasedOnUserRoleGroup = async (
        roleGroups: any,
        rolePrivileges: any
      ): Promise<any> => {
        // return new Promise(async (resolve, reject) => {
        try {
          const currentUserData = SharedService.currentUserObj;
          const urlPathName = window.location.pathname.toLocaleLowerCase();
          if (urlPathName.indexOf("edit") !== -1) {
            const isEndUser = Object.values(roleGroups).every(
              (value) => value === false
            );
            if (isEndUser) {
              window.location.href = window.location.origin + "/unauthorized";
              return;
            }
          }

          if (urlPathName.indexOf("publishing") !== -1) {
            const isEndUser = Object.values(roleGroups).every(
              (value) => value === false
            );
            if (
              isEndUser ||
              (!roleGroups.productAdministrators &&
                !roleGroups.publishingTeamLeaders &&
                !roleGroups.publishers &&
                !roleGroups.publishersDeliveryExcellence)
            ) {
              window.location.href = window.location.origin + "/unauthorized";
              return;
            }
          }

          if (urlPathName.indexOf("edit") !== -1) {
            let userCountryId = 0;
            let documentId = 0;
            let _webAPIUrl = SharedService.documentsWebApiUrl;

            let splitPath = window.location.pathname.split("/");
            documentId = parseInt(splitPath[splitPath.length - 1]);
            userCountryId =
              currentUserData && currentUserData.geographyId
                ? parseInt(currentUserData.geographyId.replace(/ /g, "")) ||
                userCountryId
                : userCountryId;
            //userCountryId = 2246;
            let params = {
              userRoleGroupsJson: JSON.stringify(roleGroups),
              userRolePrivilegesJson: JSON.stringify(rolePrivileges),
              userCountryId: userCountryId,
              documentId: documentId,
            };
            await fetch(_webAPIUrl + "/User/UserDataRestrictions", {
              method: "POST",
              body: JSON.stringify(params),
            })
              .then((response) => {
                if (response) {
                  if (response.status === 401) {
                    window.location.href =
                      window.location.origin + "/unauthorized";
                    return;
                  }
                }
              })
              .catch((err) => {
                return null;
              });
          }
        } catch (result) {
          return null;
        }
        // });
      };

      public GetSPAuth(): any {
        return StorageHandler.Get(
          Constants.SessionStorageItems.SPAuth,
          StorageType.Local,
          this.GetSPAuthFromSP
        );
      }

      public GetSPAuthFromSP = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          var encode = encodeURI(SharedService.uiUrl + "/Redirect.aspx");
          StorageHandler.Add(
            "SPAuthTargetPage",
            window.location.href,
            StorageType.Local
          );
          window.location.href = `${SharedService.SpDomainUrl}${SharedService.spSiteUrl}/_layouts/15/appredirect.aspx?client_id=${SharedService.spClientId}&redirect_uri=${encode}`;
          resolve(null);
        });
      };
      public async GetConfiguration(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.Config,
          StorageType.Local,
          this.GetConfigurationFromWebAPI
        );
      }

      public GetConfigurationFromWebAPI = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let configurations: any = {};
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/configuration")
            .then((response) => response.json())
            .then((items) => {
              items.forEach((item: any) => {
                configurations[
                  item[Constants.Columns.DKXConfigurationKey.fName]
                ] = item[Constants.Columns.DKXConfigurationValue.fName];
              });
              resolve(configurations);
            });
        });
      };
      public async GetTokenImages(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.SPAuth,
          StorageType.Local,
          this.GetTokenImagesFromWebAPI,
          true
        );
      }

      public GetTokenImagesFromWebAPI = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          var postFix = window.location.host.substring(
            0,
            window.location.host.indexOf(".")
          );
          var SPAuthToken = localStorage.getItem("SPAuthCookie_" + postFix);
          SPAuthToken = JSON.parse(SPAuthToken ? SPAuthToken : "").data
            .SPAppToken;
          var tokenRequest = { SPAppToken: SPAuthToken, SPTokenImages: "" };
          fetch(_webAPIUrl + "api/TokenImages", {
            method: "POST",
            body: JSON.stringify(tokenRequest),
          })
            .then((response) => response.json())
            .then((items) => {
              resolve(items);
            });
        });
      };

      public async GetVersion(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.Version,
          StorageType.Local,
          this.GetVersionFromWebAPI
        );
      }

      public GetVersionFromWebAPI = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/version")
            .then((response) => response.json())
            .then((items) => {
              resolve(items);
            });
        });
      };
      //Version Control For KX Assets
      public async GetKXDocsVersion(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.KXDocsVersion,
          StorageType.Local,
          this.GetKXDocsVersionFromWebAPI
        );
      }
      public GetKXDocsVersionFromWebAPI = async (): Promise<any> => {
        return await new Promise(async (resolve, reject) => {
          let _webAPIUrl = SharedService.documentsWebApiUrl;
          fetch(_webAPIUrl + "/api/version")
            .then((response) => response.text())
            .then((items) => {
              resolve(items);
            });
        });
      };

      public ValidateToken = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/ValidateToken", {
            method: "POST",
            body: JSON.stringify(SharedService.GetInstance().Data.SPAuth),
          })
            .then((response) => {
              if (response.status != 500) resolve(response.json());
              else resolve(null); //TODO:need to replace by application error msg:Noa
            })
            .then((items) => {
              resolve(items);
            });
        });
      };
      //save in local

      public async GetConfiguration2(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.Config,
          StorageType.Local,
          this.GetConfiguration2FromWebAPI
        );
      }
      public GetConfiguration2FromWebAPI = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(
            _webAPIUrl +
            "api/configuration2?lang=" +
            SharedService.GetInstance().lang
          )
            .then((response) => response.json())
            .then((items) => {
              resolve(items);
            });
        });
      };

      // public async GetWhatsNewConfiguration(): Promise<any> {
      //   return await StorageHandler.Get(
      //     Constants.SessionStorageItems.WhatsNewConfig,
      //     StorageType.Session,
      //     this.GetWhatsNewConfigurationFromWebAPI
      //   );
      // }

      // public GetWhatsNewConfigurationFromWebAPI = (): Promise<any> => {
      //   return new Promise((resolve, reject) => {
      //     let configurations: any = {};
      //     // TODO: implement familuar to GetLabelsFromWebAPI() function
      //     let _webAPIUrl = SharedService.webUrl;
      //     fetch(_webAPIUrl + "api/whatsnewconfig")
      //       .then((response) => response.json())
      //       .then((items) => {
      //         if (items.length > 0)
      //           items.forEach((item: any) => {
      //             configurations[
      //               item[Constants.Columns.DKXWhatsNewQueryKey.fName]
      //             ] = item[Constants.Columns.DKXWhatsNewQueryValue.fName];
      //           });
      //         resolve(configurations);
      //       });
      //   });
      // };

      public async GetLang(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.Lang,
          StorageType.Local,
          this.GetLangApi
        );
      }
      public GetLangApi = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          resolve("EN");
        });
      };

      public async GetBadges(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.Badges,
          localStorage.Session,
          this.GetBadgesFromApi
        );
      }
      public GetBadgesFromApi = (badges: any): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/Badges", {
            method: "POST",
            body: JSON.stringify(badges),
          })
            .then((response) => response.json())
            .then((items) => {
              resolve(items);
            });
        });
      };
      public async GetLabels(): Promise<any> {
        return await StorageHandler.Get(
          this.GetSessionKeyByLang(SharedService.instance.lang),
          StorageType.Local,
          this.GetLabelsFromWebAPI
        );
      }

      public GetSessionKeyByLang(lang: string) {
        var cachedKey = Constants.SessionStorageItems.Labels; //EN
        if (lang == "FR") {
          cachedKey = Constants.SessionStorageItems.LabelsFR;
        } else if (lang == "ES") {
          cachedKey = Constants.SessionStorageItems.LabelsES;
        } else if (lang == "JA") {
          cachedKey = Constants.SessionStorageItems.LabelsJA;
        } else if (lang == "ZH") {
          cachedKey = Constants.SessionStorageItems.LabelsZH;
        }
        return cachedKey;
      }

      public async GetResearchTools(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.ResearchTools,
          StorageType.Local,
          this.GetResearchToolsListItems
        );
      }

      public GetResearchToolsListItems = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          let researchToolsItems: IResearchToolsItem[] = [];
          fetch(_webAPIUrl + "api/researchTool")
            .then((response) => response.json())
            .then((items) => {
              if (items) {
                items.forEach((item: ResearchToolsItem) => {
                  var newItem = new IResearchToolsItem(
                    item.Title,
                    item.dkxImage.Url,
                    item.dkxAbstract,
                    item.dkxGeography,
                    item.dkxVItemLink.Url
                  );
                  researchToolsItems.push(newItem);
                  if (researchToolsItems.length == items.length)
                    resolve(researchToolsItems);
                });
              }
            });
        });
      };
      public async GetMsalTokenDpn(): Promise<any> {
        // Config object to be passed to Msal on creation
        const msalConfig = SharedService.msalConfig;

        return new Promise(async (resolve, reject) => {
          // Create an instance of PublicClientApplication
          SharedService.GetInstance().msalClient = new PublicClientApplication(
            msalConfig
          );

          const loginRequest = SharedService.apiScopes.loginRequest;
          const tokenRequest = SharedService.apiScopes.tokenRequestDPN;

          let loginRes =
            await SharedService.GetInstance().msalClient.handleRedirectPromise();
          //this.handleResponse(loginRes);
          let account = SharedService.GetInstance().userName;
          let accountObj = SharedService.GetInstance().account;

          if (account && loginRes) {
            //initInterceptor(loginRes.accessToken); // unregister previously set interceptors and register new one
            resolve(loginRes.accessToken);
          } else if (account) {
            tokenRequest.account = accountObj;
            try {
              loginRes =
                await SharedService.GetInstance().msalClient.acquireTokenSilent(
                  tokenRequest
                );
              //this.handleResponse(loginRes);

              //initInterceptor(loginRes.accessToken); // unregister previously set interceptors and register new one
              resolve(loginRes.accessToken);
            } catch (err) {
              console.log(err);
              resolve("");
            }
          } else {
            resolve("");
          }
        });
      }
      public async GetMsalToken(): Promise<any> {
        // Config object to be passed to Msal on creation
        const msalConfig = SharedService.msalConfig;

        return new Promise(async (resolve, reject) => {
          // Create an instance of PublicClientApplication
          SharedService.GetInstance().msalClient = new PublicClientApplication(
            msalConfig
          );

          const loginRequest = SharedService.apiScopes.loginRequest;
          const tokenRequest = SharedService.apiScopes.tokenRequest;

          let loginRes =
            await SharedService.GetInstance().msalClient.handleRedirectPromise();
          this.handleResponse(loginRes);
          let account = SharedService.GetInstance().userName;
          let accountObj = SharedService.GetInstance().account;

          if (account && loginRes) {
            initInterceptor(loginRes.accessToken); // unregister previously set interceptors and register new one
            resolve(loginRes.accessToken);
          } else if (account) {
            tokenRequest.account = accountObj;
            try {
              loginRes =
                await SharedService.GetInstance().msalClient.acquireTokenSilent(
                  tokenRequest
                );
              this.handleResponse(loginRes);

              initInterceptor(loginRes.accessToken); // unregister previously set interceptors and register new one

              resolve(loginRes.accessToken);
            } catch (err) {
              console.log(err);
              await SharedService.GetInstance().msalClient.acquireTokenRedirect(
                loginRequest
              );
            }
          } else {
            await SharedService.GetInstance().msalClient.loginRedirect(
              loginRequest
            );
          }
        });
      }
      public handleResponse = (response: any) => {
        if (response !== null) {
          SharedService.GetInstance().account = response.account;
          SharedService.GetInstance().userName = response.account.username;
          SharedService.GetInstance().claims = response.idTokenClaims;
        } else {
          this.selectAccount();
        }
      };
      public selectAccount = () => {
        const currentAccounts =
          SharedService.GetInstance().msalClient.getAllAccounts();
        // const claims = SharedService.GetInstance().msalClient.idTokenClaims();

        if (currentAccounts.length === 0) {
          return;
        } else if (currentAccounts.length > 1) {
          // Add your account choosing logic here
          console.warn("Multiple accounts detected.");
        } else if (currentAccounts.length === 1) {
          SharedService.GetInstance().userName = currentAccounts[0].username;
          SharedService.GetInstance().account = currentAccounts[0];
        }
      };
      public GetLabelsFromWebAPI = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let labels: any = {};

          let _webAPIUrl = SharedService.webUrl;
          // TODO: update to relative url after publishing
          fetch(
            _webAPIUrl + "api/labels?lang=" + SharedService.GetInstance().lang
          )
            .then((response) => response.json())
            .then((items) => {
              var columnName: string;
              switch (
              SharedService.GetInstance().lang
                ? SharedService.GetInstance().lang
                : "EN"
              ) {
                case "EN":
                  columnName = Constants.Columns.DKXComponentLabel.fName;
                  break;
                case "ES":
                  columnName = Constants.Columns.DKXComponentLabelSPN.fName;

                  break;
                case "FR":
                  columnName = Constants.Columns.DKXComponentLabelFR.fName;

                  break;
                case "ZH":
                  columnName = Constants.Columns.DKXComponentLabelZH.fName;

                  break;
                case "JA":
                  columnName = Constants.Columns.DKXComponentLabelJA.fName;

                  break;
                default:
                  columnName = Constants.Columns.DKXComponentLabel.fName;

                  break;
              }
              if (items.length > 0)
                items.forEach((item: any) => {
                  labels[item[Constants.Columns.DKXComponentKey.fName]] =
                    item[columnName];
                });
              resolve(labels);
            });
        });
      };
      public async GetCTPMapping(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.CtpMapping,
          StorageType.Local,
          this.GetCTPMappingFromWebAPI
        );
      }

      public GetCTPMappingFromWebAPI = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          // TODO: update to relative url after publishing
          fetch(_webAPIUrl + "api/ctmapping")
            .then((response) => response.json())
            .then((items) => {
              resolve(items);
            });
          // let _webAPIUrl = SharedService.isDevEnvironemnt ? Constants.General.DevWebApiUrl : Constants.General.ProdWebApiUrl;
          // fetch(_webAPIUrl + 'api/configuration')
          //     .then(response => response.json())
          //     .then(items => {
          //         items.forEach((item: any) => {
          //             configurations[item[Constants.Columns.DKXConfigurationKey.fName]] = item[Constants.Columns.DKXConfigurationValue.fName];
          //         });
          //         resolve(configurations);
          //     });
        });
      };
      public async GetCategoryOrder(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.CategoryOrder,
          StorageType.Local,
          this.GetCategoryOrderFromWebAPI
        );
      }

      public GetCategoryOrderFromWebAPI = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          // TODO: update to relative url after publishing
          fetch(_webAPIUrl + "api/categoryOrder")
            .then((response) => response.json())
            .then((items) => {
              resolve(items);
            });
        });
      };

      public GetCTPMappingForCombineCt = (
        source: string,
        l1?: string,
        l2?: string,
        internalContentType?: string
      ): any => {
        var ctMappingPerCombine;
        if (internalContentType) {
          if (!l2 && l1 && source) {
            let CTMappinngArr =
              SharedService.GetInstance().Data.CTPMapping || [];
            let newDPNObj = {
              Application: {
                Id: 14,
                Title: "DPN",
              },
              ContentTypeL1: {
                Id: 11,
                ContentTypeName: "People Profiles",
                CTLevel: 1,
              },
              ContentTypeL2: null,
              GlobalContentType: {
                Id: 49701,
                ContentTypeName: "",
                CTLevel: 0,
              },
              Label: "dkxNone",
              JsonMapping: "DPN KXNG",
              InternalContentType: "DPN KXNG",
              CollectionCategory: {
                Id: 25,
                Label: "Specialists",
              },
              SearchCategory: {
                Id: 9,
                Label: "People",
              },
            };
            let newCollectionsObj = {
              Application: {
                Id: 3,
                Title: "KXNG",
              },
              ContentTypeL1: {
                Id: 2,
                ContentTypeName: "Collections",
                CTLevel: 1,
              },
              ContentTypeL2: null,
              GlobalContentType: {
                Id: 6825341,
                ContentTypeName: "",
                CTLevel: 0,
              },
              Label: "dkxCollection",
              JsonMapping: "Curated Collection KXNG",
              InternalContentType: "Curated Collection KXNG",
              CollectionCategory: {
                Id: null,
                Label: null,
              },
              SearchCategory: {
                Id: 1,
                Label: "Curated Collections",
              },
            };
            CTMappinngArr = [...CTMappinngArr, newDPNObj];
            CTMappinngArr = [...CTMappinngArr, newCollectionsObj];
            ctMappingPerCombine = CTMappinngArr
              ? CTMappinngArr.filter(
                (ct) =>
                  ct.Application.Title == source &&
                  ct.InternalContentType == internalContentType &&
                  ct.ContentTypeL1.ContentTypeName == l1
              )
              : [];
          } else {
            let CTMappinngArr = SharedService.GetInstance().Data.CTPMapping;
            ctMappingPerCombine = CTMappinngArr
              ? CTMappinngArr.filter((ct) => {
                if (ct.ContentTypeL2 && ct.ContentTypeL2.ContentTypeName) {
                  return (
                    ct.Application.Title == source &&
                    ct.InternalContentType == internalContentType &&
                    ct.ContentTypeL1.ContentTypeName == l1 &&
                    ct.ContentTypeL2.ContentTypeName == l2
                  );
                } else if (!l2) {
                  return (
                    ct.Application.Title == source &&
                    ct.InternalContentType == internalContentType &&
                    ct.ContentTypeL1.ContentTypeName == l1
                  );
                }
              })
              : [];
          }
          return ctMappingPerCombine.length > 0 ? ctMappingPerCombine[0] : ""; //change to label
        }
      };
      public async GetApplications(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.Application,
          StorageType.Local,
          this.GetApplicationsFromWebAPI
        );
      }

      public GetApplicationsFromWebAPI = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/Applictions")
            .then((response) => response.json())
            .then((items) => {
              resolve(items);
            });
        });
      };
    })();
  })();
  //#endregion services

  public Subscription = new (class {
    public Get = new (class {
      public GetSubscription = (): Promise<ISubscriptionModel> => {
        return new Promise((resolve, reject) => {
          fetch(SharedService.webUrl + "api/getSubscription").then(
            (response) => {
              if (response.status == 200) {
                response
                  .json()
                  .then((subscriptionModel: ISubscriptionModel) => {
                    if (!subscriptionModel.Subscription?.isEnabled) {
                      subscriptionModel.AllSubscriptionContentTypes &&
                        subscriptionModel.AllSubscriptionContentTypes.map(
                          (ct: IContentType) => {
                            ct.isChecked =
                              (subscriptionModel.Subscription &&
                                subscriptionModel.Subscription.ContentTypes?.filter(
                                  (x: IContentType) =>
                                    x.Id == ct.ContentTypeL1.Id
                                ).length > 0) ||
                              false;
                          }
                        );
                    } else {
                      subscriptionModel.AllContentQueryTypes &&
                        subscriptionModel.AllContentQueryTypes.map(
                          (obj: IContentQueryType) => {
                            if (
                              obj.ContentTitle ==
                              Constants.LabelKeys.BasedOnInterests
                            ) {
                              var currentSelectedInDb =
                                subscriptionModel.Subscription &&
                                subscriptionModel.Subscription.ContentQueryTypes.filter(
                                  (x) => x.Id == obj.Id
                                ).length > 0;
                              obj.isChecked =
                                subscriptionModel.MyInterests.length > 0 &&
                                currentSelectedInDb;
                              obj.isEnabled =
                                subscriptionModel.MyInterests.length > 0;

                              //move interest to included of empty
                              if (
                                subscriptionModel.MyInterests.filter(
                                  (x) => x.IncludedInSubscription
                                ).length == 0
                              ) {
                                subscriptionModel.MyInterests.map(
                                  (tag) => (tag.IncludedInSubscription = true)
                                );
                              }
                            } else {
                              obj.isChecked = false;
                              obj.isEnabled = false;
                            }
                          }
                        );

                      subscriptionModel.AllSubscriptionContentTypes &&
                        subscriptionModel.AllSubscriptionContentTypes.map(
                          (ct: IContentType) => {
                            ct.isChecked =
                              (subscriptionModel.Subscription &&
                                subscriptionModel.Subscription.ContentTypes.filter(
                                  (x) => x.Id == ct.ContentTypeL1.Id
                                ).length > 0) ||
                              false;
                          }
                        );
                    }

                    resolve(subscriptionModel);
                  });
              } else {
                console.log("Subscription ERROR");
              }
            }
          );
        });
      };

      public GetSubscriberFollowers = (): Promise<IFollower[]> => {
        return new Promise((resolve, reject) => {
          let _SPAppToken;
          if (SharedService.instance.Data.SPAuth == null) {
            var postFix = window.location.host.substring(
              0,
              window.location.host.indexOf(".")
            );
            _SPAppToken = localStorage.getItem("SPAuthCookie_" + postFix);
            _SPAppToken = JSON.parse(_SPAppToken ? _SPAppToken : "").data
              .SPAppToken;
          } else {
            _SPAppToken = SharedService.instance.Data.SPAuth["SPAppToken"];
          }

          fetch(SharedService.webUrl + "api/getSubscriberFollowers", {
            method: "POST",
            body: JSON.stringify({
              SPAppToken: _SPAppToken,
            }),
          }).then((response) => {
            if (response.status == 200) {
              response.json().then((followers: IFollower[]) => {
                resolve(followers);
              });
            } else {
              resolve([]);
            }
          });
        });
      };

      public GetSubscriberPA = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          fetch(SharedService.webUrl + "api/getSubscriberPA").then(
            (response) => {
              if (response.status == 200) {
                response.json().then((practisealignments: any) => {
                  resolve(practisealignments);
                });
              } else {
                resolve(null);
              }
            }
          );
        });
      };

      public RemoveSubscription = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          fetch(SharedService.webUrl + "api/removeSubscription").then(
            (response) => {
              if (response.status == 200) {
                console.log("Unsubscribed");
                resolve(true);
              } else {
                console.log("Unsubscribe ERROR");
                resolve(false);
              }
            }
          );
        });
      };

      public AddFollowToSubscription = (sourceId: string): Promise<any> => {
        return new Promise((resolve, reject) => {
          fetch(
            SharedService.webUrl +
            "api/addFollowToSubscription?sourceId=" +
            sourceId
          ).then((response) => {
            if (response.status == 200) {
              console.log("Subscribed");
            }
          });
        });
      };
    })();

    public Post = new (class {
      public updateSubscription = (
        subscription: ISubscriptionModel,
        myPracticeAlignment: any,
        mySubscriptionFollowers: IFollower[]
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          subscription.Followers = mySubscriptionFollowers;
          fetch(SharedService.webUrl + "api/updateSubscription", {
            method: "POST",
            body: JSON.stringify(subscription),
          }).then((response) => {
            if (response.status == 200) {
              console.log("Subscription updated");
              resolve(true);
            } else {
              console.log("Update ERROR");
              resolve(false);
            }
          });
        });
      };
    })();
  })();

  public Actions = new (class {
    public Get = new (class {
      public GetRelatedCollection = (
        itemId: number,
        listName: string,
        userPermission: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/RelatedCollection", {
            method: "POST",
            body: JSON.stringify({
              ItemId: itemId,
              Type: Relatedtype.RelatedCollections,
              ListName: listName,
              SPAppToken: SharedService.instance.Data.SPAuth["SPAppToken"],
              UserPermissionFlag: userPermission,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetCuratedCollAllFeilds = (
        ItemId: number,
        listName: string,
        userPermission: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          let CuratedCollectionItem: ICuratedCollection;

          fetch(_webAPIUrl + "api/RelatedCollection", {
            method: "POST",
            body: JSON.stringify({
              ItemId: ItemId,
              Type: Relatedtype.AllFields,
              ListName: listName,
              SPAppToken: SharedService.instance.Data.SPAuth["SPAppToken"],
              UserPermissionFlag: userPermission,
            }),
          })
            .then((response) => {
              if (response.status == 401) {
                StorageHandler.Remove(
                  Constants.SessionStorageItems.SPAuth,
                  StorageType.Local
                );
                SharedService.instance.SiteSettings.Read.GetSPAuth();
              }
              return response.json();
            })
            .then((res) => {
              if (res != null && res != "") {
                CuratedCollectionItem = new ICuratedCollection(
                  "",
                  res.hasOwnProperty("dkxImage") && res.dkxImage
                    ? res.dkxImage.Url
                    : "",
                  res.hasOwnProperty("Title") ? res.Title : "",
                  res.hasOwnProperty("dkxRelatedLink") && res.dkxRelatedLink
                    ? res.dkxRelatedLink.Url
                    : "",
                  res.hasOwnProperty("dkxAbstract") ? res.dkxAbstract : "",
                  res.hasOwnProperty("ID") ? res.ID : "",
                  res.hasOwnProperty("dkxGeography") ? res.dkxGeography : "",
                  [res.dkxTopicTaxonomy],
                  res.hasOwnProperty("dkxTopicImportance")
                    ? res.dkxTopicImportance
                    : "",
                  res.hasOwnProperty("Modified") ? res.Modified.toString() : "",
                  res.hasOwnProperty("dkxIsItemArchived")
                    ? (res.dkxIsItemArchived as boolean)
                    : false,
                  res.hasOwnProperty("dkxTopBannerImage")
                    ? res.dkxTopBannerImage
                    : "",
                  "0x01003373A8E6E3E86C4E9D779DF717AFA5DA"
                );
                resolve(CuratedCollectionItem);
              } else {
                resolve(CuratedCollectionItem);
              }
            });
        });
      };

      public GetExploreConcepts = (
        ItemId: number,
        listName: string,
        userPermission: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/RelatedCollection", {
            method: "POST",
            body: JSON.stringify({
              ItemId: ItemId,
              Type: Relatedtype.ExploreConcept,
              ListName: listName,
              SPAppToken: SharedService.instance.Data.SPAuth["SPAppToken"],
              UserPermissionFlag: userPermission,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetRelatedCommunities = (
        ItemId: number,
        listName: string,
        userPermission: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/RelatedCollection", {
            method: "POST",
            body: JSON.stringify({
              ItemId: ItemId,
              Type: Relatedtype.RelatedCommunities,
              ListName: listName,
              UserPermissionFlag: userPermission,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetCureatedCollectionQuery = (CuratedId: number): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(
            _webAPIUrl + "api/cureatedCollectionQuery?CuratedId=" + CuratedId
          )
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetCollectionLeaders = (
        ItemId: number,
        listName: string,
        userPermission: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/RelatedCollection", {
            method: "POST",
            body: JSON.stringify({
              ItemId: ItemId,
              Type: Relatedtype.CollectionLeaders,
              ListName: listName,
              UserPermissionFlag: userPermission,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then(async (res) => {
              if (res) {
                var requests = [];
                var indexeResults = [];

                var query = {
                  size: 100,
                  sort: [],
                  query: {
                    bool: {
                      should: [],
                    },
                  },
                };
                var should: any = [];
                res.forEach(async (element: any) => {
                  var accountName = element.DpnLink.substring(
                    element.DpnLink.lastIndexOf("/") + 1
                  );

                  var a = { match: { kxp_accountname: accountName } };
                  should.push(a);
                });
                query.query.bool.should = should;

                var respone =
                  await SharedService.GetInstance().CategoryView.Get.GetItemsPerCategory(
                    query
                  );
                res.forEach(async (element: any) => {
                  var accountName = element.DpnLink.substring(
                    element.DpnLink.lastIndexOf("/") + 1
                  );

                  var leader = respone.filter(
                    (result) => result.kxp_accountname == accountName
                  );
                  if (element.Name == null || element.Name == "")
                    element.Name = leader[0] ? leader[0].title : "";
                  if (element.Role == null || element.Role == "")
                    element.Role = leader[0] ? leader[0].kxp_role : "";
                  if (element.image == null || element.image == "")
                    element.image = leader[0] ? leader[0].minicardimage : "";
                });
                resolve(res);
              } else {
                resolve(res);
              }

              //     var tokenRequest = Constants.APIScopes.tokenRequestGraph;
              // tokenRequest.account=SharedService.GetInstance().account
              // var loginRes = await SharedService.GetInstance().msalClient.acquireTokenSilent(tokenRequest);
              //     fetch("https://graph.microsoft.com/v1.0/$batch", {
              //       method: "POST",
              //       headers: {
              //         "Content-Type": "application/json",
              //         'Authorization': 'Bearer ' + loginRes.accessToken,
              //       },
              //       body: JSON.stringify({
              //         requests: [
              //           {
              //             id: 1,
              //             method: "GET",
              //             url: "/users/noatias@deloitte.co.il/photo/$value",
              //           },
              //           {
              //             id: 2,
              //             method: "GET",
              //             url: "/users/dkedmi@deloitte.co.il/photo/$value",
              //           },
              //         ],
              //       }),
              //     })
              //       .then((data) => {
              //         return data.json();
              //       })
              //       .then((data) => {
              //         resolve(res);
              //       });
            });
        });
      };

      public GetRelatedLinks = (
        itemId: number,
        listName: string,
        userPermission: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/RelatedCollection", {
            method: "POST",
            body: JSON.stringify({
              ItemId: itemId,
              Type: Relatedtype.RelatedLinks,
              ListName: listName,
              SPAppToken: SharedService.instance.Data.SPAuth["SPAppToken"],
              UserPermissionFlag: userPermission,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetPracticeProfileLink = (
        itemId: number,
        listName: string,
        userPermission: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/RelatedCollection", {
            method: "POST",
            body: JSON.stringify({
              ItemId: itemId,
              Type: Relatedtype.PracticeProfileLink,
              ListName: listName,
              SPAppToken: SharedService.instance.Data.SPAuth["SPAppToken"],
              UserPermissionFlag: userPermission,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetMoreAboutPracticeProfile = (
        itemId: number,
        listName: string,
        userPermission: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/RelatedCollection", {
            method: "POST",
            body: JSON.stringify({
              ItemId: itemId,
              Type: Relatedtype.MoreAboutPracticeProfile,
              ListName: listName,
              SPAppToken: SharedService.instance.Data.SPAuth["SPAppToken"],
              UserPermissionFlag: userPermission,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetIsFirstLoad = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/isFirstLoad")
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetKeyContacts = (
        ItemId: number,
        listName: string,
        userPermission: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/RelatedCollection", {
            method: "POST",
            body: JSON.stringify({
              ItemId: ItemId,
              Type: Relatedtype.KeyContacts,
              ListName: listName,
              UserPermissionFlag: userPermission,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetReCommendedForYouItems = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          let clientServiceFullPath =
            SharedService.GetInstance().Data.CurrentUser.clientServiceFullPath;

          let _configurations = SharedService.GetInstance().Data.Configuration;
          let miniCardSearchMetadata: IMinicardSearchMetaConfig =
            _configurations
              ? JSON.parse(_configurations.MinicardSearchMetaConfig)
              : {};

          let getExplainData =
            miniCardSearchMetadata?.boostingExplained.enabled;

          fetch(
            _webAPIUrl +
            `api/recommendedForYou?clientServiceFullPath=${clientServiceFullPath}&getExplainData=${getExplainData}`
          )
            .then((response) => response.json())
            .then((items) => {
              resolve(items);
            });
        });
      };

      public GetAllCollections = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/getAllCollections")
            .then((response) => response.json())
            .then((items) => {
              resolve(items);
            });
        });
      };

      public FollowersNumberBySourceId = (arr: any[]): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          var url = _webAPIUrl + "api/followers/sourceId";
          // var url = sourceContentType
          //   ? _webAPIUrl +
          //     "api/followers/sourceId?SourceId=" +
          //     sourceId +
          //     "&&sourceContentType=" +
          //     sourceContentType
          //   : _webAPIUrl + "api/followers/sourceId?SourceId=" + sourceId;
          fetch(url, {
            method: "POST",
            body: JSON.stringify(arr),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetIfFollowCt = (
        ifCount: boolean,
        sourceId: string,
        sourceContentType?: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          var url = sourceContentType
            ? _webAPIUrl +
            "api/followers?SourceId=" +
            sourceId +
            "&&sourceContentType=" +
            sourceContentType
            : _webAPIUrl + "api/followers/sourceId?SourceId=" + sourceId;
          fetch(url)
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetIfPinCt = (linkToItem?: string): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          var url = _webAPIUrl + "api/pinCt?linkToItem=" + linkToItem;
          fetch(url)
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetIfFollows = (
        sourceIds: number[],
        ctNames: string[]
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/iffollowers", {
            method: "POST",
            body: JSON.stringify({
              SourceIds: sourceIds,
              SourceContentTypes: ctNames,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetIfPins = (linkToItem: string[]): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/ifPin", {
            method: "POST",
            body: JSON.stringify({
              LinkToItems: linkToItem,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public async GetMsalTokenSilent(): Promise<any> {
        // Config object to be passed to Msal on creation
        const msalConfig = SharedService.msalConfig;

        return new Promise(async (resolve, reject) => {
          // Create an instance of PublicClientApplication
          SharedService.GetInstance().msalClient = new PublicClientApplication(
            msalConfig
          );

          const loginRequest = SharedService.apiScopes.loginRequest;
          const tokenRequest = SharedService.apiScopes.tokenRequest;

          let loginRes =
            await SharedService.GetInstance().msalClient.handleRedirectPromise();
          this.handleResponse(loginRes);
          let account = SharedService.GetInstance().userName;
          if (account) {
            tokenRequest.account = account;

            try {
              let loginRes =
                await SharedService.GetInstance().msalClient.acquireTokenSilent(
                  tokenRequest
                );
              initInterceptor(loginRes.accessToken); // unregister previously set interceptors and register new one

              resolve(loginRes.accessToken);
            } catch (err) {
              console.log(err);
              await SharedService.GetInstance().msalClient.acquireTokenRedirect(
                loginRequest
              );
            }
          }
        });
      }

      public handleResponse = (response: any) => {
        if (response !== null) {
          SharedService.GetInstance().account = response.account;
          SharedService.GetInstance().userName = response.account.username;
          SharedService.GetInstance().claims = response.idTokenClaims;
        } else {
          this.selectAccount();
        }
      };

      public selectAccount = () => {
        const currentAccounts =
          SharedService.GetInstance().msalClient.getAllAccounts();
        // const claims = SharedService.GetInstance().msalClient.idTokenClaims();

        if (currentAccounts.length === 0) {
          return;
        } else if (currentAccounts.length > 1) {
          // Add your account choosing logic here
          console.warn("Multiple accounts detected.");
        } else if (currentAccounts.length === 1) {
          SharedService.GetInstance().userName = currentAccounts[0].username;
          SharedService.GetInstance().account = currentAccounts[0];
        }
      };

      public GetLastViewed = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/lastview")
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetPersonalColection = (
        startIndex?: number,
        endIndex?: number
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(
            _webAPIUrl +
            "api/personalCollection?StartIndex=" +
            startIndex +
            "&EndIndex=" +
            endIndex
          )
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetPersonalCollectionCategories = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/personalCollectionCategories")
            .then((response) => response.json())
            .then((res: ICategory[]) => {
              res.reverse();
              var uncategorized = res.find((category) => category.Id == 1);
              var newArr = res.filter((res) => res.Id !== 1);
              if (uncategorized) {
                newArr.unshift(uncategorized);
              } else {
                var uncategorized: ICategory | undefined = {
                  CTMatch: null,
                  CategoryName: "uncategorized",
                  Count: 0,
                  Id: 1,
                  Items: null,
                  Label: "uncategorized",
                  LoadingItems: true,
                  Open: false,
                  Order: 0,
                  Query: null,
                  SeeAllLink: null,
                };
                newArr.unshift(uncategorized);
              }
              resolve(newArr);
            });
        });
      };

      public GetIfExistCategoryPersonalCollection = (
        title: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(
            _webAPIUrl + "api/ifExistCategoryPersonalCollection?title=" + title
          )
            .then((response) => response.json())
            .then((res) => {
              resolve(res);
            });
        });
      };

      public AddPersonalCollectionCategory = (
        title: string = "noa"
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          var body = {
            newTitle: title,
          };
          fetch(_webAPIUrl + "api/addCategoryPersonalCollection", {
            method: "POST",
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((res) => {
              resolve(res);
            });
        });
      };

      public UpdatePersonalCollectionCategory = (
        title: string = "noa1",
        categoryId: number = 2
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          var body = {
            newTitle: title,
            id: categoryId,
          };
          fetch(_webAPIUrl + "api/updateCategoryPersonalCollection", {
            method: "POST",
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((res) => {
              resolve(res);
            });
        });
      };

      public RemovePersonalCollectionCategory = (
        categoryId: number,
        arrOfItemsLink: string[]
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          var body = {
            id: categoryId,
            linkToItems: arrOfItemsLink,
          };
          fetch(_webAPIUrl + "api/removeCategoryPersonalCollection", {
            method: "POST",
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((res) => {
              resolve(res);
            });
        });
      };

      public MovePersonalCollectionsCategory = (
        categoryId: number,
        arrOfItemsLink: string[]
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          var body = {
            categoryId: categoryId,
            linkToItems: arrOfItemsLink,
          };
          fetch(_webAPIUrl + "api/movePersonalCollectionsCategory", {
            method: "POST",
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetPersonalCollectionCategoryItems = (
        categoryId: number = 3
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          let language: any = LanguageList.filter((item: any) => item.key.toString() === (SharedService.GetInstance().lang));
          fetch(
            _webAPIUrl +
            "api/personalCollectionByCategory?categoryId=" +
            categoryId + `&language=${language[0].text}`
          )
            .then((response) => response.json())
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetCollections = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/collection")
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              var results = res.map((data: any) => {
                return new CardSchema(data);
              });
              resolve(results);
            });
        });
      };

      public GetMyInterests = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/MyInterests")
            .then((response) => {
              return response && response.ok ? response.json() : "";
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetSearchInterests = (title?: string): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/searchInterests?Title=" + title)
            .then((response) => {
              return response && response.ok ? response.json() : "";
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetSuggestedInterests = (
        startIndex: number,
        endIndex: number,
        email?: string
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          let _email =
            email != null
              ? email
              : SharedService.instance.Data.CurrentUser.kxp_email;

          fetch(
            _webAPIUrl +
            "api/allinterests?UserEmail=" +
            _email +
            "&startIndex=" +
            startIndex +
            "&endIndex=" +
            endIndex
          )
            .then((response) => {
              return response && response.ok ? response.json() : "";
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetPeopleYouFollow = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/PeopleFollowController")
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetUserNeedsData = async (): Promise<IUserNeedData[]> => {
        let _webAPIUrl = SharedService.webUrl;

        let result = await fetch(_webAPIUrl + "api/userneeds/getall");
        let needs: IUserNeedData[] = await result.json();
        return needs;
      };

      public GetUserNeeds = async (): Promise<IUserNeedData[]> => {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.UserNeeds,
          StorageType.Local,
          this.GetUserNeedsData
        );
      };

      public GetSelectedUserNeed = async (): Promise<string> => {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.SelectedNeed,
          StorageType.Session
        );
      };

      public GetInterestsPopupFlag = (): boolean => {
        return localStorage.getItem(
          Constants.SessionStorageItems.ManageInterestsFlag
        )
          ? true
          : false;
      };

      public GetPageID = async (listTitle: string): Promise<number> => {
        let _webAPIUrl = SharedService.webUrl;

        let result = await fetch(
          `${_webAPIUrl}api/getpageid?listTitle=${listTitle}`
        );
        let pageId: number = await result.json();
        return pageId;
      };

      public GetUserPermissionsOnList = async (
        listTitles: string[]
      ): Promise<IUserPermission[]> => {
        let output: IUserPermission[] = [];

        let getPermissionsFromApi = async (listTitle: string) => {
          let _webAPIUrl = SharedService.webUrl;

          let result = await fetch(
            `${_webAPIUrl}api/getuserpermission?listTitle=${listTitle}&userEmail=${SharedService.GetInstance().userName
            }`
          );
          let userPermission: IUserPermission = await result.json();
          return userPermission;
        };

        // Check if permission info exists in the session
        let userPermissions: {
          [Key: string]: IUserPermission;
        } = await StorageHandler.Get(
          Constants.SessionStorageItems.UserPermissions,
          StorageType.Local
        );

        if (userPermissions) {
          // filter out lists which do not have permssion in session yet
          let filteredLists = listTitles.filter(
            (l) => !userPermissions.hasOwnProperty(l)
          );

          if (!isEmpty(filteredLists)) {
            // Fetch list permissions
            output = await Promise.all(
              filteredLists.map((l) => getPermissionsFromApi(l))
            );
          } else {
            output = listTitles.map((l) => userPermissions[l]);
          }
        } else {
          userPermissions = {};
          output = await Promise.all(
            listTitles.map((l) => getPermissionsFromApi(l))
          );
        }

        // Persist permission in the session
        if (!isEmpty(output)) {
          output.forEach((o) => {
            userPermissions[o.ListTitle] = o;
          });

          StorageHandler.Add(
            Constants.SessionStorageItems.UserPermissions,
            userPermissions,
            StorageType.Local
          );
        }
        return output;
      };

      public GetTaxonomyTree = async (
        storageName: string,
        tableId: any,
        locationId: number
      ): Promise<any> => {
        let output: any;

        let treeBuilder = new KXTaxonomyTreeBuilder();
        let getTreeFromApi = treeBuilder.init(tableId, locationId);

        // Check if exists in the session
        let tree: {} = await StorageHandler.Get(
          Constants.SessionStorageItems.IndustryTaxonomyTree + locationId,
          StorageType.Session
        );

        if (tree) {
          output = await getTreeFromApi;
        } else {
          tree = {};
          output = output = await getTreeFromApi;
        }

        // Persist tree in the session
        if (!isEmpty(output)) {
          StorageHandler.Add(
            Constants.SessionStorageItems.IndustryTaxonomyTree + locationId,
            tree,
            StorageType.Session
          );
        }
        return output;
      };

      public GetUserImage = async (userEmail: string): Promise<string> => {
        return new Promise((resolve, reject) => {
          fetch(`${SharedService.dpnImgApiUrl}${userEmail}`, {
            headers: {
              "Ocp-Apim-Subscription-Key": SharedService.dpnImageKey,
              Authorization: "Bearer " + SharedService.DPNToken,
            },
          })
            .then((response) => response.json())
            .then((result) => {
              if (result && result.stringPhoto) {
                resolve(result.stringPhoto);
              } else {
                resolve("");
              }
            })
            .catch((data) => {
              resolve("");
            });
        });
      };

      public GetRelatedPeople = async (): Promise<IRelatedPeople> => {
        // Get related people
        var tokenRequest = SharedService.apiScopes.people;
        tokenRequest.account = SharedService.GetInstance().account;
        var loginRes =
          await SharedService.GetInstance().msalClient.acquireTokenSilent(
            tokenRequest
          );

        let peopleResponse: IRelatedPeople = await (
          await fetch(Constants.General.GraphUsersApiUrl, {
            headers: {
              Authorization: "Bearer " + loginRes.accessToken,
            },
          })
        ).json();
        return peopleResponse;
      };

      public GetPinCount = (linkToItem?: string): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          var url = _webAPIUrl + "api/pinCount?linkToItem=" + linkToItem;
          fetch(url)
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              resolve(res);
            });
        });
      };
    })();

    public Post = new (class {
      public FollowUnFollow = (followerDetails: Follower): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/followers", {
            method: "POST",
            body: JSON.stringify(followerDetails),
          })
            .then((response) => {
              return response;
            })
            .then((res) => {
              resolve(res.status == 200);
            });
        });
      };
      public SaveInterests = (itemsArr: any): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/saveInterests", {
            method: "POST",
            body: JSON.stringify({
              ConceptReduced: itemsArr,
            }),
          })
            .then((response) => {
              return response;
            })
            .then((res) => {
              resolve(res.status == 200);
            });
        });
      };
      public PinUnPin = (pinDetails: PersonalCollection): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/pin", {
            method: "POST",
            body: JSON.stringify(pinDetails),
          })
            .then((response) => {
              return response;
            })
            .then((res) => {
              resolve(res.status == 200);
            });
        });
      };

      public GetContexualSearchRefiners = (
        searchObject: IGeneralSearch
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          // let languages:any = LanguageList.filter((item:any)=>item.key.toString() === (SharedService.GetInstance().lang));
          // searchObject.language = `${languages[0].text}`;
          fetch(SharedService.webUrl + "api/contexualsearchrefiners", {
            method: "Post",
            body: JSON.stringify(searchObject),
          })
            .then((response) => {
              return response.ok ? response.json() : "";
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetContexualSearchResults = (
        searchObject: IGeneralSearch
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          fetch(SharedService.webUrl + "api/contexualSearchResults", {
            method: "Post",
            body: JSON.stringify(searchObject),
          })
            .then((response) => {
              return response.ok ? response.json() : "";
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public PostLastView = (lastViewItem: Follower): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/lastViewed", {
            method: "POST",
            body: JSON.stringify(lastViewItem),
          })
            .then((response) => {
              return response;
            })
            .then((res) => {
              resolve(res.status == 200);
            });
        });
      };

      public GetActivities = async (
        relatedUsers: IGraphPeopleResponse[],
        maxItemCount: number
      ): Promise<any> => {
        let _webAPIUrl = SharedService.webUrl;

        if (!isEmpty(relatedUsers)) {
          let result = await fetch(
            `${_webAPIUrl}api/activities?maxItemCount=${maxItemCount}`,
            {
              method: "POST",
              body: JSON.stringify(
                relatedUsers.map((u) => u.userPrincipalName)
              ),
            }
          );
          return result.json();
        } else {
          return [];
        }
      };
    })();
    public Delete = new (class {
      public DeleteAllArchived = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/deleteArchived")
            .then((response) => {
              return response;
            })
            .then((res) => {
              resolve(res);
            });
        });
      };

      public DeleteSPListItem = async (
        itemId: number,
        listTitle: string
      ): Promise<any> => {
        let _webAPIUrl = SharedService.webUrl;

        let result = await fetch(
          `${_webAPIUrl}api/itemdelete?itemId=${itemId}&listTitle=${listTitle}`
        );
        return result.status == 200 ? "success" : "fail";
      };
    })();
  })();

  public Notifications = new (class {
    public Get = new (class {
      public GetSystemAlert = async (): Promise<UserAlertItem> => {
        let _webAPIUrl = SharedService.webUrl;

        let alert: UserAlertItem = {} as UserAlertItem;

        let r = await fetch(`${_webAPIUrl}api/systemalerts`);
        let items: IUserAlertItem[] = await r.json();
        if (!isEmpty(items)) {
          alert.AlertId = items[0].ID;
          alert.Body = items[0].dkxAlertMessage;
        }
        return alert;
      };

      public ValidateSystemAlertForCurrentUser = async (
        alertId: number
      ): Promise<any> => {
        let _webAPIUrl = SharedService.webUrl;

        let r = await fetch(
          `${_webAPIUrl}api/validatealert?alertId=${alertId}`
        );
        return r.json();
      };

      public GetNotificationDpn = async (
        userEmail: string
      ): Promise<IDPNNotification> => {
        let res = await fetch(`${SharedService.dpnApiUrl}${userEmail}`, {
          headers: {
            "Ocp-Apim-Subscription-Key": SharedService.dpnImageKey,
            Authorization: "Bearer " + SharedService.DPNToken,
          },
        });
        return res.json();
      };

      public GetNotificationDash = async (): Promise<IDashNotification> => {
        let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
        let _webAPIUrl = `${SharedService.dashApiUrl}${emailId}`;

        var tokenRequest = SharedService.apiScopes.impersonation;
        tokenRequest.account = SharedService.GetInstance().account;
        var loginRes =
          await SharedService.GetInstance().msalClient.acquireTokenSilent(
            tokenRequest
          );
        let headers = {
          Authorization: "Bearer " + loginRes.accessToken,
        };

        try {
          let result = await fetch(_webAPIUrl, {
            headers,
          });
          if (result.ok) {
            return result.json();
          }
          return {} as IDashNotification;
        } catch (err) {
          return {} as IDashNotification;
        }
      };
    })();

    public Post = new (class {
      public PostUserAlertMapping = (
        alertMapping: UserAlertItem
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/systemalert", {
            method: "POST",
            body: JSON.stringify(alertMapping),
          })
            .then((response) => {
              return response;
            })
            .then((res) => {
              resolve(res.status == 200);
            });
        });
      };
    })();
  })();

  public PracticeProfile = new (class {
    public Post = new (class {
      public GetCarousels = (
        id: number,
        type: string,
        userPermission: string = "0"
      ): Promise<any[] | null> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/getcarousels", {
            method: "POST",
            body: JSON.stringify({
              Id: id,
              Type: type,
              SPAppToken: SharedService.instance.Data.SPAuth["SPAppToken"],
              UserPermissionFlag: userPermission,
            }),
          }).then((response) => {
            if (response.status == 204 || response.status == 400) {
              resolve(null);
            } else if (response.status == 200) {
              response.json().then((result: any) => {
                resolve(
                  result && result.hits && result.hits.hits
                    ? result.hits.hits.map((x: any) => x._source)
                    : null
                );
              });
            }
          });
        });
      };

      public GetRelatedContent = (
        id: number,
        userPermission: string = "0"
      ): Promise<any[] | null> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/getrelatedcontent", {
            method: "POST",
            body: JSON.stringify({
              Id: id,
              SPAppToken: SharedService.instance.Data.SPAuth["SPAppToken"],
              UserPermissionFlag: userPermission,
            }),
          }).then((response) => {
            if (response.status == 204 || response.status == 400) {
              resolve(null);
            } else if (response.status == 200) {
              response.json().then((result: any) => {
                let relatedContent: any[] = [];
                result.relatedContent
                  .sort((x: any) => x.order)
                  .forEach((element: any) => {
                    relatedContent.push({
                      key: "relatedKey" + element.type,
                      url: element.url,
                      icon: element.icon,
                      title: element.type,
                      seeAll: element.seeAll,
                    });
                  });
                resolve(relatedContent);
              });
            }
          });
        });
      };
      public GetRelatedContentUrl = (
        practiceData: {},
        relatedContentObj: {}
      ): Promise<any[] | null> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/getRelatedPracticeSearchUrl", {
            method: "POST",
            body: JSON.stringify({
              PracticeProfile: practiceData,
              RelatedContent: relatedContentObj,
            }),
          }).then((response) => {
            if (response.status == 204 || response.status == 400) {
              resolve(null);
            } else if (response.status == 200) {
              response.json().then((result: any) => {
                resolve(result);
              });
            }
          });
        });
      };

      public GetPracticeProfileData = (
        id: number,
        userPermission: string = "0"
      ): Promise<IPracticeProfileData | undefined> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/getpracticeprofile", {
            method: "POST",
            body: JSON.stringify({
              Id: id,
              SPAppToken: SharedService.instance.Data.SPAuth["SPAppToken"],
              UserPermissionFlag: userPermission,
            }),
          }).then((response) => {
            if (response.status == 204) {
              resolve(undefined);
            } else if (response.status == 401) {
              StorageHandler.Remove(
                Constants.SessionStorageItems.SPAuth,
                StorageType.Local
              );
              SharedService.instance.SiteSettings.Read.GetSPAuth();
            } else if (response.status == 200) {
              response.json().then((result: IPracticeProfileData) => {
                var practice: Practice = new Practice(result.Practice);

                var breadcrumbsElements: any = [
                  {
                    text: Constants.PageTitles.ExplorePracticeProfiles,
                    key: "PracticeProfileLobby",
                    href: Constants.Routing.PracticeProfiles,
                  },
                ];

                result.Breadrumbs.forEach((item) => {
                  breadcrumbsElements.push({
                    text: item.name,
                    key: item.name,
                    href: Constants.Routing.PracticeProfile + "?id=" + item.id,
                  });
                });

                var geographies: IGeography[] = result.Geographies;
                var geographiesElements: any[] = geographies;

                let childPracticesElements: any[] = [];
                let searchStrings: any[] = [];
                result.ChildPractices.forEach((item: any) => {
                  childPracticesElements.push({
                    text: item.Practice.dkxPracticeName,
                    href:
                      Constants.Routing.PracticeProfile +
                      "?id=" +
                      item.Practice.ID,
                  });

                  if (
                    item.ChildPractices == null ||
                    item.ChildPractices.length == 0
                  ) {
                    searchStrings.push({
                      key: item.Practice.ID + ":" + item.Practice.ID,
                      itemKey: "",
                      title: item.Practice.dkxPracticeName,
                      text: `${item.Practice.dkxPracticeName
                        } ${CommonFunctions.stripHtml(
                          item.Practice.dkxDescription
                        )}`,
                      isClickable: true,
                      href:
                        Constants.Routing.PracticeProfile +
                        "?id=" +
                        item.Practice.ID,
                    });
                  }

                  item.ChildPractices.forEach((child: any) => {
                    searchStrings.push({
                      key: item.Practice.ID + ":" + child.Practice.ID,
                      itemKey: "",
                      title:
                        item.Practice.dkxPracticeName +
                        ": " +
                        child.Practice.dkxPracticeName,
                      text: `${item.Practice.dkxPracticeName}: ${child.Practice.dkxPracticeName
                        } ${CommonFunctions.stripHtml(
                          item.Practice.dkxDescription
                        )} ${CommonFunctions.stripHtml(
                          child.Practice.dkxDescription
                        )}`,
                      isClickable: true,
                      href:
                        Constants.Routing.PracticeProfile +
                        "?id=" +
                        child.Practice.ID,
                    });
                  });
                });

                var label = "",
                  _labels = SharedService.GetInstance().Data.Labels;
                if (
                  practice.PracticeType ==
                  Constants.PracticeProfileLabels.PracticeTypeBusiness
                ) {
                  label =
                    result.Level == 1
                      ? _labels[
                      Constants.PracticeProfileLabels.OfferingPortfolioLabel
                      ]
                      : _labels[
                      Constants.PracticeProfileLabels
                        .OfferingPortfolioLabelL2
                      ];
                } else if (
                  practice.PracticeType ==
                  Constants.PracticeProfileLabels.PracticeTypeIdustry
                ) {
                  label =
                    result.Level == 1
                      ? _labels[Constants.PracticeProfileLabels.SectorsLabel]
                      : _labels[Constants.PracticeProfileLabels.SectorsLabelL2];
                }

                var data: IPracticeProfileData = {
                  Practice: practice,
                  Breadrumbs: breadcrumbsElements,
                  Geographies: geographiesElements,
                  ChildPractices: childPracticesElements,
                  ChildPracticesLabel: label,
                  Level: result.Level,
                  SearchStrings: searchStrings,
                  OfferingPortfolio: result.ChildPractices,
                  unalteredPractice: result.Practice,
                  OwerviewLabel:
                    _labels[
                    Constants.PracticeProfileLabels
                      .PracticeProfileOverviewLabel
                    ],
                  QuickFactsLabel:
                    _labels[Constants.PracticeProfileLabels.QuickFactsLabel],
                };

                resolve(data);
              });
            } else if (response.status == 400) {
              reject();
            }
          });
        });
      };
    })();
  })();

  public Drafts = new (class {
    public Get = new (class {
      public DoesListHasDraftItems = async (
        listTitle: string
      ): Promise<boolean> => {
        let _webAPIUrl = SharedService.webUrl;

        let result = await fetch(
          `${_webAPIUrl}api/checklistdrafts?listTitle=${listTitle}`
        );
        return result.json();
      };

      public GetDrafts = async (
        listTitle: string,
        pagingInfo?: string,
        sortField?: string,
        isAscending?: boolean
      ): Promise<DraftItems> => {
        let _webAPIUrl = SharedService.webUrl;

        let result = await fetch(
          `${_webAPIUrl}api/getdraftsdata?listTitle=${listTitle}&pagingInfo=${pagingInfo}&sortColumn=${sortField}&isAscending=${isAscending}`
        );
        let obj: any = await result.json();
        return new DraftItems(obj);
      };

      public GetDraftsCount = async (listTitle: string): Promise<number> => {
        let _webAPIUrl = SharedService.webUrl;

        let result = await fetch(
          `${_webAPIUrl}api/getdraftscount?listTitle=${listTitle}`
        );
        let output = await result.json();
        return output;
      };
    })();
  })();

  public NSTaxonomy = new (class {
    public Get = new (class {
      public GetMasterData = async (
        flag: string
      ): Promise<INSTaxonomyData[]> => {
        let _webAPIUrl = SharedService.webUrl;

        let result = await fetch(
          `${_webAPIUrl}api/ns/getmasterdata?masterFlag=${flag}`
        );
        let obj: any = result.json();
        return obj;
      };

      public RenameTerm = async (
        flag: string,
        oldName: string,
        updatedName: string
      ): Promise<string> => {
        let _webAPIUrl = SharedService.webUrl;

        let result = await fetch(
          `${_webAPIUrl}api/ns/renameterm?masterFlag=${encodeURIComponent(flag)}&oldName=${encodeURIComponent(oldName)}&newName=${encodeURIComponent(updatedName)}`
        );
        let obj = await result.json();
        return obj;
      };

      public AddTerm = async (
        flag: string,
        name: string
      ): Promise<INSTaxonomyData> => {
        let _webAPIUrl = SharedService.webUrl;
        let result = await fetch(
          `${_webAPIUrl}api/ns/addterm?masterFlag=${encodeURIComponent(flag)}&name=${encodeURIComponent(name)}`
        );

        let obj = await result.json();
        return obj;
      };

      public DeleteTerm = async (
        flag: string,
        delTermName: string,
        replacementTermName: string
      ): Promise<string> => {
        let _webAPIUrl = SharedService.webUrl;
        let result = await fetch(
          `${_webAPIUrl}api/ns/deleteterm?masterFlag=${encodeURIComponent(flag)}&deletedTermName=${encodeURIComponent(delTermName)}&replacementTermName=${encodeURIComponent(replacementTermName)}`
        );

        let obj = await result.json();
        return obj;
      };
    })();
    public Post = new (class { })();
  })();
  public Search = new (class {
    public Post = new (class {
      public async SetSearchHistory(item: ISearchHistory[]): Promise<any> {
        return await StorageHandler.Add(
          Constants.SessionStorageItems.SearchHistory,
          item,
          StorageType.Session
        );
      }

      public async GetSearchHistory(): Promise<any> {
        return await StorageHandler.Get(
          Constants.SessionStorageItems.SearchHistory,
          StorageType.Session
        );
      }

      public GetAutocompleteOptions = (
        searchData: ISearchData,
        labels: ILabel<string>
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          let options: ISuggestion[] = [];
          let languages: any = LanguageList.filter((item: any) => item.key.toString() === (SharedService.GetInstance().lang));
          searchData.language = `${languages[0].text}`;
          fetch(_webAPIUrl + "api/autocomplete", {
            method: "POST",
            body: JSON.stringify(searchData),
          }).then((response) => {
            if (response.status == 200) {
              response.json().then((items) => {
                if (items.length) {
                  JSON.parse(items).forEach((item: any) => {
                    var _suggestion = new Suggestion(
                      item,
                      labels[Constants.SearchLabels.SearchSearchFor],
                      labels[Constants.SearchLabels.SearchViewAll]
                    );
                    if (_suggestion.cardData[0]) {
                      if (_suggestion.Type == "DPN") {
                        _suggestion.footer[0] = "DPN";
                      } else {
                        let ctCorelationJson =
                          SharedService.GetInstance().GetConfigurationMapping(
                            _suggestion.cardData[0]
                          );
                        _suggestion.footer[0] = labels[ctCorelationJson.Label];
                      }
                    }
                    options.push(_suggestion);
                  });
                }
                resolve(options);
              });
            }
          });
        });
      };

      public GetSuggestions = (input: string): Promise<any> => {
        return new Promise(async (resolve, reject) => {
          let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
          const geography =
            SharedService.GetInstance().Data.CurrentUser.geographyFullPath.split(
              "->"
            );
          const userMemberFirm =
            geography && geography.length > 0 ? geography[0] : "";
          const userCountry =
            geography && geography.length > 0
              ? geography[geography.length - 1]
              : "";
          let _webAPIUrl =
            SharedService.suggestion +
            emailId +
            "&appid=DIGSUI&searchterm=" +
            input +
            "&memberfirm=" +
            userMemberFirm +
            "&country=" +
            userCountry;
          var tokenRequest = SharedService.apiScopes.suggestions;
          tokenRequest.account = SharedService.GetInstance().account;
          var loginRes =
            await SharedService.GetInstance().msalClient.acquireTokenSilent(
              tokenRequest
            );
          let headers = {
            Authorization: "Bearer " + loginRes.accessToken,
            "Ocp-Apim-Subscription-Key": SharedService.suggestionapim,
            "Access-Control-Allow-Origin": "*",
          };

          try {
            let result = await fetch(_webAPIUrl, {
              headers,
            });
            if (result.ok) {
              var newRes = await result.json();
              var suggestions = newRes.SuggestionItemCollection.map(
                (data: any) => {
                  return new Suggestion(
                    {
                      Text: data.item.suggestionText,
                      Phrase: CommonFunctions.stripHtml(
                        data.item.suggestionText
                      ),
                      InternalUrl:
                        Constants.Routing.Search +
                        "?q=" +
                        encodeURIComponent(
                          CommonFunctions.stripHtml(data.item.suggestionText)
                        ) +
                        "&mode=Suggestion",
                      SuggestionType: "Suggestions",
                    },
                    SharedService.GetInstance().Data.Labels[
                    Constants.SearchLabels.SearchSearchFor
                    ],
                    SharedService.GetInstance().Data.Labels[
                    Constants.SearchLabels.SearchViewAll
                    ]
                  );
                }
              );
              resolve(suggestions);
            } else {
              resolve([]);
            }
          } catch (err) {
            resolve([]);
          }
        });
      };

      public GetRecentSearches = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          let options: IRecentSearch[] = [];
          fetch(_webAPIUrl + "api/searchistory")
            .then((response) => response.json())
            .then((items) => {
              if (items) {
                items.forEach((item: any) => {
                  var _recentSearch = new RecentSearch(item);
                  options.push(_recentSearch);
                });
              }
              resolve(options);
            });
        });
      };

      public AddRecentSearches = (
        recentSearch: IRecentSearch
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          recentSearch.Phrase = decodeURIComponent(recentSearch.Phrase);

          fetch(_webAPIUrl + "api/searchistory", {
            method: "POST",
            body: JSON.stringify(recentSearch),
          })
            .then((response) => {
              return response;
            })
            .then((res) => {
              resolve(res.status == 200);
            });
        });
      };

      public GetItemsForCategory = (
        searchObject: IGeneralSearch
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          let language: any = LanguageList.filter((item: any) => item.key.toString() === (SharedService.GetInstance().lang));
          searchObject.language = `${language[0].text}`;
          let options: IRecentSearch[] = [];
          fetch(_webAPIUrl + "api/getItemsForCategory", {
            method: "POST",
            body: JSON.stringify(searchObject),
          })
            .then((response) => response.json())
            .then((items) => {
              resolve(items);
            });
        });
      };

      public GetExportedCategoryItems = (
        searchObject: IGeneralSearch
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/getExportedCategoryItems", {
            method: "POST",
            body: JSON.stringify(searchObject),
          }).then((response) => {
            if (response.status == 200) {
              response.json().then((items) => {
                resolve({ items: items, error: null });
              });
            } else {
              resolve({
                items: null,
                error: `${response.status}: ${response.statusText}`,
              });
            }
          });
        });
      };

      public SearchGetResults = (
        searchObject: IGeneralSearch
      ): PromiseWithCancel<ISearchResults> => {
        const controller = new AbortController();
        const signal = controller.signal;
        let languages: any = LanguageList.filter((item: any) => item.key.toString() === (SharedService.GetInstance().lang));
        searchObject.language = `${languages[0].text}`;
        const promise = new Promise((resolve, reject) => {
          fetch(SharedService.webUrl + "api/searchGetResults", {
            method: "POST",
            signal,
            body: JSON.stringify(searchObject),
          }).then((response) => {
            if (response.status == 204) {
              let searchResult: ISearchResults = {
                searchResults: [],
                explainData: [],
                totalCount: 0,
                allStopWords: false,
              };
              resolve(searchResult);
            } else if (response.status == 200) {
              response.json().then((result) => {
                let searchResult: ISearchResults = {
                  searchResults: [],
                  explainData: [],
                  totalCount: 0,
                  allStopWords: false,
                };

                if (
                  (result &&
                    result.Results &&
                    result.Results.length &&
                    result.Results[0].inner_hits != null) ||
                  CommonFunctions.URLSearch().get(
                    Constants.QueryStringParameters.imageId
                  ) ||
                  ""
                ) {
                  //images
                  let asyncWrapper = async () => {
                    //await CommonFunctions.SetImages(result, searchResult);
                    let start = 0,
                      loop = 1;
                    let imageBatchSize = 16;
                    while (start < result.Results.length) {
                      let imageURLs: string[] = [];
                      let end =
                        start + imageBatchSize > result.Results.length
                          ? result.Results.length
                          : start + imageBatchSize;
                      result.Results.slice(start, end).map((x: any) => {
                        if (x._source.img_thumbnailurl)
                          imageURLs.push(x._source.img_thumbnailurl)
                      });
                      let imageBatch =
                        await SharedService.GetInstance().Search.Get.GetImageSet(
                          imageURLs
                        );
                      imageBatch = JSON.parse(imageBatch);
                      for (
                        let i = start, j = 0;
                        j < imageBatch.length;
                        i++, j++
                      ) {
                        result.Results[i]._source.image = imageBatch[j];
                        searchResult.searchResults.push(
                          new CardSchema(
                            result.Results[i].inner_hits &&
                              result.Results[i].inner_hits.parent &&
                              result.Results[i].inner_hits.parent.hits.hits[0]
                              ? result.Results[i].inner_hits.parent.hits.hits[0]
                                ._source
                              : {},
                            result.Results[i]._source
                          )
                        );
                        searchResult.explainData.push({
                          docId:
                            result.Results[i].inner_hits &&
                              result.Results[i].inner_hits.parent &&
                              result.Results[i].inner_hits.parent.hits.hits[0]
                              ? result.Results[i].inner_hits.parent.hits.hits[0]
                                ._source.appid
                              : "",
                          data: result.Results[i]._explanation,
                        });
                      }
                      start = imageBatchSize * loop;
                      loop++;
                    }
                    searchResult.totalCount = result.Count;
                    searchResult.allStopWords = result.allStopWords;
                    //searchResult.highlight = result.Results.map((items:any)=>items.highlight);
                    resolve(searchResult);
                  };
                  asyncWrapper();
                } else {
                  //search results
                  result.Results.forEach((item: any) => {
                    if (item.inner_hits == null) {
                      searchResult.searchResults.push(
                        new CardSchema(item._source, [], item.highlight, searchObject.text)
                      );
                      searchResult.explainData.push({
                        docId: item._source.appid,
                        data: item._explanation,
                      });
                    }
                  });

                  searchResult.totalCount = result.Count;
                  searchResult.allStopWords = result.allStopWords;
                  // searchResult.highlight = result.Results.map((item:any)=>item.highlight);
                  resolve(searchResult);
                }

                // result.Results.forEach((item: any) => {
                //   if(item.inner_hits == null) {
                //     searchResult.searchResults.push(new CardSchema(item._source));
                //     searchResult.explainData.push({docId:item._source.appid, data: item._explanation });
                //   }
                //   else {
                //     let asyncWrapper = async () => {
                //       let image = await SharedService.GetInstance().Search.Get.GetImage(item._source.img_attachmentfilename);
                //       item._source.image = image;
                //       searchResult.searchResults.push(new CardSchema(item.inner_hits.parent.hits.hits[0]._source, item._source));
                //       searchResult.explainData.push({ docId: item.inner_hits.parent.hits.hits[0]._source.appid, data: item._explanation });
                //     };
                //     asyncWrapper();
                //   }
                // });
              });
            }
          });
        });
        (promise as PromiseWithCancel<ISearchResults>).cancel = () =>
          controller.abort();
        return promise as PromiseWithCancel<ISearchResults>;
      };
      // public SearchGetResults = (
      //   searchObject: IGeneralSearch
      // ): PromiseWithCancel<ISearchResults> => {
      //   const controller = new AbortController();
      //   const signal = controller.signal;
      //   const promise = new Promise((resolve, reject) => {
      //     fetch(SharedService.webUrl + "api/searchGetResults", {
      //       method: "POST",
      //       signal,
      //       body: JSON.stringify(searchObject),
      //     }).then((blob:any) => {
      //       return blob;
      //     //   const url = window.URL.createObjectURL(
      //     //     new Blob([blob])
      //     // );
      //     // const link = document.createElement('a');
      //     // link.href = url;
      //     // link.setAttribute(
      //     //     'download',
      //     //     'searchExport.csv'
      //     // );
      //     // document.body.appendChild(link);
      //     // link.click();
      //     // if (link.parentNode)
      //     //     link.parentNode.removeChild(link);
      //       // if (response.status == 204) {
      //       //   let searchResult: ISearchResults = {
      //       //     searchResults: [],
      //       //     explainData: [],
      //       //     totalCount: 0,
      //       //   };
      //       //   resolve(searchResult);
      //       // } else if (response.status == 200) {
      //       //   response.json().then((result) => {
      //       //     let searchResult: ISearchResults = {
      //       //       searchResults: [],
      //       //       explainData: [],
      //       //       totalCount: 0,
      //       //     };

      //       //     if (
      //       //       (result &&
      //       //         result.Results &&
      //       //         result.Results.length &&
      //       //         result.Results[0].inner_hits != null) ||
      //       //       CommonFunctions.URLSearch().get(
      //       //         Constants.QueryStringParameters.imageId
      //       //       ) ||
      //       //       ""
      //       //     ) {
      //       //       //images
      //       //       let asyncWrapper = async () => {
      //       //         //await CommonFunctions.SetImages(result, searchResult);
      //       //         let start = 0,
      //       //           loop = 1;
      //       //         let imageBatchSize = 16;
      //       //         while (start < result.Results.length) {
      //       //           let imageURLs: string[] = [];
      //       //           let end =
      //       //             start + imageBatchSize > result.Results.length
      //       //               ? result.Results.length
      //       //               : start + imageBatchSize;
      //       //           result.Results.slice(start, end).map((x: any) =>
      //       //             imageURLs.push(x._source.img_thumbnailurl)
      //       //           );
      //       //           let imageBatch =
      //       //             await SharedService.GetInstance().Search.Get.GetImageSet(
      //       //               imageURLs
      //       //             );
      //       //           imageBatch = JSON.parse(imageBatch);
      //       //           for (
      //       //             let i = start, j = 0;
      //       //             j < imageBatch.length;
      //       //             i++, j++
      //       //           ) {
      //       //             result.Results[i]._source.image = imageBatch[j];
      //       //             searchResult.searchResults.push(
      //       //               new CardSchema(
      //       //                 result.Results[i].inner_hits &&
      //       //                   result.Results[i].inner_hits.parent &&
      //       //                   result.Results[i].inner_hits.parent.hits.hits[0]
      //       //                   ? result.Results[i].inner_hits.parent.hits.hits[0]
      //       //                     ._source
      //       //                   : {},
      //       //                 result.Results[i]._source
      //       //               )
      //       //             );
      //       //             searchResult.explainData.push({
      //       //               docId:
      //       //                 result.Results[i].inner_hits &&
      //       //                   result.Results[i].inner_hits.parent &&
      //       //                   result.Results[i].inner_hits.parent.hits.hits[0]
      //       //                   ? result.Results[i].inner_hits.parent.hits.hits[0]
      //       //                     ._source.appid
      //       //                   : "",
      //       //               data: result.Results[i]._explanation,
      //       //             });
      //       //           }
      //       //           start = imageBatchSize * loop;
      //       //           loop++;
      //       //         }
      //       //         searchResult.totalCount = result.Count;
      //       //         resolve(searchResult);
      //       //       };
      //       //       asyncWrapper();
      //       //     } else {
      //       //       //search results
      //       //       result.Results.forEach((item: any) => {
      //       //         if (item.inner_hits == null) {
      //       //           searchResult.searchResults.push(
      //       //             new CardSchema(item._source)
      //       //           );
      //       //           searchResult.explainData.push({
      //       //             docId: item._source.appid,
      //       //             data: item._explanation,
      //       //           });
      //       //         }
      //       //       });

      //       //       searchResult.totalCount = result.Count;
      //       //       resolve(searchResult);
      //       //     }

      //       //     // result.Results.forEach((item: any) => {
      //       //     //   if(item.inner_hits == null) {
      //       //     //     searchResult.searchResults.push(new CardSchema(item._source));
      //       //     //     searchResult.explainData.push({docId:item._source.appid, data: item._explanation });
      //       //     //   }
      //       //     //   else {
      //       //     //     let asyncWrapper = async () => {
      //       //     //       let image = await SharedService.GetInstance().Search.Get.GetImage(item._source.img_attachmentfilename);
      //       //     //       item._source.image = image;
      //       //     //       searchResult.searchResults.push(new CardSchema(item.inner_hits.parent.hits.hits[0]._source, item._source));
      //       //     //       searchResult.explainData.push({ docId: item.inner_hits.parent.hits.hits[0]._source.appid, data: item._explanation });
      //       //     //     };
      //       //     //     asyncWrapper();
      //       //     //   }
      //       //     // });
      //       //   });
      //      // }
      //     });
      //   });
      //   (promise as PromiseWithCancel<ISearchResults>).cancel = () =>
      //     controller.abort();
      //   return promise as PromiseWithCancel<ISearchResults>;
      // };
      public SearchGetPromotedResults = (
        searchObject: IGeneralSearch,
        isPromotedResult: boolean
      ): Promise<any> => {
        let language: any = LanguageList.filter((item: any) => item.key.toString() === (SharedService.GetInstance().lang));
        searchObject.language = `${language[0].text}`;
        let searchResult: ISearchResults = {
          searchResults: [],
          explainData: [],
          totalCount: 0,
        };
        return isPromotedResult
          ? new Promise((resolve, reject) => {
            fetch(
              SharedService.webUrl +
              "api/searchGetPromotedResults?maxItemCount=4",
              {
                method: "POST",
                body: JSON.stringify(searchObject),
              }
            ).then((response) => {
              if (response.status == 204) {
                let searchResult: ISearchResults = {
                  searchResults: [],
                  explainData: [],
                  totalCount: 0,
                };
                resolve(searchResult);
              } else if (response.status == 200) {
                response.json().then((result) => {
                  result.Results.forEach((item: any) => {
                    item._source.isPromotedResult = true;
                    if (item.inner_hits?.parent == null) {
                      searchResult.searchResults.push(
                        new CardSchema(item._source)
                      );
                      searchResult.explainData.push({
                        docId: item._source.appid,
                        data: item._explanation,
                      });
                    } else {
                      console.log(item);

                      searchResult.searchResults.push(
                        new CardSchema(
                          item.inner_hits.parent.hits.hits[0]._source,
                          item._source
                        )
                      );
                      searchResult.explainData.push({
                        docId:
                          item.inner_hits.parent.hits.hits[0]._source.appid,
                        data: item._explanation,
                      });
                    }
                  });
                  searchResult.totalCount = result.Count;

                  resolve(searchResult);
                });
              }
            });
          })
          : new Promise((resolve, reject) => {
            resolve(searchResult);
          });
      };

      public SearchGetRefiners = (
        searchObject: IGeneralSearch
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          fetch(SharedService.webUrl + "api/searchGetRefiners", {
            method: "POST",
            body: JSON.stringify(searchObject),
          }).then((response) => {
            if (response.status == 204) {
              resolve([]);
            } else if (response.status == 200) {
              let refiners: IRefiner[] = [];
              response.json().then((result) => {
                resolve(result);
              });
            }
          });
        });
      };

      public SearchGetSuggestion = (text: string): Promise<any> => {
        return new Promise((resolve, reject) => {
          fetch(
            SharedService.webUrl + "api/searchGetSuggestion?text=" + text
          ).then((response) => {
            if (response.status == 204) {
              resolve("");
            } else if (response.status == 200) {
              response.text().then((result) => {
                resolve(result);
              });
            }
          });
        });
      };

      // public GeneralSearch = (searchObject: IGeneralSearch): Promise<any> => {
      //   return new Promise((resolve, reject) => {
      //     let _webAPIUrl = SharedService.webUrl;

      //     fetch(_webAPIUrl + "api/search", {
      //       method: "POST",
      //       body: JSON.stringify(searchObject),
      //     }).then((response) => {
      //       if (response.status == 204) {
      //         let searchResult: ISearchState = {
      //           renderRefiners: true,
      //           renderSearch: true,
      //           searchText: "",
      //           searchResults: [],
      //           refiners: [],
      //           suggestion: "",
      //           totalCount: 0,
      //           categories: [],
      //         };
      //         resolve(searchResult);
      //       } else if (response.status == 200) {
      //         let searchResult: ISearchState = {
      //           renderRefiners: true,
      //           renderSearch: true,
      //           searchText: "",
      //           searchResults: [],
      //           refiners: [],
      //           suggestion: "",
      //           totalCount: 0,
      //           categories: [],
      //         };

      //         response.json().then((result) => {
      //           result.SearchResults.forEach((item: any) => {
      //             searchResult.searchResults?.push(new CardSchema(item));
      //           });
      //           searchResult.totalCount = result.Count;
      //           searchResult.suggestion =
      //             result.Suggestion.length > 0
      //               ? result.Suggestion.charAt(0).toUpperCase() +
      //                 result.Suggestion.slice(1)
      //               : "";
      //           searchResult.refiners = result.Refiners;
      //           resolve(searchResult);
      //         });
      //       }
      //     });
      //   });
      // };
    })();

    public Get = new (class {
      public GetUserLocationId = (location: string): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/searchGetUserLocationId?location=" + location)
            .then((response) => response.json())
            .then((id) => {
              resolve(id);
            });
        });
      };

      public GetAllCategories = (
        userNeedId: number,
        isSearchCategory: boolean
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          let options: IRecentSearch[] = [];
          fetch(
            _webAPIUrl +
            "api/getAllCategories?userNeedId=" +
            userNeedId +
            "&isSearchCategory=" +
            isSearchCategory
          )
            .then((response) => response.json())
            .then((items) => {
              resolve(items);
            });
        });
      };

      public GetSuggestions = (): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          fetch(_webAPIUrl + "api/suggestions")
            .then((response) => response.json())
            .then((res) => {
              resolve(res);
            });
        });
      };

      public GetHelpText = (title: string): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;
          fetch(_webAPIUrl + "api/tips")
            .then((response) => response.json())
            .then((res) => {
              var helpText = "";
              if (res != undefined && res.length > 0) {
                helpText = res.filter((helpText: any) => {
                  return helpText.Title == title;
                });
              }
              resolve(helpText.length > 0 ? helpText : null);
            });
        });
      };

      public GetImage = async (imgURL: string): Promise<string> => {
        return new Promise((resolve, reject) => {
          fetch(`${SharedService.GetImageURL}${encodeURIComponent(imgURL)}`, {})
            .then((response) => response.text())
            .then((result) => {
              if (result) {
                resolve(result);
              } else {
                resolve("");
              }
            })
            .catch((data) => {
              resolve("");
            });
        });
      };

      public GetImageSet = async (imgURLs: string[]): Promise<string> => {
        return new Promise((resolve, reject) => {
          fetch(SharedService.GetImageURL, {
            method: "POST",
            body: JSON.stringify(imgURLs),
          })
            .then((response) => response.text())
            .then((result) => {
              if (result) {
                resolve(result);
              } else {
                resolve("");
              }
            })
            .catch((data) => {
              resolve("");
            });
        });
      };
    })();
  })();

  public SpLists = new (class {
    public Get = new (class {
      public GetFeaturedListItems = (
        listName: string,
        id: number | undefined
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          let featuredItems: IFeaturedItem[] = [];

          fetch(
            `${_webAPIUrl}api/featured?listName=${listName}${id ? `&id=${id}` : ""
            }`
          )
            .then((response) => response.json())
            .then((items) => {
              if (items && items.length > 0) {
                items.forEach(async (item: FeaturedItem) => {
                  var isExternal = false;
                  var sourceId: string;
                  var ctName;
                  var app =
                    SharedService.GetInstance().Data.Applications.filter(
                      (app: any) => {
                        return item.dkxVItemLink.Url.includes(app.Domain);
                      }
                    );
                  if (app.length > 0) {
                    //take from url the ct and source id
                    if (item.dkxVItemLink.Url.includes(SharedService.uiUrl)) {
                      var x = item.dkxVItemLink.Url;
                      var y = x.substring(x.lastIndexOf("/") + 1, x.length);
                      var par = y.split("?");
                      var id = par[1].substring(par[1].indexOf("=") + 1);
                      ctName =
                        par[0] == "curatedcollection"
                          ? "Collections"
                          : item.dkxContentType;
                      sourceId = id;
                    } else {
                      //query to elastic with the linktoitem
                      var query = {
                        size: 100,
                        sort: [],
                        query: {
                          bool: {
                            must: [
                              {
                                match: {
                                  appurl: item.dkxVItemLink.Url,
                                },
                              },
                            ],
                          },
                        },
                      };

                      var respone =
                        await SharedService.GetInstance().CategoryView.Get.GetItemsPerCategory(
                          query
                        );
                      ctName =
                        respone.length > 0
                          ? respone[0].internalcontenttype
                          : item.dkxContentType;
                      sourceId = respone.length > 0 ? respone[0].appid : "0";
                    }
                  } else {
                    //there is no pin
                    isExternal = true;
                    ctName = item.dkxContentType;
                    sourceId = item.ID;
                  }

                  var newItem = new IFeaturedItem(
                    item.Title,
                    item.dkxImage ? item.dkxImage.Url : "",
                    item.dkxAbstract,
                    sourceId,
                    item.dkxVItemLink ? item.dkxVItemLink.Url : "",
                    ctName,
                    CommonFunctions.DateFormatter(new Date()),
                    CommonFunctions.DateFormatter(new Date()),
                    isExternal,
                    ctName,
                    item.dkxShowBadge
                  );
                  featuredItems.push(newItem);
                  if (featuredItems.length == items.length)
                    resolve(featuredItems);
                });
              } else {
                resolve([]);
              }
            });
        });
      };
    })();
  })();
  public CategoryView = new (class {
    public Get = new (class {
      public GetObjPerCategory = (
        typeCategory: string,
        mode: number,
        queryString: any = {
          query_string: {
            query: "**",
          },
        }
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          let categories: any = {};
          SharedService.GetInstance().Data.CTPMapping?.map((ct, i) => {
            let category: ICategory | null = null;
            let request: any = {
              size: 100,
              query: {
                bool: {
                  should: [],
                },
              },
            };
            var query: any;
            if (ct.Application && ct.ContentTypeL1 && ct.ContentTypeL2) {
              query = {
                bool: {
                  must: [
                    queryString,
                    { match: { Application: ct.Application.Title } },
                    {
                      match: {
                        ContentTypeL1: ct.ContentTypeL1.ContentTypeName,
                      },
                    },
                    {
                      match: {
                        ContentTypeL2: ct.ContentTypeL2.ContentTypeName,
                      },
                    },
                  ],
                },
              };
            } else if (ct.Application && ct.ContentTypeL1) {
              query = {
                bool: {
                  must: [
                    queryString,
                    { match: { Application: ct.Application.Title } },
                    {
                      match: {
                        ContentTypeL1: ct.ContentTypeL1.ContentTypeName,
                      },
                    },
                  ],
                },
              };
            } else {
              query = {
                bool: {
                  must: [
                    queryString,
                    { match: { Application: ct.Application.Title } },
                  ],
                },
              };
            }
            if (categories[ct[typeCategory].Label]) {
              categories[ct[typeCategory].Label].Query.query.bool.should.push(
                query
              );
            } else {
              var orderObj =
                SharedService.GetInstance().Data.CategoryOrder.filter(
                  (category: any) => {
                    return (
                      category.UserNeeds.Id == mode &&
                      category.Category.Label == ct[typeCategory].Label
                    );
                  }
                );
              if (orderObj.length == 0) {
                category = null;
              } else {
                category = {
                  Query: request,
                  CategoryName: ct[typeCategory].Label,
                  Id: ct[typeCategory].Id,
                  Label: ct[typeCategory].Label,
                  Order: orderObj.length > 0 ? orderObj[0].Order : 0,
                  Open:
                    orderObj.length > 0 && orderObj[0].Order <= 3
                      ? true
                      : false,
                  Count: 0,
                  Items: [],
                  SeeAllLink: "",
                };
                categories[ct[typeCategory].Label] = category;
              }
            }

            if (SharedService.GetInstance().Data.CTPMapping?.length === i + 1) {
              var categoryOrder: any[] = [];
              Object.keys(categories).forEach((category) => {
                categoryOrder[categories[category].order - 1] =
                  categories[category];
              });
              var test = [
                //need to remove this test after all the categories info is in the db
                {
                  Query: {
                    size: 100,
                    query: {
                      bool: {
                        should: [
                          {
                            bool: {
                              must: [
                                {
                                  query_string: {
                                    query: "**",
                                  },
                                },
                                {
                                  match: {
                                    type: "solution",
                                  },
                                },
                                {
                                  match: {
                                    id: 10,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            bool: {
                              must: [
                                {
                                  query_string: {
                                    query: "**",
                                  },
                                },
                                {
                                  match: {
                                    type: "solution",
                                  },
                                },
                                {
                                  match: {
                                    id: 11,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  },
                  CategoryName: "category6",
                  Open: true,
                  SeeAllLink: "https://www.google.com/",
                },
              ];
              //resolve(test);
            }
          });

          resolve(categories);
        });
      };

      public async GetItemsPerCategory(query: any): Promise<any[]> {
        return new Promise((resolve, reject) => {
          let _webAPIUrl = SharedService.webUrl;

          let queryConfiguration = {
            HttpMethod: "POST",
            Path: Constants.General.ElasticUrl,
            Body: JSON.stringify(query),
          };
          fetch(`${_webAPIUrl}api/searchbyquery`, {
            method: "POST",
            body: JSON.stringify(queryConfiguration),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              let sourceArray: any[] = res.map((h: any) => h._source);
              resolve(sourceArray);
            });
        });
      }
    })();
  })();

  public WhatsNew = new (class {
    public Get = new (class {
      public FetchPractiseAligments = async (needId: number): Promise<any> => {
        let _webAPIUrl = SharedService.webUrl;

        // let taxBuilder = new KXTaxonomyTreeBuilder();
        // await taxBuilder.init(TableId.CLIENT_SERVICE, 1);

        // let clientServiceIds = await taxBuilder.getChildNodeExternalIds(
        //   SharedService.GetInstance().Data.CurrentUser.clientserviceId
        // );

        // let clientServiceIdsStr = !isEmpty(clientServiceIds)
        //   ? clientServiceIds.join(",")
        //   : "";

        let self = SharedService.GetInstance();
        let clientServiceFullPath = self.Data.CurrentUser.clientServiceFullPath;
        let geoFullPath = self.Data.CurrentUser.allDataGeography;
        let userIndustryFullPath = self.Data.CurrentUser.allDataIndustry;
        let miniCardSearchMetaData =
          CommonFunctions.getMinicardsSearchMetaConfig();
        let needs = (
          await SharedService.GetInstance().Actions.Get.GetUserNeeds()
        )?.filter((n) => n.Id == needId);
        let needTitle = !isEmpty(needs) ? needs[0].Title : "";
        let language: any = LanguageList.filter((item: any) => item.key.toString() === (SharedService.GetInstance().lang));
        let r = await fetch(
          `${_webAPIUrl}api/practisealignments?geographyFullPath=${geoFullPath}&userClientServiceFullPath=${clientServiceFullPath}&userIndustryFullPath=${userIndustryFullPath}&needId=${needId}&needTitle=${needTitle}&getExplainData=${miniCardSearchMetaData?.boostingExplained.enabled}&language=${language[0].text}`
        );
        return r.json();
      };

      public FetchInterests = async (needId: number): Promise<any> => {
        let _webAPIUrl = SharedService.webUrl;
        let self = SharedService.GetInstance();
        let clientServiceFullPath = self.Data.CurrentUser.clientServiceFullPath;
        let geoFullPath = self.Data.CurrentUser.allDataGeography;
        let userIndustryFullPath = self.Data.CurrentUser.allDataIndustry;
        let miniCardSearchMetaData =
          CommonFunctions.getMinicardsSearchMetaConfig();
        let language: any = LanguageList.filter((item: any) => item.key.toString() === (SharedService.GetInstance().lang));
        let r = await fetch(
          `${_webAPIUrl}api/interests?geographyFullPath=${geoFullPath}&userClientServiceFullPath=${clientServiceFullPath}&userIndustryFullPath=${userIndustryFullPath}&needId=${needId}&getExplainData=${miniCardSearchMetaData?.boostingExplained.enabled}&language=${language[0].text}}`
        );
        return r.json();
      };

      public FetchFollowedCollections = async (
        needId: number
      ): Promise<any> => {
        let _webAPIUrl = SharedService.webUrl;
        let self = SharedService.GetInstance();
        let clientServiceFullPath = self.Data.CurrentUser.clientServiceFullPath;
        let geoFullPath = self.Data.CurrentUser.allDataGeography;
        let userIndustryFullPath = self.Data.CurrentUser.allDataIndustry;
        let miniCardSearchMetaData =
          CommonFunctions.getMinicardsSearchMetaConfig();
        let language: any = LanguageList.filter((item: any) => item.key.toString() === (SharedService.GetInstance().lang));

        let r = await fetch(
          `${_webAPIUrl}api/followeddata?geographyFullPath=${geoFullPath}&userClientServiceFullPath=${clientServiceFullPath}&userIndustryFullPath=${userIndustryFullPath}&needId=${needId}&getExplainData=${miniCardSearchMetaData?.boostingExplained.enabled}&language=${language[0].text}}`
        );
        return r.json();
      };
    })();
  })();

  //get the json mapping per card
  public GetConfigurationMapping = (card: any, isFeatured?: boolean) => {
    var internalCT = CommonFunctions.getLabelJsomMapping(card);
    return internalCT;
  };

  //add compare content items to local storage
  public AddCompareContentToLocalStorage = (comapreItems: any) => {
    StorageHandler.Add(
      Constants.SessionStorageItems.CompareContent,
      comapreItems,
      StorageType.Local
    );
  };

  public GetCompareContentToLocalStorage() {
    return StorageHandler.Get(
      Constants.SessionStorageItems.CompareContent,
      StorageType.Local,
      undefined,
      false
    );
  }

  // KX Assets
  public CreateDocument = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      // let _webAPIUrl = "https://localhost:44346";
      var body = data;
      fetch(_webAPIUrl + "/Contribution/createDocument", {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        });
    });
  };

  public GetTaxonomyTreeValues = (
    tableId: number,
    firmId: number
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.webUrl;
      fetch(
        `${_webAPIUrl}api/taxonomy/GetAll?TableId=${tableId}&MemberFirmId=${firmId}`
      ).then((res: any) => {
        res.json().then((t: any) => {
          if (t) {
            const apiJson = JSON.parse(t);
            resolve(apiJson);
          }
        });
      });
    });
  };

  public GetNonSynapticaTaxonomyTreeValues = (nsType: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      fetch(_webAPIUrl + "/api/getNonSynapticaTagsData?nsType=" + nsType).then(
        (res: any) => {
          res.json().then((t: any) => {
            if (t) {
              // const apiJson = JSON.parse(t);
              resolve(t);
            }
          });
        }
      );
    });
  };

  public GetPublisherPeopleData = async (inputText: string): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/User/SearchPublisherGroup/" + inputText
      );
      if (result.ok) {
        return result.json();
      }
      return result;
    } catch (err) {
      return err;
    }
  };

  public GetQAPeopleData = async (inputText: string): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(_webAPIUrl + "/User/SearchQAGroup/" + inputText);
      if (result.ok) {
        return result.json();
      }
      return result;
    } catch (err) {
      return err;
    }
  };

  public savePublisherandQA = async (data: any): Promise<any> => {
    let title = SharedService.GetInstance().Data.CurrentUser.title;
    data.User = title;
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      var body = data;
      fetch(
        _webAPIUrl + "/api/PublishingDashboard/AssignDocumentPublisherandQA/",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        });
    });
  };

  public saveBulkPublisherandQA = async (data: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    var body = data;
    await fetch(
      _webAPIUrl + "/api/PublishingDashboard/BulkAssignDocumentPublisherandQA/",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    ).then((response) => response.json());
  };

  public GetPeopleData = async (key: string): Promise<any> => {
    // let key= 'poonam'
    let _webAPIUrl = `${SharedService.peopleApiUrl}${key}`;

    var tokenRequest = SharedService.apiScopes.kxpeople;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    // console.log("loginRes", loginRes.accessToken);
    let headers = {
      Authorization: "Bearer " + loginRes.accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        headers,
      });
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  // public GetCurrentUserData = async (): Promise<any> => {
  //   let _webAPIUrl = SharedService.webUrl;
  //   try {
  //     let result = await fetch(_webAPIUrl + "api/currentUser");
  //     if (result.ok) {
  //       return result.json();
  //     }
  //     return {};
  //   } catch (err) {
  //     return {};
  //   }
  // };

  public GetCurrentUserData = async (): Promise<any> => {
    let _webAPIUrl = SharedService.webUrl;
    try {
      let result = await fetch(_webAPIUrl + "api/currentUser");
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetClientData = async (key: string): Promise<any> => {
    let _webAPIUrl = `${SharedService.clientApiUrl}${key}`;

    var tokenRequest = SharedService.apiScopes.kxclientqual;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    let headers = {
      Authorization: "Bearer " + loginRes.accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        headers,
      });
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public AddNewClient = async (
    clientName: string,
    userData: {
      loggedUser: string;
      firstName: string;
      lastName: string;
      email: string;
    }
  ): Promise<any> => {
    let _webAPIUrl = SharedService.addNewClientApiUrl;
    let params = new URLSearchParams(_webAPIUrl);
    params.append("clientName", clientName);
    params.append("loggedUser", userData.loggedUser);
    params.append("firstName", userData.firstName);
    params.append("lastName", userData.lastName);
    params.append("email", userData.email);
    _webAPIUrl = _webAPIUrl + params;
    var tokenRequest = SharedService.apiScopes.kxclientqual;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    let headers = {
      Authorization: "Bearer " + loginRes.accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        headers,
      });
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetRelatedQualData = async (
    clientId: number | string
  ): Promise<any> => {
    let _webAPIUrl = `${SharedService.getRelatedQualApiUrl}${clientId}`;

    var tokenRequest = SharedService.apiScopes.kxclientqual;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    let headers = {
      Authorization: "Bearer " + loginRes.accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        headers,
      });
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return null;
    }
  };

  public GetAttachmentNotifications = async (): Promise<
    IAttachmentNotication[]
  > => {
    let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      // let _webAPIUrl = "https://localhost:44346";

      fetch(
        `${_webAPIUrl}/Contribution/GetUserAttachmentNotifications?emailid=${emailId}`
      )
        .then((response) => response.status == 200 && response.json())
        .then((res) => {
          resolve(res);
        });
    });
  };

  public RemoveAttachmentNotification = async (documentId: number) => {
    let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      // let _webAPIUrl = "https://localhost:44346";

      fetch(
        `${_webAPIUrl}/Contribution/RemoveNotification?emailId=${emailId}&documentId=${documentId}`,
        {
          method: "DELETE",
        }
      )
        .then((response) => response.json())
        .then((res) => {
          // console.log(
          //   "From Shared Service RemoveAttachmentNotification : Line 3696",
          //   res
          // );
          resolve(res);
        });
    });
  };

  public GetClientDataById = async (id: number | string): Promise<any> => {
    let _webAPIUrl = SharedService.webUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "api/getClientsData?clientId=" + id
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetQualsDataById = async (id: number): Promise<any> => {
    let _webAPIUrl = SharedService.webUrl;
    try {
      let result = await fetch(_webAPIUrl + "api/getQualsData?qualId=" + id);
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetDashActivities = async (
    permission: boolean,
    searchTerm: number
  ): Promise<Array<IDashActivity>> => {
    let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    let _webAPIUrl = `${SharedService.getDashActivityApiUrl}${emailId}&permission=${permission}&searchId=${searchTerm}`;

    var tokenRequest = SharedService.apiScopes.impersonation;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    let headers = {
      Authorization: "Bearer " + loginRes.accessToken,
    };
    try {
      let result = await fetch(_webAPIUrl, {
        headers,
      });
      if (result.ok) {
        return result.json();
      }
      return [] as IDashActivity[];
    } catch (err) {
      return [] as IDashActivity[];
    }
  };

  public GetDashActivityById = async (id: number): Promise<any> => {
    let _webAPIUrl = `${SharedService.dashActivityByIdApiUrl}${id}`;

    var tokenRequest = SharedService.apiScopes.impersonation;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    let headers = {
      Authorization: "Bearer " + loginRes.accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        headers,
      });
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public createActivityIntegratedDocument = async (data: any): Promise<any> => {
    let _webAPIUrl = `${SharedService.AddDashActivityMapping}`;

    var tokenRequest = SharedService.apiScopes.impersonation;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    try {
      let result = await fetch(_webAPIUrl, {
        headers: new Headers({
          Authorization: "Bearer " + loginRes.accessToken,
          "Content-Type": "application/json",
        }),
        method: "POST",
        body: JSON.stringify(data),
      });
      if (result.ok) {
        return result;
      }
      return {};
    } catch (err) {
      return err;
    }
  };

  public removeActivityIntegratedDocument = async (data: any): Promise<any> => {
    let _webAPIUrl = `${SharedService.RemoveDashActivityMapping}`;

    var tokenRequest = SharedService.apiScopes.impersonation;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    try {
      let result = await fetch(_webAPIUrl, {
        headers: new Headers({
          Authorization: "Bearer " + loginRes.accessToken,
          "Content-Type": "application/json",
        }),
        method: "POST",
        body: JSON.stringify(data),
      });
      if (result.ok) {
        return result;
      }
      return {};
    } catch (err) {
      return err;
    }
  };

  public updateActivityIntegratedDocument = async (data: any): Promise<any> => {
    let _webAPIUrl = `${SharedService.updateDashActivityStatus}`;

    var tokenRequest = SharedService.apiScopes.impersonation;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    try {
      let result = await fetch(_webAPIUrl, {
        headers: new Headers({
          Authorization: "Bearer " + loginRes.accessToken,
          "Content-Type": "application/json",
        }),
        method: "POST",
        body: JSON.stringify(data),
      });
      if (result.ok) {
        return result;
      }
      return {};
    } catch (err) {
      return err;
    }
  };

  //API to read display Data
  public getPublishedKXDocument = async (id: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    //  let _webAPIUrl = "https://localhost:44346";
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/GetDocumentDisplayData/" + id
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getDocumentCurrentStatus = async (id: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    //  let _webAPIUrl = "https://localhost:44346";
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/GetDocumentCurrentStatus/" + id
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public LogError = (errorMessage: string) => {
    // let error:Error={
    //   error:errorMessage,
    //   timestamp: new Date(),
    //   userEmail:SharedService.GetInstance().Data.CurrentUser.kxp_email
    // };

    let error = `Error Occured at web app for User : ${SharedService.GetInstance().Data.CurrentUser.kxp_email
      } at ${new Date()} and detailed stacktrace is ${errorMessage} `;
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      fetch(_webAPIUrl + "api/Log", {
        method: "POST",
        body: JSON.stringify(error),
      })
        .then((response) => {
          if (response.status != 500) resolve(response.json());
          else resolve(null); //TODO:need to replace by application error msg:Noa
        })
        .then((items) => {
          resolve(items);
        });
    });
  };

  public GetContributedDataById = async (documentId: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346"
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/KxDocuments/" + documentId
      );
      if (result.ok) {
        return result.json();
      }
      return result;
    } catch (err) {
      return err;
    }
  };

  public GetDocumentBadgesById = async (documentId: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346"
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/DocumentBadges/" + documentId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getBadgeIconUrl() {
    return SharedService.badgeIconURL;
  }

  public getThumbnailBaseUrl() {
    return SharedService.thumbnailBaseUrl;
  }

  public GetBadgesList = async (): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346"
    try {
      let result = await fetch(_webAPIUrl + "/Contribution/Badges");
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetIndustryMoreInformation = (industry: number[]): Promise<any> => {
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      // let _webAPIUrl = "https://localhost:44346";
      var body = industry;
      fetch(_webAPIUrl + "/Contribution/MoreInformationByIndustry", {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        });
    });
  };

  public GetBusinessMoreInformation = (business: number[]): Promise<any> => {
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      // let _webAPIUrl = "https://localhost:44346";
      var body = business;
      fetch(_webAPIUrl + "/Contribution/MoreInformationByClientService", {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        });
    });
  };

  public UpdateKxDocument = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      //  let _webAPIUrl = "https://localhost:44346";
      var body = data;
      fetch(_webAPIUrl + "/Contribution/KxDocuments", {
        method: "PUT",
        body: JSON.stringify(body),
      })
        .then((response) => (response.ok ? response.json() : response))
        .then((res) => {
          resolve(res);
        });
    });
  };

  public GetDocumentRelatedLinksById = async (
    documentId: number
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346"
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/DocumentRelatedLinks/" + documentId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public FetchDocumentTopics = async (documentId: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346"
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/DocumentTopics/" + documentId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getDocumentNSTags = async (documentId: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    //  let _webAPIUrl = "https://localhost:44346"
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/DocumentNSTags/" + documentId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getNSTags = async (val: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    //  let _webAPIUrl = "https://localhost:44346"
    try {
      let result = await fetch(_webAPIUrl + "/api/getTagsData?typeId=" + val);
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  //To read DPN Profile Data
  public getDataFromDPNProfile = async (emailList: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    //  let _webAPIUrl = "https://localhost:44346";
    try {
      //let result = await fetch(`/api/getDPNProfiles?emailList:${emailList}`)
      let result = await fetch(_webAPIUrl + `/api/getDPNProfiles?${emailList}`);

      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  //To get collections data
  public getCollectionData = async (topicTagsList: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    //  let _webAPIUrl = "https://localhost:44346";
    // try {
    //   let result = await fetch( _webAPIUrl +"/api/getCollection?"+ new URLSearchParams({topicTags:topicTagsList,})
    //   )
    let result = await fetch(
      _webAPIUrl + `/api/getCollection?${topicTagsList}`
    );
    if (result.ok) {
      return result.json();
    }
    return {};
  };

  public GetSingleFileDownloadLink = async (
    docId: any,
    fileName: string,
    fileId: string
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346"
    try {
      let result = await fetch(
        _webAPIUrl +
        "/api/File/DownloadFile?" +
        new URLSearchParams({
          documentId: docId,
          originalFileName: fileName,
          attachmentId: fileId,
        })
      );
      if (result.ok) {
        return result;
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetDownloadAllLink = async (fileList: any[]): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346"
    let body = fileList;
    try {
      let result = await fetch(_webAPIUrl + "/api/File/DownloadAllFile?", {
        method: "POST",
        body: JSON.stringify(body),
      });
      if (result) {
        return result;
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetDisguisedClientData = async (clientName: string): Promise<any> => {
    let _webAPIUrl =
      SharedService.baseClientsQualApiUrl +
      `clients/ClientByName?name=${clientName}`;

    var tokenRequest = SharedService.apiScopes.kxclientqual;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    let headers = {
      Authorization: "Bearer " + loginRes.accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        headers,
      });
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetImageInformationList = async (docId: any): Promise<any> => {
    // let _webAPIUrl = 'https://devkxcentralapi.deloitteresources.com';
    // let _webAPIUrl = "https://localhost:44346"
    let _webAPIUrl = SharedService.kxcentralapiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/api/KXCentral/ImagesInformation?documentId=" + docId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public RemoveAttachment = async (data: any) => {
    let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    data.emailId = emailId;
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;

      fetch(`${_webAPIUrl}/FileUpload/RemoveAttachments`, {
        method: "DELETE",
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((res) => {
          console.log(
            "From Shared Service RemoveAttachmentNotification : Line 3696",
            res
          );
          resolve(res);
        });
    });
  };

  public GetKXDocumentAdminData = async (docId: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346"

    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/KxDocumentAdmin/" + docId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public SaveDocumentAdminData = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      // let _webAPIUrl = "https://localhost:44346";
      var body = data;
      fetch(_webAPIUrl + "/Contribution/KxDocumentAdmin", {
        method: "PUT",
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        });
    });
  };

  public GetViewSimilarData = async (
    docid: any,
    emailId: any
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;

    try {
      let result = await fetch(
        _webAPIUrl +
        "/api/getViewSimilarData?documentId=" +
        docid +
        `&emailid=${emailId}`
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  // public GetMyKXContributions = async (): Promise<any> => {
  //   let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
  //   //var emailId = 'ydahake@deloitte.com';
  //   let _webAPIUrl = SharedService.documentsWebApiUrl;

  //   try {
  //     let result = await fetch(
  //       _webAPIUrl + "/Contribution/GetMyKXDocuments/" + emailId
  //     );
  //     if (result.ok) {
  //       return result.json();
  //     }
  //     return {};
  //   } catch (err) {
  //     return {};
  //   }
  // }

  public GetUserDocuments = async (): Promise<any> => {
    let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    //var emailId = 'ydahake@deloitte.com';
    let _webAPIUrl = SharedService.documentsWebApiUrl;

    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/GetUserDocuments/" + emailId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetArchiveYear = async (
    contentTypeId: number,
    globalbusinessId: number
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;

    try {
      let result = await fetch(
        _webAPIUrl +
        "/Contribution/GetArchiveYears/" +
        contentTypeId +
        `/?primaryGlobalBusinessId=${globalbusinessId}`
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  //To read audit Log Data
  public getDocumentAuditLogData = async (documentId: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346";
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/KxDocumentAuditlogs/" + documentId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getConceptTagsData = async (documentId: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    //  let _webAPIUrl = "https://localhost:44346"
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/DocumentConceptTags/" + documentId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getContentTagsData = async (documentId: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    //  let _webAPIUrl = "https://localhost:44346"
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/DocumentContentTags/" + documentId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getAISuggestionsData = async (
    documentId: string | undefined
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(_webAPIUrl + "/api/AISuggestions/" + documentId);
      if (result) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public getUserPriorityRole = async (userRoles: any): Promise<any> => {
    for (const key in userRoles) {
      if (userRoles[key]) return key;
    }
    return undefined;
  };

  public getDataAssignmentsTabOne = async (
    searchTerm: string,
    sortBy: string,
    orderBy: string,
    pageIndex: number,
    pageSize: number,
    bulkFilter: boolean,
    curationFilter: boolean
  ): Promise<any> => {
    let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    let userInfo = SharedService.currentUserObj;
    let userRoles = await this.GetUserRoleGroupsAndPrivileges();
    const userRole = await this.getUserPriorityRole(userRoles.roleGroups);
    try {
      let result = await fetch(
        _webAPIUrl +
        "/api/PublishingDashboard/GetMyAssignmentNewContribution/" +
        emailId +
        `/?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&pageIndex=${pageIndex}&pageSize=${pageSize}&geographyId=${userInfo.geographyId}&userRole=${userRole}&bulkFilter=${bulkFilter}&curationFilter=${curationFilter}`
      );
      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetMyKXContributions = async (
    status: string,
    sortBy: string,
    orderBy: string,
    currentPage: number,
    pageSize: number
  ): Promise<any> => {
    let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    let _webAPIUrl = SharedService.documentsWebApiUrl;

    try {
      let result = await fetch(
        _webAPIUrl +
        "/Contribution/GetMyKXDocuments/" +
        emailId +
        `/?statusFilter=${status}&sortBy=${sortBy}&orderBy=${orderBy}&currentPage=${currentPage}&pageSize=${pageSize}`
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getDataAssignmentsTabTwo = async (
    searchTerm: string,
    sortBy: string,
    orderBy: string,
    pageIndex: number,
    pageSize: number,
    bulkFilter: boolean,
    curationFilter: boolean
  ): Promise<any> => {
    let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    let userInfo = SharedService.currentUserObj;
    let userRoles = await this.GetUserRoleGroupsAndPrivileges();
    const userRole = await this.getUserPriorityRole(userRoles.roleGroups);
    try {
      let result = await fetch(
        _webAPIUrl +
        "/api/PublishingDashboard/GetMyAssignmentUpdateRequested/" +
        emailId +
        `/?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&pageIndex=${pageIndex}&pageSize=${pageSize}&geographyId=${userInfo.geographyId}&userRole=${userRole}&bulkFilter=${bulkFilter}&curationFilter=${curationFilter}`
      );
      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public myassignmentsExportForPublisherDashboard = async (
    url: string,
    documentIds: string[],
    searchTerm: string | null,
    bulkFilter: boolean,
    curationFilter: boolean
  ): Promise<any> => {
    let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    let _webAPIUrl = SharedService.documentsWebApiUrl;

    let userInfo = SharedService.currentUserObj;
    let userRoles = await this.GetUserRoleGroupsAndPrivileges();
    const userRole = await this.getUserPriorityRole(userRoles.roleGroups);

    let params: {} = new URLSearchParams({
      apiName: url,
      selectedDocumentIDs: documentIds.length > 0 ? documentIds.join(",") : "",
      geographyId: userInfo.geographyId,
      userRole: userRole,
      searchTerm: searchTerm ? searchTerm : "",
      bulkFilter: String(bulkFilter),
      curationFilter: String(curationFilter),
    });
    try {
      let result = await fetch(
        _webAPIUrl +
        `/api/PublishingDashboard/GetPublishingDashboardExportForParams/${emailId}?` +
        new URLSearchParams(params)
      );
      if (result) {
        return result;
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  //To Unassigned AllDocuments NewContribution data
  public getUnassignedAllDocNewContributions = async (
    searchTerm: string,
    sortBy: string,
    orderBy: string,
    pageIndex: number,
    pageSize: number,
    bulkFilter: boolean,
    curationFilter: boolean
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl +
        `/api/PublishingDashboard/GetAllDocumentUnAssignedNewContribution?${searchTerm !== "" ? `searchTerm=${searchTerm}&` : ""
        }sortBy=${sortBy}&orderBy=${orderBy}&pageIndex=${pageIndex}&pageSize=${pageSize}&bulkFilter=${bulkFilter}&curationFilter=${curationFilter}`
      );
      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getAssignedAllDocNewContributions = async (
    searchTerm: string,
    sortBy: string,
    orderBy: string,
    pageIndex: number,
    pageSize: number,
    bulkFilter: boolean,
    curationFilter: boolean
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl +
        `/api/PublishingDashboard/GetAllDocumentAssignedorInProcessNewContribution?${searchTerm !== "" ? `searchTerm=${searchTerm}&` : ""
        }sortBy=${sortBy}&orderBy=${orderBy}&pageIndex=${pageIndex}&pageSize=${pageSize}&bulkFilter=${bulkFilter}&curationFilter=${curationFilter}`
      );
      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };
  public getUnAssignedUpdateRequested = async (
    searchTerm: string,
    sortBy: string,
    orderBy: string,
    pageIndex: number,
    pageSize: number,
    bulkFilter: boolean,
    curationFilter: boolean
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl +
        `/api/PublishingDashboard/GetAllDocumentUnAssignedUpdateRequested?${searchTerm !== "" ? `searchTerm=${searchTerm}&` : ""
        }sortBy=${sortBy}&orderBy=${orderBy}&pageIndex=${pageIndex}&pageSize=${pageSize}&bulkFilter=${bulkFilter}&curationFilter=${curationFilter}`
      );
      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };
  public getAssignedUpdateRequested = async (
    searchTerm: string,
    sortBy: string,
    orderBy: string,
    pageIndex: number,
    pageSize: number,
    bulkFilter: boolean,
    curationFilter: boolean
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl +
        `/api/PublishingDashboard/GetAllDocumentAssignedorInProcessUpdateRequested?${searchTerm !== "" ? `searchTerm=${searchTerm}&` : ""
        }sortBy=${sortBy}&orderBy=${orderBy}&pageIndex=${pageIndex}&pageSize=${pageSize}&bulkFilter=${bulkFilter}&curationFilter=${curationFilter}`
      );
      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getDocumentStatus = async (documentId: string): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/api/AISuggestions/AIAlertStatus/" + documentId
      );
      if (result) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public createAISuggestionsEntry = async (data: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/api/AISuggestions/CreateAISuggestionsEntry",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };
  public GetUpdatedClientData = async (
    clientId: string,
    Confidentiality: number
  ): Promise<any> => {
    if (clientId !== null) {
      let _webAPIUrl =
        SharedService.baseClientsQualApiUrl +
        `clients/Client?clientId=${clientId}&Clientconfidentiality=${Confidentiality}`;

      var tokenRequest = SharedService.apiScopes.kxclientqual;
      tokenRequest.account = SharedService.GetInstance().account;
      var loginRes =
        await SharedService.GetInstance().msalClient.acquireTokenSilent(
          tokenRequest
        );
      let headers = {
        Authorization: "Bearer " + loginRes.accessToken,
      };

      try {
        let result = await fetch(_webAPIUrl, {
          headers,
        });
        if (result.ok) {
          return result.json();
        }
        return {};
      } catch (err) {
        return {};
      }
    } else {
      return {};
    }
  };
  public GetUpdatedQualData = async (qualId: string): Promise<any> => {
    let _webAPIUrl =
      SharedService.baseClientsQualApiUrl +
      `quals/ClientQuals?qualId=${qualId}`;

    var tokenRequest = SharedService.apiScopes.kxclientqual;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    let headers = {
      Authorization: "Bearer " + loginRes.accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        headers,
      });
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };
  public removeDocument = async (documentId: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/RemoveDocument?documentId=" + documentId,
        {
          method: "DELETE",
        }
      );
      if (result) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };
  public singleReportDownloadFile = async (
    originalFileName: number
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/api/Report/DownloadFile?reportType=" + originalFileName
      );
      if (result) {
        return result;
      }
      return {};
    } catch (err) {
      return {};
    }
  };
  public zipReportDownloadFile = async (
    originalFileNameList: string[]
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    let originalFileNameListNew = originalFileNameList.map((items: any) =>
      parseInt(items)
    );
    try {
      let result = fetch(_webAPIUrl + "/api/Report/DownloadZip", {
        method: "POST",
        body: JSON.stringify(originalFileNameListNew),
      });

      if (result) {
        return result;
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getDocumentAttachmentsData = async (
    documentId: string
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/Attachments/Attachments/" + documentId,
        {
          method: "GET",
        }
      );
      if (result) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public createAutoCleansingEntry = async (data: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/Attachments/CreateAutoCleansingEntry",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public createAzureQueueEntry = async (data: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/FileUpload/AutoCleanseRequestToAzureQueue",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public aiProcessStatusCheck = async (documentId: string): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/api/AISuggestions/AICurrentStatus/" + documentId,
        {
          method: "GET",
        }
      );
      if (result) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public GetRawUserRoleGroupsAndPrivileges = async (): Promise<any> => {
    var postFix = window.location.host.substring(
      0,
      window.location.host.indexOf(".")
    );
    var userInfo = localStorage.getItem(
      Constants.SessionStorageItems.RoleGroupsAndPrivileges + "_" + postFix
    );
    if (userInfo != null && JSON.parse(userInfo).data.roleGroups) {
      let userRoleData = JSON.parse(userInfo).data;
      if (userRoleData) {
        return {
          roleGroups: userRoleData.roleGroups,
          userPrivileges: userRoleData.rolePrivileges,
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  public GetUserRoleGroupsAndPrivileges = async (): Promise<any> => {
    var postFix = window.location.host.substring(
      0,
      window.location.host.indexOf(".")
    );
    var userInfo = localStorage.getItem(
      Constants.SessionStorageItems.RoleGroupsAndPrivileges + "_" + postFix
    );
    if (userInfo != null && JSON.parse(userInfo).data.roleGroups) {
      let userRoleData = JSON.parse(userInfo).data;
      if (userRoleData) {
        if (userRoleData.roleGroups) {
          let rolesObj = {
            productAdministrators: false,
            publishingTeamLeaders: false,
            publishers: false,
            publishersDeliveryExcellence: false,
            collectionCurators: false,
            knowledgeServicesProfessionals: false,
          };
          if (userRoleData.roleGroups.productAdministrators) {
            rolesObj.productAdministrators = true;
          } else if (userRoleData.roleGroups.publishingTeamLeaders) {
            rolesObj.publishingTeamLeaders = true;
          } else if (userRoleData.roleGroups.publishers) {
            rolesObj.publishers = true;
          } else if (userRoleData.roleGroups.publishersDeliveryExcellence) {
            rolesObj.publishersDeliveryExcellence = true;
          } else if (userRoleData.roleGroups.collectionCurators) {
            rolesObj.collectionCurators = true;
          } else if (userRoleData.roleGroups.knowledgeServicesProfessionals) {
            rolesObj.knowledgeServicesProfessionals = true;
          }
          userRoleData.roleGroups = rolesObj;
        }
        return {
          roleGroups: userRoleData.roleGroups,
          userPrivileges: userRoleData.rolePrivileges,
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  public GetUserFromLocalStorage = async (): Promise<any> => {
    var postFix = window.location.host.substring(
      0,
      window.location.host.indexOf(".")
    );
    var userInfo = localStorage.getItem(
      Constants.SessionStorageItems.User + "_" + postFix
    );
    var userInfoParsed = userInfo ? JSON.parse(userInfo) : null;
    if (userInfoParsed) {
      return userInfoParsed.data;
    }
    return null;
  };

  public getDocumentView = async (
    searchTerm: string,
    sortBy: string,
    orderBy: string,
    pageIndex: number,
    pageSize: number
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl +
        `/api/PublishingDashboard/GetDocumentsView?searchTerm=${searchTerm}&sortBy=${sortBy}&orderBy=${orderBy}&pageIndex=${pageIndex}&pageSize=${pageSize}`
      );
      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public deleteThumbnail = async (data: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl +
        "/FileUpload/DeleteThumbnail?" +
        new URLSearchParams({
          fileName: data.fileName,
          documentId: data.documentId,
        }),
        {
          method: "POST",
        }
      );
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public GetAllMailboxes = async (
    pageIndex: number,
    pageSize: number
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;

    try {
      let result = await fetch(
        _webAPIUrl +
        "/api/ProductAdmin/GetAllMailboxes" +
        `/?pageIndex=${pageIndex}&pageSize=${pageSize}`
      );

      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public addMailbox = async (data: any) => {
    var userEmail = SharedService.instance.Data.CurrentUser.kxp_email;
    data.createdBy = userEmail;

    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(_webAPIUrl + "/api/ProductAdmin/AddMailbox", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public updateMailbox = async (data: any) => {
    var userEmail = SharedService.instance.Data.CurrentUser.kxp_email;
    data.modifiedBy = userEmail;

    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(_webAPIUrl + "/api/ProductAdmin/UpdateMailbox", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public deleteMailbox = async (data: any) => {
    var userEmail = SharedService.instance.Data.CurrentUser.kxp_email;
    data.modifiedBy = userEmail;

    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(_webAPIUrl + "/api/ProductAdmin/DeleteMailbox", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public GetAllMoreInformation = async (
    pageIndex: number,
    pageSize: number
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;

    try {
      let result = await fetch(
        _webAPIUrl +
        "/api/ProductAdmin/GetAllMoreInformation" +
        `/?pageIndex=${pageIndex}&pageSize=${pageSize}`
      );

      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public addMoreInformation = async (data: any) => {
    var userEmail = SharedService.instance.Data.CurrentUser.kxp_email;
    data.createdBy = userEmail;

    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/api/ProductAdmin/AddMoreInformation",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public updateMoreInformation = async (data: any) => {
    var userEmail = SharedService.instance.Data.CurrentUser.kxp_email;
    data.modifiedBy = userEmail;

    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/api/ProductAdmin/UpdateMoreInformation",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public deleteMoreInformation = async (data: any) => {
    var userEmail = SharedService.instance.Data.CurrentUser.kxp_email;
    data.modifiedBy = userEmail;

    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/api/ProductAdmin/DeleteMoreInformation",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public exportForAllDocsPublisherDashboard = async (
    url: string,
    documentIds: string[],
    searchTerm: string,
    bulkFilter: boolean,
    curationFilter: boolean
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    let params: {} = new URLSearchParams({
      apiName: url,
      selectedDocumentIDs: documentIds.length > 0 ? documentIds.join(",") : "",
      searchTerm: searchTerm,
      bulkFilter: String(bulkFilter),
      curationFilter: String(curationFilter),
    });

    try {
      let result = await fetch(
        _webAPIUrl +
        "/api/PublishingDashboard/GetPublishingDashboardExport?" +
        new URLSearchParams(params)
      );
      if (result) {
        return result;
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public uploadFileChunks = async (
    fileGuid: any,
    fileName: string,
    kamDocumentId: any,
    stats: any,
    fileChunkedOffsetIv: number,
    chunk: any
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    fileName = encodeURIComponent(
      CommonFunctions.ReplaceSpecialCharsInFileName(fileName)
    ); //.replace(/[~#%&*:\"<>\/\\\?+|]/g, '_');
    try {
      let result = await fetch(
        `${_webAPIUrl}/FileUpload/UploadChunksSPO?id=${fileGuid}&fileName=${fileName}&kamDocumentId=${kamDocumentId}&status=${stats}&fileChunkedOffsetIv=${fileChunkedOffsetIv}`,
        {
          method: "POST",
          body: chunk,
        }
      );
      if (result) {
        return result;
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public uploadCompleted = async (opObject: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(`${_webAPIUrl}/FileUpload/UploadCompleteSPO`, {
        method: "POST",
        body: JSON.stringify(opObject),
      });
      if (result) {
        return result;
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public updateAttachmentDetailsInDb = async (
    attachments: Attachment[]
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        `${_webAPIUrl}/Contribution/AddDocumentAttachments`,
        {
          method: "POST",
          body: JSON.stringify(attachments),
        }
      );
      if (result) {
        return result;
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GenerateAttachmentNotification = async (
    emailId: string,
    documentId: number,
    failedAttachments: boolean
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        `${_webAPIUrl}/Contribution/GenerateNotification?emailId=${emailId}&documentId=${documentId}&failedAttachments=${failedAttachments}`,
        {
          method: "POST",
        }
      );
      if (result) {
        return result;
      }
      return {};
    } catch (err) {
      return {};
    }
  };
  public updateAttachmentName = async (
    data: any,
    newName: string,
    createdby: string,
    status: any,
    substatus: any
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    newName = CommonFunctions.ReplaceSpecialCharsInFileName(newName); //.replace(/[~#%&*:\"<>\/\\\?+|]/g, '_');
    try {
      let result = await fetch(
        _webAPIUrl +
        "/FileUpload/RenameAttachment?" +
        new URLSearchParams({
          fileName: newName,
          documentId: data.documentId,
          attachmentId: data.attachmentId,
          createdBy: createdby,
          documentStatus: status,
          publishingStatus: substatus,
        }),
        {
          method: "PUT",
        }
      );
      if (result) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public GetAllBadgesAdmin = async (): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;

    try {
      let result = await fetch(_webAPIUrl + "/api/ProductAdmin/GetAllBadges");

      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public updateBadge = async (data: any) => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(_webAPIUrl + "/api/ProductAdmin/UpdateBadge", {
        method: "PUT",
        body: JSON.stringify(data),
      });
      if (result) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public CreateNewBadge = async (data: any) => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(_webAPIUrl + "/api/ProductAdmin/AddNewBadge", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public GetEmailContent = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      var body = data;
      fetch(_webAPIUrl + "/Contribution/GetEmailContent", {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => (response.ok ? response.json() : response))
        .then((res) => {
          resolve(res);
        });
    });
  };

  public GetAllBadgesAdminKX = async (): Promise<any> => {
    let _webAPIUrl = SharedService.webUrl;

    try {
      let result = await fetch(
        _webAPIUrl + "api/ns/getmasterdata?masterFlag=badges"
      );

      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetBadgeTypeOptions = async (): Promise<any> => {
    let _webAPIUrl = SharedService.webUrl;

    try {
      let result = await fetch(_webAPIUrl + "api/ns/GetBadgeTypes");
      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetBadgeSourceOptions = async (): Promise<any> => {
    let _webAPIUrl = SharedService.webUrl;

    try {
      let result = await fetch(_webAPIUrl + "api/ns/GetBadgeSource");
      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetTopicOptions = async (): Promise<any> => {
    let _webAPIUrl = SharedService.webUrl;

    try {
      let result = await fetch(_webAPIUrl + "api/ns/GetTopics");
      if (result) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public CreateNewBadgeKX = async (data: any) => {
    let _webAPIUrl = SharedService.webUrl;
    try {
      let result = await fetch(_webAPIUrl + "api/ns/addNewBadge", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public updateBadgeKX = async (data: any) => {
    let _webAPIUrl = SharedService.webUrl;
    try {
      let result = await fetch(_webAPIUrl + "api/ns/UpdateBadge", {
        method: "PUT",
        body: JSON.stringify(data),
      });
      if (result) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public deleteBadgeKX = async (id: any) => {
    let _webAPIUrl = SharedService.webUrl;
    try {
      let result = await fetch(_webAPIUrl + "api/ns/DeleteBadge?id=" + id, {
        method: "DELETE",
      });
      if (result) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public getLanguagesData = async (): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(_webAPIUrl + "/api/getLanguagesData");
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getBadgesData = async (badgeIds: string): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/GetBadgesData/" + badgeIds,
        {
          method: "GET",
        }
      );
      if (result) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public GetPageLevelRestrictions = async (): Promise<any> => {
    var postFix = window.location.host.substring(
      0,
      window.location.host.indexOf(".")
    );
    var pageRestrictions = sessionStorage.getItem(
      Constants.SessionStorageItems.PageLevelRestrictions + "_" + postFix
    );
    var pageRestrictionsParsed = pageRestrictions
      ? JSON.parse(pageRestrictions)
      : null;
    if (pageRestrictionsParsed) {
      return pageRestrictionsParsed.data;
    }
    return null;
  };

  public downloadBulkUpdateTemplate = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      let _webAPIUrl = SharedService.documentsWebApiUrl;
      fetch(_webAPIUrl + "/BulkUpdate/DownloadTemplate", {
        method: "GET",
      }).then((res) => {
        resolve(res);
      });
    });
  };

  public uploadBulkUpdateTemplate = async (formdata: any): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    const tokenRequest = SharedService.apiScopes.tokenRequest;
    let loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    let result = await fetch(_webAPIUrl + "/BulkUpdate/UploadFile", {
      method: "POST",
      body: formdata,
      headers: {
        authorization: "Bearer " + loginRes.accessToken,
        // "content-type":"multipart/form-data;boundary=<calculated when request is sent>",
      },
      // redirect: 'follow'
    });
    return result.text();
    // .then(response => {let a = response.text();return a;})
    // .then(result => {return result})
    // .catch(error => console.log('error', error));
  };

  public getBulkUpdateLogs = async (
    currentPage: number,
    pageSize: number
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    let result = await fetch(
      _webAPIUrl +
      "/BulkUpdate/GetLogs?" +
      `pageSize=${pageSize}&currentPage=${currentPage}`,
      {
        method: "GET",
      }
    );
    if (result) {
      return result.json();
    }
  };

  public downloadLogFile = async (logId: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    let result = await fetch(
      _webAPIUrl + "/BulkUpdate/DownloadLogFile?logid=" + logId
    );
    if (result) {
      return result;
    }
  };

  public getDocumentStatusForEmails = async (): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/EmailConfigurations/GetDocumentStatusForEmails"
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public getEmailTemplatesByStatus = async (statusId: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl +
        "/EmailConfigurations/GetEmailTemplatesByStatus/" +
        statusId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public modifyEmailTemplate = async (data: any) => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/EmailConfigurations/ModifyEmailTemplate",
        {
          method: "PUT",
          body: JSON.stringify(data),
        }
      );
      return result;
    } catch (error) {
      return error;
    }
  };

  public GetPageRestrictedGeoLocations = async (): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/GeoRestrictions/GetPageRestrictedGeoLocations",
        {
          method: "GET",
        }
      );
      if (result.ok) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public GetPageRestrictionsWithId = async (geoId: number): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        `${_webAPIUrl}/GeoRestrictions/GetPageRestrictions/${geoId}`,
        {
          method: "GET",
        }
      );
      if (result.ok) {
        return result.json();
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public ModifyPageLevelRestrictions = async (data: any) => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/GeoRestrictions/ModifyPageLevelRestrictions",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result.ok) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public DeleteRestriction = async (
    id: number,
    geoId: number
  ): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        `${_webAPIUrl}/GeoRestrictions/DeleteRestriction/${id}/${geoId}`,
        {
          method: "DELETE",
        }
      );
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public UpdatePriorities = async (data: any) => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/GeoRestrictions/UpdatePriorities",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public GetMiniCardDropDownValue = async (): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346"

    try {
      let result = await fetch(_webAPIUrl + "/Contribution/GetMiniCard");
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public GetImageTypeDropDownValue = async (): Promise<any> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346"

    try {
      let result = await fetch(_webAPIUrl + "/Contribution/GetImageType");
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };

  public ExtractImages = async (data: any) => {
    let _webAPIUrl = SharedService.documentsImgExtUrl;
    //var formdata = new FormData();
    //formdata.append("Values", JSON.stringify(data));
    var tokenRequest = SharedService.apiScopes.tokenRequest;
    tokenRequest.account = SharedService.GetInstance().account;
    var loginRes =
      await SharedService.GetInstance().msalClient.acquireTokenSilent(
        tokenRequest
      );
    try {
      let result = await fetch(_webAPIUrl + "/processimages", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: "Bearer " + loginRes.accessToken,
        }),
        body: JSON.stringify(data),
      });
      if (result.ok) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public SaveImageExtractionStatus = async (data: any) => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/SaveImageExtractionStatus",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result.ok) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public DeleteImages = async (data: any) => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/DeleteExtractedImages",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result.ok) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public SaveExtractedImages = async (data: any) => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/SaveExtractedImages",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result.ok) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public PublishExtractedImages = async (data: any) => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/PublishExtractedImages",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (result.ok) {
        return result;
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  public GetExtractedImages = async (docId: any) => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    // let _webAPIUrl = "https://localhost:44346"

    try {
      let result = await fetch(
        _webAPIUrl + "/Contribution/GetExtractedImages?documentdetails=" + docId
      );
      if (result.ok) {
        return result.json();
      }
      return {};
    } catch (err) {
      return {};
    }
  };
  //To get all tags data
  public getAllTagsData = async (): Promise<{}> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    let result = await fetch(_webAPIUrl + `/api/getAllTagsData`);
    if (result.ok) {
      return result.json();
    }
    return {};
  };

  //To get all ns tags data
  public getAllNSTagsData = async (): Promise<{}> => {
    let _webAPIUrl = SharedService.documentsWebApiUrl;
    let result = await fetch(_webAPIUrl + `/api/getNonSynapticaAllTagsData`);
    if (result.ok) {
      return result.json();
    }
    return {};
  };

  public getGenAIResponse = async (
    payloadData: IPromptRequestObj
  ): Promise<IPromptResponseObj | null | Error> => {
    // let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    const tokenObjKey = Constants.SessionStorageItems.AccessToken;
    const currentTimeInSeconds = new Date().getTime() / 1000;
    let _webAPIUrl = SharedService.genaiApiUrl + "/kxai";

    //Fetching stored token from local storage
    const storedTokenObj: any = sessionStorage.getItem(
      Constants.SessionStorageItems.AccessToken
    );

    const parsedStoredTokenObj = JSON.parse(storedTokenObj);
    let accessToken = parsedStoredTokenObj?.accessToken;
    const expiryTime = parsedStoredTokenObj?.expiresOn;
    const expiryTimeInSeconds = new Date(expiryTime).getTime() / 1000;

    //Checking expiry of stored token
    if (!parsedStoredTokenObj || currentTimeInSeconds > expiryTimeInSeconds) {
      var tokenRequest = SharedService.genaiAPIScopes.impersonation;
      tokenRequest.account = SharedService.GetInstance().account;
      var loginRes =
        await SharedService.GetInstance().msalClient.acquireTokenSilent(
          tokenRequest
        );
      accessToken = loginRes.accessToken;
      sessionStorage.setItem(tokenObjKey, JSON.stringify(loginRes));
    }

    let headers = {
      "Ocp-Apim-Subscription-Key": SharedService.genaiSubscriptionKey,
      Authorization: "Bearer " + accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        method: "POST",
        body: JSON.stringify(payloadData),
        headers,
      });
      const data = await result.json();

      if (!result.ok) {
        const errorMessage = `${data?.message || data?.error} Error code: ${result.status
          }`;
        throw new Error(errorMessage);
      }

      return data;
    } catch (err) {
      return err as Error;
    }
  };

  public getGenAIConversationHistory = async (): Promise<
    IConversationHistoryResponseObj[] | undefined | Error
  > => {
    // let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    const tokenObjKey = Constants.SessionStorageItems.AccessToken;
    const currentTimeInSeconds = new Date().getTime() / 1000;
    let _webAPIUrl = SharedService.genaiApiUrl + "/historyapi";

    //Fetching stored token from local storage
    const storedTokenObj: any = sessionStorage.getItem(
      Constants.SessionStorageItems.AccessToken
    );

    const parsedStoredTokenObj = JSON.parse(storedTokenObj);
    let accessToken = parsedStoredTokenObj?.accessToken;
    const expiryTime = parsedStoredTokenObj?.expiresOn;
    const expiryTimeInSeconds = new Date(expiryTime).getTime() / 1000;

    //Checking expiry of stored token
    if (!parsedStoredTokenObj || currentTimeInSeconds > expiryTimeInSeconds) {
      var tokenRequest = SharedService.genaiAPIScopes.impersonation;
      tokenRequest.account = SharedService.GetInstance().account;
      var loginRes =
        await SharedService.GetInstance().msalClient.acquireTokenSilent(
          tokenRequest
        );
      accessToken = loginRes.accessToken;
      sessionStorage.setItem(tokenObjKey, JSON.stringify(loginRes));
    }

    let headers = {
      "Ocp-Apim-Subscription-Key": SharedService.genaiSubscriptionKey,
      Authorization: "Bearer " + accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        method: "GET",
        headers,
      });
      const data = await result.json();

      if (!result.ok) {
        const errorMessage = `${data?.message || data?.error} Error code: ${result.status
          }`;
        throw new Error(errorMessage);
      }
      return data;
    } catch (err) {
      return err as Error;
    }
  };

  public addGenAIPromptFeedback = async (
    payloadData: IPromptFeedbackRequestObj
  ): Promise<IPromptFeedbackResponseObj | undefined | Error> => {
    // let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    const tokenObjKey = Constants.SessionStorageItems.AccessToken;
    const currentTimeInSeconds = new Date().getTime() / 1000;
    let _webAPIUrl = SharedService.genaiApiUrl + "/feedbackapi";

    //Fetching stored token from local storage
    const storedTokenObj: any = sessionStorage.getItem(
      Constants.SessionStorageItems.AccessToken
    );

    const parsedStoredTokenObj = JSON.parse(storedTokenObj);
    let accessToken = parsedStoredTokenObj?.accessToken;
    const expiryTime = parsedStoredTokenObj?.expiresOn;
    const expiryTimeInSeconds = new Date(expiryTime).getTime() / 1000;

    //Checking expiry of stored token
    if (!parsedStoredTokenObj || currentTimeInSeconds > expiryTimeInSeconds) {
      var tokenRequest = SharedService.genaiAPIScopes.impersonation;
      tokenRequest.account = SharedService.GetInstance().account;
      var loginRes =
        await SharedService.GetInstance().msalClient.acquireTokenSilent(
          tokenRequest
        );
      accessToken = loginRes.accessToken;
      sessionStorage.setItem(tokenObjKey, JSON.stringify(loginRes));
    }

    let headers = {
      "Ocp-Apim-Subscription-Key": SharedService.genaiSubscriptionKey,
      Authorization: "Bearer " + accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        method: "POST",
        body: JSON.stringify(payloadData),
        headers,
      });
      const data = await result.json();

      if (!result.ok) {
        const errorMessage = `${data?.message || data?.error} Error code: ${result.status
          }`;
        throw new Error(errorMessage);
      }

      return data;
    } catch (err) {
      return err as Error;
    }
  };

  public getGenAIKxResultsFromHistory = async (
    payloadData: IConversationKxHistoryRequestObj
  ): Promise<IConversationKxHistoryResponseObj[] | undefined | Error> => {
    // let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    const tokenObjKey = Constants.SessionStorageItems.AccessToken;
    const currentTimeInSeconds = new Date().getTime() / 1000;
    let _webAPIUrl = SharedService.genaiApiUrl + "/conversationforsession";

    //Fetching stored token from local storage
    const storedTokenObj: any = sessionStorage.getItem(
      Constants.SessionStorageItems.AccessToken
    );

    const parsedStoredTokenObj = JSON.parse(storedTokenObj);
    let accessToken = parsedStoredTokenObj?.accessToken;
    const expiryTime = parsedStoredTokenObj?.expiresOn;
    const expiryTimeInSeconds = new Date(expiryTime).getTime() / 1000;

    //Checking expiry of stored token
    if (!parsedStoredTokenObj || currentTimeInSeconds > expiryTimeInSeconds) {
      var tokenRequest = SharedService.genaiAPIScopes.impersonation;
      tokenRequest.account = SharedService.GetInstance().account;
      var loginRes =
        await SharedService.GetInstance().msalClient.acquireTokenSilent(
          tokenRequest
        );
      accessToken = loginRes.accessToken;
      sessionStorage.setItem(tokenObjKey, JSON.stringify(loginRes));
    }

    let headers = {
      "Ocp-Apim-Subscription-Key": SharedService.genaiSubscriptionKey,
      Authorization: "Bearer " + accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        method: "POST",
        body: JSON.stringify(payloadData),
        headers,
      });
      const data = await result.json();

      if (!result.ok) {
        const errorMessage = `${data?.message || data?.error} Error code: ${result.status
          }`;
        throw new Error(errorMessage);
      }

      return data;
    } catch (err) {
      return err as Error;
    }
  };

  public getGenAIKxResults = async (
    payloadData: IPromptKxResultRequestObj
  ): Promise<IPromptKxResultResponseObj | undefined | Error> => {
    // let emailId = SharedService.GetInstance().Data.CurrentUser.kxp_email;
    const tokenObjKey = Constants.SessionStorageItems.AccessToken;
    const currentTimeInSeconds = new Date().getTime() / 1000;
    let _webAPIUrl = SharedService.genaiApiUrl + "/conversationapi";

    //Fetching stored token from local storage
    const storedTokenObj: any = sessionStorage.getItem(
      Constants.SessionStorageItems.AccessToken
    );

    const parsedStoredTokenObj = JSON.parse(storedTokenObj);
    let accessToken = parsedStoredTokenObj?.accessToken;
    const expiryTime = parsedStoredTokenObj?.expiresOn;
    const expiryTimeInSeconds = new Date(expiryTime).getTime() / 1000;

    //Checking expiry of stored token
    if (!parsedStoredTokenObj || currentTimeInSeconds > expiryTimeInSeconds) {
      var tokenRequest = SharedService.genaiAPIScopes.impersonation;
      tokenRequest.account = SharedService.GetInstance().account;
      var loginRes =
        await SharedService.GetInstance().msalClient.acquireTokenSilent(
          tokenRequest
        );
      accessToken = loginRes.accessToken;
      sessionStorage.setItem(tokenObjKey, JSON.stringify(loginRes));
    }

    let headers = {
      "Ocp-Apim-Subscription-Key": SharedService.genaiSubscriptionKey,
      Authorization: "Bearer " + accessToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        method: "POST",
        body: JSON.stringify(payloadData),
        headers,
      });
      const data = await result.json();

      if (!result.ok) {
        const errorMessage = `${data?.message || data?.error} Error code: ${result.status
          }`;
        throw new Error(errorMessage);
      }

      return data;
    } catch (err) {
      return err as Error;
    }
  };

  public getQuickViewData = async (
    payloadData: IQuickViewResultRequestObj
  ): Promise<IQuickViewResultResponseObj | undefined | Error> => {
    let _webAPIUrl =
      SharedService.webUrl +
      `api/getQuickViewData?appid=${payloadData.appid}&source=${payloadData.source}`;

    try {
      let result = await fetch(_webAPIUrl, {
        method: "GET",
      });
      const data = await result.json();

      if (!result.ok) {
        const errorMessage = `${data?.message || data?.error} Error code: ${result.status
          }`;
        throw new Error(errorMessage);
      }
      return data;
    } catch (err) {
      return err as Error;
    }
  };

  public getDPNImgData = async (
    accountName: string
  ): Promise<IGetDPNImageDataResponseObj | undefined | Error> => {
    let _webAPIUrl = SharedService.dpnImgApiUrl + accountName;

    const headers = {
      "Ocp-Apim-Subscription-Key": SharedService.dpnImageKey,
      Authorization: "Bearer " + SharedService.DPNToken,
    };

    try {
      let result = await fetch(_webAPIUrl, {
        method: "GET",
        headers,
      });
      const data = await result.json();

      if (!result.ok) {
        const errorMessage = `${data?.message || data?.error} Error code: ${result.status
          }`;
        throw new Error(errorMessage);
      }
      return data;
    } catch (err) {
      return err as Error;
    }
  };
}
