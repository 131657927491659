import React, { useEffect } from "react";

const UnauthorizedPage: React.FC = () => {

    return (
        <div style={{"padding": "120px","textAlign": "center","fontSize": "xx-large","fontWeight": "bold"}}>
            Not Authorized.
        </div>
    );
};

export default UnauthorizedPage;
