import React, { lazy, Suspense } from 'react';
import {withRouter} from 'react-router';

const LazyCuratedCollection = lazy(() => import(/* webpackChunkName: "curatedcollection" */'./curatedCollection'));

const CuratedCollection = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (

  <Suspense fallback={null}>
    <LazyCuratedCollection {...props} />
  </Suspense>
);

export default withRouter(CuratedCollection);
