import React from "react";
import Scrollbars from "react-custom-scrollbars";

export const ScrollbarCustom: React.FunctionComponent = (props) => {
  const renderView = (style: any, ...props: any) => {
    return (
      <div className="box" style={{ ...style, overflowY: "auto !important" }} {...props} />
    );
  };
  const renderThumb = (style: any, ...props: any) => {
    const thumbStyle = {
      backgroundColor: "#767676",
      width: 0,
      borderRadius: 2,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  return (
    <Scrollbars
      autoHeight
      autoHeightMin="100%"
      autoHeightMax="100%"
      renderView={renderView}
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      autoHide={false}
      universal={true}
      className={"custom-scrollbar"}
      {...props}
    />
  );
};
