import React from "react";
import "./FloatingActionButton.scss";
import FabStars from "../../../../../assets/prompt/media/FAB/fabstars.svg";
import FabShadow from "../../../../../assets/prompt/media/FAB/fabshadow.svg";

/**
 * Interface for FloatingActionButton component props
 */
export interface FloatingActionButtonProps {
  onClick?: () => void;
}

/**
 * FloatingActionButton Component
 *
 * This component represents the Floating Action Button
 * that float towards the bottom right of each KX related page.
 */
export const FloatingActionButton: React.FunctionComponent<
  FloatingActionButtonProps
> = (props) => {
  const { onClick } = props;

  /**
   * Render the FloatingActionButton component.
   *
   * @returns {JSX.Element} - The rendered FloatingActionButton component.
   */
  return (
    <div className="floating-button-container" onClick={onClick}>
      <img className="fab-shadow" src={FabShadow} />
      <button className="floating-button"></button>
      <img className="fab-stars" src={FabStars} />
    </div>
  );
};
