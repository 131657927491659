import { resolve } from "path";
import React, { createContext, useState, useContext } from "react";
import { IAttachmentNotication } from "../../../models/IShared";
import { SharedService } from "../../../services/SharedService";

const AttachmentNotificationContext = createContext({
  attachmentNotification: [],
  setattachmentNotification: (documentItem: IAttachmentNotication[]) => {},
});

function useAttachementNotificationContext() {
  const context = useContext(AttachmentNotificationContext);
  if (!context)
    throw new Error(
      "useAttachementNotificationContext must be used within AttachmentNotificationProvider!"
    );
  return context;
}

function AttachmentNotificationProvider(props: any) {
  let sdService = SharedService.GetInstance();
  let notifications = sdService
    .GetAttachmentNotifications()
    .then((response) => {
      // console.log("Line now 23 from context notifications  : ", response);
      // notifications = response;
      return response;
    });

  const [attachmentNotification, setattachmentNotification] =
    useState(notifications);

  const value = { attachmentNotification, setattachmentNotification };
  return <AttachmentNotificationContext.Provider value={value} {...props} />;
}

export { AttachmentNotificationProvider, useAttachementNotificationContext };
