import { GET_ALL_DATA, AllDataTypeActions, GetAllElementState } from '../types'

const initialState: GetAllElementState = {
    getAllElements:[]
}


export function loadGetAllDataReducer( state = initialState, action: AllDataTypeActions) {
  switch (action.type) {
    case GET_ALL_DATA:
    return {
        ...state,
        tagsElements: action.payload
        }
    default:
      return state
  }
}
