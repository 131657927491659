import { SharedService } from "./SharedService";

export class KXNSTreeBuilder {
    private apiJson: Node[] | null; //this will be sorted by ExternalId after 'toForest' first init
    private forest: Node[] | null;

    constructor() {
        this.apiJson = null;
        this.forest = null;
    }

    public async init(val: number,data:any) {
        let lock = new Promise<Node[] | null>((resolve, reject) => {
            this.apiJson = this.forest = null;
            let _webAPIUrl = SharedService.documentsWebApiUrl;
            let t = data;
           // fetch(`${_webAPIUrl}/api/getTagsData?typeId=${val}`)
               // .then((res: any) => {
               // data.json().then((t: any) => {
                        if (t) { 
                            this.apiJson = t;
                            this.apiJson =
                                this.apiJson &&
                                this.apiJson.sort(function (x,y) {
                                    if (x.name.toLocaleLowerCase() < y.name.toLocaleLowerCase()) {return -1;}
                                    if (x.name.toLocaleLowerCase() > y.name.toLocaleLowerCase()) {return 1;}
                                    return 0;
                                }
                                );
                            this.forest = this.toForest(this.apiJson);
                            resolve(this.forest);
                        } else {
                            reject();
                        }
                    //})
               // })
                // .catch((err) => {
                //     reject(err);
                // })

        });
        return lock;
    }

    public toForest(arr?: any) {
        let map: any = {}, node: any, res: any = [], i: number;
        for (i = 0; i < arr.length; i += 1) {
            map[arr[i].id] = i;
            arr[i].children = [];
        };
        for (i = 0; i < arr.length; i += 1) {
            node = arr[i];
            if (node.parentId > 0) {
                let childNode = { 'value': node.id, 'label': node.name, 'id': node.id, ...node };
                arr[map[node.parentId]].children.push(childNode);
            }
            else {
                node = { 'value': node.id, 'label': node.name, 'id': node.id, ...node }
                res.push(node);
            };
        };
        const result = this.traverseNodes(res);
        return result;
    }

    public deleteNodes(node: any) {
        if (node.children.length > 0) {
            for (var i = 0; i < node.children.length; i++) {
                this.deleteNodes(node.children[i]);
            }
        }
        else {
            delete node.children;
        }
    }

    public traverseNodes(tree: any) {
        for (var i = 0; i < tree.length; i++) {
            this.deleteNodes(tree[i]);
        }
        return tree;
    }
}

export interface Node {
    id: any;
    name: any;
    type?: any;
    externalId: any;
    fullPath: any;
    parentId: any;
    isDeleted?: any;
    createdBy?: any;
    createdDate?: any;
    modifiedBy?: any;
    modifiedDate?: any;
}
