import { CHANGE_INDEX_PERSONAL_COLLECTION, personallCollectionIndexState, PersonalCollectionIndexTypeActions } from '../types'

const initialState: personallCollectionIndexState = {
  index: 0
}


export function personalCollectionIndexReducer( state = initialState, action: PersonalCollectionIndexTypeActions) {
  switch (action.type) {
    case CHANGE_INDEX_PERSONAL_COLLECTION:
    return {
        ...state,
        index: action.payload
        }
    default:
      return state
  }
}
