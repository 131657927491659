
import React, { useEffect , useState} from "react";
import { useParams } from "react-router-dom";
import { SharedService } from "../../../services/SharedService";
import { Constants } from "../../../helpers/Constants";
import { IDocumentDownloadTracker, IPageTracker, IPersonalizationTracker } from "../../../models/IAnalytics";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { useSelector } from "react-redux";
import { CommonFunctions } from "../../../helpers/CommonFunctions";

const AttachmentDownload: React.FC = () => {

    const _sharedService = SharedService.GetInstance();
    const { id }: { id: string} = useParams();
    const { fileId }: { fileId: string} = useParams();
    const [textMessage, setTextMessage] = useState<string>(_sharedService.Data.Labels[Constants.LabelKeys.AttachmentDownloadMessageStart])
    const subTextMessage = _sharedService.Data.Labels[Constants.LabelKeys.AttachmentDownloadSubTextMessage];
    const subTextHyperLink = SharedService.documentsUiUrl + '/documents/view/'+id;
    let labelsKey = SharedService.scopeNotesLabelKey;
    const _userNeeds: any = useSelector((s: any) => s.userNeedsReducer);
    const [userRoleGroups, setUserRoleGroups] = useState<any>(null);
    const [displayData,setDisplayData] = useState<any>(null);

    useEffect(()=>{
     if (displayData && _userNeeds && _userNeeds.userNeeds && _userNeeds.userNeeds.text) {
       AnalyticsService.resetPageContext();
       let pageTracker: IPageTracker = {} as IPageTracker;
       let personalizationTracker: IPersonalizationTracker =
         {} as IPersonalizationTracker;
       pageTracker = {
         pageInfo: {
           breadCrumbs: "",
           contentID: id,
           contentTitle: displayData?.title,
           genericPageName: Constants.PageTitles.DocumentDownload,
           language: _sharedService.lang,
           sourceContentType: "",
           sourceSystem: "",
           docAge: `${displayData?.kxA_ContentDate && displayData?.kxA_ContentDate !== "" ? CommonFunctions.monthDiff((new Date(displayData.kxA_ContentDate)), new Date()) : ""}`,
           isPublisher:
             userRoleGroups !== null
               ? userRoleGroups.publishers ||
                 userRoleGroups.productAdministrators ||
                 userRoleGroups.publishingTeamLeaders ||
                 userRoleGroups.collectionCurators ||
                 userRoleGroups.knowledgeServicesProfessionals ||
                 userRoleGroups.publishersDeliveryExcellence
               : false,
           primaryCategory: Constants.PageTitles.KXDocuments,
           pageName: `KX|Document Download| ${id}`,
         },
       };

       personalizationTracker = {
         need: _userNeeds.userNeeds.text,
         hasInterests: false,
       };
       console.log(
         "pageTracker,personalizationTracker",
         pageTracker,
         personalizationTracker
       );
       AnalyticsService.trackPageLoad(pageTracker, personalizationTracker);
     }
},[_userNeeds,displayData])

    useEffect(()=>{

      let temproleGroups:any;
      _sharedService.GetUserRoleGroupsAndPrivileges().then((response: any) => {
        setUserRoleGroups(response?.roleGroups);
        temproleGroups = response?.roleGroups;
      });

      getDisplayData();
      let fileName:any ='';
      let downloadTracker: IDocumentDownloadTracker = {} as IDocumentDownloadTracker;
        _sharedService.GetSingleFileDownloadLink(id,"",fileId)
        .then((response) => { 
        const contentDisposition = response?.headers?.get("content-disposition");
        console.log("response?.headers",response?.headers);
        const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
        fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
        if(fileName){
        //let fileFullName = fileName && fileName.length > 1 ?fileName.split("_")[1].join():undefined;
            downloadTracker.type = Constants.DocumentType.SingleDownload;
            downloadTracker.downloadURLs = [fileName];
            AnalyticsService.trackDocumentDownload(downloadTracker);
        }
        
        return response.blob()})
        .then((blob) => {
          // setTextMessage(" KXD_AttachmentDownloadMessageProcess") 
          setTextMessage(_sharedService.Data.Labels[Constants.LabelKeys.AttachmentDownloadMessageProcess])   
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            fileName,
          );
          document.body.appendChild(link);
          link.click();
          if (link.parentNode)
            link.parentNode.removeChild(link);
        //   setFileDownloadToast(false);
          // setTextMessage("Your Download has completed.")
          setTextMessage(_sharedService.Data.Labels[Constants.LabelKeys.AttachmentDownloadMessageComplete]);
        }).catch((err)=>{
          // setTextMessage("Attachment not found, redirecting to display page...")
          setTextMessage(_sharedService.Data.Labels[Constants.LabelKeys.AttachmentDownloadErrorRedirectMessage]);
          setTimeout(() => {
            window.location.href = SharedService.documentsUiUrl + '/documents/view/'+id;
          }, 5000);
        })
       
    },[])

    const getDisplayData = async() =>{
      const totalData = await _sharedService.getPublishedKXDocument(id).then((result) => {
        setDisplayData(result);
      })
    }


    return (
      <>
        <div style={{padding: "120px",textAlign: "center"}}>
          <span style={{fontSize: "xx-large",fontWeight: "bold"}}>{textMessage}</span>
          {subTextMessage !== null && subTextMessage !== "" 
          ?<p style={{fontSize: "25px"}}>{subTextMessage} <a href={subTextHyperLink}> here.</a></p>
          :<></>
          }
        </div>
      </>
    );
};

export default AttachmentDownload;