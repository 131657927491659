import { SET_SEARCHBOX_DATA, SearchBoxDataActionTypes } from "../types";
import { ISearchBoxTracker } from "../../models/IAnalytics";

// TypeScript infers that this function is returning SendMessageAction
export function setSearchBoxData(SearchBoxData: ISearchBoxTracker[]): SearchBoxDataActionTypes {
  return {
    type: SET_SEARCHBOX_DATA,
    payload: SearchBoxData
  }
}


