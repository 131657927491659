import React, { lazy, Suspense } from 'react';

const LazyKnowledgeItemHeader = lazy(() => import('./knowledgeItemHeader'));
const KnowledgeItemHeader = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyKnowledgeItemHeader {...props} />
  </Suspense>
);

export default KnowledgeItemHeader;
