import React, { useCallback, useState,useEffect} from "react";
import { FileError, FileRejection, useDropzone } from "react-dropzone";
import SingleFileRecord from "./singleFileRecord";
import "./multipleFileUpload.scss";
import { SharedService } from "../../../services/SharedService";
import { Constants } from '../../../helpers/Constants';


export interface UploadableFile {
  file: File;
  errors: FileError[];
}

export interface MultipleFileUploadProps {
  onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
  page?:string
}

function MultipleFileUpload({ onDrop,page }: MultipleFileUploadProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // maxSize: 5 * 1024 * 1024,
    // maxSize: 500 * 1024 * 1024,
  });

  // function onDelete(file: File) {
  //   setFiles((curr) => curr.filter((fw) => fw.file !== file));
  // }

  let labelsKey = SharedService.scopeNotesLabelKey;
  var labelsData: string | null = localStorage.getItem(labelsKey);
  var labels = labelsData != null ? JSON.parse(labelsData) : {};

  const sharedServiceLabels = SharedService.GetInstance().Data.Labels;
  const [newContributionAttachmentInstructions, setNewContributionAttachmentInstructions] = useState<any>();
  const [editFormAttachmentInstructions, setEditFormAttachmentInstructions] = useState<any>();

  useEffect(() => {
    setNewContributionAttachmentInstructions(sharedServiceLabels[Constants.AttachmentInstructionsLabelKeys.NewContributionInstructions]);
    setEditFormAttachmentInstructions(sharedServiceLabels[Constants.AttachmentInstructionsLabelKeys.EditPageInstructions]);
  },[]);

  return (
    <div
      {...getRootProps()}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div id="Attachments" className="uploadlabel" style={page == "Bulkupdate" ? {height:60}:page != 'Edit' ? {height:180}:{height:50}}>
        <div className="uploadicon" style={page == "Bulkupdate" ? {fontSize:50} :page != 'Edit' ? {fontSize:100}:{fontSize:22}}  {...getRootProps()}>
          <div className="fileupload">
            <input {...getInputProps()} />
            <div className="icon-kx-plus"></div>
          </div>
        </div>
        <div className={page == "Bulkupdate" ? "bulkupdateTexts":"uploadtexts"}>
          <p>
            <b>
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : ''}
            </b>
          </p>
          {(page == "Bulkupdate") ? (<><p 
                            style={{fontSize:16}}>{"Drag and drop your completed bulk update template here"}</p></>) :(page != 'Edit')? (<><p
                              dangerouslySetInnerHTML={{
                                __html:
                                  newContributionAttachmentInstructions,
                              }}  
                            ></p></>):(
//                               <div>Drag and drop some files here, or click to select files.
// If you are adding new attachments and want to send them for auto cleansing, please save and reopen the form</div>
                              <><p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    editFormAttachmentInstructions,
                                }}></p></>
                              )
                              }
          {/* { page != 'Edit' &&
            <>
              <ul>
            <li>Files must be 500MB or less</li>
            
            <li>Special characters are allowed</li>
          </ul>
          <p>
            Please note: The document(s) you are submitting may be cleansed and
            published outside of your member firm. By submitting document(s),
            you are confirming that you have received all necessary approvals,
            if required by your business or member firm, to contribute to a
            globally managed and accessible repository.
          </p>
          <p className="link">
            For more information on the cleansing process, please see the{" "}
            <a href="https://www.km.deloitteresources.com/sites/live/consulting/_layouts/DTTS.DR.KAMDocumentForms/KAMDisplay.aspx?List=513ae4d5-443f-4bc1-9f25-8f68dc5aa0c0&ID=16544" target="_blank"> KX Cleansing Guidelines.</a>
          </p>
            </>
          } */}
        </div>
      </div>
      {console.log("Multiplefile upload invoked")}
      {/* {files.map((fileWrapper) => (
        <SingleFileRecord file={fileWrapper.file} onDelete={onDelete} />
      ))} */}
    </div>
  );
}

export default React.memo(MultipleFileUpload);
