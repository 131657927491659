import { ITagsData } from "../../models/IShared";
import {  GET_ALL_DATA, loadTagDataTypeActions } from "../types";

// TypeScript infers that this function is returning SendMessageAction

export function setGetAllDataAction(AllDataState:ITagsData[]):  loadTagDataTypeActions{
  return {
    type: GET_ALL_DATA,
    payload: AllDataState
  }
}


