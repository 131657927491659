import { SHOW_RECOMENDED_FOR_YOU, showRecomendedForYouState, showRecomendedForYouTypeAction } from '../types'

const initialState: showRecomendedForYouState = {
  show: true
}


export function showRecomendedForYouReducer( state = initialState, action: showRecomendedForYouTypeAction) {
  switch (action.type) {
    case SHOW_RECOMENDED_FOR_YOU:
    return {
        ...state,
        show: action.payload
        }
    default:
      return state
  }
}
