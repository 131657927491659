import React, { useState, useRef, useEffect } from "react";
import { IConversationHistoryResponseObj } from "../../../../../models/KxPromptModels";
import { TooltipBase } from "../../../../shared/tooltipBase/tooltipBase";

interface ISidebarHistoryOptionProps {
  conversationItem: IConversationHistoryResponseObj;
  sidebarHistoryItemActiveKey: string | undefined;
  handleHistoryItemSelect: (
    historyItem: IConversationHistoryResponseObj
  ) => void;
  loadingResponse: boolean;
}

const SidebarHistoryOption = ({
  conversationItem,
  sidebarHistoryItemActiveKey,
  handleHistoryItemSelect,
  loadingResponse,
}: ISidebarHistoryOptionProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  const checkForTruncation = () => {
    const element = textRef.current;
    // Check if the text width is greater than its parent's width, indicating truncation
    if (element && element.offsetWidth < element.scrollWidth) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    // Perform the check on mount and whenever conversationItem changes
    checkForTruncation();
    // Optionally, you can add event listeners for window resize if you expect dynamic resizing
  }, [conversationItem]);

  return (
    <div
      className="history-option"
      onClick={() => handleHistoryItemSelect(conversationItem)}
      digitaldata-prompt="Left rail>History"
    >
      {showTooltip ? (
        <TooltipBase
          content={conversationItem?.title}
          wrapperClassName="prompt_tooltip"
        >
          <span
            ref={textRef}
            className={`option_text ${
              conversationItem.sessionid === sidebarHistoryItemActiveKey
                ? loadingResponse
                  ? "active-key disabled"
                  : "active-key"
                : loadingResponse
                ? "disabled"
                : ""
            }`}
          >
            {conversationItem?.title}
          </span>
        </TooltipBase>
      ) : (
        <span
          ref={textRef}
          className={`option_text ${
            conversationItem.sessionid === sidebarHistoryItemActiveKey
              ? loadingResponse
                ? "active-key disabled"
                : "active-key"
              : loadingResponse
              ? "disabled"
              : ""
          }`}
        >
          {conversationItem?.title}
        </span>
      )}
    </div>
  );
};

export default SidebarHistoryOption;
