import React, { useRef } from "react";
import { ActionButton, CommandBarButton, IconButton } from "office-ui-fabric-react/lib/Button";
import {
  ContextualMenu,
  IContextualMenuProps,
  Link,
  TooltipHost,
} from "office-ui-fabric-react";
import {
  IOverflowSetItemProps,
  OverflowSet,
} from "office-ui-fabric-react/lib/OverflowSet";
import { ModalBase } from "../modalBase/modalBase";
import "./overflowSetBase.scss";
import { SharedService } from "../../../services/SharedService";
import { Follower, CardData } from "../../../models/SharedModels";
import { ModalGeneric } from "../modalBase/modalGeneric";
import { LastViewedActions } from "../../../store/actions/LastViewedActions";
import { useDispatch, useSelector } from "react-redux";
import { ADD_DATA_TO_LAST_VIEWED } from "../../../store/types";
import { ILastViewedCard } from "../lastViewed/lastviewed";
import { Constants } from "../../../helpers/Constants";
import useProtectedActionButtons from "../customHooks/useProtectedActionButtons";
import { CommonFunctions } from "../../../helpers/CommonFunctions";

export interface OverflowSetBaseProps {
  items?: any;
  overflowItems?: any[];
  onShow?: any;
  onHide?: any;
  cardData: CardData | null;
  type: any;
  openModalEdit?: any;
  ifPin?: any;
  explainData?: any;
  toggle?: boolean;
  more?: boolean;
}

export const OverflowSetBase: React.FunctionComponent<OverflowSetBaseProps> = (
  props
) => {
  const ref = useRef<any>(null);
  let _sharedService: SharedService = SharedService.GetInstance();
  const dispatch = useDispatch();
  const lastViewedState = useSelector(
    (state: any) => state.lastViewedReducer.cards
  );

  const saveLastViewed = () => {
    let _configurations = SharedService.GetInstance().Data.Configuration;
    let ctCorelationJson = SharedService.GetInstance().GetConfigurationMapping(
      props.cardData ? props.cardData.allData : null);
    let configCT = JSON.parse(_configurations.ContentTypesMapping);

    let ctMapping = configCT[ctCorelationJson.JsonMapping]

    var title =
      ctMapping.metadataCards["line1"];
    var desc =
      ctMapping.metadataCards["line3"];

    let _obj: ILastViewedCard = {
      key: lastViewedState.length,
      imageUrl: props.cardData ? props.cardData.image : "",
      text: props.cardData ? props.cardData.allData[title] : "",
      footerText: [props.cardData ? props.cardData.allData.globalcontenttype : ""],
      showSecondaryText: true,
      id: props.cardData ? props.cardData.allData.id : -1,
    };

    let tempArr: any = [];
    tempArr = tempArr.concat(lastViewedState);

    let isExist: boolean = false;

    for (let i: number = 0; i < tempArr.length; i++) {
      if (tempArr[i].id == _obj.id) {
        tempArr.splice(0, 0, tempArr[i]);
        tempArr.splice(i + 1, 1);
        isExist = true;
        break;
      }
    }

    if (!isExist) {
      tempArr.unshift(_obj);
      if (tempArr.length > 10)
        tempArr.splice(tempArr.length - 1, 1)
      //tempArr.push(_obj);
    }
    dispatch(LastViewedActions(ADD_DATA_TO_LAST_VIEWED, tempArr));
  };

  const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    return (
      <>
        {props.cardData ? (
          <div>
            <ModalBase
              ref={ref}
              onHideModal={saveLastViewed}
              title=""
              opener=""
            >
              <ModalGeneric ifPin={props.ifPin} openModalEdit={props.openModalEdit} className="quickView" cardData={{ ...props.cardData, isQuickView: true }} explainData={props.explainData} />
            </ModalBase>
            <Link
              className="profilequickview"
              role="menuitem"
              onClick={(e) => {
                if (e) e.stopPropagation();
                ref.current.showModal();
                if (props.cardData)
                  SharedService.GetInstance().Actions.Post.PostLastView(
                    new Follower(
                      props.cardData.allData.source != Constants.Images ?
                        props.cardData.sourceId :
                        props.cardData.allData.img_Id,
                      props.cardData.allData.source != Constants.Images ?
                        props.cardData.allData.internalcontenttype : Constants.Images
                    )
                  );
                CommonFunctions.doTrackingMiniCard(Constants.MiniCardActions.QuickView, props.cardData);
              }}
            >
              {item.iconProps &&
                <>
                  <i className={item.iconProps.className}></i>
                </>}
              {SharedService.GetInstance().Data.Labels[item.key]}
            </Link>
          </div>
        ) : null}
      </>
    );
  };

  const onRenderOverflowButton = (
    overflowItems: any[] | undefined
  ): JSX.Element => {
    overflowItems = props.cardData && props.cardData.isHorizontal && props.cardData.overflowItemsData[0] ?
      props.cardData.overflowItemsData[0].overflowItems.filter(
        (action: any) => {
          return (
            action.key !== Constants.ActionsButtonsKeys.PinToCollection &&
            action.key !== Constants.ActionsButtonsKeys.DownloadAll
          );
        }
      )
      :
      overflowItems;
    return (
      <TooltipHost content={_sharedService.Data.Labels[Constants.LabelKeys.MoreOptionsLabel]}>
        {
          window.location.href.indexOf(Constants.Routing.PersonalCollection) !== -1 && overflowItems && overflowItems.filter((p) => p.name === 'Edit name').length > 0 ?
            (<IconButton
              className="categoryOverflow"
              role=""
              menuIconProps={{ iconName: "" }}
              menuProps={{ items: overflowItems! }}
              onMenuClick={(e) => {
                if (e) e.stopPropagation();
                props.onShow(e);
              }}
              text={props.more ? " More" : ""}
              onAfterMenuDismiss={props.onHide}
              menuAs={(menuProps: IContextualMenuProps) => (
                <ContextualMenuProtected
                  defaultProps={menuProps}
                  cardData={props.cardData!}
                />
              )}
            />) :
            <>
              <CommandBarButton
                className="morelistoptions"
                role="menuitem"
                menuProps={{ items: overflowItems! }}
                onMenuClick={(e) => {
                  if (e) e.stopPropagation();
                  props.onShow(e);
                }}
                text={props.more ? " More" : ""}
                onAfterMenuDismiss={props.onHide}
                menuAs={(menuProps: IContextualMenuProps) => (
                  <ContextualMenuProtected
                    defaultProps={menuProps}
                    cardData={props.cardData!}
                  />
                )}
                menuIconProps={{ iconName: "More" }}
              />
            </>
        }

      </TooltipHost>
    )

  };

  return (
    <OverflowSet
      aria-label={
        SharedService.GetInstance().Data.Labels[
        Constants.LabelKeys.ContextualMenuLabel
        ]
      }
      role="menubar"
      items={props.items}
      overflowItems={props.overflowItems}
      onRenderOverflowButton={onRenderOverflowButton}
      onRenderItem={onRenderItem}
      doNotContainWithinFocusZone={true}
    />
  );
};

export const ContextualMenuProtected: React.FunctionComponent<{
  defaultProps: IContextualMenuProps;
  cardData: CardData;
}> = (props) => {
  const protectedOverflowButtons = useProtectedActionButtons(
    props.cardData && props.cardData.listTitle ? props.cardData.listTitle : "",
    props.defaultProps.items.filter(
      (action: any) => {
        return (
          action.key !== Constants.ActionsButtonsKeys.DownloadAll
        );
      }
    ) as any
  );

  if (protectedOverflowButtons && protectedOverflowButtons.length > 0) {
    return (
      <ContextualMenu
        {...props.defaultProps}
        items={protectedOverflowButtons}
      />
    );
  }
  return <></>;
};
