import React, { useEffect, useState } from "react";
import { Spinner, IStackTokens } from "@fluentui/react";
import styles from "../newContribution.module.scss";
import { TextField } from "@fluentui/react";
import { DateRangeType, Label } from "office-ui-fabric-react";
import { ITag } from "@fluentui/react/lib/Pickers";
import { PeoplePickerBase } from "../../../shared/peoplePickerBase/peoplePickerbase";
import { useBoolean } from "@fluentui/react-hooks";
import { CheckBoxTree } from "../../../shared/checkboxTree/checkboxTree";
// import { DatePickerBase } from "../../../shared/datePickerBase/datePickerBase";
import moment from "moment";
import "moment-timezone";
import { SharedService } from "../../../../services/SharedService";
import { Constants } from "../../../../helpers/Constants";
import { ThemeProvider } from "office-ui-fabric-react/lib/Foundation";
import { TaxonomyTable } from "./TaxonomyTable";
import { CalloutBase } from "../../../shared/calloutBase/calloutBase";
import { useParams } from "react-router-dom";
import { NewDatePickerBase } from "../../../shared/newDatepickerBase/newDatePickerBase";
import { PopupDialog } from "../../../shared/popupDialog/popupDialog";
// import { ClientServiceActions } from "../../../../store/actions/clientServiceActions";
// import { useDispatch, useSelector } from "react-redux";
// import { ADD_DATA_TO_CLIENT_SERVICES } from "../../../../store/types"

const AdditionalNCForm: React.FC<{
  getAdditionalForm: (value?: any) => void;
  AdditionalFormData: {
    contentdate: Date | undefined;
    contentType: any;
    contentTypeId: any;
    authorandContactsforDocuments: any[] | null;
    publishInstructions: string | undefined;
    countryofOriginId: any;
    primaryClientServiceId: number | null;
    primaryLocalClientServiceId: number | null;
    primaryIndustryId: number | null;
    secondaryClientServiceId: any[] | null;
    secondaryLocalClientServiceId: any[] | null;
    secondaryIndustryId: any[] | null;
    secondaryCountryId: any[] | null;
    clientId: number | string | null;
    clientName: string | null;
    newClient: any;
    qualId: number | null;
    qualName: string | null;
  };
  draftData: any;
  setAuthorInvalidText: (value: string) => void;
  setContactsInvalidText: (value: string) => void;
}> = (props) => {
  const { draftid }: { draftid: any } = useParams();
  const containerStackTokens: IStackTokens = { childrenGap: 10 };
  const [hideAdditionalInfo, { toggle: toggleHideAdditionalInfo }] = useBoolean(true);
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [showFromDateCalendar, setShowFromDateCalendar] = useState<boolean>(false);
  const [showCalender, setShowCalender] = useState(false);
  const [contentModalView, setContentModalView] = useState(false);
  const [selectedClient, setSelectedClient] = useState<ITag[] | undefined>([]);
  const [checkedTreeValues, setCheckedTreeValues] = useState<string[]>([]);
  const [selectedContent, setSelectedContent] = useState<any[]>([]);
  const [savedContentTreeValues, setSavedContentTreeValues] = useState<string[]>([]);
  const [contenttypelist, setContentTypeList] = useState<any[]>([]);
  //const [countryModalView, setCountryModalView] = useState<boolean>(false);
  const [businessUserDefaultData, setBusinessUserDefaultData] = useState<any[]>([]);
  const [industryUserDefaultData, setIndustryUserDefaultData] = useState<any[]>([]);
  const [industryUserDefaultDataDraft, setIndustryUserDefaultDataDraft] = useState<any[]>([]);
  const [businessUserDefaultDataDraft, setBusinessUserDefaultDataDraft] = useState<any[]>([]);
  const [countryUserDefaultData, setCountryUserDefaultData] = useState<any[]>([]);
  const [selectedAuthor, setSelectedAuthor] = useState<any[]>([]);
  const [selectedkeyContacts, setSelectedKeyContacts] = useState<any[]>([]);
  const [selectedContributors, setSelectedContributors] = useState<any[]>([]);
  // const [checkedCountryValues, setCheckedCountryValues] = useState<string[]>([]);
  const [selctedCountryValue, setSelectedCountryValue] = useState<any[]>([]);
  const [checkedbusinesstreevalue, setCheckedBusinessTreeValue] = useState<any[]>([]);
  const [checkedBusinessValues, setcheckedBusinessValues] = useState<any[]>([]);
  const [businessList, setBusinessList] = useState<any[]>([]);
  const [checkedbusinesslocaltreevalue, setCheckedBusinessLocalTreeValue] = useState<any[]>([]);
  const [checkedBusinessLocalValues, setcheckedBusinessLocalValues] = useState<any[]>([]);
  const [businessLocalList, setBusinessLocalCAList] = useState<any[]>([]);
  const [checkedindustrytreevalue, setCheckedIndustryTreeValue] = useState<any[]>([]);
  const [checkedIndustryValues, setcheckedIndustryValues] = useState<any[]>([]);
  const [industryList, setIndustryList] = useState<any[]>([]);
  const [checkedCountrytreevalue, setCheckedCountryTreeValue] = useState<any[]>([]);
  const [checkedCountryValues, setcheckedCountryValues] = useState<any[]>([]);
  const [countrylist, setCountryList] = useState<[]>([]);
  const [businessModalView, setBusinessModalView] = useState<boolean>(false);
  const [checkedBusinessRadioValue, setCheckedBusinessRadioValue] = useState<any>();
  const [businessLocalModalView, setBusinessLocalModalView] = useState<boolean>(false);
  const [checkedBusinessLocalRadioValue, setCheckedBusinessLocalRadioValue] = useState<any>();
  const [IndustryModalView, setIndustryModalView] = useState<boolean>(false);
  const [checkedIndustryRadioValue, setCheckedIndustryRadioValue] = useState<any>();
  const [checkedCountryRadioValue, setCheckedCountryRadioValue] = useState<any>();
  const [CountryModalView, setCountryModalView] = useState<boolean>(false);
  const [curruserMemberFirmID, setcurruserMemberFirmID] = useState<number>();
  const [memberFirmID, setMemberFirmID] = useState<number>();
  const [businessLocalTableId, setBusinessLocalTableId] = useState<number | null>(null);
  const [businessLocalMFId, setBusinessLocalMFId] = useState<number | null>(null);
  const [hideBusinessLocal, setHideBusinessLocal] = useState<boolean>(false);
  const [publishingInstructiontext, setPublishingInstructiontext] = useState<string>();
  const [selectedQualValue, setSelectedQualValue] = React.useState<any[]>([]);
  const [relatedQualData, setRelatedQualData] = useState([]);
  const [selectedQual, setSelectedQual] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const _sharedService = SharedService.GetInstance();
  const userdetails = _sharedService.Data.CurrentUser;
  const [defaultAuthor, setDefaultAuthor] = useState<any[]>([]);
  const [defaultContact, setDefaultContact] = useState<any[]>([]);
  const [defaultContributor, setDefaultContributor] = useState<any[]>([]);
  const [reopenFlag, setReopenFlag] = useState<boolean>(false);
  const [addtionalNCExpandCount, setAddtionalNCExpandCount] = useState<number>(0)
  const [authorFullData, setAuthorFullData] = useState<any[]>([]);
  const [contactFullData, setContactFullData] = useState<any[]>([]);
  const [contributorFullData, setContributorFullData] = useState<any[]>([]);
  const [autoPoulate, setautoPoulate] = useState<boolean>(false);
  const [tryClient, setTryClient] = useState<string>();
  const [showBusinessuser, setShowBusinessUser] = useState<boolean>(true);
  const [showIndustryuser, setShowIndustryUser] = useState<boolean>(true);
  const [showCountryuser, setShowCountryUser] = useState<boolean>(true);
  const [newClientView, setNewClientView] = useState<boolean>(false);
  const [hideDialogCountry, { toggle: toggleHideDialogCountry }] =
    useBoolean(true);

  let labelsKey = SharedService.scopeNotesLabelKey;
  var labelsData: string | null = localStorage.getItem(labelsKey);
  var labels = labelsData != null ? JSON.parse(labelsData) : {};
  let businessObjlist: any[] = [];
  let businessLocalObjlist: any[] = [];
  let industryObjlist: any[] = [];
  let countryObjlist: any[] = [];


  useEffect(() => {
    if (contenttypelist && contenttypelist.length > 0) {
      setPublishingInstructiontext(props.AdditionalFormData.publishInstructions ? props.AdditionalFormData.publishInstructions : "");
      let data = contenttypelist.find((m: any) => m.Id === props.AdditionalFormData.contentTypeId);
      if (data?.FullPath) {
        let dataObj = [{ name: data.FullPath, value: data.FullPath }]
        setSelectedContent(dataObj);
        if (data.Name.includes("->")) {
          setCheckedTreeValues([data.Name.split("->")[1]]);
        } else {
          setCheckedTreeValues([data.Name]);
        }
      } else {
        setSelectedContent([]);
        setCheckedTreeValues([]);
      }
    }
  }, [props.AdditionalFormData, contenttypelist]);

  useEffect(() => {
    if (props.draftData != null || undefined) {
      if (props.draftData.contentType != null) {
        let dataObj = [{ name: props.draftData.contentType.fullPath, value: props.draftData.contentType.fullPath }]
        setSelectedContent(dataObj);
        if (props.draftData.contentType.name.includes("->")) {
          setCheckedTreeValues([props.draftData.contentType.name.split("->")[1]]);
        } else {
          setCheckedTreeValues([props.draftData.contentType.name]);
        }
      }
    }
  }, [props.draftData])

  useEffect(() => {
    // let countryOfOriginTableId = Constants.TableIdsForTree.CountryOfOrigin.tableId;
    // let contentTypeTableId = Constants.TableIdsForTree.ContentType.tableId;
    // let memeberFirmId = Constants.TableIdsForTree.ContentType.memberFirmId;
    // let businessGlobalTableId = Constants.TableIdsForTree.BusinessGlobal.tableId;
    // let IndustryTableId = Constants.TableIdsForTree.Industry.tableId;
    let industryListinitial: any[] = [];
    let businessListinitial: any[] = [];
    let allData: any = localStorage.getItem("__dkm__GetAllData");
    let AllSynapticaTagsData = allData != null ? JSON.parse(allData).data : "";
    if (AllSynapticaTagsData !== null) {
      setContentTypeList(AllSynapticaTagsData.ContentTypeList);
      setBusinessList(AllSynapticaTagsData.buisinessGlobalList);
      setIndustryList(AllSynapticaTagsData.IndustryList);
    }
    let result = AllSynapticaTagsData !== null ? AllSynapticaTagsData?.CountryList : null;
    if (result) {
      setCountryList(result);
    }
    const currentUser = _sharedService.GetCurrentUserData().then((result) => {
      let data = result && result.userData ? result.userData[0]?._source : null;
      return data;
    });

    if (currentUser) {
      currentUser.then((data) => {
        //Get default country data
        if (props.draftData === null) {
          if (result) {
            let defaultCountryValues: any[] = [];
            let currentCountry = data && data.geography && data.geography.length > 0 ? data.geography[0] : "";
            if (currentCountry !== "") {
              if (result && result.length > 0) {
                let countryObj: any = result.filter(
                  (country: any) => {
                    return country.ExternalId == currentCountry.id;
                  }
                );
                if (countryObj && countryObj?.length > 0) {
                  var datacountryobj = {
                    Name: countryObj[0].Name,
                    Id: countryObj[0].Id,
                  };
                  defaultCountryValues.push(datacountryobj);
                }
              }
            }
            if (draftid == undefined && defaultCountryValues?.length > 0) {
              setCountryUserDefaultData(defaultCountryValues);
            }
            else if (defaultCountryValues?.length > 0) {
              setCountryUserDefaultData([...defaultCountryValues]);
            }
          }
        }
        //Get Business User Default Data
        let defaultBusinessValues: any[] = [];
        let memberfirm = data && data.geography && data.geography.length > 0 ? data.geography[0]?.id : 0;
        // setMemberFirmID(memberfirm);
        // setcurruserMemberFirmID(memberfirm); //memberfirm
        let userclientService: any;
        let userclientserviceArr: any[] = [];
        if (data?.kxp_pdifunctionsglobaltext && data?.kxp_pdifunctionsglobaltext != '') {
          userclientService = data?.clientservice.find((cs: any) => cs.fullpath === data?.kxp_pdifunctionsglobaltext)
          if (userclientService) {
            userclientserviceArr.push(userclientService);
          }
        }

        userclientserviceArr?.map((item: { name: string; id: string }) => {
          if (AllSynapticaTagsData?.buisinessGlobalList?.length > 0) {
            let businessObj = AllSynapticaTagsData?.buisinessGlobalList?.filter((business: any) => { return business.ExternalId == parseInt(item.id) })
            if (businessObj && businessObj.length > 0) {
              var data = {
                Name: businessObj[0].FullPath,
                Id: businessObj[0].Id
              };
              defaultBusinessValues.push(data);
            }
          }
        });
        if (draftid == undefined && defaultBusinessValues?.length > 0) {
          setBusinessUserDefaultData(defaultBusinessValues);
        }
        else if (draftid != undefined && defaultBusinessValues?.length > 0) {
          setBusinessUserDefaultDataDraft([...defaultBusinessValues]);
        }
        //Get Industry User Default Data
        let defaultIndustryValues: any[] = [];
        let userprimaryindustry: any;
        let usersecondaryindustry: any;
        let userindustryArr: any[] = [];
        if (data?.kxp_pdiprimaryindustryglobaltext && data?.kxp_pdiprimaryindustryglobaltext != '') {
          userprimaryindustry = data?.industry.find((cs: any) => cs.fullpath === data?.kxp_pdiprimaryindustryglobaltext)
          if (userprimaryindustry) {
            userindustryArr.push(userprimaryindustry);
          }
        }
        if (data?.kxp_pdisecondaryindustryglobaltext && data?.kxp_pdisecondaryindustryglobaltext != '') {
          usersecondaryindustry = data?.industry.find((cs: any) => cs.fullpath === data?.kxp_pdisecondaryindustryglobaltext)
          if (usersecondaryindustry) {
            userindustryArr.push(usersecondaryindustry);
          }
        }
        userindustryArr?.map((item: { name: string; id: string }) => {
          if (AllSynapticaTagsData?.IndustryList?.length > 0) {
            let industryObj = AllSynapticaTagsData?.IndustryList?.filter((industry: any) => { return industry.ExternalId == parseInt(item.id) })
            if (industryObj && industryObj?.length > 0) {
              var data = {
                Name: industryObj[0].FullPath,
                Id: industryObj[0].Id,
              };
              defaultIndustryValues.push(data);
            }
          }
        });
        if (draftid == undefined && defaultIndustryValues?.length > 0) {
          setIndustryUserDefaultData(defaultIndustryValues);
        } else if (draftid != undefined && defaultIndustryValues?.length > 0) {
          setIndustryUserDefaultDataDraft([...defaultIndustryValues]);
        }

      });
    }
  }, []);
  //console.log("countryUserDefaultData", countryUserDefaultData)

  useEffect(() => {
    if (tryClient) {
      let unsavedclient: any = [{
        key: "",
        name: tryClient
      }]
      props.AdditionalFormData.newClient = unsavedclient;
    }
  }, [tryClient])

  //Load Local Taxonomy
  const GetLocalMemberFirmTree = (mf: number | undefined) => {
    setHideBusinessLocal(false);
    setBusinessLocalTableId(Constants.TableIdsForTree.BusinessLocalCA.tableId);
    let allData: any = localStorage.getItem("__dkm__GetAllData");
    let AllSynapticaTagsData = allData != null ? JSON.parse(allData).data : "";
    if (mf == 2348) {
      setBusinessLocalMFId(Constants.TableIdsForTree.BusinessLocalCA.memberFirmId);
      setBusinessLocalCAList(AllSynapticaTagsData.MemberFirmIdBLCA);
    } else if (mf == 2494) {
      setBusinessLocalMFId(Constants.TableIdsForTree.BusinessLocalUK.memberFirmId);
      setBusinessLocalCAList(AllSynapticaTagsData.MemberFirmIdBLUK);
    } else if (mf == 2768) {
      setBusinessLocalMFId(Constants.TableIdsForTree.BusinessLocalUS.memberFirmId);
      setBusinessLocalCAList(AllSynapticaTagsData.MemberFirmIdBLUS);
    } else if (mf == 2470) {
      setBusinessLocalMFId(Constants.TableIdsForTree.BusinessLocalDE.memberFirmId);
      setBusinessLocalCAList(AllSynapticaTagsData.MemberFirmIdBLDE);
    } else if (mf == 2243) {
      setBusinessLocalMFId(Constants.TableIdsForTree.BusinessLocalAU.memberFirmId);
      setBusinessLocalCAList(AllSynapticaTagsData.MemberFirmIdBLAU);
    } else {
      setHideBusinessLocal(true);
    }
  };

  useEffect(() => {
    GetLocalMemberFirmTree(curruserMemberFirmID);
  }, [curruserMemberFirmID]);

  const setNullBusinessIndustry = () => {
    props.AdditionalFormData.primaryIndustryId = null;
    props.AdditionalFormData.primaryLocalClientServiceId = null;
    props.AdditionalFormData.primaryClientServiceId = null;
    props.AdditionalFormData.secondaryIndustryId = null;
    props.AdditionalFormData.secondaryLocalClientServiceId = null;
    props.AdditionalFormData.secondaryClientServiceId = null;
    setCheckedIndustryTreeValue([]);
    saveIndustryID([])
    //setCheckedIndustryRadioValue({Id:null,Name:null})
    setCheckedBusinessTreeValue([]);
    saveBusinessID([])
    //setCheckedBusinessRadioValue({Id:null,Name:null})
    setCheckedBusinessLocalTreeValue([]);
    saveBusinessLocalID([])
    //setCheckedBusinessLocalRadioValue({Id:null,Name:null})
  }

  //default value for global business according to user
  const setUserBusinessGlobalDefaultData = () => {
    let presentBusiness: any[] = [];
    if (businessList && businessList.length > 0) {
      presentBusiness = businessUserDefaultData.filter((o) =>
        businessList.some(({ Id, Name }) => o.Id === Id)
      );
    }

    let defaultlist: any[] = presentBusiness.map((item) => {
      return item.Id;
    });
    setCheckedBusinessTreeValue(defaultlist);
    saveBusinessID(defaultlist);
  };

  const setUserBusinessGlobalDefaultDataDraft = () => {
    let presentBusiness: any[] = [];
    if (businessList && businessList.length > 0) {
      presentBusiness = businessUserDefaultDataDraft.filter((o) =>
        businessList.some(({ Id, Name }) => o.Id === Id)
      );
    }

    let defaultlist: any[] = presentBusiness.map((item) => {
      return item.Id;
    });
    setCheckedBusinessTreeValue(defaultlist);
    saveBusinessID(defaultlist);
  };

  const setUserBusinessLocalDefaultData = (data: any) => {
    setHideBusinessLocal(false);
    let presentBusinessLocal: any[] = [];
    if (businessLocalList.length > 0) {
      presentBusinessLocal = data?.filter((o: any) =>
        businessLocalList.some(({ Id, Name }) => o.Id === Id)
      );
    }

    let defaultlist: any[] = presentBusinessLocal.map((item) => {
      return item.Id;
    });
    setCheckedBusinessLocalTreeValue(defaultlist);
    saveBusinessLocalID(defaultlist);
  };

  const saveIndustryID = (checkedlist: any[]) => {
    checkedlist.map((item) => {
      var checkeditems = industryList?.filter(
        (e: { Name?: string; Id?: number }) => {
          if (e.Id == item) {
            return e;
          }
        }
      );
      if (checkeditems && checkeditems.length > 0) {
        let data = {
          Name: checkeditems[0].FullPath,
          Id: checkeditems[0].Id,
        };
        industryObjlist.push(data);
      }
    });
    if (industryObjlist && industryObjlist.length > 0) {
      setCheckedIndustryRadioValue(industryObjlist[0]);
      setcheckedIndustryValues(industryObjlist);
    }
    else {
      setcheckedIndustryValues([]);
      setCheckedIndustryRadioValue(null);
      props.AdditionalFormData.primaryIndustryId = null;
      props.AdditionalFormData.secondaryIndustryId = null;
    }

  }

  const setCountryHierarcy = (selectedCountry: any, initialCountryList?: []) => {
    let hierarcy = selectedCountry.Name;

    let allCountrylist = countrylist && countrylist.length > 0 ? countrylist : initialCountryList;
    if (allCountrylist === null || allCountrylist === undefined || allCountrylist.length === 0) {
      return hierarcy;
    }
    if (selectedCountry.ParentId > 0) {
      let parentItem: any = allCountrylist.find((m: any) => m.Id === selectedCountry.ParentId);
      let l1_ParentItemName: string = "";
      if (selectedCountry.Level && selectedCountry.Level === 3) {
        let level1_parentItem: any = allCountrylist.find((m: any) => m.Id === parentItem?.ParentId);
        l1_ParentItemName = level1_parentItem?.Name + ">";
      }
      hierarcy = l1_ParentItemName + parentItem?.Name + ">" + selectedCountry.Name;
    }
    selectedCountry.Hierarcy = hierarcy;

    return hierarcy;
  }

  const saveCountryID = (checkedlist: any[]) => {
    if (checkedCountryValues && checkedCountryValues.length == 0) {
      checkedlist.map((item) => {
        var checkeditems: any = countrylist?.filter(
          (e: { Name?: string; Id?: number }) => {
            if (e.Id == item) {
              return e;
            }
          }
        );
        if (checkeditems && checkeditems.length > 0) {
          let data = {
            Name: setCountryHierarcy(checkeditems[0]),
            Id: checkeditems[0].Id,
          };
          countryObjlist.push(data);
        }
      });
      if (countryObjlist.length > 0 && checkedCountryRadioValue == null) {
        setCheckedCountryRadioValue(countryObjlist[0]);
      }
      setcheckedCountryValues(countryObjlist);
    }


  }

  const saveBusinessID = (checkedlist: any[]) => {
    checkedlist?.map((item) => {
      var checkeditems = businessList?.filter(
        (e: { Name?: string; Id?: number }) => {
          if (e.Id == item) {
            return e;
          }
        }
      );
      if (checkeditems && checkeditems.length > 0) {
        let data = {
          Name: checkeditems[0].FullPath,
          Id: checkeditems[0].Id,
        };
        businessObjlist.push(data);
      }
    });

    if (businessObjlist && businessObjlist.length > 0) {
      setCheckedBusinessRadioValue(businessObjlist[0]);
      setcheckedBusinessValues(businessObjlist);
    }
    else {

      setcheckedBusinessValues([]);
      setCheckedBusinessRadioValue(null);
      props.AdditionalFormData.primaryClientServiceId = null;
      props.AdditionalFormData.secondaryClientServiceId = null;
    }

  }

  const saveBusinessLocalID = (checkedlist: any[]) => {
    if (checkedCountryRadioValue && Object.keys(checkedCountryRadioValue).length > 0) {
      let checkCountry: any[] = countrylist.filter((item: any) => { return item.Id == checkedCountryRadioValue.Id });
      if (checkCountry && checkCountry.length > 0) {
        if (checkCountry[0].Level == 3 && (checkCountry[0].Name == 'Australia' || checkCountry[0].Name == 'Canada' || checkCountry[0].Name == 'Germany' || checkCountry[0].Name == 'United States' || checkCountry[0].Name == 'United Kingdom')) {
          checkedlist.map((item) => {
            var checkeditems = businessLocalList?.filter(
              (e: { Name?: string; Id?: number }) => {
                if (e.Id == item) {
                  return e;
                }
              }
            );
            if (checkeditems && checkeditems.length > 0) {
              let data = {
                Name: checkeditems[0].FullPath,
                Id: checkeditems[0].Id,
              };
              businessLocalObjlist.push(data);
            }
          });
          if (businessLocalObjlist && businessLocalObjlist.length > 0) {
            setCheckedBusinessLocalRadioValue(businessLocalObjlist[0]);
            setcheckedBusinessLocalValues(businessLocalObjlist);
          }
          else {
            setcheckedBusinessLocalValues([]);
            setCheckedBusinessLocalRadioValue(null);
            props.AdditionalFormData.primaryLocalClientServiceId = null;
            props.AdditionalFormData.secondaryLocalClientServiceId = null;
          }
        }
      }
    }


  }

  useEffect(() => {
    if (showBusinessuser) {
      setUserBusinessGlobalDefaultData();
    } else {
      setShowBusinessUser(true)
    }

  }, [businessUserDefaultData, businessList]);

  const setUserIndustryDefaultData = () => {
    let presentIndustry: any[] = [];
    if (industryList && industryList.length > 0) {
      presentIndustry = industryUserDefaultData?.filter((o) =>
        industryList.some(({ Id, Name }) => o.Id === Id)
      );
    }

    let defaultlist: any[] = presentIndustry?.map((item) => {
      return item.Id;
    });
    setCheckedIndustryTreeValue(defaultlist);
    saveIndustryID(defaultlist)
  };

  const setUserIndustryDefaultDataDraft = () => {
    let presentIndustry: any[] = [];
    if (industryList.length > 0) {
      presentIndustry = industryUserDefaultDataDraft?.filter((o) =>
        industryList.some(({ Id, Name }) => o.Id === Id)
      );
    }

    let defaultlist: any[] = presentIndustry?.map((item) => {
      return item.Id;
    });
    setCheckedIndustryTreeValue(defaultlist);
    saveIndustryID(defaultlist)
  };

  useEffect(() => {
    if (showIndustryuser) {
      setUserIndustryDefaultData();
    } else {
      setShowIndustryUser(true)
    }

  }, [industryUserDefaultData, industryList]);


  const setUserCountryDefaultData = () => {
    console.log("inner default valu", countryUserDefaultData)
    if (countryUserDefaultData && countryUserDefaultData.length > 0) {
      let presentCountry: any[] = [];
      if (countrylist.length > 0) {
        presentCountry = countryUserDefaultData?.filter((o) =>
          countrylist.some(({ Id, Name }) => o.Id === Id)
        );

      }

      let defaultlist: any[] = presentCountry.map((item) => {
        return item.Id;
      });
      setCheckedCountryTreeValue(defaultlist);
      saveCountryID(defaultlist)
    }
  }

  useEffect(() => {
    setUserCountryDefaultData();
  }, [countryUserDefaultData, countrylist]);

  const autoPopulateBusinessandIndustry = async () => {
    if (!autoPoulate) {
      if (
        selectedClient &&
        selectedClient.length > 0 &&
        selectedQualValue &&
        selectedQualValue.length > 0
      ) {
        let qualId = selectedQualValue[0].id;
        let QualIdData = await _sharedService.GetQualsDataById(qualId)
        let QualIdDataNew = QualIdData && QualIdData.qualsData && QualIdData.qualsData.length > 0 ? QualIdData.qualsData[0]?._source : null;
        if (QualIdDataNew) {
          let memberfirmqual = QualIdDataNew.geography && QualIdDataNew.geography.length > 0 ? QualIdDataNew.geography[0].id : 0;
          let industryobjlistval = [];
          let industrytreelist = [];
          let buslocalobjlistval = [];
          let buslocaltreelist = [];
          let busglobalobjlistval = [];
          let busglobaltreelist = [];
          if (
            QualIdDataNew.kxq_primaryglobalindustry ||
            QualIdDataNew.kxq_secondaryglobalindustry ||
            QualIdDataNew.kxq_primaryglobalclientservice ||
            QualIdDataNew.kxq_secondarygobalclientservice ||
            QualIdDataNew.kxq_primarylocalclientservice ||
            QualIdDataNew.kxq_secondarylocalclientservice
          ) {
            if (QualIdDataNew.kxq_primaryglobalindustry) {
              let primaryindustry = QualIdDataNew.kxq_primaryglobalindustry?.split(";");
              let industryObj = industryList.filter((industry) => { return industry.ExternalId == parseInt(primaryindustry[0]) })
              if (industryObj && industryObj.length > 0) {
                let primaryindustryobj: any[] = [
                  { Id: industryObj[0].Id, Name: industryObj[0].FullPath },
                ];
                industryobjlistval.push(primaryindustryobj[0]);
                let defaultlist: any[] = primaryindustryobj.map((item) => {
                  return item.Id;
                });
                industrytreelist.push(defaultlist[0]);
              }
            }
            if (QualIdDataNew.kxq_secondaryglobalindustry) {
              let secondaryindustryitems = QualIdDataNew.kxq_secondaryglobalindustry?.split("|");
              secondaryindustryitems.map((item: any) => {
                let secondaryindustry = item.split(";");
                let industryObj = industryList.filter((industry) => { return industry.ExternalId == parseInt(secondaryindustry[0]) })
                if (industryObj && industryObj.length > 0) {
                  let secondaryindustryobj: any = [
                    { Id: industryObj[0].Id, Name: industryObj[0].FullPath },
                  ];
                  industryobjlistval.push(secondaryindustryobj[0]);
                  let defaultlist: any[] = secondaryindustryobj.map(
                    (item: any) => {
                      return item.Id;
                    }
                  );
                  industrytreelist.push(defaultlist[0]);
                }
              });
            }
            if (memberfirmqual) {
              if (memberfirmqual == memberFirmID) {
                if (QualIdDataNew.kxq_primarylocalclientservice) {
                  let primarybuslocal = QualIdDataNew.kxq_primarylocalclientservice?.split(";");
                  let businessLocalObj = businessLocalList.filter((business) => { return business.ExternalId == parseInt(primarybuslocal[0]) })
                  if (businessLocalObj && businessLocalObj.length > 0) {
                    let primarybuslocalobj: any[] = [
                      { Id: businessLocalObj[0].Id, Name: businessLocalObj[0].FullPath },
                    ];
                    buslocalobjlistval.push(primarybuslocalobj[0]);
                    let defaultlist: any[] = primarybuslocalobj.map((item) => {
                      return item.Id;
                    });
                    buslocaltreelist.push(defaultlist[0]);
                  }
                  // setCheckedBusinessLocalRadioValue(primarybuslocalobj[0]);
                }
                if (QualIdDataNew.kxq_secondarylocalclientservice) {
                  let secondarybuslocalitems = QualIdDataNew.kxq_secondarylocalclientservice?.split("|");
                  secondarybuslocalitems.map((item: any) => {
                    let secondarybuslocal = item.split(";");
                    let businessLocalObj = businessLocalList.filter((business) => { return business.ExternalId == parseInt(secondarybuslocal[0]) })
                    if (businessLocalObj && businessLocalObj.length > 0) {
                      let secondarybuslocalobj: any = [
                        { Id: businessLocalObj[0].Id, Name: businessLocalObj[0].FullPath },
                      ];
                      buslocalobjlistval.push(secondarybuslocalobj[0]);
                      let defaultlist: any[] = secondarybuslocalobj.map(
                        (item: any) => {
                          return item.Id;
                        }
                      );
                      buslocaltreelist.push(defaultlist[0]);
                    }
                  });
                }
                if (QualIdDataNew.kxq_primaryglobalclientservice) {
                  let primarybusglobal =
                    QualIdDataNew.kxq_primaryglobalclientservice?.split(";");
                  let businessObj = businessList.filter((business) => { return business.ExternalId == parseInt(primarybusglobal[0]) })
                  if (businessObj && businessObj.length > 0) {
                    let primarybusglobalobj: any[] = [
                      { Id: businessObj[0].Id, Name: businessObj[0].FullPath },
                    ];
                    busglobalobjlistval.push(primarybusglobalobj[0]);
                    let defaultlist: any[] = primarybusglobalobj.map((item) => {
                      return item.Id;
                    });
                    busglobaltreelist.push(defaultlist[0]);
                  }
                  //setCheckedBusinessRadioValue(primarybusglobalobj[0]);
                }
                if (QualIdDataNew.kxq_secondaryglobalclientservice) {
                  let secondarybusglobalitems = QualIdDataNew.kxq_secondaryglobalclientservice?.split("|");
                  secondarybusglobalitems.map((item: any) => {
                    let secondarybusglobal = item.split(";");
                    let businessObj = businessList.filter((business) => { return business.ExternalId == parseInt(secondarybusglobal[0]) })
                    if (businessObj && businessObj.length > 0) {
                      let secondarybusglobalobj: any = [
                        {
                          Id: businessObj[0].Id,
                          Name: businessObj[0].FullPath,
                        },
                      ];
                      busglobalobjlistval.push(secondarybusglobalobj[0]);
                      let defaultlist: any[] = secondarybusglobalobj.map(
                        (item: any) => {
                          return item.Id;
                        }
                      );
                      busglobaltreelist.push(defaultlist[0]);
                    }
                  });
                }
                setCheckedBusinessLocalTreeValue(buslocaltreelist);
                saveBusinessLocalID(buslocaltreelist)
                setCheckedBusinessTreeValue(busglobaltreelist);
                saveBusinessID(busglobaltreelist)
                // setCheckedBusinessTreeValue([]);
                // saveBusinessID([])
                // setCheckedBusinessRadioValue({Id:null,Name:null})

              } else {
                if (QualIdDataNew.kxq_primaryglobalclientservice) {
                  let primarybusglobal =
                    QualIdDataNew.kxq_primaryglobalclientservice?.split(";");
                  let businessObj = businessList.filter((business) => { return business.ExternalId == parseInt(primarybusglobal[0]) })
                  if (businessObj && businessObj.length > 0) {
                    let primarybusglobalobj: any[] = [
                      { Id: businessObj[0].Id, Name: businessObj[0].FullPath },
                    ];
                    busglobalobjlistval.push(primarybusglobalobj[0]);
                    let defaultlist: any[] = primarybusglobalobj.map((item) => {
                      return item.Id;
                    });
                    busglobaltreelist.push(defaultlist[0]);
                    //setCheckedBusinessRadioValue(primarybusglobalobj[0]);
                  }
                }
                if (QualIdDataNew.kxq_secondaryglobalclientservice) {
                  let secondarybusglobalitems = QualIdDataNew.kxq_secondaryglobalclientservice?.split("|");
                  secondarybusglobalitems?.map((item: any) => {
                    let secondarybusglobal = item?.split(";");
                    let businessObj = businessList?.filter((business) => { return business.ExternalId == parseInt(secondarybusglobal[0]) })
                    if (businessObj && businessObj.length > 0) {
                      let secondarybusglobalobj: any = [
                        {
                          Id: businessObj[0].Id,
                          Name: businessObj[0].FullPath,
                        },
                      ];
                      busglobalobjlistval.push(secondarybusglobalobj[0]);
                      let defaultlist: any[] = secondarybusglobalobj.map(
                        (item: any) => {
                          return item.Id;
                        }
                      );
                      busglobaltreelist.push(defaultlist[0]);
                    }
                  });
                }
                setCheckedBusinessTreeValue(busglobaltreelist);
                saveBusinessID(busglobaltreelist)
                setCheckedBusinessLocalTreeValue([]);
                saveBusinessLocalID([])
                setCheckedBusinessLocalRadioValue(null)
              }
            }
            setCheckedIndustryTreeValue(industrytreelist);
            saveIndustryID(industrytreelist)
          } else {
            setUserIndustryDefaultData();
            setUserBusinessGlobalDefaultData();
          }

        }
      } else if (
        selectedClient &&
        selectedClient.length > 0 &&
        selectedQualValue &&
        selectedQualValue.length == 0
      ) {
        let clientId = selectedClient[0].key;
        let ClientIdData = await _sharedService.GetClientDataById(clientId)
        let ClientIdDataNew = ClientIdData && ClientIdData.clientsData && ClientIdData.clientsData.length > 0 ? ClientIdData.clientsData[0]?._source : null;

        if (ClientIdDataNew) {
          let industryobjlistval = [];
          let industrytreelist = [];
          if (
            ClientIdDataNew.kxc_primaryglobalindustry ||
            ClientIdDataNew.kxc_secondaryglobalindustry
          ) {
            if (ClientIdDataNew.kxc_primaryglobalindustry) {
              let primaryindustry = ClientIdDataNew.kxc_primaryglobalindustry?.split(";");
              let industryObj = industryList.filter((industry) => { return industry.ExternalId == parseInt(primaryindustry[0]) })
              if (industryObj && industryObj.length > 0) {
                let primaryindustryobj: any[] = [
                  { Id: industryObj[0].Id, Name: industryObj[0].FullPath },
                ];
                industryobjlistval.push(primaryindustryobj[0]);
                let defaultlist: any[] = primaryindustryobj.map((item) => {
                  return item.Id;
                });
                industrytreelist.push(defaultlist[0]);
              }
            }
            if (ClientIdDataNew.kxc_secondaryglobalindustry) {
              let secondaryindustryitems = ClientIdDataNew.kxc_secondaryglobalindustry?.split("|");
              secondaryindustryitems?.map((item: any) => {
                let secondaryindustry = item.split(";");
                let industryObj = industryList?.filter((industry) => { return industry.ExternalId == parseInt(secondaryindustry[0]) })
                if (industryObj && industryObj.length > 0) {
                  let secondaryindustryobj: any = [
                    { Id: industryObj[0].Id, Name: industryObj[0].FullPath },
                  ];
                  industryobjlistval.push(secondaryindustryobj[0]);
                  let defaultlist: any[] = secondaryindustryobj.map(
                    (item: any) => {
                      return item.Id;
                    }
                  );
                  industrytreelist.push(defaultlist[0]);
                }
              });
            }
            setCheckedIndustryTreeValue(industrytreelist);
            saveIndustryID(industrytreelist)
            if (draftid == undefined) {
              setUserBusinessGlobalDefaultData();
            }
            else if (draftid != undefined) {
              setUserBusinessGlobalDefaultDataDraft();
            }
            // setCheckedBusinessTreeValue([]);
            // saveBusinessID([]);
            // setCheckedBusinessRadioValue(null)
            // setCheckedBusinessLocalTreeValue([]);
            // saveBusinessLocalID([])
            // setCheckedBusinessLocalRadioValue(null)
          } else {
            setUserIndustryDefaultData();
          }
        }
      } else {
        setNullBusinessIndustry();
        if (draftid == undefined) {
          setUserIndustryDefaultData();
          setUserBusinessGlobalDefaultData();
        } else if (draftid != undefined) {
          setUserIndustryDefaultDataDraft();
          setUserBusinessGlobalDefaultDataDraft();
        }
        //setcurruserMemberFirmID(memberFirmID);
        //GetLocalMemberFirmTree(memberFirmID);

      }
    }
    else {
      setautoPoulate(false)
    }

  }

  useEffect(() => {
    autoPopulateBusinessandIndustry();
  }, [selectedClient, selectedQualValue]);

  useEffect(() => {
    if (props.draftData === null) {
      setReopenFlag(false)
      let defaultUserData = {
        name: userdetails.title,
        key: userdetails.kxp_email,
      }
      setDefaultAuthor([defaultUserData]);
      setDefaultContact([defaultUserData]);
      setDefaultContributor([defaultUserData]);
    }
    if (props.AdditionalFormData && props.draftData) {
      setReopenFlag(true)
      if (props.AdditionalFormData.contentdate && props.AdditionalFormData.contentdate != null) {
        //const moment = require('moment');
        //var date = moment.utc(props.AdditionalFormData.contentdate).local().format("MM-DD-YYYY");
        //var time = moment().format("HH:mm");
        //var datetime = date + " " + time;
        setSelectedDate(new Date(props.AdditionalFormData.contentdate));
      }

      if (props.AdditionalFormData.publishInstructions) {
        setPublishingInstructiontext(props.AdditionalFormData.publishInstructions)
      }

      if (props.AdditionalFormData.countryofOriginId && props.draftData.countryOfOrigin) {
        var countryOfOriginobj = [{ name: props.draftData.countryOfOrigin.name, key: props.draftData.countryOfOrigin.name }]
        setSelectedCountryValue(countryOfOriginobj)
      }

      if (props.AdditionalFormData.authorandContactsforDocuments && props.AdditionalFormData.authorandContactsforDocuments.length > 0) {
        let tempauthors: any[] = [];
        let tempkeycontacts: any[] = [];
        let tempauthorsobjarr: any[] = [];
        let tempkeycontactsobjarr: any[] = [];
        let tempcontributors: any[] = [];
        let tempcontributorsobjarr: any[] = [];
        props.AdditionalFormData.authorandContactsforDocuments.map((item) => {
          if (item.peopleType == 1) {
            let data = {
              name: item.user.displayName,
              key: item.user.email,
              // isNonDeloitteUser: item.isExternalAuthor == true
            }
            tempauthors.push(data);
            tempauthorsobjarr.push(item)
          }
          if (item.peopleType == 2) {
            let data = {
              name: item.user.displayName,
              key: item.user.email,
            }
            tempkeycontacts.push(data)
            tempkeycontactsobjarr.push(item)
          }
          if (item.peopleType == 3) {
            let data = {
              name: item.user.displayName,
              key: item.user.email,
            }
            tempcontributors.push(data)
            tempcontributorsobjarr.push(item)
          }
        });
        setDefaultAuthor(tempauthors);
        setDefaultContact(tempkeycontacts)
        setAuthorFullData(tempauthorsobjarr)
        setContactFullData(tempkeycontactsobjarr);

        // setSelectedAuthor(tempauthors);
        setSelectedAuthor(tempauthorsobjarr);
        // setSelectedKeyContacts(tempkeycontacts);
        setSelectedKeyContacts(tempkeycontactsobjarr);
        setDefaultContributor(tempcontributors);
        setContributorFullData(tempcontributorsobjarr);
        // setSelectedContributors(tempcontributors);
        setSelectedContributors(tempcontributorsobjarr);
      } else {
        setDefaultAuthor([]);
        setDefaultContact([])
        setAuthorFullData([])
        setContactFullData([]);
        setSelectedAuthor([]);
        setSelectedKeyContacts([]);
        setDefaultContributor([]);
        setContributorFullData([]);
        setSelectedContributors([]);
      }

      if (props.AdditionalFormData.clientName && props.AdditionalFormData.clientId) {
        let clientData: ITag[] | undefined = [{ name: props.AdditionalFormData.clientName, key: props.AdditionalFormData.clientId }];
        setSelectedClient(clientData)
        _sharedService.GetRelatedQualData(props.AdditionalFormData.clientId).then((res) => {
          setRelatedQualData(res);
          let arr: any = [];
          res?.map((qual: { qualId: number; qualName: string; clientConfidentiality: number; status: string; startDate: any; endDate: any }) => {
            if (qual?.clientConfidentiality != 2 && qual?.status == "Published") {
              let obj = {
                key: qual.qualName,
                id: qual.qualId,
                name: qual.qualName + " [Start Date: " + dateFormat(qual.startDate) + " | End Date: " + dateFormat(qual.endDate) + "]",
              };
              arr.push(obj);
            }
          });
          setSelectedQual(arr);
        })
        // setRelatedQualData(associatedQual);
        setautoPoulate(true)
      }

      if (props.AdditionalFormData.qualName && props.AdditionalFormData.qualId) {
        let qualData = [{ key: props.AdditionalFormData.qualName, id: props.AdditionalFormData.qualId, name: props.AdditionalFormData.qualName }];
        setSelectedQualValue(qualData)
        setautoPoulate(true)
      }



    }

    if (props.draftData) {
      if (industryList && industryList.length > 0 && (props.draftData.primaryGlobalIndustryId || (props.draftData.secondaryGlobalIndustryIds && props.draftData.secondaryGlobalIndustryIds.length > 0))) {

        let industryDefaulvalues: any[] = []
        if (props.draftData.primaryGlobalIndustry) {
          var primaryindustry = {
            Name: props.draftData.primaryGlobalIndustry.name,
            Id: props.draftData.primaryGlobalIndustryId
          }
          industryDefaulvalues.push(primaryindustry);
        }
        if (props.draftData.secondaryGlobalIndustryIds && props.draftData.secondaryGlobalIndustryIds.length > 0) {
          props.draftData.secondaryGlobalIndustryIds.map((data: any, index: number) => {
            let secondaryIndustryData: any = industryList.filter(industry => industry.Id == data);
            let obj = {
              Name: secondaryIndustryData[0].Name,
              Id: secondaryIndustryData[0].Id
            }
            industryDefaulvalues.push(obj)


          })
        }
        // setIndustryUserDefaultData(industryDefaulvalues);
        let presentIndustry: any[] = [];
        if (industryList.length > 0) {
          presentIndustry = industryDefaulvalues.filter((o) =>
            industryList.some(({ Id, Name }) => o.Id === Id)
          );
        }

        let defaultlist: any[] = presentIndustry?.map((item) => {
          return item.Id;
        });
        setCheckedIndustryTreeValue(defaultlist);
        saveIndustryID(defaultlist);
      }
      if (businessList && businessList.length > 0 && (props.draftData.primaryGlobalClientServiceId || (props.draftData.secondaryGlobalClientServiceIds && props.draftData.secondaryGlobalClientServiceIds.length > 0))) {
        let businessGlobalDefaulvalues: any[] = []
        if (props.draftData.primaryGlobalClientService) {
          var primarybusinessglobal = {
            Name: props.draftData.primaryGlobalClientService.fullPath,
            Id: props.draftData.primaryGlobalClientServiceId
          }
          businessGlobalDefaulvalues.push(primarybusinessglobal);
        }
        if (props.draftData.secondaryGlobalClientServiceIds) {
          props.draftData.secondaryGlobalClientServiceIds.map((data: any, index: number) => {

            let secondaryBusinessGlobalData: any = businessList.filter(business => business.Id == data);
            let obj = {
              Name: secondaryBusinessGlobalData[0].FullPath,
              Id: secondaryBusinessGlobalData[0].Id
            }
            businessGlobalDefaulvalues.push(obj)

          })
        }
        // setBusinessUserDefaultData(businessGlobalDefaulvalues);
        let presentBusiness: any[] = [];
        if (businessList && businessList.length > 0) {
          presentBusiness = businessGlobalDefaulvalues.filter((o) =>
            businessList.some(({ Id, Name }) => o.Id === Id)
          );
        }

        let defaultlist: any[] = presentBusiness.map((item) => {
          return item.Id;
        });
        setCheckedBusinessTreeValue(defaultlist);
        saveBusinessID(defaultlist);
        // setcheckedBusinessValues(businessGlobalDefaulvalues);
      }

      if (businessLocalList && businessLocalList.length > 0 && (props.draftData.primaryLocalClientServiceId || (props.draftData.secondaryLocalClientServiceIds && props.draftData.secondaryLocalClientServiceIds.length > 0))) {
        let businessLocalDefaulvalues: any[] = []
        if (props.draftData.primaryLocalClientService) {
          var primarybusinesslocal = {
            Name: props.draftData.primaryLocalClientService.name,
            Id: props.draftData.primaryLocalClientServiceId
          }
          businessLocalDefaulvalues.push(primarybusinesslocal);
        }
        if (props.draftData.secondaryLocalClientServiceIds) {
          props.draftData.secondaryLocalClientServiceIds?.map((data: any, index: number) => {
            let secondaryBusinessLocalData: any = businessLocalList?.filter(business => business.Id == data);
            let obj = {
              Name: secondaryBusinessLocalData[0]?.Name,
              Id: secondaryBusinessLocalData[0]?.Id
            }
            businessLocalDefaulvalues.push(obj);
          })
        }
        setUserBusinessLocalDefaultData(businessLocalDefaulvalues);
      }
      if (countrylist && countrylist.length > 0 && (props.draftData.countryofOriginId || (props.draftData.secondaryCountryOfOriginIds && props.draftData.secondaryCountryOfOriginIds.length > 0))) {
        let countryDefaulvalues: any[] = []
        if (props.draftData.countryOfOrigin) {
          var countryOfOrigin = {
            Name: props.draftData.countryOfOrigin.name,
            Id: props.draftData.countryofOriginId
          }
          countryDefaulvalues.push(countryOfOrigin);
        }
        if (props.draftData.secondaryCountryOfOriginIds && props.draftData.secondaryCountryOfOriginIds.length > 0) {
          props.draftData.secondaryCountryOfOriginIds?.map((data: any, index: number) => {
            let secondaryCountryData: any = countrylist?.filter((country: any) => country.Id == data);
            let obj = {
              Name: secondaryCountryData[0].Name,
              Id: secondaryCountryData[0].Id
            }
            countryDefaulvalues.push(obj)


          })
        }
        // setIndustryUserDefaultData(industryDefaulvalues);
        let presentCountry: any[] = [];
        if (countrylist && countrylist.length > 0) {
          presentCountry = countryDefaulvalues?.filter((o) =>
            countrylist.some(({ Id, Name }) => o.Id === Id)
          );
        }

        let defaultlist: any[] = presentCountry.map((item) => {
          return item.Id;
        });
        setCheckedCountryTreeValue(defaultlist);
        saveCountryID(defaultlist);
      }
    }
  }, [props.AdditionalFormData, props.draftData, industryList, businessList, businessLocalList, countrylist])

  useEffect(() => {
    if (selectedDate) {
      var date = moment(selectedDate).format("MM-DD-YYYY");
      var time = moment().format("HH:mm");
      var datetime = date + " " + time;
      props.AdditionalFormData.contentdate = new Date(datetime);
    } else {
      props.AdditionalFormData.contentdate = undefined
    }
  }, [selectedDate])

  const setAuthor = (items: any) => {
    setSelectedAuthor(items);
  };

  const setKeyContacts = (items: any) => {
    setSelectedKeyContacts(items);
  };

  const setContributors = (items: any) => {
    setSelectedContributors(items);
  }

  useEffect(() => {
    setTimeout(() => {
      if (selectedAuthor && selectedAuthor.length > 0 && selectedkeyContacts && selectedkeyContacts.length > 0 && selectedContributors.length > 0) {
        props.AdditionalFormData.authorandContactsforDocuments = [
          ...selectedAuthor,
          ...selectedkeyContacts,
          ...selectedContributors
        ];
      } else if (selectedkeyContacts && selectedkeyContacts.length > 0 && selectedAuthor.length === 0 && selectedContributors.length === 0) {
        props.AdditionalFormData.authorandContactsforDocuments = [
          ...selectedkeyContacts,
        ];
      } else if (selectedkeyContacts.length === 0 && selectedAuthor && selectedAuthor.length > 0 && selectedContributors.length === 0) {
        props.AdditionalFormData.authorandContactsforDocuments = [
          ...selectedAuthor,
        ];
      } else if (selectedkeyContacts.length === 0 && selectedAuthor.length === 0 && selectedContributors && selectedContributors.length > 0) {
        props.AdditionalFormData.authorandContactsforDocuments = [
          ...selectedContributors,
        ];
      }
      else if (selectedkeyContacts.length === 0 && selectedAuthor.length === 0 && selectedContributors.length === 0) {
        if (props.AdditionalFormData.authorandContactsforDocuments != null)
          props.AdditionalFormData.authorandContactsforDocuments = [];
      }
    }, 1000)

  }, [selectedAuthor, selectedkeyContacts, selectedContributors]);

  const setCheckedValues = (value: any) => {
    let checkedContentTypeValue =
      checkedTreeValues.indexOf(value[0]) > -1 ? [] : value;
    setCheckedTreeValues(checkedContentTypeValue);
  };

  // const setCountryCheckedValues = (value: any) => {
  //   setCheckedCountryValues(value);
  // };

  const setBusinessCheckedValues = (value: any) => {
    let currentdata = checkedbusinesstreevalue;
    let currentCheckedRadioValue = checkedBusinessRadioValue;
    if (currentdata.indexOf(value[0]) > -1) {
      if (currentCheckedRadioValue && currentCheckedRadioValue.Id == value[0]) {
        setCheckedBusinessRadioValue(null);
      }
      let filtercurrentdata = currentdata.filter((item) => {
        return item != value[0];
      });
      setCheckedBusinessTreeValue(filtercurrentdata);
    } else {
      currentdata.push(value[0]);
      setCheckedBusinessTreeValue(currentdata);
    }
  };

  const setBusinessLocalCheckedValues = (value: any) => {
    let currentCheckedRadioValue = checkedBusinessLocalRadioValue;
    let currentdata = checkedbusinesslocaltreevalue;
    if (currentdata.indexOf(value[0]) > -1) {
      if (currentCheckedRadioValue && currentCheckedRadioValue.Id == value[0]) {
        setCheckedBusinessLocalRadioValue(null);
      }
      let filtercurrentdata = currentdata?.filter((item) => {
        return item != value[0];
      });
      setCheckedBusinessLocalTreeValue(filtercurrentdata);
    } else {
      currentdata.push(value[0]);
      setCheckedBusinessLocalTreeValue(currentdata);
    }
  };

  const setIndustryCheckedValues = (value: any) => {
    let currentCheckedRadioValue = checkedIndustryRadioValue;
    let currentdata = checkedindustrytreevalue;
    if (currentdata.indexOf(value[0]) > -1) {
      if (currentCheckedRadioValue && currentCheckedRadioValue.Id == value[0]) {
        setCheckedIndustryRadioValue(null);
      }
      let filtercurrentdata = currentdata?.filter((item) => {
        return item != value[0];
      });
      setCheckedIndustryTreeValue(filtercurrentdata);
    } else {
      currentdata.push(value[0]);
      setCheckedIndustryTreeValue(currentdata);
    }
  };

  const setCountryCheckedValues = (value: any) => {
    let currentCheckedRadioValue = checkedCountryRadioValue;
    let currentdata = checkedCountrytreevalue;
    if (currentdata.indexOf(value[0]) > -1) {
      if (currentCheckedRadioValue && currentCheckedRadioValue.Id == value[0]) {
        setCheckedCountryRadioValue(null);
      }
      let filtercurrentdata = currentdata?.filter((item) => {
        return item != value[0];
      });
      setCheckedCountryTreeValue(filtercurrentdata);
    } else {
      currentdata.push(value[0]);
      setCheckedCountryTreeValue(currentdata);
    }
  };

  const setSelection = (items: any) => {
    if (items.length > 0) {
      props.AdditionalFormData.contentTypeId = items[0].id;
    } else {
      props.AdditionalFormData.contentTypeId = null;
    }
    setSelectedContent(items);
    if (items.length == 0) {
      setCheckedTreeValues([]);
    } else {
      if (items[0].name.includes("->")) {
        setCheckedTreeValues([items[0].name?.split("->")[1]]);
      } else {
        setCheckedTreeValues([items[0].name]);
      }
    }
  };

  // const setCountrySelection = (items: any) => {
  //   if (items.length > 0) {
  //     countrylist.map((item: any) => {
  //       if (item.Name == items[0].name) {
  //         props.AdditionalFormData.countryofOriginId = item.Id;
  //       }
  //     });
  //   }
  //   setSelectedCountryValue(items);

  //   if (items.length == 0) {
  //     setCheckedCountryValues([]);
  //   } else {
  //     setCheckedCountryValues([items[0].id]);
  //   }
  // };

  const onTreeValueSave = (currentNode: any) => {
    setContentModalView(!contentModalView);
    if (checkedTreeValues.length == 0) {
      setSelectedContent([]);
      props.AdditionalFormData.contentTypeId = null;
    } else {
      contenttypelist?.map((item: any) => {
        if (item.Name == currentNode[0].label) {
          props.AdditionalFormData.contentTypeId = item.Id;
        }
      });
      if (currentNode && JSON.stringify(currentNode[0].parent) != '{}') {
        setSavedContentTreeValues(checkedTreeValues);
        contenttypelist?.map((item: any) => {
          if (item.Name == currentNode[0].label) {
            setSelectedContent([{ name: item.FullPath, key: item.FullPath }]);
          }
        });
      } else {
        setSavedContentTreeValues(checkedTreeValues);
        setSelectedContent([
          { name: checkedTreeValues, key: checkedTreeValues },
        ]);
      }
    }
  };

  // const onCountryTreeValueSave = (currentNode: any) => {
  //   countrylist.map((item: any) => {
  //     if (item.Name == currentNode[0].label) {
  //       props.AdditionalFormData.countryofOriginId = item.Id;
  //     }

  //   });

  //   setCountryModalView(!countryModalView);
  //   if (checkedCountryValues == undefined) {
  //     setSelectedCountryValue([]);
  //   } else {
  //     setSavedContentTreeValues(checkedCountryValues);
  //     setSelectedCountryValue([
  //       { name: currentNode[0].label, key: currentNode[0].value },
  //     ]);
  //   }
  // };

  const onBusinessTreeValueSave = () => {
    checkedbusinesstreevalue.map((item) => {
      var checkeditems = businessList?.filter(
        (e: { Name?: string; Id?: number }) => {
          if (e.Id == item) {
            return e;
          }
        }
      );
      if (checkeditems.length > 0) {
        let data = {
          Name: checkeditems[0].FullPath,
          Id: checkeditems[0].Id,
        };
        businessObjlist.push(data);
      }
    });

    setcheckedBusinessValues(businessObjlist);
    if (!checkedBusinessRadioValue || checkedBusinessRadioValue == null) {
      setCheckedBusinessRadioValue(businessObjlist[0]);
    }
    setBusinessModalView(!businessModalView);
  };

  const onBusinessLocalTreeValueSave = () => {
    // let prevValues: boolean;
    // checkedBusinessLocalValues.length > 0
    //   ? (prevValues = true)
    //   : (prevValues = false);
    checkedbusinesslocaltreevalue.map((item) => {
      var checkeditems = businessLocalList?.filter(
        (e: { Name?: string; Id?: number }) => {
          if (e.Id == item) {
            return e;
          }
        }
      );
      if (checkeditems.length > 0) {
        let data = {
          Name: checkeditems[0].FullPath,
          Id: checkeditems[0].Id,
        };
        businessLocalObjlist.push(data);
      }
    });
    setcheckedBusinessLocalValues(businessLocalObjlist);
    if (!checkedBusinessLocalRadioValue || checkedBusinessLocalRadioValue == null) {
      setCheckedBusinessLocalRadioValue(businessLocalObjlist[0]);
    }
    setBusinessLocalModalView(!businessLocalModalView);
  };

  const onIndustryTreeValueSave = () => {
    checkedindustrytreevalue.map((item) => {
      var checkeditems = industryList?.filter(
        (e: { Name?: string; Id?: number }) => {
          if (e.Id == item) {
            return e;
          }
        }
      );
      if (checkeditems.length > 0) {
        let data = {
          Name: checkeditems[0].FullPath,
          Id: checkeditems[0].Id,
        };
        industryObjlist.push(data);
      }
    });
    setcheckedIndustryValues(industryObjlist);
    if (!checkedIndustryRadioValue || checkedIndustryRadioValue == null) {
      setCheckedIndustryRadioValue(industryObjlist[0]);
    }
    setIndustryModalView(!IndustryModalView);
  };

  const onCountryTreeValueSave = () => {
    checkedCountrytreevalue.map((item) => {
      var checkeditems: any = countrylist?.filter(
        (e: { Name?: string; Id?: number }) => {
          if (e.Id == item) {
            return e;
          }
        }
      );
      if (checkeditems.length > 0) {
        let data = {
          Name: setCountryHierarcy(checkeditems[0]),
          Id: checkeditems[0].Id,
        };
        countryObjlist.push(data);
      }
    });
    setcheckedCountryValues(countryObjlist);
    let primaryCountry: any = countrylist?.filter(
      (e: { Name?: string; Id?: number }) => {
        if (e.Id == checkedCountrytreevalue[0]) {
          return e;
        }
      }
    );
    if (primaryCountry && primaryCountry.length > 0 && checkedCountryRadioValue && checkedCountryRadioValue.Id != primaryCountry[0].Id) {
      if (primaryCountry[0].Level == 3 && (primaryCountry[0].Name == 'Australia' || primaryCountry[0].Name == 'Canada' || primaryCountry[0].Name == 'Germany' || primaryCountry[0].Name == 'United States' || primaryCountry[0].Name == 'United Kingdom')) {
        let memberFirm: any;
        if (primaryCountry[0].Name == 'Australia') {
          memberFirm = 2243;
        } else if (primaryCountry[0].Name == 'Canada') {
          memberFirm = 2348;
        } else if (primaryCountry[0].Name == 'Germany') {
          memberFirm = 2470;
        } else if (primaryCountry[0].Name == 'United States') {
          memberFirm = 2768;
        } else if (primaryCountry[0].Name == 'United Kingdom') {
          memberFirm = 2494;
        } else {
          memberFirm = 0
        }
        GetLocalMemberFirmTree(memberFirm);
      }
    }
    if (!checkedCountryRadioValue || checkedCountryRadioValue == null) {
      setCheckedCountryRadioValue(countryObjlist[0]);
    } else if (checkedCountrytreevalue && checkedCountrytreevalue.length == 1) {
      setCheckedCountryRadioValue(countryObjlist[0]);
    }
    setCountryModalView(!CountryModalView);
    if (checkedCountrytreevalue && checkedCountrytreevalue.length == 0) {
      GetLocalMemberFirmTree(0);
    }
  };


  const clientQualView = () => {
    if (selectedContent && selectedContent.length > 0) {
      let selectedContentTypeName = selectedContent[0]?.name.includes("->")
        ? selectedContent[0].name.split("->")[1]
        : selectedContent[0].name;
      if (
        selectedContentTypeName == "Proposals" ||
        selectedContentTypeName == "Deliverables" ||
        selectedContentTypeName == "Client Stories" ||
        selectedContentTypeName == "Sales and Marketing" ||
        selectedContentTypeName == "CXO Presentations"
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    clientQualView();
  }, [selectedContent]);

  const toggleContentType = () => {
    setContentModalView(true);
  };

  const PublishInstruction = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newText: string | undefined
  ) => {
    props.AdditionalFormData.publishInstructions = newText;
    setPublishingInstructiontext(newText)
  };

  const contentTypeCancel = () => {
    if (selectedContent.length > 0) {
      if (selectedContent[0].name.includes("->")) {
        setCheckedTreeValues([selectedContent[0].name.split("->")[1]]);
      } else {
        setCheckedTreeValues([selectedContent[0].name]);
      }
    } else {
      setCheckedTreeValues([]);
    }
  };

  // const countryCancelClick = () => {
  //   if (selctedCountryValue.length > 0) {
  //     setcheckedCountryValues([selctedCountryValue[0].name]);
  //   } else {
  //     setcheckedCountryValues([]);
  //   }
  // };

  const businessCancelClick = () => {
    let selectedlist: any[] = checkedBusinessValues.map((item) => {
      return item.Id;
    });
    setCheckedBusinessTreeValue(selectedlist);
  };

  const businessLocalCancelClick = () => {
    let selectedlist: any[] = checkedBusinessLocalValues.map((item) => {
      return item.Name;
    });
    setCheckedBusinessLocalTreeValue(selectedlist);
  };

  const industryCancelClick = () => {
    let selectedlist: any[] = checkedIndustryValues.map((item) => {
      return item.Id;
    });
    setCheckedIndustryTreeValue(selectedlist);
  };

  const countryCancelClick = () => {
    let selectedlist: any[] = checkedCountryValues.map((item) => {
      return item.Id;
    });
    setCheckedCountryTreeValue(selectedlist);
    let countryData: any = countrylist.filter((country: any) => { return country.Id == selectedlist[0] });
    if (checkedCountryRadioValue == null && countryData && countryData.length > 0) {
      let obj = {
        Name: countryData.Name,
        Id: countryData.Id
      }
      setCheckedCountryRadioValue(obj);
    }
  };

  const removeBusinessItem = (index: number, item: any) => {
    const newbussinesslist = checkedBusinessValues.filter((item, i) => {
      return i != index;
    });

    let selectedlist: any[] = newbussinesslist.map((item) => {
      return item.Id;
    });
    setCheckedBusinessTreeValue(selectedlist);
    if (item.Id == checkedBusinessRadioValue.Id) {
      if (newbussinesslist && newbussinesslist.length > 0) {
        setCheckedBusinessRadioValue(newbussinesslist[0]);
        props.AdditionalFormData.primaryClientServiceId = newbussinesslist[0].Id;
      }
      else {
        setCheckedBusinessRadioValue(null);
        props.AdditionalFormData.primaryClientServiceId = null;
      }
    }
    setcheckedBusinessValues(newbussinesslist);
  };

  const removeBusinessLocalItem = (index: number, item: any) => {
    const newbussinesslocallist = checkedBusinessLocalValues.filter(
      (item, i) => {
        return i != index;
      }
    );

    let selectedlist: any[] = newbussinesslocallist.map((item) => {
      return item.Name;
    });
    setCheckedBusinessLocalTreeValue(selectedlist);
    if (item.Id == checkedBusinessLocalRadioValue.Id) {
      if (newbussinesslocallist && newbussinesslocallist.length > 0) {
        setCheckedBusinessLocalRadioValue(newbussinesslocallist[0]);
        props.AdditionalFormData.primaryLocalClientServiceId = newbussinesslocallist[0].Id;
      }
      else {
        setCheckedBusinessLocalRadioValue(null);
        props.AdditionalFormData.primaryLocalClientServiceId = null;
      }
    }
    setcheckedBusinessLocalValues(newbussinesslocallist);
  };

  const removeIndustryItem = (index: number, item: any) => {
    const newindustrylist = checkedIndustryValues.filter((item, i) => {
      return i != index;
    });
    let selectedlist: any[] = newindustrylist.map((item) => {
      return item.Id;
    });
    setCheckedIndustryTreeValue(selectedlist);
    if (item.Id == checkedIndustryRadioValue.Id) {
      if (newindustrylist && newindustrylist.length > 0) {
        setCheckedIndustryRadioValue(newindustrylist[0]);
        props.AdditionalFormData.primaryIndustryId = newindustrylist[0].Id;
      }
      else {
        setCheckedIndustryRadioValue(null);
        props.AdditionalFormData.primaryIndustryId = null;
      }
    }
    setcheckedIndustryValues(newindustrylist);


  };

  const [currentRemovedCountry, setCurrentRemovedCountry] = useState<{ Name: string, Id: number }[]>([]);

  const removeCountryItem = (index: number, item: any) => {
    let countryRemoved: any = countrylist?.filter(
      (e: { Name?: string; Id?: number }) => {
        if (e.Id == item.Id) {
          return e;
        }
      }
    );
    if (!hideBusinessLocal && checkedBusinessLocalValues.length > 0 && (countryRemoved && countryRemoved.length > 0 && (countryRemoved[0].Name == 'Australia' || countryRemoved[0].Name == 'Canada' || countryRemoved[0].Name == 'Germany' || countryRemoved[0].Name == 'United States' || countryRemoved[0].Name == 'United Kingdom')) && (checkedCountryRadioValue != null && checkedCountryRadioValue.Id == countryRemoved[0].Id)) {
      setCurrentRemovedCountry([item]);
      toggleHideDialogCountry();
      return;
    } else {
      setCurrentRemovedCountry([]);
    }
    const newcountrylist = checkedCountryValues.filter((item, i) => {
      return i != index;
    });
    let selectedlist: any[] = newcountrylist.map((item) => {
      return item.Id;
    });
    setCheckedCountryTreeValue(selectedlist);
    if (item.Id == checkedCountryRadioValue.Id) {
      if (newcountrylist && newcountrylist.length > 0) {
        setCheckedCountryRadioValue(newcountrylist[0]);
        props.AdditionalFormData.countryofOriginId = newcountrylist[0].Id;
      }
      else {
        setCheckedCountryRadioValue(null);
        props.AdditionalFormData.countryofOriginId = null;
      }
    }
    setcheckedCountryValues(newcountrylist);

    if (newcountrylist.length == 0) {
      GetLocalMemberFirmTree(0)
    }


  };

  const removeCountryOfOriginValues = () => {
    const newcountrylist = checkedCountryValues.filter((item, i) => {
      return item.Id != currentRemovedCountry[0].Id;
    });
    let selectedlist: any[] = newcountrylist.map((item) => {
      return item.Id;
    });

    setCheckedCountryTreeValue(selectedlist);
    if (currentRemovedCountry[0].Id == checkedCountryRadioValue.Id) {
      if (newcountrylist && newcountrylist.length > 0) {
        setCheckedCountryRadioValue(newcountrylist[0]);
        props.AdditionalFormData.countryofOriginId = newcountrylist[0].Id;
      }
      else {
        setCheckedCountryRadioValue(null);
        props.AdditionalFormData.countryofOriginId = null;
      }
    }
    if (newcountrylist && newcountrylist.length > 0) {
      let primaryCountry: any = countrylist?.filter(
        (e: { Name?: string; Id?: number }) => {
          if (e.Id == newcountrylist[0].Id) {
            return e;
          }
        }
      );
      if (primaryCountry && primaryCountry.length > 0) {
        if (primaryCountry[0].Level == 3 && (primaryCountry[0].Name == 'Australia' || primaryCountry[0].Name == 'Canada' || primaryCountry[0].Name == 'Germany' || primaryCountry[0].Name == 'United States' || primaryCountry[0].Name == 'United Kingdom')) {
          let memberFirm: any;
          if (primaryCountry[0].Name == 'Australia') {
            memberFirm = 2243;
          } else if (primaryCountry[0].Name == 'Canada') {
            memberFirm = 2348;
          } else if (primaryCountry[0].Name == 'Germany') {
            memberFirm = 2470;
          } else if (primaryCountry[0].Name == 'United States') {
            memberFirm = 2768;
          } else if (primaryCountry[0].Name == 'United Kingdom') {
            memberFirm = 2494;
          } else {
            memberFirm = 0
          }
          GetLocalMemberFirmTree(memberFirm);
        }
      }
    } else {
      GetLocalMemberFirmTree(0);
    }
    setcheckedCountryValues(newcountrylist);
    toggleHideDialogCountry();
  }

  props.AdditionalFormData.primaryClientServiceId =
    checkedBusinessRadioValue?.Id;
  props.AdditionalFormData.primaryLocalClientServiceId =
    checkedBusinessLocalRadioValue?.Id;
  props.AdditionalFormData.primaryIndustryId = checkedIndustryRadioValue?.Id;
  props.AdditionalFormData.countryofOriginId = checkedCountryRadioValue?.Id;

  if (checkedBusinessValues.length > 0) {
    let businessIdArr: any[] = checkedBusinessValues.filter((item) => {
      if (checkedBusinessRadioValue) {
        if (item.Id != checkedBusinessRadioValue.Id) {
          return item;
        }
      }
    });
    businessIdArr = businessIdArr.map((item) => {
      return item.Id;
    });
    props.AdditionalFormData.secondaryClientServiceId = businessIdArr;
  }

  if (checkedBusinessLocalValues.length > 0) {
    let businessLocalIdArr: any[] = checkedBusinessLocalValues.filter(
      (item) => {
        if (checkedBusinessLocalRadioValue) {
          if (item.Id != checkedBusinessLocalRadioValue.Id) {
            return item;
          }
        }
      }
    );
    businessLocalIdArr = businessLocalIdArr.map((item) => {
      return item.Id;
    });
    props.AdditionalFormData.secondaryLocalClientServiceId = businessLocalIdArr;
  }

  if (checkedIndustryValues.length > 0) {
    let industryIdArr: any[] = checkedIndustryValues.filter((item) => {
      if (checkedIndustryRadioValue) {
        if (item.Id != checkedIndustryRadioValue.Id) {
          return item;
        }
      }
    });
    industryIdArr = industryIdArr.map((item) => {
      return item.Id;
    });
    props.AdditionalFormData.secondaryIndustryId = industryIdArr;
  }

  if (checkedCountryValues.length > 0) {
    let countryIdArr: any[] = checkedCountryValues.filter((item) => {
      if (checkedCountryRadioValue) {
        if (item.Id != checkedCountryRadioValue.Id) {
          return item;
        }
      }
    });
    countryIdArr = countryIdArr.map((item) => {
      return item.Id;
    });
    props.AdditionalFormData.secondaryCountryId = countryIdArr;
  }

  const addNewClient = async (client: ITag[] | undefined) => {
    setRelatedQualData([]);
    setSelectedQual([]);
    setSelectedClient(client);
    props.AdditionalFormData.qualId = null;
    props.AdditionalFormData.qualName = null;
    //const newClient = client ? client[0].name : "";
    // setNewClient(client ? client[0].name : "")
    props.AdditionalFormData.newClient = client;
    // let addNewClient;
    // if (newClient != "") {
    //   addNewClient = await _sharedService.AddNewClient(newClient, userData);
    //   if (addNewClient) {
    //     props.AdditionalFormData.clientName = addNewClient[0].clientName;
    //     props.AdditionalFormData.clientId = addNewClient[0].clientId;
    //   }
    // }
  };

  const saveClientChange = async (client: any[] | undefined) => {
    setRelatedQualData([]);
    setSelectedQual([]);
    props.AdditionalFormData.qualId = null;
    props.AdditionalFormData.qualName = null;
    setSelectedClient(client);
    if (client && client.length == 0) {
      setSelectedQual([]);
      setSelectedQualValue([]);
      props.AdditionalFormData.clientId = null;
      props.AdditionalFormData.clientName = null;
      props.AdditionalFormData.newClient = null;
      setIsLoading(false);
    }
    else if (client && client.length > 0 && client[0]?.key != "") {
      setIsLoading(true);
      let associatedQual = await _sharedService.GetRelatedQualData(
        client[0].key
      );
      setRelatedQualData(associatedQual);
      let arr: any = [];
      associatedQual.map((qual: { qualId: number; qualName: string; clientConfidentiality: number; status: string; startDate: any; endDate: any }) => {
        if (qual?.clientConfidentiality != 2 && qual.status == "Published") {
          let obj = {
            key: qual.qualName,
            id: qual.qualId,
            name: qual.qualName + " [Start Date: " + dateFormat(qual.startDate) + " | End Date: " + dateFormat(qual.endDate) + "]",
          };
          arr.push(obj);
        }
      });
      setSelectedQual(arr);
      props.AdditionalFormData.clientId = client[0].key;
      props.AdditionalFormData.clientName = client[0].name;
      props.AdditionalFormData.newClient = null;
      setIsLoading(false);
    }
  };

  const dateFormat = (DateData: Date) => {
    const moment = require('moment');
    let strdate = moment.utc(DateData).local().format('D MMM YYYY');
    return strdate;
  };


  const getRelatedQualData = async (clientId: number | string) => {
    let associatedQual = await _sharedService.GetRelatedQualData(
      clientId
    );
    let arr: any = [];
    associatedQual.map((qual: { qualId: number; qualName: string; clientConfidentiality: number; status: string; startDate: any; endDate: any }) => {
      if (qual) {
        if (qual?.clientConfidentiality != 2 && qual?.status == "Published") {
          let obj = {
            key: qual.qualName,
            id: qual.qualId,
            name: qual.qualName + " [Start Date: " + dateFormat(qual.startDate) + " | End Date: " + dateFormat(qual.endDate) + "]",
            confidentiality: qual.clientConfidentiality
          };
          arr.push(obj);
        }

      }
    });
    setSelectedQual(arr);
    setIsLoading(false);
  };

  const saveQualsData = (items: any) => {
    setSelectedQualValue(items);
    if (items && items.length > 0) {
      props.AdditionalFormData.qualId = items[0].id;
      props.AdditionalFormData.qualName = items[0].name;
    } else {
      if (selectedClient && selectedClient.length > 0) {
        setIsLoading(true);
        let currentClientId = selectedClient[0].key;
        getRelatedQualData(currentClientId)
      }
      props.AdditionalFormData.qualId = null;
      props.AdditionalFormData.qualName = null;
    }
  }

  const setFromDateState = (value: any) => {
    setSelectedDate(value);
  };

  const setBuinessSelection = (items: any) => {
    let currentData = checkedBusinessValues.slice()
    let currentTreeData = checkedbusinesstreevalue.slice();
    var checkeditems = businessList?.filter(
      (e: { Name?: string; Id?: number }) => {
        if (e.Id == items[0].id) {
          return e;
        }
      }
    );
    if (checkeditems.length > 0) {
      if (currentData.length > 0) {
        var existing = currentData.find(el => el.Id === checkeditems[0].Id);
        if (!existing) {
          let data = {
            Name: checkeditems[0].FullPath,
            Id: checkeditems[0].Id,
          };
          currentData.push(data);
          currentTreeData.push(checkeditems[0].Id);
        }
      } else {
        let data = {
          Name: checkeditems[0].FullPath,
          Id: checkeditems[0].Id,
        };
        currentData.push(data);
        currentTreeData.push(checkeditems[0].Id);
      }
    }
    if (!checkedBusinessRadioValue || checkedBusinessRadioValue == null) {
      setCheckedBusinessRadioValue(currentData[0]);
    }
    setcheckedBusinessValues(currentData);
    setCheckedBusinessTreeValue(currentTreeData);
  }

  const setLocalBuinessSelection = (items: any) => {
    let currentData = checkedBusinessLocalValues.slice()
    let currentTreeData = checkedbusinesslocaltreevalue.slice();
    var checkeditems = businessLocalList?.filter(
      (e: { Name?: string; Id?: number }) => {
        if (e.Id == items[0].id) {
          return e;
        }
      }
    );
    if (checkeditems.length > 0) {
      if (currentData.length > 0) {
        var existing = currentData.find(el => el.Id === checkeditems[0].Id);
        if (!existing) {
          let data = {
            Name: checkeditems[0].FullPath,
            Id: checkeditems[0].Id,
          };
          currentData.push(data);
          currentTreeData.push(checkeditems[0].Id);
        }
      } else {
        let data = {
          Name: checkeditems[0].FullPath,
          Id: checkeditems[0].Id,
        };
        currentData.push(data);
        currentTreeData.push(checkeditems[0].Id);
      }
    }
    if (!checkedBusinessLocalRadioValue || checkedBusinessLocalRadioValue == null) {
      setCheckedBusinessLocalRadioValue(currentData[0]);
    }
    setcheckedBusinessLocalValues(currentData);
    setCheckedBusinessLocalTreeValue(currentTreeData);
  }

  const setIndustrySelection = (items: any) => {
    let currentData = checkedIndustryValues.slice()
    let currentTreeData = checkedindustrytreevalue.slice();
    var checkeditems = industryList?.filter(
      (e: { Name?: string; Id?: number }) => {
        if (e.Id == items[0].id) {
          return e;
        }
      }
    );
    if (checkeditems.length > 0) {
      if (currentData.length > 0) {
        var existing = currentData.find(el => el.Id === checkeditems[0].Id);
        if (!existing) {
          let data = {
            Name: checkeditems[0].FullPath,
            Id: checkeditems[0].Id,
          };
          currentData.push(data);
          currentTreeData.push(checkeditems[0].Id);
        }
      } else {
        let data = {
          Name: checkeditems[0].FullPath,
          Id: checkeditems[0].Id,
        };
        currentData.push(data);
        currentTreeData.push(checkeditems[0].Id);
      }
    }
    if (!checkedIndustryRadioValue || checkedIndustryRadioValue == null) {
      setCheckedIndustryRadioValue(currentData[0]);
    }
    setcheckedIndustryValues(currentData);
    setCheckedIndustryTreeValue(currentTreeData);
  }

  const setCountrySelection = (items: any) => {
    let currentData = checkedCountryValues.slice()
    let currentTreeData = checkedCountrytreevalue.slice();
    var checkeditems: any = countrylist?.filter(
      (e: { Name?: string; Id?: number }) => {
        if (e.Id == items[0].id) {
          return e;
        }
      }
    );
    if (checkeditems.length > 0) {
      if (currentData.length > 0) {
        var existing = currentData.find(el => el.Id === checkeditems[0].Id);
        if (!existing) {
          let data = {
            Name: setCountryHierarcy(checkeditems[0]),
            Id: checkeditems[0].Id,
          };
          currentData.push(data);
          currentTreeData.push(checkeditems[0].Id);
        }
      } else {
        let data = {
          Name: setCountryHierarcy(checkeditems[0]),
          Id: checkeditems[0].Id,
        };
        currentData.push(data);
        currentTreeData.push(checkeditems[0].Id);
      }
    }
    if (!checkedCountryRadioValue || checkedCountryRadioValue == null) {
      setCheckedCountryRadioValue(currentData[0]);
    }
    setcheckedCountryValues(currentData);
    setCheckedCountryTreeValue(currentTreeData);
  }

  useEffect(() => {
    if (checkedCountryRadioValue) {
      let selectedCountry: any = countrylist?.filter(
        (e: { Name?: string; Id?: number }) => {
          if (e.Id == checkedCountryRadioValue.Id) {
            return e;
          }
        }
      );
      if (selectedCountry[0].Level == 3 && (selectedCountry[0].Name == 'Australia' || selectedCountry[0].Name == 'Canada' || selectedCountry[0].Name == 'Germany' || selectedCountry[0].Name == 'United States' || selectedCountry[0].Name == 'United Kingdom')) {
        let memberFirm: any;
        if (selectedCountry[0].Name == 'Australia') {
          memberFirm = 2243;
        } else if (selectedCountry[0].Name == 'Canada') {
          memberFirm = 2348;
        } else if (selectedCountry[0].Name == 'Germany') {
          memberFirm = 2470;
        } else if (selectedCountry[0].Name == 'United States') {
          memberFirm = 2768;
        } else if (selectedCountry[0].Name == 'United Kingdom') {
          memberFirm = 2494;
        } else {
          memberFirm = 0
        }
        GetLocalMemberFirmTree(memberFirm);
      } else {
        GetLocalMemberFirmTree(0);
      }
    }
  }, [checkedCountryRadioValue]);

  const [currentSelectedPrimaryCountry, setCurrentSelectedPrimaryCountry] = useState<any>("");

  const changeCountryRadioValue = (item: any) => {
    setCurrentSelectedPrimaryCountry(item);
    if (hideBusinessLocal) {
      setCheckedCountryRadioValue(item)
    } else if (!hideBusinessLocal && checkedBusinessLocalValues.length > 0) {
      toggleHideDialogCountry();
    } else if (!hideBusinessLocal && checkedBusinessLocalValues.length == 0) {
      setCheckedCountryRadioValue(item)
    }
  }

  const onClickYes = () => {
    // setCheckedCountryRadioValue(currentSelectedPrimaryCountry);
    if (currentRemovedCountry && currentRemovedCountry.length > 0) {
      removeCountryOfOriginValues();
    } else {
      // setCheckedCountryRadioValue(checkedCountryValues[0]);
      setCheckedCountryRadioValue(currentSelectedPrimaryCountry);
      toggleHideDialogCountry();
      onCountryRadioValueChange();
    }
    setcheckedBusinessLocalValues([]);
    setCheckedBusinessLocalRadioValue("");
    setCheckedBusinessLocalTreeValue([]);
    props.AdditionalFormData.primaryLocalClientServiceId = null;
    props.AdditionalFormData.secondaryLocalClientServiceId = null;
  }

  const onCountryRadioValueChange = () => {
    if (checkedCountryRadioValue) {
      let selectedCountry: any = countrylist?.filter(
        (e: { Name?: string; Id?: number }) => {
          if (e.Id == checkedCountryRadioValue.Id) {
            return e;
          }
        }
      );
      if (selectedCountry[0].Level == 3 && (selectedCountry[0].Name == 'Australia' || selectedCountry[0].Name == 'Canada' || selectedCountry[0].Name == 'Germany' || selectedCountry[0].Name == 'United States' || selectedCountry[0].Name == 'United Kingdom')) {
        let memberFirm: any;
        if (selectedCountry[0].Name == 'Australia') {
          memberFirm = 2243;
        } else if (selectedCountry[0].Name == 'Canada') {
          memberFirm = 2348;
        } else if (selectedCountry[0].Name == 'Germany') {
          memberFirm = 2470;
        } else if (selectedCountry[0].Name == 'United States') {
          memberFirm = 2768;
        } else if (selectedCountry[0].Name == 'United Kingdom') {
          memberFirm = 2494;
        } else {
          memberFirm = 0
        }
        GetLocalMemberFirmTree(memberFirm);
      }
    }
  }

  const onClickNo = () => {
    toggleHideDialogCountry();
  }

  const incrementExpandCount = () => {
    let count = addtionalNCExpandCount + 1;
    setAddtionalNCExpandCount(count);
    setAuthorFullData(selectedAuthor)
    setContactFullData(selectedkeyContacts)
    setContributorFullData(selectedContributors)
    let defaultauthors: any = [];
    let defaultcontact: any = [];
    let defaultcontributor: any = [];
    if (count > 1) {
      if (props.AdditionalFormData.authorandContactsforDocuments) {
        props.AdditionalFormData.authorandContactsforDocuments.map((item) => {
          const data = {
            name: item.user.displayName,
            key: item.user.email,
            // isNonDeloitteUser: item.isExternalAuthor == true
          };
          if (item.peopleType === 1) {
            defaultauthors.push(data);
          } else if (item.peopleType === 2) {
            defaultcontact.push(data);
          } else if (item.peopleType === 3) {
            defaultcontributor.push(data);
          }
        });
      }
      setDefaultAuthor(defaultauthors)
      setDefaultContact(defaultcontact)
      setDefaultContributor(defaultcontributor)
    }
  }
  return (
    <>
      <div className="kx-nextgrn">
        <section className={styles.phase2}>
          <div className={styles.contributionfrom}>
            <button
              type="button"
              className={styles.collapsible}
              onClick={() => {
                incrementExpandCount()
                toggleHideAdditionalInfo();
              }}
            >
              {
                _sharedService.Data.Labels[
                "KXDocument_Additional Information_NewContribution"
                ]
              }
              <span className="expandinfo">
                <i
                  className={
                    hideAdditionalInfo
                      ? "icon-kx-chevron-down"
                      : "icon-kx-chevron-up"
                  }
                ></i>
              </span>
            </button>
            {hideAdditionalInfo ? (
              ""
            ) : (
              <div className={styles.content}>
                <div className={styles.maincard}>
                  <div className={styles.additionalinfo}>
                    <div
                      id="content-type" className={[styles.formfields, styles.contenttype].join(
                        " "
                      )}
                    >
                      <Label htmlFor="ContentType" className={styles.title}>
                        {
                          _sharedService.Data.Labels[
                          "KXDocument_Content type_NewContribution"
                          ]
                        }
                        {(selectedContent == undefined ||
                          selectedContent.length < 1 ||
                          selectedContent == null) && (
                            <span
                              className={styles.treeplus}
                              style={{ cursor: "pointer" }}
                            >
                              <i
                                onClick={toggleContentType}
                                className="icon-kx-plus"
                              ></i>
                            </span>
                          )}
                      </Label>
                      <CalloutBase
                        keyValue={
                          "KXDocuments_NewContribution_HelpText_Contenttype"
                        }
                        label={"Content Type"}
                      />
                      <PeoplePickerBase
                        dataList={contenttypelist}
                        itemlimit={1}
                        suggestionsHeaderText="Existing content type"
                        placeholder={
                          _sharedService.Data.Labels[
                          "KXDocuments_NewContribution_Placeholder_Contenttype"
                          ]
                        }
                        ContentType={true}
                        selectedItems={selectedContent}
                        onSelectedItemChange={setSelection}
                      />
                      {contenttypelist && contenttypelist.length > 0 && (
                        <CheckBoxTree
                          checkedTreeValues={checkedTreeValues}
                          onCheck={setCheckedValues}
                          modalView={contentModalView}
                          onmodalviewChange={() =>
                            setContentModalView(!contentModalView)
                          }
                          onCancelClick={contentTypeCancel}
                          onSaveChanges={onTreeValueSave}
                          multipleSelect={false}
                          tableID={
                            Constants.TableIdsForTree.ContentType.tableId
                          }
                          memberFirmId={
                            Constants.TableIdsForTree.ContentType.memberFirmId
                          }
                          treeType="ContentType"
                          heading={
                            "Select the content type. Please be as specific as possible."
                          }
                          flatArrayData={contenttypelist}
                          flatData={contenttypelist}
                        />
                      )}
                    </div>
                    {clientQualView() && (
                      <>
                        {!newClientView ? <div
                          className={[
                            styles.formfields,
                            styles.clientname,
                          ].join(" ")}
                        >
                          <Label htmlFor="ClientName" className={styles.title}>
                            {
                              _sharedService.Data.Labels[
                              "KXDocument_Client name_NewContribution"
                              ]
                            }
                          </Label>
                          <CalloutBase
                            keyValue={
                              "KXDocuments_NewContribution_HelpText_Client"
                            }
                            label={"Client"}
                          />
                          <PeoplePickerBase
                            dataList={[]}
                            itemlimit={1}
                            suggestionsHeaderText="Existing Client"
                            AddbtnText="Add Client"
                            placeholder={
                              _sharedService.Data.Labels[
                              "KXDocuments_NewContribution_Placeholder_Client"
                              ]
                            }
                            ContentType={false}
                            clientName={true}
                            //clientNameNC={true}
                            //setTryClient={setTryClient}
                            selectedItems={selectedClient}
                            onSelectedItemChange={saveClientChange}
                          // onNewClientAdd={(client) => addNewClient(client)}
                          />
                          <div>{_sharedService.Data.Labels["KXDocuments_NewContribution_AddNewClientText"]} <a onClick={() => { setNewClientView(true); saveClientChange([]) }} style={{ cursor: 'pointer' }}>New Client</a></div>
                        </div> :
                          <div
                            className={[
                              styles.formfields,
                              styles.clientname,
                            ].join(" ")}
                          >
                            <Label htmlFor="ClientName" className={styles.title}>
                              New Client
                            </Label>
                            <CalloutBase
                              keyValue={
                                "KXDocuments_NewContribution_HelpText_Client"
                              }
                              label={"Client"}
                            />
                            <PeoplePickerBase
                              dataList={[]}
                              itemlimit={1}
                              placeholder="Enter New Client"
                              ContentType={false}
                              //clientName={true}
                              clientNameNC={true}
                              setTryClient={setTryClient}
                              selectedItems={selectedClient}
                              onSelectedItemChange={saveClientChange}
                              onNewClientAdd={(client) => addNewClient(client)}
                            />
                            <div>{_sharedService.Data.Labels["KXDocuments_NewContribution_AddOldClientText"]} <a onClick={() => { setNewClientView(false); saveClientChange([]) }} style={{ cursor: 'pointer' }}>Existing Client</a></div>
                          </div>
                        }
                        <div
                          className={[
                            styles.formfields,
                            styles.relatedqual,
                          ].join(" ")}
                        >
                          <Label htmlFor="RelatedQual" className={styles.title}>
                            {
                              _sharedService.Data.Labels[
                              "KXDocument_Related Qual _NewContribution"
                              ]
                            }
                          </Label>
                          <CalloutBase
                            keyValue={
                              "KXDocuments_NewContribution_HelpText_RelatedQual"
                            }
                            label={"RelatedQual"}
                          />
                          {isLoading ? (
                            <ThemeProvider>
                              <Spinner className={styles.spinner} />
                            </ThemeProvider>
                          ) : (
                            ""
                          )}
                          <PeoplePickerBase
                            dataList={selectedQual}
                            itemlimit={1}
                            suggestionsHeaderText="Existing Related Quals"
                            placeholder={
                              _sharedService.Data.Labels[
                              "KXDocuments_NewContribution_Placeholder_RelatedQual"
                              ]
                            }
                            selectedItems={selectedQualValue}
                            onSelectedItemChange={saveQualsData}
                            ContentType={false}
                            quals={true}
                            disabled={
                              selectedClient &&
                                selectedClient.length > 0 &&
                                !isLoading
                                ? false
                                : true
                            }
                          />
                        </div>
                      </>
                    )}
                    <div style={{ display: "flex" }}>
                      <div id="author" className={[styles.formfield, styles.author].join(" ")}>
                        <Label htmlFor="Author" className={styles.title}>
                          {
                            _sharedService.Data.Labels[
                            "KXDocument_Authors_NewContribution"
                            ]
                          }
                        </Label>
                        <CalloutBase
                          keyValue={
                            "KXDocuments_NewContribution_HelpText_Authors"
                          }
                          label={"Author(s)"}
                        />
                        <PeoplePickerBase
                          dataList={[]}
                          suggestionsHeaderText="Existing Authors"
                          ContentType={false}
                          author={true}
                          placeholder={
                            _sharedService.Data.Labels[
                            "KXDocuments_NewContribution_Placeholder_Authors"
                            ]
                          }
                          // defaultValue={[
                          //   {
                          //     name: userdetails.title,
                          //     key: userdetails.kxp_email,
                          //   },
                          // ]}
                          authorInvalidInput={(value: string) => props.setAuthorInvalidText(value)}
                          addtionalNCExpandCount={addtionalNCExpandCount}
                          defaultValue={defaultAuthor}
                          reopenFlag={reopenFlag}
                          AuthorData={authorFullData}
                          // selectedItems={selectedAuthor}
                          onSelectedItemChange={setAuthor}
                          enableExternalAuthor={true}
                        />
                      </div>
                      <div id="contacts" className={[styles.formfield, styles.keycontact].join(" ")}>
                        <Label htmlFor="KeyContacts" className={styles.title}>
                          {
                            _sharedService.Data.Labels[
                            "KXDocument_Contacts_NewContribution"
                            ]
                          }
                        </Label>
                        <CalloutBase
                          keyValue={
                            "KXDocuments_NewContribution_HelpText_Contacts"
                          }
                          label={"Contact(s)"}
                        />
                        <PeoplePickerBase
                          dataList={[]}
                          suggestionsHeaderText="Existing Key Contacts"
                          ContentType={false}
                          keyContacts={true}
                          placeholder={
                            _sharedService.Data.Labels[
                            "KXDocuments_NewContribution_Placeholder_Contacts"
                            ]
                          }

                          addtionalNCExpandCount={addtionalNCExpandCount}
                          defaultValue={defaultContact}
                          reopenFlag={reopenFlag}
                          contactData={contactFullData}
                          // selectedItems={selectedkeyContacts}
                          onSelectedItemChange={setKeyContacts}
                          contactInvalidInput={(value: string) => props.setContactsInvalidText(value)}
                        />
                      </div>
                    </div>
                    <div
                      id="contributor" className={[styles.formfield, styles.author].join(
                        " "
                      )}
                    >
                      <Label htmlFor="KeyContacts" className={styles.title}>
                        {
                          "Contributor"
                        }
                      </Label>
                      <CalloutBase
                        keyValue={
                          "KXDocuments_NewContribution_HelpText_Contacts"
                        }
                        label={"Contributor(s)"}
                      />
                      <PeoplePickerBase
                        dataList={[]}
                        suggestionsHeaderText="Existing contributors"
                        ContentType={false}
                        contributor={true}
                        placeholder={
                          "Select Contributors"
                        }
                        addtionalNCExpandCount={addtionalNCExpandCount}
                        defaultValue={defaultContributor}
                        reopenFlag={reopenFlag}
                        contributorData={contributorFullData}
                        onSelectedItemChange={setContributors}
                        contactInvalidInput={(value: string) => props.setContactsInvalidText(value)}
                      />
                    </div>
                    {/* <div
                      className={[styles.formfield, styles.countryorigin].join(
                        " "
                      )}
                    >
                      <Label htmlFor="Country" className={styles.title}>
                        {
                          _sharedService.Data.Labels[
                            "KXDocument_Country of Origin_NewContribution"
                          ]
                        }
                      </Label>
                      <CalloutBase
                        keyValue={
                          "KXDocuments_NewContribution_HelpText_CountryofOrigin"
                        }
                        label={"Country of Origin"}
                      />
                      <PeoplePickerBase
                        dataList={countrylist}
                        itemlimit={1}
                        suggestionsHeaderText="Existing CountryOfOrigin"
                        placeholder={
                          _sharedService.Data.Labels[
                            "KXDocuments_NewContribution_Placeholder_CountryofOrigin"
                          ]
                        }
                        ContentType={false}
                        countryOfOrigin={true}
                        selectedItems={selctedCountryValue}
                        onSelectedItemChange={setCountrySelection}
                      />
                      {countrylist && countrylist.length > 0 && (
                        <CheckBoxTree
                          checkedTreeValues={checkedCountryValues}
                          onCheck={setCountryCheckedValues}
                          modalView={countryModalView}
                          onmodalviewChange={() =>
                            setCountryModalView(!countryModalView)
                          }
                          onCancelClick={countryCancelClick}
                          onSaveChanges={onCountryTreeValueSave}
                          multipleSelect={false}
                          tableID={
                            Constants.TableIdsForTree.CountryOfOrigin.tableId
                          }
                          memberFirmId={
                            Constants.TableIdsForTree.CountryOfOrigin
                              .memberFirmId
                          }
                          treeType="CountryType"
                          heading={
                            "Select the country of origin. Please be as specific as possible."
                          }
                          flatArrayData={countrylist}
                          flatData={countrylist}
                        />
                      )}
                      {(selctedCountryValue == undefined ||
                        selctedCountryValue.length < 1 ||
                        selctedCountryValue == null) && (
                        <span
                          className="closemodel"
                          id="myBtn"
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            onClick={() => setCountryModalView(true)}
                            className="icon-kx-plus"
                          ></i>
                        </span>
                      )}
                    </div> */}
                    <div className={[styles.formfield, styles.contentdatelist].join(" ")}>
                      <Label htmlFor="ContentDate" className={styles.title}>
                        {
                          _sharedService.Data.Labels[
                          "KXDocument_Content Date_NewContribution"
                          ]
                        }
                      </Label>
                      <CalloutBase
                        keyValue={
                          "KXDocuments_NewContribution_HelpText_ContentDate"
                        }
                        label={"Content Date"}
                      />
                      <NewDatePickerBase
                        dateRangeType={DateRangeType.Day}
                        showGoToToday={true}
                        setSelectedDate={setFromDateState}
                        setFromShowCalendar={setShowFromDateCalendar}
                        setToShowCalendar={setShowCalender}
                        showCalender={showCalender}
                        selectedDate={selectedDate}
                        placeHolder={
                          _sharedService.Data.Labels[
                          "KXDocuments_NewContribution_Placeholder_Contentdate"
                          ]
                        }
                        dateInWords={true}
                        dateType="DD MMM YYYY"
                        className="newContributionDatePicker"
                        showClearButton={selectedDate ? true : false}
                        onClearBtnClick={() => setSelectedDate(undefined)}
                        clearBtnClassName={"clearDateBtn"}
                        maxDate={new Date()}
                      />
                    </div>

                    <div id="country-of-origin" className={styles.businessformfield}>
                      <Label htmlFor="Country" className={styles.labeltitle}>
                        <span>
                          {
                            _sharedService.Data.Labels[
                            "KXDocument_Country of Origin_NewContribution"
                            ]
                          }
                        </span>
                        <CalloutBase
                          keyValue={
                            "KXDocuments_NewContribution_HelpText_CountryofOrigin"
                          }
                          label={"Country of Origin"}
                        />
                        <span
                          className={styles.treeplus}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            onClick={() => setCountryModalView(true)}
                            className="icon-kx-plus"
                          ></i>
                        </span>
                      </Label>
                      <PeoplePickerBase
                        dataList={countrylist}
                        suggestionsHeaderText="Existing CountryOfOrigin"
                        placeholder={
                          _sharedService.Data.Labels[
                          "KXDocuments_NewContribution_Placeholder_CountryofOrigin"
                          ]
                        }
                        ContentType={false}
                        countryOfOrigin={true}
                        selectedItems={[]}
                        onSelectedItemChange={setCountrySelection}
                      />
                      {countrylist && countrylist.length > 0 && (
                        <CheckBoxTree
                          checkedTreeValues={checkedCountrytreevalue}
                          onCheck={setCountryCheckedValues}
                          modalView={CountryModalView}
                          onmodalviewChange={() =>
                            setCountryModalView(!CountryModalView)
                          }
                          onCancelClick={countryCancelClick}
                          onSaveChanges={onCountryTreeValueSave}
                          multipleSelect={true}
                          tableID={Constants.TableIdsForTree.CountryOfOrigin.tableId}
                          memberFirmId={
                            Constants.TableIdsForTree.CountryOfOrigin.memberFirmId
                          }
                          treeType="CountryType"
                          heading="Select the country of origin. Please be as specific as possible."
                          flatArrayData={countrylist}
                          flatData={countrylist}
                        />
                      )}

                      <TaxonomyTable
                        radioButtonGroupname="maingroupcountry"
                        // tableTitle={
                        //   _sharedService.Data.Labels[
                        //     "KXDocument_Industry subheading_NewContribution"
                        //   ]
                        // }
                        tableTitle={
                          _sharedService.Data.Labels[
                          "KXDocument_Country_of_Origin_subheading_NewContribution"
                          ]
                        }
                        checkedTaxonomyValue={checkedCountryValues}
                        checkedTaxonomyRadioValue={checkedCountryRadioValue}
                        removeTaxonomyItem={removeCountryItem}
                        setCheckedTaxonomyRadioValue={(item: any) =>
                          changeCountryRadioValue(item)
                        }
                      />
                    </div>

                    <div id="globalbusiness" className={styles.businessformfield}>
                      <Label htmlFor="Business" className={styles.labeltitle}>
                        <span>
                          {
                            _sharedService.Data.Labels[
                            "KXDocument_Services_NewContribution"
                            ]
                          }
                        </span>
                        <CalloutBase
                          keyValue={
                            "KXDocuments_NewContribution_HelpText_ServicesGlobal"
                          }
                          label={"Service(s) Global"}
                        />
                        <span
                          className={styles.treeplus}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            onClick={() => setBusinessModalView(true)}
                            className="icon-kx-plus"
                          ></i>
                        </span>
                      </Label>
                      <div className="businessformfield">
                        <PeoplePickerBase
                          dataList={businessList}
                          // itemlimit={1}
                          suggestionsHeaderText="Existing business type"
                          // placeholder={_sharedService.Data.Labels["KXDocuments_NewContribution_Placeholder_Business"]}
                          placeholder="Select Business"
                          ContentType={false}
                          selectedItems={[]}
                          onSelectedItemChange={setBuinessSelection}
                          clientServices={true}
                        />
                      </div>

                      {businessList && businessList.length > 0 && (
                        <CheckBoxTree
                          checkedTreeValues={checkedbusinesstreevalue}
                          onCheck={setBusinessCheckedValues}
                          modalView={businessModalView}
                          onmodalviewChange={() =>
                            setBusinessModalView(!businessModalView)
                          }
                          onCancelClick={businessCancelClick}
                          onSaveChanges={onBusinessTreeValueSave}
                          multipleSelect={true}
                          tableID={
                            Constants.TableIdsForTree.BusinessGlobal.tableId
                          }
                          memberFirmId={
                            Constants.TableIdsForTree.BusinessGlobal
                              .memberFirmId
                          }
                          treeType="BusinessType"
                          heading="Select the Business Global. Please be as specific as possible."
                          flatArrayData={businessList}
                          flatData={businessList}
                        />
                      )}
                      <TaxonomyTable
                        radioButtonGroupname="maingroupbusiness"
                        tableTitle={
                          _sharedService.Data.Labels[
                          "KXDocument_Service subheading_NewContribution"
                          ]
                        }
                        checkedTaxonomyValue={checkedBusinessValues}
                        checkedTaxonomyRadioValue={checkedBusinessRadioValue}
                        removeTaxonomyItem={removeBusinessItem}
                        setCheckedTaxonomyRadioValue={(item: any) =>
                          setCheckedBusinessRadioValue(item)
                        }
                      />
                    </div>
                    {hideBusinessLocal ? "" :
                      <div id="localbusiness" className={styles.businessformfield}>
                        <Label htmlFor="BusinessLocal" className={styles.labeltitle}>
                          {_sharedService.Data.Labels["KXDocument_ServicesLocal_NewContribution"]}
                          <CalloutBase
                            keyValue={"KXDocuments_NewContribution_HelpText_ServicesLocal"}
                            label={"Service(s) Local"}
                          />
                          <span className={styles.treeplus} style={{ cursor: "pointer" }}>
                            <i onClick={() => setBusinessLocalModalView(true)} className="icon-kx-plus"></i>
                          </span>
                        </Label>
                        <PeoplePickerBase
                          dataList={businessLocalList}
                          // itemlimit={1}
                          suggestionsHeaderText="Existing business type"
                          // placeholder={_sharedService.Data.Labels["KXDocuments_NewContribution_Placeholder_Business"]}
                          placeholder="Select Business Local"
                          ContentType={false}
                          selectedItems={[]}
                          onSelectedItemChange={setLocalBuinessSelection}
                          clientServices={true}
                        />
                        {businessLocalList && businessLocalList.length > 0 && (
                          <CheckBoxTree
                            checkedTreeValues={checkedbusinesslocaltreevalue}
                            onCheck={setBusinessLocalCheckedValues}
                            modalView={businessLocalModalView}
                            onmodalviewChange={() =>
                              setBusinessLocalModalView(!businessLocalModalView)
                            }
                            onCancelClick={businessLocalCancelClick}
                            onSaveChanges={onBusinessLocalTreeValueSave}
                            multipleSelect={true}
                            tableID={businessLocalTableId}
                            memberFirmId={businessLocalMFId}
                            treeType="BusinessLocalTypeNC"
                            heading="Select the Business Local. Please be as specific as possible."
                            flatArrayData={businessLocalList}
                            flatData={businessLocalList}
                          />
                        )}

                        <TaxonomyTable
                          radioButtonGroupname="maingroupbusinesslocal"
                          tableTitle={
                            _sharedService.Data.Labels[
                            "KXDocument_ServiceLocal subheading_NewContribution"
                            ]
                          }
                          checkedTaxonomyValue={checkedBusinessLocalValues}
                          checkedTaxonomyRadioValue={
                            checkedBusinessLocalRadioValue
                          }
                          removeTaxonomyItem={removeBusinessLocalItem}
                          setCheckedTaxonomyRadioValue={(item: any) =>
                            setCheckedBusinessLocalRadioValue(item)
                          }
                        />
                      </div>
                    }

                    <div id="industries" className={styles.businessformfield}>
                      <Label htmlFor="Industries" className={styles.labeltitle}>
                        <span>
                          {
                            _sharedService.Data.Labels[
                            "KXDocument_Industries_NewContribution"
                            ]
                          }
                        </span>
                        <CalloutBase
                          keyValue={
                            "KXDocuments_NewContribution_HelpText_Industries"
                          }
                          label={"Industries"}
                        />
                        <span
                          className={styles.treeplus}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            onClick={() => setIndustryModalView(true)}
                            className="icon-kx-plus"
                          ></i>
                        </span>
                      </Label>
                      <PeoplePickerBase
                        dataList={industryList}
                        // itemlimit={1}
                        suggestionsHeaderText="Existing Industries"
                        // placeholder={_sharedService.Data.Labels["KXDocuments_NewContribution_Placeholder_Business"]}
                        placeholder="Select Industry"
                        ContentType={false}
                        selectedItems={[]}
                        onSelectedItemChange={setIndustrySelection}
                        clientServices={true}
                      />
                      {industryList && industryList.length > 0 && (
                        <CheckBoxTree
                          checkedTreeValues={checkedindustrytreevalue}
                          onCheck={setIndustryCheckedValues}
                          modalView={IndustryModalView}
                          onmodalviewChange={() =>
                            setIndustryModalView(!IndustryModalView)
                          }
                          onCancelClick={industryCancelClick}
                          onSaveChanges={onIndustryTreeValueSave}
                          multipleSelect={true}
                          tableID={Constants.TableIdsForTree.Industry.tableId}
                          memberFirmId={
                            Constants.TableIdsForTree.Industry.memberFirmId
                          }
                          treeType="IndustryType"
                          heading="Select the Industry. Please be as specific as possible."
                          flatArrayData={industryList}
                          flatData={industryList}
                        />
                      )}

                      <TaxonomyTable
                        radioButtonGroupname="maingroupindustry"
                        tableTitle={
                          _sharedService.Data.Labels[
                          "KXDocument_Industry subheading_NewContribution"
                          ]
                        }
                        checkedTaxonomyValue={checkedIndustryValues}
                        checkedTaxonomyRadioValue={checkedIndustryRadioValue}
                        removeTaxonomyItem={removeIndustryItem}
                        setCheckedTaxonomyRadioValue={(item: any) =>
                          setCheckedIndustryRadioValue(item)
                        }
                      />
                    </div>
                    <div className={styles.formfields}>
                      <Label htmlFor="PublishNote" className={styles.title}>
                        {
                          _sharedService.Data.Labels[
                          "KXDocument_Publishing Instructions_NewContribution"
                          ]
                        }
                      </Label>
                      <CalloutBase
                        keyValue={
                          "KXDocuments_NewContribution_HelpText_PublishingInstructions"
                        }
                        label={"Publishing Notes"}
                      />
                      <TextField
                        placeholder={
                          _sharedService.Data.Labels[
                          "KXDocuments_NewContribution_Placeholder_Publishinginstructions"
                          ]
                        }
                        multiline
                        autoAdjustHeight
                        onChange={PublishInstruction}
                        value={publishingInstructiontext}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        <PopupDialog
          title={
            _sharedService.Data.Labels[
            "KXDocuments_NewContribution_BusinessLocal_Alert"
            ]
          }
          subText={
            _sharedService.Data.Labels[
            "KXDocuments_NewContribution_BusinessLocal_Alert_Subtext"
            ]
          }
          hideDialog={hideDialogCountry}
          toggleHideDialog={toggleHideDialogCountry}
          btn1text="Yes"
          onClickBtn1={onClickYes}
          btn2text="No"
          onClickBtn2={onClickNo}
          hideTopBtn={true}
        />
      </div>
    </>
  );
};

export default AdditionalNCForm;
