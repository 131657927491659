import { Dispatch } from "redux";
import { SharedService } from "../../services/SharedService";
import {
  CLEAR_QUICK_VIEW_DATA,
  CLEAR_QUICK_VIEW_LOADING,
  GET_QUICK_VIEW_RESULTS_ERROR,
  GET_QUICK_VIEW_RESULTS_REQUEST,
  GET_QUICK_VIEW_RESULTS_SUCCESS,
  IClearQuickViewData,
  IClearQuickViewLoading,
  IGetQuickViewResultsErrorAction,
  IGetQuickViewResultsRequestAction,
  IGetQuickViewResultsSuccessAction,
  IResetQuickViewResultsErrorAction,
  RESET_QUICK_VIEW_RESULTS_ERROR,
} from "../types";
import {
  IQuickViewResultRequestObj,
  IQuickViewResultResponseObj,
} from "../../models/KxPromptModels";

export type DataActionTypes =
  | IGetQuickViewResultsRequestAction
  | IGetQuickViewResultsSuccessAction
  | IGetQuickViewResultsErrorAction
  | IResetQuickViewResultsErrorAction
  | IClearQuickViewData
  | IClearQuickViewLoading;

const getQuickViewResultsRequest = (): IGetQuickViewResultsRequestAction => ({
  type: GET_QUICK_VIEW_RESULTS_REQUEST,
});

const getQuickViewResultsSuccess = (
  data: IQuickViewResultResponseObj
): IGetQuickViewResultsSuccessAction => ({
  type: GET_QUICK_VIEW_RESULTS_SUCCESS,
  payload: data,
});

const getQuickViewResultsError = (
  data: string
): IGetQuickViewResultsErrorAction => ({
  type: GET_QUICK_VIEW_RESULTS_ERROR,
  payload: data,
});

export const resetQuickViewResultsError =
  (): IResetQuickViewResultsErrorAction => ({
    type: RESET_QUICK_VIEW_RESULTS_ERROR,
  });

export const clearQuickViewData = (): IClearQuickViewData => ({
  type: CLEAR_QUICK_VIEW_DATA,
});
export const clearQuickViewLoading = (): IClearQuickViewLoading => ({
  type: CLEAR_QUICK_VIEW_LOADING,
});

export const getQuickViewResponse = (
  payloadData: IQuickViewResultRequestObj
) => {
  return async (dispatch: Dispatch<DataActionTypes>) => {
    dispatch(getQuickViewResultsRequest());

    try {
      const _sharedService = SharedService.GetInstance();
      const data = await _sharedService.getQuickViewData(payloadData);
      if (data !== null && data !== undefined && !(data instanceof Error)) {
        dispatch(getQuickViewResultsSuccess(data));
      } else {
        if (data) {
          dispatch(getQuickViewResultsError(data.message));
        }
      }
    } catch (error) {
      dispatch(getQuickViewResultsError(error as string));
    }
  };
};
