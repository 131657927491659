import { APPLYHASH_STATE } from '../types'
import { IApplyHashState } from '../../models/IShared'

const initialState: IApplyHashState = {
  fromApply: false,
  fromHash: false
}

export default function filtersReducer(state:IApplyHashState = initialState, action: any): any {
  switch (action.type) {
    case APPLYHASH_STATE:      
      return { 
        fromApply: action.payload.fromApply,
        fromHash: action.payload.fromHash
      }
    default:
      return state
  }
}