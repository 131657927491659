import React from 'react';
import { TooltipHost, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { useId } from '@uifabric/react-hooks';
import './tooltipBase.scss';

export interface TooltipBaseProps {
    content: any,
    isHidden?: boolean
    wrapperClassName?: string
    directionalHint?: DirectionalHint
}
export const TooltipBase: React.FunctionComponent<TooltipBaseProps> = props => {
    const tooltipId = useId('tooltip');
    return(
        !props.isHidden  ? 
        <TooltipHost 
            className={"tooltip1"}
            tooltipProps={{className: props.wrapperClassName ? props.wrapperClassName : ''}}
            id={tooltipId}
            content={props.content}
            directionalHint={props.directionalHint ? props.directionalHint : DirectionalHint.bottomCenter}
        >
            {props.children}
        </TooltipHost>
        :<>
         {props.children}
        </>
    );
}