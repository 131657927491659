import React, { createContext, useContext, useState } from "react";

interface DisableAfterModifySelectedContextType {
  disableResultsOnModifySelected: boolean;
  setDisableResultsOnModifySelected: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const DisableAfterModifySelectedContext =
  createContext<DisableAfterModifySelectedContextType>({
    disableResultsOnModifySelected: false,
    setDisableResultsOnModifySelected: () => {},
  });

export const DisableResultsOnModifySelectedProvider: React.FC = ({
  children,
}) => {
  const [disableResultsOnModifySelected, setDisableResultsOnModifySelected] =
    useState<boolean>(false);

  return (
    <DisableAfterModifySelectedContext.Provider
      value={{
        disableResultsOnModifySelected,
        setDisableResultsOnModifySelected,
      }}
    >
      {children}
    </DisableAfterModifySelectedContext.Provider>
  );
};

export const useDisableResultsOnModifySelected =
  (): DisableAfterModifySelectedContextType => {
    const context = useContext(DisableAfterModifySelectedContext);
    if (!context) {
      throw new Error(
        "useDisableResultsOnModifySelected must be used within a DisableResultsOnModifySelectedProvider"
      );
    }
    return context;
  };
