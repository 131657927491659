import {
  IActionButton,
  IMetaDataCards,
  CardType,
  Tag,
  TitleCard,
  OverflowItemsData,
  IPractice,
  IPaginate,
  IDrafts,
  hightLightTagsSchema,
} from "./IShared";
import { CommonFunctions } from "../helpers/CommonFunctions";
import { isEmpty } from "lodash";
import { Constants } from "../helpers/Constants";

export class PersonalCollection {
  public ApplicationType: string;
  public PersonalCategoryTitle?: string;
  public PersonalCategoryId?: number;
  public LinkToItem: string;
  public TitleCollection: string;
  public SubTitle: string;
  public ImgUrl: string;
  public SourceId: string;
  public SourceContentType: string;
  constructor(
    applicationType: string,
    PersonalCategoryTitle: string,
    LinkToItem: string,
    TitleCollection: string,
    SubTitle: string,
    ImgUrl: string,
    SourceId?: string,
    SourceContentType?: string,
    PersonalCategoryId?: number
  ) {
    this.ApplicationType = applicationType;
    this.PersonalCategoryId = PersonalCategoryId ? PersonalCategoryId : 1;
    this.PersonalCategoryTitle = PersonalCategoryTitle;
    this.LinkToItem = LinkToItem;
    this.TitleCollection = TitleCollection;
    this.SubTitle = SubTitle;
    this.ImgUrl = ImgUrl;
    this.SourceId = SourceId ? SourceId : "";
    this.SourceContentType = SourceContentType ? SourceContentType : "";
  }
}

export class Follower {
  public SourceContentType?: string;
  public SourceId?: string;
  public Email?: string;
  constructor(SourceId?: string, SourceContentType?: string, Email?: string) {
    this.SourceId = SourceId;
    this.SourceContentType = SourceContentType;
    this.Email = Email;
  }
}
export class IFeaturedItem {
  Title: string;
  Img: string;
  Abstract: string;
  ID: string;
  RelatedLink: string;
  Type: string;
  Created: string;
  Modified: string;
  IfExternal: boolean;
  internalcontenttype: string;
  showBadge: boolean;
  constructor(
    Title: string,
    Img: string,
    Abstract: string,
    ID: string,
    RelatedLink: string,
    Type: string,
    Created: string,
    Modified: string,
    IfExternal: boolean,
    internalcontenttype: string,
    showBadge: boolean
  ) {
    this.Title = Title;
    this.Img = Img;
    this.Abstract = Abstract;
    this.ID = ID;
    this.RelatedLink = RelatedLink;
    this.Type = Type;
    this.Created = Created;
    this.Modified = Modified;
    this.IfExternal = IfExternal;
    this.internalcontenttype = internalcontenttype;
    this.showBadge = showBadge;
  }
}

export class IResearchToolsItem {
  title: string;
  image: string;
  textMain: string;
  icon: boolean;
  geography: string;
  link: string;
  constructor(
    title: string,
    image: string,
    textMain: string,
    geography: string,
    link: string,
    icon: boolean = true
  ) {
    this.title = title;
    this.image = image;
    this.textMain = textMain;
    this.icon = icon;
    this.geography = geography;
    this.link = link;
  }
}

export class UserAlertItem {
  public AlertId: number;
  public Body?: string;
  constructor(AlertId: number) {
    this.AlertId = AlertId;
  }
}

export class ItemGeneral {
  metadataCards: IMetaDataCards;
  actionButtons: OverflowItemsData[];

  constructor(
    metadataCards: IMetaDataCards,
    actionButtons: OverflowItemsData[]
  ) {
    this.metadataCards = metadataCards;
    this.actionButtons = actionButtons;
  }
}
export class QuickViewModel {
  metadataQuickView: any;
  actionButtons: IActionButton[];

  constructor(metadataCards: any, actionButtons: IActionButton[]) {
    this.metadataQuickView = metadataCards;
    this.actionButtons = actionButtons;
  }
}

export class CardData implements ICollectionCardData {
  key: number;
  appId: string;
  type: CardType;
  ctId: string;
  listTitle: string;
  editUrl: string;
  viewUrl: string;
  tag: Tag[];
  badge: string;
  image: string;
  title: TitleCard;
  subTitle: string;
  desc: string;
  otherContent: string;
  footer: string[];
  overflowItemsData: OverflowItemsData[];
  sourceId: string;
  linkToItem: string;
  topLeftLabel: string;
  ctName: string;
  ifPinCard: boolean;
  searchedTerm?: string;
  highlight?: any;
  imageData?: any;

  deleteHandler?: (itemId: number, listTitle: string) => void;
  openNewTab?: boolean;
  size?: string;
  isHorizontal?: boolean;
  allData?: any;
  IsRemoved?: boolean;
  geography?: string;
  displayArea?: string;
  position?: string;
  isQuickView?: boolean;

  constructor(
    key: number,
    appId: string,
    type: CardType,
    ctId: string,
    listTitle: string,
    editUrl: string,
    viewUrl: string,
    tag: Tag[],
    badge: string,
    image: string,
    title: TitleCard,
    subTitle: string,
    desc: string,
    otherContent: string,
    footer: string[],
    overflowItemsData: OverflowItemsData[],
    sourceId: string,
    linkToItem: string,
    topLeftLabel: string,
    ctName: string,
    ifPinCard: boolean,


    deleteHandler?: (itemId: number, listTitle: string) => void,
    openNewTab?: boolean,
    size?: string,
    isHorizontal?: boolean,
    allData?: any,
    IsRemoved?: boolean,
    geography?: string,
    displayArea?: string,
    position?: string,
    isQuickView?: boolean,

  ) {
    this.key = key;
    this.appId = appId;
    this.type = type;
    this.ctId = ctId;
    this.listTitle = listTitle;
    this.editUrl = editUrl;
    this.viewUrl = viewUrl;
    this.tag = tag;
    this.badge = badge;
    this.image = image;
    this.title = title;
    this.subTitle = subTitle;
    this.desc = desc;
    this.otherContent = otherContent;
    this.footer = footer;
    this.overflowItemsData = overflowItemsData;
    this.sourceId = sourceId;
    this.linkToItem = linkToItem;
    this.size = size;
    this.ctName = this.ctName = allData.img_Id ? Constants.Images : ctName;
    this.footer = footer;;
    this.ifPinCard = ifPinCard;
    this.deleteHandler = deleteHandler;
    this.openNewTab = openNewTab;
    this.isHorizontal = isHorizontal;
    this.allData = allData;
    this.topLeftLabel = topLeftLabel;
    this.IsRemoved = IsRemoved;
    this.geography = geography;
    this.displayArea = displayArea;
    this.position = position;
    this.isQuickView = isQuickView;
    this.searchedTerm = allData.searchedTerm;
    this.highlight = allData.highlight;

  }
}

export class PeopleCardData implements IPeopleCardData {
  key: number;
  type: CardType;
  image: string;
  title: TitleCard;
  subTitle: string;
  textFirst: string;
  textSecond: string;
  overflowItemsData: OverflowItemsData[];
  sourceId: string;
  ctName: string;
  footer: string[];
  email: string;
  isHorizontal: boolean;
  internalcontenttype: string;
  allData: any;
  ifPinCard?: boolean;
  linkToItem: string;
  displayArea?: string;
  position?: string;
  badge?: string;
  constructor(
    key: number,
    type: CardType,
    image: string,
    title: TitleCard,
    subTitle: string,
    textFirst: string,
    textSecond: string,
    overflowItemsData: OverflowItemsData[],
    sourceId: string,
    ctName: string,
    footer: any,
    email: string,
    isHorizontal: boolean,
    internalcontenttype: string,
    allData: any,
    linkToItem: string,
    displayArea?: string,
    position?: string,
    ifPinCard?: boolean,
    badge?: string
  ) {
    this.key = key;
    this.type = type;
    this.image = image;
    this.title = title;
    this.subTitle = subTitle;
    this.textFirst = textFirst;
    this.textSecond = textSecond;
    this.overflowItemsData = overflowItemsData;
    this.sourceId = sourceId;
    this.ctName = ctName;
    this.footer = footer;
    this.email = email;
    this.isHorizontal = isHorizontal;
    this.internalcontenttype = internalcontenttype;
    this.allData = allData;
    this.linkToItem = linkToItem;
    this.displayArea = displayArea;
    this.position = position;
    this.ifPinCard = ifPinCard;
    this.badge = badge;
  }
}

export class CardArchivedData {
  key: string;
  title: TitleCard;
  desc: string;
  IsRemoved?: boolean;
  constructor(
    key: string,
    title: TitleCard,
    desc: string,
    IsRemoved?: boolean
  ) {
    this.key = key;
    this.desc = desc;
    this.title = title;
    this.IsRemoved = IsRemoved;
  }
}
export class CardDataQuickView {
  key?: string;
  badges?: string[];
  image?: string;
  title?: string;
  notes?: string[];
  desc?: string;
  allData?: any[];
  constructor(
    key?: string,
    badges?: string[],
    image?: string,
    title?: string,
    notes?: string[],
    desc?: string,
    allData?: any[]
  ) {
    this.key = key;
    this.badges = badges;
    this.image = image;
    this.title = title;
    this.desc = desc;
    this.notes = notes;
    this.allData = allData;
  }
}

export class ICuratedCollection {
  key: string;
  imageUrl: string;
  title: string;
  id: number;
  linkToItem: string;
  desc: string;
  dkxGeography: string;
  topic: Array<string>;
  whyImportant: string;
  modified: string;
  isArchived: boolean;
  topBannerImage: any;
  ctId: string;
  followers?: number;
  constructor(
    key: string,
    imageUrl: string,
    title: string,
    linkToItem: string,
    desc: string,
    id: number,
    dkxGeography: string,
    topic: Array<string>,
    whyImportant: string,
    modified: string,
    isArchived: boolean,
    topBannerImage: any,
    ctId: string,
    followers?: number
  ) {
    this.key = key;
    this.imageUrl = imageUrl;
    this.title = title;
    this.linkToItem = linkToItem;
    this.desc = desc;
    this.id = id;
    this.dkxGeography = dkxGeography;
    this.topic = topic;
    this.whyImportant = whyImportant;
    this.modified = modified;
    this.isArchived = isArchived;
    this.topBannerImage = topBannerImage;
    this.ctId = ctId;
    this.followers = followers;
  }
}

export interface ICardSchema {
  id: number;
  appurl: string;
  source: string;
  apptype: string;
  appsubtype: string;
  RelatedLink: string;
  IsRemoved: boolean;
  kxe_title: string;
  kxa_businesstitle: string;
  clientservice: string;
  kxq_projectname: string;
  kxe_description: string;
  kxp_othername: string;
  kxp_pdicountrydesc: string;
  kxp_pdicitydesc: string;
  pdilevelsglobal_text: string;
  kxp_pdilevelsglobaltext: string;
  kxp_accountname: string;
  kxp_pdifunctionsglobaltext: string;
  kxp_pdisecondaryindustryglobaltext: string;
  kxp_email: string;
  geography: string;
  created: string;
  createdBy: string;
  modified: string;
  kxe_abstract: string;
  industry: string;
  kxe_practicetype: string;
  kxa_businessissue: string;
  Img: string;
  image: string;
  kxe_relatedlink: string;
  kxc_clientname: string;
  kxc_lastmodifieddate: string;
  kxc_lastrevieweddate: string;
  kxc_primaryglobalindustry: string;
  kxc_headquartercountry: string;
  kxc_globallcsp: string;
  kxq_businessissue: string;
  kxq_badge: string;
  kxq_projectenddate: string;
  kxq_viewableclientname: string;
  kxq_leadprojectmemberfirm: string;
  kxq_primaryglobalclientservice: string;
  kxq_projectstartdate: string;
  kxq_basicprojectsummary: string;
  kxq_externalqualuse: string;
  kxq_memberfirmsinvolved: string;
  kxq_primaryglobalindustry: string;
  kxq_teammemberinvolved: string;
  kxq_contributor: string;
  kxq_engagemantmanager: string;
  kxq_leadengagagementpartner: string;
  kxa_contentdate: string;
  kxa_abstract: string;
  kxa_primaryglobalservices: string;
  kxa_primaryglobalindustry: string;
  kxa_secondaryglobalindustry: string;
  kxa_kamcontributor: string;
  kxe_alliancepartner: string;
  kxe_certifiedsell: string;
  kxe_productmaturity: string;
  kxe_assettype: string;
  modifiedDate: Date;
  concepttags: any;
  topictags: any;
  contenttags: any;
  matchedQueries: string[];
  kxp_pdiempattributespreflastname: string;
  kxp_pdiempattributespreffirstname: string;
  img_attachmentfilename: string;
  img_fullurl: string;
  img_minicardlabel: string;
  img_imagetypes: string[];
  img_pageinattachment: number;
  img_parent: string;
  img_displayname: string;
  img_thumbnailurl: string;
  img_Id: string;
  //collection export
  filetype?: any[] | null;
  kxq_projectscopeapproach?: string;
  kxa_kamdescription?: string;
  kxc_clientdescription?: string;
  kxp_aboutme?: string;
  kxa_secondaryglobalservices?: string;
  kxq_secondaryglobalclientservice?: string;
  kxe_secondaryglobalbusiness?: string;
  description?: string;
}

export class CardSchema implements ICardSchema {
  id: number;
  source: string;
  title: string;
  titletext?: [];
  apptype: string;
  appid: string;
  appsubtype: string;
  appurl: string;
  appurl_nospecchar: string;
  badges: any;
  badgesDB: any;
  internalcontenttype: string;
  RelatedLink: string;
  IsRemoved: boolean;
  kxe_title: string;
  kxa_businesstitle: string;
  clientservice: string;
  kxq_projectname: string;
  kxe_description: string;
  kxp_othername: string;
  kxp_pdicountrydesc: string;
  kxp_pdicitydesc: string;
  pdilevelsglobal_text: string;
  kxp_pdilevelsglobaltext: string;
  kxp_accountname: string;
  kxp_pdifunctionsglobaltext: string;
  kxp_pdisecondaryindustryglobaltext: string;
  kxp_email: string;
  geography: string;
  created: string;
  createdBy: string;
  modified: string;
  modifiedFull: string;
  kxq_projectenddateFull: string;
  kxa_contentdateFull: string;
  kxc_lastmodifieddateFull: string;
  createdFull: string;
  kxe_abstract: string;
  industry: string;
  kxe_practicetype: string;
  kxa_businessissue: string;
  Img: string;
  image: string;
  kxe_relatedlink: string;
  kxc_clientname: string;
  kxc_lastmodifieddate: string;
  kxc_lastrevieweddate: string;
  kxc_lastrevieweddateFull: string;
  kxc_primaryglobalindustry: string;
  kxc_headquartercountry: string;
  kxc_globallcsp: string;
  kxq_businessissue: string;
  kxq_projectenddate: string;
  kxq_viewableclientname: string;
  kxq_leadprojectmemberfirm: string;
  kxq_badge: string;
  kxq_primaryglobalclientservice: string;
  kxq_projectstartdate: string;
  kxq_basicprojectsummary: string;
  kxq_externalqualuse: string;
  kxq_memberfirmsinvolved: string;
  kxq_primaryglobalindustry: string;
  kxq_teammemberinvolved: string;
  kxq_contributor: string;
  kxq_engagemantmanager: string;
  kxq_leadengagagementpartner: string;
  kxa_contentdate: string;
  kxa_abstract: string;
  kxa_primaryglobalservices: string;
  kxa_primaryglobalindustry: string;
  kxa_kamcontributor: string;
  kxe_alliancepartner: string;
  kxe_certifiedsell: string;
  kxe_productmaturity: string;
  kxe_assettype: string;
  modifiedDate: Date;
  concepttags: any;
  topictags: any;
  kxp_pdiempattributespreflastname: string;
  kxp_pdiempattributespreffirstname: string;
  kxp_recentactivitiesmodified: string;
  contenttags: any;
  matchedQueries: string[];
  publishedDate: Date | null;
  kxe_primaryglobalindustry: string;
  kxe_primaryglobalbusiness: string;
  [key: string]: any;
  kxp_pdiprimaryindustrylocaltext: string;
  kxp_aboutme: string;
  kxp_managementrole: string;
  kxp_role: string;
  allDataClients: any;
  allDataGeography: any;
  allDataIndustry: any;
  secondIndustry: any;
  secondClient: any;
  kxa_client: string;
  fullName: string;
  globalcontenttype: any;
  kxe_videolink: string;
  kxe_videoduration: string;
  kxe_collectionleaders?: string;
  kxe_keycontacts?: string;
  kxe_use: string;
  kxp_askmeabouttext: string;
  kxp_business: string;
  kxp_industry: string;
  kxp_memberfirm: string;
  kxp_country: string;
  pinDate: Date;
  kxp_pdiprimaryindustryglobaltext: string;
  kxa_secondaryglobalindustry: string;
  kxa_author: string;
  img_attachmentfilename: string;
  img_fullurl: string;
  img_minicardlabel: string;
  img_imagetypes: string[];
  isPromotedResult: boolean;
  kxa_attachments: string;
  img_pageinattachment: number;
  img_parent: string;
  img_displayname: string;
  img_thumbnailurl: string;
  img_Id: string;
  sortbydate: Date | null;
  //collection export
  filetype?: any[] | null;
  kxa_kamdescription?: string;
  kxc_clientdescription?: string;
  kxa_secondaryglobalservices?: string;
  kxq_secondaryglobalclientservice?: string;
  kxe_secondaryglobalbusiness?: string;
  kxq_secondaryglobalindustry?: string;
  kxc_secondaryglobalindustry?: string;
  highlight?: hightLightTagsSchema[];
  searchedTerm?: string;
  description?: string;
  usageguidelines?: string;
  constructor(data?: any, imageData?: any, highlight?: any, searchedTerm?: string) {

    this.filetype = data.hasOwnProperty("filetype") ? data.filetype : [];

    this.isPromotedResult = data.hasOwnProperty("isPromotedResult")
      ? data.isPromotedResult
      : false;

    this.sortbydate =
      data.hasOwnProperty("sortbydate") &&
        data.sortbydate != null &&
        data.sortbydate != "NULL"
        ? new Date(data.sortbydate)
        : null;

    // if(CommonFunctions.URLSearch().get(Constants.QueryStringParameters.imageId)) {
    //    data = imageData;
    // }

    if (
      data.hasOwnProperty("img_Id") &&
      data.img_Id != null &&
      data.img_Id != ""
    ) {
      imageData = data;
    }

    if (imageData != null && (imageData.length || imageData.img_Id)) {
      this.img_Id = imageData.hasOwnProperty("img_Id") ? imageData.img_Id : "";
      this.img_attachmentfilename = imageData.hasOwnProperty(
        "img_attachmentfilename"
      )
        ? imageData.img_attachmentfilename
        : "";
      this.img_fullurl = imageData.hasOwnProperty("img_fullurl")
        ? imageData.img_fullurl
        : "";
      this.img_minicardlabel = imageData.hasOwnProperty("img_minicardlabel")
        ? imageData.img_minicardlabel
        : "";
      this.img_imagetypes = imageData.hasOwnProperty("img_imagetypes")
        ? imageData.img_imagetypes
        : "";
      this.image = data.image = imageData.image;
      this.img_pageinattachment = imageData.hasOwnProperty(
        "img_pageinattachment"
      )
        ? imageData.img_pageinattachment
        : 0;
      this.img_parent = imageData.hasOwnProperty("img_parent")
        ? imageData.img_parent
        : "";
      this.img_displayname = imageData.hasOwnProperty("img_displayname")
        ? imageData.img_displayname
        : "";
      this.img_thumbnailurl = imageData.hasOwnProperty("img_thumbnailurl")
        ? imageData.img_thumbnailurl
        : "";
      this.source = Constants.Images;
    } else {
      this.img_Id =
        this.img_attachmentfilename =
        this.img_fullurl =
        this.img_minicardlabel =
        "";
      this.img_imagetypes = [];
      this.img_pageinattachment = 0;
      this.img_parent = "";
      this.img_displayname = "";
      this.img_thumbnailurl = "";
    }

    this.id = data.hasOwnProperty("appid") ? data.appid : 0;
    this.appurl_nospecchar = data.hasOwnProperty("appurl_nospecchar")
      ? data.appurl_nospecchar
      : 0;
    this.appurl = data.hasOwnProperty("appurl") ? data.appurl : 0;
    this.kxe_videoduration = data.hasOwnProperty("kxe_videoduration")
      ? data.kxe_videoduration
      : "";
    this.kxe_keycontacts = data.hasOwnProperty("kxe_keycontacts")
      ? data.kxe_keycontacts
      : "";
    this.kxe_collectionleaders = data.hasOwnProperty("kxe_collectionleaders")
      ? data.kxe_collectionleaders
      : "";
    this.appid = data.hasOwnProperty("appid") ? data.appid : "";
    this.source = data.hasOwnProperty("source")
      ? (imageData ? (imageData.hasOwnProperty("img_Id") && imageData.img_Id != null) : "")
        ? Constants.Images
        : data.source
      : "";
    this.title = data.hasOwnProperty("title") ? data.title : "";
    this.titletext = data.hasOwnProperty("titletext") ? data.titletext : [];
    //resently viewed
    if (data.hasOwnProperty("img_Id") && data.img_Id != null && !imageData) {
      this.appid = data.img_Id;
      this.title = data.img_displayname;
      this.img_thumbnailurl = data.hasOwnProperty("img_thumbnailurl")
        ? data.img_thumbnailurl
        : "";
    }
    this.internalcontenttype = data.hasOwnProperty("internalcontenttype")
      ? data.internalcontenttype
      : "";
    this.fullName =
      data.hasOwnProperty("kxp_pdiempattributespreffirstname") &&
        data.hasOwnProperty("kxp_pdiempattributespreflastname")
        ? data.kxp_pdiempattributespreffirstname +
        " " +
        data.kxp_pdiempattributespreflastname
        : "";
    this.matchedQueries = data.hasOwnProperty("matched_queries")
      ? data.matched_queries != null && data.matched_queries.length > 0
        ? data.matched_queries
        : []
      : [];
    this.kxe_primaryglobalindustry =
      data.hasOwnProperty("kxe_primaryglobalindustry") &&
        data.kxe_primaryglobalindustry != null &&
        data.kxe_primaryglobalindustry != "NULL"
        ? data.kxe_primaryglobalindustry
        : "";
    this.kxp_pdiprimaryindustryglobaltext =
      data.hasOwnProperty("kxp_pdiprimaryindustryglobaltext") &&
        data.kxp_pdiprimaryindustryglobaltext != null &&
        data.kxp_pdiprimaryindustryglobaltext != "NULL"
        ? data.kxp_pdiprimaryindustryglobaltext
        : "";
    this.kxe_primaryglobalbusiness =
      data.hasOwnProperty("kxe_primaryglobalbusiness") &&
        data.kxe_primaryglobalbusiness != null &&
        data.kxe_primaryglobalbusiness != "NULL"
        ? data.kxe_primaryglobalbusiness
        : "";
    this.apptype = data.hasOwnProperty("apptype")
      ? data.apptype != null && data.apptype.length > 0
        ? data.apptype[0].name
        : ""
      : "";
    this.appsubtype = data.hasOwnProperty("appsubtype")
      ? data.appsubtype != null && data.appsubtype.length > 0
        ? data.appsubtype[0].name
        : ""
      : "";
    this.badges = data.hasOwnProperty("badges")
      ? data.badges != null && data.badges.length > 0
        ? data.badges
          .filter((data: any) => {
            return data.id != null && data.id != " ";
          })
          .filter((data: any) => data)
        : []
      : "";
    this.kxp_business =
      data.hasOwnProperty("kxp_business") &&
        data.kxp_business != null &&
        data.kxp_business != "NULL"
        ? data.kxp_business
        : "";
    this.kxp_industry =
      data.hasOwnProperty("kxp_industry") &&
        data.kxp_industry != null &&
        data.kxp_industry != "NULL"
        ? data.kxp_industry
        : "";
    this.kxp_memberfirm =
      data.hasOwnProperty("kxp_memberfirm") &&
        data.kxp_memberfirm != null &&
        data.kxp_memberfirm != "NULL"
        ? data.kxp_memberfirm
        : "";
    this.kxp_country =
      data.hasOwnProperty("kxp_country") &&
        data.kxp_country != null &&
        data.kxp_country != "NULL"
        ? data.kxp_country
        : "";
    this.badgesDB = null;
    this.globalcontenttype = data.hasOwnProperty("globalcontenttype")
      ? data.globalcontenttype != null && data.globalcontenttype.length > 0
        ? data.globalcontenttype[0].name
        : ""
      : "";
    this.createdBy = data.hasOwnProperty("createdby")
      ? data.createdby != null && data.createdby.length > 0
        ? data.createdby[0].email
        : ""
      : "";
    this.kxp_askmeabouttext =
      data.hasOwnProperty("kxp_askmeabouttext") &&
        data.kxp_askmeabouttext != null &&
        data.kxp_askmeabouttext != "NULL" &&
        data.kxp_askmeabouttext
        ? data.kxp_askmeabouttext.split("|").map((tag: any) => {
          return {
            id: "",
            name: tag,
            fullpath: "",
          };
        })
        : "";
    this.RelatedLink =
      data.hasOwnProperty("kxe_relatedlink") &&
        data.kxe_relatedlink != null &&
        data.kxe_relatedlink != "NULL"
        ? data.kxe_relatedlink
        : "";
    this.kxe_relatedlink =
      data.hasOwnProperty("kxe_relatedlink") &&
        data.kxe_relatedlink != null &&
        data.kxe_relatedlink != "NULL"
        ? data.kxe_relatedlink
        : "";
    this.kxp_aboutme =
      data.hasOwnProperty("kxp_aboutme") &&
        data.kxp_aboutme != null &&
        data.kxp_aboutme != "NULL"
        ? data.kxp_aboutme
        : "";
    this.kxp_managementrole =
      data.hasOwnProperty("kxp_managementrole") &&
        data.kxp_managementrole != null &&
        data.kxp_managementrole != "NULL"
        ? data.kxp_managementrole
        : data.kxp_role
          ? data.kxp_role
          : "";
    this.kxp_role =
      data.hasOwnProperty("kxp_role") &&
        data.kxp_role != null &&
        data.kxp_role != "NULL"
        ? data.kxp_role
        : "";
    this.kxp_email =
      data.hasOwnProperty("kxp_email") &&
        data.kxp_email != null &&
        data.kxp_email != "NULL"
        ? data.kxp_email
        : "";
    this.kxp_pdiprimaryindustrylocaltext =
      data.hasOwnProperty("kxp_pdiprimaryindustrylocaltext") &&
        data.kxp_pdiprimaryindustrylocaltext != null &&
        data.kxp_pdiprimaryindustrylocaltext != "NULL"
        ? data.kxp_pdiprimaryindustrylocaltext
        : "";
    this.IsRemoved = data.hasOwnProperty("isdeleted")
      ? data.isdeleted == 1
      : false;
    this.kxe_title =
      data.hasOwnProperty("kxe_title") &&
        data.kxe_title != null &&
        data.kxe_title != "NULL"
        ? data.kxe_title
        : "";
    this.kxa_businesstitle =
      data.hasOwnProperty("kxa_businesstitle") &&
        data.kxa_businesstitle != null &&
        data.kxa_businesstitle != "NULL"
        ? data.kxa_businesstitle
        : "";
    this.kxq_projectname =
      data.hasOwnProperty("kxq_projectname") &&
        data.kxq_projectname != null &&
        data.kxq_projectname != "NULL"
        ? data.kxq_projectname
        : "";
    this.kxe_description =
      data.hasOwnProperty("kxe_description") &&
        data.kxe_description != null &&
        data.kxe_description != "NULL"
        ? data.kxe_description
        : "";
    this.kxa_kamdescription =
      data.hasOwnProperty("kxa_kamdescription") &&
        data.kxa_kamdescription != null &&
        data.kxa_kamdescription != "NULL"
        ? data.kxa_kamdescription
        : "";
    this.kxc_clientdescription =
      data.hasOwnProperty("kxc_clientdescription") &&
        data.kxc_clientdescription != null &&
        data.kxc_clientdescription != "NULL"
        ? data.kxc_clientdescription
        : "";
    this.kxq_projectscopeapproach =
      data.hasOwnProperty("kxq_projectscopeapproach") &&
        data.kxq_projectscopeapproach != null &&
        data.kxq_projectscopeapproach != "NULL"
        ? data.kxq_projectscopeapproach
        : "";

    this.kxp_othername =
      data.hasOwnProperty("kxp_othername") &&
        data.kxp_othername != null &&
        data.kxp_othername != "NULL"
        ? data.kxp_othername
        : "";
    this.kxp_pdicountrydesc =
      data.hasOwnProperty("kxp_pdicountrydesc") &&
        data.kxp_pdicountrydesc != null &&
        data.kxp_pdicountrydesc != "NULL"
        ? data.kxp_pdicountrydesc
        : "";
    this.kxp_pdicitydesc =
      data.hasOwnProperty("kxp_pdicitydesc") &&
        data.kxp_pdicitydesc != null &&
        data.kxp_pdicitydesc != "NULL"
        ? data.kxp_pdicitydesc
        : "";
    this.pdilevelsglobal_text =
      data.hasOwnProperty("pdilevelsglobal_text") &&
        data.pdilevelsglobal_text != null &&
        data.pdilevelsglobal_text != "NULL"
        ? data.pdilevelsglobal_text
        : "";
    this.kxp_pdilevelsglobaltext =
      data.hasOwnProperty("kxp_pdilevelsglobaltext") &&
        data.kxp_pdilevelsglobaltext != null &&
        data.kxp_pdilevelsglobaltext != "NULL"
        ? data.kxp_pdilevelsglobaltext
        : "";
    this.clientservice =
      data.hasOwnProperty("clientservice") &&
        data.clientservice != null &&
        data.clientservice.length > 0
        ? data.clientservice[0].name
        : "";
    this.allDataClients =
      data.hasOwnProperty("clientservice") &&
        data.clientservice != null &&
        data.clientservice.length > 0
        ? data.clientservice
          .map((element: any) => {
            if (element.fullpath && element.fullpath != " ")
              return element.fullpath.replaceAll("->", " > ");
          })
          .filter(Boolean)
          .join("; ")
        : "";

    this.geography =
      data.hasOwnProperty("geography") &&
        data.geography != null &&
        data.geography.length > 0
        ? data.geography[0].name
        : "";
    this.allDataGeography =
      data.hasOwnProperty("geography") &&
        data.geography != null &&
        data.geography.length > 0
        ? data.geography
          .map((element: any) => {
            if (element.fullpath) return element.fullpath;
          })
          .filter(Boolean)
          .join(";")
        : "";
    this.industry =
      data.hasOwnProperty("industry") &&
        data.industry != null &&
        data.industry.length > 0
        ? data.industry[0].name
        : "";
    this.allDataIndustry =
      data.hasOwnProperty("industry") &&
        data.industry != null &&
        data.industry.length > 0
        ? data.industry
          .map((element: any) => {
            if (element.fullpath && element.fullpath != " ")
              return element.fullpath.replaceAll("->", " > ");
          })
          .filter(Boolean)
          .join("; ")
        : "";
    this.secondIndustry =
      data.hasOwnProperty("industry") &&
        data.industry != null &&
        data.industry.length > 0
        ? data.industry[0].fullpath.split("->")[1]
          ? data.industry[0].fullpath.split("->")[1]
          : data.industry[0].fullpath.split("->")[0]
        : "";
    this.secondClient =
      data.hasOwnProperty("clientservice") &&
        data.clientservice != null &&
        data.clientservice.length > 0
        ? data.clientservice[0].fullpath.split("->")[1]
          ? data.clientservice[0].fullpath.split("->")[1]
          : data.clientservice[0].fullpath.split("->")[0]
        : "";
    this.kxp_pdifunctionsglobaltext =
      data.hasOwnProperty("kxp_pdifunctionsglobaltext") &&
        data.kxp_pdifunctionsglobaltext != null &&
        data.kxp_pdifunctionsglobaltext != "NULL"
        ? data.kxp_pdifunctionsglobaltext.replaceAll("->", " > ")
        : "";
    this.kxp_pdisecondaryindustryglobaltext =
      data.hasOwnProperty("kxp_pdisecondaryindustryglobaltext") &&
        data.kxp_pdisecondaryindustryglobaltext != null &&
        data.kxp_pdisecondaryindustryglobaltext != "NULL"
        ? data.kxp_pdisecondaryindustryglobaltext.replaceAll("->", " > ")
        : "";
    this.created =
      data.hasOwnProperty("created") &&
        data.created != null &&
        data.created != "NULL"
        ? CommonFunctions.DateFormatter(new Date(data.created))
        : "";
    this.createdFull =
      data.hasOwnProperty("created") &&
        data.created != null &&
        data.created != "NULL"
        ? CommonFunctions.DateFormatter(new Date(data.created), true)
        : "";
    this.modified =
      data.hasOwnProperty("modified") &&
        data.modified != null &&
        data.modified != "NULL"
        ? CommonFunctions.DateFormatter(new Date(data.modified))
        : "";
    this.modifiedFull =
      data.hasOwnProperty("modified") &&
        data.modified != null &&
        data.modified != "NULL"
        ? CommonFunctions.DateFormatter(new Date(data.modified), true)
        : "";
    this.modifiedDate =
      data.hasOwnProperty("modified") &&
        data.modified != null &&
        data.modified != "NULL"
        ? new Date(data.modified)
        : new Date();
    this.pinDate =
      data.hasOwnProperty("pinDate") &&
        data.pinDate != null &&
        data.pinDate != "NULL"
        ? new Date(data.pinDate)
        : new Date();
    this.publishedDate =
      data.hasOwnProperty("publishedDate") &&
        data.publishedDate != null &&
        data.publishedDate != "NULL"
        ? new Date(data.publishedDate)
        : null;
    this.kxe_abstract =
      data.hasOwnProperty("kxe_abstract") &&
        data.kxe_abstract != null &&
        data.kxe_abstract != "NULL"
        ? data.kxe_abstract
        : "";
    this.kxe_practicetype =
      data.hasOwnProperty("kxe_practicetype") &&
        data.kxe_practicetype != null &&
        data.kxe_practicetype != "NULL"
        ? data.kxe_practicetype
        : "";
    this.kxa_businessissue =
      data.hasOwnProperty("kxa_businessissue") &&
        data.kxa_businessissue != null &&
        data.kxa_businessissue != "NULL"
        ? data.kxa_businessissue
        : "";
    this.Img = data.hasOwnProperty("minicardimage")
      ? data.minicardimage == null || data.minicardimage == "NULL"
        ? ""
        : data.minicardimage
      : "";
    this.image = data.hasOwnProperty("image")
      ? data.image == null || data.image == "NULL"
        ? ""
        : data.image
      : "";
    this.kxc_clientname = data.hasOwnProperty("kxc_clientname")
      ? data.kxc_clientname
      : "";
    this.kxc_lastmodifieddate = data.hasOwnProperty("kxc_lastmodifieddate")
      ? CommonFunctions.DateFormatter(new Date(data.kxc_lastmodifieddate))
      : "";
    this.kxc_lastmodifieddateFull = data.hasOwnProperty("kxc_lastmodifieddate")
      ? CommonFunctions.DateFormatter(new Date(data.kxc_lastmodifieddate), true)
      : "";
    this.kxp_recentactivitiesmodified = data.hasOwnProperty(
      "kxp_recentactivitiesmodified"
    )
      ? CommonFunctions.DateFormatter(
        new Date(data.kxp_recentactivitiesmodified),
        true
      )
      : "";
    this.kxc_lastrevieweddate = data.hasOwnProperty("kxc_lastrevieweddate")
      ? CommonFunctions.DateFormatter(new Date(data.kxc_lastrevieweddate))
      : "";
    this.kxc_lastrevieweddateFull = data.hasOwnProperty("kxc_lastrevieweddate")
      ? CommonFunctions.DateFormatter(new Date(data.kxc_lastrevieweddate), true)
      : "";
    this.kxc_primaryglobalindustry = data.hasOwnProperty(
      "kxc_primaryglobalindustry"
    )
      ? data.kxc_primaryglobalindustry
      : "";

    this.kxc_headquartercountry = data.hasOwnProperty("kxc_headquartercountry")
      ? data.kxc_headquartercountry == null ||
        data.kxc_headquartercountry == "NULL"
        ? ""
        : data.kxc_headquartercountry.split(";")
          ? data.kxc_headquartercountry.split(";")[
          data.kxc_headquartercountry.split(";").length - 1
          ]
          : ""
      : "";
    this.kxc_globallcsp =
      data.hasOwnProperty("kxc_globallcsp") && data.kxc_globallcsp
        ? data.kxc_globallcsp.split(";")[1]
        : "";
    this.kxq_businessissue = data.hasOwnProperty("kxq_businessissue")
      ? data.kxq_businessissue
      : "";
    this.kxq_projectenddate = data.hasOwnProperty("kxq_projectenddate")
      ? CommonFunctions.DateFormatter(new Date(data.kxq_projectenddate))
      : "";
    this.kxq_projectenddateFull = data.hasOwnProperty("kxq_projectenddate")
      ? CommonFunctions.DateFormatter(new Date(data.kxq_projectenddate), true)
      : "";
    this.kxq_leadprojectmemberfirm =
      data.hasOwnProperty("kxq_leadprojectmemberfirm") &&
        data.kxq_leadprojectmemberfirm
        ? data.kxq_leadprojectmemberfirm + " (Lead)"
        : "";
    this.kxq_viewableclientname =
      data.hasOwnProperty("kxq_viewableclientname") &&
        data.kxq_viewableclientname
        ? data.kxq_viewableclientname.indexOf("#") > -1
          ? data.kxq_viewableclientname.substring(
            0,
            data.kxq_viewableclientname.indexOf("#")
          )
          : data.kxq_viewableclientname
        : "";
    this.topictags = data.hasOwnProperty("topictags") ? data.topictags : "";
    this.concepttags = data.hasOwnProperty("concepttags")
      ? data.concepttags
      : "";
    this.contenttags = data.hasOwnProperty("contenttags")
      ? data.contenttags
      : "";
    this.kxq_badge = data.hasOwnProperty("kxq_badge") ? data.kxq_badge : "";
    this.kxq_projectstartdate = data.hasOwnProperty("kxq_projectstartdate")
      ? CommonFunctions.DateFormatter(new Date(data.kxq_projectstartdate))
      : "";
    this.kxq_basicprojectsummary = data.hasOwnProperty(
      "kxq_basicprojectsummary"
    )
      ? data.kxq_basicprojectsummary
      : "";
    this.kxq_externalqualuse = data.hasOwnProperty("kxq_externalqualuse")
      ? data.kxq_externalqualuse
      : "";
    this.kxq_memberfirmsinvolved = data.hasOwnProperty(
      "kxq_memberfirmsinvolved"
    )
      ? data.kxq_memberfirmsinvolved
      : "";
    this.kxq_badge = data.hasOwnProperty("kxq_badge") ? data.kxq_badge : "";
    this.kxq_primaryglobalclientservice =
      data.hasOwnProperty("kxq_primaryglobalclientservice") &&
        data.kxq_primaryglobalclientservice != null &&
        data.kxq_primaryglobalclientservice
        ? data.kxq_primaryglobalclientservice
        : "";
    this.kxq_primaryglobalindustry =
      data.hasOwnProperty("kxq_primaryglobalindustry") &&
        data.kxq_primaryglobalindustry != null &&
        data.kxq_primaryglobalindustry
        ? data.kxq_primaryglobalindustry
        : "";

    this.kxa_contentdate = data.hasOwnProperty("kxa_contentdate")
      ? CommonFunctions.DateFormatter(new Date(data.kxa_contentdate))
      : "";
    this.kxa_contentdateFull = data.hasOwnProperty("kxa_contentdate")
      ? CommonFunctions.DateFormatter(new Date(data.kxa_contentdate), true)
      : "";
    this.kxq_teammemberinvolved = data.hasOwnProperty("kxq_teammemberinvolved")
      ? data.kxq_teammemberinvolved == null ||
        data.kxq_teammemberinvolved == "NULL"
        ? ""
        : data.kxq_teammemberinvolved
      : "";
    this.kxq_leadengagagementpartner = data.hasOwnProperty(
      "kxq_leadengagagementpartner"
    )
      ? data.kxq_leadengagagementpartner == null ||
        data.kxq_leadengagagementpartner == "NULL"
        ? ""
        : data.kxq_leadengagagementpartner
      : "";
    this.kxq_engagemantmanager = data.hasOwnProperty("kxq_engagemantmanager")
      ? data.kxq_engagemantmanager == null ||
        data.kxq_engagemantmanager == "NULL"
        ? ""
        : data.kxq_engagemantmanager
      : "";
    this.kxq_contributor = data.hasOwnProperty("kxq_contributor")
      ? data.kxq_contributor == null || data.kxq_contributor == "NULL"
        ? ""
        : data.kxq_contributor
      : "";
    this.kxa_abstract = data.hasOwnProperty("kxa_abstract")
      ? data.kxa_abstract
      : "";
    this.kxp_pdiempattributespreflastname = data.hasOwnProperty(
      "kxp_pdiempattributespreflastname"
    )
      ? data.kxp_pdiempattributespreflastname
      : "";
    this.kxp_pdiempattributespreffirstname = data.hasOwnProperty(
      "kxp_pdiempattributespreffirstname"
    )
      ? data.kxp_pdiempattributespreffirstname
      : "";
    this.kxa_primaryglobalservices = data.hasOwnProperty(
      "kxa_primaryglobalservices"
    )
      ? data.kxa_primaryglobalservices
      : "";
    this.kxa_secondaryglobalservices = data.hasOwnProperty("kxa_secondaryglobalservices")
      ? data.kxa_secondaryglobalservices : "";
    this.kxq_secondaryglobalclientservice = data.hasOwnProperty("kxq_secondaryglobalclientservice")
      ? data.kxq_secondaryglobalclientservice : "";
    this.kxe_secondaryglobalbusiness = data.hasOwnProperty("kxe_secondaryglobalbusiness")
      ? data.kxe_secondaryglobalbusiness : "";

    this.kxa_primaryglobalindustry = data.hasOwnProperty("kxa_primaryglobalindustry")
      ? data.kxa_primaryglobalindustry
        ? data.kxa_primaryglobalindustry
        : data.kxa_secondaryglobalindustry
      : "";
    this.kxa_secondaryglobalindustry = data.hasOwnProperty("kxa_secondaryglobalindustry")
      ? data.kxa_secondaryglobalindustry : "";
    this.kxq_secondaryglobalindustry = data.hasOwnProperty("kxq_secondaryglobalindustry")
      ? data.kxq_secondaryglobalindustry : "";
    this.kxc_secondaryglobalindustry = data.hasOwnProperty("kxc_secondaryglobalindustry")
      ? data.kxc_secondaryglobalindustry : "";

    this.kxa_kamcontributor = data.hasOwnProperty("kxa_kamcontributor")
      ? data.kxa_kamcontributor == null || data.kxa_kamcontributor == "NULL"
        ? ""
        : data.kxa_kamcontributor
      : "";
    this.kxe_certifiedsell = data.hasOwnProperty("kxe_certifiedsell")
      ? data.kxe_certifiedsell == null || data.kxe_certifiedsell == "NULL"
        ? ""
        : data.kxe_certifiedsell.split(";")
          ? data.kxe_certifiedsell.split(";")[
          data.kxe_certifiedsell.split(";").length - 1
          ]
          : ""
      : "";
    this.kxe_use = data.hasOwnProperty("kxe_use")
      ? data.kxe_use == null || data.kxe_use == "NULL"
        ? ""
        : data.kxe_use.split(";")
          ? data.kxe_use.split(";")[data.kxe_use.split(";").length - 1]
          : ""
      : "";
    this.kxe_alliancepartner = data.hasOwnProperty("kxe_alliancepartner")
      ? data.kxe_alliancepartner == null || data.kxe_alliancepartner == "NULL"
        ? ""
        : data.kxe_alliancepartner
      : "";
    this.kxe_productmaturity = data.hasOwnProperty("kxe_productmaturity")
      ? data.kxe_productmaturity == null || data.kxe_productmaturity == "NULL"
        ? ""
        : data.kxe_productmaturity.split(";")
          ? data.kxe_productmaturity.split(";")[
          data.kxe_productmaturity.split(";").length - 1
          ]
          : ""
      : "";
    this.kxe_assettype = data.hasOwnProperty("kxe_assettype")
      ? data.kxe_assettype == null || data.kxe_assettype == "NULL"
        ? ""
        : data.kxe_assettype.split(";")
          ? data.kxe_assettype.split(";")[
          data.kxe_assettype.split(";").length - 1
          ]
          : ""
      : "";
    this.kxp_accountname = data.hasOwnProperty("kxp_accountname")
      ? data.kxp_accountname == null || data.kxp_accountname == "NULL"
        ? ""
        : data.kxp_accountname
      : "";
    this.kxa_client = data.hasOwnProperty("kxa_client")
      ? data.kxa_client == null || data.kxa_client == "NULL"
        ? ""
        : data.kxa_client
      : "";
    this.kxe_videolink = data.hasOwnProperty("kxe_videolink")
      ? data.kxe_videolink == null || data.kxe_videolink == "NULL"
        ? ""
        : data.kxe_videolink
      : "";
    this.kxa_author = data.hasOwnProperty("kxa_author")
      ? data.kxa_author == null || data.kxa_author == "NULL"
        ? ""
        : data.kxa_author
      : "";
    this.kxa_attachments = data.hasOwnProperty("kxa_attachments")
      ? data.kxa_attachments == null || data.kxa_attachments == "NULL"
        ? ""
        : data.kxa_attachments
      : "";

    this.highlight = highlight;
    this.searchedTerm = searchedTerm;
    this.description = data.hasOwnProperty("description")
      ? data.description
      : "";
    this.usageguidelines = data.hasOwnProperty("usageguidelines") ? data.usageguidelines : "";
    // this.title = data.hasOwnProperty("title.match")
    // ? data.description
    // : "";
  }
}

export interface IUserNeedData {
  Id: number;
  Title: string;
  TitleLabel: string;
  SubTitleLabel: string;
}

export interface IUserNeedWithMpping {
  Id: number;
  Title: string;
  TitleLabel: string;
  SubTitleLabel: string;
  ContentTypeMappings: CTPMapping[];
}

export interface CTPMapping {
  CollectionCategory: any;
  SearchCategory: any;
}

export interface IGenericCardData {
  key: number;
  type: CardType;
  image: string;
  title: TitleCard;
  subTitle: string;
  footer: string[];
  overflowItemsData: OverflowItemsData[];
  sourceId: string;
  ctName: string;
}

export interface ICollectionCardData extends IGenericCardData {
  ctId: string;
  listTitle: string;
  editUrl: string;
  viewUrl: string;
  tag: Tag[];
  badge: string;
  desc: string;
  otherContent: string;
  linkToItem: string;
  topLeftLabel: string;
  openNewTab?: boolean;
  size?: string;
  isHorizontal?: boolean;
  allData?: any;
  IsRemoved?: boolean;
  geography?: string;
  followers?: any;
}

export interface IPeopleCardData extends IGenericCardData {
  textFirst: string;
  textSecond: string;
  email: string;
  imageData?: string;
  searchBoxData?: {};
  followers?: any;
}

export class Practice implements IPractice {
  Id: number;
  Title: string;
  AccoladesAwards: string;
  ContentType: string;
  Description: string;
  Geography: string;
  GlobalBusiness: string;
  GlobalIndustry: string;
  IndustryLevels: string;
  KeyContacts: string;
  OverviewPresent: string;
  PractcieLevelType: string;
  PracticeName: string;
  PracticeType: string;
  RelatedColl: string;
  RelatedComm: string;
  RelatedItems: string;
  TopBannerImage: any;
  StatDate: string;
  ParcticeHead: string;
  ParcticeRev: string;
  StatUse: string;
  IsArchived: boolean;

  constructor(data?: any) {
    this.Id = data.hasOwnProperty("ID") ? data.ID : 0;
    this.Title = data.hasOwnProperty("Title") ? data.Title : "";
    this.AccoladesAwards = data.hasOwnProperty("dkxAccoladesAwards")
      ? data.dkxAccoladesAwards
      : "";
    this.ContentType = data.hasOwnProperty("dkxContentType")
      ? data.dkxContentType
      : "";
    this.Description = data.hasOwnProperty("dkxDescription")
      ? data.dkxDescription
      : "";
    this.Geography = data.hasOwnProperty("dkxGeographyText")
      ? data.dkxGeographyText
      : "";
    this.GlobalBusiness = data.hasOwnProperty("dkxGlobalBusinessText")
      ? data.dkxGlobalBusinessText
      : "";
    this.GlobalIndustry = data.hasOwnProperty("dkxGlobalIndustryText")
      ? data.dkxGlobalIndustryText
      : "";
    this.IndustryLevels = data.hasOwnProperty("dkxIndustryLevels")
      ? data.dkxIndustryLevels
      : "";
    this.KeyContacts = data.hasOwnProperty("dkxKeyContacts")
      ? data.dkxKeyContacts
      : "";
    this.OverviewPresent = data.hasOwnProperty("dkxOverviewPresent")
      ? data.dkxOverviewPresent
      : "";
    this.PractcieLevelType = data.hasOwnProperty("dkxPractcieLevelType")
      ? data.dkxPractcieLevelType
      : "";
    this.PracticeName = data.hasOwnProperty("dkxPracticeName")
      ? data.dkxPracticeName
      : "";
    this.RelatedColl = data.hasOwnProperty("dkxRelatedColl")
      ? data.dkxRelatedColl
      : "";
    this.PracticeType = data.hasOwnProperty("dkxPracticeType")
      ? data.dkxPracticeType
      : "";
    this.RelatedComm = data.hasOwnProperty("dkxRelatedComm")
      ? data.dkxRelatedComm
      : "";
    this.RelatedItems = data.hasOwnProperty("dkxRelatedItems")
      ? data.dkxRelatedItems
      : "";
    this.TopBannerImage = data.hasOwnProperty("dkxTopBannerImage")
      ? data.dkxTopBannerImage
      : "";
    this.StatDate = data.hasOwnProperty("dkxStatDate") ? data.dkxStatDate : "";
    this.ParcticeHead = data.hasOwnProperty("dkxParcticeHead")
      ? data.dkxParcticeHead
      : "";
    this.ParcticeRev = data.hasOwnProperty("dkxParcticeRev")
      ? data.dkxParcticeRev
      : "";
    this.StatUse = data.hasOwnProperty("dkxStatUse") ? data.dkxStatUse : "";
    this.IsArchived = data.hasOwnProperty("dkxIsItemArchived")
      ? (data.dkxIsItemArchived as boolean)
      : false;
  }
}

export class UserSchema {
  id: number;
  appurl: string;
  source: string;
  title: string;
  apptype: string;
  appsubtype: string;
  internalcontenttype: string;
  isdeleted: boolean;
  kxp_rowid: string;
  kxp_aboutme: string;
  clientService: string;
  clientServiceId: string;
  clientServiceFullPath: string;
  geographyFullPath: string;
  clientserviceObj: string;
  kxp_accountname: string;
  kxp_additionallanguages: string;
  kxp_assistantname: string;
  kxp_dpnnativename: string;
  kxp_email: string;
  kxp_faxnumber: string;
  kxp_hometown: string;
  kxp_pdiaddressline3: string;
  kxp_pdicountrycode: string;
  kxp_pdicountrydesc: string;
  kxp_pdidesignationsdesignationdesc: string;
  kxp_pdiemailsemailaddress: string;
  kxp_pdiempattributesjobtitledesc: string;
  kxp_pdiempattributespreffirstname: string;
  kxp_pdiempattributespreflastname: string;
  kxp_pdiemployeesmfid: string;
  kxp_pdifunctionsglobaltext: string;
  kxp_pdiguid: string;
  kxp_pdicitydesc: string;
  kxp_pdilanguageslanguagedesc: string;
  kxp_pdilevelsglobaltext: string;
  kxp_pdilevelslocaltext: string;
  kxp_pdiprimaryindustrylocaltext: string;
  kxp_managementrole: string;
  kxp_role: string;
  kxp_pdimasterlastmodifieddate: string;
  kxp_pdiofficesregiondesc: string;
  kxp_pdiorganizationscostcenterdesc: string;
  kxp_pdiorganizationsorganizationdesc: string;
  kxp_pdipostalcode: string;
  kxp_pdiprimaryindustryglobaltext: string;
  kxp_pdisecondaryindustryglobaltext: string;
  kxp_pdistatedesc: string;
  kxp_pdistatusglobaltext: string;
  kxp_pdistatuslocaltext: string;
  kxp_pditypesglobaltext: string;
  kxp_pditypeslocaltext: string;
  kxp_personalinterests: string;
  kxp_volunteeractivities: string;
  kxp_dpncurrentassignmenttext: string;
  kxp_business: string;
  kxp_industry: string;
  kxp_memberfirm: string;
  kxp_country: string;
  kxp_pictureurl: string;
  isCurrentUserCurator: boolean;
  isCurrentUserTaxonomyAdmin: boolean;
  isInterest?: boolean;
  fullName: string;
  allDataClients: any;
  allDataGeography: any;
  allDataIndustry: any;
  constructor(
    data?: any,
    isCurrentUserCurator?: boolean,
    isCurrentUserTaxonomyAdmin?: boolean
  ) {
    this.id = data.hasOwnProperty("id") ? data.id : 0;
    this.appurl = data.hasOwnProperty("appurl") ? data.appurl : 0;
    this.source = data.hasOwnProperty("source") ? data.source : "";
    this.title = data.hasOwnProperty("title") ? data.title : "";
    this.fullName =
      data.hasOwnProperty("kxp_pdiempattributespreffirstname") &&
        data.hasOwnProperty("kxp_pdiempattributespreflastname")
        ? data.kxp_pdiempattributespreffirstname +
        " " +
        data.kxp_pdiempattributespreflastname
        : "";
    this.kxp_pictureurl = data.hasOwnProperty("kxp_pictureurl")
      ? data.kxp_pictureurl
      : "";
    this.allDataClients =
      data.hasOwnProperty("clientservice") &&
        data.clientservice != null &&
        data.clientservice.length > 0
        ? data.clientservice
          .map((element: any) => {
            if (element.fullpath) return element.fullpath;
          })
          .join(";")
        : "";
    this.allDataGeography =
      data.hasOwnProperty("geography") &&
        data.geography != null &&
        data.geography.length > 0
        ? data.geography
          .map((element: any) => {
            if (element.fullpath) return element.fullpath;
          })
          .filter(Boolean)
          .filter(Boolean)
          .join(";")
        : "";
    this.allDataIndustry =
      data.hasOwnProperty("industry") &&
        data.industry != null &&
        data.industry.length > 0
        ? data.industry
          .map((element: any) => {
            if (element.fullpath && element.fullpath != " ")
              return element.fullpath;
          })
          .filter(Boolean)
          .join(";")
        : "";
    this.isCurrentUserCurator = isCurrentUserCurator || false;
    this.isCurrentUserTaxonomyAdmin = isCurrentUserTaxonomyAdmin || false;
    this.internalcontenttype = data.hasOwnProperty("internalcontenttype")
      ? data.internalcontenttype
      : "";
    this.apptype = data.hasOwnProperty("apptype")
      ? data.apptype != null && data.apptype.length > 0
        ? data.apptype[0].name
        : ""
      : "";
    this.appsubtype = data.hasOwnProperty("appsubtype")
      ? data.appsubtype != null && data.appsubtype.length > 0
        ? data.appsubtype[0].name
        : ""
      : "";

    if (
      data.hasOwnProperty("clientservice") &&
      data.clientservice != null &&
      data.clientservice.length > 0
    ) {
      this.clientServiceFullPath = data.clientservice[0].fullpath;
      this.clientService = data.clientservice[0].name;
      this.clientServiceId = data.clientservice[0].id;
    } else {
      this.clientServiceFullPath = "";
      this.clientService = "";
      this.clientServiceId = "";
    }
    if (
      data.hasOwnProperty("geography") &&
      data.geography != null &&
      data.geography.length > 0
    ) {
      this.geographyFullPath = data.geography[0].fullpath;
    } else {
      this.geographyFullPath = "";
    }
    var a =
      data.clientservice && data.clientservice.length > 0
        ? data.clientservice
        : [];
    var b = a.map((data: any) => {
      if (data.id != "" && data.id != " ")
        return data.id + ";" + data.fullpath + ";" + data.name;
      else return null;
    });
    var filtered = b.filter(function (el: any) {
      return el != null;
    });
    this.clientserviceObj = filtered.join("|");
    this.isdeleted = data.hasOwnProperty("isdeleted")
      ? data.isdeleted == 1
      : false;
    this.kxp_rowid =
      data.hasOwnProperty("kxp_rowid") &&
        data.kxp_rowid != null &&
        data.kxp_rowid != "NULL"
        ? data.kxp_rowid
        : "";
    this.kxp_aboutme =
      data.hasOwnProperty("kxp_aboutme") &&
        data.kxp_aboutme != null &&
        data.kxp_aboutme != "NULL"
        ? data.kxp_aboutme
        : "";
    this.kxp_accountname =
      data.hasOwnProperty("kxp_accountname") &&
        data.kxp_accountname != null &&
        data.kxp_accountname != "NULL"
        ? data.kxp_accountname
        : "";
    this.kxp_additionallanguages =
      data.hasOwnProperty("kxp_additionallanguages") &&
        data.kxp_additionallanguages != null &&
        data.kxp_additionallanguages != "NULL"
        ? data.kxp_additionallanguages
        : "";
    this.kxp_assistantname =
      data.hasOwnProperty("kxp_assistantname") &&
        data.kxp_assistantname != null &&
        data.kxp_assistantname != "NULL"
        ? data.kxp_assistantname
        : "";
    this.kxp_dpnnativename =
      data.hasOwnProperty("kxp_dpnnativename") &&
        data.kxp_dpnnativename != null &&
        data.kxp_dpnnativename != "NULL"
        ? data.kxp_dpnnativename
        : "";
    this.kxp_email =
      data.hasOwnProperty("kxp_email") &&
        data.kxp_email != null &&
        data.kxp_email != "NULL"
        ? data.kxp_email
        : "";
    this.kxp_faxnumber =
      data.hasOwnProperty("kxp_faxnumber") &&
        data.kxp_faxnumber != null &&
        data.kxp_faxnumber != "NULL"
        ? data.kxp_faxnumber
        : "";
    this.kxp_hometown =
      data.hasOwnProperty("kxp_hometown") &&
        data.kxp_hometown != null &&
        data.kxp_hometown != "NULL"
        ? data.kxp_hometown
        : "";
    this.kxp_pdiaddressline3 =
      data.hasOwnProperty("kxp_pdiaddressline3") &&
        data.kxp_pdiaddressline3 != null &&
        data.kxp_pdiaddressline3 != "NULL"
        ? data.kxp_pdiaddressline3
        : "";
    this.kxp_pdicitydesc =
      data.hasOwnProperty("kxp_pdicitydesc") &&
        data.kxp_pdicitydesc != null &&
        data.kxp_pdicitydesc != "NULL"
        ? data.kxp_pdicitydesc
        : "";
    this.kxp_pdicountrycode =
      data.hasOwnProperty("kxp_pdicountrycode") &&
        data.kxp_pdicountrycode != null &&
        data.kxp_pdicountrycode != "NULL"
        ? data.kxp_pdicountrycode
        : "";
    this.kxp_pdicountrydesc =
      data.hasOwnProperty("kxp_pdicountrydesc") &&
        data.kxp_pdicountrydesc != null &&
        data.kxp_pdicountrydesc != "NULL"
        ? data.kxp_pdicountrydesc
        : "";
    this.kxp_pdidesignationsdesignationdesc =
      data.hasOwnProperty("kxp_pdidesignationsdesignationdesc") &&
        data.kxp_pdidesignationsdesignationdesc != null &&
        data.kxp_pdidesignationsdesignationdesc != "NULL"
        ? data.kxp_pdidesignationsdesignationdesc
        : "";
    this.kxp_pdiemailsemailaddress =
      data.hasOwnProperty("kxp_pdiemailsemailaddress") &&
        data.kxp_pdiemailsemailaddress != null &&
        data.kxp_pdiemailsemailaddress != "NULL"
        ? data.kxp_pdiemailsemailaddress
        : "";
    this.kxp_pdiempattributesjobtitledesc =
      data.hasOwnProperty("kxp_pdiempattributesjobtitledesc") &&
        data.kxp_pdiempattributesjobtitledesc != null &&
        data.kxp_pdiempattributesjobtitledesc != "NULL"
        ? data.kxp_pdiempattributesjobtitledesc
        : "";
    this.kxp_pdiempattributespreffirstname =
      data.hasOwnProperty("kxp_pdiempattributespreffirstname") &&
        data.kxp_pdiempattributespreffirstname != null &&
        data.kxp_pdiempattributespreffirstname != "NULL"
        ? data.kxp_pdiempattributespreffirstname
        : "";
    this.kxp_pdiempattributespreflastname =
      data.hasOwnProperty("kxp_pdiempattributespreflastname") &&
        data.kxp_pdiempattributespreflastname != null &&
        data.kxp_pdiempattributespreflastname != "NULL"
        ? data.kxp_pdiempattributespreflastname
        : "";
    this.kxp_pdiemployeesmfid =
      data.hasOwnProperty("kxp_pdiemployeesmfid") &&
        data.kxp_pdiemployeesmfid != null &&
        data.kxp_pdiemployeesmfid != "NULL"
        ? data.kxp_pdiemployeesmfid
        : "";
    this.kxp_pdifunctionsglobaltext =
      data.hasOwnProperty("kxp_pdifunctionsglobaltext") &&
        data.kxp_pdifunctionsglobaltext != null &&
        data.kxp_pdifunctionsglobaltext != "NULL"
        ? data.kxp_pdifunctionsglobaltext
        : "";
    this.kxp_pdiguid =
      data.hasOwnProperty("kxp_pdiguid") &&
        data.kxp_pdiguid != null &&
        data.kxp_pdiguid != "NULL"
        ? data.kxp_pdiguid
        : "";
    this.kxp_pdilanguageslanguagedesc =
      data.hasOwnProperty("kxp_pdilanguageslanguagedesc") &&
        data.kxp_pdilanguageslanguagedesc != null &&
        data.kxp_pdilanguageslanguagedesc != "NULL"
        ? data.kxp_pdilanguageslanguagedesc
        : "";
    this.kxp_pdilevelsglobaltext =
      data.hasOwnProperty("kxp_pdilevelsglobaltext") &&
        data.kxp_pdilevelsglobaltext != null &&
        data.kxp_pdilevelsglobaltext != "NULL"
        ? data.kxp_pdilevelsglobaltext
        : "";
    this.kxp_pdilevelslocaltext =
      data.hasOwnProperty("kxp_pdilevelslocaltext") &&
        data.kxp_pdilevelslocaltext != null &&
        data.kxp_pdilevelslocaltext != "NULL"
        ? data.kxp_pdilevelslocaltext
        : "";
    this.kxp_pdiprimaryindustrylocaltext =
      data.hasOwnProperty("kxp_pdiprimaryindustrylocaltext") &&
        data.kxp_pdiprimaryindustrylocaltext != null &&
        data.kxp_pdiprimaryindustrylocaltext != "NULL"
        ? data.kxp_pdiprimaryindustrylocaltext
        : "";
    this.kxp_managementrole =
      data.hasOwnProperty("kxp_managementrole") &&
        data.kxp_managementrole != null &&
        data.kxp_managementrole != "NULL"
        ? data.kxp_managementrole
        : data.kxp_role
          ? data.kxp_role
          : "";
    this.kxp_role =
      data.hasOwnProperty("kxp_role") &&
        data.kxp_role != null &&
        data.kxp_role != "NULL"
        ? data.kxp_role
        : "";
    this.kxp_pdimasterlastmodifieddate =
      data.hasOwnProperty("kxp_pdimasterlastmodifieddate") &&
        data.kxp_pdimasterlastmodifieddate != null &&
        data.kxp_pdimasterlastmodifieddate != "NULL"
        ? data.kxp_pdimasterlastmodifieddate
        : "";
    this.kxp_pdiofficesregiondesc =
      data.hasOwnProperty("kxp_pdiofficesregiondesc") &&
        data.kxp_pdiofficesregiondesc != null &&
        data.kxp_pdiofficesregiondesc != "NULL"
        ? data.kxp_pdiofficesregiondesc
        : "";
    this.kxp_pdiorganizationscostcenterdesc =
      data.hasOwnProperty("kxp_pdiorganizationscostcenterdesc") &&
        data.kxp_pdiorganizationscostcenterdesc != null &&
        data.kxp_pdiorganizationscostcenterdesc != "NULL"
        ? data.kxp_pdiorganizationscostcenterdesc
        : "";
    this.kxp_pdiorganizationsorganizationdesc =
      data.hasOwnProperty("kxp_pdiorganizationsorganizationdesc") &&
        data.kxp_pdiorganizationsorganizationdesc != null &&
        data.kxp_pdiorganizationsorganizationdesc != "NULL"
        ? data.kxp_pdiorganizationsorganizationdesc
        : "";
    this.kxp_pdipostalcode =
      data.hasOwnProperty("kxp_pdipostalcode") &&
        data.kxp_pdipostalcode != null &&
        data.kxp_pdipostalcode != "NULL"
        ? data.kxp_pdipostalcode
        : "";
    this.kxp_pdiprimaryindustryglobaltext =
      data.hasOwnProperty("kxp_pdiprimaryindustryglobaltext") &&
        data.kxp_pdiprimaryindustryglobaltext != null &&
        data.kxp_pdiprimaryindustryglobaltext != "NULL"
        ? data.kxp_pdiprimaryindustryglobaltext
        : "";

    this.kxp_pdisecondaryindustryglobaltext =
      data.hasOwnProperty("kxp_pdisecondaryindustryglobaltext") &&
        data.kxp_pdisecondaryindustryglobaltext != null &&
        data.kxp_pdisecondaryindustryglobaltext != "NULL"
        ? data.kxp_pdisecondaryindustryglobaltext
        : "";
    this.kxp_pdistatedesc =
      data.hasOwnProperty("kxp_pdistatedesc") &&
        data.kxp_pdistatedesc != null &&
        data.kxp_pdistatedesc != "NULL"
        ? data.kxp_pdistatedesc
        : "";
    this.kxp_pdistatusglobaltext =
      data.hasOwnProperty("kxp_pdistatusglobaltext") &&
        data.kxp_pdistatusglobaltext != null &&
        data.kxp_pdistatusglobaltext != "NULL"
        ? data.kxp_pdistatusglobaltext
        : "";
    this.kxp_pdistatuslocaltext =
      data.hasOwnProperty("kxp_pdistatuslocaltext") &&
        data.kxp_pdistatuslocaltext != null &&
        data.kxp_pdistatuslocaltext != "NULL"
        ? data.kxp_pdistatuslocaltext
        : "";
    this.kxp_pditypesglobaltext =
      data.hasOwnProperty("kxp_pditypesglobaltext") &&
        data.kxp_pditypesglobaltext != null &&
        data.kxp_pditypesglobaltext != "NULL"
        ? data.kxp_pditypesglobaltext
        : "";
    this.kxp_pditypeslocaltext =
      data.hasOwnProperty("kxp_pditypeslocaltext") &&
        data.kxp_pditypeslocaltext != null &&
        data.kxp_pditypeslocaltext != "NULL"
        ? data.kxp_pditypeslocaltext
        : "";
    this.kxp_personalinterests =
      data.hasOwnProperty("kxp_personalinterests") &&
        data.kxp_personalinterests != null &&
        data.kxp_personalinterests != "NULL"
        ? data.kxp_personalinterests
        : "";
    this.kxp_dpncurrentassignmenttext =
      data.hasOwnProperty("kxp_dpncurrentassignmenttext") &&
        data.kxp_dpncurrentassignmenttext != null &&
        data.kxp_dpncurrentassignmenttext != "NULL"
        ? data.kxp_dpncurrentassignmenttext
        : "";
    this.kxp_volunteeractivities =
      data.hasOwnProperty("kxp_volunteeractivities") &&
        data.kxp_volunteeractivities != null &&
        data.kxp_volunteeractivities != "NULL"
        ? data.kxp_volunteeractivities
        : "";
    this.kxp_business =
      data.hasOwnProperty("kxp_business") &&
        data.kxp_business != null &&
        data.kxp_business != "NULL"
        ? data.kxp_business
        : "";
    this.kxp_industry =
      data.hasOwnProperty("kxp_industry") &&
        data.kxp_industry != null &&
        data.kxp_industry != "NULL"
        ? data.kxp_industry
        : "";
    this.kxp_memberfirm =
      data.hasOwnProperty("kxp_memberfirm") &&
        data.kxp_memberfirm != null &&
        data.kxp_memberfirm != "NULL"
        ? data.kxp_memberfirm
        : "";
    this.kxp_country =
      data.hasOwnProperty("kxp_country") &&
        data.kxp_country != null &&
        data.kxp_country != "NULL"
        ? data.kxp_country
        : "";
  }
}

export class DraftItems implements IPaginate<IDrafts> {
  items: IDrafts[] = [];
  pagingInfo: string = "";

  constructor(data: any) {
    if (data) {
      this.pagingInfo = data.pagingInfo;

      this.items = [];
      if (!isEmpty(data.items)) {
        this.items = data.items.map((o: any): IDrafts => {
          return {
            ID: o.ID,
            Title: o.Title,
            Modified: o.Modified,
            dkxIsItemArchived: o.dkxIsItemArchived ? "Yes" : "No",
            _UIVersionString: o._UIVersionString,
            Editor: o.Editor ? o.Editor.LookupValue : "",
          };
        });
      }
    }
  }
}

export class Attachment {
  DocumentId: number;
  AttachmentName: string;
  TechnicalNameinBlob: string;
  BlobUrl: string;
  IsDeleted: boolean;
  IsDocumentPublished: boolean;
  IsUploadSuccess: boolean;
  CreatedBy: string;
  FileSize: number | string;
  QueueGuid: string;
  SpoUrl?: string;
  SpoId: number;
  FileLanguage?: string;
  orderforDisplay?: number;
  constructor(
    DocumentId: number,
    AttachmentName: string,
    TechnicalNameinBlob: string,
    BlobUrl: string,
    IsDeleted: boolean,
    IsDocumentPublished: boolean,
    IsUploadSuccess: boolean,
    CreatedBy: string,
    FileSize: number,
    QueueGuid: string,
    SpoId: number,
    SpoUrl?: string,
    FileLanguage?: string,
    orderforDisplay?: number
  ) {
    this.DocumentId = DocumentId;
    this.AttachmentName = AttachmentName;
    this.TechnicalNameinBlob = TechnicalNameinBlob;
    this.BlobUrl = BlobUrl;
    this.IsDeleted = IsDeleted;
    this.IsDocumentPublished = IsDocumentPublished;
    this.IsUploadSuccess = IsUploadSuccess;
    this.CreatedBy = CreatedBy;
    this.FileSize = FileSize;
    this.QueueGuid = QueueGuid;
    this.SpoUrl = SpoUrl;
    this.SpoId = SpoId;
    this.FileLanguage = FileLanguage;
    this.orderforDisplay = orderforDisplay;
  }
}

export class SubCategory {
  Title: string;
  RelatedLinks: Array<IRelatedLinksCard>;
  IsOpen: boolean;

  constructor(
    title: string,
    relatedLinks: Array<IRelatedLinksCard> = [],
    isOpen: boolean = true
  ) {
    this.Title = title;
    this.RelatedLinks = relatedLinks || [];
    this.IsOpen = isOpen;
  }
}

export interface ISubCategoryResponse {
  LabelKey: string;
  RelatedItems?: Array<IRelatedLinksCard>;
}

export interface IRelatedLinksCard {
  LinkLabel?: string;
  LinkToItem?: string;
}

export interface IRelatedCommunitiesCard {
  CommunityName?: string;
  LinkToItem?: string;
}

