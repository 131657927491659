import React, { useEffect, useState, Suspense } from "react";
import "./App.css";
import CuratedCollection from "./components/root/curatedCollectionPage/curatedCollection.lazy";
import HomePage from "./components/root/homePage/homePage.lazy";
import ExploreCollection from "./components/root/exploreCollection/exploreCollection.lazy";
import PageNotFound from "./components/root/pageNotFound/pageNotFound.lazy";
import PersonalCollectionPage from "./components/root/personalCollectionPage/personalCollectionPage.lazy";
import ManageMyInterestsPage from "./components/root/manageMyInterestsPage/manageMyInterests.lazy";
import SubscriptionPage from "./components/root/subscriptionPage/subscriptionPage.lazy";
import PracticeProfilePage from "./components/root/practiceProfilePage/practiceProfilePage.lazy";
import SearchPage from "./components/root/searchPage/searchPage.lazy";
import GlobalNavigation from "./components/shared/globalNavigation/globalNavigation.lazy";
import KnowledgeItemHeader from "./components/shared/knowledgeItemHeader/knowledgeItemHeader.lazy";
import Drafts from "./components/root/drafts/drafts.lazy";
import TaxonomyManagment from "./components/root/taxonomyManagment/taxonomyManagment.lazy";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { SharedService } from "./services/SharedService";
import ErrorBoundary from "./components/shared/errorBoundary/errorBoundary";
import "normalize.css";
import "./assets/scss/app.scss";
import "./assets/scss/ourCssApp.scss";
import CardsWrapper from "./components/shared/cardsWrapper/cardsWrapper.lazy";
import Footer from "./components/shared/footer/footer";
import { Constants } from "./helpers/Constants";
import { initializeIcons } from "@uifabric/icons";
import { Header } from "./components/shared/header/header";
import { loadTheme } from "@uifabric/styling";
import { Link as TextLink } from "office-ui-fabric-react/lib/Link";
import { IDigitalData, IPromptDigitalData } from "./models/IAnalytics";
import { UserNeedProvider } from "./components/shared/contextAPIs/userNeedContext";
import { PageEditorProvider } from "./components/shared/contextAPIs/pageEditorContext";
import { CollapsibleBaseProvider } from "./components/shared/contextAPIs/collapsibleBaseContext";
import PracticeProfileLobbyPage from "./components/root/practiceProfileLobbyPage/practiceProfileLobbyPage.lazy";
import { ActivityTracker } from "./helpers/ActivityTracker";
import { CommonFunctions } from "./helpers/CommonFunctions";
import { ModalBase } from "./components/shared/modalBase/modalBase";
import CompareContentPage from "./components/shared/compareContent/compareContentPage";
import { CompareContentProvider } from "./components/shared/contextAPIs/comparContentContext";
import { CompareArea } from "./components/shared/compareContent/comapreArea";
import NewContribution from "./components/root/newContribution/newContribution";
import TreePage from "./components/root/treePage/treePage";
import DisplayPage from "./components/root/displayPage/display.lazy";
import MyContribution from "./components/root/myContribution/myContribution.lazy";
import MyKxContribution from "./components/root/myKxContribution/myKx.lazy";
import { AttachmentNotificationProvider } from "./components/shared/contextAPIs/AttachmentNotificationContext";
import EditForm from "./components/root/editPage/editPage.lazy";
import DocumentAdmin from "./components/root/editPage/documentAdmin";
import PublisherDashBoard from "./components/root/publisherDashBoard/publisherDashboard.lazy";
import { CookiesConsent } from "./components/shared/cookiesConsent/cookiesConsent";
import UnauthorizedPage from "./components/root/unauthorizedPage/unauthorizedPage";
import UserNotAllowed from "./components/root/userNotAllowed/userNotAllowed";
import AttachmentDownload from "./components/root/attachmentDownload/attachmentDownload";
import OldUrl from "./components/root/oldUrl/oldUrl";
import { TagsDataProvider } from "./components/shared/contextAPIs/tagsDataContext";
import { AllDataProvider } from "./components/shared/contextAPIs/allDataContext";
import ScrollToTop from "./components/shared/scrollToTop/scrollToTop";
import { setLoadTagsDataAction } from "../src/store/actions/TagsDataAction";
import { setGetAllDataAction } from "../src/store/actions/AllDataAction";
import { useDispatch } from "react-redux";
import Prompt from "./components/root/prompt/layouts/Prompt/Prompt";
import { FloatingActionButton } from "./components/root/prompt/components/FloatingActionButton/FloatingActionButton";
import TryPromptByKXPopup from "./components/root/prompt/components/TryPromptByKXPopup/TryPromptByKXPopup";
import {
  CURRENT_COUNTRY,
  RESTRICTED_COUNTRY_LIST,
} from "./components/root/prompt/layouts/Prompt/PromptConstants";
import CountryRestriction from "./components/root/prompt/components/CountryRestriction/CountryRestriction";
import PopupBG from "./assets/prompt/KX_hompage_popup_bg1.svg";

//#region Global Declaritions
declare global {
  interface Window {
    FormatStringWithLinks: any;
    digitalData: IDigitalData;
    promptDigitalData: IPromptDigitalData;
  }
}

window.digitalData = {} as IDigitalData;
window.promptDigitalData = {} as IPromptDigitalData;

window.FormatStringWithLinks = (strings: string[], ...links: string[]) => {
  var output: JSX.Element[] = [];
  // eslint-disable-next-line
  links.map((l, i) => {
    let linkArr = l.split("|");
    if (linkArr && linkArr.length === 2) {
      let element = (
        <TextLink
          key={linkArr[1]}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            window.open(linkArr[0], "_blank");
          }}
        >
          {linkArr[1]}
        </TextLink>
      );
      output.push(<>{strings[i]}</>);
      output.push(element);
    }
  });
  if (strings.length > links.length) {
    output.push(<>{strings[strings.length - 1]}</>);
  }
  return output;
};

//#endregion

initializeIcons();

loadTheme({
  defaultFontStyle: { fontFamily: "Open Sans, Helvetica, Arial, sans-serif" },
  palette: {
    neutralPrimary: "#252525",
    themeDark: "#005587",
    themePrimary: "#0076a8",
  },
});

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [isServiceDataLoaded, loadApp] = useState(false);
  const [showCompareArea, setShowCompareArea] = useState(true);
  let isApiSubscribedForVersion = true;
  let isApiSubscribedForKXNGVersion = true;
  const [isContributionPageAllowed, setIsContributionPageAllowed] =
    useState(true);
  const [isEditPageAllowed, setIsEditPageAllowed] = useState(true);
  const [isDisplayPageAllowed, setIsDisplayPageAllowed] = useState(true);
  const [isPublishingDashboardAllowed, setIsPublishingDashboardAllowed] =
    useState(true);
  const [isMyKxAllowed, setIsMyKxAllowed] = useState(true);
  const [isPromptByKXAllowed, setIsPromptByKXAllowed] = useState(true);
  const [isVipFromPromptByKXAllowed, setIsVipFromPromptByKXAllowed] = useState(true);

  useEffect(() => {
    if (isApiSubscribedForKXNGVersion) {
      SharedService.InitializeInstance().then((_) => {
        if (window.location.href.indexOf(Constants.Routing.CompareItems) !== -1)
          setShowCompareArea(false);
        else setShowCompareArea(true);
        loadApp(true);
        SharedService.GetInstance()
          .SiteSettings.Read.GetVersionFromWebAPI()
          .then((data: any) => {
            if (SharedService.GetInstance().Data.Version !== data.version) {
              localStorage.clear();
              window.location.reload();
              sessionStorage.removeItem("__dkm__TagsData");
              dispatch(setLoadTagsDataAction([]));
              dispatch(setGetAllDataAction([]));
            }
          });
      });
    }
    // eslint-disable-next-line
    return () => {
      isApiSubscribedForKXNGVersion = false;
    };
  }, []);

  useEffect(() => {
    if (isApiSubscribedForVersion) {
      SharedService.InitializeInstance().then((_) => {
        if (window.location.href.indexOf(Constants.Routing.CompareItems) !== -1)
          setShowCompareArea(false);
        else setShowCompareArea(true);
        loadApp(true);
        SharedService.GetInstance()
          .SiteSettings.Read.GetKXDocsVersionFromWebAPI()
          .then((data: any) => {
            if (SharedService.GetInstance().Data.KXDocsVersion !== data) {
              localStorage.clear();
              window.location.reload();
              sessionStorage.removeItem("__dkm__TagsData");
              dispatch(setLoadTagsDataAction([]));
              dispatch(setGetAllDataAction([]));
              dispatch(setLoadTagsDataAction([]));
              dispatch(setGetAllDataAction([]));
            }
          });

        SharedService.GetInstance()
          .GetPageLevelRestrictions()
          .then((data: any) => {
            let isContributionPageAllowed =
              data.find((r: any) => r.page === "NewContribution") === undefined;
            setIsContributionPageAllowed(isContributionPageAllowed);

            let isEditPageAllowed =
              data.find((r: any) => r.page === "EditForm") === undefined;
            setIsEditPageAllowed(isEditPageAllowed);

            let isDisplayPageAllowed =
              data.find((r: any) => r.page === "DisplayPage") === undefined;
            setIsDisplayPageAllowed(isDisplayPageAllowed);

            let isPublishingDashboardAllowed =
              data.find((r: any) => r.page === "PublishingDashboard") ===
              undefined;
            setIsPublishingDashboardAllowed(isPublishingDashboardAllowed);

            let isMyKxAllowed =
              data.find((r: any) => r.page === "MyKx") === undefined;
            setIsMyKxAllowed(isMyKxAllowed);

            let isPromptByKXAllowed =
              data.find((r: any) => r.page === "PromptByKX") === undefined;
            setIsPromptByKXAllowed(isPromptByKXAllowed);
          });

          SharedService.GetInstance()
          .SiteSettings.Read.GetUserRoleGroupsAndPrivileges()
          .then((response: any) => {
            setIsVipFromPromptByKXAllowed(response.roleGroups.vipForPromptByKX);
          });
      });
    }
    // eslint-disable-next-line
    return () => {
      isApiSubscribedForVersion = false;
    };
  }, []);

  const setShowCompareAreaFC = () => {
    if (window.location.href.indexOf(Constants.Routing.CompareItems) !== -1)
      setShowCompareArea(false);
    else setShowCompareArea(true);
  };

  return isServiceDataLoaded ? (
    <UserNeedProvider>
      <CompareContentProvider>
        <PageEditorProvider>
          <CollapsibleBaseProvider>
            <AttachmentNotificationProvider>
              <TagsDataProvider>
                <AllDataProvider>
                  <ErrorBoundary>
                    <BrowserRouter>
                      <ScrollToTop />
                      <Suspense fallback={<div>Loading...</div>}>
                        <ActivityTracker
                          renderComp={() => <div></div>}
                        ></ActivityTracker>
                        {window.location.href.indexOf(
                          Constants.Routing.CookiesConsent
                        ) !== -1 ||
                        window.location.href.indexOf(
                          Constants.Routing.Prompt
                        ) !== -1 
                        ? (
                          ""
                        ) : (
                          <Header
                            setShowCompareArea={setShowCompareAreaFC}
                          ></Header>
                        )}
                        {CommonFunctions.isIE() ? (
                          <ModalBase
                            title={
                              SharedService.GetInstance().Data.Labels[
                                Constants.LabelKeys.ieMsgTitle
                              ]
                            }
                            withCancelBtn={true}
                            isOpen={true}
                          >
                            <p style={{ width: "100%", textAlign: "center" }}>
                              {
                                SharedService.GetInstance().Data.Labels[
                                  Constants.LabelKeys.ieMsgBody
                                ]
                              }
                            </p>
                          </ModalBase>
                        ) : null}
                        <Switch>
                          <Route exact path="/" component={HomePage} />
                          <Route
                            exact
                            path={Constants.Routing.Prompt}
                            component={
                              (isPromptByKXAllowed || isVipFromPromptByKXAllowed) ? Prompt : CountryRestriction
                            }
                          />
                          <Route
                            path={Constants.Routing.PracticeProfile}
                            component={PracticeProfilePage}
                          />

                          <Route
                            path={Constants.Routing.Collections}
                            component={ExploreCollection}
                          />

                          <Route
                            path={Constants.Routing.MyContribution}
                            component={MyContribution}
                          />

                          <Route
                            path={Constants.Routing.NewContributionID}
                            component={
                              isContributionPageAllowed
                                ? NewContribution
                                : UserNotAllowed
                            }
                          />

                          <Route
                            path={Constants.Routing.NewContribution}
                            component={
                              isContributionPageAllowed
                                ? NewContribution
                                : UserNotAllowed
                            }
                          />

                          <Route
                            path={Constants.Routing.TreePage}
                            component={TreePage}
                          />

                          <Route
                            path={Constants.Routing.AttachmentDownloadPage}
                            component={AttachmentDownload}
                          />

                          <Route
                            path={Constants.Routing.displayPage}
                            component={
                              isDisplayPageAllowed
                                ? DisplayPage
                                : UserNotAllowed
                            }
                          />

                          <Route
                            path={Constants.Routing.publisherDashBoard}
                            component={
                              isPublishingDashboardAllowed
                                ? PublisherDashBoard
                                : UserNotAllowed
                            }
                          />

                          <Route
                            path={Constants.Routing.myKxContribution}
                            component={
                              isMyKxAllowed ? MyKxContribution : UserNotAllowed
                            }
                          />

                          <Route
                            exact
                            path={Constants.Routing.Collection}
                            component={CuratedCollection}
                          />

                          <Route
                            path={Constants.Routing.collection}
                            component={CuratedCollection}
                          />

                          <Route
                            path={Constants.Routing.Drafts}
                            component={Drafts}
                          />

                          <Route
                            path={Constants.Routing.TaxonomyManagment}
                            component={TaxonomyManagment}
                          />

                          <Route
                            path={Constants.Routing.PersonalCollection}
                            component={PersonalCollectionPage}
                          />

                          <Route
                            path="/PracticeProfilePage"
                            component={PracticeProfilePage}
                          />

                          <Route
                            path={Constants.Routing.Search}
                            component={SearchPage}
                          />

                          <Route
                            path="/Navigation"
                            component={GlobalNavigation}
                          />

                          <Route
                            path="/KnowledgeItemHeader"
                            component={KnowledgeItemHeader}
                          />

                          <Route
                            path={Constants.Routing.PracticeProfiles}
                            component={PracticeProfileLobbyPage}
                          />

                          <Route
                            path={Constants.Routing.MyIntrests}
                            component={ManageMyInterestsPage}
                          />

                          <Route
                            path={Constants.Routing.MySubscription}
                            component={SubscriptionPage}
                          />
                          <Route
                            path={Constants.Routing.MySubscription}
                            component={SubscriptionPage}
                          />

                          <Route path="/Cards" component={CardsWrapper} />

                          <Route
                            path={Constants.Routing.CompareItems}
                            component={CompareContentPage}
                          />

                          <Route
                            path={Constants.Routing.EditForm}
                            component={
                              isEditPageAllowed ? EditForm : UserNotAllowed
                            }
                          />

                          <Route
                            path={Constants.Routing.oldUrlView}
                            component={OldUrl}
                          />
                          <Route
                            path={Constants.Routing.UnauthorizedPage}
                            component={UnauthorizedPage}
                          />

                          <Route
                            path={Constants.Routing.DocumentAdmin}
                            component={DocumentAdmin}
                          />

                          <Route
                            path={Constants.Routing.CookiesConsent}
                            component={CookiesConsent}
                          />

                          <Route
                            path={Constants.Routing.PageNotFound}
                            render={(props) => (
                              <PageNotFound
                                is404={
                                  window.location.href.indexOf(
                                    Constants.QueryStringParameters.requestUrl
                                  ) !== -1
                                    ? true
                                    : false
                                }
                                {...props}
                              ></PageNotFound>
                            )}
                          />
                          <Route>
                            <Redirect
                              to={`/PageNotFound?${Constants.QueryStringParameters.requestUrl}=${window.location.href}`}
                            ></Redirect>
                          </Route>
                        </Switch>
                      </Suspense>
                      {window.location.href.indexOf(
                        Constants.Routing.CookiesConsent
                      ) !== -1 
                      ||
                      window.location.href.indexOf(Constants.Routing.Prompt) !==
                        -1 
                      ? (
                        ""
                      )
                       : (
                        <div>
                          <Footer />
                           {JSON.parse(
                            SharedService.GetInstance().Data.Labels[
                              Constants.LabelKeys
                                .KXPrompt_HomepageElementVisibility
                            ].toLowerCase()
                          ) && (
                            <>
                              <FloatingActionButton
                                onClick={() => setOpenModal(true)}
                              />
                              <TryPromptByKXPopup
                                open={openModal}
                                closeHandler={() => setOpenModal(false)}
                                backgroundImage={PopupBG}
                              />
                            </>
                          )}
                        </div>
                      )}
                      {showCompareArea ? <CompareArea></CompareArea> : null}
                    </BrowserRouter>
                  </ErrorBoundary>
                </AllDataProvider>
              </TagsDataProvider>
            </AttachmentNotificationProvider>
          </CollapsibleBaseProvider>
        </PageEditorProvider>
      </CompareContentProvider>
    </UserNeedProvider>
  ) : (
    <div />
  );
};

export default App;
