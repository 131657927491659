import {
  USER_NEEDS,
  SetUserNeedType
} from '../types'

const initialState: any = {
  userNeeds: false
}

export default function menuElementsReducer(state = initialState, action: SetUserNeedType): any {
  switch (action.type) {
    case USER_NEEDS:
      return {
        ...state,
        userNeeds: action.payload
      }
    default:
      return state
  }
}