import React from 'react';
import './breadcrumbCustomModern.scss';

export interface BreadcrumbCustomProps {
  item: string
}

export const BreadcrumbCustomModern: React.FC<BreadcrumbCustomProps> = props => {
  return (
    <div className='modern-banner-breadcrumb-wrapper'>
      <span className='modern-banner-breadcrumb'>
          <div className='modern-banner-breadcrumb-icon-cover'></div>
          <i className='icon-kx-ok-circled'></i>
          {props.item}
      </span>
    </div>
  );
};