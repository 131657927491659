import React, { useState, useEffect } from "react";
import { useId } from '@fluentui/react-hooks';
import { FontWeights, mergeStyleSets } from '@fluentui/react';
import { FocusTrapCallout, FocusZone, FocusZoneTabbableElements, ITag, TagPicker } from "office-ui-fabric-react";
import { SharedService } from "../../../services/SharedService";
import { IDashActivity } from "../../../models/IShared";
import { ThemeProvider, Spinner, mergeStyles, Stack, StackItem, Checkbox, IStackTokens } from '@fluentui/react';

export interface customDropOptions {
    dashActivityId: number,
    activityName: string | null,
    clientName: string | null,
    activityOwner: string | null
}

export const CustomDropdown: React.FC<{
    selected: (activities: any) => void,
    removeActivity: boolean,
    incomingActivity: any
}> = (props) => {
    const styles = mergeStyleSets({
        callout: {
            width: 320,
            maxWidth: '90%',
            maxHeight: '140px !important',
            overflowY: 'scroll',
        },
        title: {
            marginBottom: 12,
            fontWeight: FontWeights.semilight,
        },
        optionsDiv: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            borderBottom: '1px solid #cccccc',
            cursor: 'pointer',
            padding: '5px'
        },
        checkBox: {
            display: 'none'
        },
        p: {
            paddingBottom: '3px',
            margin: '0 0 5px'
        },
        b: {
            marginRight: '10px'
        },
        spinnerCustom: {
            height: '20px',
            marginBottom: '2rem'
        }
    });
    const _sharedService = SharedService.GetInstance();
    const [isCalloutVisible, setsCalloutVisible] = useState<boolean>(false);
    const buttonId = useId('callout-button');
    const [activities, setActivities] = useState<ITag[]>();
    const [activityData, setActivityData] = useState<customDropOptions[]>([]);
    const [loader, setLoader] = useState<boolean>();
    const containerStackTokens: IStackTokens = { childrenGap: 10 };

    useEffect(() => {
        setActivities(props.incomingActivity);
    }, [props.incomingActivity.length != 0]);

    useEffect(() => {
        activities?.pop();
    }, [props.removeActivity == true])

    useEffect(() => {
        allActivities(0);
    }, [])

    const allActivities = async (searchId: number) => {
        setLoader(true);
        const dashActivities: IDashActivity[] = await _sharedService.GetDashActivities(true, searchId);
        let data: customDropOptions[] = [];
        if (dashActivities) {
            dashActivities.map((i: any) => {
                let option: customDropOptions = {
                    dashActivityId: i.activityId,
                    activityName: i.activityName,
                    clientName: i.clientName,
                    activityOwner: i.activityOwner ? i.activityOwner : ""
                }
                data.push(option);
            })
            setActivityData(data);
        }
        setLoader(false);
    }

    const setOption = (m: any) => {
        setsCalloutVisible(false);
        let dataArray: ITag[] = [];
        let data: ITag = { key: m, name: m };
        dataArray.push(data);
        setActivities(dataArray);
        props.selected(dataArray);
    }

    const Tags: ITag[] = activityData.map(item => ({ key: item.dashActivityId.toString(), name: item.dashActivityId.toString() }));

    const filterSuggestedTags = (filterText: string) => {
        if (filterText.length >= 3) {
            setsCalloutVisible(true);
            allActivities(Number(filterText));
        } else {
            setsCalloutVisible(false);
        }
        return filterText ? Tags.filter(tag => !tag.name.toLowerCase().indexOf(filterText.toLowerCase())) : [];
    };

    const getTextFromItem = (item: ITag) => item.name;

    const item = (m: ITag[] | undefined) => {
        setActivities(m);
        props.selected(m);
    }

    const wrapperClass = mergeStyles({
        padding: 2,
        selectors: {
            '& > .ms-Shimmer-container': {
                margin: '10px 0px',
                padding: '0px 15px',
            },
        },
    });

    return (
        <>
            <div id={buttonId}>
                <TagPicker
                    onResolveSuggestions={filterSuggestedTags}
                    getTextFromItem={getTextFromItem}
                    itemLimit={1}
                    onChange={item}
                    selectedItems={activities}
                />
            </div>
            {isCalloutVisible ?
                <>
                    <FocusTrapCallout
                        role="alertdialog"
                        className={styles.callout}
                        gapSpace={0}
                        target={`#${buttonId}`}
                        onDismiss={() => setsCalloutVisible(false)}
                        setInitialFocus
                    >
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            {loader ?

                                <ThemeProvider className={wrapperClass}>
                                    <Spinner className={`spinner ${styles.spinnerCustom}`} />
                                </ThemeProvider>
                                :
                                <Stack tokens={containerStackTokens}>
                                    {activityData.map((m: customDropOptions, id: any) => (
                                        <Stack horizontal tokens={containerStackTokens} onClick={() => setOption(m.dashActivityId)}>
                                            <StackItem className={styles.checkBox} >
                                                <Checkbox id={id} />
                                            </StackItem>
                                            <StackItem className={styles.optionsDiv}>
                                                {m.dashActivityId ?
                                                    <p className={styles.p}><b>Activity ID:</b> {m.dashActivityId}</p>
                                                    :
                                                    ""
                                                }
                                                {m.activityName ?
                                                    <p className={styles.p}><b>Activity Name:</b> {m.activityName}</p>
                                                    :
                                                    ""
                                                }
                                                {m.clientName ?
                                                    <p className={styles.p}><b>Client Name:</b> {m.clientName}</p>
                                                    :
                                                    ""
                                                }
                                                {m.activityOwner ?
                                                    <p className={styles.p}><b>Activity Owner:</b> {m.activityOwner}</p>
                                                    :
                                                    ""
                                                }
                                            </StackItem>
                                        </Stack>
                                    )
                                    )}
                                </Stack>
                            }
                        </FocusZone>
                    </FocusTrapCallout>
                </> : ""}
        </>
    )
};

