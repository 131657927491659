import { REFINERS } from "../types";
import { IRefiner } from "../../models/IShared";

const setRefiners = (refiners: IRefiner[]) => {
  return {
    type: REFINERS,
    payload: refiners
  }
}

export default setRefiners;