import React, { useState, useEffect, useRef } from "react";
import { Link, Link as RouteLink, Redirect } from "react-router-dom";
import { IIconProps, Link as TextLink } from "office-ui-fabric-react";
import {
    Stack,
    IStackTokens,
    Spinner,
  } from '@fluentui/react';
  
import "./cookiesConsent.scss";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Constants } from "../../../helpers/Constants";
import logo from "../../../assets/images/header-logo.svg";
import { SharedService } from "../../../services/SharedService";
import { Events } from "../../../models/IShared";
import { useSelector, useDispatch } from "react-redux";
import { KxState, MenuElement } from "../../../store/types";
import { setMenuElements } from "../../../store/actions/menuActions";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useConst } from '@fluentui/react-hooks';
import { IconButton } from '@fluentui/react/lib/Button';
import UserProfileDropdown from "../header/components/userProfileDropdown";
import { ContextMenu } from "../contextMenu/contextMenu"; 
import LinkGenerator from "../linkGenerator/linkGenerator";
 


const hostName: string | undefined =
  window &&
  window.location &&
  window.location.host &&
  window.location.host.split(".").shift();



const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      var element=document.getElementsByClassName('ms-Layer--fixed');
      if (!ref.current || ref.current.contains(event.target) ||(element&&element.length>0&&element[0].contains(event.target))) {
        return;
      }

      handler(event);
    };

    document.addEventListener(Events.MouseDown, listener);
    document.addEventListener(Events.TouchStart, listener);

    return () => {
      document.removeEventListener(Events.MouseDown, listener);
      document.removeEventListener(Events.TouchStart, listener);
    };
  }, [ref, handler]);
};
interface IProps {
  setShowCompareArea?: () => void;
}

export const CookiesConsent: React.FunctionComponent<IProps> = (props:IProps) => {
  var _sharedService = SharedService.GetInstance();
  const containerStackTokens: IStackTokens = { childrenGap: 10 };

  const [menuItemsDash, setMenuItemsDash] = useState<any>(null);
  const [menuItemsUser, setMenuItemsUser] = useState<any>(null);
  const menuElementsState = useSelector((state: KxState) => state.menu);

  const dispatch = useDispatch();


  useEffect(() => {
    let _configurations = _sharedService.Data.Configuration;
    var MenuElementsOptions: MenuElement[] = _configurations
      ? JSON.parse(_configurations.MenuElementsOptions)
      : [];
    var MenuItemsDash: any = _configurations
      ? JSON.parse(_configurations.MenuItemsDash)
      : null;
    dispatch(setMenuElements(MenuElementsOptions));
    setMenuItemsDash(MenuItemsDash);
    var MenuItemsUser: any = _configurations
    var MenuElementsOptions: MenuElement[] = _configurations
      ? JSON.parse(_configurations.MenuElementsOptions)
      : [];
    var MenuItemsDash: any = _configurations
      ? JSON.parse(_configurations.MenuItemsDash)
      : null;
    dispatch(setMenuElements(MenuElementsOptions));

  }, []);

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header-top cookieheader d-flex">
            
              <RouteLink to="/" className="logo">
                <img
                  src={logo}
                  alt={
                    _sharedService.Data.Labels[Constants.LabelKeys.WebsiteName]
                  }
                />
              </RouteLink>
            
            <div className="dashboardicons">
            {menuItemsDash ? (
              <div className="header-context hiddenDownTablet">
                <ContextMenu
                  items={menuItemsDash.items}
                  opener={menuItemsDash.opener}
                  customClass={menuItemsDash.customClass}
                  analyticsType={"wafflemenu"}
                /></div>):("")}
            <span className="cookieprofile"><UserProfileDropdown></UserProfileDropdown></span>
            </div>
          </div>
       
        </div>
      </header>
      <Stack tokens={containerStackTokens}>
      <div className="ms-Grid">
        <div className="cookieslist">
        <h5>Last revised: [22/02/2023]</h5>
        <h1>Cookie Notice</h1>
        <h5>What is a cookie?</h5>
        <p>A cookie is a small text file placed on your device by the websites that you visit. Cookies are 
          then sent back to the website on each subsequent visit, or to another webpage that recognizes 
          that cookie. Cookies are used in order to make the website work, or to work more efficiently, 
          as well as to provide information to the owners of the website. Such information may be used 
          to provide a more personalized and responsive service. Cookies may be either first party cookies 
          set directly by us to your device, or third-party cookies set by a third-party provider on our 
          behalf. Whenever you use this system, information may be collected through the use of cookies 
          and other similar technologies (e.g., web beacons, tags, scripts, local storage). 
          </p>

          <p>This Cookie Notice is a part of our Privacy Notice. For more information about us, and how we process personal 
            information, please see our <a href={SharedService.GetInstance().Data.Labels[Constants.LabelKeys.PrivacyLink]}>Privacy Notice</a>.
          </p>
        {/* <p>Insert the following when personal information is collected via cookies: This Cookie Notice is a 
        part of our Privacy Notice. For more information about us, and how we process personal 
        information, please see our <LinkGenerator  linkTemplateText={SharedService.GetInstance().Data.Labels[Constants.LabelKeys.Privacy]
          }></LinkGenerator> Notice . </p> */}
        <h5>Cookies we use and why</h5>
        <p>This system uses cookies and web beacons that are classified into the following categories: </p>
        <ul>
          <li>“Strictly Necessary” which are necessary for the system to function and cannot be 
        switched off in our systems. They are set in response to actions made by you which 
        amount to a request for services, such as authenticating into the system or managing 
        your session with the requested service. </li>
        <li>
        “Analytics and Performance” which are necessary for our non-public and internal 
        systems to measure audience and usage statistics, as well as content consumption. 
        These enable us to obtain relevant usage information to produce internal analytics so 
        we can monitor and improve the performance of our systems. These cannot be switched 
        off in our non-public or internal systems.
        </li>
        <li>
        "Preference or Functionality" which allow websites to remember the users’ respective site preferences and choices. These cannot be switched off in our non-public or internal systems.
        </li>
        </ul>
        <p>To view a list of the cookies that are used by the system,  <a className="optanon-toggle-display">View Cookies</a> .</p>
        <h5>How long will cookies remain on my device? </h5>
        <p>The length of time cookies will stay on your device will depend on whether it is a “persistent” 
        or “session” cookie. A persistent cookie will be stored by a web browser and will remain valid 
        until its set expiration date, unless deleted by you before the expiration date. A session cookie, 
        on the other hand, will expire at the end of your web session, when the web browser is closed. 
        </p>
        <p>
          <span><b>Controller of the Cookies:</b></span>&nbsp;
            Deloitte Global Services Limited with registered office at Hill House, 1 Little New Street, London, EC4A 3TR decided to use the cookies specified on the website.
        </p>
        </div>
      </div>
      </Stack>
    </>
  );
};
