import * as React from "react";
import { DatePicker, IDatePicker, mergeStyleSets } from "@fluentui/react";
import {
  DateRangeType,
  DayOfWeek,
} from "office-ui-fabric-react/lib/DatePicker";
import { useEffect, useState } from "react";
import moment from "moment";
import { IDatePickerStyles } from "@fluentui/react-date-time/lib/DatePicker";
import { Icon } from "@fluentui/react";

const styles = mergeStyleSets({
  commondatepicker: {
    selectors: {
      "> *": { marginBottom: 15 },
      ".ms-DatePicker-callout": {
        position: "relative",
      },
    },
  },
  clearBtn: {
    marginRight: "26px !important",
    fontSize: "10px !important",
    marginTop: "4px !important",
    paddingLeft: "4px !important",
    cursor: "pointer",
  },
  ".ms-DatePicker": { position: "relative" },
});

export interface IDatepickerProps {
  isMonthPickerVisible?: boolean;
  dateRangeType: DateRangeType;
  showGoToToday: boolean;
  label?: string;
  setSelectedDate: any;
  selectedDate?: any;
  dateType?: string;
  minDate?: any;
  maxDate?: any;
  firstDayOfWeek?: DayOfWeek;
  setFromShowCalendar?: any;
  setToShowCalendar?: any;
  showCalender: boolean;
  dateInWords?: boolean;
  placeHolder?: string;
  className?: string;
  showClearButton?: boolean;
  onClearBtnClick?: () => void;
  clearBtnClassName?: string;
}

export const NewDatePickerBase: React.FunctionComponent<IDatepickerProps> = (
  props: IDatepickerProps
) => {
  const styleRules: IDatePickerStyles = {
    callout: "",
    icon: "",
    root: "",
    textField: "",
    statusMessage: { margin: 0 },
  };
  const [selectedDate, setSelectedDate] = useState<any>(props.selectedDate);
  const datePickerRef = React.useRef<IDatePicker>(null);
  const dayPickerStrings = {
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    days: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    mediumDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    shortDays: ["S", "M", "T", "W", "T", "F", "S"],
    goToToday: "Go to today",
    weekNumberFormatString: "Week number {0}",
    prevMonthAriaLabel: "Previous month",
    nextMonthAriaLabel: "Next month",
    prevYearAriaLabel: "Previous year",
    nextYearAriaLabel: "Next year",
    prevYearRangeAriaLabel: "Previous year range",
    nextYearRangeAriaLabel: "Next year range",
    closeButtonAriaLabel: "Close",
    monthPickerHeaderAriaLabel: "{0}, select to change the year",
    yearPickerHeaderAriaLabel: "{0}, select to change the month",
  };

  useEffect(() => {
    setSelectedDate(props.selectedDate);
  }, [props.selectedDate]);

  const onFormatDate = (date?: Date): string => {
    let newDate = "";
    if (props.dateType === "MM/DD/YYYY") {
      newDate = !date
        ? ""
        : date.getMonth() +
          1 +
          "/" +
          date.getDate() +
          "/" +
          (date.getFullYear() % 100);
    } else if (props.dateType === "DD MMM YYYY") {
      newDate = !date ? "" : moment(date).format("DD MMM YYYY");
    }
    return newDate;
  };
  const onClick = React.useCallback((): void => {
    setSelectedDate(undefined);
    datePickerRef.current?.focus();
    if (props.onClearBtnClick) props.onClearBtnClick();
  }, []);

  const onSelectDate = (date: any): void => {
    if (
      (props.minDate && date < props.minDate) ||
      (props.maxDate && date > props.maxDate)
    )
      return;
    setSelectedDate(date);
    props.setSelectedDate(date);
  };

  return (
    <div className={styles.commondatepicker}>
      <DatePicker
        componentRef={datePickerRef}
        label={props.label}
        allowTextInput={false}
        ariaLabel="Select a date"
        placeholder={props.placeHolder}
        isMonthPickerVisible={true}
        formatDate={onFormatDate}
        onSelectDate={onSelectDate}
        showGoToToday={true}
        value={selectedDate}
        firstDayOfWeek={
          props.firstDayOfWeek ? props.firstDayOfWeek : DayOfWeek.Sunday
        }
        strings={dayPickerStrings}
        minDate={props.minDate}
        maxDate={props.maxDate}
        className={props.className}
        highlightCurrentMonth={false}
        styles={styleRules}
        textField={{
          required: false,
          onRenderSuffix: props.showClearButton
            ? () => (
                <Icon
                  iconName="Clear"
                  className={styles.clearBtn}
                  onClick={onClick}
                />
              )
            : undefined,
        }}
      />
    </div>
  );
};
