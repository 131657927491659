import { CardData } from "../models/SharedModels";

import { ISearchBoxTracker } from "../models/IAnalytics";
import {
  IUserNeed,
  IRefiner,
  IGeneralSearch,
  ITagsData,
  IGetAllData,
  INSTagsData,
  Error,
} from "../models/IShared";
import {
  IConversationHistoryResponseObj,
  IConversationKxHistoryRequestObj,
  IConversationKxHistoryResponseObj,
  IConversationMessage,
  IPromptFeedbackRequestObj,
  IPromptFeedbackResponseObj,
  IPromptKxResultRequestObj,
  IPromptKxResultResponseObj,
  IPromptResponseObj,
  IQuickViewResultResponseObj,
  IResultsResponse,
} from "../models/KxPromptModels";

export interface KxState {
  menu: any;
  cardsData: any;
}
export interface personallCollectionState {
  cards: any;
}
export interface manageMyInterestsState {
  interests: any;
}
export interface personallCollectionIndexState {
  index: number;
}
export interface lastViewedState {
  cards: any;
}
export interface collectionState {
  cards: any;
}
export interface dpnState {
  cards: any;
}
export interface ShowFeaturedItemsState {
  show: boolean;
}
export interface showRecomendedForYouState {
  show: boolean;
}

export interface MenuElement {
  label: string;
  url: string;
  customProperties: CustomProperty[];
  children: MenuElement[];
}
export interface CustomProperty {
  Key: string;
  Value: any;
}

export interface MenuElementsState {
  menuElements: MenuElement[];
}
export interface TagsElement {
  tagsState: [];
}
export interface GetAllElement {
  getAllState: [];
}
export interface NSTagsElement {
  NStagsState: {};
}
export interface TagsElementState {
  tagsElements: TagsElement[];
}
export interface GetAllElementState {
  getAllElements: GetAllElement[];
}
export interface NSTagsElementState {
  NStagsElements: NSTagsElement;
}
export const SET_MENU_ELEMENTS = "SET_MENU_ELEMENTS";
export const SET_CARDS_DATA = "SET_CARDS_DATA";
export const SET_SEARCHBOX_DATA = "SET_SEARCHBOX_DATA";
export const GET_SEARCHBOX_DATA = "GET_SEARCHBOX_DATA";
export const REMOVE_ARCHIVED = "REMOVE_ARCHIVED";
export const CHANGE_INDEX_PERSONAL_COLLECTION =
  "CHANGE_INDEX_PERSONAL_COLLECTION";

export const ADD_DATA_TO_PERSONAL_COLLECTION =
  "ADD_DATA_TO_PERSONAL_COLLECTION";
export const ADD_DATA_TO_INTERESTS = "ADD_DATA_TO_INTERESTS";
export const ADD_DATA_TO_LAST_VIEWED = "ADD_DATA_TO_LAST_VIEWED";
export const ADD_DATA_TO_COLLECTION = "ADD_DATA_TO_COLLECTION";
export const ADD_DATA_TO_DPN = "ADD_DATA_TO_DPN";
export const SHOW_FEATURED_ITEMS = "SHOW_FEATURED_ITEMS";
export const SHOW_RECOMENDED_FOR_YOU = "SHOW_RECOMENDED_FOR_YOU";
export const LOAD_TAGS_DATA = "LOAD_TAGS_DATA";
export const GET_ALL_DATA = "GET_ALL_DATA";
export const LOAD_NSTAGS_DATA = "LOAD_NSTAGS_DATA";
interface GetMenuElementsAction {
  type: typeof SET_MENU_ELEMENTS;
  payload: MenuElement[];
}
export type MenuElementsActionTypes = GetMenuElementsAction;

interface GetCardsDatatsAction {
  type: typeof SET_CARDS_DATA;
  payload: CardData[];
}
interface GetSearchBoxDatatsAction {
  type: typeof SET_SEARCHBOX_DATA;
  payload: ISearchBoxTracker[];
}
interface AddToPersonalCollection {
  type: any;
  payload: CardData[];
}
interface Addinterests {
  type: any;
  payload: CardData[];
}
interface AddToPersonalCollectionIndex {
  type: any;
  payload: number;
}
interface AddToLastViewed {
  type: any;
  payload: CardData[];
}
interface AddToCollection {
  type: any;
  payload: CardData[];
}
interface AddToDPN {
  type: any;
  payload: CardData[];
}
interface ShowFeaturedItems {
  type: any;
  payload: boolean;
}
interface showRecomendedForYou {
  type: any;
  payload: boolean;
}

interface loadTagData {
  type: any;
  payload: ITagsData[];
}
interface loadGetAllData {
  type: any;
  payload: IGetAllData[];
}
interface loadNSTagData {
  type: string;
  payload: INSTagsData;
}
export type CardsDataActionTypes = GetCardsDatatsAction;
export type SearchBoxDataActionTypes = GetSearchBoxDatatsAction;
export type PersonalCollectionTypeActions = AddToPersonalCollection;
export type PersonalCollectionIndexTypeActions = AddToPersonalCollectionIndex;
export type LastViewedActionsTypeActions = AddToLastViewed;
export type CollectionActionsTypeActions = AddToCollection;
export type DPNActionsTypeActions = AddToDPN;
export type ShowFeaturedItemsTypeActions = ShowFeaturedItems;
export type showRecomendedForYouTypeAction = showRecomendedForYou;
export type ManageMyInterestsTypeActions = Addinterests;
export type loadTagDataTypeActions = loadTagData;
export type AllDataTypeActions = loadGetAllData;
export type loadNSTagDataTypeActions = loadNSTagData;

export const USER_NEEDS = "USER_NEEDS";
interface ISetUserNeed {
  type: typeof USER_NEEDS;
  payload: IUserNeed[];
}
export type SetUserNeedType = ISetUserNeed;

export const SET_SHOW_RECENT = "SET_SHOW_RECENT";

export const SET_USER_NEED_MODAL = "SET_USER_NEED_MODAL";
interface ISetUserNeedModal {
  type: typeof SET_USER_NEED_MODAL;
  payload: boolean;
}
export type SetUserNeedModalType = ISetUserNeedModal;

export const REFINERS = "REFINERS";
export const REFINERSOLD = "REFINERSOLD";
interface IRefinersState {
  type: typeof REFINERS;
  payload: IRefiner[];
}
export type SetRefinersState = IRefinersState;

export const SINGLE_REFINER = "SINGLE_REFINER";
interface ISingleRefinerState {
  type: typeof SINGLE_REFINER;
  payload: IRefiner;
}
export type SetSingleRefineState = ISingleRefinerState;

export const SEARCH_STATE = "SEARCH_STATE";

export const FILTERS_STATE = "FILTERS_STATE";

export const REFINERS_FILTERS_STATE = "REFINERS_FILTERS_STATE";

export const APPLYHASH_STATE = "APPLYHASH_STATE";

export const DATE_CREARED = "DATE_CREARED";

export const APPLY_FILTERS_STATE = "APPLY_FILTERS_STATE";

interface ISetDateCleared {
  type: typeof DATE_CREARED;
  payload: boolean;
}
export type SetDateCleared = ISetDateCleared;

export const GET_PROMPT_RESPONSE_REQUEST = "GET_PROMPT_RESPONSE_REQUEST";
export const GET_PROMPT_RESPONSE_SUCCESS = "GET_PROMPT_RESPONSE_SUCCESS";
export const GET_PROMPT_RESPONSE_FAILURE = "GET_PROMPT_RESPONSE_FAILURE";
export const RESET_PROMPT_RESPONSE_ERROR = "RESET_PROMPT_RESPONSE_ERROR";
export const RESET_INFO_MESSAGE = "RESET_INFO_MESSAGE";
export const CLEAR_PROMPT_RESPONSE = "CLEAR_PROMPT_RESPONSE";
export const ADD_PROMPT_KX_RESULTS = "ADD_PROMPT_KX_RESULTS";

export interface IGetPromptResponseRequestAction {
  type: typeof GET_PROMPT_RESPONSE_REQUEST;
}
export interface IGetPromptResponseSuccessAction {
  type: typeof GET_PROMPT_RESPONSE_SUCCESS;
  payload: IPromptResponseObj;
}
export interface IGetPromptResponseFailureAction {
  type: typeof GET_PROMPT_RESPONSE_FAILURE;
  payload: string;
}
export interface IResetPromptResponseError {
  type: typeof RESET_PROMPT_RESPONSE_ERROR;
}
export interface IResetInfoMessages {
  type: typeof RESET_INFO_MESSAGE;
}

export interface IClearPromptResponseAction {
  type: typeof CLEAR_PROMPT_RESPONSE;
}
export interface IAddPromptKXResultsAction {
  type: typeof ADD_PROMPT_KX_RESULTS;
  payload: IResultsResponse[];
}

// Check is modify response is clicked and store the previous state

export const SET_PROMPT_RESPONSE = "SET_PROMPT_RESPONSE";
export const SET_MODIFY_RESPONSE_CLICKED = "SET_MODIFY_RESPONSE_CLICKED";

export interface ISetPromptResponseAction {
  type: typeof SET_PROMPT_RESPONSE;
  payload: IPromptResponseObj | undefined;
}

export interface ISetModifyResponseClickedAction {
  type: typeof SET_MODIFY_RESPONSE_CLICKED;
  payload: boolean;
}

//Set contain specialists value when loading from history
export const SET_CONTAIN_SPECIALIST = "SET_CONTAIN_SPECIALIST";
export interface ISetContainSpecialistAction {
  type: typeof SET_CONTAIN_SPECIALIST;
  payload: boolean;
}

// Conversation Center Pane & Regenrate Response

export type MessageSenderType = "USER" | "SERVER";
export const ADD_PROMPT_MESSAGE = "ADD_PROMPT_MESSAGE";
export const CLEAR_PROMPT_MESSAGE = "CLEAR_PROMPT_MESSAGE";
export const SET_FOLLOWUP_PROMPTS = "SET_FOLLOWUP_PROMPTS";
export const CLEAR_FOLLOWUP_PROMPTS = "CLEAR_FOLLOWUP_PROMPTS";
export const REMOVE_LAST_SERVER_PROMPT_MESSAGE =
  "REMOVE_LAST_SERVER_PROMPT_MESSAGE";
export enum MessageSender {
  USER = "USER",
  SERVER = "SERVER",
}

export interface AddPromptMessageAction {
  type: typeof ADD_PROMPT_MESSAGE;
  payload: IConversationMessage;
}
export interface ClearPromptMessageAction {
  type: typeof CLEAR_PROMPT_MESSAGE;
  payload: IConversationMessage[];
}
export interface SetFollowUpPromptsAction {
  type: typeof SET_FOLLOWUP_PROMPTS;
  payload: Array<string>;
}
export interface ClearFollowUpPropmptsAction {
  type: typeof CLEAR_FOLLOWUP_PROMPTS;
  payload: Array<string>;
}

export interface RemoveLastServerMessageAction {
  type: typeof REMOVE_LAST_SERVER_PROMPT_MESSAGE;
}

// Conversation History
export const GET_ALL_CONVERSATION_HISTORY_REQUEST =
  "GET_ALL_CONVERSATION_HISTORY_REQUEST";
export const GET_ALL_CONVERSATION_HISTORY_SUCCESS =
  "GET_ALL_CONVERSATION_HISTORY_SUCCESS";
export const GET_ALL_CONVERSATION_HISTORY_ERROR =
  "GET_ALL_CONVERSATION_HISTORY_ERROR";
export const RESET_ALL_CONVERSATION_HISTORY_ERROR =
  "RESET_ALL_CONVERSATION_HISTORY_ERROR";

export interface IGetAllConversationHistoryRequestAction {
  type: typeof GET_ALL_CONVERSATION_HISTORY_REQUEST;
}

export interface IGetAllConversationHistorySuccessAction {
  type: typeof GET_ALL_CONVERSATION_HISTORY_SUCCESS;
  payload: IConversationHistoryResponseObj[];
}

export interface IGetAllConversationHistoryErrorAction {
  type: typeof GET_ALL_CONVERSATION_HISTORY_ERROR;
  payload: string;
}
export interface IResetAllConversationHistoryErrorAction {
  type: typeof RESET_ALL_CONVERSATION_HISTORY_ERROR;
}

// Prompt Feedback

export const ADD_PROMPT_FEEDBACK_REQUEST = "ADD_PROMPT_FEEDBACK_REQUEST";
export const ADD_PROMPT_FEEDBACK_SUCCESS = "ADD_PROMPT_FEEDBACK_SUCCESS";
export const ADD_PROMPT_FEEDBACK_ERROR = "ADD_PROMPT_FEEDBACK_ERROR";
export const RESET_ADD_PROMPT_FEEDBACK_ERROR =
  "RESET_ADD_PROMPT_FEEDBACK_ERROR";

export interface IAddPromptFeedbackRequestAction {
  type: typeof ADD_PROMPT_FEEDBACK_REQUEST;
  payload: IPromptFeedbackRequestObj;
}

export interface IAddPromptFeedbackSuccessAction {
  type: typeof ADD_PROMPT_FEEDBACK_SUCCESS;
  payload: IPromptFeedbackResponseObj;
}

export interface IAddPromptFeedbackErrorAction {
  type: typeof ADD_PROMPT_FEEDBACK_ERROR;
  payload: string;
}
export interface IResetAddPromptFeedbackErrorAction {
  type: typeof RESET_ADD_PROMPT_FEEDBACK_ERROR;
}

// KxResults on select from history

export const GET_KX_RESULTS_HISTORY_REQUEST = "GET_KX_RESULTS_HISTORY_REQUEST";
export const GET_KX_RESULTS_HISTORY_SUCCESS = "GET_KX_RESULTS_HISTORY_SUCCESS";
export const GET_KX_RESULTS_HISTORY_ERROR = "GET_KX_RESULTS_HISTORY_ERROR";
export const RESET_KX_RESULTS_HISTORY_ERROR = "RESET_KX_RESULTS_HISTORY_ERROR";

export interface IGetKxResultsFromHistoryRequestAction {
  type: typeof GET_KX_RESULTS_HISTORY_REQUEST;
  payload: IConversationKxHistoryRequestObj;
}

export interface IGetKxResultsFromHistorySuccessAction {
  type: typeof GET_KX_RESULTS_HISTORY_SUCCESS;
  payload: IConversationKxHistoryResponseObj[];
}

export interface IGetKxResultsFromHistoryErrorAction {
  type: typeof GET_KX_RESULTS_HISTORY_ERROR;
  payload: string;
}
export interface IResetKxResultsFromHistoryErrorAction {
  type: typeof RESET_KX_RESULTS_HISTORY_ERROR;
}

// Sidebar History Active Item & Load Conversation Fromn History

export const SET_SIDEBAR_HISTORY_ITEM_ACTIVE =
  "SET_SIDEBAR_HISTORY_ITEM_ACTIVE";
export const RESET_SIDEBAR_HISTORY_ITEM_ACTIVE =
  "RESET_SIDEBAR_HISTORY_ITEM_ACTIVE";

export interface ISetSidebarHistoryItemActive {
  type: typeof SET_SIDEBAR_HISTORY_ITEM_ACTIVE;
  payload: string;
}

export interface IReSetSidebarHistoryItemActive {
  type: typeof RESET_SIDEBAR_HISTORY_ITEM_ACTIVE;
}

export const LOAD_CONVERSATION_FROM_HISTORY = "LOAD_CONVERSATION_FROM_HISTORY";

export interface ILoadConversationFromHistory {
  type: typeof LOAD_CONVERSATION_FROM_HISTORY;
  payload: IConversationMessage[];
}

//CONVERSATION API GET KX RESULTS.
export const GET_KX_RESULTS_REQUEST = "GET_KX_RESULTS_REQUEST";
export const GET_KX_RESULTS_SUCCESS = "GET_KX_RESULTS_SUCCESS";
export const GET_KX_RESULTS_ERROR = "GET_KX_RESULTS_ERROR";
export const RESET_GET_KX_RESULTS_ERROR = "RESET_GET_KX_RESULTS_ERROR";

export interface IGetKxResultsRequestAction {
  type: typeof GET_KX_RESULTS_REQUEST;
  payload: IPromptKxResultRequestObj;
}

export interface IGetKxResultsSuccessAction {
  type: typeof GET_KX_RESULTS_SUCCESS;
  payload: IPromptKxResultResponseObj;
}

export interface IGetKxResultsErrorAction {
  type: typeof GET_KX_RESULTS_ERROR;
  payload: string;
}
export interface IResetGetKxResultsErrorAction {
  type: typeof RESET_GET_KX_RESULTS_ERROR;
}

//Quick View API
export const GET_QUICK_VIEW_RESULTS_REQUEST = "GET_QUICK_VIEW_RESULTS_REQUEST";
export const GET_QUICK_VIEW_RESULTS_SUCCESS = "GET_QUICK_VIEW_RESULTS_SUCCESS";
export const GET_QUICK_VIEW_RESULTS_ERROR = "GET_QUICK_VIEW_RESULTS_ERROR";
export const RESET_QUICK_VIEW_RESULTS_ERROR = "RESET_QUICK_VIEW_RESULTS_ERROR";
export const CLEAR_QUICK_VIEW_DATA = "CLEAR_QUICK_VIEW_DATA";
export const CLEAR_QUICK_VIEW_LOADING = "CLEAR_QUICK_VIEW_LOADING";

export interface IGetQuickViewResultsRequestAction {
  type: typeof GET_QUICK_VIEW_RESULTS_REQUEST;
}

export interface IGetQuickViewResultsSuccessAction {
  type: typeof GET_QUICK_VIEW_RESULTS_SUCCESS;
  payload: IQuickViewResultResponseObj;
}

export interface IGetQuickViewResultsErrorAction {
  type: typeof GET_QUICK_VIEW_RESULTS_ERROR;
  payload: string;
}
export interface IResetQuickViewResultsErrorAction {
  type: typeof RESET_QUICK_VIEW_RESULTS_ERROR;
}
export interface IClearQuickViewData {
  type: typeof CLEAR_QUICK_VIEW_DATA;
}
export interface IClearQuickViewLoading {
  type: typeof CLEAR_QUICK_VIEW_LOADING;
}

//DPN Image API
export const SET_DPN_IMAGE_LOADING = "SET_DPN_IMAGE_LOADING";
export interface ISetDPNImageLoading {
  type: typeof SET_DPN_IMAGE_LOADING;
  payload: boolean;
}
