import { ICountOfRefinersFilters } from '../../models/IShared'
import { REFINERS_FILTERS_STATE } from '../types'

const initialState: ICountOfRefinersFilters = {}

export default function refinersFiltersReducer(state: ICountOfRefinersFilters = initialState, action: any): any {
  switch (action.type) {
    case REFINERS_FILTERS_STATE:      
      return { 
        refinersFilters: action.payload.refinersFilters ? action.payload.refinersFilters : state,
      }
    default:
      return state
  }
}