import React, { useState, useEffect, useRef } from "react";
import { ScrollbarCustom } from "../scrollbarCustom/scrollbarCustom";
import {
  PrimaryButton,
  OverflowSet,
  Callout,
  Stack,
  StackItem,
  Text,
  mergeStyleSets,
  FontWeights,
} from "office-ui-fabric-react";
import { SharedService } from "../../../services/SharedService";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { TagList } from "../tagList/tagList";
import useProtectedActionButtons from "../customHooks/useProtectedActionButtons";
import { isEmpty } from "lodash";
import { Constants } from "../../../helpers/Constants";
import { isString } from "util";
import { useHistory } from "react-router";
import { TooltipBase } from "../tooltipBase/tooltipBase";
import { OverflowActions } from "../../../models/IShared";
import { useId } from '@fluentui/react-hooks';

export interface ModalVideoProps {
  cardData: any;
  metadataQuickView: any;
  indexKey: any;
  className: string;
  onRenderItem(item: any, card: any): any;
  callout?: boolean;
  calloutId(id: string): any;
  resetCallout(): any;
}

export const ModalBasic: React.FunctionComponent<ModalVideoProps> = (props) => {
  const styles = mergeStyleSets({
    callout: {
      width: 320,
      padding: '20px 24px',
      background: 'white'
    },
    title: {
      marginBottom: 12,
      fontWeight: FontWeights.semilight,
    }
  });
  const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);
  const protectedOverflowButtons = useProtectedActionButtons(
    props.cardData.listTitle,
    props.cardData.overflowItemsData[0].overflowItems
  );
  const [containerHeight, setContainerHeight] = useState<any>(null);
  const [containerWidth, setContainerWidth] = useState<any>(null);
  const [BadgeIcon] = useState("icon-kx-bullseye");
  const buttonId = useId('callout-button');
  const containerRef = useRef<any>(null)

  useEffect(() => {
    if (containerRef.current) {
      var height = containerRef ? containerRef.current.clientHeight : "";
      var width = containerRef ? containerRef.current.clientWidth : "";
      if (height > width) {
        var newHeight = 70
        var newWidth = 50
        setContainerHeight(newHeight + "px")
        setContainerWidth(newWidth + "px")
      }
      else if (height < width) {
        var newHeight = 50
        var newWidth = 70
        setContainerHeight(newHeight + "px")
        setContainerWidth(newWidth + "px")
      }
      else if (height == width) {
        var newHeight = 70
        var newWidth = 70
        setContainerHeight(newHeight + "px")
        setContainerWidth(newWidth + "px")
      }
    }
  }, [containerRef]);

  const getNoExplainOverflowItems = (items: OverflowActions[]) => {
    let filteredItems = items.filter((item) => {
      if (
        (item.key &&
          (item.key === Constants.ActionsButtonsKeys.ExplainData ||
            item.key === Constants.ActionsButtonsKeys.MetaData ||
            item.key === Constants.ActionsButtonsKeys.Compare ||
            item.key === Constants.ActionsButtonsKeys.EditItem ||
            item.key === Constants.ActionsButtonsKeys.DeleteItem
          ))) {
        return false;
      }
      return true;
    });
    return filteredItems;
  };

  useEffect(() => {
    if (props.callout === true) {
      setIsCalloutVisible(true);
    }
    props.calloutId(buttonId);
  }, [props.callout])

  const getTheRelevantValue = (data: string): any => {
    var arr = data.split(";");
    if (arr && arr.length > 1) {
      return arr[1]
    }
    return arr[arr.length - 1];
  };

  var tags: any[] = [];
  let history = useHistory();

  const getButtonLabel = () => {
    if (props.cardData.allData.apptype === "Clients")
      return props.cardData.allData.source === "KX Assets" ? "View Full Details and Attachments" : "View Client Profile"
    else if (props.cardData.allData.apptype === "Collections")
      return props.cardData.allData.source === "KX Assets" ? "View Full Details and Attachments" : "View Collection"
    else if (props.cardData.allData.apptype === "Enabling Team Profiles")
      return props.cardData.allData.source === "KX Assets" ? "View Full Details and Attachments" : "View Full Details"
    else if (props.cardData.allData.apptype === "Communications")
      return props.cardData.allData.source === "KX Assets" ? "View Full Details and Attachments" : "View Full Details"
    else if (props.cardData.allData.apptype === "Practice Information") {
      if (props.cardData.allData.appsubtype === "Practice Profiles")
        return props.cardData.ctName === "KX Assets" ? "View Full Details & Attachments" : "View Practice Profile"
      else
        return props.cardData.ctName === "KX Assets" ? "View Full Details & Attachments" : "View Full Details"
    }
    else
      return props.metadataQuickView.buttonLabel
  }

  const dismissPopup = () => {
    setIsCalloutVisible(false);
    props.resetCallout()
  }

  const filterDownload = (items: OverflowActions[]) => {
    const attachments: string = props.cardData.allData.kxa_attachments;
    const newItems: OverflowActions[] = []
    items.map((item) => {
      if (item.key === Constants.ActionsButtonsKeys.DownloadAll && attachments.length === 0) { }
      else
        newItems.push(item)
    })
    return newItems
  }

  const getDPNAlias = (val: string) => {
    let lastIndex = val.lastIndexOf("/");
    return val.substring(lastIndex + 1);
  }

  const getModalRegular = () => {
    var conceptTagsAdd = CommonFunctions.returnArrNoEmpty(props.cardData.allData["concepttags"]);
    var contentTagsAdd = CommonFunctions.returnArrNoEmpty(props.cardData.allData["contenttags"]);
    var topicTagsAdd = CommonFunctions.returnArrNoEmpty(props.cardData.allData["topictags"]);
    tags = conceptTagsAdd.concat(contentTagsAdd);

    return <div className="modal__body">
      {props.metadataQuickView != null && props.cardData != null ? (
        <>
          <ScrollbarCustom>
            <span className="content-tag">
              {props.cardData.tag ? SharedService.GetInstance().Data.Labels[props.cardData.tag[0].tagName] :
                props.cardData.allData.internalcontenttype == Constants.DPN || props.cardData.allData.internalcontenttype == Constants.DPNKXNG
                  ? Constants.DPN
                  : null}
            </span>
            <div className="heading-inner">
              <span
                className="image" style={{
                  backgroundImage: `url("${props.cardData.allData.internalcontenttype == Constants.DPN ?
                    props.cardData.imageData ?
                      props.cardData.imageData :
                      props.cardData.image :
                    props.cardData.image
                    }")`,
                }}
              >
              </span>
              {/* Field 1 */}
              {props.cardData.allData[props.metadataQuickView.field1[0].field] !== undefined ?
                <h2>{props.cardData.allData[props.metadataQuickView.field1[0].field]}</h2> :
                <h2>{props.cardData.allData.title}</h2>
              }
            </div>

            {/* Field 2 */}
            {props.metadataQuickView.field2.length > 0 && props.metadataQuickView.field2[0].label != "Badges" ? (
              <span className="notes">
                {props.metadataQuickView.field2.map(
                  (field: any, index: number) => {
                    if (field.field && props.cardData.followers.length)
                      return CommonFunctions.ParsingDataNotPromise(
                        field.field,
                        props.cardData.allData,
                        props.cardData.followers
                      ) + " "
                    else if (field.field)
                      return CommonFunctions.ParsingDataNotPromise(
                        field.field,
                        props.cardData.allData
                      ) + " "
                    else return isString(field) ? CommonFunctions.stripHtml(field) + " " : null;
                  }
                )}
              </span>
            ) : null}

            {/* Field 3 */}
            {props.metadataQuickView.field3.length > 0 ? props.metadataQuickView.field3[0].field.length > 0 ?
              <p className="quickviewdatelocation">
                {props.metadataQuickView.field3.map((field: any) => {
                  if (field.field) {
                    return (CommonFunctions.stripHtml(CommonFunctions.ParsingDataNotPromise(field.field, props.cardData.allData)) + " ")
                  }
                  else return isString(field) ? field + " " : null
                })}
              </p> : null : null}

            {/* Field 2 Badges */}
            {props.metadataQuickView.field2[0].label == "Badges" &&
              props.cardData.allData[props.metadataQuickView.field2[0].field].length > 0 && props.cardData.allData.badgesDB.length > 0 ? (
              <div className="badges">
                <strong className="modal-content-title title-sm">
                  {props.metadataQuickView.field2[0].label}
                </strong>
                <div className="ms-Grid-row">
                  {props.cardData.allData.badgesDB.map((badge: any) => {
                    return (
                      <div className="ms-Grid-col ms-lg4">
                        <Stack horizontal className="badge">
                          <TooltipBase content={<div ><h3>{badge.title}</h3><div>{badge.description}</div></div>}>
                            {badge.iconImg && badge.iconImg !== "" ? <div className="heading-icon">
                              <img width="24px" src={SharedService.SpDomainUrl + SharedService.spSiteUrl + badge.iconImg} />
                              {/* <i className="icon-kx-featured"></i> */}
                            </div> :

                              <div className="heading-icon badgeIcon">
                                <i className={BadgeIcon}></i>
                              </div>
                            }
                          </TooltipBase>
                          <p>{badge.title}</p>

                        </Stack>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {/* Field 4 */}
            {Array.isArray(props.cardData.allData[props.metadataQuickView.field4[0].field])
              ? <p>{CommonFunctions.stringFromArr(props.cardData.allData[props.metadataQuickView.field4[0].field])}</p> :
              props.cardData.allData[props.metadataQuickView.field4[0].field] ?
                <p dangerouslySetInnerHTML={{ __html: props.cardData.allData[props.metadataQuickView.field4[0].field] }}></p>
                : null}

            {/* Field Common */}
            {Object.keys(props.metadataQuickView).map((key, index) => {
              if (index > 3 && props.metadataQuickView[key][0].label) {
                if (props.metadataQuickView[key][0].field == "topictags") {
                  if (topicTagsAdd.length > 0)
                    return (
                      <div className="tags-section">
                        <strong className="modal-content-title">
                          {props.metadataQuickView[key][0].label}
                        </strong>
                        <div className="tags">
                          <TagList tagListData={topicTagsAdd} itemsVisible={8} />
                        </div>
                      </div>
                    );
                } else if (props.metadataQuickView[key][0].field.indexOf("concepttags") > -1 && props.metadataQuickView[key][0].field.indexOf("contenttags") > -1) {
                  if (tags.length > 0)
                    return (
                      <div className="tags-section">
                        <strong className="modal-content-title">
                          {props.metadataQuickView[key][0].label}
                        </strong>
                        <div className="tags">
                          <TagList tagListData={tags} itemsVisible={8} />
                        </div>
                      </div>
                    );
                } else if (props.metadataQuickView[key][0].field.indexOf("concepttags") > -1) {
                  if (conceptTagsAdd.length > 0)
                    return (
                      <div className="tags-section">
                        <strong className="modal-content-title">
                          {props.metadataQuickView[key][0].label}
                        </strong>
                        <div className="tags">
                          <TagList tagListData={conceptTagsAdd} itemsVisible={8} />
                        </div>
                      </div>
                    );
                } else if (props.metadataQuickView[key][0].field.indexOf("contenttags") > -1) {
                  if (contentTagsAdd.length > 0)
                    return (
                      <div className="tags-section">
                        <strong className="modal-content-title">
                          {props.metadataQuickView[key][0].label}
                        </strong>
                        <div className="tags">
                          <TagList tagListData={contentTagsAdd} itemsVisible={8} />
                        </div>
                      </div>
                    );
                } else if (props.metadataQuickView[key][0].field.indexOf("kxa_client") > -1) {
                  if (props.cardData.allData[props.metadataQuickView[key][0].field] &&
                    props.cardData.allData[props.metadataQuickView[key][0].field] != " " &&
                    getTheRelevantValue(props.cardData.allData[props.metadataQuickView[key][0].field]) !== "") {
                    return (
                      <Text block>
                        <b>{props.metadataQuickView[key][0].label}</b>
                        {Array.isArray(props.cardData.allData[props.metadataQuickView[key][0].field]) ?
                          CommonFunctions.stringFromArr(props.cardData.allData[props.metadataQuickView[key][0].field]) :
                          getTheRelevantValue(props.cardData.allData[props.metadataQuickView[key][0].field])}
                      </Text>
                    );
                  }
                } else if (props.metadataQuickView[key][0].field.indexOf("kxe_collectionleaders") > -1 || props.metadataQuickView[key][0].field.indexOf("kxe_keycontacts") > -1) {
                  if (props.cardData.allData[props.metadataQuickView[key][0].field] &&
                    props.cardData.allData[props.metadataQuickView[key][0].field] != " " &&
                    getTheRelevantValue(props.cardData.allData[props.metadataQuickView[key][0].field]) !== "") {
                    return (
                      <>
                        <b>{props.metadataQuickView[key][0].label}</b>
                        {Array.isArray(JSON.parse(props.cardData.allData[props.metadataQuickView[key][0].field])) &&
                          JSON.parse(props.cardData.allData[props.metadataQuickView[key][0].field]).map((item: any) => {
                            return <><a target="_blank" href={SharedService.dpnLink + getDPNAlias(item.DpnLink)}>{item.Name}</a>; </>
                          })
                        }
                      </>
                    );
                  }
                } else if (props.cardData.allData[props.metadataQuickView[key][0].field] && props.cardData.allData[props.metadataQuickView[key][0].field] != " ") {
                  return (
                    <Text block>
                      <b>{props.metadataQuickView[key][0].label}</b>
                      {Array.isArray(props.cardData.allData[props.metadataQuickView[key][0].field])
                        ? CommonFunctions.stringFromArr(props.cardData.allData[props.metadataQuickView[key][0].field])
                        : getTheRelevantValue(props.cardData.allData[props.metadataQuickView[key][0].field])}
                    </Text>
                  );
                }
              }
            })}
          </ScrollbarCustom>
          <div className="modal__bottom-bar">
            <Stack className="centered-buttons">
              <Stack.Item align="center">
                <PrimaryButton
                  text={getButtonLabel()}
                  className="ms-Button--xl centerButt"
                  onClick={(e) => {
                    CommonFunctions.doTrackingMiniCard(Constants.MiniCardActions.ViewFullContent, props.cardData);
                    if (e) e.stopPropagation();
                    CommonFunctions.redirectLink(props.cardData.ctName == Constants.DPN ? true : props.cardData.openNewTab, props.cardData.linkToItem, history);
                  }}
                />
              </Stack.Item>
            </Stack>
            {isCalloutVisible ?
              <Callout className={styles.callout} preventDismissOnResize={true} preventDismissOnLostFocus={true} preventDismissOnScroll={true} role="dialog" gapSpace={0} setInitialFocus target={`#${buttonId}`}>
                <Stack horizontal horizontalAlign="space-between">
                  <StackItem>
                    <Text variant="xLarge" className={styles.title}>
                      Usage Guideline
                    </Text>
                  </StackItem>
                  <StackItem>
                    <i className="icon-kx-close text--Sm c-p" onClick={dismissPopup}></i>
                  </StackItem>
                </Stack>
                <Text variant="small">
                  {props.cardData.allData?.usageguidelines ? <div dangerouslySetInnerHTML={{ __html: props.cardData.allData?.usageguidelines }}></div> : ""}
                </Text>
              </Callout> : null}
            {!isEmpty(protectedOverflowButtons) && (
              <OverflowSet
                className="new-ms-OverflowSet"
                aria-label={
                  SharedService.GetInstance().Data.Labels[
                  Constants.LabelKeys.ContextualMenuLabel
                  ]
                }
                role="menubar"
                key={props.indexKey}
                items={getNoExplainOverflowItems(filterDownload(protectedOverflowButtons))}
                onRenderOverflowButton={() => null}
                onRenderItem={(item) =>
                  props.onRenderItem(item, props.cardData)
                }
                doNotContainWithinFocusZone={true}
              />
            )}
          </div>
        </>
      ) : null}
    </div>

  }

  const getModalDPN = () => {
    var conceptTagsAdd = CommonFunctions.returnArrNoEmpty(
      props.cardData.allData[
      "concepttags"
      ])
    var contentTagsAdd = CommonFunctions.returnArrNoEmpty(
      props.cardData.allData[
      "contenttags"
      ])

    var askMeTags = CommonFunctions.returnArrNoEmpty(
      props.cardData.allData[
      "kxp_askmeabouttext"
      ])
    tags = conceptTagsAdd.concat(contentTagsAdd);
    var roles = CommonFunctions.returnRoles(props.cardData.allData)

    //tags=data.tagListData;
    return <div className="modal__bodyDPN">
      {props.metadataQuickView != null && props.cardData != null ? (
        <>
          <ScrollbarCustom>
            <span className="content-tag">
              {props.cardData.tag
                ? SharedService.GetInstance().Data.Labels[
                props.cardData.tag[0].tagName
                ]
                : props.cardData.allData.internalcontenttype == Constants.DPN || props.cardData.allData.internalcontenttype == Constants.DPNKXNG
                  ? Constants.DPN
                  : null}
            </span>
            <div className="heading-inner">
              <img ref={containerRef} style={{ visibility: 'hidden', position: 'absolute' }}
                src={props.cardData.allData.internalcontenttype == Constants.DPN
                  ? !isEmpty(props.cardData.imageData)
                    ? props.cardData.imageData
                    : props.cardData.image
                  : props.cardData.image} />

              <span
                className="imageDPN"
                style={{
                  height: containerHeight,
                  width: containerWidth,
                  backgroundImage: `url("${props.cardData.allData.internalcontenttype == Constants.DPN
                    ? !isEmpty(props.cardData.imageData)
                      ? props.cardData.imageData
                      : props.cardData.image
                    : props.cardData.image
                    }")`,
                }}
              ></span>
              <div>
                {/* Field 1 */}
                {props.cardData.allData[props.metadataQuickView.field1[0].field] !== undefined ?
                  <h2>{props.cardData.allData[props.metadataQuickView.field1[0].field]}</h2> :
                  <h2>{props.cardData.allData.title}</h2>}

                {/* Field 2 */}
                {props.metadataQuickView.field2.length > 0 ? (
                  <span className="notesDPN">
                    {props.metadataQuickView.field2.map(
                      (field: any, index: number) => {
                        // let hyphen = index >= 1 ? " " : " - ";
                        if (field.field)
                          return (
                            CommonFunctions.ParsingDataNotPromise(
                              field.field,
                              props.cardData.allData
                            ) ? CommonFunctions.ParsingDataNotPromise(
                              field.field,
                              props.cardData.allData
                            ) : ""
                          );
                        else return isString(field) ? field + " " : null;
                      }
                    )}
                  </span>
                ) : null}

                <br />

                {/* Field 3 */}
                {props.metadataQuickView.field3.length > 0 ? (
                  <span className="notes2DPN">
                    {props.metadataQuickView.field3.map((field: any) => {
                      if (field.field)
                        return (
                          CommonFunctions.ParsingDataNotPromise(
                            field.field,
                            props.cardData.allData
                          ) + " "
                        );
                      else return isString(field) ? field + " " : null;
                    })}
                  </span>
                ) : null}
              </div>
            </div>

            {/* Field 4 */}
            {props.cardData.allData[
              props.metadataQuickView.field4[0].field
            ] ? <Text block>
              <b>{props.metadataQuickView.field4[0].label}</b>
              {Array.isArray(
                props.cardData.allData[
                props.metadataQuickView.field4[0].field
                ]
              )
                ? CommonFunctions.stringFromArr(
                  props.cardData.allData[
                  props.metadataQuickView.field4[0].field
                  ]
                )
                : getTheRelevantValue(
                  props.cardData.allData[
                  props.metadataQuickView.field4[0].field
                  ]
                )}
            </Text> : null}

            {/* Field 5 */}
            {props.cardData.allData[props.metadataQuickView.field5[0].field] ?
              <Text block>
                <b>{props.metadataQuickView.field5[0].label}</b>
                {Array.isArray(props.cardData.allData[props.metadataQuickView.field5[0].field])
                  ? CommonFunctions.stringFromArr(
                    props.cardData.allData[
                    props.metadataQuickView.field5[0].field
                    ]
                  )
                  : getTheRelevantValue(props.cardData.allData[props.metadataQuickView.field5[0].field])
                }
              </Text> : null}

            {/* Badges Field 6 Badges*/}
            {props.metadataQuickView.field6[0].label == "Badges" &&
              props.cardData.allData[props.metadataQuickView.field6[0].field]?.length > 0 &&
              props.cardData.allData.badgesDB?.length > 0 ? (
              <div className="badges">
                <strong className="modal-content-title title-sm">
                  {props.metadataQuickView.field6[0].label}
                </strong>
                <div className="ms-Grid-row">
                  {props.cardData.allData.badgesDB.map((badge: any, index: number) => {
                    return (
                      <div className="ms-Grid-col ms-lg4">
                        <Stack horizontal className="badge" style={{ minHeight: '60px', maxHeight: '76px' }}>
                          {badge.iconImg && badge.iconImg !== "" ? <div className="heading-icon">
                            <img width="24px" src={badge.iconImg !== "" ? SharedService.SpDomainUrl + SharedService.spSiteUrl + badge.iconImg : ""} />
                          </div> :
                            <div className="heading-icon badgeIcon">
                              <i className={BadgeIcon}></i>
                            </div>
                          }
                          <p>{badge.title}</p>
                        </Stack>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {/* Badges Field 8 */}
            {props.metadataQuickView.field8.length > 0 && props.cardData.allData[
              props.metadataQuickView.field8[0].field] ?
              <Text block>
                <b>{props.metadataQuickView.field8[0].label}</b>
                {roles.map((data: any) => {
                  return <div>
                    {data}
                  </div>
                })}
                <br></br>
              </Text> : null
            }

            {/* Badges Field 9 */}
            {props.metadataQuickView.field9.length > 0 &&
              props.cardData.allData[props.metadataQuickView.field9[0].field] ?
              props.cardData.allData[props.metadataQuickView.field9[0].field].length > 500 ?
                <TooltipBase content={props.cardData.allData[
                  props.metadataQuickView.field9[0].field
                ]}>
                  <Text block>
                    <b>{props.metadataQuickView.field9[0].label}</b>
                    {props.cardData.allData[
                      props.metadataQuickView.field9[0].field
                    ].length > 500 ?
                      <span
                        dangerouslySetInnerHTML={{
                          __html: props.cardData.allData[
                            props.metadataQuickView.field9[0].field
                          ].replace(/(?:\r\n|\r|\n)/g, '<br>').substring(0, 500) + "..."
                        }}
                      ></span> : <span
                        dangerouslySetInnerHTML={{
                          __html: props.cardData.allData[
                            props.metadataQuickView.field9[0].field
                          ].replace(/(?:\r\n|\r|\n)/g, '<br>')
                        }}
                      ></span>}

                  </Text>
                </TooltipBase> :
                props.cardData.allData[props.metadataQuickView.field9[0].field] !== "NULL" ?
                  <Text block>
                    <b>{props.metadataQuickView.field9[0].label}</b>
                    {props.cardData.allData[
                      props.metadataQuickView.field9[0].field
                    ]}
                  </Text> : ""
              : null
            }

            {/* Badges Field 10 */}
            {props.metadataQuickView.field10 && askMeTags.length > 0 ?
              <Text style={{ paddingTop: '20px' }} block>
                <b>{props.metadataQuickView.field10[0].label}</b>
                <div className="tags-section">
                  <div className="tags">
                    <TagList tagListData={askMeTags} itemsVisible={8} />
                  </div>
                </div>

              </Text> : null
            }

            {/* Badges Field 11 */}
            {props.metadataQuickView.field11.length > 0 &&
              props.cardData.allData[props.metadataQuickView.field11[0].field] ?
              <Text block>
                <b>{props.metadataQuickView.field11[0].label}</b>
                <a target="_blank" href={SharedService.dpnLink + props.cardData.allData[
                  props.metadataQuickView.field11[0].field
                ]}>
                  {SharedService.dpnLink + props.cardData.allData[
                    props.metadataQuickView.field11[0].field
                  ]}
                </a>
              </Text>
              : null
            }

          </ScrollbarCustom>
          <div className="modal__bottom-bar">
            <Stack className="centered-buttons">
              <Stack.Item align="center">
                <PrimaryButton
                  text={props.metadataQuickView.buttonLabel}
                  className="ms-Button--xl centerButt"
                  onClick={(e) => {
                    CommonFunctions.doTrackingMiniCard(Constants.MiniCardActions.ViewFullContent, props.cardData);
                    if (e) e.stopPropagation();
                    CommonFunctions.redirectLink(
                      props.cardData.ctName == Constants.DPN
                        ? true
                        : props.cardData.openNewTab,
                      props.cardData.linkToItem,
                      history
                    );
                  }}
                />
              </Stack.Item>
            </Stack>
            {!isEmpty(protectedOverflowButtons) && (
              <OverflowSet
                className="new-ms-OverflowSet"
                aria-label={
                  SharedService.GetInstance().Data.Labels[
                  Constants.LabelKeys.ContextualMenuLabel
                  ]
                }
                role="menubar"
                key={props.indexKey}
                items={getNoExplainOverflowItems(protectedOverflowButtons)}
                onRenderOverflowButton={() => null}
                onRenderItem={(item) =>
                  props.onRenderItem(item, props.cardData)
                }
                doNotContainWithinFocusZone={true}
              />
            )}
          </div>
        </>
      ) : null}
    </div >

  }

  if (props.cardData.ctName == Constants.DPN)
    return (
      getModalDPN()
    );
  else
    return getModalRegular()
};
