import React, { lazy, Suspense } from 'react';

 const LazyPracticeProfilePage = lazy(() => import(/* webpackChunkName: "practiceprofile" */'./practiceProfilePage'));

interface ICompProps{
  id?:number;
}

const PracticeProfilePage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; } & ICompProps) => (
  <Suspense fallback={null}>
    <LazyPracticeProfilePage {...props} />
  </Suspense>
);

export default PracticeProfilePage;
