import React from "react";
import "./SearchAiDashboard.scss";
import Results from "../Results/Results";
import Guidelines from "../Guidelines/Guidelines";
import { RootState } from "../../../../../store/reducers";
import { useSelector } from "react-redux";
import HomeSearchPage from "../HomeSearchPage/HomeSearchPage";

interface ISearchAiDashboard {}

/**
 * SearchAiDashboard Component
 *
 * This component represents the main dashboard for the AI search functionality.
 * It consists of a conversation container, home search page, and guidelines/results container.
 */

const SearchAiDashboard: React.FC<ISearchAiDashboard> = () => {
  // Retrieve conversation messages from Redux state
  const { messages, kxResultsFromHistoryLoading } = useSelector(
    (state: RootState) => state.promptConversationReducer
  );
  const { loadingDPNUserImage } = useSelector(
    (state: RootState) => state.promptResponseReducer
  );

  return (
    <div className="ms-Grid search-ai-dashboard-wrapper ">
      <div className="ms-Grid-row search-ai-dashboard-container">
        <div className="ms-Grid-col ms-sm9 conversation-container">
          <HomeSearchPage />
        </div>
        <div className="ms-Grid-col ms-sm3 guidelines-results-container">
          {messages.length === 0 &&
          !kxResultsFromHistoryLoading &&
          !loadingDPNUserImage ? (
            <Guidelines />
          ) : (
            <Results />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchAiDashboard;
