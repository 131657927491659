import * as React from "react";
import { SharedService } from "../services/SharedService";
import { Constants } from "./Constants";
import { ModalBase } from "../components/shared/modalBase/modalBase";
import { StorageHandler, StorageType } from "./StorageHandler";

export interface IActivityTrackerProps {
  activityEvents: string[];
  inactivityEvents: string[];
  ignoredEventsWhenIdle: string[];
  renderComp: Function;
}

export interface IActivityTrackerState {
  currentState: string;
}

const ACTIVE = "active";
const IDLE = "idle";
const SECOND_IDLE = "second idle";

const DEFAULT_INITIAL_STATE = ACTIVE;

const DEFAULT_TIMER = "3300000"; //55 min (55*60*1000)

const SECOND_DEFAULT_TIMER = "3600000"; //1 hour

const DEFAULT_ACTIVITY_EVENTS = [
  "click",
  "mousemove",
  "keydown",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "focus",
];

const DEFAULT_INACTIVITY_EVENTS = ["blur", "visibilitychange"];

const DEFAULT_IGNORED_EVENTS_WHEN_IDLE = ["mousemove"];

export class ActivityTracker extends React.Component<
  IActivityTrackerProps,
  IActivityTrackerState
> {
  private sdService: SharedService;
  private doc: any = document;

  public hidden = "";
  public visibilityChangeEvent = "";
  public timer: any;
  public secondTimer: any;
  public dayTimer: any;
  public listeners = { [ACTIVE]: [], [IDLE]: [], [SECOND_IDLE]: [] };

  public static defaultProps = {
    activityEvents: DEFAULT_ACTIVITY_EVENTS,
    inactivityEvents: DEFAULT_INACTIVITY_EVENTS,
    ignoredEventsWhenIdle: DEFAULT_IGNORED_EVENTS_WHEN_IDLE,
    timeToIdle: 10000,
  };

  constructor(props: any) {
    super(props);
    this.sdService = SharedService.GetInstance();

    if (typeof document.hidden !== "undefined") {
      this.hidden = "hidden";
      this.visibilityChangeEvent = "visibilitychange";
    } else {
      const prefixes = ["webkit", "moz", "ms"];
      for (let i = 0; i < prefixes.length; i++) {
        const prefix = prefixes[i];
        if (typeof this.doc[`${prefix}Hidden`] !== "undefined") {
          this.hidden = `${prefix}Hidden`;
          this.visibilityChangeEvent = `${prefix}visibilitychange`;
          break;
        }
      }
    }
    this.state = {
      currentState: DEFAULT_INITIAL_STATE,
    };
  }

  public render(): React.ReactElement<{}> {
    return (
      <React.Fragment>
        <ModalBase
          isTwoButtons={false}
          withCancelBtn={false}
          onHideModal={() => {
            this.closeModal();
          }}
          button={{
            text: SharedService.GetInstance().Data.Labels[
              Constants.LabelKeys.IdleAcceptButton
            ],
            function: this.closeModal,
          }}
          title={
            SharedService.GetInstance().Data.Labels[
              Constants.LabelKeys.IdleHeader
            ]
          }
          isOpen={this.state.currentState === IDLE}
          needIcon={true}
          tracker={true}
        >
          <p className="text-center">
            {
              SharedService.GetInstance().Data.Labels[
                Constants.LabelKeys.IdlePriorMessage
              ]
            }
          </p>
        </ModalBase>
        <ModalBase
          isTwoButtons={false}
          withCancelBtn={false}
          onHideModal={() => {
            this.closeModal();
          }}
          button={{
            text: SharedService.GetInstance().Data.Labels[
              Constants.LabelKeys.IdleAcceptButton
            ],
            function: () => {
              StorageHandler.Remove(
                Constants.SessionStorageItems.SPAuth,
                StorageType.Local
              );
              window.location.reload();
            },
          }}
          title={
            SharedService.GetInstance().Data.Labels[
              Constants.LabelKeys.IdleHeader
            ]
          }
          isOpen={this.state.currentState === SECOND_IDLE}
          tracker={true}
        >
          <p className="text-center">
            {
              SharedService.GetInstance().Data.Labels[
                Constants.LabelKeys.IdleMessage
              ]
            }
          </p>
        </ModalBase>
      </React.Fragment>
    );
  }

  public componentDidMount() {
    this.init();
    this.setTimer();
  }

  public closeModal = () => {
    this.setState({ currentState: ACTIVE });
  };

  private handleUserActivityEvent = (event: any) => {
    if (this.state.currentState === ACTIVE) {
      clearTimeout(this.timer);
      clearTimeout(this.secondTimer);
      this.setTimer();
    }
  };

  private setTimer() {
    let timeout = DEFAULT_TIMER;
    let secondTimeout = SECOND_DEFAULT_TIMER;
    this.timer = setTimeout(
      () => this.setState({ currentState: IDLE }),
      parseInt(timeout)
    );
    this.secondTimer = setTimeout(
      () => this.setState({ currentState: SECOND_IDLE }),
      parseInt(secondTimeout)
    );
  }

  private init = () => {
    setInterval(() => {
      SharedService.GetInstance().SiteSettings.Read.GetMsalToken();
      SharedService.GetInstance()
        .SiteSettings.Read.GetMsalTokenDpn()
        .then((data) => {
          SharedService.DPNToken = data;
        });

      //Logic for prompt token
      const tokenKey = Constants.SessionStorageItems.AccessToken;
      var tokenRequest = SharedService.genaiAPIScopes.impersonation;
      tokenRequest.account = SharedService.GetInstance().account;

      SharedService.GetInstance()
        .msalClient.acquireTokenSilent(tokenRequest)
        .then((loginRes: any) => {
          sessionStorage.setItem(tokenKey, JSON.stringify(loginRes));
        });
    }, 1800000); //30 min
    setInterval(() => {
      SharedService.GetInstance()
        .SiteSettings.Read.ValidateToken()
        .then((data) => {
          if (data == null || data.SPAppToken == null) {
            this.setState({ currentState: SECOND_IDLE });
            console.log("SECOND_IDLE", data);
          } else {
            StorageHandler.Add(
              Constants.SessionStorageItems.SPAuth,
              data,
              StorageType.Local
            );
            SharedService.GetInstance().Data.SPAuth = data;
          }
        });
    }, 3300000); //10min : 600000 | 3300000 : 55 min (55*60*1000)
    this.setState({ currentState: ACTIVE });
    this.props.activityEvents.forEach((eventName) =>
      window.addEventListener(eventName, this.handleUserActivityEvent)
    );
  };
}
