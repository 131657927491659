import React, { useEffect, useState } from "react";
import { SharedService } from "../../../services/SharedService";
import {
  CardData,
  PeopleCardData,
  ICollectionCardData,
  IPeopleCardData,
  PersonalCollection,
} from "../../../models/SharedModels";
import {
  CardType,
  IContentTypeEditMapping,
  IExplainData,
  ICategory,
} from "../../../models/IShared";
import { CardBase } from "../cardBase/cardBase";
import { useDispatch } from "react-redux";
import { setCardsData } from "../../../store/actions/CardsDataActions";
import { Constants } from "../../../helpers/Constants";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { cloneDeep, isEmpty } from "lodash";
import { ModalBase } from "../modalBase/modalBase";
import { useCompareContentContext } from "../contextAPIs/comparContentContext";
import { SearchBox } from "office-ui-fabric-react";
import { TooltipBase } from "../tooltipBase/tooltipBase";
import LinkGenerator from "../linkGenerator/linkGenerator";

interface ICardsWrapperProps {
  contentTypeName?: any;
  cardsData?: any;
  featured?: boolean;
  modernUi?: boolean;
  slider?: boolean;
  displayViewList?: boolean;
  displayArea?: string;
  archivedDeletionHandler?: (cardData: CardData) => void;
  explainData?: IExplainData[];
  categoryMove?: (e: any, cardData: CardData) => void;
  chosenItems?: any[];
  loadingAll?: any;
  searchBoxData?: any;
  setActions?: any;
  highlights?:any;
}

const CardsWrapper: React.FC<ICardsWrapperProps> = (props) => {
  const [cardsState, setCardsState] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [filteredCategories, setFilteredCategories] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setItemsToCompare } = useCompareContentContext();
  const { setShowMaxItemValidationPopUp } = useCompareContentContext();
  const [showAddToSubscription, setShowAddToSubscription] = useState<any>(null);
  const [isOpenPinModal, setIsOpenPinModal] = useState<boolean>(false);
  const [cardToPin, setCardToPin] = useState<any>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [newCategory, setNewCategory] = useState<string>("");
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [existName, setExistName] = useState<boolean>(false);
  const [isNameEmpty, setIsNameEmpty] = useState<boolean>(false);
  const [highlighteData, setHighlightedData] = useState<any>([]);
  const [isNameCharLimitExceeded, setIsNameCharLimitExceeded] =
    useState<boolean>(false);

  const [deletionModalState, setDeletionModalState] = useState<{
    isOpen: boolean;
    itemId: number;
    listTitle: string;
  }>({ isOpen: false, listTitle: "", itemId: -1 });

  let _sharedService: SharedService = SharedService.GetInstance();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    if (props.setActions) {
      props.setActions(true);
    }
    let _configurations = _sharedService.Data.Configuration;
    let contentTypesMappingUrls = _configurations
      ? JSON.parse(_configurations.CTMappingUrl)
      : []; //get the linktoitem to internal content
    formatCardsData(contentTypesMappingUrls, _configurations);
  }, [props.displayViewList, props.cardsData]);

  let formatCardsData = async (
    contentTypesMappingUrls: any,
    _configurations: any
  ) => {
    let cardMetaData: any;
    let newCardsData: (ICollectionCardData | IPeopleCardData)[] = [];
    let arrOfIds: number[] = [];
    let arrOfContentType: string[] = [];
    let arrOfItemsLink: string[] = [];
    let followers: any;
    let pins: any;
    let arrOfCollections: any = [];
    props.cardsData?.map((card: any) => {
      let ctCorelationJson =
        SharedService.GetInstance().GetConfigurationMapping(card);
      let configCT = JSON.parse(_configurations.ContentTypesMapping);
      let ctMapping = configCT[ctCorelationJson.JsonMapping];
      let linksImg = CommonFunctions.ifExternal(
        card,
        ctMapping,
        contentTypesMappingUrls,
        props.featured
      );
      let relatedLinkSp = linksImg && linksImg.length > 0 ? linksImg[0] : "";
      let imgFromUrl = linksImg && linksImg.length > 0 ? linksImg[1] : "";
      card.relatedLinkSp = relatedLinkSp;
      card.imgFromUrl = imgFromUrl;
      arrOfIds.push(props.featured ? card.ID : card.id); //CREATE ARRAY OF IDS TO TAKE IN BATCH IF FOLLOW

      if (card.source == Constants.Images) {
        if (card.img_fullurl) {
          arrOfItemsLink.push(card.img_fullurl);
        }
      } else if (relatedLinkSp) {
        arrOfItemsLink.push(relatedLinkSp); //CREATE ARRAY OF LINKS TO TAKE IN BATCH IF PIN
      }
      arrOfContentType.push(
        props.featured ? card.Type : card.internalcontenttype
      ); //CREATE ARRAY OF CTS TO TAKE IN BATCH IF FOLLOW
      if (card.internalcontenttype == "Curated Collection") {
        arrOfCollections.push({
          SourceId: card.id,
          SourceContentType: card.internalcontenttype,
        });
      }
      setHighlightedData(props.highlights);
      return card;
    });

    var howMuchFollowers =
      await SharedService.GetInstance().Actions.Get.FollowersNumberBySourceId(
        arrOfCollections
      );
    followers = await SharedService.GetInstance().Actions.Get.GetIfFollows(
      arrOfIds,
      arrOfContentType
    );
    pins = await SharedService.GetInstance().Actions.Get.GetIfPins(
      arrOfItemsLink
    );

    props.cardsData?.map((card: any, i: number) => {
      let ctCorelationJson =
        SharedService.GetInstance().GetConfigurationMapping(
          card,
          props.featured
        );
      let configCT = JSON.parse(_configurations.ContentTypesMapping);

      let ctMapping = configCT[ctCorelationJson.JsonMapping];
      var ifPinCard = false;
      var ifPin =
        pins && pins.length > 0 && (card.relatedLinkSp || card.img_fullurl)
          ? pins.filter(
            (pin: any) =>
              (card.source === Constants.Images &&
                pin.SourceId == card.img_Id) ||
              pin.LinkToItem == card.relatedLinkSp
          )
          : [];
      if (ifPin.length > 0) {
        ifPinCard = true;
      }
      let ctId = ctMapping ? ctMapping.ctID : null;

      let ctUrlMapping: IContentTypeEditMapping =
        ctMapping && contentTypesMappingUrls[ctMapping.ctID];

      let listTitle = ctMapping ? ctMapping.listTitle : null;
      let editUrl = ctUrlMapping
        ? _configurations.SPSiteUrl + ctUrlMapping.EditItemUrl
        : "";
      let viewUrl = ctUrlMapping ? ctUrlMapping.ViewItemUrl : "";

      if (!card.IsRemoved) {
        if (ctMapping) {
          cardMetaData = {
            metadataCards: ctMapping.metadataCards, //need to replace the source with ctCorelationJson.JsonMapping
            actionButtons: ctMapping.actionButtons, //need to replace the source with ctCorelationJson.JsonMapping
            img: ctMapping.img,
            icon: ctMapping.icon,
          };
        }
      }
      // if (state) {
      var cardNewData: ICollectionCardData | IPeopleCardData;
      if (card.IsRemoved) {
        //ARCHIVED
        cardNewData = new CardData(
          i,
          card.appid,
          CardType.Single,
          ctId,
          listTitle,
          editUrl,
          viewUrl,
          [{ tagName: card.internalcontenttype, tagUrl: "#" }],
          "featured-solid",
          card.imgFromUrl,
          { text: card["kxe_title"], icon: "" },
          card["kxe_description"],
          card["kxe_description"],
          "",
          [],
          [],
          card.ID,
          card.relatedLinkSp,
          "",
          card.source,
          ifPinCard,
          invokeDeleteModal,
          false,
          "",
          false,
          card,
          card.IsRemoved ? card.IsRemoved : false,
          "",
          props.displayArea,
          i.toString(),
          undefined,
          
        );
      } else {
        if (props.featured) {
          //FEATURED
          cardMetaData.actionButtons[0].overflowItems = cardMetaData
            .actionButtons[0].overflowItems
            ? cardMetaData.actionButtons[0].overflowItems.filter(
              (action: any) => {
                return (
                  action.key !== "EditItem" &&
                  action.key !== "DeleteItem" &&
                  action.key !== "Follow" &&
                  action.key !== Constants.ActionsButtonsKeys.Compare
                );
              }
            )
            : cardMetaData.actionButtons.overflowItems;
          cardMetaData.actionButtons[0].items = [];
          var label = card.internalcontenttype;
          cardNewData = new CardData(
            i,
            card.ID,
            CardType.Single,
            ctId,
            listTitle,
            editUrl,
            viewUrl,
            [{ tagName: label, tagUrl: "#" }],
            card.showBadge ? "featured-solid" : "",
            card.imgFromUrl,
            {
              text: card.Title,
              icon:
                label.toLowerCase().indexOf("collection") > -1
                  ? "icon-kx-ok-circled green-circle-icon"
                  : "",
            },
            "",
            card.Abstract,
            "",
            [],
            cardMetaData.actionButtons,
            card.ID,
            card.relatedLinkSp,
            ctCorelationJson ? ctCorelationJson.Label : "",
            card.Type,
            ifPinCard,
            invokeDeleteModal,
            cardMetaData.metadataCards.openNewTab,
            props.featured ? "lg" : "",
            false,
            card,
            undefined,
            "",
            props.displayArea,
            (i + 1).toString()
          );
        } else {
          if (card.source == Constants.DPN) {
            //PEOPLE
            var relImgUrl = CommonFunctions.changeUrlSafari(
              SharedService.SpDomainUrl +
              SharedService.spSiteUrl +
              "/DKXKXImagesLibrary/People/Unknown-person.jpg"
            );
            var ifCityExist = card[cardMetaData.metadataCards.line2[2]];
            var ifCountryExist = card[cardMetaData.metadataCards.line2[0]];
            var line2 = "";
            if (ifCityExist == "" || ifCountryExist == "") {
              line2 = ifCountryExist + ifCityExist;
            } else {
              line2 =
                ifCountryExist +
                cardMetaData.metadataCards.line2[1] +
                ifCityExist;
            }
            // var linkToItem = CommonFunctions.userNameByEmail(
            //   card.kxp_email,
            //   card.kxp_accountname
            // );
            cardNewData = new PeopleCardData(
              i,
              CardType.Single,
              relImgUrl,
              card[cardMetaData.metadataCards.line1],
              line2,
              card[cardMetaData.metadataCards.line3],
              Array.isArray(cardMetaData.metadataCards.line4)
                ? cardMetaData.metadataCards.line4.map((data: any) => {
                  var splitArr = card[data] ? card[data].split(";") : null;
                  if (splitArr) return splitArr.join("->");
                  else return card[data];
                })
                : card[cardMetaData.metadataCards.line4],
              cardMetaData.actionButtons,
              card.id,
              card.source,
              [],
              card["kxp_email"],
              props.displayViewList ? true : false,
              card.internalcontenttype,
              card,
              card.appurl,
              props.displayArea,
              (i + 1).toString(),
              ifPinCard,
              card.badges.length > 0 ? "featured-solid" : ""
            );
          } else {
            //BASIC EXAMPLE:card[cardMetaData.metadataCards.line2]=cardMetaData.metadataCards.line2="TITLE"=>CARD["Title"]
            cardNewData = new CardData(
              i,
              card.appid,
              CardType.Single,
              ctId,
              listTitle,
              editUrl,
              viewUrl,
              [
                {
                  tagName: ctCorelationJson.Label,
                  tagUrl: "#",
                },
              ], //replace ctCorelationJson.Label SharedService.GetInstance().Data.Labels[ctCorelationJson.Label]
              card.badges.length > 0 ? "featured-solid" : "",
              card.imgFromUrl,
              {
                text: cardMetaData.metadataCards
                  ? card.source == "Images"
                    ? card.img_displayname
                    : card[cardMetaData.metadataCards.line1]
                  : "",
                icon: cardMetaData.icon
                  ? "icon-kx-ok-circled green-circle-icon"
                  : "",
              },
              cardMetaData.metadataCards
                ? card[cardMetaData.metadataCards.line2] &&
                  Array.isArray(card[cardMetaData.metadataCards.line2])
                  ? CommonFunctions.stringFromArr(
                    card[cardMetaData.metadataCards.line2]
                  )
                  : card[cardMetaData.metadataCards.line2]
                : "",
              cardMetaData.metadataCards
                ? card[cardMetaData.metadataCards.line3] &&
                  Array.isArray(card[cardMetaData.metadataCards.line3])
                  ? CommonFunctions.stringFromArr(
                    card[cardMetaData.metadataCards.line3]
                  )
                  : card[cardMetaData.metadataCards.line3]
                : "",
              cardMetaData.metadataCards
                ? card[cardMetaData.metadataCards.line4] &&
                  Array.isArray(card[cardMetaData.metadataCards.line4])
                  ? CommonFunctions.stringFromArr(
                    card[cardMetaData.metadataCards.line4]
                  )
                  : card[cardMetaData.metadataCards.line4]
                : "",

              cardMetaData.metadataCards
                ? Array.isArray(cardMetaData.metadataCards.line5)
                  ? cardMetaData.metadataCards.line5.map((field: string) =>
                    CommonFunctions.ParsingDataNotPromise(
                      field,
                      card,
                      howMuchFollowers
                    )
                  )
                  : [
                    CommonFunctions.ParsingDataNotPromise(
                      cardMetaData.metadataCards.line5,
                      card,
                      howMuchFollowers
                    ),
                  ]
                : "",
              cardMetaData.actionButtons,
              card.id,
              card.relatedLinkSp,
              ctCorelationJson.Label,
              card.source,
              ifPinCard,
              invokeDeleteModal,
              cardMetaData.metadataCards
                ? cardMetaData.metadataCards.openNewTab
                : "",
              props.featured ? "lg" : "sm",
              props.displayViewList ? true : false,
              card,
              undefined,
              "",
              props.displayArea,
              (i + 1).toString()
            );
          }
        }
      }
      if (
        window.location.href.indexOf(Constants.Routing.PersonalCollection) !==
        -1 &&
        cardNewData.overflowItemsData &&
        cardNewData.overflowItemsData.length > 0 &&
        cardNewData.overflowItemsData[0].overflowItems &&
        cardNewData.overflowItemsData[0].overflowItems.length > 0
      ) {
        cardNewData.overflowItemsData[0].overflowItems =
          cardNewData.overflowItemsData[0].overflowItems.filter(function (item) {
            return item.key !== Constants.ActionsButtonsKeys.Compare;
          });
      }

      //Check if user has edit and delete rights, generate buttons on the basis of that
      if (
        ctUrlMapping &&
        cardNewData &&
        !isEmpty(cardNewData.overflowItemsData) &&
        !isEmpty(cardNewData.overflowItemsData[0].overflowItems) &&
        (ctUrlMapping.CanBeEdited || ctUrlMapping.CanBeEdited)
      ) {
        let disAllowedOperationKeys: string[] = [];

        if (ctUrlMapping) {
          if (!ctUrlMapping.CanBeEdited) {
            disAllowedOperationKeys.push("EditItem");
          }
          if (!ctUrlMapping.CanBeDeleted) {
            disAllowedOperationKeys.push("DeleteItem");
          }
        } else {
          disAllowedOperationKeys = ["EditItem", "DeleteItem"];
        }

        if (!isEmpty(disAllowedOperationKeys)) {
          cardNewData.overflowItemsData[0].overflowItems =
            cardNewData.overflowItemsData[0].overflowItems.filter(function (
              item
            ) {
              return disAllowedOperationKeys.indexOf(item.key) == -1;
            });
        }
      }

      // Check if search metadata and explain feature on the minicard needs to be enabled
      if (!props.featured) {
        let miniCardSearchMetadata =
          CommonFunctions.getMinicardsSearchMetaConfig();

        if (
          miniCardSearchMetadata?.metadata?.enabled &&
          cardNewData.overflowItemsData &&
          cardNewData.overflowItemsData.length > 0
        ) {
          cardNewData.overflowItemsData[0].overflowItems.push(
            miniCardSearchMetadata?.metadata?.buttonConfig
          );
        }

        if (
          miniCardSearchMetadata?.boostingExplained?.enabled &&
          cardNewData.overflowItemsData &&
          cardNewData.overflowItemsData.length > 0
        ) {
          cardNewData.overflowItemsData[0].overflowItems.push(
            miniCardSearchMetadata?.boostingExplained?.buttonConfig
          );
        }
      }

      Promise.all(cardNewData.footer).then(async (data) => {
        //because footer need to wait if there is a line of total followers
        cardNewData.footer = data;
        if (cardNewData.ctName == Constants.DPN) {
          let imgData =
            await SharedService.GetInstance().Actions.Get.GetUserImage(
              (cardNewData as IPeopleCardData).email
            );
          (cardNewData as IPeopleCardData).imageData = imgData;
        }
        newCardsData[cardNewData.key] = cardNewData;
        if (props.cardsData.length === newCardsData.length) {
          let data1 = newCardsData.map((card1) => {
            let card = cloneDeep(card1);
            if (card.ctName && !(card as any).IsRemoved) {
              var overFlowItemsData = CommonFunctions.changeCardData(
                card.overflowItemsData[0].overflowItems,
                card,
                followers,
                pins,
                props.featured ? true : false,
                openModalAndGetCategories,
                props.explainData?.filter((d) => d.docId == card.sourceId),
                props.loadingAll,
                setItemsToCompare,
                setShowMaxItemValidationPopUp
              );
              card.followers = howMuchFollowers
              card.overflowItemsData[0].overflowItems = overFlowItemsData;
              if (props.featured) card.overflowItemsData[0].items = [];
              return card;
            } else {
              return card;
            }
          });
          setCardsState(data1);
          dispatch(setCardsData(data1 as any));
          setIsLoading(false);
          // props.setActions(false);
        }
      });
    });
  };

  const invokeDeleteModal = (itemId: number, listTitle: string) => {
    setDeletionModalState({ isOpen: true, listTitle, itemId });
  };

  const setShowSubscribe = (show: boolean, sourceId: string) => {
    setShowAddToSubscription({
      show: show,
      sourceId: sourceId,
    });
  };

  const addToSubscription = () => {
    _sharedService.Subscription.Get.AddFollowToSubscription(
      showAddToSubscription.sourceId
    );
    setShowAddToSubscription(null);
  };

  const closeSubscribe = (): any => {
    setShowAddToSubscription(null);
  };

  const openModalAndGetCategories = (
    original: any,
    card: any,
    doTracking: any
  ) => {
    return new Promise((resolve) => {
      if (
        card &&
        (card.linkToItem || (card.allData && card.allData.img_fullurl)) &&
        original &&
        original.name ==
        SharedService.GetInstance().Data.Labels[
        Constants.ActionsButtonsNames.pin_to_Collection
        ]
      ) {
        _sharedService.Actions.Get.GetPersonalCollectionCategories().then(
          (categories) => {
            var isSelected = false;
            categories.map(async (category: ICategory) => {
              if (category.Count < 100 && isSelected == false) {
                category.isSelected = true;
                isSelected = true;
              }
            });
            setCategories(categories);
            setFilteredCategories(categories);
            setIsOpenPinModal(true);
            setCardToPin(card);
            resolve(original);
          }
        );
      } else if (
        card &&
        (card.linkToItem || (card.allData && card.allData.img_fullurl)) &&
        original
      ) {
        SharedService.GetInstance()
          .Actions.Post.PinUnPin(
            new PersonalCollection(
              card.ctName,
              "uncategorized",
              card.allData && card.allData.source === Constants.Images
                ? card.allData.img_fullurl
                : card.linkToItem,
              card.source === Constants.DPN ||
                (card.allData && card.allData.source === Constants.DPN)
                ? card.title
                : card.title.text
                  ? card.title.text
                  : card.allData.title,
              "",
              card.image,
              card.allData && card.allData.source === Constants.Images
                ? card.allData.img_Id
                : card.sourceId,
              card.allData && card.allData.source === Constants.Images
                ? Constants.Images
                : card.allData.internalcontenttype
            )
          )
          .then(() => {
            if (original) {
              original.name =
                SharedService.GetInstance().Data.Labels[
                Constants.ActionsButtonsNames.pin_to_Collection
                ];
              card.ifPinCard = false;
            }
            //IF FAILED CHANGE TO THE OPPOSITE OPTIONS :PIN TO UN PIN
            // Adobe Analytics
            if (doTracking)
              CommonFunctions.doTracking(Constants.MiniCardActions.Unpin, card);
            resolve(original);
          });
      } else resolve(original);
    });
  };

  const deleteHandler = async () => {
    if (
      deletionModalState &&
      deletionModalState.itemId != -1 &&
      deletionModalState.listTitle != ""
    ) {
      setDeletionModalState({ isOpen: false, listTitle: "", itemId: -1 });
    }
  };

  var searchTermChanged = (event: any) => {
    let _uInput = event && event.target && event.target.value;
    if (_uInput.length <= 255) {
      setSearchTerm(_uInput);
      var categoriesParse = JSON.parse(JSON.stringify(categories));
      var filteredCategories = categoriesParse.filter((category: ICategory) => {
        return (
          category.Label.toLocaleLowerCase().indexOf(
            _uInput.toLocaleLowerCase()
          ) != -1
        );
      });
      setFilteredCategories(filteredCategories);
    } else {
      setError("top 255 chars");
    }
  };

  var onClickCategory = (e: any, id: number) => {
    var list = document.getElementsByClassName("categoriesList");
    for (var i = 0; i < list.length; i++) {
      var el = list[i];
      if (el && el.classList.contains("selectedCategory")) {
        el.classList.remove("selectedCategory");
      }
    }
    setSelectedCategoryId(id);
    e.currentTarget.classList.add("selectedCategory");
  };

  var createNewCategoryAndPin = async (cardsState: any) => {
    if (newCategory) {
      var existName =
        await SharedService.GetInstance().Actions.Get.GetIfExistCategoryPersonalCollection(
          newCategory
        );
      if (!existName) {
        setIsNameEmpty(false);
        setExistName(false);
        if (newCategory.length < 255) {
          setIsNameCharLimitExceeded(false);
          SharedService.GetInstance()
            .Actions.Get.AddPersonalCollectionCategory(newCategory)
            .then((data: any) => {
              setSelectedCategoryId(data);
              savePinToCategory(cardsState, data);
              setCreateNew(false);
              setNewCategory("");
            });
        } else setIsNameCharLimitExceeded(true);
      } else {
        setIsNameEmpty(false);
        setExistName(true);
        setIsNameCharLimitExceeded(false);
      }
    } else {
      setExistName(false);
      setIsNameEmpty(true);
      setIsNameCharLimitExceeded(false);
    }
  };

  var savePinToCategory = (
    cardsState: any,
    categoryId: any = selectedCategoryId
  ) => {
    var cardIndex = -1;

    let linkToItem =
      cardToPin.allData && cardToPin.allData.source === Constants.Images
        ? cardToPin.allData.img_fullurl
        : cardToPin.linkToItem;

    cardIndex = cardsState.findIndex(
      (card: any) =>
        (card.allData.source === Constants.Images &&
          card.allData.img_Id == cardToPin.allData.img_Id) ||
        card.linkToItem === linkToItem
    );

    var card = cardsState[cardIndex];
    card.isQuickView = cardToPin.isQuickView;

    if (card && linkToItem) {
      let sourceId =
        card.allData && card.allData.source === Constants.Images
          ? card.allData.img_Id
          : card.sourceId;

      SharedService.GetInstance()
        .Actions.Post.PinUnPin(
          new PersonalCollection(
            card.ctName,
            "uncategorized",
            linkToItem,
            card.source === Constants.DPN ||
              (card.allData && card.allData.source === Constants.DPN)
              ? card.title
              : card.title && card.title.text
                ? card.title.text
                : card.allData.title,
            "",
            card.image ? card.image : card.allData.img_fullurl,
            sourceId,
            card.allData && card.allData.source === Constants.Images
              ? Constants.Images
              : card.allData.internalcontenttype,
            categoryId
          )
        )
        .then(() => {
          if (card.overflowItemsData) {
            SharedService.GetInstance()
              .Actions.Get.GetIfPins([linkToItem])
              .then((pins) => {
                var ifPin =
                  pins && pins.length > 0
                    ? pins.filter(
                      (pin: any) =>
                        (card.allData.source === Constants.Images &&
                          pin.SourceId == sourceId) ||
                        pin.LinkToItem == linkToItem
                    )
                    : [];

                if (ifPin.length > 0) {
                  var indexPin =
                    card.overflowItemsData[0] &&
                    card.overflowItemsData[0].overflowItems.find(
                      (item: any) =>
                        item.key == Constants.ActionsButtonsKeys.PinToCollection
                    );
                  if (indexPin) {
                    indexPin.name =
                      SharedService.GetInstance().Data.Labels[
                      Constants.ActionsButtonsNames.unPin
                      ];
                    card.ifPinCard = true;
                  } else {
                    card.ifPinCard = false;
                  }
                }
                setCardsState(cardsState);
                setIsOpenPinModal(false);
                CommonFunctions.doTracking(Constants.MiniCardActions.Pin, card);
                card.isQuickView = false;
              });
          }
        });
    }
  };

  const actionDataForTabs = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: any
  ) => {
    if (newValue) {
      props.setActions(newValue);
    }
  };

  return (
    <>
      <div>
        {
          <CardBase
            isHorizontal={props.displayViewList ? true : false}
            dataForShimmer={props.cardsData}
            isLoading={isLoading}
            slider={props.slider}
            collection={cardsState}
            featured={props.featured ? true : false}
            modernUi={props.modernUi}
            archiveRemoval={props.archivedDeletionHandler}
            categoryMove={props.categoryMove}
            chosenItems={props.chosenItems}
            openModalEdit={openModalAndGetCategories}
            explainData={props.explainData}
            setShowSubscribe={setShowSubscribe}
            setActionsFlag={actionDataForTabs}
          ></CardBase>
        }
      </div>

      <ModalBase
        onHideModal={closeSubscribe}
        isTwoButtons={true}
        withCancelBtn={true}
        SecondButton={{
          text: _sharedService.Data.Labels[Constants.LabelKeys.YesButtonLabel],
          function: () => {
            addToSubscription();
          },
        }}
        button={{
          text: _sharedService.Data.Labels[Constants.LabelKeys.NoButtonLabel],
          function: () => {
            closeSubscribe();
          },
        }}
        title={
          _sharedService.Data.Labels[Constants.LabelKeys.CollectionAddedToList]
        }
        isOpen={showAddToSubscription && showAddToSubscription.show}
      >
        <span className="text-center">
          <LinkGenerator
            linkTemplateText={
              _sharedService.Data.Labels[
              Constants.LabelKeys.CollectionAddToSubscription
              ]
            }
          ></LinkGenerator>
        </span>
      </ModalBase>

      <ModalBase
        isTwoButtons={true}
        withCancelBtn={true}
        button={{
          text: SharedService.GetInstance().Data.Labels[
            Constants.LabelKeys.NoButtonLabel
          ],
          function: () =>
            setDeletionModalState({ isOpen: false, listTitle: "", itemId: -1 }),
        }}
        SecondButton={{
          text: SharedService.GetInstance().Data.Labels[
            Constants.LabelKeys.YesButtonLabel
          ],
          function: deleteHandler,
        }}
        title={
          _sharedService.Data.Labels[Constants.LabelKeys.ItemDeletionAlert]
        }
        isOpen={deletionModalState.isOpen}
      ></ModalBase>

      <ModalBase
        onHideModal={() => {
          setIsOpenPinModal(false);
          setCreateNew(false);
          setExistName(false);
        }}
        isTwoButtons={true}
        button={{
          text: SharedService.GetInstance().Data.Labels[
            Constants.LabelKeys.CancelCapitalButton
          ],
          function: () => {
            setIsOpenPinModal(false);
            setCreateNew(false);
            setExistName(false);
          },
        }}
        SecondButton={{
          text: SharedService.GetInstance().Data.Labels[
            Constants.LabelKeys.SaveCapitalButton
          ],
          function: async () => {
            if (createNew) {
              createNewCategoryAndPin(cardsState);
            } else {
              savePinToCategory(cardsState);
            }
          },
          noHideModal: true,
        }}
        title={"Pin To"}
        className="categoryModal"
        isOpen={isOpenPinModal}
      >
        <div style={{ width: "100%" }}>
          <div className="moreThan100">
            {
              SharedService.GetInstance().Data.Labels[
              Constants.LabelKeys.PinNewItemPopUp
              ]
            }
          </div>
          <div style={{ display: "flex" }}>
            <SearchBox
              className={"search-default search--lg newCategory"}
              onChange={searchTermChanged}
              defaultValue={searchTerm}
              value={searchTerm}
              placeholder={"Search Here"}
              disableAnimation
            />
          </div>
          <button
            className="moveButtonCreate"
            onClick={async () => {
              var existNameDefault = true;
              var index = 0;
              var name = "Category 0";
              while (existNameDefault == true) {
                index++;
                existNameDefault =
                  await SharedService.GetInstance().Actions.Get.GetIfExistCategoryPersonalCollection(
                    name + index
                  );
              }
              setNewCategory(name + index);
              var list = document.getElementsByClassName("categoriesList");
              for (var i = 0; i < list.length; i++) {
                var el = list[i];
                if (el && el.classList.contains("selectedCategory")) {
                  el.classList.remove("selectedCategory");
                }
              }
              setCreateNew(true);
            }}
          >
            <i className="icon-kx-plus" style={{ paddingRight: "3px" }} />
            Create new Category
          </button>

          <div className="categorieslistAll">
            {createNew ? (
              <>
                <input
                  maxLength={255}
                  value={newCategory}
                  onChange={(e: any) => {
                    setNewCategory(e.target.value);
                  }}
                  className="categoriesList"
                ></input>{" "}
                {existName ? (
                  <div className="errorExist">
                    This category already exist , Please select another name.
                  </div>
                ) : isNameEmpty ? (
                  <div className="errorExist">Please enter category name.</div>
                ) : isNameCharLimitExceeded ? (
                  <div className="errorExist">
                    Category name length should be less than 255 characters.
                  </div>
                ) : null}
              </>
            ) : null}
            {filteredCategories.map((category: ICategory) => {
              var count = props.chosenItems
                ? props.chosenItems.length + category.Count
                : category.Count;
              return (
                <button
                  disabled={count >= 100 || createNew}
                  onClick={(e: any) => onClickCategory(e, category.Id)}
                  className={
                    category.isSelected
                      ? "categoriesList selectedCategory"
                      : "categoriesList"
                  }
                >
                  <TooltipBase
                    content={category.Label + " (" + category.Count + ")"}
                  >
                    {category.Label + " (" + category.Count + ")"}
                  </TooltipBase>
                </button>
              );
            })}
          </div>
        </div>
      </ModalBase>
    </>
  );
};

export default CardsWrapper;
