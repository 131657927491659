import React, { useState } from "react";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { PrimaryButton } from "office-ui-fabric-react";

const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};

export interface popupDialogProps {

  title?: string;
  subText?: string;
  hideDialog?: any;
  toggleHideDialog?: () => void | any;
  btn1text?: string;
  onClickBtn1?: () => void | any;
  btn2text?: string;
  onClickBtn2?: () => void | any;
  hideTopBtn: boolean;
}

export const PopupDialog: React.FunctionComponent<popupDialogProps> = (props) => {

  const dialogContentProps = {
    type: DialogType.normal,
    title: props.title,
    closeButtonAriaLabel: 'Close',
    subText: props.subText
  };

  const modalProps = React.useMemo(
    () => ({
      styles: dialogStyles,
      isBlocking: true,
      className: props.hideTopBtn? 'modalButton' : ''
    }),
    [],
  );


  return (
    <Dialog
      hidden={props.hideDialog}
      onDismiss={props.toggleHideDialog}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <DialogFooter>
        {props.btn1text ? <PrimaryButton
          onClick={props.onClickBtn1}
          text={props.btn1text}
        /> : ""}
        {props.btn2text ? <PrimaryButton
          onClick={props.onClickBtn2}
          text={props.btn2text}
        /> : ""}
        {/* <PrimaryButton onClick={toggleHideDialog} text="Cn" /> */}
      </DialogFooter>
    </Dialog>

  );
};