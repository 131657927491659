import { Link } from "office-ui-fabric-react";
import React, { useState, useEffect } from "react";
import { Constants } from "../../../helpers/Constants";
import { CardSchema } from "../../../models/SharedModels";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { SharedService } from "../../../services/SharedService";
import { TooltipBase } from "../tooltipBase/tooltipBase";
import "./compareArea.scss";

export interface CompareItemProps {
    card: CardSchema;
    itemIndex: number;
    removeHandler: (event:any, itemIndex: number) => void;
  }

export const CompareItem: React.FunctionComponent<CompareItemProps> = (props) => {
  const titleClickHandler = (event: any, card: CardSchema) => {
    compareItemTracking(Constants.MiniCardActions.ViewFullContent, card);
    window.open(card.linkToItem ? card.linkToItem : card.allData.appurl, '_blank');
  }
  const compareItemTracking = (action:string, card:CardSchema) => {
    let compareTracker = {
      action: action,
      title: card.allData.title,
      url: card.linkToItem ? card.linkToItem : card.allData.appurl,
      position: (props.itemIndex + 1).toString(),
      sourceSystem: card.allData.internalcontenttype,
      sourceContentType: card.allData.apptype,
      contentID: card.allData.appid
    };
    AnalyticsService.trackComparePage(compareTracker);
  }
  return (
    <div className="compare-item-container">
        <div className="compare-item-remove">
			<Link onClick={(e: any) => {props.removeHandler(e, props.itemIndex)}}>x</Link>
        </div>
        <div className='compare-item-image' style={{ backgroundImage: `url("${props.card.allData.image}")` }}>
        </div>
        <div className='compare-item-title-container'>
          <div className='compare-item-title'>
          <TooltipBase content={props.card.allData.title}>
            <Link target='_blank' onClick={(e: any) => {titleClickHandler(e, props.card)}}>{props.card.allData.title}</Link>
          </TooltipBase>
          </div>
          <div className='card-base__footer'> {
              props.card.allData.globalcontenttype === 'Quals' ?
              <span>{props.card.footer[0]}</span> : <span>{props.card.allData.created}</span>
            }
            <span className='tag-name'>{SharedService.GetInstance().Data.Labels[props.card.tag[0].tagName]}</span>
          </div>
        </div>
    </div>
  );
};