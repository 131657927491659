import {
  IPromptFeedbackResponseObj,
  IPromptKxResultResponseObj,
  IPromptResponseObj,
  IResultsResponse,
} from "../../models/KxPromptModels";
import {
  GET_PROMPT_RESPONSE_REQUEST,
  GET_PROMPT_RESPONSE_SUCCESS,
  GET_PROMPT_RESPONSE_FAILURE,
  CLEAR_PROMPT_RESPONSE,
  ADD_PROMPT_FEEDBACK_ERROR,
  ADD_PROMPT_FEEDBACK_REQUEST,
  ADD_PROMPT_FEEDBACK_SUCCESS,
  ADD_PROMPT_KX_RESULTS,
  GET_KX_RESULTS_ERROR,
  GET_KX_RESULTS_REQUEST,
  GET_KX_RESULTS_SUCCESS,
  SET_PROMPT_RESPONSE,
  SET_MODIFY_RESPONSE_CLICKED,
  SET_DPN_IMAGE_LOADING,
  RESET_PROMPT_RESPONSE_ERROR,
  RESET_ADD_PROMPT_FEEDBACK_ERROR,
  RESET_GET_KX_RESULTS_ERROR,
  RESET_INFO_MESSAGE,
  SET_CONTAIN_SPECIALIST,
} from "../types";
import { DataActionTypes } from "../actions/promptResponseAction";

export interface IPromptResponseState {
  promptResponse: IPromptResponseObj | null | undefined;
  loadingResponse: boolean;
  error: string | null | undefined;
  loadingFeedback: boolean;
  promptFeedback: IPromptFeedbackResponseObj | undefined;
  feedbackError: string | undefined;
  kxResultsResponseObj: IPromptKxResultResponseObj | undefined;
  kxResultsResponseLoading: boolean;
  kxResultsResponseError: string | undefined;
  promptResponseOnModifySelected: IPromptResponseObj | null | undefined;
  modifyResponseSelected: boolean;
  loadingDPNUserImage: boolean;
  infoMessages: string[];
  prevKXResults: IResultsResponse[] | null;
}

const initialState: IPromptResponseState = {
  promptResponse: null,
  loadingResponse: false,
  error: null,
  loadingFeedback: false,
  promptFeedback: undefined,
  feedbackError: undefined,
  kxResultsResponseObj: undefined,
  kxResultsResponseLoading: false,
  kxResultsResponseError: undefined,
  promptResponseOnModifySelected: undefined,
  modifyResponseSelected: false,
  loadingDPNUserImage: false,
  infoMessages: [],
  prevKXResults: null,
};

const setKXResults = (
  promptResponse: IPromptResponseObj | null | undefined,
  kxResults: IResultsResponse[]
) => {
  if (!promptResponse) {
    const promptLocalResponse: IPromptResponseObj = {
      actualresults: undefined,
      filters: undefined,
      followup_questions: [],
      kxresults: kxResults,
      sessionid: "",
      summary: "",
      tokensconsumed: undefined,
      usage_guidelines: "",
      selectedcitation: [],
      infomessages: [],
      conversation_id: "",
      usageguidelines_citations_links: [],
      clarification_question: false,
      contain_specialist: false,
      is_updated_from_history: true,
    };
    return promptLocalResponse;
  }
  promptResponse.kxresults = kxResults;
  return promptResponse;
};

const setPromptResponse = (
  modifyResponseSelected: boolean,
  incomingResponse: IPromptResponseObj,
  currentResponse: IPromptResponseObj | undefined | null
) => {
  if (modifyResponseSelected && currentResponse) {
    const response: IPromptResponseObj = JSON.parse(
      JSON.stringify(currentResponse)
    );
    response.summary = incomingResponse.summary;
    response.sessionid = incomingResponse.sessionid;
    response.conversation_id = incomingResponse.conversation_id;
    response.contain_specialist = incomingResponse.contain_specialist;
    response.clarification_question = incomingResponse.clarification_question;
    return response;
  } else {
    return incomingResponse;
  }
};

const setContainSpecialist = (
  promptResponse: IPromptResponseObj | null | undefined,
  containSpecialist: boolean
) => {
  if (promptResponse) {
    promptResponse.contain_specialist = containSpecialist;
  }
  return promptResponse;
};

const promptResponseReducer = (
  state = initialState,
  action: DataActionTypes
): IPromptResponseState => {
  switch (action.type) {
    case GET_PROMPT_RESPONSE_REQUEST:
      return {
        ...state,
        loadingResponse: true,
        error: null,
        promptResponse: null,
      };
    case GET_PROMPT_RESPONSE_SUCCESS:
      return {
        ...state,
        loadingResponse: false,
        error: null,
        promptResponse: setPromptResponse(
          state.modifyResponseSelected,
          action.payload,
          state.promptResponseOnModifySelected
        ),
        infoMessages: action.payload.infomessages,
      };
    case GET_PROMPT_RESPONSE_FAILURE:
      return {
        ...state,
        loadingResponse: false,
        error: action.payload,
        promptResponse: null,
      };
    case RESET_PROMPT_RESPONSE_ERROR:
      return {
        ...state,
        error: null,
      };
    case RESET_INFO_MESSAGE:
      return {
        ...state,
        infoMessages: [],
      };
    case SET_PROMPT_RESPONSE:
      return {
        ...state,
        promptResponseOnModifySelected: action.payload,
      };
    case SET_CONTAIN_SPECIALIST:
      return {
        ...state,
        promptResponse: setContainSpecialist(
          state.promptResponse,
          action.payload
        ),
      };
    case SET_MODIFY_RESPONSE_CLICKED:
      return {
        ...state,
        modifyResponseSelected: action.payload,
      };
    case CLEAR_PROMPT_RESPONSE:
      return {
        ...state,
        promptResponse: null,
      };
    case ADD_PROMPT_FEEDBACK_REQUEST:
      return {
        ...state,
        loadingFeedback: true,
        promptFeedback: undefined,
        feedbackError: undefined,
      };
    case ADD_PROMPT_FEEDBACK_SUCCESS:
      return {
        ...state,
        loadingFeedback: false,
        promptFeedback: action.payload,
        feedbackError: undefined,
      };
    case ADD_PROMPT_FEEDBACK_ERROR:
      return {
        ...state,
        loadingFeedback: false,
        promptFeedback: undefined,
        feedbackError: action.payload,
      };
    case RESET_ADD_PROMPT_FEEDBACK_ERROR:
      return {
        ...state,
        feedbackError: undefined,
      };
    case ADD_PROMPT_KX_RESULTS:
      return {
        ...state,
        promptResponse: setKXResults(state.promptResponse, action.payload),
      };
    case GET_KX_RESULTS_REQUEST:
      return {
        ...state,
        kxResultsResponseObj: undefined,
        kxResultsResponseLoading: true,
        kxResultsResponseError: undefined,
      };
    case GET_KX_RESULTS_SUCCESS:
      return {
        ...state,
        kxResultsResponseObj: action.payload,
        kxResultsResponseLoading: false,
        kxResultsResponseError: undefined,
      };
    case GET_KX_RESULTS_ERROR:
      return {
        ...state,
        kxResultsResponseObj: undefined,
        kxResultsResponseLoading: false,
        kxResultsResponseError: action.payload,
      };
    case RESET_GET_KX_RESULTS_ERROR:
      return {
        ...state,
        kxResultsResponseError: undefined,
      };
    case SET_DPN_IMAGE_LOADING:
      return {
        ...state,
        loadingDPNUserImage: action.payload,
      };

    default:
      return state;
  }
};

export default promptResponseReducer;
