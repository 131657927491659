import { FILTERS_STATE } from '../types'
import { IFiltersState } from '../../models/IShared'

const initialState: IFiltersState = {
  filters: null, 
  hasResults: true  
}

export default function filtersReducer(state:IFiltersState = initialState, action: any): any {
  switch (action.type) {
    case FILTERS_STATE:      
      return { 
        filters: action.payload.filters ? action.payload.filters : state.filters,
        hasResults: action.payload.hasResults ? action.payload.hasResults : state.hasResults
      }
    default:
      return state
  }
}