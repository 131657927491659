import { identity } from "lodash";
import { Constants } from "../helpers/Constants";
import { SharedService } from "./SharedService";

export class KXTaxonomyTreeBuilder {
  private apiJson: Node[] | null; //this will be sorted by ExternalId after 'toForest' first init
  private forest: Node[] | null;
  private isDevEnvironemnt: boolean;
  private tableId?: number;
  private firmId?: number;

  constructor() {
    this.apiJson = null;
    this.forest = null;
    this.isDevEnvironemnt = window.location.href.indexOf("://localhost") != -1;
  }

  /**
   * @returns The promise returns the original tree. NOTE: do not change the data of the nodes as it will impact performance.
   * @param firmId
   * @param tableId see enum TableId
   */
  public async init(tableId: TableId, firmId: number) {
    this.firmId = firmId;
    this.tableId = tableId;
    let lock = new Promise<Node[] | null>((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      this.apiJson = this.forest = null;
      let _webAPIUrl = SharedService.webUrl;
      
      fetch(`${_webAPIUrl}api/taxonomy/GetAll?TableId=${tableId}&MemberFirmId=${firmId}`)
        .then((res: any) => {
          res.json().then((t: any) => {
            if (t) {
              this.apiJson = JSON.parse(t);
              this.forest = this.toForest(this.apiJson);
              this.apiJson =
                this.apiJson &&
                this.apiJson.sort((a, b) =>
                  a.ExternalId < b.ExternalId
                    ? -1
                    : a.ExternalId > b.ExternalId
                      ? 1
                      : 0
                );
              resolve(this.forest);
            } else {
              reject();
            }
          })
        })
        .catch((err) => {
          reject(err);
        })

    });
    return lock;
  }

  private BinarySearchIterative(
    arr: any,
    x: any,
    compareEqual: (a: any, b: any) => boolean,
    compareLess: (a: any, b: any) => boolean
  ) {
    let start = 0,
      end = arr && arr.length ? arr.length - 1 : -1;

    // Iterate while start not meets end
    while (start <= end) {
      // Find the mid index
      let mid = Math.floor((start + end) / 2);
      // If element is present at mid, return True
      if (compareEqual(arr[mid], x)) return mid;
      // Else look in left or right half accordingly
      else if (compareLess(arr[mid], x)) start = mid + 1;
      else end = mid - 1;
    }
    return -1;
  }

  /**
   * build a forest from data. (all nodes must be included)
   * @param all sorted array by Id.
   */
  public toForest(all?: Node[] | null): Node[] | null {
    if (!all) return this.forest;

    let forest: Node[] = [];
    for (let a of all) {
      if (a.ParentId) {
        let i = this.BinarySearchIterative(
          all,
          a.ParentId,
          (node: Node, id: Number) => node.Id === id,
          (node: Node, id: Number) => node.Id < id
        );
        if (i >= 0) {
          let par = all[i];
          a.ParentExternalId = par.ExternalId;
          if (!par.Nodes || !par.Nodes.forEach) par.Nodes = [];
          par.Nodes.push(a);
          if (!par.children || !par.children.forEach) par.children = [];
          a = {'value':a.Name,'label':a.Name,'id':a.Id,...a}
          par.children.push(a);
        }
      } else {
        // a = {'value':a.Name,'label':a.Name,...a}
        a.value = a.Name;
        a.label = a.Name;
        a.id = a.Id
        forest.push(a);
      }
    }
    return forest;
  }

  private indexOfNode(nodes: Node[], name: string, isContains?: boolean) {
    let i = 0;
    for (let node of nodes) {
      if (
        (!isContains && node.Name === name) ||
        (isContains && node.Name.indexOf(name) >= 0)
      )
        return i;
      i++;
    }
    return -1;
  }

  /**
   * search the forest for a term.
   * @param refiners results are derived from these refiners.
   * @param searchTerm results (leaves if asTree is true) contains this term. If empty, a copy of the forest's leaves will return (Pay attention to memory use).
   * @param asTree result is a forest from the leaves. else, results are only the leaves.
   */
  public GetSearchResuls(
    refiners: Refiner[],
    // refiners: any,
    searchTerm?: string,
    asTree?: boolean
  ): Node[] {
    if (!searchTerm) searchTerm = "";
    else searchTerm = searchTerm.trim().toLowerCase();

    let parenRefiners: any = [];

    refiners.forEach((r: Refiner) => {
      let k = parenRefiners[r.FullPath[0]];
      if (!k || k.PathLength > r.FullPath.length)
        parenRefiners[r.FullPath[0]] = {
          PathLength: r.FullPath.length,
          Val: r,
        };
    });

    let leaves: Node[] = [];
    for (let refiner of Object.values(parenRefiners) as any) {
      let i = this.BinarySearchIterative(
        this.apiJson,
        refiner.Val.Id,
        (a, b) => a.ExternalId == b,
        (a, b) => a.ExternalId < b
      );
      if (i >= 0) {
        leaves = leaves.concat(
          this.findInTree(this.apiJson ? [this.apiJson[i]] : [], searchTerm)
        );
      }
    }
    return asTree ? this.BuildForestFromLeaves2(leaves) : leaves;
  }

  public async getChildNodeExternalIds(
    rootNodeExternalId: string
  ): Promise<number[]> {
    let output: number[] = [];
    let _webAPIUrl = this.isDevEnvironemnt
      ? Constants.General.DevWebApiUrl
      : Constants.General.ProdWebApiUrl;
    let url = `${_webAPIUrl}api/taxonomy/GetById/${rootNodeExternalId}?TableId=${this.tableId
      }&MemberFirmId=${this.firmId}&IsExternalId=${true}`;
    let result = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let node: Node = JSON.parse(await result.json());

    if (node) {
      let nodeObj = this.getNodeObjByIdRec(node.Id, this.forest!);

      if (nodeObj) {
        output.push(nodeObj.ExternalId);
        this.getNodeIdsRec(nodeObj, output);
      }
    }
    return output;
  }

  private getNodeObjByIdRec(nodeId: number, nodes: Node[]): Node | null {
    if (!nodes) {
      return null;
    }

    let nodeIndex = this.BinarySearchIterative(
      nodes,
      nodeId,
      (a, b) => a.Id == b,
      (a, b) => a.Id < b
    );

    if (nodeIndex === -1) {
      for (let index = 0; index < nodes.length; index++) {
        let node = this.getNodeObjByIdRec(nodeId, nodes[index].Nodes!);

        if (node) {
          return node;
        }
      }
    } else {
      return nodes[nodeIndex];
    }
    return null;
  }

  private getNodeIdsRec(node: Node, output: number[]) {
    if (node && node.Nodes) {
      for (let index = 0; index < node.Nodes.length; index++) {
        output.push(node.Nodes[index].ExternalId);
        this.getNodeIdsRec(node.Nodes[index], output);
      }
    }
  }

  private findInTree(stack: Array<Node>, term: string): Node[] {
    let node: Node | null | undefined;
    let results: Node[] = [];
    let isLeaf;

    while (stack.length > 0) {
      node = stack.pop();
      isLeaf = node && (!Array.isArray(node.Nodes) || node.Nodes.length == 0);

      if (node) {
        if (isLeaf && node.Name.toLowerCase().indexOf(term) > -1) {
          results.push(node);
        } else if (!isLeaf) {
          stack = stack.concat(node.Nodes as Node[]);
        }
      }
    }

    // Didn't find it. Return null.
    return results;
  }

  private BuildForestFromLeaves2(leaves: Node[]) {
    let parentDict: any = {};
    let rootsIds: number[] = [];
    let stack = leaves.map((n) => ({ ...n, Nodes: undefined } as Node));
    while (stack.length) {
      let leaf = stack.pop();
      let parentId = leaf && leaf.ParentExternalId;
      if (typeof parentId == "number" || parentId >= 0) {
        if (parentDict && !parentDict[parentId]) {
          parentDict[parentId] = [];
          let parentIndex = this.BinarySearchIterative(
            this.apiJson,
            parentId,
            (a, b) => a.ExternalId == b,
            (a, b) => a.ExternalId < b
          );
          if (parentIndex >= 0 && this.apiJson) {
            stack.push({
              ...this.apiJson[parentIndex],
              Nodes: [] as Node[] | undefined,
            });
          }
        }
        parentDict[parentId].push(leaf);
      } else {
        if (leaf && rootsIds.indexOf(leaf.ExternalId) == -1)
          rootsIds.push(leaf.ExternalId);
      }
    }
    let roots: any = [];
    rootsIds.forEach((id) => {
      let rootIndex = this.BinarySearchIterative(
        this.apiJson,
        id,
        (a, b) => a.ExternalId == b,
        (a, b) => a.ExternalId < b
      );
      let root: any = this.apiJson
        ? { ...this.apiJson[rootIndex], Nodes: undefined }
        : {};
      this.buildSubTree(root, parentDict);
      roots.push(root);
    });
    stack = [...roots];
    while (stack.length) {
      let node: Node | undefined = stack.pop();
      this.buildSubTree(node, parentDict);
      if (node && node.Nodes && node.Nodes.length)
        stack = stack.concat(node.Nodes);
    }

    return roots;
  }

  private buildSubTree(root: Node | undefined, parentDict: any) {
    root && (root.Nodes = parentDict[root.ExternalId]);
  }

  /*
    private BuildForestFromLeaves(leaves: Node[]) {
        if (!Array.isArray(leaves) || leaves.length == 0)
            return undefined;
        let compareEqual = (node, pId) => node.ExternalId === pId;
        let compareLess = (node, pId) => node.ExternalId < pId;

        let forest: Node[] = [];
        let nodesStack: Node[] = [];
        
        leaves.forEach(l => {
            nodesStack = this.addToSortedForest(nodesStack, {...l});
        });
        let parentsStack = []

        while(nodesStack.length > 0) {
            let node = nodesStack.shift();
            let parentExId = node.ParentExternalId;
            let isRoot = typeof parentExId != "number" || parentExId < 0;
            let parentIndex = this.BinarySearchIterative(parentsStack, parentExId, compareEqual, compareLess);

            if (!isRoot && parentIndex < 0) { //add parent
                let pi = this.BinarySearchIterative(this.apiJson, parentExId, compareEqual, compareLess);
                let copyParent = {...this.apiJson[pi]};
                copyParent.Nodes = [node];
                parentsStack = this.addToSortedForest(parentsStack, copyParent);
            }
            else if (!isRoot) { //add node to parent
                let parent = parentsStack[parentIndex];
                parent.Nodes = this.addToSortedForest(parent.Nodes, node);
            }
            else if (isRoot) { //is root
                let rootIndex = this.BinarySearchIterative(forest, parentExId, compareEqual, compareLess);
                if (rootIndex < 0) { //add root to forest
                    forest = this.addToSortedForest(forest, node);
                }
            }
            if (nodesStack.length == 0 && parentsStack.length > 0) {//switch stacks
                let temp = nodesStack;
                nodesStack = parentsStack;
                parentsStack = temp;
            }
        }

        return forest;
    }*/

  private addToSortedForest(forest: Node[], node: Node) {
    if (forest.length == 0) {
      forest.push(node);
      return forest;
    }

    let i;
    for (i = 0; i < forest.length; i++) {
      if (forest[i].ExternalId >= node.ExternalId) break;
    }
    if (i == forest.length) forest.push(node);
    else {
      if (i > 0) {
        let left = forest.slice(0, i);
        forest = left.concat([node], forest.slice(i));
      } else forest = [node].concat(forest);
    }
    return forest;
  }
}

export interface Node {
  Id: any;
  ExternalId: any;
  ParentExternalId?: any;
  ParentId?: any;
  Parent	:	any;
  IsDeleted	:	boolean;
  Level?:number;
  Taxonomy_Id?:any;
  Name: any;
  FullPath?: any;
  Nodes?: Node[] | null;
  children?:Node[] | null;
  label?:String;
  value?:String;
  LocationId?:Number;
  Global?:null | any;
  GlobalId?:null | any;
  DeliveryDetailsSection?: null | any;
  id?:Number;
}

export interface Refiner {
  Id: number;
  Name: string;
  FullPath: string[];
}

export enum TableId {
  INDUSTRY = 1,
  BUSINESS,
  CLIENT_SERVICE,
  COUNTRY_OF_ORIGIN,
  LOCATIONS,
  TOPIC,
  CONCEPT,
  CONTENT,
  CONTENT_TYPE,
}

//Use example
// var b = new KXTaxonomyTreeBuilder();
// b.init(TableId.INDUSTRY, 2768).then(forestRef => {});
// var forestRef = b.toForest();
// b.GetSearchResuls()
