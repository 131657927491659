import React, { useState } from 'react';
import { DefaultButton, Link, Stack } from 'office-ui-fabric-react';
import { ResizeGroup } from 'office-ui-fabric-react/lib/ResizeGroup';
import { OverflowSet } from 'office-ui-fabric-react/lib/OverflowSet';
import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import './buttonsResizeGroup.scss';

export interface IOverflowData {
  primary: IContextualMenuItem[];
  overflow: IContextualMenuItem[];
}

export interface ButtonsResizeGroupProps {
  items: any,
}

export const ButtonsResizeGroup: React.FunctionComponent<ButtonsResizeGroupProps> = props => {
    const [onGrowDataEnabled, setOnGrowDataEnabled] = useState(false);
    const dataToRender = (): IOverflowData => {
      const result: IOverflowData = {
        primary: props.items,
        overflow: [] as any[],
      };

      return result;
    };
    
    const onRenderItem = (item: any) => (
      <Link
        className="link-bordered bg-white"
      >{item.name}</Link>
    );

    const onReduceData = (currentData: any) => {
        if (currentData.primary.length === 0) {
            return undefined;
        }
        const overflow = [...currentData.primary.slice(-1), ...currentData.overflow];
        const primary = currentData.primary.slice(0, -1);
        let cacheKey = undefined;

        return { primary, overflow, cacheKey };
    };

    const onGrowData = (currentData: any) => {
        if (currentData.overflow.length === 0) {
            return undefined;
        }
        const overflow = currentData.overflow.slice(1);
        const primary = [...currentData.primary, ...currentData.overflow.slice(0, 1)];
        let cacheKey = undefined;

        return { primary, overflow, cacheKey };
    };

    const onRenderOverflowButton = (overflowItems: any) => (
        <DefaultButton className="bg-white" text={`+${overflowItems.length}`} />
    );

    const onRenderData = (data: any) => {
        return (
        <OverflowSet
            role="menubar"
            items={data.primary}
            overflowItems={data.overflow.length ? data.overflow : null}
            onRenderItem={onRenderItem}
            // eslint-disable-next-line react/jsx-no-bind
            onRenderOverflowButton={onRenderOverflowButton}
        />
        );
    };

    return (
        <Stack horizontal className="btns-resize-container">
            {props.items.label && (
                <Stack.Item shrink={0}>
                    <span className="btns-label">{props.items.label}</span>
                </Stack.Item>
            )}
            <Stack.Item grow={1}>
                <ResizeGroup
                    className="btns-resize-group"
                    role="tabpanel"
                    aria-label="Resize Group with an Overflow Set"
                    data={dataToRender()}
                    // eslint-disable-next-line react/jsx-no-bind
                    onReduceData={onReduceData}
                    // onGrowData={onGrowDataEnabled ? onGrowData : undefined}
                    // eslint-disable-next-line react/jsx-no-bind
                    onRenderData={onRenderData}
                />
            </Stack.Item>
        </Stack>
    );
};
