import React, { useEffect, useState } from "react";
import "./Results.scss";
import { useDispatch, useSelector } from "react-redux";
import RegenerateDisabled from "../../../../../assets/prompt/regenerate-disabled.svg";
import Regenerate from "../../../../../assets/prompt/regenerate.svg";
import ResultsIcon from "../../../../../assets/prompt/results.svg";
import { Constants } from "../../../../../helpers/Constants";
import {
  IConversationMessage,
  IPromptRequestObj,
  IResultsResponse,
} from "../../../../../models/KxPromptModels";
import { SharedService } from "../../../../../services/SharedService";
import { addMessage } from "../../../../../store/actions/promptConversationAction";
import { getPromptResponse } from "../../../../../store/actions/promptResponseAction";
import { RootState } from "../../../../../store/reducers";
import { MessageSender } from "../../../../../store/types";
import Result from "./Result";
import { useResponseAnalyticsTracker } from "../../promptContextAPIs/ResponseAnalyticTrackerContext";
import { TooltipBase } from "../../../../shared/tooltipBase/tooltipBase";
import ResultsLoader from "./ResultsLoader";
import { useDisableResultsOnModifySelected } from "../../promptContextAPIs/DisableResultsOnModifySelected";
import { toast } from "react-toastify";
import { resetQuickViewResultsError } from "../../../../../store/actions/promptQuickViewAction";

/**
 * Results Component
 *
 * This component represents the entire results section on right hand side
 * within Prompt by KX.
 */
const Results: React.FC = () => {
  const dispatch = useDispatch();
  const [kxresults, setKxresults] = useState<IResultsResponse[]>([]);
  const [originalKxresults, setOriginalKxresults] = useState<
    IResultsResponse[]
  >([]);
  const [selectedCheckboxCount, setSelectedCheckboxCount] = useState(0);
  const [displayedCheckboxCount, setDisplayedCheckboxCount] = useState(0);
  const _sharedService = SharedService.GetInstance();
  const { responseTriggered, setResponseTriggered } =
    useResponseAnalyticsTracker();

  const { disableResultsOnModifySelected } =
    useDisableResultsOnModifySelected();

  const { promptResponse, loadingResponse, loadingDPNUserImage } = useSelector(
    (state: RootState) => state.promptResponseReducer
  );

  const { messages, kxResultsFromHistoryLoading } = useSelector(
    (state: RootState) => state.promptConversationReducer
  );

  const { error } = useSelector(
    (state: RootState) => state.promptQuickViewReducer
  );

  //Handler to get session id
  const getSessionId = () => {
    if (messages.length >= 2) {
      return messages[messages.length - 1].sessionId;
    } else if (promptResponse?.sessionid) return promptResponse?.sessionid;
    else return "";
  };

  //Handler for check/uncheck checkbox
  const handleCheckboxChange = (citationNo: number) => {
    setKxresults((prevResults) => {
      return prevResults.map((result) => {
        if (result.citation_no === citationNo) {
          return { ...result, ischecked: !result.ischecked };
        }
        return result;
      });
    });
  };

  //Click handler for refresh button
  const handleRefreshButtonClick = () => {
    const appIdList: string[] = kxresults
      .filter((kxResult) => kxResult.ischecked)
      .map((kxResult) => kxResult.appid);

    const lastPromptUserMessage = messages
      .slice()
      .reverse()
      .find((message) => message.sender === MessageSender.USER);
    const payloadData: IPromptRequestObj = {
      userprompt: (lastPromptUserMessage?.text as string) || "",
      sessionid: lastPromptUserMessage?.sessionId || getSessionId(),
      overridesettings: {
        top: 5,
        search_mode: "hybrid",
      },
      is_regenerate_request: true,
      regeneratefromids: appIdList || [],
    };
    const updateMessage: IConversationMessage = {
      text: lastPromptUserMessage?.text || "",
      sender: MessageSender.USER,
      usageGuidelines: "",
      sessionId: getSessionId(),
      conversationId: "",
    };

    dispatch(addMessage(updateMessage));
    setResponseTriggered(true);
    dispatch(getPromptResponse(payloadData));
  };

  // Update selectedCheckboxCount when kxResults is selected changes
  useEffect(() => {
    const count: number = kxresults.filter(
      (kxresult) => kxresult.ischecked
    ).length;
    setSelectedCheckboxCount(count);
  }, [kxresults]);

  useEffect(() => {
    const count: number = originalKxresults.filter(
      (kxresult) => kxresult.ischecked
    ).length;
    setDisplayedCheckboxCount(count);
  }, [originalKxresults]);

  useEffect(() => {
    setSelectedCheckboxCount(0);
    setDisplayedCheckboxCount(0);
  }, [promptResponse]);

  useEffect(() => {
    if (promptResponse?.contain_specialist) {
      //If contain specialists is true, then show all results and make first five checked
      const finalKXResults = promptResponse.kxresults?.map((result, idx) => ({
        ...result,
        ischecked: idx < 5,
      }));
      setKxresults(finalKXResults || []);
      setOriginalKxresults(finalKXResults || []);
    } else if (promptResponse && promptResponse?.kxresults) {
      //If contain specialists is false, updating the latest results
      setKxresults(promptResponse.kxresults);
      setOriginalKxresults(promptResponse.kxresults);
    } else {
      setKxresults([]);
      setOriginalKxresults([]);
    }
  }, [promptResponse, kxResultsFromHistoryLoading]);

  useEffect(() => {
    if (error) {
      toast.dismiss();
      toast.clearWaitingQueue();
      toast.error(error);
      dispatch(resetQuickViewResultsError());
    }
  }, [error]);

  return (
    <>
      {loadingResponse ||
      kxResultsFromHistoryLoading ||
      (loadingDPNUserImage && promptResponse?.is_updated_from_history) ? (
        <div className="results-container" data-testid="results-main-container">
          <div className="results-header-container">
            <div className="label-parent-container">
              <div className="label-container">
                <img
                  src={ResultsIcon}
                  alt={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_ResultsLabel
                    ]
                  }
                  className="results-icon"
                />
                <span className="result-label">
                  {
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_ResultsLabel
                    ]
                  }
                </span>
              </div>
            </div>
          </div>
          <ResultsLoader />
        </div>
      ) : (
        <div className="results-container" data-testid="results-main-container">
          <div className="results-header-container">
            <div className="label-parent-container">
              <div className="label-container">
                <img
                  src={ResultsIcon}
                  alt={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_ResultsLabel
                    ]
                  }
                  className="results-icon"
                />
                <span className="result-label">
                  {
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_ResultsLabel
                    ]
                  }
                </span>
              </div>
              <div className="result-count-text">
                {`${displayedCheckboxCount} ${
                  _sharedService.Data.Labels[
                    Constants.LabelKeys.KXPrompt_ResultsCitationNumber
                  ]
                }`}
              </div>
            </div>

            <div className="refresh-icon">
              <TooltipBase
                content={
                  _sharedService.Data.Labels[
                    promptResponse?.contain_specialist
                      ? Constants.LabelKeys
                          .KXPrompt_RegenerateResponsePopup_Specialist
                      : Constants.LabelKeys.KXPrompt_RegenerateResponsePopup
                  ]
                }
                directionalHint={10}
                wrapperClassName="prompt_tooltip"
              >
                <div
                  onClick={
                    selectedCheckboxCount === 0 ||
                    promptResponse?.contain_specialist ||
                    disableResultsOnModifySelected // Flag to disable the regenerate response and results checkbox if modify response is selected
                      ? undefined
                      : handleRefreshButtonClick
                  }
                  digitaldata-prompt="Right rail>Regenerate Response"
                >
                  <img
                    src={
                      selectedCheckboxCount === 0 ||
                      promptResponse?.contain_specialist ||
                      disableResultsOnModifySelected // Flag to disable the regenerate response and results checkbox if modify response is selected
                        ? RegenerateDisabled
                        : Regenerate
                    }
                    className={
                      selectedCheckboxCount === 0 ||
                      disableResultsOnModifySelected // Flag to disable the regenerate response and results checkbox if modify response is selected
                        ? "custom-icon-disabled"
                        : "custom-icon"
                    }
                    alt={
                      _sharedService.Data.Labels[
                        Constants.LabelKeys.KXPrompt_RegenerateResponsePopup
                      ]
                    }
                  />
                </div>
              </TooltipBase>
            </div>
          </div>
          <div
            className={`results-list-wrapper ${
              kxresults.length === 0 && "flex-centered"
            }`}
          >
            {kxresults.length === 0 && (
              <span className="no_result_message">
                {
                  _sharedService.Data.Labels[
                    Constants.LabelKeys.KXPrompt_NoResultsMsgLabel
                  ]
                }
              </span>
            )}
            <div className="results-list-container">
              {kxresults.length !== 0 &&
                kxresults.map((resultsItem, index) => (
                  <Result
                    resultsItem={resultsItem}
                    key={index}
                    index={resultsItem.citation_no} // Used to show citation number and pass it to handle checkbox.
                    handleCheckboxChange={handleCheckboxChange}
                    selectedCheckboxCount={selectedCheckboxCount}
                    defaultKxResults={originalKxresults}
                    position={index + 1} // Used for Citation Tracking
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Results;
