import React from "react";
import "./toastNotification.scss"

export interface toastNotificationProps {

    message?: string;
    onCloseClick?: () => void | any;
    
}

export const ToastNotification: React.FunctionComponent<toastNotificationProps> = (props) => {

  return (
    <div className="basicformerror">
    <div className="bannerMessage">
      {props.message}
    </div>
    <div className="bannerclose">
      <i className="icon-kx-close" onClick={props.onCloseClick}></i>
    </div>
  </div>
 
  );
};