import React, { useCallback, useState, useEffect } from "react";
import styles from "../newContribution.module.scss";
import { Shimmer, ShimmerElementType, ShimmerElementsGroup, mergeStyles } from '@fluentui/react';
import { ThemeProvider } from '@fluentui/react';
import { Label, TextField } from "@fluentui/react";
import { RichTextbox } from "../../../shared/richTextbox/richTextbox";
import MultipleFileUpload from "../../../shared/multipleFileUpload/multipleFileUpload";
import { FileError, FileRejection } from "react-dropzone";
import { SingleFileRecord } from "../../../shared/multipleFileUpload/singleFileRecord";
import { CommonFunctions } from "../../../../helpers/CommonFunctions";
import { SharedService } from "../../../../services/SharedService";
import { Constants } from "../../../../helpers/Constants";
import { IDashActivity } from "../../../../models/IShared";
import ReopenSingleFileRecord from "../../../shared/multipleFileUpload/reopenSingleFileRecord";
import { CalloutBase } from "../../../shared/calloutBase/calloutBase";
import { basicFormData } from "../newContribution";
import { QuillToolbar, modules, formats } from "../../../shared/reactQuillEditor/reactQuillEditor";
import ReactQuill from "react-quill";
import { PopupDialog } from "../../../shared/popupDialog/popupDialog";
import { useBoolean } from "@fluentui/react-hooks";

export interface UploadableFile {
  file: File;
  errors: FileError[];
  url?: string;
}

const BasicNCForm: React.FC<{
  getBasicForm: (value?: any) => void;
  setNewTitle: (value?: string) => void;
  description: (value?: any) => void;
  BasicFormData: any;
  getfilesToBeUploaded: (value?: any) => void;
  draftData: any;
  updateParentAttachment: (value?: any) => void;
}> = (props) => {
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const [filesReopen, setFilesReopen] = useState<any[]>([]);
  const [description, setDescription] = useState<string>();
  const [title, setTitle] = useState<string>("");
  const [dashActivities, setDashActivities] = useState<any>([]);
  const [selectedDashActivity, setSelectedDashActivity] = useState<any>();
  const [dashViewLength, setDashViewLength] = useState<number>(4);
  const [seeMoreExpanded, setSeeMoreExpanded] = useState<boolean>(false);
  const _sharedService = SharedService.GetInstance();
  const [instructionsTextContent, setInstructionsTextContent] = useState<any>(null);
  const [contentTypeList, setContentTypeList] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  let labelsKey = SharedService.scopeNotesLabelKey;
  var labelsData: string | null = localStorage.getItem(labelsKey);
  var labels = labelsData != null ? JSON.parse(labelsData) : {};
  const [overrideTitleFlag, setOverideTitleFlag] = useState<boolean>(false);
  const [overrideDescFlag, setOverideDescFlag] = useState<boolean>(false);
  const [currDescDataFlag, setCurrDescDataFlag] = useState<boolean>(false);
  const [value, setValue] = useState('');
  const initialData: basicFormData = {
    title: '',
    dashActivityId: 0,
    dashActivityName: "None",
    clientName: '',
    contentType: null,
    publishingData: "",
    description: ""
  }

  let draftActivityData: basicFormData;
  const [hideDialogDash, { toggle: toggleHideDialogDash }] = useBoolean(true);
  const [draftDashActivity, setDraftDashActivity] = useState<number | null>(0);
  const [activityForChange, setActivityForChange] = useState<any>();

  useEffect(() => {
    let allData: any = localStorage.getItem("__dkm__GetAllData");
    let AllSynapticaTagsData = allData != null ? JSON.parse(allData).data : "";
    if (AllSynapticaTagsData !== null) {
      setContentTypeList(AllSynapticaTagsData.ContentTypeList);
    allActivities();
    }
  }, []);

  useEffect(() => {
    props.getfilesToBeUploaded(files);
  }, [files]);

  useEffect(() => {
    const currentUser = _sharedService.GetCurrentUserData().then((result) => {
      let data;
      if (result && result.userData?.length > 0) {
        data = result.userData[0]._source;
      }
      return data;
    })

    if (currentUser) {
      currentUser.then((data) => {
        let memberfirm = data && data.geography && data.geography.length > 0 ? data.geography[0]?.id : 0;
        let helpTextKey = mapMfIdWithHelpTextKeys.find(m => m.memberFirmId == memberfirm)?.instructionsHelpTextKeys;
        _sharedService.Search.Get.GetHelpText(
          helpTextKey || Constants.HelpTextKeys.InstructionalTextGlobal
        ).then((response) => {
          let data = response;
          if(response && response.length > 0){
          data[0].dkxDescription = CommonFunctions.replaceSpUrlRelative(response[0].dkxDescription);
          }
          return setInstructionsTextContent(data);
        });
      })
    }
  }, [])

  useEffect(() => {
    if (props.draftData) {
      setTitle(props.draftData.title != null ? props.draftData.title : "");
      setDescription(props.draftData.description ? props.draftData.description : "");
      setDraftDashActivity(props.draftData.dashActivityId);
      props.description(props.draftData.description ? props.draftData.description : "");
      if (props.draftData.attachmentDetails)
        setFilesReopen(props.draftData.attachmentDetails)
      
    }
    allActivities();
  }, [props.draftData]);
 
  const allActivities = async () => {
    setLoader(true);
    const incomingDashActivities: IDashActivity[] = await _sharedService.GetDashActivities(false, 0);
    const allDashActivities = await Promise.all(incomingDashActivities.map(id =>
      _sharedService.GetDashActivityById(id.activityId)
    )
    );
    let data: any = [];
    allDashActivities.map((m: any) => {
      data.push(m.data);
    });
    let newData: any = [];
    data.map((m: any) => {
      let activity: any = incomingDashActivities.find((n: any) => m.id === n.activityId);
      let industry: string = activity.globalIndustry ? ("Industries" + " : " + activity.globalIndustry) : "";
      let service: string = activity.globalServices ? ("Services" + " : " + activity.globalServices + "\n") : "";
      let firm: string = activity.memberFirm ? ("Lead Member Firm" + " : " + activity.memberFirm + "\n") : "";
      let clientName: string = activity.clientName ? ("Client Name" + " : " + activity.clientName + "\n") : "";
      let actualData: basicFormData = {
        title: m.activityName,
        dashActivityId: activity.activityId,
        dashActivityName: m.activityName,
        clientName: activity.clientName,
        contentType: m.activityTypeId,
        publishingData: firm + clientName + service + industry,
        description: activity.description != null ? activity.description : ""
      }
      newData.push(actualData);
    })
    if (props.draftData && props.draftData.dashActivityId) {
      let contentTypeData = Constants.dashActivityContentMap.find(i => i.text === props.draftData.contentType?.name);
      draftActivityData = {
        title: props.draftData.dashActivityName,
        dashActivityId: props.draftData.dashActivityId,
        dashActivityName: props.draftData.dashActivityName,
        clientName: props.draftData?.clientName,
        contentType: contentTypeData?.key,
        publishingData: props.draftData.publishInstructions,
        description: props.draftData.description
      }
      newData.unshift(props.draftData.dashActivityId && draftActivityData);
    }
      newData.unshift(initialData);

    setDashActivities(newData);
    if (props.draftData && props.draftData.dashActivityId) {
      let dashActivity = newData.find((d: any) => d.dashActivityId === props.draftData.dashActivityId);
      setSelectedDashActivity(dashActivity);
    } else {
      setSelectedDashActivity(newData[0]);
    }
    setLoader(false);
  }
  
    const wrapperClass = mergeStyles({
    padding: 2,
    selectors: {
      '& > .ms-Shimmer-container': {
        margin: '10px 0px',
        padding: '0px 15px',
      },
    },
  });

  const onDashActivityChange = (activity: basicFormData) => {
    if (draftDashActivity && draftDashActivity != activity.dashActivityId) {
      toggleHideDialogDash();
      setActivityForChange(activity);
    } else {
      setDashActivity(activity);
    }
  }

  const getContentType = (data: any) => {
    if (data != undefined || null) {
      let activityType: any = Constants.dashActivityContentMap.find(i => i.key === data);
      return activityType.text;
    }
  }

  const setDashActivity = (activity: basicFormData) => {
    setSelectedDashActivity(activity);
    if (activity.dashActivityId != 0) {
      let dashActivity = dashActivities.find((d: any) => d.dashActivityId === activity.dashActivityId);
      if (dashActivity != undefined || [] || null) {
        setTitle(overrideTitleFlag ? title : activity.dashActivityName);
        let activityType: any = getContentType(dashActivity.contentType);
        let contentTypeId = contentTypeList.find((m: any) => m.Name === activityType);
        let data: basicFormData = {
          contentType: contentTypeId,
          title: overrideTitleFlag ? title : activity.title,
          dashActivityId: activity.dashActivityId,
          dashActivityName: activity.dashActivityName,
          clientName:activity.clientName,
          publishingData: activity.publishingData,
          description: overrideDescFlag ? description : activity.description,
        }
        props.getBasicForm(data);
        props.setNewTitle(overrideTitleFlag ? title : activity.dashActivityName);
        props.description(overrideDescFlag ? description : activity.description);
        setDescription(overrideDescFlag ? description : activity.description);
      }
    } else {
      setTitle(overrideTitleFlag ? title : "");
      setDescription(overrideDescFlag ? description : "");
      props.setNewTitle(overrideTitleFlag ? title : "");
      props.description(overrideDescFlag ? description : "");
      let data2: basicFormData = {
        title: overrideTitleFlag ? title : '',
        dashActivityId: null,
        dashActivityName: 'None',
        contentType: '',
        clientName:'',
        description: overrideDescFlag ? description : '',
        publishingData: ''
      }
      props.getBasicForm(data2);
    }
  }

  const unlinkDash = async () => {
    setLoader(true);
    toggleHideDialogDash();
    setDashActivity(activityForChange);
    setLoader(false);
  };

  const mapMfIdWithHelpTextKeys = [
    {
      memberFirmId: 1,
      instructionsHelpTextKeys: Constants.HelpTextKeys.InstructionalTextGlobal
    },
    {
      memberFirmId: 2348,
      instructionsHelpTextKeys: Constants.HelpTextKeys.InstructionalTextCa
    },
    {
      memberFirmId: 2243,
      instructionsHelpTextKeys: Constants.HelpTextKeys.InstructionalTextAu
    },
    {
      memberFirmId: 2768,
      instructionsHelpTextKeys: Constants.HelpTextKeys.InstructionalTextUs
    },
    {
      memberFirmId: 2470,
      instructionsHelpTextKeys: Constants.HelpTextKeys.InstructionalTextDe
    },
    {
      memberFirmId: 2494,
      instructionsHelpTextKeys: Constants.HelpTextKeys.InstructionalTextUk
    }
  ];

  const descriptionData = (editor: any) => {
    let data: string
    if (editor == "<div><br></div>") {
      data = "";
    }
    else {
      data = editor;
    }
    setDescription(data);
    props.description(data);
    if (dashActivities.length > 1) {
      setCurrDescDataFlag(data != "" ? true : false);
      setOverideDescFlag(selectedDashActivity.dashActivityName == 'None' && data.length != 0 ? true : false);
    }
  };

  useEffect(() => {
    if (selectedDashActivity && selectedDashActivity.dashActivityName != 'None' && !currDescDataFlag) {
      setOverideDescFlag(false);
    }
  }, [selectedDashActivity])

  const titleData = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: any
  ) => {
    if (!newValue || newValue.length <= 255) {
      if (selectedDashActivity?.dashActivityName != 'None' && newValue != "") {
        setOverideTitleFlag(overrideTitleFlag);
      } else {
        setOverideTitleFlag(selectedDashActivity?.dashActivityName == 'None' && newValue != "" ? true : false);
      }
      setTitle(newValue);
      props.setNewTitle(newValue);
      // props.getBasicForm({ ...props.BasicFormData, title: newValue });
    }
  };

  const onFileDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const mappedAcc: any = acceptedFiles.map((file) => ({
        file,
        errors: [],
      }));
      setFiles([...files, ...mappedAcc]);
    },
    [files]
  );

  function onFileDelete(file: File) {
    setFiles((curr) => curr.filter((fw) => fw.file !== file));
  }

  function deleteAttachment(file: any) {
    let deleteData = {
      documentId: props.draftData.documentId,
      attachmentIds: [file.attachmentId]
    }
    _sharedService.RemoveAttachment(deleteData).then((res) => {
      if (res) {
        let data = filesReopen
        data = data.filter((fw) => fw.attachmentId !== file.attachmentId)
        // let reopenfiles= filesReopen.filter((fw) => fw.attachmentId !== file.attachmentId)
        setFilesReopen(data);
        props.updateParentAttachment(file);

      }
    })
  }

  const onSeeMoreClick = () => {
    setDashViewLength(dashActivities.length);
    setSeeMoreExpanded(true);
  }

  const onSeeMoreCollapse = () => {
    setDashViewLength(4);
    setSeeMoreExpanded(false);
  }

  const wrapperStyle = { display: 'flex' };

  const getCustomElementsExampleThree = (): JSX.Element => {
    return (
      <div style={wrapperStyle}>
        <ShimmerElementsGroup
          width={'130px'}
          shimmerElements={[
            { type: ShimmerElementType.line, height: 100, width: 100 },
            { type: ShimmerElementType.gap, width: 10, height: 100 },
          ]}
        />
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          <ShimmerElementsGroup
            shimmerElements={[
              { type: ShimmerElementType.line, width: '90%', height: 10 },
              { type: ShimmerElementType.gap, width: '10%', height: 10 },
              { type: ShimmerElementType.line, width: '100%', height: 10 },
            ]}
          />
          <ShimmerElementsGroup
            flexWrap
            width={'100%'}
            shimmerElements={[
              { type: ShimmerElementType.line, width: '80%', height: 10, verticalAlign: 'bottom' },
              { type: ShimmerElementType.gap, width: '20%', height: 20 },
              { type: ShimmerElementType.line, width: '40%', height: 10, verticalAlign: 'bottom' },
              { type: ShimmerElementType.gap, width: '2%', height: 20 },
              { type: ShimmerElementType.line, width: '58%', height: 10, verticalAlign: 'bottom' },
            ]}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="kx-nextgen">
        <div className="ms-Grid p-0">
          {loader ?

            <ThemeProvider className={wrapperClass}>
              <Shimmer />
              <Shimmer width="75%" />
              <Shimmer width="50%" />

              <Shimmer customElementsGroup={getCustomElementsExampleThree()} width="90%" className={styles.Shimmermargin} />
              <Shimmer className={styles.Shimmermargin} />
              <Shimmer width="85%" />
              <Shimmer width="60%" />
              <Shimmer width="30%" />

              <Shimmer width="35%" className={styles.Shimmermargin} />
              <Shimmer width="85%" />

            </ThemeProvider>
            :
            <section className="phase-1">
              <div>
                <div className={styles.maintext}>
                  {/* <h1>New KX Contribution</h1>  */}
                <div
                 dangerouslySetInnerHTML={{
                  __html: CommonFunctions.replaceImageRelative(
                    _sharedService.Data.Labels["KXDocument_Contribute_Guidelines"]
                  )
                 }}
                 ></div>
                {instructionsTextContent
                    ? instructionsTextContent.map((insText: any) => {
                      return (
                      <p className="newcontributelinks"
                          dangerouslySetInnerHTML={{
                            __html: CommonFunctions.replaceImageRelative(
                              insText.dkxDescription
                            ),
                          }}
                        ></p>
                      );
                    })
                    : null}
                </div>
              </div>
              <form>
                <div className={styles.contributionfrom}>
                  <div className={styles.formmaincard}>
                    <div className={styles.attach}>
                      <Label htmlFor="Title" className={styles.title}>
                        {_sharedService.Data.Labels["KXDocument_Attachments_NewContribution"]}
                      </Label>
                      <CalloutBase
                        keyValue={"KXDocuments_NewContribution_HelpText_Attachments"}
                        label={"Attachments"}
                      />
                      <MultipleFileUpload onDrop={onFileDrop} />
                      {props.draftData && props.draftData.attachmentDetails && props.draftData.attachmentDetails.length > 0 && filesReopen.length > 0 && filesReopen.map((file: any) => (
                        <ReopenSingleFileRecord
                          file={file}
                          docId={props.draftData.documentId}
                          onDelete={deleteAttachment}
                        />
                      ))}
                      {files.map((fileWrapper) => (
                        <SingleFileRecord
                          file={fileWrapper.file}
                          onDelete={onFileDelete}
                        />
                      ))}
                    </div>
                    <div className={[styles.formfield, styles.doctitle].join(" ")}>
                      <Label htmlFor="Title" className={styles.title}>
                        {_sharedService.Data.Labels["KXDocument_Title_NewContribution"]}
                      </Label>
                      <CalloutBase
                        keyValue={"KXDocuments_NewContribution_HelpText_Title"}
                        label={"Title"}
                      />
                      <TextField
                        id="Title"
                        placeholder={_sharedService.Data.Labels["KXDocuments_NewContribution_Placeholder_Title"]}
                        value={title}
                        onChange={titleData}
                      />
                    </div>
                    <div
                      className={[styles.formfield, styles.discriptiontitle].join(
                        " "
                      )}
                    >
                      <Label htmlFor="Description" className={styles.title}>
                        {_sharedService.Data.Labels["KXDocument_Description_NewContribution"]}
                      </Label>
                      <CalloutBase
                        keyValue={"KXDocuments_NewContribution_HelpText_Description"}
                        label={"Description"}
                      />
                      <div className={styles.textEditor}>
                        <QuillToolbar />
                        <ReactQuill
                          theme="snow"
                          value={description || ""}
                          //onChange={(dataN)=>{descriptionData(dataN)}}
                          onChange={(text: string, delta: any, source: string, editor: any) => {
                            if (source == 'user') {
                              descriptionData(text)
                            }
                          }}
                          modules={modules}
                          formats={formats}
                          placeholder="Provide brief description"
                        />
                      </div>
                      {/* <ReactQuill  modules={modules} theme="snow"  placeholder={labels.data["KXDocuments_NewContribution_Placeholder_Description"]} value={description} onChange={(dataN:any)=>{descriptionData(dataN)}} /> */}
                    </div>
                    <div className={styles.formfield}> 
                    {dashActivities && selectedDashActivity && dashActivities?.length > 1 &&
                      <div>             
                        <Label htmlFor="TaskOption" className={styles.title}>
                          {_sharedService.Data.Labels["KXDocument_Related KX Dash task_NewContribution"]}
                        </Label>
                        <CalloutBase
                          keyValue={"KXDocuments_NewContribution_HelpText_RelatedKXDashtask"}
                          label={"Related Dash Task"}
                        />
                      </div> 
                      }
                      <div className={styles.relatedtask}>
                        {selectedDashActivity && props.BasicFormData?.dashActivityId && 
                          <ul>
                            <li>
                              <div>
                                <h4>
                                  <b className="text--Dark">{_sharedService.Data.Labels["KXDocuments_NewContribution_Selected Dash Activity"]}</b>
                                </h4>
                              </div>
                            </li>
                            <li>
                              <div>
                                {props.BasicFormData.dashActivityId ?
                                  <>
                                    <h4>
                                      <b className="text--Dark">{_sharedService.Data.Labels["KXDocuments_NewContribution_Activity Id"]} </b>
                                      <span>{props.BasicFormData.dashActivityId}</span>
                                    </h4>
                                    <h4>
                                      <b className="text--Dark">{_sharedService.Data.Labels["KXDocuments_NewContribution_Activity Name"]} </b>
                                      <span className="text--Dark">{props.BasicFormData.dashActivityName}</span>
                                    </h4>
                                  </>
                                  :
                                  ""}
                              </div>
                            </li>
                          </ul>
                        }
                        {dashActivities && dashActivities.length > 1 &&
                          <ul>
                            {dashActivities && selectedDashActivity && dashActivities.slice(0, dashViewLength).map((activity: any) => (
                              <li className="mb-10">
                                <div className={styles.select}>
                                  <input
                                    type="radio"
                                    value={activity.dashActivityId}
                                    name="relatedkxgroup"
                                    checked={selectedDashActivity.dashActivityId === activity.dashActivityId}
                                    onChange={() => onDashActivityChange(activity)}
                                  />
                                </div>
                                <div>
                                  {activity.dashActivityName ?
                                    <h4>
                                      <b className="text--Dark">{activity.dashActivityName}</b>
                                    </h4>
                                    :
                                    ""}
                                  {activity.contentType != null ?
                                    <p className="p-0">
                                      <b> {_sharedService.Data.Labels["KXDocument_Content type_NewContribution"]} :</b>
                                      <span className="ml-10">
                                        {getContentType(activity.contentType)}
                                      </span>
                                    </p>
                                    :
                                    ""}
                                  {activity.clientName ?
                                    <p>
                                      <b> {_sharedService.Data.Labels["KXDocument_Client name_NewContribution"]} :</b>
                                      <span className="ml-10">
                                        {activity.clientName}
                                      </span>
                                    </p>
                                    :
                                    ""}
                                </div>
                              </li>
                            ))}
                            {dashActivities && dashActivities.length > 4 && !seeMoreExpanded && <a onClick={onSeeMoreClick} style={{ cursor: 'pointer' }}> See More </a>}
                            {dashActivities && dashActivities.length > 4 && seeMoreExpanded && <a onClick={onSeeMoreCollapse} style={{ cursor: 'pointer' }}> See Less </a>}
                          </ul>
                        }
                      </div>
                    </div>
                    
                  </div>
                </div>
              </form>
            </section>
          }
        </div>
      </div>
      <PopupDialog
        title={_sharedService.Data.Labels[
          "KXDocuments_NewContribution_Dash_Task_Change_From_Already_Drafted_Activity_Title"
        ]}
        subText={_sharedService.Data.Labels[
          "KXDocuments_NewContribution_Dash_Task_Change_From_Already_Drafted_Activity_Subtext"
        ]}
        hideDialog={hideDialogDash}
        toggleHideDialog={toggleHideDialogDash}
        btn1text="Yes"
        onClickBtn1={unlinkDash}
        btn2text="No"
        onClickBtn2={toggleHideDialogDash}
        hideTopBtn={true}
      />
    </>
  );
};

export default BasicNCForm;
