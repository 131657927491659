import React, { lazy, Suspense } from 'react';

const LazyPersonalCollectionPage = lazy(() => import(/* webpackChunkName: "personalcollection" */'./personalCollectionPage'));

const PersonalCollectionPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPersonalCollectionPage {...props} />
  </Suspense>
);

export default PersonalCollectionPage;
