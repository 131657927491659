import React from "react";
import AboutIcon from "../../../../../assets/prompt/media/sidebar/aboutModal.svg";
import CloseIcon from "../../../../../assets/prompt/media/sidebar/close.svg";
import { Constants } from "../../../../../helpers/Constants";
import { SharedService } from "../../../../../services/SharedService";
import { useId } from "@fluentui/react-hooks";
import { Modal } from "@fluentui/react";
import "./AboutModal.scss";

/**
 * Interface for AboutModal component props
 */
interface IAboutModal {
  showAboutModal: boolean;
  setShowAboutModal: (state: boolean) => void;
}

/**
 * AboutModal Component
 *
 * This component represents the About Popup of the application.
 * It displays information about the application.
 */
const AboutModal: React.FC<IAboutModal> = ({
  showAboutModal,
  setShowAboutModal,
}: IAboutModal) => {
  const _sharedService = SharedService.GetInstance();
  const titleId = useId("aboutModalTitle");

  /**
   * Handler to close the modal
   */
  const modalCloseHandler = () => {
    setShowAboutModal(false);
  };

  const paragraphsText: string[] =
    _sharedService.Data.Labels[Constants.LabelKeys.KXPrompt_AboutInfo].split(
      "\\n\\n"
    );

  /**
   * Render the AboutModal component.
   *
   * @returns {JSX.Element} - The rendered AboutModal component.
   */
  return (
    <Modal
      titleAriaId={titleId}
      isOpen={showAboutModal}
      onDismiss={modalCloseHandler}
      isBlocking={false}
      containerClassName="about-modal-container"
    >
      <div className="about-modal-header">
        <div className="header-left">
          <img
            className="about-modal-heading-icon"
            src={AboutIcon}
            alt="about-icon"
          />
          <span className="about-modal-heading">
            {
              _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_AboutPromptByKxLabel
              ]
            }
          </span>
        </div>
        <div className="header-right">
          <img
            className="close-icon"
            src={CloseIcon}
            onClick={modalCloseHandler}
            alt="close-icon"
          />
        </div>
      </div>
      <div className="about-modal-body">
        <span className="heading-text">
          {
            _sharedService.Data.Labels[
              Constants.LabelKeys.KXPrompt_PromptByKxLabel
            ]
          }
        </span>
        <span className="content-text">
          {paragraphsText.map((paragraph) => (
            <p className="content-paragraph">{paragraph}</p>
          ))}
        </span>
      </div>
    </Modal>
  );
};

/**
 * Export the AboutModal component as default
 */
export default AboutModal;
