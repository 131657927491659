import React, { useEffect, useState } from 'react';
import { Stack, IStackProps } from 'office-ui-fabric-react';
import smartcrop from 'smartcrop';

import './banner.scss';

const verticalStackProps: IStackProps = {
  styles: { root: { overflow: 'hidden', width: '100%' } },
  tokens: { childrenGap: 20 },
};

export interface IBannerProps {
  background?: string;
  className?: string;
  modernUi?: boolean;
  isMobile?: boolean;
}

export const Banner: React.FunctionComponent<IBannerProps> = (props) => {
  const canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();
  let canvasDimensions = props.modernUi && !props.isMobile ? getCanvasDimensions(!!props.isMobile) : null as any;

  useEffect(() => {
    if (props.modernUi && !props.isMobile)
    {
      let photoCanvas = canvasRef.current;
      canvasDimensions = getCanvasDimensions(!!props.isMobile);
      readImage(`${props.background}`, (img?: HTMLImageElement, err?: string | Event) => {
        if (err) {
          console.error(err);
          return;
        }
  
        if (img)
          smartcrop
            .crop(img, {
              width: canvasDimensions.width,
              height: canvasDimensions.height,
              ruleOfThirds: true,
              minScale: 1.0,
            })
            .then(function (cropObj) {
              const { x, y, width, height } = cropObj.topCrop;
              if (photoCanvas) {
                let photoCtx = photoCanvas.getContext("2d");
                photoCtx && photoCtx.drawImage(img, x, y, width, height, 0, 0, canvasDimensions.width, canvasDimensions.height);
              }
            });
      });
    }
  }, [props.background, props.isMobile]);

  return (
    <>
      {props.modernUi ? (
        <div className="banner modern-banner-wrapper">
          {!props.isMobile &&
            <canvas ref={canvasRef} width={canvasDimensions.width} height={canvasDimensions.height} />
          }
          <div className="layer"></div>
          <div className="ms-Grid">{props.children}</div>
        </div>
      ) : (
        <Stack
          {...verticalStackProps}
          style={{ backgroundImage: `url(${props.background})` }}
          className={`banner ${props.className}`}
        >
          <div className="layer"></div>
          <div className="ms-Grid">{props.children}</div>
        </Stack>
      )}
    </>
  );
};

// private methods
const readImage = (url: string, cb: (img?: HTMLImageElement, err?: string | Event) => any) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = () => cb(img);
  img.onerror = (err) => cb(undefined, err);
  img.src = url;
};

const getCanvasDimensions = (isMobile: boolean) => {
  const dimensions = { width: 479, height: isMobile ? 542 : 266 };
  return dimensions;
}