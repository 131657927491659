import React, { lazy, Suspense } from 'react';

const LazyGlobalNavigation = lazy(() => import('../globalNavigation/globalNavigation'));
const GlobalNavigation = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyGlobalNavigation {...props} />
  </Suspense>
);

export default GlobalNavigation;
