import React, { useState, useEffect } from "react";
import { Shimmer, ThemeProvider, mergeStyles } from "@fluentui/react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCollapse } from "react-collapsed";
import { Stack, IStackStyles } from "@fluentui/react/lib/Stack";
import {
  History,
  ArrowUpSVG,
  EditSVG,
  DeleteSVG,
  ExternalSVGLink,
} from "../../../../../assets/prompt/media/sidebar";
import "./Sidebar.scss";
import { getSideBarIcons } from "../../../../../assets/prompt/media/sidebar/getSidebarIcons";
import TipsModal from "../TipsModal/TipsModal";
import {
  addMessage,
  clearMessage,
  getKxResultsFromHistory,
  resetAllConversationHistoryError,
  resetKxResultsFromHistoryError,
  resetSidebarHistoryItemActive,
  setSidebarHistoryItemActive,
} from "../../../../../store/actions/promptConversationAction";
import AboutModal from "../AboutModal/AboutModal";
import {
  addPromptKXResults,
  clearPromptResponse,
  getPromptResponse,
} from "../../../../../store/actions/promptResponseAction";
import { getAllConversationHistory } from "../../../../../store/actions/promptConversationAction";
import { SharedService } from "../../../../../services/SharedService";
import { Constants } from "../../../../../helpers/Constants";
import { RootState } from "../../../../../store/reducers";
import {
  IConversationHistoryResponseObj,
  IConversationKxHistoryRequestObj,
  IConversationMessage,
  IPromptRequestObj,
} from "../../../../../models/KxPromptModels";
import {
  StorageHandler,
  StorageType,
} from "../../../../../helpers/StorageHandler";
import RegeneratePopupIcon from "../../../../../assets/prompt/regeneratepopupicon.svg";
import WarningPopupIcon from "../../../../../assets/prompt/warningpopupicon.svg";
import RegenerateButtonIcon from "../../../../../assets/prompt/refreshiconbutton.svg";
import HistoryInfoIcon from "../../../../../assets/prompt/history_info_icon.svg";
import TopicModal from "../TopicModal/TopicModal";
import { MessageSender } from "../../../../../store/types";
import { useResponseAnalyticsTracker } from "../../promptContextAPIs/ResponseAnalyticTrackerContext";
import { IShimmerStyleProps, IShimmerStyles } from "office-ui-fabric-react";
import { TooltipBase } from "../../../../shared/tooltipBase/tooltipBase";
import SidebarHistoryOption from "./SidebarHistoryOption";
import { useDisableResultsOnModifySelected } from "../../promptContextAPIs/DisableResultsOnModifySelected";
import { toast } from "react-toastify";

const wrapperClass = mergeStyles({
  padding: 2,
  selectors: {
    "& > .ms-Shimmer-container": {
      margin: "10px 0",
    },
  },
  background: "transparent!important",
});
const getShimmerStyles = (props: IShimmerStyleProps): IShimmerStyles => {
  return {
    shimmerWrapper: [
      {
        background: "rgba(208, 208, 206, 0.3)",
        margin: "10px 0",
        borderRadius: "8px",
      },
    ],
    shimmerGradient: [
      {
        background: "rgba(208, 208, 206, 0.3)",
        backgroundImage:
          "linear-gradient(90deg, rgba(208, 208, 206, 0.65) 42.7%, rgba(208, 208, 206, 0) 86.83%)",
      },
    ],
  };
};
const historyStyles: IStackStyles = {
  root: {
    columnGap: "16px",
    alignItems: "center",
  },
};

/**
 * Sidebar Component
 *
 * This component represents the sidebar navigation for the Prompt feature.
 * It includes tabs for Home, History, Tips, About, and Knowledge Exchange (KX).
 * The component also manages state for collapsible sections and modal visibility.
 *
 * @param {boolean} collapsed - Indicates whether the sidebar is collapsed or expanded.
 * @param {Function} toggleCollapseSidebar - Function to toggle the sidebar's collapse state.
 */

interface ISidebarProps {
  collapsed: boolean;
  toggleCollapseSidebar?: () => void;
}

const Sidebar: React.FC<ISidebarProps> = ({
  collapsed,
  toggleCollapseSidebar,
}) => {
  const dispatch = useDispatch();
  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [showTipsModal, setShowTipsModal] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [userMail, setUserMail] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const { responseTriggered, setResponseTriggered } =
    useResponseAnalyticsTracker();
  const { setDisableResultsOnModifySelected } =
    useDisableResultsOnModifySelected();

  const _sharedService = SharedService.GetInstance();

  const {
    conversationHistory,
    conversationHistoryLoading,
    sidebarHistoryItemActiveKey,
    messages,
    error,
    kxResultsFromHistoryError,
    kxResultsFromHistory,
  } = useSelector((state: RootState) => state.promptConversationReducer);

  const { loadingResponse } = useSelector(
    (state: RootState) => state.promptResponseReducer
  );

  useEffect(() => {
    if (error) {
      toast.dismiss();
      toast.clearWaitingQueue();
      toast.error(error);
      dispatch(resetAllConversationHistoryError());
    } else if (kxResultsFromHistoryError) {
      toast.dismiss();
      toast.clearWaitingQueue();
      toast.error(kxResultsFromHistoryError);
      dispatch(resetKxResultsFromHistoryError());
    }
  }, [error, kxResultsFromHistoryError]);

  useEffect(() => {
    if (kxResultsFromHistory.length > 0) {
      //Opening regenerate dialog only when conversation data is loaded
      if (kxResultsFromHistory?.length > 0) {
        const containsSpecialist =
          kxResultsFromHistory[kxResultsFromHistory.length - 1]
            .contain_specialist;
        !containsSpecialist && setIsDialogOpen(true);
      }
    }
  }, [kxResultsFromHistory]);

  /**
   * Tips tab click handler
   */
  const tipsTabClickHandler = () => {
    setShowTipsModal(true);
  };

  /**
   * Click handler for home button
   */
  const homeButtonClickHandler = () => {
    if (loadingResponse) {
      return;
    }

    if (messages.length > 0) {
      setIsWarningDialogOpen(true);
    } else {
      navigateToHome();
    }
  };

  /**
   * Click handler to navigate to Home Page
   */
  const navigateToHome = () => {
    setIsWarningDialogOpen(false);
    toast.dismiss();
    toast.clearWaitingQueue();
    const conversationHistoryObj = conversationHistory.find(
      (history) =>
        history.sessionid === messages[messages.length - 1]?.sessionId
    );

    conversationHistoryObj?.title &&
      toast.success(
        `${conversationHistoryObj?.title} ${
          _sharedService.Data.Labels[
            Constants.LabelKeys.KXPrompt_CurrentChatHistorySaved
          ]
        }`,
        {
          autoClose: 8000,
        }
      );
    setDisableResultsOnModifySelected(false); // Flag to disable the regenerate response and results checkbox if modify response is selected
    dispatch(resetSidebarHistoryItemActive());
    dispatch(clearMessage());
    dispatch(clearPromptResponse());
    dispatch(getAllConversationHistory());
  };

  /**
   * Click handler for opening About Modal
   */
  const aboutTabClickHandler = () => {
    setShowAboutModal(true);
  };

  /**
   * Click handler for primary button
   */
  const handlePrimaryButtonClick = () => {
    setIsDialogOpen(false);
    setIsWarningDialogOpen(false);
  };
  const { promptResponse } = useSelector(
    (state: RootState) => state.promptResponseReducer
  );

  /**
   * Handler to get session id
   * @returns session Id
   */
  const getSessionId = () => {
    if (messages.length >= 2) {
      return messages[messages.length - 1].sessionId;
    } else if (promptResponse?.sessionid) return promptResponse?.sessionid;
    else return "";
  };

  /**
   * Click handler for secondary buton click
   */
  const handleSecondaryButtonClick = () => {
    const lastPromptUserMessage = messages
      .slice()
      .reverse()
      .find((message) => message.sender === MessageSender.USER);
    const payloadData: IPromptRequestObj = {
      userprompt: (lastPromptUserMessage?.text as string) || "",
      sessionid: lastPromptUserMessage?.sessionId || getSessionId(),
      overridesettings: {
        top: 5,
        search_mode: "hybrid",
      },
    };
    const updateMessage: IConversationMessage = {
      text: lastPromptUserMessage?.text || "",
      sender: MessageSender.USER,
      usageGuidelines: "",
      sessionId: getSessionId(),
      conversationId: "",
    };
    dispatch(addMessage(updateMessage));
    dispatch(getPromptResponse(payloadData));
    setResponseTriggered(true);
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const getUserMail = async () => {
      const response = await StorageHandler.Get(
        Constants.SessionStorageItems.User,
        StorageType.Local
      );
      setUserMail(response.kxp_email || "");
    };
    getUserMail();
  }, []);

  useEffect(() => {
    dispatch(getAllConversationHistory());
  }, []);

  useEffect(() => {
    if (messages && messages.length === 2) {
      if (messages[messages.length - 2].sessionId.length === 0) {
        dispatch(getAllConversationHistory());
      }
    }
  }, [messages]);

  /**
   * Retrieve sidebar options from shared service labels
   */
  const SIDEBAR_OPTIONS_TOP = JSON.parse(
    _sharedService.Data.Labels[Constants.LabelKeys.KXPrompt_SIDEBAR_OPTIONS_TOP]
  );
  const SIDEBAR_OPTIONS_BOTTOM = JSON.parse(
    _sharedService.Data.Labels[
      Constants.LabelKeys.KXPrompt_SIDEBAR_OPTIONS_BOTTOM
    ]
  );

  /**
   * Handle history of selected Item
   * @param historyItem - Interface of History Response Object
   * @returns
   */
  const handleHistoryItemSelect = (
    historyItem: IConversationHistoryResponseObj
  ) => {
    if (
      loadingResponse ||
      historyItem.sessionid === sidebarHistoryItemActiveKey
    ) {
      return;
    }
    setDisableResultsOnModifySelected(false); // Flag to disable the regenerate response and results checkbox if modify response is selected
    dispatch(clearPromptResponse());
    dispatch(setSidebarHistoryItemActive(historyItem.sessionid));
    if (historyItem?.kxresults.length === 0) {
      dispatch(addPromptKXResults([]));
    } else {
      dispatch(addPromptKXResults(historyItem.kxresults));
    }
    const payload: IConversationKxHistoryRequestObj = {
      sessionid: historyItem?.sessionid || "",
      useremail: userMail,
    };

    dispatch(getKxResultsFromHistory(payload));
  };

  const historyButtonClickHandler = () => {
    if (collapsed && toggleCollapseSidebar) {
      toggleCollapseSidebar();
      setExpanded(true);
    } else {
      setExpanded((prevExpanded) => !prevExpanded);
    }
  };

  return (
    <div className="sidebar-container">
      <div className={`sidebar-menu-container ${collapsed ? "collapsed" : ""}`}>
        <div className="sidebar-menu-header">
          <TooltipBase
            content={SIDEBAR_OPTIONS_TOP[0]}
            isHidden={!collapsed}
            directionalHint={12}
            wrapperClassName="prompt_tooltip"
          >
            <Link
              to="/prompt"
              className={`home-tab tab ${
                !sidebarHistoryItemActiveKey && "active-border"
              }`}
              onClick={homeButtonClickHandler}
              role="link"
              digitaldata-prompt="Left rail>Home"
              style={{ cursor: loadingResponse ? "not-allowed" : "pointer" }}
            >
              <img
                src={getSideBarIcons(
                  !sidebarHistoryItemActiveKey,
                  "home",
                  null
                )}
                alt={SIDEBAR_OPTIONS_TOP[0]}
              />
              {!collapsed && (
                <span className={sidebarHistoryItemActiveKey && "inactive-key"}>
                  {SIDEBAR_OPTIONS_TOP[0]}
                </span>
              )}
            </Link>
          </TooltipBase>

          <TooltipBase
            content={SIDEBAR_OPTIONS_TOP[1]}
            isHidden={!collapsed}
            directionalHint={12}
            wrapperClassName="prompt_tooltip"
          >
            <div
              className={`history-wrapper ${
                sidebarHistoryItemActiveKey
                  ? "historyActive"
                  : "historyInactive"
              } ${isExpanded ? "historyExpanded" : "historyNotExpanded"}`}
            >
              <div
                className="history-tab tab"
                {...getToggleProps({
                  onClick: () => historyButtonClickHandler(),
                })}
              >
                <Stack horizontal styles={historyStyles}>
                  <img
                    src={getSideBarIcons(
                      sidebarHistoryItemActiveKey,
                      "history",
                      null
                    )}
                    alt="history"
                  />
                  {!collapsed && (
                    <span
                      style={{
                        color: sidebarHistoryItemActiveKey ? "white" : "",
                      }}
                    >
                      {SIDEBAR_OPTIONS_TOP[1]}
                      <TooltipBase
                        content={
                          _sharedService.Data.Labels[
                            Constants.LabelKeys
                              .KXPrompt_HistoryInfoIconTooltipText
                          ]
                        }
                        wrapperClassName="prompt_tooltip"
                      >
                        <img
                          src={HistoryInfoIcon}
                          alt="info"
                          className="historyInfoIcon"
                        />
                      </TooltipBase>
                    </span>
                  )}
                </Stack>
                {!collapsed && (
                  <img
                    src={ArrowUpSVG}
                    alt="arrow"
                    className={isExpanded ? "rotate180" : "rotate-180"}
                  />
                )}
              </div>
              {!collapsed && (
                <div
                  className="collapse-history-section"
                  {...getCollapseProps()}
                >
                  {conversationHistoryLoading ? (
                    <div className="history">
                      <ThemeProvider className={wrapperClass}>
                        {new Array(2).fill(0).map(() => (
                          <div
                            style={{
                              padding: "1rem 1rem",
                              background: "transparent",
                            }}
                          >
                            <Shimmer width="100%" styles={getShimmerStyles} />
                            <Shimmer width="80%" styles={getShimmerStyles} />
                            <Shimmer width="60%" styles={getShimmerStyles} />
                          </div>
                        ))}
                      </ThemeProvider>
                    </div>
                  ) : (
                    <div className="history">
                      {conversationHistory?.length === 0 && (
                        <span className="history_notFound">
                          {
                            _sharedService.Data.Labels[
                              Constants.LabelKeys.KXPrompt_NoHistoryRecord
                            ]
                          }
                        </span>
                      )}
                      {conversationHistory?.length > 0 &&
                        conversationHistory
                          ?.filter((historyItem) =>
                            historyItem.hasOwnProperty("convhistory")
                          )
                          .map((conversationItem, index) => (
                            <SidebarHistoryOption
                              key={conversationItem.sessionid}
                              conversationItem={conversationItem}
                              sidebarHistoryItemActiveKey={
                                sidebarHistoryItemActiveKey
                              }
                              handleHistoryItemSelect={handleHistoryItemSelect}
                              loadingResponse={loadingResponse}
                            />
                          ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </TooltipBase>
        </div>
        <div className="sidebar-menu-footer">
          <TooltipBase
            content={SIDEBAR_OPTIONS_BOTTOM[0]}
            isHidden={!collapsed}
            directionalHint={12}
            wrapperClassName="prompt_tooltip"
          >
            <Link
              to="#"
              className="tips-tab tab"
              onClick={tipsTabClickHandler}
              role="link"
              digitaldata-prompt="Left rail>Support"
            >
              <img
                src={getSideBarIcons(false, "tips")}
                alt={SIDEBAR_OPTIONS_BOTTOM[0]}
              />
              {!collapsed && <span>{SIDEBAR_OPTIONS_BOTTOM[0]}</span>}
            </Link>
          </TooltipBase>
          <TooltipBase
            content={SIDEBAR_OPTIONS_BOTTOM[1]}
            isHidden={!collapsed}
            directionalHint={12}
            wrapperClassName="prompt_tooltip"
          >
            <Link
              to="#"
              className="about-tab tab"
              onClick={aboutTabClickHandler}
              role="link"
              digitaldata-prompt="Left rail>About"
            >
              <img
                src={getSideBarIcons(false, "about")}
                alt={SIDEBAR_OPTIONS_BOTTOM[1]}
              />
              {!collapsed && <span>{SIDEBAR_OPTIONS_BOTTOM[1]}</span>}
            </Link>
          </TooltipBase>
          <TooltipBase
            content={SIDEBAR_OPTIONS_BOTTOM[2]}
            isHidden={!collapsed}
            directionalHint={12}
            wrapperClassName="prompt_tooltip"
          >
            <Link
              to={{
                pathname:
                  _sharedService.Data.Labels[
                    Constants.LabelKeys.KXPrompt_KX_FEEDBACK_LINK
                  ],
              }}
              target="_blank"
              rel="noopener noreferrer"
              className="feedback-tab tab"
              role="link"
            >
              <div className="feedback_left flex-centered">
                <img
                  src={getSideBarIcons(false, "feedback")}
                  alt={SIDEBAR_OPTIONS_BOTTOM[2]}
                />
                {!collapsed && <span>{SIDEBAR_OPTIONS_BOTTOM[2]}</span>}
              </div>
              <div className="feedback_right">
                {!collapsed && (
                  <img src={ExternalSVGLink} alt="external-link" />
                )}
              </div>
            </Link>
          </TooltipBase>
          <TooltipBase
            content={SIDEBAR_OPTIONS_BOTTOM[3]}
            isHidden={!collapsed}
            directionalHint={12}
            wrapperClassName="prompt_tooltip"
          >
            <Link
              to={{
                pathname:
                  _sharedService.Data.Labels[
                    Constants.LabelKeys.KXPrompt_KX_HOMEPAGE_LINK
                  ],
              }}
              target="_blank"
              rel="noopener noreferrer"
              className="knowledgeExchange-tab tab"
              role="link"
              digitaldata-prompt="Left rail>KX"
            >
              <div className="kx_left flex-centered">
                <img
                  src={getSideBarIcons(false, "kx")}
                  alt={SIDEBAR_OPTIONS_BOTTOM[3]}
                />
                {!collapsed && <span>{SIDEBAR_OPTIONS_BOTTOM[3]}</span>}
              </div>
              <div className="kx_right">
                {!collapsed && (
                  <img src={ExternalSVGLink} alt="external-link" />
                )}
              </div>
            </Link>
          </TooltipBase>
        </div>
      </div>

      <TipsModal
        showTipsModal={showTipsModal}
        setShowTipsModal={setShowTipsModal}
      />
      <AboutModal
        showAboutModal={showAboutModal}
        setShowAboutModal={setShowAboutModal}
      />

      <TopicModal
        isOpen={isDialogOpen || isWarningDialogOpen}
        title={
          isDialogOpen
            ? _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_RegenerateTopic
              ]
            : isWarningDialogOpen
            ? _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_WarningPopupTitle
              ]
            : ""
        }
        subText={
          isDialogOpen
            ? _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_RegenerateOnHistorySavePopup
              ]
            : isWarningDialogOpen
            ? _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_WarningPopupSubtext
              ]
            : ""
        }
        icon={
          isDialogOpen
            ? RegeneratePopupIcon
            : isWarningDialogOpen
            ? WarningPopupIcon
            : ""
        }
        buttonIcon={isDialogOpen ? RegenerateButtonIcon : ""}
        primaryButtonText={
          _sharedService.Data.Labels[Constants.LabelKeys.KXPrompt_NoButton]
        }
        secondaryButtonText={
          isDialogOpen
            ? _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_RegenerateButton
              ]
            : isWarningDialogOpen
            ? _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_SaveAndProceedButton
              ]
            : ""
        }
        onPrimaryButtonClick={handlePrimaryButtonClick}
        onSecondaryButtonClick={
          isDialogOpen ? handleSecondaryButtonClick : navigateToHome
        }
      />
    </div>
  );
};

export default Sidebar;
