import { isEmpty } from "lodash";
import { Constants } from "../helpers/Constants";
import {
  IContextualSearchTracker,
  IDigitalData,
  IMiniCardTracker,
  IPageTracker,
  IQuickViewTracker,
  ISearchBoxTracker,
  ISearchElseWhereTracker,
  ISearchTracker,
  IPersonalizationTracker,
  IErrorTracker,
  IComparePageTracker,
  IDocumentDownloadTracker,
  IDocumentContributionTracker,
  ICategoryTracker,
  IExportCollectionsTracker,
  IRHRTracker,
  IPromptDigitalData,
  IPromptPageTracker,
  IPromptChatInteractionTracker,
  IPromptResultCitationTracker,
  IPromptErrortracking,
  IQuickViewDocumentDownloadTracker,
} from "../models/IAnalytics";
import { SharedService } from "./SharedService";

export class AnalyticsService {
  public static resetPageContext = () => {
    window.digitalData = {} as IDigitalData;
  };

  public static validatePageContext = () => {
    if (!window.digitalData) {
      window.digitalData = {} as IDigitalData;
    }
  };

  public static trackPageLoad = (
    data: IPageTracker,
    personalizationData: IPersonalizationTracker,
    categoryData?: ICategoryTracker,
    errorData: IErrorTracker = { errorMessage: "", requestedURL: "" }
  ) => {
    const _satellite = (window as any)._satellite;
    const sdService = SharedService.GetInstance();

    sdService.Actions.Get.GetUserNeeds().then((userNeedsData: any) => {
      if (!isEmpty(userNeedsData)) {
        userNeedsData.forEach((data: any) => {
          let text = sdService.Data.Labels[data.TitleLabel];
          if (text == personalizationData.need) {
            personalizationData.need = data.Title;
          }
        });
      }

      window.digitalData.page = data;
      window.digitalData.personalization = personalizationData;
      window.digitalData.user = [
        {
          profile: [
            {
              profileInfo: {
                userName:
                  sdService.Data &&
                    sdService.Data.CurrentUser &&
                    sdService.Data.CurrentUser.kxp_email
                    ? sdService.Data.CurrentUser.kxp_email.substring(
                      0,
                      sdService.Data.CurrentUser.kxp_email.indexOf("@")
                    )
                    : "",
              },
            },
          ],
        },
      ];
      window.digitalData.error = errorData;
      _satellite.track(Constants.TrackerKeys.PageLoad);
    });
  };

  public static trackSearch = (data: ISearchTracker) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();

    //window.digitalData.search = data;
    _satellite.track(Constants.TrackerKeys.Search, data.search);
  };

  public static trackSearchBox = (data: ISearchBoxTracker) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    _satellite.track(Constants.TrackerKeys.SearchBoxResult, data);
  };

  public static trackSearchElsewhere = (data: ISearchElseWhereTracker) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    _satellite.track(Constants.TrackerKeys.SearchElsewhere, data);
  };

  public static trackContextualSearch = (data: IContextualSearchTracker) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    _satellite.track(Constants.TrackerKeys.ContextualSearch, data);
  };

  public static trackMiniCard = (data: IMiniCardTracker) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    _satellite.track(
      data.isMiniCard
        ? Constants.TrackerKeys.MiniCard
        : Constants.TrackerKeys.QuickView,
      data
    );
  };

  public static trackQuickView = (data: IQuickViewTracker) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    _satellite.track(Constants.TrackerKeys.QuickView, data);
  };

  public static trackComparePage = (data: IComparePageTracker) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    _satellite.track(Constants.TrackerKeys.Compare, data);
  };

  public static trackDocumentDownload = (data: IDocumentDownloadTracker) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    _satellite.track(Constants.TrackerKeys.DocumentDownload, data);
  };

  public static trackQuickViewDocumentDownload = (data: IQuickViewDocumentDownloadTracker) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    _satellite.track(Constants.TrackerKeys.DocumentDownload, data);
  };



  public static trackDocumentContribution = (
    data: IDocumentContributionTracker
  ) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    _satellite.track(Constants.TrackerKeys.DocumentContribution, data);
  };

  public static trackExportCollections = (data: IExportCollectionsTracker) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    _satellite.track(Constants.TrackerKeys.ExportError, data);
  };

  public static trackRHR = (data: IRHRTracker) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    _satellite.track(Constants.TrackerKeys.RightRail, data);
  };

  // Prompt By Kx Analytics Section
  public static trackPromptPageLoad = (data: IPromptPageTracker) => {
    const _satellite = (window as any)._satellite;
    const sdService = SharedService.GetInstance();
    try {
      window.digitalData.page = data;
      window.digitalData.user = [
        {
          profile: [
            {
              profileInfo: {
                userName:
                  sdService.Data &&
                    sdService.Data.CurrentUser &&
                    sdService.Data.CurrentUser.kxp_email
                    ? sdService.Data.CurrentUser.kxp_email.substring(
                      0,
                      sdService.Data.CurrentUser.kxp_email.indexOf("@")
                    )
                    : "",
              },
            },
          ],
        },
      ];
      _satellite.track(Constants.TrackerKeys.PageLoad);
    } catch (error) {
      console.log(error);
    }
  };

  public static trackPromptChatInteraction = (
    promptInput: IPromptChatInteractionTracker
  ) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    try {
      window.digitalData.prompt = promptInput;
      _satellite.track(Constants.TrackerKeys.ChatInteraction);
    } catch (error) {
      console.log(error);
    }
  };

  public static trackPromptResultCitation = (
    resultTrackerObj: IPromptResultCitationTracker
  ) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    try {
      _satellite.track(Constants.TrackerKeys.Citation, resultTrackerObj);
    } catch (error) {
      console.log(error);
    }
  };

  public static trackPromptError = (errorTrackerObj: IPromptErrortracking) => {
    const _satellite = (window as any)._satellite;
    AnalyticsService.validatePageContext();
    try {
      _satellite.track(Constants.TrackerKeys.ExportError, errorTrackerObj);
    } catch (error) {
      console.log(error);
    }
  };
}
