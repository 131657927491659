import {  SHOW_RECOMENDED_FOR_YOU, showRecomendedForYouTypeAction } from "../types";
import { CardData } from "../../models/SharedModels";

// TypeScript infers that this function is returning SendMessageAction

export function showRecomendedForYouActions(type: any,show: boolean):  showRecomendedForYouTypeAction{
  return {
    type: type,
    payload: show
  }
}


