import React from "react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import TipsBulb from "../../../../../assets/prompt/tips-bulb-icon.svg";
import TipsIcon from "../../../../../assets/prompt/tips_icon.svg";
import { Constants } from "../../../../../helpers/Constants";
import { SharedService } from "../../../../../services/SharedService";
import "./Guidelines.scss";
import { GuidelineItem } from "./GuidelinesConstants";

/**
 * Guidelines Component
 *
 * This component represents "Quick Tips" in Prompt by KX on RHN
 */
const Guidelines: React.FC = () => {
  const _sharedService = SharedService.GetInstance();

  const guidelineslist: GuidelineItem[] = JSON.parse(
    _sharedService.Data.Labels[Constants.LabelKeys.KXPrompt_TipsList]
  )?.tipsOptions;

  /**
   * Render the Guidelines component.
   *
   * @returns {JSX.Element} - The rendered Guidelines component.
   */
  return (
    <div className="guidelines-parent-container">
      <div className="guidelines-header">
        <div className="guidelines-left-container">
          <img className="icon" src={TipsBulb} alt="Icon" />
          <div className="heading-container">
            <span className="heading-label">
              {
                _sharedService.Data.Labels[
                  Constants.LabelKeys.KXPrompt_QuickTipsLabel
                ]
              }
            </span>
          </div>
        </div>

        <div className="guidelines-right-container">
          <PrimaryButton
            className="guided-tour-btn"
            onClick={() =>
              window.open(
                _sharedService.Data.Labels[
                  Constants.LabelKeys.KXPrompt_GuidedTourURL
                ]
              )
            }
          >
            {
              _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_GuidedTourLabel
              ]
            }
          </PrimaryButton>
        </div>
      </div>
      <div className="guidelines-container">
        {guidelineslist?.map((guidelineItem, index) => (
          <div key={index} className="guideline-card">
            <div className="title">
              <img src={TipsIcon} alt="Icon" style={{ marginRight: 8 }} />
              {guidelineItem.label}
            </div>
            <div className="content">{guidelineItem.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

/**
 * Export the Guidelines component as default
 */
export default Guidelines;
