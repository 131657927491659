import * as React from "react";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import "./PageLoader.scss";
import { SpinnerSize } from "office-ui-fabric-react";
import { SharedService } from "../../../../../services/SharedService";
import { Constants } from "../../../../../helpers/Constants";

/**
 * Interface for PageLoader component props
 */
interface IPageLoaderProps {
  text?: string;
}

/**
 * PageLoader Component
 *
 *
 *
 */
const PageLoader: React.FunctionComponent<IPageLoaderProps> = (props) => {
  const _sharedService = SharedService.GetInstance();
  const loaderLabel =
    _sharedService.Data.Labels[
      Constants.LabelKeys.KXPrompt_QuickViewLoaderText
    ];

  const stackTokens: IStackTokens = {
    childrenGap: 20,
  };

  const styles: React.CSSProperties = {
    background: "#000",
    position: "fixed",
    opacity: 0.77,
    zIndex: 1000001,
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  /**
   * Render the PageLoader component.
   *
   * @returns {JSX.Element} - The rendered PageLoader component.
   */
  return (
    <Stack tokens={stackTokens} style={styles} className="prompt_PageLoader">
      <Spinner label={loaderLabel} size={SpinnerSize.large} />
    </Stack>
  );
};

/**
 * Export the PageLoader component as default
 */
export default PageLoader;
