import {
  SET_USER_NEED_MODAL,
  SetUserNeedModalType
} from '../types'

const initialState: any = {
  userNeedModal: false
}

export default function userNeedsModalReducer(state = initialState, action: SetUserNeedModalType): any {
  switch (action.type) {
    case SET_USER_NEED_MODAL:
      return {
        ...state,
        userNeedModal: action.payload
      }
    default:
      return state
  }
}