import React, { useState, useEffect } from 'react';
import { Link, Icon } from 'office-ui-fabric-react';
import './dropdownMultiLevel.scss';
import { Constants } from '../../../helpers/Constants';

export interface IGeography {
    ID: number,
    GeographyName: string,
    Path: string,
    ChildGeographies: IGeography[],
}

export interface DropdownData extends IGeography {
    selected?: boolean,
}

export interface DropdownMultilevelProps {
    data: DropdownData[],
    labelIcon?: string,
    onChange?: (event: React.FormEvent<HTMLDivElement>, option?: any, index?: number) => void | any
}

export interface DropdownItemProps {
    dataItem: DropdownData,
    handleInnerClick?: (e: any) => void,
    selectedState: string,
    isFirstItem?: boolean
}

export interface DropdownInnerProps {
    dataInner: DropdownData[],
    handleInnerClick?: (e: any) => void,
    selectedState: string
}

const resizeHandler = () => {
    const curOpenedDrop = document.querySelector<HTMLElement>('.dropdown__list');
    if (curOpenedDrop) {
        const dropRight = curOpenedDrop.getBoundingClientRect().right;
        const docWidth = document.documentElement.clientWidth;

        if (dropRight > docWidth) {
            curOpenedDrop.style.marginLeft = `${docWidth - dropRight}px`
        }
    }
}

export const DropdownMultilevel: React.FunctionComponent<DropdownMultilevelProps> = props => {
    const [selectedItem, setSelectedItem] = useState(props.data[0].GeographyName);
    const [isDropOpened, setIsDropOpened] = useState(false);

    const hideDrop = () => {
        setIsDropOpened(false);
    }

    const handleInnerClick = (e: any) => {
        setSelectedItem(e.target.textContent);
        hideDrop();
    }

    const mainOpenerClickHandler = (e: any) => {
        e.preventDefault();
        setIsDropOpened(!isDropOpened);

        if (!isDropOpened) {
            window.addEventListener('resize', resizeHandler, true);
        } else {
            window.removeEventListener('resize', resizeHandler, true);
        }
    }

    const outsideClickHandler = (e: any) => {
        if (!e.target.closest('.dropdown-multilevel')) {
            hideDrop();
        }
    }

    useEffect(() => {
        if (isDropOpened) {
            resizeHandler();
            window.addEventListener('resize', resizeHandler);
            document.addEventListener('click', outsideClickHandler);
        } else {
            window.removeEventListener('resize', resizeHandler);
            document.removeEventListener('click', outsideClickHandler);
        }
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [isDropOpened]);

    return (
        <div className="dropdown-multilevel">
            <div className='dropdown__opener' onClick={mainOpenerClickHandler}>
                {props.labelIcon && (
                    <i className={props.labelIcon}></i>
                )}
                {selectedItem}
                {isDropOpened ? <Icon iconName="CaretUpSolid8" className="caret-icon" /> : <Icon iconName="CaretDownSolid8" className="caret-icon" />}
            </div>
            {isDropOpened && (
                <div className="dropdown__list">
                    <DropdownInner dataInner={props.data} handleInnerClick={handleInnerClick} selectedState={selectedItem} />
                </div>
            )}
        </div>
    );
}

export const DropdownInner: React.FunctionComponent<DropdownInnerProps> = props => {
    return (
        <ul>
            {
                props.dataInner.map((dropDownInnerItem: any, index: number) => {
                    return (
                        <DropdownItem isFirstItem={index === 0} dataItem={dropDownInnerItem} key={dropDownInnerItem.ID} handleInnerClick={props.handleInnerClick} selectedState={props.selectedState} />
                    )
                })
            }
        </ul>
    )
}

export const DropdownItem: React.FunctionComponent<DropdownItemProps> = props => {
    const [isInnerOpened, setIsInnerOpened] = useState(false);
    const innerOpenerClickHandler = (e: any) => {
        e.preventDefault();
        setIsInnerOpened(!isInnerOpened);
    }

    return (
        <li key={props.dataItem.ID} className={(props.dataItem.GeographyName === props.selectedState) ? 'selected' : ''}>
            <Link href={Constants.Routing.PracticeProfile + '?id=' + props.dataItem.ID} onClick={(props.dataItem.ChildGeographies && props.dataItem.ChildGeographies.length > 0) ? innerOpenerClickHandler : props.handleInnerClick}>
                {props.dataItem.GeographyName + ' ' + (props.dataItem.Path !== null && props.dataItem.ID !== 0 ? props.dataItem.Path : '')}
                {(props.dataItem.ChildGeographies && props.dataItem.ChildGeographies.length > 0) && (
                    isInnerOpened ? <i className="icon-kx-chevron-up" /> : <i className="icon-kx-chevron-down" />
                )}
            </Link>
            {(props.dataItem.ChildGeographies && props.dataItem.ChildGeographies.length > 0) && (
                isInnerOpened && (
                    <DropdownInner dataInner={props.dataItem.ChildGeographies} handleInnerClick={props.handleInnerClick} selectedState={props.selectedState} />
                )
            )}
        </li>
    )
}

