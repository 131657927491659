import React, { useState, useEffect } from "react";
import { SharedService } from "../../../../services/SharedService";
import { ContextMenu } from "../../contextMenu/contextMenu";
import { usePageEditorContext } from "../../contextAPIs/pageEditorContext";
import { IContextMenuItem, IContextMenu } from "../../../../models/IShared";
import { isEmpty } from "lodash";
import { ModalBase } from "../../modalBase/modalBase";
import { Constants } from "../../../../helpers/Constants";

interface IUserDropdownProps {
  handle?: ((event: any) => void) | undefined;
  additionalClass?: string;
}

const UserProfileDropdown: React.FC<IUserDropdownProps> = (props) => {
  let _sharedService = SharedService.GetInstance();
  let { pageEditButtonsConfig } = usePageEditorContext();
  let currentPageTitle = pageEditButtonsConfig
    ? pageEditButtonsConfig.pageTitle
    : "";
  const [menuItemsUser, setMenuItemsUser] = useState<IContextMenu>(
    {} as IContextMenu
  );
  const [deletionModalState, setDeletionModalState] = useState<boolean>(false);

  useEffect(() => {
    let _configurations = _sharedService.Data.Configuration;
    let MenuItemsUser: IContextMenu = _configurations
      ? JSON.parse(_configurations.MenuItemsUser)
      : null;

    let myDraftsItem: IContextMenuItem = {
      key: "Drafts",
      linkText: _sharedService.Data.Labels[Constants.LabelKeys.DraftsLabel],
      isUrlInternal: true,
      target: "_self",
      url: "/Drafts",
    };
    let taxonomyManagment: IContextMenuItem = {
      key: "taxonomyManagment",
      linkText:
        _sharedService.Data.Labels[Constants.LabelKeys.taxonomyManagmentLabel],
      isUrlInternal: true,
      target: "_self",
      url: "/TaxonomyManagement",
    };
    if (MenuItemsUser && !isEmpty(MenuItemsUser.items)) {
      if (pageEditButtonsConfig && pageEditButtonsConfig.pageTitle) {
        if (!isEmpty(pageEditButtonsConfig.editUrl)) {
          let item: IContextMenuItem = {
            key: "Edit Item",
            linkText: _sharedService.Data.Labels[Constants.LabelKeys.EditLabel],
            isUrlInternal: false,
            target: "_blank",
            url: pageEditButtonsConfig.editUrl,
          };
          MenuItemsUser.items = [...MenuItemsUser.items, item];
        }

        if (pageEditButtonsConfig.deleteHandler) {
          let item: IContextMenuItem = {
            key: "Delete Item",
            linkText:
              _sharedService.Data.Labels[Constants.LabelKeys.DeleteLabel],
            handler: invokeDeleteModal,
          };
          MenuItemsUser.items = [...MenuItemsUser.items, item];
        }
      }

      if (!_sharedService.Data.CurrentUser.isCurrentUserCurator) {
        let itemKeysForCurator = ["myProfileCreateContent"];
        MenuItemsUser.items = MenuItemsUser.items.filter(
          (item) => itemKeysForCurator.indexOf(item.key) === -1
        );
      }

      if (_sharedService.Data.CurrentUser.isCurrentUserCurator) {
        MenuItemsUser.items = [...MenuItemsUser.items, myDraftsItem];
      }
      if (_sharedService.Data.CurrentUser.isCurrentUserTaxonomyAdmin) {
        MenuItemsUser.items = [...MenuItemsUser.items, taxonomyManagment];
      }
      MenuItemsUser.items.forEach((item) => {
        if (item.key && _sharedService.Data.Labels[item.key])
          item.linkText = _sharedService.Data.Labels[item.key];
      });
    }
    setMenuItemsUser(MenuItemsUser);
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [currentPageTitle]);

  const invokeDeleteModal = () => {
    setDeletionModalState(true);
  };

  return menuItemsUser && !isEmpty(menuItemsUser.items) ? (
    <>
      <ContextMenu
        items={menuItemsUser.items}
        opener={menuItemsUser.opener}
        customClass={`${menuItemsUser.customClass} ${
          props.additionalClass || ""
        }`}
        handle={props.handle}
        analyticsType={"profile"}
      />

      {pageEditButtonsConfig && pageEditButtonsConfig.deleteHandler && (
        <ModalBase
          isTwoButtons={true}
          withCancelBtn={true}
          button={{
            text: _sharedService.Data.Labels[Constants.LabelKeys.NoButtonLabel],
            function: () => setDeletionModalState(false),
          }}
          SecondButton={{
            text: _sharedService.Data.Labels[
              Constants.LabelKeys.YesButtonLabel
            ],
            function: pageEditButtonsConfig.deleteHandler,
          }}
          title={
            _sharedService.Data.Labels[Constants.LabelKeys.ItemDeletionAlert]
          }
          isOpen={deletionModalState}
        ></ModalBase>
      )}
    </>
  ) : (
    <></>
  );
};

export default UserProfileDropdown;
