import { Dispatch } from "redux";
import {
  IConversationHistoryResponseObj,
  IConversationKxHistoryRequestObj,
  IConversationKxHistoryResponseObj,
  IConversationMessage,
} from "../../models/KxPromptModels";
import {
  ADD_PROMPT_MESSAGE,
  CLEAR_PROMPT_MESSAGE,
  REMOVE_LAST_SERVER_PROMPT_MESSAGE,
  GET_ALL_CONVERSATION_HISTORY_REQUEST,
  GET_ALL_CONVERSATION_HISTORY_SUCCESS,
  GET_ALL_CONVERSATION_HISTORY_ERROR,
  GET_KX_RESULTS_HISTORY_REQUEST,
  GET_KX_RESULTS_HISTORY_SUCCESS,
  GET_KX_RESULTS_HISTORY_ERROR,
  SET_SIDEBAR_HISTORY_ITEM_ACTIVE,
  RESET_SIDEBAR_HISTORY_ITEM_ACTIVE,
  LOAD_CONVERSATION_FROM_HISTORY,
  AddPromptMessageAction,
  ClearPromptMessageAction,
  RemoveLastServerMessageAction,
  IGetAllConversationHistoryRequestAction,
  IGetAllConversationHistorySuccessAction,
  IGetAllConversationHistoryErrorAction,
  IGetKxResultsFromHistoryRequestAction,
  IGetKxResultsFromHistorySuccessAction,
  IGetKxResultsFromHistoryErrorAction,
  ISetSidebarHistoryItemActive,
  IReSetSidebarHistoryItemActive,
  ILoadConversationFromHistory,
  ClearFollowUpPropmptsAction,
  SetFollowUpPromptsAction,
  CLEAR_FOLLOWUP_PROMPTS,
  SET_FOLLOWUP_PROMPTS,
  RESET_ALL_CONVERSATION_HISTORY_ERROR,
  IResetAllConversationHistoryErrorAction,
  IResetKxResultsFromHistoryErrorAction,
  RESET_KX_RESULTS_HISTORY_ERROR,
} from "../types";
import { SharedService } from "../../services/SharedService";
import { IPromptErrortracking } from "../../models/IAnalytics";
import { AnalyticsService } from "../../services/AnalyticsService";

export type ConversationActionTypes =
  | AddPromptMessageAction
  | ClearPromptMessageAction
  | SetFollowUpPromptsAction
  | ClearFollowUpPropmptsAction
  | RemoveLastServerMessageAction
  | IGetAllConversationHistoryRequestAction
  | IGetAllConversationHistorySuccessAction
  | IGetAllConversationHistoryErrorAction
  | IResetAllConversationHistoryErrorAction
  | IGetKxResultsFromHistoryRequestAction
  | IGetKxResultsFromHistorySuccessAction
  | IGetKxResultsFromHistoryErrorAction
  | IResetKxResultsFromHistoryErrorAction
  | ISetSidebarHistoryItemActive
  | IReSetSidebarHistoryItemActive
  | ILoadConversationFromHistory;

export function addMessage(
  message: IConversationMessage
): ConversationActionTypes {
  return {
    type: ADD_PROMPT_MESSAGE,
    payload: message,
  };
}
export function clearMessage(): ConversationActionTypes {
  return {
    type: CLEAR_PROMPT_MESSAGE,
    payload: [],
  };
}

export function setFollowupPrompt(
  followUpPrompts: Array<string>
): ConversationActionTypes {
  return {
    type: SET_FOLLOWUP_PROMPTS,
    payload: followUpPrompts,
  };
}
export function clearFollowupPrompt(): ConversationActionTypes {
  return {
    type: CLEAR_FOLLOWUP_PROMPTS,
    payload: [],
  };
}

export function removeLastServerMessage(): ConversationActionTypes {
  return {
    type: REMOVE_LAST_SERVER_PROMPT_MESSAGE,
  };
}

// Sidebar History Item Active
export const setSidebarHistoryItemActive = (
  sessionId: string
): ConversationActionTypes => {
  return {
    type: SET_SIDEBAR_HISTORY_ITEM_ACTIVE,
    payload: sessionId,
  };
};

export const resetSidebarHistoryItemActive = (): ConversationActionTypes => {
  return {
    type: RESET_SIDEBAR_HISTORY_ITEM_ACTIVE,
  };
};

// Load Conversation From History

export const loadConversationFromHistory = (
  messagesFromHistory: IConversationMessage[]
): ConversationActionTypes => {
  return {
    type: LOAD_CONVERSATION_FROM_HISTORY,
    payload: messagesFromHistory,
  };
};

// Conversation History - Sidebar

const fetchAllConversationHistoryRequest =
  (): IGetAllConversationHistoryRequestAction => ({
    type: GET_ALL_CONVERSATION_HISTORY_REQUEST,
  });

const fetchAllConversationHistorySuccess = (
  data: IConversationHistoryResponseObj[]
): IGetAllConversationHistorySuccessAction => ({
  type: GET_ALL_CONVERSATION_HISTORY_SUCCESS,
  payload: data,
});

const fetchAllConversationHistoryError = (
  data: string
): IGetAllConversationHistoryErrorAction => ({
  type: GET_ALL_CONVERSATION_HISTORY_ERROR,
  payload: data,
});

export const resetAllConversationHistoryError =
  (): IResetAllConversationHistoryErrorAction => ({
    type: RESET_ALL_CONVERSATION_HISTORY_ERROR,
  });

const fetchKxResultsFromHistoryRequest = (
  data: IConversationKxHistoryRequestObj
): IGetKxResultsFromHistoryRequestAction => ({
  type: GET_KX_RESULTS_HISTORY_REQUEST,
  payload: data,
});

const fetchKxResultsFromHistorySuccess = (
  data: IConversationKxHistoryResponseObj[]
): IGetKxResultsFromHistorySuccessAction => ({
  type: GET_KX_RESULTS_HISTORY_SUCCESS,
  payload: data,
});

const fetchKxResultsFromHistoryError = (
  data: string
): IGetKxResultsFromHistoryErrorAction => ({
  type: GET_KX_RESULTS_HISTORY_ERROR,
  payload: data,
});

export const resetKxResultsFromHistoryError =
  (): IResetKxResultsFromHistoryErrorAction => ({
    type: RESET_KX_RESULTS_HISTORY_ERROR,
  });

export const getAllConversationHistory = () => {
  return async (dispatch: Dispatch<ConversationActionTypes>) => {
    dispatch(fetchAllConversationHistoryRequest());
    try {
      const _sharedService = SharedService.GetInstance();
      const data = await _sharedService.getGenAIConversationHistory();
      if (data !== null && data !== undefined && !(data instanceof Error)) {
        dispatch(fetchAllConversationHistorySuccess(data));
      } else {
        if (data) {
          dispatch(fetchAllConversationHistoryError(data.message));
          const errorTrackerObj: IPromptErrortracking = {
            action: "Fetching History",
            errorMessage: data.message,
          };
          AnalyticsService.trackPromptError(errorTrackerObj);
        }
      }
    } catch (error) {
      dispatch(fetchAllConversationHistoryError(error as string));
      const errorTrackerObj: IPromptErrortracking = {
        action: "Fetching History",
        errorMessage: error as string,
      };
      AnalyticsService.trackPromptError(errorTrackerObj);
    }
  };
};

export const getKxResultsFromHistory = (
  payloadData: IConversationKxHistoryRequestObj
) => {
  return async (dispatch: Dispatch<ConversationActionTypes>) => {
    dispatch(fetchKxResultsFromHistoryRequest(payloadData));
    try {
      const _sharedService = SharedService.GetInstance();
      const data = await _sharedService.getGenAIKxResultsFromHistory(
        payloadData
      );
      if (data !== null && data !== undefined && !(data instanceof Error)) {
        dispatch(fetchKxResultsFromHistorySuccess(data));
      } else {
        if (data) {
          dispatch(fetchKxResultsFromHistoryError(data.message));
          const errorTrackerObj: IPromptErrortracking = {
            action: "Fetching the Messages from history",
            errorMessage: data.message,
          };
          AnalyticsService.trackPromptError(errorTrackerObj);
        }
      }
    } catch (error) {
      dispatch(fetchKxResultsFromHistoryError(error as string));
      const errorTrackerObj: IPromptErrortracking = {
        action: "Fetching the Messages from history",
        errorMessage: error as string,
      };
      AnalyticsService.trackPromptError(errorTrackerObj);
    }
  };
};
