import React, { createContext, useState, useContext } from "react";


const AllDataContext = createContext({
  tableId: 0,
  setAllData: (allData: []) => {},
});

function useAllDataContext() {
  const context = useContext(AllDataContext);
  if (!context)
    throw new Error(
      "useAttachementNotificationContext must be used within AttachmentNotificationProvider!"
    );
  return context;
}

function AllDataProvider(props: any) {
  const [allData, setAllData] = useState<any>(null);
  const value:any = { allData, setAllData };
  return <AllDataContext.Provider value={value} {...props} />;
}

export { AllDataProvider, useAllDataContext };
