import React, { useState, useEffect } from "react";
import { Constants } from "../../../helpers/Constants";
import { CardSchema } from "../../../models/SharedModels";
import { PrimaryButton } from "office-ui-fabric-react";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { SharedService } from "../../../services/SharedService";
import "./compareArea.scss";
import { CompareContentPageItem } from "./compareContentPageItem";
import KnowledgeItemHeader from "../knowledgeItemHeader/knowledgeItemHeader";
import { useCompareContentContext } from "../contextAPIs/comparContentContext";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { IPageTracker, IPersonalizationTracker } from "../../../models/IAnalytics";
import { forEach } from "lodash";
import { useSelector } from "react-redux";

const CompareContentPage: React.FC<{}> = (props) => {
  const { itemsToCompare } = useCompareContentContext();
  const { setItemsToCompare } = useCompareContentContext();
  const _userNeeds: any = useSelector((s: any) => s.userNeedsReducer);

  const _sharedService = SharedService.GetInstance();

  var [compareContentTableFields, setCompareContentTableFields] = useState<string[]>([]);

  useEffect(() => {
    let _configurations = SharedService.GetInstance().Data.Configuration;
    let configCCFields = JSON.parse(_configurations.CompareContentPageFields);
    setCompareContentTableFields(configCCFields.fields);
    let bannerFooterElem = document.getElementsByClassName("banner__footer");
    if(bannerFooterElem && bannerFooterElem.length > 0)
      bannerFooterElem[0].setAttribute("style","display:none");
    let getItemsToComareFromLocalStorage = async () => {
      let itemsToCompareLS = await CommonFunctions.getCompareItemsToLocalStorage();
      if (itemsToCompareLS && itemsToCompareLS.length) {
        itemsToCompareLS.forEach( async (item: CardSchema, index:number) => {
          item.isChecked = false;
          item.key = index+1;
        });
        sortItems(itemsToCompareLS);
        setTimeout(() => {
          //setTagsHeight("badges", "compare-content-page-item badge", "compare-content-page-table-headings-child-badges");
          setTagsHeight("tags-section topics", "compare-content-page-item topics", "compare-content-page-table-headings-child-topics");
          setTagsHeight("tags-section tags", "compare-content-page-item tags", "compare-content-page-table-headings-child-tags");
          setTagsHeight("attachments", "compare-content-page-item attachments", "compare-content-page-table-headings-child-attachments");
        }, 2000);
      }
    };
    getItemsToComareFromLocalStorage();
  }, []);
  useEffect(() => {
    if(_sharedService.Data.Labels[Constants.LabelKeys.PageTitleHome])
      document.title = _sharedService.Data.Labels[Constants.LabelKeys.PageTitleHome];
    CommonFunctions.SetLanguage();
    
    if (_userNeeds && _userNeeds.userNeeds && _userNeeds.userNeeds.text) {
      _sharedService.Actions.Get.GetMyInterests().then((res) => {
        if (res != null && res.length > 0) {
          _sharedService.Data.CurrentUser.isInterest = true;
        }

        AnalyticsService.resetPageContext();
        let pageTracker: IPageTracker = {} as IPageTracker;

        let personalizationTracker: IPersonalizationTracker = {} as IPersonalizationTracker;
        pageTracker = {
          pageInfo: {
            breadCrumbs: "",
            contentID: "",
            contentTitle: Constants.PageTitles.CompareItems,
            genericPageName: Constants.PageTitles.CompareItems,
            language: _sharedService.lang,
            sourceContentType: "",
            sourceSystem: "",
          },
        };
        personalizationTracker = {
          need: _userNeeds.userNeeds.text,
          hasInterests: _sharedService.Data.CurrentUser.isInterest
            ? _sharedService.Data.CurrentUser.isInterest
            : false,
        };
        AnalyticsService.trackPageLoad(pageTracker, personalizationTracker);
      });
    }
  }, [_userNeeds]);
  var emailCompareSelections = () => {
    var emailbody  = "";
    itemsToCompare.forEach( (item: CardSchema) => {
      if(item.isChecked === true)
        emailbody +=  item.allData.internalcontenttype + "\n" + item.allData.title + "\n" + item.allData.appurl +"\n\n";
    });
    let emailContent = "mailto:?subject=KX Content&body=" + encodeURIComponent(emailbody);
    window.open(emailContent);
  }
  var removeCompareItem = (event: any, index:number) => {
    let compareItems = [...itemsToCompare];
    compareItems.splice(index,1);
    _sharedService.AddCompareContentToLocalStorage(compareItems);
    sortItems(compareItems);
  }
  var handleCheck = (item: CardSchema, isChecked: boolean) => {
    let compareItems = [...itemsToCompare];
    compareItems.forEach( (childItem: CardSchema) => {
      if(childItem.key === item.key)
        childItem.isChecked = !isChecked;
    });
    setItemsToCompare(compareItems);
    setTimeout(() => {
      let tempElems = document.getElementsByClassName("compare-content-page-item-chkbox");
      forEach(tempElems, (elem:any) => {
        elem.parentElement.parentElement.parentElement.classList.remove("checkbox-selected");
      });
      let elems = document.getElementsByClassName("is-checked");
      forEach(elems, (elem:any) => {
        elem.parentElement.parentElement.parentElement.classList.add("checkbox-selected");
      });
    }, 0);
  }
  var getCheckedItems = (item:CardSchema) => {
    return item.isChecked === true;
  }
  var sortItems = (compareItems:any) => {
    let contentType = '';
    let contentTypeArr:any[] = [];
    let contentTypeMismatch = false;
    compareItems.forEach( (childItem: CardSchema) => {
      if(contentTypeArr.indexOf(childItem.allData.globalcontenttype) === -1)
        contentTypeArr.push(childItem.allData.globalcontenttype);
      if(contentType !== '' && contentType !== childItem.allData.globalcontenttype) {
        contentTypeMismatch = true;
      }
      else {
        contentType = childItem.allData.globalcontenttype;
      }
    });
    if(contentTypeMismatch !== false) {
      contentTypeArr.sort();
      let tempArr:any[] = [];
      contentTypeArr.forEach( (item: any) => {
        let childArr = compareItems.filter( function(x:any) { return x.allData.globalcontenttype === item; });
        if(childArr && childArr.length > 0) {
          childArr = sortAlphabatical(childArr, 'title');
          tempArr = [...tempArr, ...childArr];
        }
      });
      setItemsToCompare(tempArr ? tempArr : compareItems);
    }
    else {
      let tempArr = sortAlphabatical(compareItems, 'title');
      setItemsToCompare(tempArr ? tempArr : compareItems);
    }
  }
  var sortAlphabatical = (arr: any, property: string) => {
    arr.sort(function (a: any, b: any) {
      var textA = a && a.allData[property] ? a.allData[property].toUpperCase() : null;
      var textB = b && b.allData[property] ? b.allData[property].toUpperCase() : null;
      if (textA === textB)          // identical? return 0
        return 0;
      else if (!textA)  // a is null? last 
        return 1;
      else if (!textB)  // b is null? last
        return -1;
      return textA.localeCompare(textB);
    });
    return arr;
  };
  var setTagsHeight = (childClassName:string, parentClassname:string, parentHeadingName:string) => {
    let elemArrBadges = document.getElementsByClassName(childClassName);
    let maxHeight = 0;
    forEach(elemArrBadges, (item:any) => {
      let currentHeight = childClassName !== "attachments" && item.parentElement && item.parentElement.offsetHeight ? item.parentElement?.offsetHeight : item.offsetHeight;
      maxHeight = currentHeight > maxHeight ? currentHeight : maxHeight;
    });
    if(document.getElementsByClassName(parentHeadingName) && document.getElementsByClassName(parentHeadingName).length)
      document.getElementsByClassName(parentHeadingName)[0].setAttribute("style","height:" + maxHeight + "px");

    let tempElemArr = document.getElementsByClassName(parentClassname);
    forEach(tempElemArr, (item) => {
      item.setAttribute("style","height:" + maxHeight + "px");
    });
  }

  return (
    <div className="compare-content-page-container">
      <KnowledgeItemHeader
        itemTitle={_sharedService.Data.Labels[Constants.LabelKeys.ComparePageTitle]}
        desc={_sharedService.Data.Labels[Constants.LabelKeys.ComparePageSubTitle]}
        contentTypeName={Constants.ExplorePage}
        bannerImage={CommonFunctions.changeUrlSafari(SharedService.SpDomainUrl + SharedService.spSiteUrl +Constants.BannerImages.ComparePage)}
       ></KnowledgeItemHeader>
        <div className="compare-content-page-header">
        <PrimaryButton className='compare-selection-btn' text={_sharedService.Data.Labels[Constants.LabelKeys.CompareEmailLinks]} onClick={() => emailCompareSelections()} disabled={itemsToCompare.filter(getCheckedItems).length === 0 } />
        <span className='number-of-selected-compare-items'>{itemsToCompare.filter(getCheckedItems).length} {_sharedService.Data.Labels[Constants.LabelKeys.ComparePageNumberText]}</span>
        </div>
        <div className="compare-content-page-body">
        {itemsToCompare.length > 0 ?
          <div className="compare-content-page-table-headings"> {
            compareContentTableFields.length > 0 ? compareContentTableFields.map((field:string) => (
              <div className={field !== 'Title' && field !== 'Description' && field !== 'Badges' && field !== 'Topics' && field !== 'Tags' && field !== 'Attachments' 
              ? 'compare-content-page-table-headings-child' 
              : field === 'Title' ? 'compare-content-page-table-headings-child-title' 
              : field === 'Badges' ? 'compare-content-page-table-headings-child-badges' 
              : field === 'Topics' ? 'compare-content-page-table-headings-child-topics'
              : field === 'Tags' ? 'compare-content-page-table-headings-child-tags' 
              : field === 'Attachments' ? 'compare-content-page-table-headings-child-attachments' 
              : 'compare-content-page-table-headings-child-description' }>
                <span>{field}</span>
              </div>
            )) : null
          }
          </div>
          : null}
          {itemsToCompare && itemsToCompare.length > 0 ? itemsToCompare.map((item: CardSchema, index:number) => (
            <CompareContentPageItem removeHandler={removeCompareItem} handleCheck={handleCheck} setTagsHeight={setTagsHeight} isChecked={item.isChecked} itemIndex={index} card={item} key={index} />
          )) : null}
        </div>
    </div>
  );
};
export default CompareContentPage;