import React, { useState, useEffect, Fragment, useRef } from "react";
import { Stack, Text, Link, TooltipBase } from "office-ui-fabric-react";
import { OverflowSetBase } from "../../shared/overflowSetBase/overflowSetBase";
import { SwiperCustom } from "../../shared/swiperCustom/swiperCustom";
import useHover from "@react-hook/hover";
import "./cardImage.scss";
import { con } from "../cardBase/cardBase";
import { Constants } from "../../../helpers/Constants";
import { SharedService } from "../../../services/SharedService";
import { Follower, CardData } from "../../../models/SharedModels";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { ButtonsResizeGroup } from "../buttonsResizeGroup/buttonsResizeGroup";
import { IMiniCardTracker } from "../../../models/IAnalytics";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";
export interface ImageCardProps {
  collection: any[];
  slider?: boolean;
}

export interface ImageCardContentProps {
  slider?: boolean;
  card: any;
  categoryMove?: (e: any, cardData: CardData) => void;
  chosenItems?: any[];
  openModalEdit?: any;
  explainData?:any;
}

export const cardImage: React.FunctionComponent<ImageCardProps> = (props) => {
  const ConditionalWrap = (con: con) =>
    con.condition ? con.wrap(con.children) : con.children;
  return (
    <Stack horizontal>
      <ConditionalWrap
        condition={props.slider}
        wrap={(children) => {
          return <SwiperCustom>{children}</SwiperCustom>;
        }}
      >
        {props.collection.map((item, i) => {
          return <ImageCardContent card={item} key={i} />;
        })}
      </ConditionalWrap>
    </Stack>
  );
};

export const ImageCardContent: React.FunctionComponent<ImageCardContentProps> = (
  props
) => {
  const target = useRef(null);
  const [isMenuOpened, setIsOpened] = useState(false);
  const [card, setCard] = useState(props.card);
  const [containerHeight, setContainerHeight] = useState<any>(null);
  const [containerWidth, setContainerWidth] = useState<any>(null);
  const titleElement = useRef(null);
  const cardDescriptionElement = useRef(null);
  const otherContentElement = useRef(null);
  
  const onShow = () => {
    setIsOpened(true);
  };

  const onHide = () => {
    setIsOpened(false);
  };

  let history = useHistory();

  const goToContent = (url: string, openInNewTab?: boolean) => {
    if (url) {
      // do tracking
      let cardTracker: IMiniCardTracker = {} as IMiniCardTracker;
      cardTracker.action = Constants.MiniCardActions.ViewFullContent;
      cardTracker.contentID = card.sourceId ? `${card.sourceId}` : "";
      cardTracker.displayArea = card.displayArea ? card.displayArea : "";
      cardTracker.isExternal = card.linkToItem ? card.linkToItem.indexOf(SharedService.dpnLink) !== -1 : false;
      cardTracker.position = card.position ? `${card.position}` : "";
      cardTracker.url = card.linkToItem;
      cardTracker.title = card.allData && card.allData.title ? card.allData.title : "";
      cardTracker.sourceSystem = card.allData.internalcontenttype || card.allData.Type || card.ctName;
      cardTracker.sourceContentType = card.allData.apptype;
      cardTracker.isMiniCard = true;
      cardTracker.imageType = card.allData && card.allData.img_minicardlabel ? card.allData.img_minicardlabel : "";
      AnalyticsService.trackMiniCard(cardTracker);
      CommonFunctions.redirectLink(true, url, history);
    }
  };

  useEffect(() => {
    if (card && card.overflowItemsData && card.overflowItemsData.length > 0 && card.overflowItemsData[0].overflowItems) {
      //insert the on click method
      var index =
        card.overflowItemsData[0] &&
        card.overflowItemsData[0].overflowItems.find(
          (item: any) => item.key == Constants.ActionsButtonsKeys.Follow
        );
      if (index)
        index.onClick = (event: any, original: any) =>
          CommonFunctions.followUnFllow(
            event,
            original,
            card.internalcontenttype,
            card.sourceId,
            card,
            card.email
          );
    }
    return () => {
      //console.log('people card is unmounted.')
    };
  }, []);

  
  var tempArr: any[] = props.chosenItems ? JSON.parse(JSON.stringify(props.chosenItems)) : [];
  var index = tempArr.findIndex((carddata:any)=> props.card.allData && carddata.allData && ((carddata.allData.img_Id && 
    props.card.allData.img_Id && props.card.allData.img_Id === carddata.allData.img_Id) || (!props.card.allData.img_Id && carddata.allData.title && 
      props.card.allData.title && props.card.allData.title === carddata.allData.title)))
  return (
    <div className="ms-Grid-col" key={props.card.key} ref={target}>
      <Stack
        className={`image-card ${props.card.isHorizontal ? "horizontal" : ""} ${props.categoryMove ? "categoryChoose" : ""} ${index != -1 ? "greenBorder" : ""}`}
        onClick={(e) => {
          if (props.categoryMove)
            props.categoryMove(e, props.card)
          else {
            e ?.stopPropagation();
            if (e ?.ctrlKey) {
              goToContent(card.linkToItem, true);
            } else {
              goToContent(card.linkToItem, card.openNewTab);
            }
          }
        }}
      >
        {props.card.overflowItemsData && props.card.overflowItemsData.length > 0 &&
          props.card.overflowItemsData[0].overflowItems && (
            <>
            <div className={"card-fade"}> </div>
            <div className={`card-menu ${isMenuOpened ? "is-opened" : ""}`}>
              <OverflowSetBase
                cardData={props.card}
                items={props.card.overflowItemsData[0].items}
                overflowItems={props.card.overflowItemsData[0].overflowItems}
                onShow={onShow}
                onHide={onHide}
                type={props.card.type}
                openModalEdit={props.openModalEdit}
                explainData={props.explainData}
              />
            </div>
            </>
          )}
        <Stack.Item className="image-card__image">
          <img style={{ visibility: 'hidden', position: 'absolute' }} src={!isEmpty(props.card.imageData) ? props.card.imageData : card.image} />

          <div
            className="img"
            style={{
              height: containerHeight,
              width: containerWidth,
              backgroundImage: `url("${
                !isEmpty(props.card.imageData) ? props.card.imageData : card.image
                }")`,
            }}
          ></div>

          <div ref={titleElement} className="image-card__title">       
            {props.card.title.text} <span className="hiddenSpan">.</span>
          </div>
         
          {props.card.tag && !props.card.IsRemoved && (
                <Link  className="image-card__tag">
                  {props.card.allData.img_minicardlabel}
                </Link>
              )}
              {props.card.badge && !props.card.IsRemoved && (
                <Link className="image-card__badge">
                  <i className={`icon-kx-${props.card.badge}`}></i>
                </Link>
              )}
              {props.card.ifPinCard && (
                <Link className={props.card.badge ? `image-card__pin` : `image-card__pin-no-tag`}>
                  <i className={`icon-kx-pin`}></i>
                </Link>
              )}
          {!props.card.isHorizontal ? <div>
            <div style={{ display: 'flex' }}>
             
              
            </div>
            <Text block className="image-card__subtitle">
              {props.card.subTitle}
            </Text>
          </div> : <div style={{ display: 'flex' }}>
              {props.card.badge && !props.card.IsRemoved && (
                <Link className="image-card__badge">
                  <i className={`icon-kx-${props.card.badge}`}></i>
                </Link>
              )}
              {props.card.ifPinCard && (
                <Link className="image-card__pin">
                  <i className={`icon-kx-pin`}></i>
                </Link>
              )}
            </div>}


        </Stack.Item>
        <Stack.Item className="image-card__content">
          {!props.card.isHorizontal && (
            <Fragment>

              <Text block className="image-card__text-first">
                {props.card.textFirst}
              </Text>
              <Text block className="image-card__text-third">
                {Array.isArray(props.card.textSecond)
                  ? CommonFunctions.stringFromArr(props.card.textSecond)
                  : props.card.textSecond}
              </Text>


            </Fragment>
          )}
        </Stack.Item>

      </Stack>
    </div>
  );
};
