import React, { createRef, useEffect, useState } from "react";
import {
  IIconProps,
  IBasePicker,
  ITag,
  TagPicker,
  IBasePickerSuggestionsProps,
  IInputProps,
  ValidationState, Stack, Modal, IStackTokens, StackItem, TextField
} from "@fluentui/react";
import { SharedService } from "../../../services/SharedService";
import styles from "./peoplePickerbase.module.scss";
import { useParams } from 'react-router';

export interface PeoplePickerProps {
  dataList: any[];
  suggestionsHeaderText?: string;
  AddbtnText?: string;
  placeholder?: string;
  itemlimit?: number;
  ContentType: boolean;
  NSTags?: boolean;
  author?: boolean;
  keyContacts?: boolean;
  contributor?: boolean;
  reopenFlag?: boolean
  AuthorData?: any[];
  contactData?: any[];
  contributorData?: any[];
  contentPublisher?: boolean;
  qaResource?: boolean;
  contentManager?: boolean;
  contentApprover?: boolean;
  clientName?: boolean;
  quals?: boolean;
  selectedItems?: any[];
  defaultValue?: ITag[] | undefined;
  defaultObj?: any;
  disabled?: boolean;
  onSelectedItemChange?: (item: any | undefined) => void;
  countryOfOrigin?: boolean;
  onNewClientAdd?: (item: any) => void;
  contentTags?: boolean;
  Activities?: boolean;
  setTryClient?: any;
  clientNameNC?: boolean;
  clientServices?: boolean;
  authorInvalidInput?: (value: string) => void;
  contactInvalidInput?: (value: string) => void;
  contributorInvalidInput?: (value: string) => void;
  contentPublisherInvalidInput?: (value: string) => void;
  qaResourceInvalidInput?:(value: string) => void;
  contentManagerInvalidInput?:(value: string) => void;
  contentApproverInvalidInput?:(value: string) => void;
  enableExternalAuthor?: boolean;
  addtionalNCExpandCount?: number;
}

export type Category = {
  id: number;
  text: string;
};

const addIcon: IIconProps = { iconName: "Add" };
const tagPickerRef = createRef<IBasePicker<ITag>>();

export const PeoplePickerBase: React.FunctionComponent<PeoplePickerProps> = (
  props
) => {
  const _sharedService = SharedService.GetInstance();
  const containerStackTokens: IStackTokens = { childrenGap: 10 };
  const userdetails = _sharedService.Data.CurrentUser;
  const [selectedTagItemsList, setSelectedTagItemsList] = useState<
    ITag[] | undefined
  >(props.defaultValue ? props.defaultValue : []);
  const [filterstring, setFilterString] = useState<string>("");
  const [authorDataList, setAuthorDataList] = useState<any[]>([]);
  const [keyContactsDataList, setKeyContactsDataList] = useState<any[]>([]);
  const [contributorDataList, setContributorDataList] = useState<any[]>([]);
  const [contentPublisherDataList, setContentPublisherDataList] = useState<any[]>([]);
  const [qAResourceDataList, setQAResourceDataList] = useState<any[]>([]);
  const [contentManagerDataList, setContentManagerDataList] = useState<any[]>([]);
  const [contentApproverDataList, setContentApproverDataList] = useState<any[]>([]);
  const [qualsText, setQualsText] = useState<string>("");
  const picker = React.useRef(null);
  const [inFocus, setInFocus] = useState<boolean>(true);
  const { documentId }: { documentId: string } = useParams();

  const [selectedAuthorTagsList, setSelectedAuthorTagsList] = useState<ITag[] | undefined>(props.defaultValue ? props.defaultValue : []);
  const [isExAuthorModalOpen, setIsExAuthorModalOpen] = useState<boolean>(false);
  const [externalAuthorsText, setExternalAuthorsText] = useState<string>();
  const [clientNames,setClientNames]=useState<any>();

  let peoplelistdata: any[];
  let publisherPeoplelistdata: any[];
  let qaPeoplelistdata: any[];

  let authorlist: any[] = [];
  let keyContactslist: any[] = [];
  let contributorList: any[] = [];
  let contentPublisher: any = null;
  let qaResource: any = null;
  let contentManager: any = [];
  let contentApprover: any = null;

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    className: props.clientName || props.ContentType || props.Activities || props.clientNameNC ? filterstring.length < 2 || props.clientNameNC ? styles.hidesuggestion : "" :
      props.quals
        ? styles.customdropDown
        : filterstring.length < 3
          ? styles.hidesuggestion
          : "",
    // suggestionsHeaderText: props.clientName
    //   ? ""
    //   : props.quals
    //   ? qualsText
    //   : props.suggestionsHeaderText,
    noResultsFoundText: filterstring.length > 2 ? "No results found!" : "",
    /* onRenderNoResultFound: () => {
       return (
         <CommandBarButton
           iconProps={props.quals ? { iconName: "" } : addIcon}
           styles={buttonStyles}
           text={props.quals ? "" : props.AddbtnText}
           onClick={() => {
             tagPickerRef.current?.completeSuggestion(true);
           }}
         />
       );
     },*/
  };

  const inputProps: IInputProps = {
    style: { margin: 0 },
    placeholder: props.placeholder,
  };

  const getTextFromItem = (item: ITag) => item.name;

  const onInputChange = (input: string) => {
    if (props.author && props.authorInvalidInput) {
      props.authorInvalidInput(input);
    }
    if (props.keyContacts && props.contactInvalidInput) {
      props.contactInvalidInput(input);
    }
    if (props.contributor && props.contributorInvalidInput) {
      props.contributorInvalidInput(input);
    }
    if(props.contentPublisher && props.contentPublisherInvalidInput){
      props.contentPublisherInvalidInput(input);
    }
    if(props.qaResource && props.qaResourceInvalidInput){
      props.qaResourceInvalidInput(input);
    }
    if(props.contentManager && props.contentManagerInvalidInput){
      props.contentManagerInvalidInput(input);
    }
    if(props.contentApprover && props.contentApproverInvalidInput){
      props.contentApproverInvalidInput(input);
    }
    if (props.clientNameNC) {
      props.setTryClient(input);
      setFilterString(input);
      return input;
    } else {
      setFilterString(input);
      return input;
    }



  };

  const onResolveSuggestions = async (filter: string): Promise<ITag[]> => {
    if (filter.length > 2) {
      var suggestionarr: any[] = [];

      if (props.ContentType) {
        props.dataList?.map((item) => {
          return suggestionarr.push({
            name: item.FullPath.replace("u003E", ">"),
            id: item.Id,
          });
        });
        return suggestionarr
          .filter((data: any) =>
            data.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            return {
              name: data.name,
              key: data.name,
              id: data.id,
            };
          });
      } else if (props.clientServices) {
        props.dataList?.map((item) => {
          return suggestionarr.push({
            name: item.Name,
            id: item.Id,
            fullpath: item.FullPath
          });
        });
        return suggestionarr
          .filter((data: any) =>
            data.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            return {
              name: data.fullpath,
              key: data.fullpath,
              id: data.id,
            };
          });
      } else if (props.NSTags) {
        props.dataList?.map((item) => {
          return suggestionarr.push({
            name: item.FullPath,
            id: item.id,
          });
        });
        return suggestionarr
          .filter((data: any) =>
            data.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            return {
              name: data.name,
              key: data.name,
              id: data.id,
            };
          });
      } else if (props.contentPublisher) {
        publisherPeoplelistdata = await _sharedService.GetPublisherPeopleData(filterstring);
        setContentPublisherDataList([...contentPublisherDataList, ...publisherPeoplelistdata]);

        publisherPeoplelistdata.map((item) => {
          return suggestionarr.push({
            displayName: item.displayName,
            mail: item.mail,
            mailNickname: item.mailNickname,
            givenName: item.givenName,
            surname: item.surname,
          });
        });

        return suggestionarr
          .filter(
            (data: any) =>
              data.mail?.toLowerCase().includes(filter.toLowerCase()) ||
              data.displayName?.toLowerCase().includes(filter.toLowerCase()) ||
              data.givenName?.toLowerCase().includes(filter.toLowerCase()) ||
              data.surname?.toLowerCase().includes(filter.toLowerCase()) ||
              data.mailNickname?.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            return {
              name: data.displayName,
              key: data.mail,
            };
          });

      } else if (props.qaResource) {
        qaPeoplelistdata = await _sharedService.GetQAPeopleData(filterstring);
        setQAResourceDataList([...qAResourceDataList, ...qaPeoplelistdata]);

        qaPeoplelistdata.map((item) => {
          return suggestionarr.push({
            displayName: item.displayName,
            mail: item.mail,
            mailNickname: item.mailNickname,
            givenName: item.givenName,
            surname: item.surname,
          });
        });

        return suggestionarr
          .filter(
            (data: any) =>
              data.mail?.toLowerCase().includes(filter.toLowerCase()) ||
              data.displayName?.toLowerCase().includes(filter.toLowerCase()) ||
              data.givenName?.toLowerCase().includes(filter.toLowerCase()) ||
              data.surname?.toLowerCase().includes(filter.toLowerCase()) ||
              data.mailNickname?.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            return {
              name: data.displayName,
              key: data.mail,
            };
          });

      } else if (props.author || props.keyContacts || props.contentManager || props.contentApprover || props.contributor) {
        peoplelistdata = await _sharedService.GetPeopleData(filterstring);
        if (props.author) {
          setAuthorDataList([...authorDataList, ...peoplelistdata]);
        } else if (props.keyContacts) {
          setKeyContactsDataList([...keyContactsDataList, ...peoplelistdata]);
        } else if (props.contentManager) {
          setContentManagerDataList([...contentManagerDataList, ...peoplelistdata]);
        } else if (props.contentApprover) {
          setContentApproverDataList([...contentApproverDataList, ...peoplelistdata]);
        } else if (props.contributor) {
          setContributorDataList([...contributorDataList, ...peoplelistdata]);
        }
        peoplelistdata.map((item) => {
          return suggestionarr.push({
            displayName: item.displayName,
            mail: item.userPrincipalName,
            mailNickname: item.mailNickname,
            givenName: item.givenName,
            surname: item.surname,
          });
        });

        return suggestionarr
          .filter(
            (data: any) =>
              data.mail?.toLowerCase().includes(filter.toLowerCase()) ||
              data.displayName?.toLowerCase().includes(filter.toLowerCase()) ||
              data.givenName?.toLowerCase().includes(filter.toLowerCase()) ||
              data.surname?.toLowerCase().includes(filter.toLowerCase()) ||
              data.mailNickname?.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            return {
              name: data.displayName,
              key: data.mail,
            };
          });
      } else if (props.clientName) {
        peoplelistdata = await _sharedService.GetClientData(filterstring);
        var suggestionarr: any[] = [];
        peoplelistdata?.map((item) => {
          return suggestionarr.push({
            disguisedClient: item.disguisedClientName == null ? false : true,
            clientName:
              item.disguisedClientName == null
                ? item.clientName
                : item.disguisedClientName,
            key: item.clientId ? item.clientId : "",
          });
        });
        return suggestionarr
          .filter((data: any) =>
            data.clientName.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            return {
              name: data.clientName,
              key: data.key,
              disguisedClient: data.disguisedClient
            };
          });
      } else if (props.countryOfOrigin) {
        var suggestionarr: any[] = [];
        props.dataList?.map((item) => {
          return suggestionarr.push({ name: item.Name, id: item.Id, level: item.Level });
        });
        return suggestionarr
          .filter((data: any) =>
            data.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            let suffix = data.level === 3 ? "(Member Firm -> Local Market -> Country)" : data.level === 2 ? "(Member Firm -> Local Market)" : "(Member Firm)";
            return {
              name: data.name + " " + suffix,
              key: data.name,
              id: data.id,
            };
          });
      } else if (props.quals) {
        var suggestionarr: any[] = [];
        props.dataList?.map((item) => {
          return suggestionarr.push({ 'name': item.name, 'id': item.id });
        });
        return suggestionarr
          .filter((data: any) =>
            data.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            return {
              name: data.name,
              key: data.name,
              id: data.id,
            };
          });
      } else if (props.contentTags) {
        var suggestionarr: any[] = [];
        props.dataList?.map((item) => {
          return suggestionarr.push({ 'name': item.Name, 'id': item.Id });
        });
        return suggestionarr
          .filter((data: any) =>
            data.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            return {
              name: data.name,
              key: data.id,
              id: data.id,
            };
          });
      } else if (props.Activities) {
        var suggestionarr: any[] = [];
        props.dataList?.map((item) => {
          let data = item.id;
          return suggestionarr.push({
            name: item.id.toString(),
            id: item.id,
          });
        });
        return suggestionarr
          .filter((data: any) =>
            data.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            return {
              name: data.name,
              key: data.name,
              id: data.id,
            };
          });
      } else {
        var suggestionarr: any[] = [];
        props.dataList?.map((item) => {
          return suggestionarr.push(item.title);
        });
        return suggestionarr
          .filter((data: any) =>
            data.toLowerCase().includes(filter.toLowerCase())
          )
          .map((data: any): any => {
            return {
              name: data,
              key: data,
            };
          });
      }
    }
    return [];
  };

  const onChange = (items: ITag[] | undefined) => {
    if (inFocus) {
      setSelectedTagItemsList(items);
      setSelectedAuthorTagsList(items);
      // setClientNames(items);
      // props.clientServices = true;
      // if (props && props.onSelectedItemChange && props.AuthorData) {
      //  setSelectedAuthorTagsList(items);
      // }
      if (props && props.onSelectedItemChange && props.clientServices) {
        if (items?.length == 0) {
          props.onSelectedItemChange(items);
        }
        setTimeout(() => {
          if (props.onSelectedItemChange) props.onSelectedItemChange(items);
        }, 100);
      }
    }

    if(props && props.onSelectedItemChange && props.ContentType){
      props.onSelectedItemChange(items);
    }

    if (props.clientNameNC && props.onSelectedItemChange) {
      if (items?.length == 0) {
        props.onSelectedItemChange(items);
      }
      setTimeout(() => {
        if (props.onSelectedItemChange) props.onSelectedItemChange(items);
      }, 100);
    }

    if (props && props.onSelectedItemChange && props.author) {
      items?.map((item) => {
        var detail = authorDataList?.filter(
          (e: { mail?: string; givenName?: string; surname?: string }) => {
            if (e.mail == item.key) {
              return e;
            }
          }
        );
        if (detail.length > 0) {
          var data = {
            user: {
              firstName: detail[0].givenName ? detail[0].givenName : "",
              lastName: detail[0].surname ? detail[0].surname : "",
              middleName: "",
              email: item.key,
              deloitteId: "",
              empGuid: "",
              displayName: item.name,
              fullName:
                detail[0].givenName && detail[0].surname
                  ? detail[0].givenName + " " + detail[0].surname
                  : "",
              lastModifiedDate: new Date(),
            },
            peopleType: 1,
            isExternalAuthor: false
          };
          authorlist.push(data);
        } else {
          var data1 = {
            user: {
              firstName: "",
              lastName: "",
              middleName: "",
              email: item.key,
              deloitteId: "",
              empGuid: "",
              displayName: item.name,
              fullName: "",
              lastModifiedDate: new Date(),
            },
            peopleType: 1,
            isExternalAuthor: false
          };

          if (item.key === item.name)
            data1.isExternalAuthor = true;

          authorlist.push(data1);
        }
      });
      props.onSelectedItemChange(authorlist);
    }

    if (props && props.onSelectedItemChange && props.keyContacts) {
      items?.map((item) => {
        var detail = keyContactsDataList?.filter(
          (e: { mail?: string; givenName?: string; surname?: string }) => {
            if (e.mail == item.key) {
              return e;
            }
          }
        );
        if (detail.length > 0) {
          var data = {
            user: {
              firstName: detail[0].givenName ? detail[0].givenName : "",
              lastName: detail[0].surname ? detail[0].surname : "",
              middleName: "",
              email: item.key,
              deloitteId: "",
              empGuid: "",
              displayName: item.name,
              fullName:
                detail[0].givenName && detail[0].surname
                  ? detail[0].givenName + " " + detail[0].surname
                  : "",
              lastModifiedDate: new Date(),
            },
            peopleType: 2,
          };
          keyContactslist.push(data);
        } else {
          var data1 = {
            user: {
              firstName: "",
              lastName: "",
              middleName: "",
              email: item.key,
              deloitteId: "",
              empGuid: "",
              displayName: item.name,
              fullName: "",
              lastModifiedDate: new Date(),
            },
            peopleType: 2,
          };
          keyContactslist.push(data1);
        }
      });
      props.onSelectedItemChange(keyContactslist);
    }

    if (props && props.onSelectedItemChange && props.contributor) {
      items?.map((item) => {
        var detail = contributorDataList?.filter(
          (e: { mail?: string; givenName?: string; surname?: string }) => {
            if (e.mail == item.key) {
              return e;
            }
          }
        );
        if (detail.length > 0) {
          var data = {
            user: {
              firstName: detail[0].givenName ? detail[0].givenName : "",
              lastName: detail[0].surname ? detail[0].surname : "",
              middleName: "",
              email: item.key,
              deloitteId: "",
              empGuid: "",
              displayName: item.name,
              fullName:
                detail[0].givenName && detail[0].surname
                  ? detail[0].givenName + " " + detail[0].surname
                  : "",
              lastModifiedDate: new Date(),
            },
            peopleType: 3
          };
          contributorList.push(data);
        } else {
          var data1 = {
            user: {
              firstName: "",
              lastName: "",
              middleName: "",
              email: item.key,
              deloitteId: "",
              empGuid: "",
              displayName: item.name,
              fullName: "",
              lastModifiedDate: new Date(),
            },
            peopleType: 3
          };
          contributorList.push(data1);
        }
      });
      props.onSelectedItemChange(contributorList);
    }

    if (props && props.onSelectedItemChange && props.contentPublisher) {
      console.log("items contentPublisher contentPublisherDataList", items, contentPublisherDataList)
      if (items && items?.length == 0){
        props.onSelectedItemChange(null);
        setSelectedTagItemsList([]);
        console.log('items:', items)
      }
     else if (items && items?.length != 0) {
        var detail = contentPublisherDataList?.filter(
          (e: { mail?: string; givenName?: string; surname?: string }) => {
            if (e.mail == items[0].key) {
              return e;
            }
          }
        );
        if (detail.length > 0) {
          var user = {
            firstName: detail[0].givenName ? detail[0].givenName : "",
            lastName: detail[0].surname ? detail[0].surname : "",
            middleName: "",
            email: items[0].key,
            deloitteId: "",
            empGuid: "",
            displayName: items[0].name,
            fullName:
              detail[0].givenName && detail[0].surname
                ? detail[0].givenName + " " + detail[0].surname
                : "",
            lastModifiedDate: new Date(),
          }
          contentPublisher = user;
        } else {
          var user1 = {
            firstName: "",
            lastName: "",
            middleName: "",
            email: items[0].key,
            deloitteId: "",
            empGuid: "",
            displayName: items[0].name,
            fullName: "",
            lastModifiedDate: new Date(),
          }
          contentPublisher = user1;
        }

        props.onSelectedItemChange(contentPublisher);
        console.log('contentPublisher:', contentPublisher)
      }
    }
    if (props && props.onSelectedItemChange && props.NSTags) {
      props.onSelectedItemChange(items);
    }

    if (props && props.onSelectedItemChange && props.contentTags) {
      props.onSelectedItemChange(items);
    }

    if (props && props.onSelectedItemChange && props.quals) {
      props.onSelectedItemChange(items);
    }

    if (props && props.onSelectedItemChange && props.countryOfOrigin) {
      props.onSelectedItemChange(items);
    }

    if (props.clientName && props.onSelectedItemChange) {
      if (items?.length == 0) {
        props.onSelectedItemChange(items);
      }
      setTimeout(() => {
        if (props.onSelectedItemChange) props.onSelectedItemChange(items);
      }, 100);
    }

    // if (props && props.onSelectedItemChange && props.author) {
    //   items?.map((item) => {
    //     var detail = authorDataList?.filter(
    //       (e: { mail?: string; givenName?: string; surname?: string }) => {
    //         if (e.mail == item.key) {
    //           return e;
    //         }
    //       }
    //     );
    //     if (detail.length > 0) {
    //       var data = {
    //         user: {
    //           firstName: detail[0].givenName ? detail[0].givenName : "",
    //           lastName: detail[0].surname ? detail[0].surname : "",
    //           middleName: "",
    //           email: item.key,
    //           deloitteId: "",
    //           empGuid: "",
    //           displayName: item.name,
    //           fullName:
    //             detail[0].givenName && detail[0].surname
    //               ? detail[0].givenName + " " + detail[0].surname
    //               : "",
    //           lastModifiedDate: new Date(),
    //         },
    //         peopleType: 1,
    //       };
    //       authorlist.push(data);
    //     } else {
    //       var data1 = {
    //         user: {
    //           firstName: "",
    //           lastName: "",
    //           middleName: "",
    //           email: item.key,
    //           deloitteId: "",
    //           empGuid: "",
    //           displayName: item.name,
    //           fullName: "",
    //           lastModifiedDate: new Date(),
    //         },
    //         peopleType: 1,
    //       };
    //       authorlist.push(data1);
    //     }
    //   });
    //   props.onSelectedItemChange(authorlist);
    // }

    // if (props && props.onSelectedItemChange && props.keyContacts) {
    //   items?.map((item) => {
    //     var detail = keyContactsDataList?.filter(
    //       (e: { mail?: string; givenName?: string; surname?: string }) => {
    //         if (e.mail == item.key) {
    //           return e;
    //         }
    //       }
    //     );
    //     if (detail.length > 0) {
    //       var data = {
    //         user: {
    //           firstName: detail[0].givenName ? detail[0].givenName : "",
    //           lastName: detail[0].surname ? detail[0].surname : "",
    //           middleName: "",
    //           email: item.key,
    //           deloitteId: "",
    //           empGuid: "",
    //           displayName: item.name,
    //           fullName:
    //             detail[0].givenName && detail[0].surname
    //               ? detail[0].givenName + " " + detail[0].surname
    //               : "",
    //           lastModifiedDate: new Date(),
    //         },
    //         peopleType: 2,
    //       };
    //       keyContactslist.push(data);
    //     } else {
    //       var data1 = {
    //         user: {
    //           firstName: "",
    //           lastName: "",
    //           middleName: "",
    //           email: item.key,
    //           deloitteId: "",
    //           empGuid: "",
    //           displayName: item.name,
    //           fullName: "",
    //           lastModifiedDate: new Date(),
    //         },
    //         peopleType: 2,
    //       };
    //       keyContactslist.push(data1);
    //     }
    //   });
    //   props.onSelectedItemChange(keyContactslist);
    // }

    // if (props && props.onSelectedItemChange && props.contentPublisher) {
    //   console.log("items contentPublisher contentPublisherDataList", items, contentPublisherDataList)
    //   if (items && items?.length != 0) {
    //     var detail = contentPublisherDataList?.filter(
    //       (e: { mail?: string; givenName?: string; surname?: string }) => {
    //         if (e.mail == items[0].key) {
    //           return e;
    //         }
    //       }
    //     );
    //     if (detail.length > 0) {
    //       var user = {
    //         firstName: detail[0].givenName ? detail[0].givenName : "",
    //         lastName: detail[0].surname ? detail[0].surname : "",
    //         middleName: "",
    //         email: items[0].key,
    //         deloitteId: "",
    //         empGuid: "",
    //         displayName: items[0].name,
    //         fullName:
    //           detail[0].givenName && detail[0].surname
    //             ? detail[0].givenName + " " + detail[0].surname
    //             : "",
    //         lastModifiedDate: new Date(),
    //       }
    //       contentPublisher = user;
    //     } else {
    //       var user1 = {
    //         firstName: "",
    //         lastName: "",
    //         middleName: "",
    //         email: items[0].key,
    //         deloitteId: "",
    //         empGuid: "",
    //         displayName: items[0].name,
    //         fullName: "",
    //         lastModifiedDate: new Date(),
    //       }
    //       contentPublisher = user1;
    //     }

    //     props.onSelectedItemChange(contentPublisher);
    //     console.log('contentPublisher:', contentPublisher)
    //   }
    //   else {
    //     props.onSelectedItemChange(null);
    //   }
    // }

    if (props && props.onSelectedItemChange && props.qaResource) {
      if (items && items?.length != 0) {
        var detail = qAResourceDataList?.filter(
          (e: { mail?: string; givenName?: string; surname?: string }) => {
            if (e.mail == items[0].key) {
              return e;
            }
          }
        );
        if (detail.length > 0) {
          var user = {
            firstName: detail[0].givenName ? detail[0].givenName : "",
            lastName: detail[0].surname ? detail[0].surname : "",
            middleName: "",
            email: items[0].key,
            deloitteId: "",
            empGuid: "",
            displayName: items[0].name,
            fullName:
              detail[0].givenName && detail[0].surname
                ? detail[0].givenName + " " + detail[0].surname
                : "",
            lastModifiedDate: new Date(),
          }
          qaResource = user;
        } else {
          var user1 = {
            firstName: "",
            lastName: "",
            middleName: "",
            email: items[0].key,
            deloitteId: "",
            empGuid: "",
            displayName: items[0].name,
            fullName: "",
            lastModifiedDate: new Date(),
          }
          qaResource = user1;
        }

        props.onSelectedItemChange(qaResource);
        console.log('qaResource:', qaResource)
      }
      else {
        props.onSelectedItemChange(null);
      }
    }

    if (props && props.onSelectedItemChange && props.contentManager) {
      if (items && items?.length != 0) {
        items.map((data: any) => {
          var detail = contentManagerDataList?.filter(
            (e: { mail?: string; givenName?: string; surname?: string }) => {
              if (e.mail == data.key) {
                return e;
              }
            }
          );
          if (detail.length > 0) {
            var user = {
              firstName: detail[0].givenName ? detail[0].givenName : "",
              lastName: detail[0].surname ? detail[0].surname : "",
              middleName: "",
              email: data.key,
              deloitteId: "",
              empGuid: "",
              displayName: data.name,
              fullName:
                detail[0].givenName && detail[0].surname
                  ? detail[0].givenName + " " + detail[0].surname
                  : "",
              lastModifiedDate: new Date(),
              documentId: documentId
            }
            contentManager.push(user);
          } else {
            var user1 = {
              firstName: "",
              lastName: "",
              middleName: "",
              email: data.key,
              deloitteId: "",
              empGuid: "",
              displayName: data.name,
              fullName: "",
              lastModifiedDate: new Date(),
              documentId: documentId
            }
            contentManager.push(user1);
          }
        });
        const uniqueContentManagers = contentManager.reduce((accumulator:any, current:any) => {
          if (!accumulator.find((item:any) => item.email === current.email)) {
            accumulator.push(current);
          }
          return accumulator;
        }, []);
        props.onSelectedItemChange(uniqueContentManagers);
        // console.log('contentManager:', contentManager)

      }
      else {
        props.onSelectedItemChange(null);
      }
    }

    if (props && props.onSelectedItemChange && props.contentApprover) {
      if (items && items?.length != 0) {
        var detail = contentApproverDataList?.filter(
          (e: { mail?: string; givenName?: string; surname?: string }) => {
            if (e.mail == items[0].key) {
              return e;
            }
          }
        );
        if (detail.length > 0) {
          var user = {
            firstName: detail[0].givenName ? detail[0].givenName : "",
            lastName: detail[0].surname ? detail[0].surname : "",
            middleName: "",
            email: items[0].key,
            deloitteId: "",
            empGuid: "",
            displayName: items[0].name,
            fullName:
              detail[0].givenName && detail[0].surname
                ? detail[0].givenName + " " + detail[0].surname
                : "",
            lastModifiedDate: new Date(),
          }
          contentApprover = user;
        } else {
          var user1 = {
            firstName: "",
            lastName: "",
            middleName: "",
            email: items[0].key,
            deloitteId: "",
            empGuid: "",
            displayName: items[0].name,
            fullName: "",
            lastModifiedDate: new Date(),
          }
          contentApprover = user1;
        }


        props.onSelectedItemChange(contentApprover);
        console.log('contentApprover:', contentApprover)
      }
      else {
        props.onSelectedItemChange(null);
      }
    }

    if (props && props.onSelectedItemChange && props.Activities) {
      props.onSelectedItemChange(items);
    }
    // }
    setInFocus(true);
  };

  useEffect(() => {
    if (props.defaultValue) {
      if (
        props &&
        props.onSelectedItemChange &&
        (props.author || props.keyContacts || props.contentPublisher || props.qaResource || props.contentManager || props.contentApprover || props.contributor)
      ) {
        if (props.author && !props.reopenFlag) {
          if(props.addtionalNCExpandCount && props.addtionalNCExpandCount == 1){
          props.defaultValue.map((item) => {
            var data1 = {
              user: {
                firstName: userdetails.kxp_pdiempattributespreffirstname,
                lastName: userdetails.kxp_pdiempattributespreflastname,
                middleName: "",
                email: item.key,
                deloitteId: userdetails?.kxp_accountname,
                empGuid: "",
                displayName: item.name,
                fullName: userdetails.fullName,
                lastModifiedDate: new Date(),
              },
              peopleType: 1,
            };
            // var data1=
            //   {
            //     name: item.name,
            //     key: item.key,
            //   }

            authorlist.push(data1);
          });
          props.onSelectedItemChange(authorlist);
        }
        else if(props.addtionalNCExpandCount && props.addtionalNCExpandCount > 1){
          props.onSelectedItemChange(props.AuthorData);
        }
        }

        if (props.author && props.reopenFlag) {
          props.onSelectedItemChange(props.AuthorData);
        }

        if (props.keyContacts && !props.reopenFlag) {
          if(props.addtionalNCExpandCount && props.addtionalNCExpandCount == 1){
          props.defaultValue.map((item) => {
            var data1 = {
              user: {
                firstName: userdetails.kxp_pdiempattributespreffirstname,
                lastName: userdetails.kxp_pdiempattributespreflastname,
                middleName: "",
                email: item.key,
                deloitteId: userdetails?.kxp_accountname,
                empGuid: "",
                displayName: item.name,
                fullName: userdetails.fullName,
                lastModifiedDate: new Date(),
              },
              peopleType: 2,
            };
            keyContactslist.push(data1);
          });
          props.onSelectedItemChange(keyContactslist);
        }
        else if(props.addtionalNCExpandCount && props.addtionalNCExpandCount > 1){
          props.onSelectedItemChange(props.contactData);
        }
        }
        if (props.keyContacts && props.reopenFlag) {
          props.onSelectedItemChange(props.contactData);
        }

        if (props.contributor && !props.reopenFlag) {
          if(props.addtionalNCExpandCount && props.addtionalNCExpandCount == 1){
          props.defaultValue.map((item) => {
            var data1 = {
              user: {
                firstName: userdetails.kxp_pdiempattributespreffirstname,
                lastName: userdetails.kxp_pdiempattributespreflastname,
                middleName: "",
                email: item.key,
                deloitteId: userdetails?.kxp_accountname,
                empGuid: "",
                displayName: item.name,
                fullName: userdetails.fullName,
                lastModifiedDate: new Date(),
              },
              peopleType: 3,
            };
            contributorList.push(data1);
          });
          props.onSelectedItemChange(contributorList);
        }
        else if(props.addtionalNCExpandCount && props.addtionalNCExpandCount > 1){
          props.onSelectedItemChange(props.contributorData);
        }
      }

        if (props.contributor && props.reopenFlag) {
          props.onSelectedItemChange(props.contributorData);
        }
        if (props.contentPublisher) {
          props.defaultValue.map((item) => {
            var user1 = {
              firstName: props.defaultObj.firstname ? props.defaultObj.firstname : "",
              lastName: props.defaultObj.lastName ? props.defaultObj.lastName : "",
              middleName: props.defaultObj.middleName ? props.defaultObj.middleName : "",
              email: item.key,
              deloitteId: props.defaultObj.deloitteId ? props.defaultObj.deloitteId : "",
              empGuid: props.defaultObj.empGuid ? props.defaultObj.empGuid : "",
              displayName: item.name,
              fullName: props.defaultObj.fullName ? props.defaultObj.fullName : "",
              lastModifiedDate: new Date(),
            };
            contentPublisher = user1;
          });
          props.onSelectedItemChange(contentPublisher);
        }
        if (props.qaResource) {
          props.defaultValue.map((item) => {
            var user1 = {
              firstName: props.defaultObj.firstname ? props.defaultObj.firstname : "",
              lastName: props.defaultObj.lastName ? props.defaultObj.lastName : "",
              middleName: props.defaultObj.middleName ? props.defaultObj.middleName : "",
              email: item.key,
              deloitteId: props.defaultObj.deloitteId ? props.defaultObj.deloitteId : "",
              empGuid: props.defaultObj.empGuid ? props.defaultObj.empGuid : "",
              displayName: item.name,
              fullName: props.defaultObj.fullName ? props.defaultObj.fullName : "",
              lastModifiedDate: new Date(),
            };
            qaResource = user1;
          });
          props.onSelectedItemChange(qaResource);
        }
        if (props.contentManager) {
          props.defaultObj.map((item: any) => {
            // var user1 = {
            //   firstName: props.defaultObj.firstname ? props.defaultObj.firstname : "",
            //   lastName: props.defaultObj.lastName ? props.defaultObj.lastName : "",
            //   middleName: props.defaultObj.middleName ? props.defaultObj.middleName : "",
            //   email: item.key,
            //   deloitteId: props.defaultObj.deloitteId ? props.defaultObj.deloitteId : "",
            //   empGuid: props.defaultObj.empGuid ? props.defaultObj.empGuid : "",
            //   displayName: item.name,
            //   fullName: props.defaultObj.fullName ? props.defaultObj.fullName : "",
            //   lastModifiedDate: new Date(),
            // };
            var user1 = {
              firstName: item.firstname ? item.firstname : "",
              lastName: item.lastName ? item.lastName : "",
              middleName: item.middleName ? item.middleName : "",
              email: item.email,
              deloitteId: item.deloitteId ? item.deloitteId : "",
              empGuid: item.empGuid ? item.empGuid : "",
              displayName: item.name,
              fullName: item.fullName ? item.fullName : "",
              lastModifiedDate: new Date(),
              // name:item.name,
              // key:item.email
            };
            contentManager.push(user1);
          });
          props.onSelectedItemChange(contentManager);
        }
        if (props.contentApprover) {
          props.defaultValue.map((item) => {
            var user1 = {
              firstName: props.defaultObj.firstname ? props.defaultObj.firstname : "",
              lastName: props.defaultObj.lastName ? props.defaultObj.lastName : "",
              middleName: props.defaultObj.middleName ? props.defaultObj.middleName : "",
              email: item.key,
              deloitteId: props.defaultObj.deloitteId ? props.defaultObj.deloitteId : "",
              empGuid: props.defaultObj.empGuid ? props.defaultObj.empGuid : "",
              displayName: item.name,
              fullName: props.defaultObj.fullName ? props.defaultObj.fullName : "",
              lastModifiedDate: new Date(),
            };
            contentApprover = user1;
          });
          props.onSelectedItemChange(contentApprover);
        }
      }
    }
  }, []);


  const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  const createNewItem = (items: ITag[] | undefined) => {
    if (props.clientNameNC) {
      setTimeout(() => {
        if (props.onNewClientAdd) props.onNewClientAdd(items);
      }, 100);
    }
  };

  const returnMostRecentlyUsed = (): ITag[] | Promise<ITag[]> => {
    if (props.dataList && props.dataList.length == 0) {
      setQualsText("No Results");
    } else {
      setQualsText("");
    }
    return props.dataList;
  };

  useEffect(() => {
    //if(props.defaultValue){
    if(props.author || props.contributor || props.keyContacts || props.contentManager || props.contentApprover){
      if(props.addtionalNCExpandCount && props.addtionalNCExpandCount <= 1){
      setSelectedTagItemsList(props.defaultValue)
      }
      else if(props.addtionalNCExpandCount && props.addtionalNCExpandCount > 1)
      {
      setSelectedTagItemsList(props.selectedItems)
      }
    }else{
      setSelectedTagItemsList(props.selectedItems)
    }  
  }, [props.selectedItems,props.defaultValue])

  const onPickerBlur = (ev: any) => {
    if (filterstring.length > 0) {
      setInFocus(false);
    }
  }

  const openExAuthorsModel = () => {
    setIsExAuthorModalOpen(true);
    setExternalAuthorsText("");
  }

  const closeExAuthorModal = () => {
    // props.modalClosing();
    setIsExAuthorModalOpen(false);
    setExternalAuthorsText("");
  }

  const onAddExAuthorModal = () => {
    if (externalAuthorsText && externalAuthorsText.length > 0) {
      let allAuthorsList = selectedAuthorTagsList || authorlist;
      let obj = { name: externalAuthorsText, key: externalAuthorsText };
      allAuthorsList.push(obj);

      listContainsTagList(obj, selectedAuthorTagsList);
      onChange(allAuthorsList);
    }
    closeExAuthorModal();
  }

  const onChangeExternalAuthor = (event: any) => {
    let newValue = event.target.value;
    setExternalAuthorsText(newValue);
  }

  return (
    props.clientNameNC ? <TagPicker
      className={styles.dummy}
      selectedItems={props.selectedItems ? props.selectedItems : undefined}
      removeButtonAriaLabel="Remove"
      componentRef={tagPickerRef}
      itemLimit={props.itemlimit}
      onInputChange={onInputChange}
      onResolveSuggestions={onResolveSuggestions}
      getTextFromItem={getTextFromItem}
      pickerSuggestionsProps={pickerSuggestionsProps}
      inputProps={inputProps}
      onChange={onChange}
      defaultSelectedItems={props.defaultValue}
      onValidateInput={(input: string) => {
        return input ? ValidationState.valid : ValidationState.invalid;
      }}
      createGenericItem={(input: string) => {
        createNewItem([{ key: "", name: input }]);
        return { key: "", name: input } as ITag;
      }}
      onItemSelected={(item?: any | undefined) => {
        if (
          tagPickerRef.current &&
          listContainsTagList(item, selectedTagItemsList)
        ) {
          return null;
        }
       // setSelectedTagItemsList(item);
        return item;
      }}
      disabled={props.disabled ? props.disabled : false}
      onEmptyInputFocus={returnMostRecentlyUsed}
      onBlur={onPickerBlur}
    /> : <>
      <TagPicker
        className={styles.dummy}
        selectedItems={props.selectedItems ? props.selectedItems : undefined}
        removeButtonAriaLabel="Remove"
        componentRef={tagPickerRef}
        itemLimit={props.itemlimit}
        onInputChange={onInputChange}
        onResolveSuggestions={onResolveSuggestions}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        inputProps={inputProps}
        onChange={onChange}
        defaultSelectedItems={props.defaultValue ? props.defaultValue : []}
        onValidateInput={(input: string) => {
          return input ? ValidationState.valid : ValidationState.invalid;
        }}
        onItemSelected={(item?: any | undefined) => {
          if (
            tagPickerRef.current &&
            listContainsTagList(item, selectedTagItemsList)
          ) {
            return null;
          }
         // setSelectedTagItemsList(item);
          return item;
        }}
        disabled={props.disabled ? props.disabled : false}
        onEmptyInputFocus={returnMostRecentlyUsed}
        onBlur={onPickerBlur}
      />
      {props.enableExternalAuthor ? <div style={{ margin: "5px 0 0", display: "inline-block", cursor: "pointer" }}><i className="icon-kx-plus"></i><a onClick={openExAuthorsModel}> {_sharedService.Data.Labels["KXDocuments_NewContribution_ExternalAuthor_Button"]}</a></div> : ""}

      {isExAuthorModalOpen &&
        <Modal isOpen={isExAuthorModalOpen} onDismiss={closeExAuthorModal} isBlocking={false} containerClassName="countryModal">
          <div className="modalHeader">
            <Stack wrap horizontal horizontalAlign="space-between" verticalAlign="baseline" tokens={containerStackTokens} className="w-100">
              <h2 className="heading" id='id'>
              {_sharedService.Data.Labels["KXDocuments_NewContribution_AddExternalAuthor"]}
              </h2>
              <i className="icon-kx-close sm" onClick={closeExAuthorModal}></i>
            </Stack>
          </div>

          <div className="modalBody" style={{ marginTop: "25px" }}>
            <Stack wrap horizontal horizontalAlign="start" verticalAlign="center" tokens={containerStackTokens}>
              <StackItem className="w-100">
                <div className="pickerInput country">
                  <TextField id="ExternalAuthor" className="title w-100" placeholder={_sharedService.Data.Labels["KXDocuments_NewContribution_Placeholder_ExternalAuthor"]} value={externalAuthorsText} onChange={event => onChangeExternalAuthor(event)} />
                </div>
              </StackItem>
            </Stack>
          </div>

          <Stack className="mt-10" style={{ marginTop: "20px" }} wrap horizontal horizontalAlign="end" verticalAlign="center" tokens={containerStackTokens}>
            <StackItem>
              <Stack horizontal horizontalAlign="end">
                <button className="info" onClick={closeExAuthorModal}>Cancel</button>
                <button className="primary ml-10" onClick={onAddExAuthorModal}>Add</button>
              </Stack>
            </StackItem>
          </Stack>
        </Modal>
      }
    </>

  );
};
