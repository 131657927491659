import { menuElementsReducer } from "./menuReducer";
import { cardsDataReducer } from "./cardsDataReducer";
import { personalCollectionReducer } from "./personalCollectionReducer";
import userNeedsModalReducer from "./userNeedsModalReducer";
import { combineReducers } from "redux";
import showRecentReducer from "./searchBoxReducers";
import userNeedsReducer from "./userNeedsReducer";
import refinersReducer from "./refinersReducer";
import searchReducer from "./searchReducer";
import { lastViewedReducer } from "./lastViewedReducer";
import { personalCollectionIndexReducer } from "./personalCollectionIndexReducer";
import { showFeaturedItemsReducer } from "./showFeaturedItemsReducer";
import { showRecomendedForYouReducer } from "./showRecomendedForYouReducer";
import { manageMyInterestsReducer } from "./manageMyInterestsReducer";
import { collectionYouFollowReducer } from "./collectionYouFollowReducer";
import refinersOldReducer from "./refinersOldReducer";
import filtersReducer from "./filtersReducer";
import refinersFiltersReducer from "./refinersFiltersCountReducer";
import hashApplyReducer from "./applyHashReducer";
import applyFiltersReducer from "./applyFiltersReducer";
import { dpnReducer } from "./dpnReducer";
import { searchBoxDataReducer } from "./searchBoxDataReducer";
import dateClearedReducer from "./dateClearedReducer";
import { loadTagsDataReducer } from "./tagsDataReducer";
import { loadGetAllDataReducer } from "./getAllReducer";
import promptResponseReducer from "./promptResponseReducer";
import promptConversationReducer from "./promptConversationReducer";
import promptQuickViewReducer from "./promptQuickViewReducer";

export const rootReducer = combineReducers({
  menu: menuElementsReducer,
  cardsData: cardsDataReducer,
  showRecentReducer: showRecentReducer,
  personalCollectionReducer: personalCollectionReducer,
  userNeedsReducer: userNeedsReducer,
  userNeedsModalReducer: userNeedsModalReducer,
  refinersReducer: refinersReducer,
  refinersOldReducer: refinersOldReducer,
  searchReducer: searchReducer,
  filtersReducer: filtersReducer,
  refinersFiltersReducer: refinersFiltersReducer,
  dateClearedReducer: dateClearedReducer,
  hashApplyReducer: hashApplyReducer,
  applyFiltersReducer: applyFiltersReducer,
  lastViewedReducer: lastViewedReducer,
  personalCollectionIndexReducer: personalCollectionIndexReducer,
  showFeaturedItemsReducer: showFeaturedItemsReducer,
  showRecomendedForYouReducer: showRecomendedForYouReducer,
  manageMyInterestsReducer: manageMyInterestsReducer,
  collectionReducer: collectionYouFollowReducer,
  dpnReducer: dpnReducer,
  searchBoxData: searchBoxDataReducer,
  loadTagsDataReducer: loadTagsDataReducer,
  loadGetAllDataReducer: loadGetAllDataReducer,
  promptResponseReducer: promptResponseReducer,
  promptConversationReducer: promptConversationReducer,
  promptQuickViewReducer: promptQuickViewReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
