import { SEARCH_STATE } from '../types'
import { ISearchState } from '../../models/IShared'

const initialState: ISearchState = {
  renderRefiners: true,
  renderSearch: false,
  searchText: '',
  searchResults: [],
  refiners: [],  
  suggestion: '',
  totalCount: -1,
  categories: [],
  navigation: false
}

export default function searchReducer(state:ISearchState = initialState, action: any): any {
  switch (action.type) {
    case SEARCH_STATE:      
      return {        
        renderRefiners: action.payload.renderRefiners ? action.payload.renderRefiners : state.renderRefiners,
        renderSearch: action.payload.renderSearch ? action.payload.renderSearch : state.renderSearch,
        searchText: action.payload.searchText ? action.payload.searchText : state.searchText,
        searchResults: action.payload.searchResults !== undefined || action.payload.totalCount > -1 ? action.payload.searchResults : state.searchResults,
        suggestion: action.payload.suggestion !== undefined ? action.payload.suggestion : state.suggestion,
        totalCount: action.payload.totalCount !== undefined ? action.payload.totalCount : state.totalCount,
        refiners: action.payload.refiners !== undefined ? action.payload.refiners : state.refiners,
        categories: action.payload.categories !== undefined ? action.payload.categories : state.categories,
        navigation: action.payload.navigation !== undefined ? action.payload.navigation : state.navigation                  
      }

    default:
      return state
  }
}