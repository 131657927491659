import React, { useState, useEffect } from 'react';
import { ScrollbarCustom } from '../scrollbarCustom/scrollbarCustom';
import { Link, Text, Stack, PrimaryButton, OverflowSet, IOverflowSetItemProps } from 'office-ui-fabric-react';
import { SharedService } from '../../../services/SharedService';
import { CommonFunctions } from '../../../helpers/CommonFunctions';
import { Constants } from '../../../helpers/Constants';
import { PersonalCollection } from '../../../models/SharedModels';
import { useHistory } from 'react-router';

export interface ModalImagesProps {
    cardData: any
    metadataQuickView: any;
    indexKey: any;
    onRenderItem(item: any, card: any): any
}

export const ModalImages: React.FunctionComponent<ModalImagesProps> = props => {
    const [bigImage, setBigImage] = useState<any>("");
    const _sharedService = SharedService.GetInstance();

    useEffect(() => {
        let imageURL: string[] = [];
        if (props.cardData.allData.img_fullurl)
            imageURL.push(props.cardData.allData.img_fullurl);
        SharedService.GetInstance().Search.Get.GetImageSet(imageURL).then((simages: any) => {
            let images = JSON.parse(simages);
            if (images && images.length == 1) setBigImage(images[0]);
            else setBigImage(null);
        });
    }, [bigImage]);


    const getTheRelevantValue = (data: string): any => {
        var arr = data.split(";");
        if (arr && arr.length > 1) {
            return arr[1]
        }
        return arr[arr.length - 1];
    };

    return (
        <div className="modal__bodyimages">
            {props.metadataQuickView && props.cardData != null ?
                <>
                    <ScrollbarCustom>
                        <span className="content-tag">{props.cardData.tag[0].tagName}</span>
                        <div className="heading-inner">
                            <span className="image" style={{ backgroundImage: `url("${bigImage}")` }}></span>
                            <h2>{props.cardData.title.text}</h2>
                        </div>
                        {_sharedService.Data.Labels[Constants.LabelKeys.imagesSourceInfo]}
                        <br />
                        {_sharedService.Data.Labels[Constants.LabelKeys.imagesSource]}: <Link href={props.cardData.allData.appurl} target="blank"> {props.cardData.allData.title} </Link>
                        <p>
                            <img className="imageBody" src={bigImage} />
                        </p>
                        <Text block><b>{_sharedService.Data.Labels[Constants.LabelKeys.imagesTypes]}: </b>{props.cardData.allData.img_imagetypes && props.cardData.allData.img_imagetypes.join(', ')}</Text>
                        <Text block><b>{_sharedService.Data.Labels[Constants.LabelKeys.date]}: </b>{props.cardData.allData.created}</Text>
                    </ScrollbarCustom>
                    <div className="modal__bottom-bar">
                        <OverflowSet
                            className="new-ms-OverflowSet"
                            aria-label={_sharedService.Data.Labels[Constants.LabelKeys.ContextualMenuLabel]}
                            role="menubar"
                            key={props.indexKey}
                            items={props.cardData.overflowItemsData[0].overflowItems}
                            onRenderOverflowButton={() => null}
                            onRenderItem={(item) => props.onRenderItem(item, props.cardData)}
                            doNotContainWithinFocusZone={true}
                        />
                    </div>
                </>
                : null}
        </div>
    )
}