import { SharedService } from "./SharedService";
import * as React from 'react';


export class TreeBuilder {
    private apiJson: Node[] | null; //this will be sorted by ExternalId after 'toForest' first init
    private forest: any;
    private isDevEnvironemnt: boolean;
    private tableId?: number;
    private firmId?: number;
    private flatData: any;

    constructor() {
        this.apiJson = null;
        this.forest = null;
        this.isDevEnvironemnt = window.location.href.indexOf("://localhost") != -1;
    }

    public async init(tableId: TableId, firmId: number,flatData?: any,multiple?: boolean, multipleIds?: number[]) {
        this.firmId = firmId;
        this.tableId = tableId;
         let lock = new Promise<Node[] | null>((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            this.apiJson = this.forest = null;
            let _webAPIUrl = SharedService.webUrl;
            if(flatData){
            let t = flatData;
                if (t) {
                    this.apiJson = t;
                    this.apiJson?.sort(function (x,y) {
                        if (x.Name.toLowerCase() < y.Name.toLowerCase()) {return -1;}
                        if (x.Name.toLowerCase() > y.Name.toLowerCase()) {return 1;}
                        return 0;
                    });
                    if(multiple){
                        this.forest = this.toForest(this.apiJson,true);
                        resolve(this.forest);
                    }else{
                        this.forest = this.toForest(this.apiJson);
                        resolve(this.forest);
                    }
                    
                } else {
                    reject();
                }
            }
            // else{
            //     fetch(`${_webAPIUrl}api/taxonomy/GetAll?TableId=${tableId}&MemberFirmId=${firmId}`)
            //     .then((res: any) => {
            //         res.json().then((t: any) => {
            //             if (t) {
            //                 this.apiJson = JSON.parse(t);
            //                 this.apiJson?.sort(function (x,y) {
            //                     if (x.Name.toLowerCase() < y.Name.toLowerCase()) {return -1;}
            //                     if (x.Name.toLowerCase() > y.Name.toLowerCase()) {return 1;}
            //                     return 0;
            //                 });
            //                 if(multiple){
            //                     this.forest = this.toForest(this.apiJson,true);
            //                     resolve(this.forest);
            //                 }else{
            //                     this.forest = this.toForest(this.apiJson);
            //                     resolve(this.forest);
            //                 }
                            
            //             } else {
            //                 reject();
            //             }
            //         })
            //     })
            //     .catch((err) => {
            //         reject(err);
            //     })

            // }

        });
        return lock;
    }

    public toForest(arr?: any,multiple?:boolean,aiTree?:boolean) {
        let map:any = {}, node:any, res:any = [], i:number;
        for (i = 0; i < arr.length; i += 1) {
             map[arr[i].Id] = i;
             arr[i].children = [];
        };
        for (i = 0; i < arr.length; i += 1) {
            node = arr[i];
            if (node.ParentId > 0) {
                if(multiple){
                    let childNode = {'value':node.Id,'label':node.Name,'id':node.Id,...node};
                    arr[map[node.ParentId]].children.push(childNode);
                }else{
                    let childNode;
                    if(aiTree){
                        childNode = {'value':node.Id,'label':<span className="rct-title">{this.transformCapital(node.Name)} <span className="relevancy">{node.Relevancy+'%'}</span></span>,'id':node.Id,...node}
                    }else{
                        childNode = {'value':node.Name,'label':node.Name,'id':node.Id,...node};
                    }
                    if(arr[map[node.ParentId]] && arr[map[node.ParentId]].children){
                        arr[map[node.ParentId]].children.push(childNode);
                    }
                }
                
            }
            else {
                if(multiple){
                    node = {'value':node.Id,'label':node.Name,'id':node.Id,...node}
                    res.push(node);
                }else{
                    if(aiTree){
                        node = {'value':node.Id,'label':<span className="rct-title">{this.transformCapital(node.Name)} <span className="relevancy">{node.Relevancy+'%'}</span></span>,'id':node.Id,...node}
                    }else{
                        node = {'value':node.Name,'label':node.Name,'id':node.Id,...node}
                    }
                    res.push(node);
                }
                
            };
        };
        const result = this.traverseNodes(res);
        return result;
    }

    public deleteNodes(node:any){
        if(node.children.length>0){
            for(var i = 0; i< node.children.length; i++){
                this.deleteNodes(node.children[i]);
            }
        }
        else{
            delete node.children;
        }
    }
    
    
    public traverseNodes(tree:any){
        for(var i=0; i<tree.length; i++)
        {
            this.deleteNodes(tree[i]);
        }
        return tree;
    }

    public transformCapital(name:string){
        let words = name.split(" ");
        for (let i = 0; i < words.length; i++) {
            if(words[i].length > 0){
                words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }
        }
        let joinedWord = words.join(" ");
        return joinedWord
    }
}

export interface Node {
    Id: any;
    ExternalId: any;
    ParentExternalId?: any;
    ParentId?: any;
    Parent	:	any;
    IsDeleted	:	boolean;
    Level?:number;
    Taxonomy_Id?:any;
    Name: any;
    FullPath?: any;
    Nodes?: Node[] | null;
    children?:Node[] | null;
    label?:String;
    value?:String;
    LocationId?:Number;
    Global?:null | any;
    GlobalId?:null | any;
    DeliveryDetailsSection?: null | any;
    id?:Number;
}

export enum TableId {
    INDUSTRY = 1,
    BUSINESS,
    CLIENT_SERVICE,
    COUNTRY_OF_ORIGIN,
    LOCATIONS,
    TOPIC,
    CONCEPT,
    CONTENT,
    CONTENT_TYPE,
}