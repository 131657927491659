import React, { useEffect, useState } from "react";
import BodyBackground from "../../../../../assets/prompt/media/global/bodyBackground.svg";
import "./Prompt.scss";
import AppHeader from "../../components/AppHeader/AppHeader";
import Sidebar from "../../components/Sidebar/Sidebar";
import SearchAiDashboard from "../../components/SearchAiDashboard/SearchAiDashboard";
import Footer from "../../../../shared/footer/footer";
import MenuFoldOutlined from "../../../../../assets/prompt/MenuFoldOutlined.svg";
import MenuUnfoldOutlined from "../../../../../assets/prompt/MenuUnfoldOutlined.svg";
import { AnalyticsService } from "../../../../../services/AnalyticsService";
import { getDataLayerForAnalytics } from "./PromptConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/reducers";
import PageLoader from "../../components/PageLoader/PageLoader";
import { ResponseAnalyticsTrackerProvider } from "../../promptContextAPIs/ResponseAnalyticTrackerContext";
import {
  Stack,
  IStackStyles,
  IStackItemStyles,
} from "@fluentui/react/lib/Stack";
import { DisableResultsOnModifySelectedProvider } from "../../promptContextAPIs/DisableResultsOnModifySelected";
import { SharedService } from "../../../../../services/SharedService";
import { Constants } from "../../../../../helpers/Constants";
import { ToastContainer, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Prompt Component
 *
 * This component is the landing page for Prompt by KX
 */
const Prompt: React.FC = () => {
  const _sharedService = SharedService.GetInstance();
  const [collapsed, setCollapsed] = useState(false);
  const { loadingQuickViewModal } = useSelector(
    (state: RootState) => state.promptQuickViewReducer
  );

  const logoText = JSON.parse(
    _sharedService.Data.Labels[Constants.LabelKeys.KXPrompt_TopNav_Label]
  );

  useEffect(() => {
    //Setting page title to Prompt By KX
    document.title = logoText.join(" ") + " | Knowledge Exchange";
    AnalyticsService.resetPageContext();
    AnalyticsService.trackPromptPageLoad(getDataLayerForAnalytics());
  }, []);

  const toggleCollapseSidebar = () => {
    setCollapsed(!collapsed);
  };

  // Styles definition
  const stackStyles: IStackStyles = {
    root: {
      width: "100%",
    },
  };
  const layoutStyles: IStackStyles = {
    root: {
      background: "transparent !important",
    },
  };
  const headerStyles: IStackItemStyles = {
    root: {
      background: "rgba(0, 0, 0, 1)",
      padding: "0px",
    },
  };
  const mainLayoutStyles: IStackStyles = {
    root: {
      position: "relative",
    },
  };
  const siderStyles: IStackStyles = {
    root: {
      position: "relative",
      height: "calc(100vh - 72px)",
      background: "transparent",
      minWidth: collapsed ? "min-content" : "16vw",
      width: collapsed ? "min-content" : "16vw",
    },
  };
  const contentStyle: React.CSSProperties = {
    height: "calc(100vh - 72px)",
    padding: "30px 24px",
    backgroundColor: "transparent",
  };
  const sidebarToggleStyle: React.CSSProperties = {
    color: "#fff",
    fontSize: "20px",
  };

  return (
    <>
      <div
        className="App"
        style={{
          background: `url(${BodyBackground})`,
          backgroundSize: "cover",
        }}
      >
        <Stack styles={stackStyles}>
          <DisableResultsOnModifySelectedProvider>
            <Stack styles={layoutStyles}>
              <Stack styles={headerStyles}>
                <AppHeader pagetype="homePage" />
              </Stack>
              <Stack styles={mainLayoutStyles} horizontal>
                <ResponseAnalyticsTrackerProvider>
                  <Stack styles={siderStyles}>
                    <Sidebar
                      collapsed={collapsed}
                      toggleCollapseSidebar={toggleCollapseSidebar}
                    />
                  </Stack>
                  <div
                    className={
                      collapsed
                        ? "expand-btn-container"
                        : "collapse-btn-container"
                    }
                  >
                    <div onClick={toggleCollapseSidebar}>
                      {collapsed ? (
                        <img
                          src={MenuFoldOutlined}
                          style={sidebarToggleStyle}
                          alt="uncollapse"
                        />
                      ) : (
                        <img
                          src={MenuUnfoldOutlined}
                          style={sidebarToggleStyle}
                          alt="collapse"
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={
                      collapsed
                        ? { width: "95vw", overflowX: "hidden" }
                        : { width: "84vw", overflowX: "hidden" }
                    }
                  >
                    <div style={contentStyle}>
                      <SearchAiDashboard />
                    </div>
                    <div className="ms-Grid prompt_footerWrapper">
                      <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 prompt_footer">
                          <Footer />
                        </div>
                      </div>
                    </div>
                  </div>
                </ResponseAnalyticsTrackerProvider>
              </Stack>
            </Stack>
          </DisableResultsOnModifySelectedProvider>
        </Stack>
      </div>
      {loadingQuickViewModal && <PageLoader />}
      <ToastContainer
        position="top-center"
        theme="light"
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        closeOnClick={false}
        autoClose={5000}
        limit={1}
      />
    </>
  );
};

/**
 * Export the Promot component as default
 */
export default Prompt;
