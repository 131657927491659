import React, { useState, useEffect } from "react";
import { Constants } from "../../../helpers/Constants";
import { CardSchema } from "../../../models/SharedModels";
import { CompareItem } from "./compareItem";
import { Link, PrimaryButton, Stack } from "office-ui-fabric-react";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { SharedService } from "../../../services/SharedService";
import { ScrollbarCustom } from "../scrollbarCustom/scrollbarCustom";
import { ModalBase } from "../modalBase/modalBase";
import { useCompareContentContext } from "../contextAPIs/comparContentContext";
import "./compareArea.scss";

export const CompareArea: React.FunctionComponent = (props) => {
  const [showComapreArea, setShowComapreArea] = useState<boolean>(false);

  const _sharedService = SharedService.GetInstance();

  const { itemsToCompare } = useCompareContentContext();
  const { setItemsToCompare } = useCompareContentContext();
  const { showMaxItemValidationPopUp } = useCompareContentContext();
  const { setShowMaxItemValidationPopUp } = useCompareContentContext();

  useEffect(() => {
    let getItemsToComareFromLocalStorage = async () => {
      let itemsToCompareLS = await CommonFunctions.getCompareItemsToLocalStorage();

      if (itemsToCompareLS && itemsToCompareLS.length) {
        setItemsToCompare(itemsToCompareLS);
      }
    };
    getItemsToComareFromLocalStorage();
  }, []);

  var compareSelections = () => {
    window.location.href = window.location.origin + Constants.Routing.CompareItems;
  }
  var toggleCompareArea = () => {
    setShowComapreArea(!showComapreArea);
  }
  var removeAllCompareItems = () => {
    localStorage.clear();
    setItemsToCompare([]);
    setShowComapreArea(false);
  }
  var removeCompareItem = (event: any, index:number) => {
    let compareItems = [...itemsToCompare];
    compareItems.splice(index,1);

    _sharedService.AddCompareContentToLocalStorage(compareItems);
    setItemsToCompare(compareItems);
  }

  return ( itemsToCompare && itemsToCompare.length > 0 ?
    <div className="compare-area-container">
        <div className="compare-area-header">
        <span>{_sharedService.Data.Labels[Constants.LabelKeys.ComapreAreaTitle]} | {itemsToCompare.length} {_sharedService.Data.Labels[Constants.LabelKeys.ComapreAreaNumberOfItemsText]}</span>
        <i onClick={toggleCompareArea} className={`${
                  showComapreArea
                    ? "icon-kx-chevron-down compare-area-icon"
                    : "icon-kx-chevron-up compare-area-icon"
                }`}></i>
        </div>
        { showComapreArea && (
          <><div className="compare-area-body">
            {itemsToCompare.length > 0 && itemsToCompare.map((item: CardSchema, index) => (
              <CompareItem removeHandler={removeCompareItem} itemIndex={index} card={item} key={index} />
            ))}
          </div><div className="compare-area-footer">
              <PrimaryButton className='compare-selection-btn' text={_sharedService.Data.Labels[Constants.LabelKeys.ComapreAreaCompareSelectionText]} onClick={() => compareSelections()} disabled={itemsToCompare.length === 0 } />
              <Link className='cancel-compare-btn' onClick={removeAllCompareItems}>{_sharedService.Data.Labels[Constants.LabelKeys.CompareAreaRemoveAllText]}</Link>
            </div></>)
        }
        
    {showMaxItemValidationPopUp ? (
      <ModalBase
      className="userNeedsModal"
      onHideModal={() => {
        setShowMaxItemValidationPopUp(false);
      }}
      button={{
        text: _sharedService.Data.Labels[Constants.LabelKeys.OkLabel],
        function: () => {
          setShowMaxItemValidationPopUp(false);
        },
      }}
      title={_sharedService.Data.Labels[Constants.LabelKeys.CompareContentMaxItemValidationPopUpTitle]}
      isOpen={showMaxItemValidationPopUp}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: _sharedService.Data.Labels[Constants.LabelKeys.CompareContentMaxItemValidationPopUpMessage] 

        }}
      ></span>
    </ModalBase>
    ) : null}
    </div> 
  : null);
};