import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Constants } from "../../../helpers/Constants";
import { SharedService } from "../../../services/SharedService";

const OldUrl: React.FC = () => {
    const _sharedService = SharedService.GetInstance();
    let history = useHistory();
    const [redirectMessage, setRedirectMessage] = useState<string>("")
    let urlParams = window.location.search;
    let hashesValues:any = urlParams.split("=");
    let hashesValues1:any = hashesValues?hashesValues[hashesValues.length - 1].toLowerCase().split('-'):"";
    let labelsKey = SharedService.scopeNotesLabelKey;
    var labelsData: string | null = localStorage.getItem(labelsKey);
    var labels = labelsData != null ? JSON.parse(labelsData) : {};
    useEffect(() => {
        const urlParams:any = window.location.href;
         if( window.location.href.indexOf("view") !==-1){
            if(hashesValues1 ===null ||hashesValues1.indexOf("kmip") ===-1){
                setRedirectMessage(_sharedService.Data.Labels[Constants.LabelKeys.URLRediectDefaultMessage]);
                setTimeout(()=>{
                    //history.push("/");
                    window.open(SharedService.KXHelpUrl,"_self");
                },5000)
            }else{
            setRedirectMessage(_sharedService.Data.Labels[Constants.LabelKeys.URLRedirectViewMessage]);
            setTimeout(()=>{
                history.push(Constants.Routing.displayPageID + hashesValues[1].toUpperCase());
            },5000) 
        }
        }else if(window.location.href.indexOf("contribute") !==-1){
            setRedirectMessage(_sharedService.Data.Labels[Constants.LabelKeys.URLRedirectContributeMessage]);
            setTimeout(()=>{
                history.push(Constants.Routing.NewContribution);
            },5000)
        }else if(window.location.href.indexOf("default") !==-1){
            setRedirectMessage(_sharedService.Data.Labels[Constants.LabelKeys.URLRediectDefaultMessage]);
            setTimeout(()=>{
                // history.push("/");
                window.open(SharedService.KXHelpUrl,"_self");
            },5000)
        }
           

    }, [])
    return (
        <div>
            <div style={{ "padding": "120px", "textAlign": "center", "fontSize": "xx-large", "fontWeight": "bold" }}>
                {redirectMessage}
            </div> 
        </div>
    );
};

export default OldUrl;
