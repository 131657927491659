import React, { useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import ChatSparkle from "../../../../../../../assets/prompt/media/conversation/chatSparkle.svg";
import Warning from "../../../../../../../assets/prompt/media/conversation/warning.svg";
import Like from "../../../../../../../assets/prompt/media/conversation/like.svg";
import LikeActive from "../../../../../../../assets/prompt/media/conversation/likeActive.svg";
import Dislike from "../../../../../../../assets/prompt/media/conversation/dislike.svg";
import DislikeActive from "../../../../../../../assets/prompt/media/conversation/dislikeActive.svg";
import Copy from "../../../../../../../assets/prompt/media/conversation/copy.svg";
import Copied from "../../../../../../../assets/prompt/media/conversation/copied.svg";
import SummaryModifier from "../../../../../../../assets/prompt/media/conversation/summaryModify.svg";
import SummaryModifierActive from "../../../../../../../assets/prompt/media/conversation/summaryModifyActive.svg";
import ActiveBackground from "../../../../../../../assets/prompt/media/conversation/activeBackground.svg";
import ResponseBackground from "../../../../../../../assets/prompt/media/conversation/responseBackground.png";
import { TooltipBase } from "../../../../../../shared/tooltipBase/tooltipBase";
import "./Response.scss";
import MenuOption from "../MenuOption/MenuOption";
import { SharedService } from "../../../../../../../services/SharedService";
import { Constants } from "../../../../../../../helpers/Constants";
import {
  IConversationMessage,
  IPromptFeedbackRequestObj,
} from "../../../../../../../models/KxPromptModels";
import {
  StorageHandler,
  StorageType,
} from "../../../../../../../helpers/StorageHandler";

import {
  addPromptFeedback,
  resetAddPromptFeedbackFailure,
} from "../../../../../../../store/actions/promptResponseAction";
import { RootState } from "../../../../../../../store/reducers";
import { IPromptResultCitationTracker } from "../../../../../../../models/IAnalytics";
import { AnalyticsService } from "../../../../../../../services/AnalyticsService";
import GeneralResponse from "../GeneralResponse/GeneralResponse";
import PeopleResponse from "../PeopleResponse/PeopleResponse";
import { toast } from "react-toastify";

/**
 * Interface for Response component props
 */
interface IResponseProps {
  conversation: IConversationMessage;
  isLastMessage: boolean;
  handleModifyResponse: (modifyOpion: string) => void;
  setScrollOnModifyClick: (isOptionSelected: boolean) => void;
  idx?: number;
}

/**
 * Response Component
 *
 * This component represents a general response in a conversation.
 * It includes various actions like liking, disliking, copying, and modifying the response.
 */
const Response: React.FunctionComponent<IResponseProps> = ({
  conversation,
  isLastMessage,
  handleModifyResponse,
  setScrollOnModifyClick,
  idx,
}) => {
  const dispatch = useDispatch();
  const specialistProfileContainerRef = useRef<HTMLDivElement>(null);
  const [isBadResponseClicked, setIsBadResponseClicked] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState("");
  const [userMail, setUserMail] = useState("");
  const [isResponseModifierHovered, setIsResponseModifierHovered] =
    useState(false);
  const [liked, setLiked] = React.useState({
    isLiked: false,
    isDisliked: false,
  });
  const [isCopied, setIsCopied] = useState(false);
  const { messages } = useSelector(
    (state: RootState) => state.promptConversationReducer
  );

  const { promptResponse, feedbackError } = useSelector(
    (state: RootState) => state.promptResponseReducer
  );

  useEffect(() => {
    const handleCopy = (event: ClipboardEvent): void => {
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      const container = range?.commonAncestorContainer;
      if (selection && selection.rangeCount > 0) {
        let shouldPreventDefault = false;
        for (let i = 0; i < selection.rangeCount; i++) {
          const range = selection.getRangeAt(i);
          const container = range.commonAncestorContainer;
          const isOrContainsResponse =
            container.nodeType === Node.ELEMENT_NODE
              ? (container as Element).classList.contains(
                  "generalResponse_container"
                ) ||
                (container as Element).querySelector(
                  ".generalResponse_container"
                )
              : container.parentElement?.querySelector(
                  ".generalResponse_container"
                );
          if (isOrContainsResponse) {
            shouldPreventDefault = true;
            break;
          }
        }
        if (shouldPreventDefault) {
          event.preventDefault();
          event.clipboardData?.setData("text/plain", "");
        }
      }
    };
    document.addEventListener("copy", handleCopy);
    return () => {
      document.removeEventListener("copy", handleCopy);
    };
  }, []);

  useEffect(() => {
    if (feedbackError) {
      toast.dismiss();
      toast.clearWaitingQueue();
      toast.error(feedbackError);
      dispatch(resetAddPromptFeedbackFailure());
    }
  }, [feedbackError]);

  const _sharedService = SharedService.GetInstance();

  const BAD_RESPONSE_MENU_OPTIONS = JSON.parse(
    _sharedService.Data.Labels[
      Constants.LabelKeys.KXPrompt_BadResponseMenuOptions
    ]
  );

  const RESPONSE_MODIFIER_MENU_LIST = JSON.parse(
    _sharedService.Data.Labels[
      Constants.LabelKeys.KXPrompt_ResponseModifierMenuList
    ]
  );

  /**
   * Handles prompt feedback by constructing a payload and dispatching an action.
   * @param feedbackstatus - Numeric code representing the feedback status.
   * @param feedbacktype - Optional string specifying the type of feedback.
   * @returns void
   */
  const handlePromptFeedback = (
    feedbackstatus: number,
    feedbacktype?: string
  ) => {
    const payload: IPromptFeedbackRequestObj = {
      conversation_id: conversation.conversationId,
      sessionid: conversation.sessionId,
      useremail: userMail,
      feedbackstatus: feedbackstatus,
      feedbacktype: feedbacktype || "",
      feedbackcomments: "",
      successevent: false,
    };

    dispatch(addPromptFeedback(payload));
  };

  useEffect(() => {
    const getUserMail = async () => {
      const response = await StorageHandler.Get(
        Constants.SessionStorageItems.User,
        StorageType.Local
      );
      setUserMail(response.kxp_email || "");
    };
    getUserMail();
  }, []);

  useEffect(() => {
    //Populating feedback data from received conversation data
    setLiked({
      isLiked: Boolean(conversation.feedbackStatus === 1),
      isDisliked: Boolean(conversation.feedbackStatus === 0),
    });
  }, [conversation]);

  // Auto-hide copied message after 2 seconds
  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  /**
   * Handler for like button click
   */
  const likeClickHandler = () => {
    setSelectedFeedbackType("");
    setLiked((prevState) => {
      handlePromptFeedback(!prevState.isLiked ? 1 : -1);
      return {
        isLiked: !prevState.isLiked,
        isDisliked: false,
      };
    });
  };

  /**
   * Handler for dislike button click
   */
  const disLikeClickHandler = () => {
    if (messages.length - 1 === idx) setScrollOnModifyClick(true);
    setLiked((prevState) => {
      prevState.isDisliked && handlePromptFeedback(-1, "");
      !prevState.isDisliked
        ? setIsBadResponseClicked(true)
        : setIsBadResponseClicked(false);
      return {
        isLiked: false,
        isDisliked: !prevState.isDisliked,
      };
    });
  };

  /**
   * function to remove all the html tags
   */

  const formatPlainText = (richText: string) => {
    return richText.replace(/<[^>]*>/g, "");
  };

  /**
   * Handler for copy button click
   */
  const copyButtonHandler = () => {
    if (isCopied) {
      return;
    }
    if (typeof conversation.text === "string") {
      const textContent =
        formatContent(conversation.text as string) +
        "\n\n" +
        (conversation.usageGuidelines.length === 0
          ? _sharedService.Data.Labels[
              Constants.LabelKeys.KXPrompt_ResponseFooter
            ]
          : _sharedService.Data.Labels[
              Constants.LabelKeys.KXPrompt_ResponseFooter
            ] +
            " " +
            _sharedService.Data.Labels[
              Constants.LabelKeys.KXPrompt_ResponseFooterExtension
            ]) +
        conversation.usageGuidelines;

      const htmlContent = textContent.replace(/\n/g, "<br/>");

      const blobPlainText = new Blob([formatPlainText(textContent)], {
        type: "text/plain",
      });
      const blobHTML = new Blob([htmlContent], { type: "text/html" });
      const clipboardItem = new window.ClipboardItem({
        "text/plain": blobPlainText,
        "text/html": blobHTML,
      });
      navigator.clipboard.write([clipboardItem]);
    } else {
      const htmlContent = specialistProfileContainerRef.current?.outerHTML;
      if (htmlContent) {
        const blobHTML = new Blob([htmlContent], { type: "text/html" });
        const clipboardItem = new window.ClipboardItem({
          "text/html": blobHTML,
        });
        navigator.clipboard.write([clipboardItem]);
      }
    }
    toast.dismiss();
    toast.clearWaitingQueue();
    toast.success(
      `${
        _sharedService.Data.Labels[Constants.LabelKeys.KXPrompt_ResponseCopied]
      }`,
      {
        autoClose: 2000,
      }
    );
    setIsCopied(true);
    let feedbackStatus = liked.isLiked ? 1 : liked.isDisliked ? 0 : -1;
    const payload: IPromptFeedbackRequestObj = {
      conversation_id: conversation.conversationId,
      sessionid: conversation.sessionId,
      useremail: userMail,
      feedbackstatus: feedbackStatus,
      feedbacktype: selectedFeedbackType,
      feedbackcomments: "",
      successevent: true,
    };
    dispatch(addPromptFeedback(payload));
  };

  /**
   * Handler for bad response menu option click
   */
  const badResponseOptionClickHandler = (option: string) => {
    setSelectedFeedbackType(option);
    setScrollOnModifyClick(false);
    setIsBadResponseClicked(false);
    handlePromptFeedback(0, option);
  };

  /**
   * Handler for response modifier menu option click
   */
  const responseModifierOptionClickHandler = (option: string) => {
    setScrollOnModifyClick(false);
    setIsResponseModifierHovered(false);
    handleModifyResponse(option);
    // const payload: IPromptKxResultRequestObj = {
    //   conversation_id: conversation.conversationId,
    //   sessionid: conversation.sessionId,
    //   useremail: "debarpal@deloitte.com",
    //   type: "conversation",
    // };
    // dispatch(getKxResults(payload));
  };

  /**
   * Formats the given content by replacing specific patterns with desired HTML tags.
   *
   * @param {string} content - The input content to be formatted.
   * @returns {string | undefined} - The formatted content with replaced patterns and newline characters.
   */
  const formatContent = (content: string) => {
    const replaceBrackets = content?.replace(
      /<cit><a\s+href='([^']+)'>\[(\d+)\]<\/a><\/cit>/g,
      '<cit><a href="$1" target="_blank"}>$2</a></cit>'
    );

    const replaceNextLine = replaceBrackets?.replace(/\n/g, "<br />");
    return replaceNextLine;
  };

  /**
   * Handles the click event on result metrics, specifically designed for anchor elements within a custom container (CIT).
   *
   * @param event - The click event triggered on a div element.
   */
  const handleResultsMetricsClick = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    let element = event.target as HTMLElement;
    if (element.tagName !== "A" && element.parentElement?.tagName === "CIT") {
      const anchorElement = element.parentElement.querySelector("a");
      if (anchorElement) {
        processAnchorElement(anchorElement);
      }
    } else if (element.tagName === "A") {
      processAnchorElement(element);
    }
  };

  /**
   * Processes the given anchor element by extracting a formatted content from its text,
   * searching for a matching result in the prompt response, and tracking analytics.
   *
   * @param anchorElement - The HTML anchor element to be processed.
   */
  const processAnchorElement = (anchorElement: HTMLElement) => {
    const content = anchorElement.textContent;
    const pattern = /^\[(\d+)\]$/;

    if (promptResponse && content) {
      const match = pattern.exec(content);
      const formattedContent = match ? match[1] : content;
      const resultItemObj = promptResponse.kxresults.find(
        (resultItem) => resultItem.citation_no?.toString() === formattedContent
      );
      const position = promptResponse.kxresults.findIndex(
        (resultItem) => resultItem.citation_no?.toString() === formattedContent
      );
      if (resultItemObj) {
        const resultCitationTrackerObj: IPromptResultCitationTracker = {
          action: "View Full Content",
          displayArea: "Response",
          position: (position + 1).toString(),
          title: resultItemObj.title,
          url: resultItemObj.appurl,
          sourceSystem: resultItemObj.source,
          sourceContentType: resultItemObj?.globalcontenttype
            ? resultItemObj?.globalcontenttype[0].name
            : "",
          contentID: resultItemObj.appid,
        };
        AnalyticsService.trackPromptResultCitation(resultCitationTrackerObj);
      }
    }
  };

  /**
   * Render the Response component.
   *
   * @returns {JSX.Element} - The rendered Response component.
   */
  return (
    <div
      className="generalResponse_container"
      style={{ background: `url(${ResponseBackground})` }}
    >
      <img src={ChatSparkle} alt="sparkle-icon" />
      <div className="response_content">
        {typeof conversation.text === "string" ? (
          <GeneralResponse
            text={conversation.text}
            isLastMessage={isLastMessage}
            isClarificationQuestion={
              conversation?.clarification_question || false
            }
            formatContent={formatContent}
            handleResultsMetricsClick={handleResultsMetricsClick}
          />
        ) : (
          <PeopleResponse
            conversation={conversation.text}
            specialistProfileContainerRef={specialistProfileContainerRef}
          />
        )}
        <div className="response_footer flex-centered">
          <div className="restriction flex-centered">
            <img className="res-img" src={Warning} alt="restriction-warning" />
            <div className="response-footer-label">
              <span
                className="pre-ug-text"
                onClick={handleResultsMetricsClick}
                dangerouslySetInnerHTML={{
                  __html:
                    conversation.usageGuidelines.length === 0
                      ? _sharedService.Data.Labels[
                          Constants.LabelKeys.KXPrompt_ResponseFooter
                        ] + conversation.usageGuidelines
                      : _sharedService.Data.Labels[
                          Constants.LabelKeys.KXPrompt_ResponseFooter
                        ] +
                        " " +
                        _sharedService.Data.Labels[
                          Constants.LabelKeys.KXPrompt_ResponseFooterExtension
                        ] +
                        "<span class='usage-guidelines-text'>" +
                        conversation.usageGuidelines +
                        "</span>",
                }}
              />

              {/* <span
                onClick={handleResultsMetricsClick}
                className="usage-guidelines-text"
                dangerouslySetInnerHTML={{
                  __html: conversation.usageGuidelines,
                }}
              /> */}
            </div>
          </div>
          <div className="response_action flex-centered">
            <div
              className="action_btn"
              style={
                liked.isLiked
                  ? {
                      background: `url(${ActiveBackground})`,
                    }
                  : {}
              }
            >
              <TooltipBase
                content={
                  _sharedService.Data.Labels[
                    Constants.LabelKeys.KXPrompt_GoodResponsePopup
                  ]
                }
                wrapperClassName="prompt_tooltip"
              >
                <img
                  src={liked.isLiked ? LikeActive : Like}
                  alt={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_GoodResponsePopup
                    ]
                  }
                  onClick={likeClickHandler}
                  digitaldata-prompt="Tools>Good Response"
                />
              </TooltipBase>
            </div>

            <div
              className="action_btn"
              style={
                liked.isDisliked
                  ? {
                      background: `url(${ActiveBackground})`,
                    }
                  : {}
              }
            >
              <TooltipBase
                content={
                  _sharedService.Data.Labels[
                    Constants.LabelKeys.KXPrompt_BadResponsePopup
                  ]
                }
                wrapperClassName="prompt_tooltip"
              >
                <img
                  src={liked.isDisliked ? DislikeActive : Dislike}
                  alt={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_BadResponsePopup
                    ]
                  }
                  onClick={disLikeClickHandler}
                />
              </TooltipBase>

              {isBadResponseClicked && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setIsBadResponseClicked(false);
                    if (messages.length - 1 === idx)
                      setScrollOnModifyClick(false);
                    setLiked((prevState) => ({
                      ...prevState,
                      isDisliked: false,
                    }));
                  }}
                >
                  <MenuOption
                    menuList={BAD_RESPONSE_MENU_OPTIONS}
                    clickHandler={badResponseOptionClickHandler}
                    analyticsIdentifier={"Bad Response"}
                    className={
                      typeof conversation.text !== "string"
                        ? "badResponse_menuOption"
                        : ""
                    }
                  />
                </OutsideClickHandler>
              )}
            </div>

            <div className="action_btn">
              <TooltipBase
                content={
                  _sharedService.Data.Labels[
                    Constants.LabelKeys.KXPrompt_CopyResponseLabel
                  ]
                }
                wrapperClassName="prompt_tooltip"
              >
                <img
                  src={isCopied ? Copied : Copy}
                  alt={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_CopyResponseLabel
                    ]
                  }
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={copyButtonHandler}
                  digitaldata-prompt="Tools>Copy"
                />
              </TooltipBase>
            </div>

            {typeof conversation.text === "string" && (
              <div className="action_btn">
                <TooltipBase
                  content={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_ModifyResponseLabel
                    ]
                  }
                  wrapperClassName="prompt_tooltip"
                >
                  <img
                    src={
                      isResponseModifierHovered
                        ? SummaryModifierActive
                        : SummaryModifier
                    }
                    alt={
                      _sharedService.Data.Labels[
                        Constants.LabelKeys.KXPrompt_ModifyResponseLabel
                      ]
                    }
                    style={{
                      opacity: messages.length - 1 === idx ? "1" : "0.7",
                      cursor:
                        messages.length - 1 === idx ? "pointer" : "not-allowed",
                      pointerEvents:
                        messages.length - 1 === idx ? "auto" : "none",
                    }}
                    onClick={() => {
                      if (messages.length - 1 === idx) {
                        setIsResponseModifierHovered(true);
                        setScrollOnModifyClick(true);
                      }
                    }}
                  />
                </TooltipBase>

                {isResponseModifierHovered && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setIsResponseModifierHovered(false);
                      if (messages.length - 1 === idx)
                        setScrollOnModifyClick(false);
                    }}
                  >
                    <MenuOption
                      menuList={RESPONSE_MODIFIER_MENU_LIST}
                      clickHandler={responseModifierOptionClickHandler}
                      className="responseModifier_menuOption"
                      analyticsIdentifier={"Modify"}
                    />
                  </OutsideClickHandler>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Export the Response component as default
 */
export default Response;
