import React from "react";
import {
  Dropdown,
  IDropdownOption,
  IDropdown,
} from "office-ui-fabric-react/lib/Dropdown";
import { Label } from "office-ui-fabric-react/lib/Label";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import "./dropdownBase.scss";
import { ISource, ITaxonomy } from "../../../models/IShared";

export interface DropdownProps {
  classSize?: string;
  type?: string;
  label?: string;
  className?: string;
  labelIcon?: string;
  options: ISource[] | IDropdownOption[] | ITaxonomy[];
  selectedKey?: string | null;
  onChange?: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | any,
    index?: number
  ) => void | any;
  onRenderOption?: (option: any) => any;
  modernUi?: boolean;
  isMobile?: boolean;
  onClickHandler?:()=>void;
  onDismissHandler?:()=>void;
  
}

const onRenderLabel = (props: any): JSX.Element => {
  return (
    <div className="label-wrapper d-flex">
      {props.modernUi
        ?
        (props.label && <Label>{props.label}</Label>)
        :
        (
          <>
            {props.labelIcon && <i className={props.labelIcon}></i>}
            {props.label && <Label>{props.label}</Label>}
          </>
        )
      }
    </div>
  );
};

const onRenderOption = (option: any): any => {
  return (
    <div className="options">
      <div>
        <span className="main-text">
          {option.text ? option.text : option.title}
        </span>
        {option.data && option.data.subText && (
          <span className="sub-text">{option.data.subText}</span>
        )}
      </div>
    </div>
  );
};

export const DropdownBase: React.FunctionComponent<DropdownProps> = (props) => {
  const dropdownRef = React.createRef<IDropdown>();
  const onSetFocus = () => dropdownRef.current!.focus(true);

  const dropdownStyles: Partial<any> = {
    root: [
      props.className,
      {
        display: "flex",
        height: "100%",
        alignItems: "center",
      },
    ],
    callout: props.className,
  };

  const onRenderOptionModernUi = (option: any): any => {
    return (
      <div className="options">
        <i className={option.icon}></i>
        <span className="main-text">
          {option.text}
        </span>
      </div>
    );
  }

  const onRenderCaretDown = (): JSX.Element => {
    return props.modernUi ?
      <i className={props.labelIcon}></i> :
      <Icon iconName="CaretDownSolid8" />;
  };

  return (
    <div className={`ms-StackItem ${props.classSize ? props.classSize : ''}`}>
      {props.modernUi && props.isMobile && <p onClick={onSetFocus}>{props.label}</p>}
      <Dropdown
        label={props.modernUi && props.isMobile ? undefined : props.label}
        defaultSelectedKey={
          props.selectedKey
            ? props.selectedKey
            : props.options && props.options[0] && props.options[0].key
              ? props.options && props.options[0].key
              : ""
        }
        onRenderLabel={props.modernUi && props.isMobile ? undefined : () => onRenderLabel(props)}
        onRenderCaretDown={onRenderCaretDown}
        onRenderOption={
          props.onRenderOption ? props.onRenderOption : !props.modernUi ? onRenderOption : onRenderOptionModernUi
        }
        options={props.options}
        onChange={props.onChange}
        styles={dropdownStyles}
        responsiveMode={2}
        calloutProps={{className: props.modernUi ? 'modern-ui-dropdown' : ''}}
        componentRef={dropdownRef}
        onClick={props.onClickHandler ? props.onClickHandler : undefined}
        onDismiss={props.onDismissHandler ? props.onDismissHandler : undefined}
      />
    </div>
  );
};
