import { PrimaryButton } from "@fluentui/react/lib/Button";
import React from "react";
import CloseIcon from "../../../../../assets/prompt/media/sidebar/close.svg";
import TipsIcon from "../../../../../assets/prompt/media/sidebar/tipsCheck.svg";
import TipsBulbIcon from "../../../../../assets/prompt/media/sidebar/tipsbulbicon.svg";
import { Constants } from "../../../../../helpers/Constants";
import { SharedService } from "../../../../../services/SharedService";
import { TipsItem } from "./TipsModalConstants";
import { useId } from "@fluentui/react-hooks";
import { Modal, Stack, IStackTokens } from "@fluentui/react";
import "./TipsModal.scss";

/**
 * Interface for TipsModal component props
 */
interface TipsModalProps {
  showTipsModal: boolean;
  setShowTipsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * TipsModal Component
 *
 * This component represents the Tips Popup of the application.
 * It displays valuable insights about the application.
 */
const TipsModal: React.FC<TipsModalProps> = ({
  showTipsModal,
  setShowTipsModal,
}) => {
  const _sharedService = SharedService.GetInstance();
  const tipsList: TipsItem[] = JSON.parse(
    _sharedService.Data.Labels[Constants.LabelKeys.KXPrompt_TipsList]
  )?.tipsOptions;

  /**
   * Handler to close the modal
   */
  const modalCloseHandler = () => {
    setShowTipsModal(false);
  };
  const titleId = useId("tipsModalTitle");
  const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 8,
    padding: "0 0 6px 0",
  };
  return (
    <Modal
      titleAriaId={titleId}
      isOpen={showTipsModal}
      onDismiss={modalCloseHandler}
      isBlocking={false}
      containerClassName="tips-modal-container"
    >
      <div className="tips-modal-header">
        <div className="header-left">
          <img
            className="tips-modal-heading-icon"
            src={TipsBulbIcon}
            alt="Icon"
          />
          <span className="tips-modal-heading">
            {_sharedService.Data.Labels[Constants.LabelKeys.KXPrompt_TipsLabel]}
          </span>
        </div>
        <div className="header-right">
          <img
            className="close-icon"
            src={CloseIcon}
            alt="Icon"
            onClick={modalCloseHandler}
          />
        </div>
      </div>
      <div className="tips-modal-body">
        <div className="tips-modal-body-header">
          <span className="heading-text">
            {
              _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_TipsQuestion
              ]
            }
          </span>
          <PrimaryButton className="guided-btn" disabled={true}>
            {
              _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_GuidedTourLabel
              ]
            }
          </PrimaryButton>
        </div>
        <div className="tips-modal-content">
          {tipsList?.map((tipsItem, index) => (
            <Stack className="tips-card" key={index}>
              <Stack
                className="tips-header"
                tokens={horizontalGapStackTokens}
                horizontal
              >
                <img className="header-icon" src={TipsIcon} alt="Tips" />
                <span className="heading-text">{tipsItem.label}</span>
              </Stack>
              <span className="description-text">{tipsItem.content}</span>
            </Stack>
          ))}
        </div>
      </div>
    </Modal>
  );
};

/**
 * Export the TipsModal component as default
 */
export default TipsModal;
