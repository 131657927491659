import React, { useState, useEffect } from 'react';
import { PivotItem, Pivot } from 'office-ui-fabric-react/lib/Pivot';
import './pivotBase.scss';
import { Suggestion } from '../../../models/SearchBox';
import { ISuggestion } from '../../../models/IShared';
import { CardBase } from '../cardBase/cardBase';
import CardsWrapper from '../cardsWrapper/cardsWrapper';
import { parse } from 'path';
import { Link } from 'office-ui-fabric-react';
import starIcon from "../../../assets/images/starGreen.svg";

export interface PivotBaseProps {
	pivotData?: any[],
	active: number,
	onClick: (x: any) => undefined
	onHoverEvent: (x: any) => undefined
}

export const PivotBase: React.FunctionComponent<PivotBaseProps> = (props:PivotBaseProps) => {
	const [active, setActive] = useState<string>("");
	const [isPromotedResult, setIsPromotedResult] = useState<boolean>(false);
const hover=(i:number)=>{
	setActive(i.toString());
}

useEffect(()=>{
if(props.active == -1){
	setActive("");
}else{
	setActive(props.active.toString());

}
},[props.active])

useEffect(()=>{

	var elements = document.getElementsByClassName('ms-Pivot-link')
	for (var i = 0; i < elements.length; i++) {
		var index=i.toString()
		elements[i].addEventListener('mouseover',  (event:any)=> {
			var id=event.currentTarget.id;
			hover(id.substring(id.indexOf('Tab')+3));
		})
	}
},[props.pivotData])
useEffect(()=>{
	var elements = document.getElementsByClassName('ms-Pivot-link')
	for (var i = 0; i < elements.length; i++) {
		if(i === parseInt(active)){
			elements[i].className=elements[i].className+" active"
		}else{
			elements[i].className.replace('active','')
		}
	}
	
},[active])
    const _customRenderer = (item:any, i: number) => {
			return (
				<span onClick={() => props.onClick(item)} className={ 'pivot-custom-button' } onMouseOver={()=>props.onHoverEvent(item)}> 
					<span className="link-column">
						<span className="link-title">
						{item.isCuratedCollection && (
							<i className={'icon-kx-ok-circled green-circle-icon'}></i>
						)}
						{ item.Text }
						</span>
						{item.footer && (<span className="link-footer">
							<ul>
								{item.footer.map((item:any, i:any) => {
									return <li key={`key${i}`}>{item}</li>
								})}
							</ul>
						</span>)}
					</span>
					{item.isPromotedResult && (
						<Link className="card-base__tag">
						<img src={starIcon} style={{marginRight:'4px'}} />
						Best Match
						</Link>
					)}
					<span className="link-column icon-column">
						<i className="icon-kx-chevron-right"></i>
					</span>
				</span>
			);
	}
	
    return (
		<Pivot  className="pivot-vertical" selectedKey={active != ""?`key${active}`:null} >
			{props && props.pivotData ? props.pivotData.map((data, i) => {
				return (
					<PivotItem itemKey={`key${i}`} onRenderItemLink={() => _customRenderer(data, i)} tabIndex={i} >
            <CardsWrapper slider={false} cardsData={data.cardData} featured={false}></CardsWrapper>
					</PivotItem>
				);
			}) : null}
		</Pivot>
  );
};

