import * as React from "react";
import "./PromptToolTip.scss";
interface IPromptTooltipProps {
  content: string;
  delay?: number;
  direction?: string;
  wrapperClassName?: string;
}

const PromptTooltip: React.FunctionComponent<IPromptTooltipProps> = (props) => {
  let timeout: any;
  const [active, setActive] = React.useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className={`Tooltip-Wrapper ${props.wrapperClassName}`}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {props.children}
      {active && (
        <div
          className={`Tooltip-Tip ${props.direction || "bottom"} `}
          style={{ display: props.content.trim().length === 0 ? "none" : "" }}
        >
          {props.content}
        </div>
      )}
    </div>
  );
};

export default PromptTooltip;
