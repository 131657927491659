import React, { useState, useEffect } from "react";
import "./pagination.scss";

export interface PaginationProps {
  itemsLength: number;
  onChangePage(
    startIndex: number,        
    isFirstLoad?: boolean,
    isFromHash?: boolean,
    clear?: boolean,
    isPagination?: boolean,
    renderFilters?: boolean,
    isApplied?: boolean,
    currentPage?: number
  ): any;
  pageSize: number;
  currentView?: any;
  totalLines?: number;
  isSearchResult?: boolean;
  startIndex?: number;
  forceCurrentPage?: number;
  totalCount?: number;  
  isCategory?: boolean;
}
export interface PaginationState {
  pager: any;
}
export const Pagination: React.FunctionComponent<PaginationProps> = (props) => {
  
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pages, setPages] = useState<any[]>([]);

  useEffect(() => {
    if (props.isSearchResult) {
      if (props.itemsLength && props.startIndex != undefined) {
        setPage(props.startIndex / props.pageSize + 1, true);
      } else {
        //props.onChangePage(0);
      }
    } else if (props.itemsLength > -1) {
      setPage(props.forceCurrentPage || 1, true);
    }
  }, [props.itemsLength]);

  const setPage = (page: any, fromUseEffect?: boolean) => {
    var itemsLength = props.itemsLength;

    if (page < 1) {
      return;
    }

    var pager = getPager(itemsLength, page);

    if (!fromUseEffect)
      props.onChangePage(pager.startIndex, false, true, undefined, true, true, undefined, page);
  };

  const getPager = (
    totalItems: number,
    currentPageVal: number,
    pageSize?: any
  ) => {
    currentPageVal = currentPageVal || 1;
    pageSize = pageSize || props.pageSize;

    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage: any, endPage: any;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPageVal <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPageVal + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPageVal - 5;
        endPage = currentPageVal + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPageVal - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    var pages: any[] = [];
    for (var i = startPage; i < endPage + 1; i++) {
      pages.push(i);
    }

    setTotalPages(totalPages);
    setCurrentPage(currentPageVal);
    setPages(pages);

    return {
      totalItems: totalItems,
      pageSize: pageSize,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
    };
  };

  return (
    <div className={"paging" + (props.isCategory ? "-category" : "") }>
      {!pages || pages.length <= 1 ? null : (
        <span className="pagination" id="pagination-carousell">
          <span
            className={
              currentPage === 1 ? "disabled move-page-btn" : "move-page-btn"
            }
          >
            <a onClick={() => setPage(currentPage - 1)}>
              <i className="icon-kx-chevron-left arrow-icon"></i>
            </a>
          </span>
          {pages.map((page: any, index: any) => (
            <span
              key={index}
              className={
                currentPage === page
                  ? "active move-page-btn numbers"
                  : "move-page-btn numbers"
              }
            >
              <a onClick={() => setPage(page)}>{page}</a>
            </span>
          ))}
          <span
            className={
              currentPage === totalPages
                ? "disabled move-page-btn"
                : "move-page-btn"
            }
          >
            <a onClick={() =>  currentPage >= totalPages ? false : setPage(currentPage + 1)}>
              <i className="icon-kx-chevron-right arrow-icon"></i>
            </a>
          </span>
        </span>
      )}
    </div>
  );
};
