import { IDropdownOption, IStyle } from "office-ui-fabric-react";
import { CardSchema, ICardSchema, Practice, UserSchema } from "./SharedModels";

export interface IConfig<T> {
  [Key: string]: T;
}

export interface ILabel<T> {
  [Key: string]: T;
}

export interface PromiseWithCancel<T> extends Promise<T> {
  cancel: () => void;
}

export interface ISharedData {
  WebInfo: any; // TODO: remove if not reqiered
  SPPageContextInfo: any; // TODO: remove if not reqiered
  Configuration: IConfig<string>;
  WhatsNewConfiguration: IConfig<string>;
  Labels: ILabel<string>;
  Version: string;
  KXDocsVersion: string;
  CurrentUser: UserSchema;
  CTPMapping: any[];
  CategoryOrder: any;
  Applications: any;
  MsalToken: any;
  SPAuth: any;
  UserRoleGroupsAndPrivileges: any;
}

export interface IKnowledgeItemHeader {
  metadataHeader: IMetaDataHeader;
  helpModal: boolean;
  breadcrumbs: boolean;
  actionButtons: any;
}

export interface IMetaDataCards {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  line5: string;
  topLeftLabel: string;
}
export interface ISearchHistory {
  pageUrl: string;
  searchId: string;
  searchCofig: IFilter[];
}
export interface IMetaDataHeader {
  linkToItem: string;
  ctName: string;
  desc: string;
  image: string;
  abstract: boolean;
  followers: boolean;
  modified: boolean;
}

export interface IActionButton {
  type: ActionButton;
  labelKey: string;
  visible: boolean;
}

export interface IUserNeed extends IDropdownOption {
  id: string;
  title: string;
  subTitle: string;
  mapping: [{ type: string }];
}

export interface IUserNeedDropdownOption extends IDropdownOption {
  key: string;
  text: string;
  data: { subText: string };
  mapping?: [{ type: string }];
}

export interface ISource extends IDropdownOption {
  id: string;
  title: string;
  url: string;
  urlEnd: string;
  order: number;
  target: string;
  data: any;
  text: string;
  key: string;
}

export interface ITaxonomy extends IDropdownOption {
  id: string;
  title: string;
  order: number;
  data: any;
  text: string;
  key: string;
}

export interface IMetaDataCards {
  line1: string;
  line2: string;
  img: string[];
  line3: string;
  line4: string;
  line5: string;
  topLeftLabel: string;
  openNewTab: boolean;
}
export interface IRecentSearch {
  Id: number | undefined;
  Phrase: string;
}

export interface ISuggestion {
  Id: number;
  Text: string;
  Phrase: string;
  RefinerId: number;
  Count: number;
  InternalUrl: string;
  SuggestionType: string;
  SourceType: string;
  Type: string;
  isCuratedCollection: boolean;
  footer: string[];
  cardData: any;
  ImageUrl: string;
  TotalFollowers: string;
  index?: number;
}

export interface IActionButton {
  type: ActionButton;
  labelKey: string;
  visible: boolean;
}

export enum CardType {
  Multi,
  Single,
}

export enum PageList {
  MyKx = "My KX",
  EditForm = "Edit Page",
  DisplayPage = "Display Page",
  NewContribution = "New Contribution",
  PublishingDashboard = "Publishing Dashboard",
  PromptByKX = "Prompt By KX"
}

export enum Events {
  MouseDown = "mousedown",
  TouchStart = "touchstart",
}

export enum NSTags {
  AllianceAndPartnerShip = 1,
  TargetAudience,
  Method,
  Disclaimer,
  Classification,
  DeloitteAssetsAndTools,
  UsageGuidelines,
  MethodDocumentType = 9,
  SystemSource = 8
}

export enum DocumentStatus {
  Draft = 1,
  Contributed,
  Published,
  Assigned,
  InProcess,
  UpdateRequested,
  Archived,
  Unarchived
}

export enum PublishingStatus {
  RequestedforInfo = 1,
  ApprovalRequested,
  ApprovalRecieved,
  PendingInfo,
  ReadytoQA,
  QAinProcess,
  ReworkNeeded,
  OnHold
}

export interface IDefaultRelatedItem {
  title: string;
  subtitle: string;
  image?: string;
}
export interface IRecentActivities {
  person: IPerson;
  action: string;
  dateInDaysAgo: string;
}
export interface IHyperlink {
  title: string;
  hyperlink: string;
}
export interface IPerson {
  name: string;
  linkDpn: string;
}
export interface IRelatedItem {
  type: TypeReletedItem;
  data: IDefaultRelatedItem[] | IRecentActivities[] | IHyperlink[] | string[];
}
export interface MiniCardData {
  Line3?: string;
  Line4?: string;
  Line5?: string;
}
export interface Tag {
  tagName: string;
  tagUrl: string;
}
export interface TitleCard {
  text: string;
  icon: string;
}
export interface OverflowActions {
  key: string;
  name: string;
  iconProps: { className: string };
  onClick?: any;
}
export interface OverflowItemsData {
  items: OverflowActions[];
  overflowItems: OverflowActions[];
}
export interface ISearchData {
  Phrase: string;
  Source: ISource;
  UserNeedId: any;
  isContextual?: boolean;
  userEmail?: string;
  UserGeoFullPath?: string;
  UserClientServiceFullPath?: string;
  language?:string;
}

export enum TypeReletedItem {
  Default,
  RecentActivities,
  Hyperlink,
  RelatedConcept,
}
export enum ActionButton {
  CopyLink,
  Follow,
  Pin,
  QuickView,
}
export interface IDefaultRelatedItem {
  title: string;
  subtitle: string;
  image?: string;
}
export interface IRecentActivities {
  person: IPerson;
  action: string;
  dateInDaysAgo: string;
}
export interface IHyperlink {
  title: string;
  hyperlink: string;
}
export interface IPerson {
  name: string;
  linkDpn: string;
}
export interface IRelatedItem {
  type: TypeReletedItem;
  data: IDefaultRelatedItem[] | IRecentActivities[] | IHyperlink[] | string[];
}
export interface MiniCardData {
  Line3?: string;
  Line4?: string;
  Line5?: string;
}
export interface PicturesGallery {
  FileLeafRef: string;
  Path: string;
  Childrens: PicturesGallery[];
  Type: string;
}
export interface FeaturedItem {
  Title: string;
  dkxImage: UrlSp;
  dkxAbstract: string;
  ID: string;
  dkxVItemLink: UrlSp;
  dkxContentType: string;
  dkxShowBadge: boolean;
}

export interface ResearchToolsItem {
  Title: string;
  dkxAbstract: string;
  dkxImage: UrlSp;
  dkxVItemLink: UrlSp;
  dkxGeography: string;
  dkxNumberID: number;
}

export interface IUserAlertItem {
  ID: number;
  Title: string;
  dkxAlertMessage: string;
  dkxAlertTrigger: Boolean;
}

export interface UrlSp {
  Description: string;
  Url: string;
  TypeId: string;
}
export interface Categories {
  Query: string;
  Open: boolean;
  SeeAllLink: string;
}

export interface IRefiner {
  id: string;
  title: string;
  count?: number | null;
  key: string;
  isOpened?: boolean;
  isChecked?: boolean;
  options?: IRefiner[] | null | undefined;
  fullPath?: string;
  isVisible?: boolean;
}

export interface IFilter {
  key: string;
  title: string;
  tip: string;
  path: string[];
  isVisible: boolean;
  excluded?: boolean;
  isDate?: boolean;
  parent?: string;
  parentId?: string;
}
export interface ICountOfRefinersFilters {
  Industries?: ICountOfRefiner;
  Business?: ICountOfRefiner;
  Geographies?: ICountOfRefiner;
  ContentType?: ICountOfRefiner;
  FileTypes?: ICountOfRefiner;
  Concepts?: ICountOfRefiner;
}

export interface ICountOfRefiner {
  id: string;
  count: number;
}

export interface Category {
  Id: number;
  CategoryName: string;
  Query: string;
  Open: boolean;
  SeeAllLink: string;
  Order: string;
  Items: ICardSchema[];
  Count: number;
}

export interface ISearchState {
  renderRefiners?: boolean | false;
  renderSearch?: boolean | false;
  searchText?: string | "";
  searchResults?: CardSchema[];
  refiners?: IRefiner[];
  suggestion?: string | "";
  totalCount?: number | 0;
  categories?: Category[];
  reSearchFlag?: boolean | false;
  navigation?: boolean | false;
}

export interface IFiltersState {
  filters: IFilter[] | null;
  hasResults: boolean;
}

export interface IApplyHashState {
  fromApply: boolean;
  fromHash: boolean;
}

export interface IApplyFiltersState {
  isApply: boolean;
 }

export interface ISearchResults {
  searchResults: CardSchema[];
  totalCount: number;
  explainData: IExplainData[];
  allStopWords?: boolean | undefined;
}

export interface IGeneralSearch {
  render: boolean | true;
  text: string;
  filters: IFilter[];
  isGlobal: boolean;
  sourceId: number;
  userNeedId?: number | 0;
  from: number | 0;
  size: number | 1000;
  sortOrder: string;
  page?: string;
  pageSection?: string;
  query?: string;
  itemId?: number;
  isSearchCategory?: boolean;
  CategoryId?: number;
  URL?: string;
  UserGeography?: string;
  UserBusiness?: string[];
  userEmail?: string;
  PracticeType?: string;
  CategoryQuery?: string;
  UserClientServiceFullPath?: string;
  UserGeographyFullPath?: string;
  UserIndustryFullPath?: string;
  UserPermissionFlag?: string;
  SPUserToken?: string;
  IsExplain?: boolean;
  SourceFields?: string; // additional fields required can be set here. fields to be space seperated
  fromDate?: string;
  toDate?: string;
  isImages?: boolean;
  imageId?: string;
  language?:string;
}

export interface IExportConfig {
  results: any; 
  userEmail: string;
  fileName: string;
  sortedBy: string;
  collectionURL: string;
  filters: string;
}

export interface IUserData {
  UserId: number;
  UserEmail: string;
  LoginName: string;
  DisplayName: string;
  IsCurrentUserCurator: boolean;
}

export interface IOpener {
  type: string;
  icon: string;
  text: string;
}

export interface IContextMenuItem {
  key: string;
  linkText?: string;
  isUrlInternal?: boolean;
  url?: string;
  target?: string;
  title?: string;
  isDash?: boolean;
  text?: any;
  iconImage?: any;
  className?: string;
  handler?: (...props: any) => void;
}

export interface IContextMenu {
  opener: IOpener[];
  customClass: string;
  doNotLayer: boolean;
  items: IContextMenuItem[];
}

export interface IContentTypeEditMapping {
  Name: string;
  ViewItemUrl: string;
  EditItemUrl: string;
  CanBeEdited: boolean;
  CanBeDeleted: boolean;
}

export interface IPageEditProps {
  editUrl?: string;
  deleteHandler?: () => void;
  pageTitle: string;
}

export interface IDPNNotification {
  empCode: string;
  email: string;
  empLogin: string;
  hasAboutMe: boolean;
  hasAskMeAbout: boolean;
  hasValidResume: boolean;
  resumeDateModified: string;
  mfS1: boolean;
}

export interface IDashNotification {
  pastDue: number;
  aboutToDue: number;
  totalDue: number;
}

export interface IDashActivity {
  activityId: number;
  activityName: string;
  activityOwner: string;
  clientName: string;
  clientId: number;
  globalIndustry: string;
  globalServices: string;
  memberFirm: string;
  description: string;
}

export interface ICategory {
  Id: number;
  Query: string | null;
  CategoryName: string;
  Label: string;
  Open: boolean;
  SeeAllLink: string | null;
  Count: number;
  RefinersCount?: number;
  Order: number;
  Items: any[] | null;
  ItemSpliceFirst?: any[];
  ItemSpliceSecond?: any[];
  ExplainDataFirst?: IExplainData[];
  ExplainDataSecond?: IExplainData[];
  canRender?: boolean;
  show?: boolean;
  LoadingItems?: boolean;
  CTMatch?: any;
  isResults?: boolean;
  isSelected?: boolean;
  //new UI
  ItemSplice1?: any[];
  ItemSplice2?: any[];
  ItemSplice3?: any[];
  ItemSplice4?: any[];
  ItemSplice5?: any[];
  ExplainDataItems?: IExplainData[];
  ExplainData1?: IExplainData[];
  ExplainData2?: IExplainData[];
  ExplainData3?: IExplainData[];
  ExplainData4?: IExplainData[];
  ExplainData5?: IExplainData[];  
}

export interface IUserPermission {
  ListTitle: string;
  CanView: boolean;
  CanEdit: boolean;
  CanDelete: boolean;
}

export interface IContexualSearchBoxStyles {
  root?: IStyle;
  iconContainer?: IStyle;
  icon?: IStyle;
  field?: IStyle;
  clearButton?: IStyle;
}

export interface IPractice {
  Id: number;
  Title: string;
  AccoladesAwards: string;
  ContentType: string;
  Description: string;
  Geography: string;
  GlobalBusiness: string;
  GlobalIndustry: string;
  IndustryLevels: string;
  KeyContacts: string;
  OverviewPresent: string;
  PractcieLevelType: string;
  PracticeName: string;
  PracticeType: string;
  RelatedColl: string;
  RelatedComm: string;
  RelatedItems: string;
  TopBannerImage: string;
  StatDate: string;
  ParcticeHead: string;
  ParcticeRev: string;
  StatUse: string;
}

export interface IPracticeProfileData {
  Level: number;
  Practice: Practice;
  Breadrumbs: any[];
  Geographies: any[] | IGeography[];
  ChildPractices: any[];
  ChildPracticesLabel: string;
  SearchStrings: any[];
  OfferingPortfolio: IPracticeProfileData[];
  OwerviewLabel: string;
  QuickFactsLabel: string;
  unalteredPractice:Practice
}

export interface IGeography {
  ID: number;
  GeographyName: string;
  Path: string;
  ChildGeographies: IGeography[];
}

export interface IDrafts {
  ID: number;
  Title: string;
  _UIVersionString: string;
  dkxIsItemArchived: string;
  Modified: string;
  Editor: string;
}

export interface IPaginate<T> {
  items: T[];
  pagingInfo: string;
}

export interface IGraphPeopleResponse {
  id: string;
  displayName: string;
  userPrincipalName: string;
}

export interface IRelatedPeople {
  value: IGraphPeopleResponse[];
}

export interface IMinicardSearchMetaConfig {
  metadata: {
    enabled: boolean;
    buttonConfig: OverflowActions;
  };
  boostingExplained: {
    enabled: boolean;
    buttonConfig: OverflowActions;
  };
}

export interface IExplainData {
  docId: string;
  data: any;
}

export interface INSTaxonomyData {
  Id: number;
  Name: string;
  IsDeleted: boolean;
}

export interface IAttachmentUploadStatus {
  FileGuidId: string;
  File: File;
}

export interface IAttachementIconsImages {
  AttachementType: string;
  IconImageClass: string;
}

export interface IAttachmentNotication {
  documentId: number;
  documentTitle: string;
  attachmentUploadIsSuccess: boolean;
  documentStatus: number;
}

export interface BadgeProps {
  background?: string;
  className?: string;
  text?: string;
  alt?: string;
  badgeList?: any;
}

export interface Error {
  error: string;
  timestamp: Date;
  userEmail: string;
}

export interface IManageMyInterests {
  Id: number;
  IsDeleted?: boolean;
  Name: string;
  key?: number;
  IncludedInSubscription?: boolean;
}

export interface IFollower {
  Id: number;  
  SourceContentType: string;
  key?: number;
  IncludedInSubscription?: boolean;
}

export interface IContentQueryType {
  Id: number;
  isEnabled: boolean;
}

export interface ISubscription {
  Id?: number;
  Frequency: number;  
  LastSend?: Date;
  ContentQueryTypes: IContentQueryType[];
  ContentTypes: IContentType[];
  isEnabled: boolean;
}

export interface ISubscriptionModel {
  Subscription: ISubscription;
  MyInterests: IManageMyInterests[];
  Followers: IFollower[];
  PracticeAlignmentBusiness: string[],
  PracticeAlignmentIndustry: string[],
  
  AllContentQueryTypes: IContentQueryType[];
  AllFrequencies: any[];
  AllSubscriptionContentTypes: IContentType[];

  SPAppToken: string;
}

export interface IContentType {
  Id: number;
  Label: string;
  isChecked: boolean;
  isEnabled: boolean;
  ContentTypeL1?:any;

  ShowInPracticeCarousel: boolean;
  ShowInFollowedCarousel: boolean;
  ShowInInterestsCarousel: boolean;
}

export interface IContentQueryType {
  Id: number;
  ContentTitle?: string;
  isChecked: boolean;
}

export interface ITagsData {
  createdBy: Date;
  createdDate: Date;
  externalId: number;
  fullPaths: string;
  id: string;
  isDeleted: boolean;
  modifiedBy: string
  modifiedDate: Date;
  name: string;
  parentId: number;
  type: number;
}

export interface IGetAllData {
  ExternalId: number;
  FullPath: string;
  Global: null | string | number;
  GlobalId: number;
  Id: number;
  IsDeleted: boolean;
  LocationId: number;
  Name: string;
  Nodes: null | string | number;
  Parent: null | string | number
  ParentId: number;
}
export interface INSTagData{
  Id: Number,
  Name: string,
  NSId: Number
}

export interface INSTagsData{
  Topics: INSTagData[],
  ConceptTags: INSTagData[],
  ContentTags: INSTagData[]
}

export interface IDigitalDataAttr{
  AttrName:string,
  AttrValue:string
}
export interface hightLightTagsSchema{
  title:string;
  description:string;
}