import React from 'react';
import { Breadcrumb, IBreadcrumbItem, IDividerAsProps } from 'office-ui-fabric-react/lib/Breadcrumb';
import './breadcrumbBase.scss';
import { SharedService } from '../../../services/SharedService';
import { Constants } from '../../../helpers/Constants';

export interface BreadcrumbBaseProps {
    items: IBreadcrumbItem[]
}

export const BreadcrumbBase: React.FC<BreadcrumbBaseProps> = props => {
  return (
    <div>
      <Breadcrumb
        items={props.items}
        maxDisplayedItems={10}
        ariaLabel={SharedService.GetInstance().Data.Labels[Constants.LabelKeys.BreadcrumbItemsLabel]}
        dividerAs={_getCustomDivider}
        overflowAriaLabel={SharedService.GetInstance().Data.Labels[Constants.LabelKeys.MoreLinksLabel]}
        onRenderItem={_onRenderItem}
        className="breadcrumb-default"
      />
    </div>
  );
};

const _onRenderItem = (props:any): JSX.Element => {
    return (
        <div>
            {props.href && (
                <a className="ms-Link ms-Breadcrumb-itemLink" href={props.href}>
                    {props.text}
                </a>
            )}

            {!props.href && (
                <span className="ms-Breadcrumb-item">
                    {props.text}
                </span>
            )}
        </div>
    )
}

function _getCustomDivider(dividerProps: IDividerAsProps): JSX.Element {
  return (
    <span aria-hidden="true" className="breadcrumb-separator">/</span>
  );
}
