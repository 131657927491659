import { IPromptPageTracker } from "../../../../../models/IAnalytics";
import { SharedService } from "../../../../../services/SharedService";

export const RESTRICTED_COUNTRY_LIST = ["China"];
export const CURRENT_COUNTRY = "India";

export const getDataLayerForAnalytics = () => {
  const _sharedService = SharedService.GetInstance();
  let promptPageTracker: IPromptPageTracker = {
    pageInfo: {
      contentTitle: "Prompt by KX",
      genericPageName: "Prompt by KX",
      language: _sharedService.lang,
      primaryCategory: "Prompt by KX",
    },
  };

  return promptPageTracker;
};
