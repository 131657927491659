import React from "react";
import { SharedService } from "../../../services/SharedService";
import { Constants } from "../../../helpers/Constants";
import "./footer.scss";
import LinkGenerator from "../../shared/linkGenerator/linkGenerator";
import { Link } from "react-router-dom";
declare global {
  interface Window {
    FormatStringWithLinks: any;
  }
}

const Footer: React.FC = () => {
  const handleClick = () => {
    return window.open((Constants.Routing.CookiesConsent, '_blank'))
    }
  return (
    <>
      <footer id="footer" className="footer">
        <div className="container">
          <ul className="footer-links">
            <li>
              <LinkGenerator
                linkTemplateText={
                  SharedService.GetInstance().Data.Labels[Constants.LabelKeys.TermsOfUse]
                }
              ></LinkGenerator>
            </li>
            <li>
              <LinkGenerator
                linkTemplateText={
                  SharedService.GetInstance().Data.Labels[Constants.LabelKeys.Privacy]
                }
              ></LinkGenerator>
            </li>
            <li>
              <LinkGenerator
                linkTemplateText={
                  SharedService.GetInstance().Data.Labels[Constants.LabelKeys.ReportConcern]
                }
              ></LinkGenerator>
            </li>
            <li> 
            <a className="optanon-toggle-display">{SharedService.GetInstance().Data.Labels[Constants.LabelKeys.CookieSettings]}</a>
            </li>
          </ul>
          <p>
           {/* © {new Date().getFullYear()}.&nbsp; */}
            <LinkGenerator
              linkTemplateText={
                SharedService.GetInstance().Data.Labels[
                  Constants.LabelKeys.DeloitteDisclaimerLine1
                ]
              }
            ></LinkGenerator>
          </p><br />
          <p>
            <LinkGenerator
              linkTemplateText={
                SharedService.GetInstance().Data.Labels[
                  Constants.LabelKeys.DeloitteDisclaimerLine2
                ]
              }
            ></LinkGenerator>
          </p>          
        </div>
      </footer>
    </>
  );
};

export default Footer;
