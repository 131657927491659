import { SET_CARDS_DATA, CardsDataActionTypes } from '../types'

const initialState = {
  cardsData: []
}

export function cardsDataReducer(
  state = {},
  action: CardsDataActionTypes
): any {
  switch (action.type) {
    case SET_CARDS_DATA:
      return action.payload
    default:
      return state
  }
}