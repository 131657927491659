import React, { lazy, Suspense } from 'react';

const LazyPersonalCollectionPage = lazy(() => import(/* webpackChunkName: "practiceProfileLobbyPage" */'./practiceProfileLobbyPage'));

const PracticeProfileLobbyPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPersonalCollectionPage {...props} />
  </Suspense>
);

export default PracticeProfileLobbyPage;
