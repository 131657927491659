import React, { lazy, Suspense } from 'react';

const LazyHomePage = lazy(() => import(/* webpackChunkName: "homepage" */'./homePage'));

const HomePage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyHomePage {...props} />
  </Suspense>
);

export default HomePage;
