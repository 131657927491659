import React, { useEffect, useState } from "react";
import {
  Text,
  IStackTokens,
} from "@fluentui/react";
import {
  PrimaryButton,
  DateRangeType,
  Dropdown,
  IDropdownOption,
  mergeStyleSets,
  Stack,
  StackItem,
} from "office-ui-fabric-react";

import { Pagination } from "../../shared/pagination/pagination";
import { SharedService } from "../../../services/SharedService";
// import { DatePickerBase } from "../../shared/datePickerBase/datePickerBase";
import { InputTextbox } from "../../shared/inputTextbox/inputTextbox";
import { Constants } from "../../../helpers/Constants";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { CollapseBase } from "../../shared/collapseBase/collapseBase";
import { SideBox } from "../../shared/sideBox/sideBox";
import moment from "moment";
import { defaultDatePickerStrings } from "@fluentui/react-date-time";
import { NewDatePickerBase } from "../../shared/newDatepickerBase/newDatePickerBase";

const AuditLog: React.FC<{ documentId: any, documentStatus: any, publishingStatus: any }> = (props) => {
  const containerStackTokens: IStackTokens = { childrenGap: 10 };
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [showFromDateCalendar, setShowFromDateCalendar] = useState<boolean>(false);
  const [showCalender, setShowCalender] = useState(false);
  const [searchKeywordText, setSearchKeywordsText] = useState<string>("")
  const options: IDropdownOption[] = [
    { key: "to", text: "To" },
    { key: "from", text: "From" },
  ];
  const [more, setMore] = useState<boolean>(false);
  const _sharedService = SharedService.GetInstance();
  const [auditLogShowData, setAuditLogShowData] = useState<any>([]);
  const [actualLogData, setActualAuditLogData] = useState<any>([]);
  const [pageLength, setPageLength] = useState<any>();
  const [prevStartIndex, setPrevStartIndex] = useState<number>(0)
  const [currentView, setCurrentView] = useState(Constants.DisplayView.List);
  const [Paginate, setPaginate] = useState<boolean>(true)
  const [toDate, setToDate] = useState<Date>();
  const [fromDate, setFromDate] = useState<Date>();
  const [showToDateCalendar, setShowToDateCalendar] = useState<boolean>(false);
  const [selectedDocStatus, setSelectedDocStatus] = useState<any>();
  const [selectedPublishingStatus, setSelectedPublishingStatus] = useState<any>();
  const [filteredList, setFilteredList] = useState<any>([]);

  const styles = mergeStyleSets({
    root: { selectors: { '> *': { marginBottom: 15 } } },
    control: { maxWidth: 300, marginBottom: 15 },
  });

  const getResults = (
    startIndex: number,
    isPagination: boolean = false,
    renderFilters: boolean = false,
    isFirstLoad?: boolean,
    isFromHash?: boolean,
    clear?: boolean,
    isApplied?: boolean,
    currentPage?: any,
    view: any = currentView
  ) => {
    let actualData = filteredList.length > 0 ? filteredList : actualLogData;
    let newData: any;
    newData = actualData.slice(startIndex, currentPage * 10);
    setPrevStartIndex(startIndex);
    setAuditLogShowData(newData);
  };

  useEffect(() => {
    getAuditDataForEdit();
    getResults(0, true, false, true, false, false, false, 0, currentView);
  }, []);
  useEffect(() => {
    if (toDate !== undefined && toDate !== null) {
      filterOutItemsBasedOnCriteria();
    }
  }, [toDate])

  const getAuditDataForEdit = async () => {
    const auditLogData = await _sharedService.getDocumentAuditLogData(props.documentId).then((result) => {
      if (Object.keys(result).length === 0) {
      } else if (result !== undefined) {
        let totalItems: any = [];
        result.map((item: any) => {

          let docStatus = Constants.docStatusOptions.find(i => i.key === item.documentStatus);
          let docPublishingStatus = Constants.docPublishingStatus.find(i => i.key === item.publishingStatus);
          item.documentStatus = docStatus ? docStatus.text : "";
          item.publishingStatus = docPublishingStatus ? docPublishingStatus.text : "";
          item.changesJson = JSON.parse(item.changesJson);
          // item.createdDate = defaultDateTimeFormat(item.createdDate);

          let obj = {
            setMore: false,
            auditlog: item
          }
          totalItems.push(obj);
        })
        setAuditLogShowData(totalItems.slice(0, 10));
        setActualAuditLogData(totalItems);
        if (totalItems.length < 10) {
          setPageLength(10);
        } else {
          setPageLength(totalItems.length);
        }
        return result;
      }
    });
  }

  const isValid = (value: any) => {
    if (value == null || value == undefined || value == "") return false;
    return true;
  }

  const getInnerTableList = (event: any, itemIdx: any) => {
    let newArray: any = [];
    let dateLogs = ["Update Requested Date", "Planned Archive Date", "Content Date", "Follow-Up Date", "SME Review Date"];
    let newData = auditLogShowData.map((items: any, idx: number) => {
      if (idx === itemIdx) {
        let updatedAuditLogData = items.auditlog.changesJson.map((newItems: any) => {
          if (dateLogs.includes(newItems.Name)) {
            newItems.NewValue = isValid(newItems.NewValue) ? moment.utc(newItems.NewValue).local().format('MM/DD/YYYY') : "";
            newItems.OldValue = isValid(newItems.OldValue) ? moment.utc(newItems.OldValue).local().format('MM/DD/YYYY') : "";
          } else if (newItems.Name === "Description") {
            newItems.NewValue = CommonFunctions.getTextFromHtml(newItems.NewValue, 225)
            newItems.OldValue = CommonFunctions.getTextFromHtml(newItems.OldValue, 225)
          }
        })
        let newObject = {
          setMore: !items.setMore,
          auditlog: items.auditlog
        }
        newArray.push(newObject);
      } else {
        newArray.push(items)
      }
    })
    setAuditLogShowData(newArray);
  }

  const filterItemsAccordingToPreference = (event: any, option: any) => {
    if (event.target.id === "documentStatus") {
      setSelectedDocStatus(option);
    } else {
      setSelectedPublishingStatus(option);
    }
  }
  useEffect(() => {
    filterOutItemsBasedOnCriteria();
  }, [selectedDocStatus, selectedPublishingStatus])

  const searchKeywordsData = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: any
  ) => {

    setSearchKeywordsText(newValue)
    filterOutItemsBasedOnCriteria();
  };

  const setFromDateState = (value: any) => {
    setFromDate(value);
  };

  const setToDateState = (value: any) => {
    setToDate(value);
  };

  const clearFilters = () => {
    let actualData = actualLogData
    setPageLength(actualLogData.length);
    setAuditLogShowData(actualData.slice(0, 10));
    setFromDate(undefined);
    setToDate(undefined);
    setSelectedDocStatus(undefined);
    setSelectedPublishingStatus(undefined);
    setSearchKeywordsText("");
    setFilteredList([]);
  }

  const filterOutItemsBasedOnCriteria = () => {
    let filterData = actualLogData;
    if (searchKeywordText !== "" || searchKeywordText !== undefined) {
      filterData = filterData.filter((items: any) => ((items.auditlog["createdBy"].toLowerCase()).includes(searchKeywordText.toLowerCase())));
    }
    if (selectedDocStatus !== undefined) {
      filterData = filterData.filter((items: any) => items.auditlog["documentStatus"] === selectedDocStatus.text)
    }
    if (selectedPublishingStatus !== undefined) {
      filterData = filterData.filter((items: any) => items.auditlog["publishingStatus"] === selectedPublishingStatus.text)
    }
    if (fromDate && toDate) {
      filterData = filterData.filter((items: any) =>
        Date.parse(moment(items.auditlog["createdDate"]).format("DD MMMM YYYY")) >= Date.parse(moment(fromDate).format("DD MMMM YYYY")) && Date.parse(moment(items.auditlog["createdDate"]).format("DD MMMM YYYY")) <= Date.parse(moment(toDate).format("DD MMMM YYYY"))
      )
    }
    setFilteredList(filterData);
    setPageLength(filterData.length);
    setAuditLogShowData(filterData.slice(0, 10));
  }

  return (
    <section className="content-section">
      <div className="docadmin">
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <StackItem>
            <Text className="text text--Xl text--Bold text--LightDark">
              Audit Log (Document ID-{props.documentId})
            </Text>
          </StackItem>
        </Stack>
      </div>
      <div className="scrollX p-10 tableinformation">
        <Stack horizontal horizontalAlign="end" className="clearfilter">
          <PrimaryButton text="Clear Filters" onClick={clearFilters} />
        </Stack>
        <div className="ms-Grid-row auditlogfilters">
          <div className="ms-Grid-col">
            {/* <DatePickerBase
              dateRangeType={DateRangeType.Day}
              showGoToToday={true}
              setSelectedDate={setFromDateState}
              setFromShowCalendar={setShowFromDateCalendar}
              setToShowCalendar={setShowToDateCalendar}
              showCalender={showFromDateCalendar}
              selectedDate={fromDate}
              maxDate={toDate}
              label="From"
            ></DatePickerBase>
            <DatePickerBase
              dateRangeType={DateRangeType.Day}
              showGoToToday={true}
              setSelectedDate={setToDateState}
              setFromShowCalendar={setShowFromDateCalendar}
              setToShowCalendar={setShowToDateCalendar}
              showCalender={showToDateCalendar}
              selectedDate={toDate}
              minDate={fromDate}
              label="To"
            ></DatePickerBase> */}
            <NewDatePickerBase
              dateRangeType={DateRangeType.Day}
              showGoToToday={true}
              setSelectedDate={setFromDateState}
              setFromShowCalendar={setShowFromDateCalendar}
              setToShowCalendar={showFromDateCalendar}
              showCalender={showToDateCalendar}
              selectedDate={fromDate}
              maxDate={toDate}
              dateType="MM/DD/YYYY"
              label="From"
              placeHolder="MM/DD/YYYY"
            ></NewDatePickerBase>
          </div>
          <div className="ms-Grid-col">
            <NewDatePickerBase
              dateRangeType={DateRangeType.Day}
              showGoToToday={true}
              setSelectedDate={setToDateState}
              setFromShowCalendar={setShowFromDateCalendar}
              setToShowCalendar={showFromDateCalendar}
              dateType="MM/DD/YYYY"
              showCalender={showToDateCalendar}
              selectedDate={toDate}
              minDate={fromDate}
              label="To"
              placeHolder="MM/DD/YYYY"
            ></NewDatePickerBase>
          </div>
          <div className="ms-Grid-col">
            <InputTextbox
              id="searchKeywords"
              placeholder="Who"
              value={searchKeywordText}
              onChange={searchKeywordsData}
            />
          </div>
          <div className="ms-Grid-col">
            <Dropdown className="formdrop" placeholder="Document Status" id={"documentStatus"}
              options={props.documentStatus !== null ? props.documentStatus : []}
              selectedKey={selectedDocStatus ? selectedDocStatus.key : props.documentStatus}
              onChange={filterItemsAccordingToPreference} />

          </div>
          <div className="ms-Grid-col">
            <Dropdown className="formdrop" placeholder="Publishing Status" id={"publishingStatus"} options={props.publishingStatus !== null ? props.publishingStatus : []} onChange={filterItemsAccordingToPreference}
              selectedKey={selectedPublishingStatus ? selectedPublishingStatus.key : props.publishingStatus}
            />
          </div>
        </div>
        <table>
          <tr>
            <th className="w-30"></th>
            <th>
             {_sharedService.Data.Labels["KXDocuments_AuditLog_Date"]}
            </th>
            <th>
            {_sharedService.Data.Labels["KXDocuments_AuditLog_Who"]}
            </th>
            <th>
            {_sharedService.Data.Labels["KXDocuments_AuditLog_Document Status"]}
            </th>
            <th>
            {_sharedService.Data.Labels["KXDocuments_AuditLog_Publishing Status"]}
            </th>
          </tr>
          {auditLogShowData.length > 0 ? auditLogShowData.map((items: any, idx: number) => {
            return (
              <>
                <tr key={idx}>
                  <td>
                    {!items.setMore ? (
                      <i key={idx}
                        className="icon-kx-add text--Link text--Bold"
                        onClick={(e) => {
                          setMore(true);
                          getInnerTableList(e, idx);
                        }}
                      ></i>
                    ) : (
                      <i key={idx}
                        className="icon-kx-minus text--Link text--Bold"
                        onClick={(e) => {
                          setMore(false);
                          getInnerTableList(e, idx);
                        }}
                      ></i>
                    )}
                  </td>
                  {/* <td className="w-30">
                  <Checkbox />
                </td> */}
                  <td>{moment(items.auditlog.createdDate).format('HH:mm, DD MMMM YYYY')}</td>
                  <td>{items.auditlog.createdBy}</td>
                  <td>{items.auditlog.documentStatus}</td>
                  <td>{items.auditlog.publishingStatus}</td>
                </tr>
                {items.setMore ?
                  <tr className="innerTable">
                    <td colSpan={6}>
                      <table>
                        <tr>
                        <th className="docnewadmin">{_sharedService.Data.Labels["KXDocuments_AuditLog_DocumentAdmin"]}</th>
                        <th className="newdoc">{_sharedService.Data.Labels["KXDocuments_AuditLog_New"]}</th>
                        <th className="newdoc">{_sharedService.Data.Labels["KXDocuments_AuditLog_Old"]}</th>
                        </tr>
                        {items.auditlog.changesJson.map((detailItem: any) => {
                          return (<tr className="resultdoc">
                            <td className="docnewadmin">{detailItem.Name}</td>
                            <td className="newdoc">{detailItem.NewValue} </td>
                            <td className="newdoc">{detailItem.OldValue}</td>
                          </tr>
                          )
                        })}
                      </table>
                    </td>
                  </tr>
                  : ""}
              </>
            )
          }) : ""}
        </table>
      </div>

      <Pagination pageSize={10}
        itemsLength={pageLength !== null ? pageLength : 1}
        onChangePage={getResults}
        currentView={currentView}
        startIndex={prevStartIndex}
        forceCurrentPage={+(CommonFunctions.URLSearch().get(Constants.QueryStringParameters.Page) || 1)} />
    </section>
  );
};

export default AuditLog;

