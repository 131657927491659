import React, { useEffect, useState } from "react";
import { Stack, IStackStyles } from "@fluentui/react/lib/Stack";
import UserProfileDropdown from "../../../../shared/header/components/userProfileDropdown";
import { SharedService } from "../../../../../services/SharedService";
import { MenuElement } from "../../../../../store/types";
import { setMenuElements } from "../../../../../store/actions/menuActions";
import { Constants } from "../../../../../helpers/Constants";
import { DropdownBase } from "../../../../shared/dropdownBase/dropdownBase";
import { CommonFunctions } from "../../../../../helpers/CommonFunctions";
import {
  clearMessage,
  getAllConversationHistory,
  resetSidebarHistoryItemActive,
} from "../../../../../store/actions/promptConversationAction";
import { clearPromptResponse } from "../../../../../store/actions/promptResponseAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/reducers";
import TopicModal from "../TopicModal/TopicModal";
import WarningPopupIcon from "../../../../../assets/prompt/warningpopupicon.svg";
import "./AppHeader.scss";
import { useDisableResultsOnModifySelected } from "../../promptContextAPIs/DisableResultsOnModifySelected";
import { toast } from "react-toastify";

const logoWrapperStyles: IStackStyles = {
  root: {
    columnGap: "4px",
  },
};

type PageType = "homePage" | "restrictedCountryPage";

/**
 * Interface that defines the structure of the header with an optional 'pagetype' property.
 * 'pagetype' represents the type of the page.
 */
interface IAppHeader {
  pagetype?: PageType;
}

/**
 * AppHeader Component
 *
 * This component represents the header of the application.
 * It includes the logo, language selector, and user profile dropdown.
 *
 * @param {PageType} pagetype - Type of the page, either "homePage" or "restrictedCountryPage".
 */
const AppHeader: React.FC<IAppHeader> = ({
  pagetype = "homePage",
}: IAppHeader) => {
  var _sharedService = SharedService.GetInstance();

  // State variables for menu items, dropdown options, and Redux dispatch
  // const [menuItemsDash, setMenuItemsDash] = useState<any>(null);
  // const [menuItemsUser, setMenuItemsUser] = useState<any>(null);
  const [dropdownOptionsLang, setDropdownOptionsLang] = useState<any>([]);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { setDisableResultsOnModifySelected } =
    useDisableResultsOnModifySelected();

  const { messages, conversationHistory, kxResultsFromHistoryLoading } =
    useSelector((state: RootState) => state.promptConversationReducer);

  const logoText = JSON.parse(
    _sharedService.Data.Labels[Constants.LabelKeys.KXPrompt_TopNav_Label]
  );
  const { loadingResponse, loadingDPNUserImage } = useSelector(
    (state: RootState) => state.promptResponseReducer
  );

  /**
   * Function to handle the primary button click associated with closing the warning dialog
   */
  const handlePrimaryButtonClick = () => {
    setIsWarningDialogOpen(false);
  };

  /**
   * Function to handle the Secondary button click associated with the warning dialog
   */
  const handleSecondaryButtonClick = () => {
    if (loadingResponse) {
      return;
    }
    setIsWarningDialogOpen(false);
    toast.dismiss();
    toast.clearWaitingQueue();
    const conversationHistoryObj = conversationHistory.find(
      (history) => history.sessionid === messages[messages.length - 1].sessionId
    );

    conversationHistoryObj?.title &&
      toast.success(
        `${conversationHistoryObj?.title} ${
          _sharedService.Data.Labels[
            Constants.LabelKeys.KXPrompt_CurrentChatHistorySaved
          ]
        }`,
        {
          autoClose: 8000,
        }
      );
    setDisableResultsOnModifySelected(false); // Flag to disable the regenerate response and results checkbox if modify response is selected
    dispatch(clearMessage());
    dispatch(clearPromptResponse());
    dispatch(resetSidebarHistoryItemActive());
    dispatch(getAllConversationHistory());
  };

  useEffect(() => {
    // Fetch configurations and update state variables on component mount
    let _configurations = _sharedService.Data.Configuration;
    var MenuElementsOptions: MenuElement[] = _configurations
      ? JSON.parse(_configurations.MenuElementsOptions)
      : [];
    // var MenuItemsDash: any = _configurations
    //   ? JSON.parse(_configurations.MenuItemsDash)
    //   : null;
    dispatch(setMenuElements(MenuElementsOptions));
    // setMenuItemsDash(MenuItemsDash);
    // var MenuItemsUser: any = _configurations
    //   ? JSON.parse(_configurations.MenuItemsUser)
    //   : null;
    // setMenuItemsUser(MenuItemsUser);

    // Update language dropdown options
    setDropdownOptionsLang([
      {
        key: "EN",
        text: _sharedService.Data.Labels[Constants.LabelKeys.KeyEnglishUS],
      },
      {
        key: "ES",
        text: _sharedService.Data.Labels[Constants.LabelKeys.KeySpanishES],
      },
      {
        key: "FR",
        text: _sharedService.Data.Labels[Constants.LabelKeys.KeyFrenchFR],
      },
      {
        key: "ZH",
        text: _sharedService.Data.Labels[Constants.LabelKeys.KeyChineseZH],
      },
      {
        key: "JA",
        text: _sharedService.Data.Labels[Constants.LabelKeys.KeyJapaneseJA],
      },
    ]);
  }, []);

  /**
   * Click handler for logo.
   * Clears messages and prompt responses.
   */
  const logoClickHandler = () => {
    if (loadingResponse) {
      return;
    }
    if (messages.length > 0) {
      setIsWarningDialogOpen(true);
    } else {
      dispatch(clearMessage());
      dispatch(clearPromptResponse());
      dispatch(getAllConversationHistory());
    }
  };

  /**
   * Render the AppHeader component.
   *
   * @returns {JSX.Element} - The rendered AppHeader component.
   */
  return (
    <div className="nav-bar">
      {/* Logo and Name */}
      <Stack
        horizontal
        className="logo-name"
        styles={logoWrapperStyles}
        onClick={logoClickHandler}
      >
        <span
          className="ms-fontWeight-bold"
          style={{
            color: "#fff",
            fontSize: "20px",
            marginBottom: 0,
            cursor: loadingResponse ? "not-allowed" : "pointer",
          }}
        >
          {logoText[0]}
        </span>
        <span
          style={{
            color: "#fff",
            fontSize: "14px",
            cursor: loadingResponse ? "not-allowed" : "pointer",
          }}
        >
          {logoText[1]}
        </span>
      </Stack>

      {/* Right Section (Language Selector and User Profile Dropdown) */}
      {pagetype === "homePage" && (
        <div className="right-section">
          {/* Language Selector */}
          {messages.length === 0 &&
            !kxResultsFromHistoryLoading &&
            !loadingDPNUserImage && (
              <div
                className="language-selector"
                data-testid="language-selector"
              >
                <div className="hiddenDownTablet d-flex">
                  <DropdownBase
                    selectedKey={
                      CommonFunctions.URLSearch().get(
                        Constants.QueryStringParameters.Lang
                      )
                        ? CommonFunctions.URLSearch().get(
                            Constants.QueryStringParameters.Lang
                          )
                        : _sharedService.lang
                    }
                    options={dropdownOptionsLang}
                    onChange={(e, item) => {
                      SharedService.UpdateLabelsLang(
                        item ? item.key.toString() : "EN"
                      );
                    }}
                    labelIcon="icon-kx-language"
                    className="hiddenDownTablet"
                  />
                </div>
              </div>
            )}

          {/* User Profile Dropdown */}
          <div className="user-image" data-testid="user-dropdown">
            <UserProfileDropdown additionalClass="prompt_userProfile"></UserProfileDropdown>
          </div>
        </div>
      )}
      <TopicModal
        isOpen={isWarningDialogOpen}
        title={
          _sharedService.Data.Labels[
            Constants.LabelKeys.KXPrompt_WarningPopupTitle
          ]
        }
        subText={
          _sharedService.Data.Labels[
            Constants.LabelKeys.KXPrompt_WarningPopupSubtext
          ]
        }
        icon={WarningPopupIcon}
        primaryButtonText={
          _sharedService.Data.Labels[Constants.LabelKeys.KXPrompt_NoButton]
        }
        secondaryButtonText={
          _sharedService.Data.Labels[
            Constants.LabelKeys.KXPrompt_SaveAndProceedButton
          ]
        }
        onPrimaryButtonClick={handlePrimaryButtonClick}
        onSecondaryButtonClick={handleSecondaryButtonClick}
      />
    </div>
  );
};

/**
 * Export the AppHeader component as default
 */
export default AppHeader;
