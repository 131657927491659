import { SET_CARDS_DATA, CardsDataActionTypes } from "../types";
import { CardData } from "../../models/SharedModels";

// TypeScript infers that this function is returning SendMessageAction
export function setCardsData(cardsData: CardData[]): CardsDataActionTypes {
  return {
    type: SET_CARDS_DATA,
    payload: cardsData
  }
}

