import React, { useState, useEffect } from "react";
import { SharedService } from "../../../../services/SharedService";
import { ContextMenu } from "../../contextMenu/contextMenu";
import { isEmpty } from "lodash";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Link as TextLink } from "office-ui-fabric-react/lib/Link";
import { Constants } from "../../../../helpers/Constants";
import LinkGenerator from "../../linkGenerator/linkGenerator";
import {
  IAttachmentNotication,
  IContextMenuItem,
  IDashNotification,
  IDPNNotification,
  IOpener,
} from "../../../../models/IShared";
import { useAttachementNotificationContext } from "../../contextAPIs/AttachmentNotificationContext";
import { useHistory } from "react-router-dom";


interface INotificationsProps {
  className: string;
}

interface INotificationsState {
  items: IContextMenuItem[];
  count: number;
}

const notificationsOpener: IOpener = {
  type: "icon",
  icon: "icon-kx-bell icon-kx-circle",
  text: "",
};

const Notifications: React.FC<INotificationsProps> = (props) => {
  let history = useHistory();
  let sdService = SharedService.GetInstance();
  const [notifications, setNotificationsState] = useState<INotificationsState>({
    items: [],
    count: 0,
  });

  const linkPlaceholder = "**LINK**";
  const { attachmentNotification, setattachmentNotification } =
    useAttachementNotificationContext();
  const [ dpnNotification, setdpnNotification ] = useState<any>()
  // const [attachmentNotificationCount,setAttachmentNotificationCount] = useState<number>(0);
  // const [dpnNotificationCount,setDpnNotificationCount] =  useState<number>(0);
  const notificationLabels = {
    Task: sdService.Data.Labels[Constants.LabelKeys.Task],
    Tasks: sdService.Data.Labels[Constants.LabelKeys.Tasks],
    AboutMe: sdService.Data.Labels[Constants.LabelKeys.AboutMeNotification],
    AskMe: sdService.Data.Labels[Constants.LabelKeys.AskMeNotification],
    Resume: sdService.Data.Labels[Constants.LabelKeys.ResumeNotification],
    AboutTo: sdService.Data.Labels[Constants.LabelKeys.AboutToNotification],
    PastDue: sdService.Data.Labels[Constants.LabelKeys.PastDueNotification],
    DPNHeader: sdService.Data.Labels[Constants.LabelKeys.DPNNotificationHeader],
    VisitKXDash: sdService.Data.Labels[Constants.LabelKeys.VisitKXDash],
    NoNotifications:
      sdService.Data.Labels[Constants.LabelKeys.NoNotificationsText],
    AttachmentUploadSuccess: "Upload Successful",
    AttachmentUploadFailed: "Upload Failed",
    VisitYourKXContributions: "Visit your KX Contributions",
  };

  let getTasksNotificationText = (taskCount?: number): string => {
    let output = "";
    if (taskCount && taskCount > 0) {
      if (taskCount > 1000) {
        output = `> 1000 ${notificationLabels.Tasks}`;
      } else if (taskCount === 1) {
        output = `${taskCount} ${notificationLabels.Task}`;
      } else {
        output = `${taskCount} ${notificationLabels.Tasks}`;
      }
    }
    return output;
  };

  /** Validates resume date. Returns 0 if between 11 & 12 months. -1 if greater than 12 months. 1 if less than 11 months. */
  let validateResumeDate = (date: string): number => {
    if (date) {
      let dateToCheck = new Date(date);
      let date11MonthsOld = new Date(),
        date12MonthsOld = new Date();

      date11MonthsOld.setMonth(date11MonthsOld.getMonth() - 11);
      date12MonthsOld.setMonth(date12MonthsOld.getMonth() - 12);

      if (dateToCheck >= date12MonthsOld && dateToCheck <= date11MonthsOld) {
        return 0;
      } else if (dateToCheck > date11MonthsOld) {
        return 1;
      }
    }
    return -1;
  };

  let removeNotification = async (
    documentId: number,
    menuItems: IContextMenuItem[],
    totalnotifications: number
  ) => {
    setNotificationsState({
      items: menuItems.filter((item) => item.key != "key4" + documentId),
      count: totalnotifications - 1,
    });

    await sdService.RemoveAttachmentNotification(documentId);
    let updatedNotifications = await sdService.GetAttachmentNotifications();
    setattachmentNotification(updatedNotifications);
  };

  useEffect(() => {
    let userEmail:string = sdService.Data.CurrentUser.kxp_email;
    let getNotifications = async () => {
      
      let dashItems: IDashNotification =
        await sdService.Notifications.Get.GetNotificationDash();
      let documentItem: IAttachmentNotication[] = await attachmentNotification;

      let menuItems: IContextMenuItem[] = [];
      let totalNotifications = 0;
      //Document Attachment notificaiton
      if (!isEmpty(documentItem)) {
        documentItem.map((item, i) => {
          totalNotifications += 1;
          return (menuItems = [
            ...menuItems,
            {
              key: "key4" + item.documentId,
              iconImage: item.attachmentUploadIsSuccess
              ? require("../../../../assets/images/green-check.svg").default
              : require("../../../../assets/images/red-icon.svg").default,
              title: item.attachmentUploadIsSuccess ? "Attachment upload successful" : "Some attachment upload failed",
              text: [
                <Text block key="textkey12">
                  <Text>{item.documentTitle}</Text>
                  {/* {
                    item.documentStatus == 1
                      ?
                      <TextLink>{item.documentTitle}</TextLink>
                      :
                      <TextLink onClick={() => window.open((Constants.Routing.EditForm), '_blank')}
                        href={Constants.Routing.EditForm}>
                        {item.documentTitle}
                      </TextLink>
                  } */}
                </Text>,
                <Text block key="textkey12">
                  <TextLink
                    onClick={() => window.open((Constants.Routing.myKxContribution), '_blank')}
                    href={Constants.Routing.myKxContribution}
                  >
                    {documentItem.length - 1 === i
                      ? notificationLabels.VisitYourKXContributions
                      : ""}
                  </TextLink>
                </Text>,
                <Text block key="textkey12" className="closenote">
                  <Text
                    onClick={() =>
                      removeNotification(
                        item.documentId,
                        menuItems,
                        totalNotifications
                      )
                    }
                  >
                    <i className="icon-kx-close"></i>
                  </Text>
                </Text>,
              ],
              // url: Constants.Routing.myKxContribution,
              isUrlInternal: false,
              isDash: true,
              className: "dash-item",
            },
          ]);
        });
      }

      // KXDash Notifications
      if (!isEmpty(dashItems)) {
        if (dashItems.pastDue && dashItems.pastDue > 0) {
          totalNotifications += dashItems.pastDue;
          menuItems = [
            ...menuItems,
            {
              key: "key1",
              iconImage: require("../../../../assets/images/red-icon.svg").default,
              title: getTasksNotificationText(dashItems.pastDue),
              text: [
                <Text block key="textkey01">
                  {notificationLabels.PastDue}
                </Text>,
                dashItems.aboutToDue && dashItems.aboutToDue > 0 ? null : (
                  <Text block key="textkey18">
                    <TextLink onClick={() => {}} href={SharedService.kxDashUrl}>
                      {notificationLabels.VisitKXDash}
                    </TextLink>
                  </Text>
                ),
              ],
              url: SharedService.kxDashUrl,
              isUrlInternal: false,
              isDash: true,
              className: "dash-item",
            },
          ];
        }

        if (dashItems.aboutToDue && dashItems.aboutToDue > 0) {
          totalNotifications += dashItems.aboutToDue;
          menuItems = [
            ...menuItems,
            {
              key: "key2",
              iconImage: require("../../../../assets/images/alarm-clock.svg").default,
              title: getTasksNotificationText(dashItems.aboutToDue),
              text: [
                <Text block key="textkey11">
                  {notificationLabels.AboutTo}
                </Text>,
                <Text block key="textkey12">
                  <TextLink onClick={() => {}} href={SharedService.kxDashUrl}>
                    {notificationLabels.VisitKXDash}
                  </TextLink>
                </Text>,
              ],
              url: SharedService.kxDashUrl,
              isUrlInternal: false,
              isDash: true,
              className: "dash-item",
            },
          ];
        }
      }
      
      let dpnItems:any;
        sdService.Notifications.Get.GetNotificationDpn(userEmail).then((response)=>{
          if(response){
            dpnItems = response
            setdpnNotification(response)
          }
        })
        
      menuItems.forEach((item) => {
        if (item.text) {
          item.text = item.text.filter(Boolean);
        }
      });

      
      if(totalNotifications > 0){
        setNotificationsState({
          items: menuItems,
          count: totalNotifications,
        });
      }
      setNoNotification(totalNotifications, menuItems);
    };
    getNotifications();
  }, [attachmentNotification]);

  useEffect(()=>{
    let dpnItems = dpnNotification
    let menuItems = notifications.items
    let totalNotifications = notifications.count
    if (
      !isEmpty(dpnItems) &&
      (dpnItems.hasAboutMe === false ||
        dpnItems.hasAskMeAbout === false ||
        dpnItems.hasValidResume === false)
    ) {
      let resumeDateValidationFlag = validateResumeDate(
        dpnItems.resumeDateModified
      );

      [
        dpnItems.hasAboutMe,
        dpnItems.hasAskMeAbout,
        dpnItems.hasValidResume,
      ].forEach((item) => {
        if (!item) {
          totalNotifications++;
        }
      });
      menuItems = [
        ...menuItems,
        {
          key: "key3",
          iconImage:
            dpnItems.hasAboutMe === true &&
              dpnItems.hasAskMeAbout === true &&
              (isEmpty(dpnItems.resumeDateModified) ||
                resumeDateValidationFlag === 0)
              ? require("../../../../assets/images/alarm-clock.svg").default
              : require("../../../../assets/images/red-icon.svg").default,
          title: "",
          text: [
            <Text block key="textkey21">
              {notificationLabels.DPNHeader}
            </Text>,
            !dpnItems.hasAboutMe && (
              <Text block key="textkey22">
                <LinkGenerator
                  linkTemplateText={notificationLabels.AboutMe.replace(
                    linkPlaceholder,
                    `${SharedService.dpnUrl}/${sdService.Data.CurrentUser.kxp_accountname}`
                  )}
                ></LinkGenerator>
              </Text>
            ),
            !dpnItems.hasAskMeAbout && (
              <Text block key="textkey23">
                <LinkGenerator
                  linkTemplateText={notificationLabels.AskMe.replace(
                    linkPlaceholder,
                    `${SharedService.dpnUrl}/${sdService.Data.CurrentUser.kxp_accountname}`
                  )}
                ></LinkGenerator>
              </Text>
            ),
            !dpnItems.hasValidResume && (
              <Text block key="textkey24">
                <LinkGenerator
                  linkTemplateText={notificationLabels.Resume.replace(
                    linkPlaceholder,
                    `${SharedService.dpnUrl}/${sdService.Data.CurrentUser.kxp_accountname}`
                  )}
                ></LinkGenerator>
              </Text>
            ),
            !isEmpty(dpnItems.resumeDateModified) &&
            resumeDateValidationFlag === 0 && (
              <Text block key="textkey23">
                {notificationLabels.AboutTo}
              </Text>
            ),
          ],
          url: ``,
          isUrlInternal: false,
        },
      ];
    }

    menuItems.forEach((item) => {
      if (item.text) {
        item.text = item.text.filter(Boolean);
      }
    });

    if(totalNotifications > 0){
      setNotificationsState({
        items: menuItems,
        count: totalNotifications,
      });
      setNoNotification(totalNotifications, menuItems);
    }
  },[dpnNotification])

  const setNoNotification = (totalNotifications : number, menuItems1: IContextMenuItem[]) => {
    if(totalNotifications == 0){
      let menuItems = [
         {
           key: "key0",
           iconImage: "",
           title: notificationLabels.NoNotifications,
           url: "",
           isUrlInternal: false,
           className: 'noCursor'
         },
       ];
       setNotificationsState({
         items: menuItems,
         count: 0,
       });
     }
     else{
       const items = menuItems1.filter(x=>x.key != 'key0' );
       setNotificationsState({
         items: items,
         count: items.length
       });
       
     }
  }

  return notifications.items.length >= 0 ? (
    <ContextMenu
      items={notifications.items}
      opener={[notificationsOpener]}
      customClass={props.className}
      notifications={
        notifications.count > 1000 ? "> 1000" : `${notifications.count}`
      }
      analyticsType={"notification"}
    />
  ) : (
    <div />
  );
};

export default Notifications;
