import React, { useEffect, useState } from "react";
import {
  IMessageBarStyles,
  Link,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";
import "./messageBar.scss";
import "./messageBarCustom.scss";
import { SharedService } from "../../../services/SharedService";
import { UserAlertItem } from "../../../models/SharedModels";
import LinkGenerator from "../linkGenerator/linkGenerator";
import { Constants } from "../../../helpers/Constants";
import { isEmpty } from "lodash";
import { useBoolean } from "@fluentui/react-hooks";
import { CommonFunctions } from "../../../helpers/CommonFunctions";

interface IBarState {
  switchVisibility?: () => void;
  messageBody: string;
}

const styles: Partial<IMessageBarStyles> = {
  expand: {
    marginTop: "4px",
    marginBottom: "4px"
  },
};

const DefaultBar = (props: IBarState) => (
  <MessageBar
    className="no-icon"
    onDismiss={props.switchVisibility}
    dismissButtonAriaLabel={
      SharedService.GetInstance().Data.Labels[
        Constants.LabelKeys.CloseMenuItemText
      ]
    }
    isMultiline={false}
    overflowButtonAriaLabel={
      SharedService.GetInstance().Data.Labels[Constants.LabelKeys.SeeMoreLabel]
    }
    truncated={true}
    styles={styles}
  >
    {/* <LinkGenerator linkTemplateText={props.messageBody}></LinkGenerator> */}
    <div className="banneralert">
    <div className="messagepopup" dangerouslySetInnerHTML={{ __html: props.messageBody }}></div>
    {/* <span className="bannerclose"><i className="icon-kx-chevron-down"></i></span> */}
    </div>
  </MessageBar>
);

export const MessageBarDefault: React.FunctionComponent = () => {
  const [isVisible, setVisibility] = React.useState<string | boolean>(false);
  const [hideMessageBar,setHideMessageBar] = useState<boolean>(false);
  // const [expandMessageBar,setExpandMessageBar] = useState<boolean>(false)
  const [expandMessageBar, { toggle: toggleExpandMessageBar }] = useBoolean(false);
  const [alert, setMessage] = React.useState<{
    message: string;
    alertID: number;
  }>({ message: "", alertID: 0 });

  const switchVisibility = React.useCallback(async () => {
    let _sharedService = SharedService.GetInstance();

    let userAlertItem = new UserAlertItem(
      alert.alertID
    );
    await _sharedService.Notifications.Post.PostUserAlertMapping(userAlertItem);
    setVisibility(false);
  }, [alert]);

  useEffect(() => {
    let _sharedService = SharedService.GetInstance();
    
    async function fetchMessage() {
      let result = await _sharedService.Notifications.Get.GetSystemAlert();
      if (!isEmpty(result)) {
        let validateAlertForUser = await _sharedService.Notifications.Get.ValidateSystemAlertForCurrentUser(
          result.AlertId
        );
        if (validateAlertForUser && result && result.Body) {
          setMessage({ alertID: result.AlertId, message: result.Body });
          setVisibility(true);
        }
      }
    }
    fetchMessage();
  }, []);

  const CloseMessageBar = () =>{
    setHideMessageBar(true)
  }
  const ExpandMessageBar = () =>{
    toggleExpandMessageBar();
  }

  return (
    <>
      {isVisible && (
        <div className={"messageBar-container " + (hideMessageBar? "hideMessageBar":"")}>
          <div className="container">
            {/* <DefaultBar
              switchVisibility={switchVisibility}
              messageBody={alert.message}
            /> */}
            <div className="bannernewconfig">
            <div className={"newconfig"+(expandMessageBar? "configbannerclass":"")}>
            <p
                        dangerouslySetInnerHTML={{
                          __html: CommonFunctions.replaceImageRelativeMessageBar(
                            alert.message
                          ),
                        }}
                      ></p>
            {/* {alert.message} */}
              {/* <p>A document card (DocumentCard) represents a file, and contains additional metadata or actions. 
                This offers people a richer view into a file than the typical grid view.A document card 
                (DocumentCard) represents a file, and contains additional metadata or actions. This offers people 
                a richer view into a file than the typical grid view.</p>
                <ul>
                  <li>additional metadata or actions.</li>
                  <li>contains additional metadata or actions.</li>
                  <li>the contains additional metadata or actions.</li>
                  <li>contains additional metadata or actions.</li>
                  <li>list contains additional metadata or actions.</li>
                </ul>
                <ol>
                  <li>additional metadata or actions</li>
                  <li>additional metadata</li>
                  <li>list additional metadata or actions</li>
                </ol>
                <img src="https://resources.deloitte.com/sites/qa-kx/DKXKXImagesLibrary/Internal%20Images/Collections/Feature/Picture1.jpg"></img> */}
            </div>
            <div className="iconsconfig">
            <span className="expandconfig" onClick={ExpandMessageBar}><i className={expandMessageBar?"icon-kx-angle-double-down":"icon-kx-angle-double-down"}></i></span>
              <span className="close" onClick={CloseMessageBar}><i className='icon-kx-close'></i></span>
            </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
