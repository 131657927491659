import React from 'react';

export interface SwiperCustomSlideProps {
    content?: any
}
export const SwiperCustomSlide:  React.FC<SwiperCustomSlideProps> = (props) => {
    return(
        <div className="swiper-slide">
            {props.content}
        </div>
    )
}