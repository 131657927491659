import React, { createContext, useContext, useEffect, useState } from "react";
import { IPageEditProps } from "../../../models/IShared";

const PageEditorContext = createContext({
  pageEditButtonsConfig: {} as IPageEditProps,
  setEditButtonsConfig: (data?: IPageEditProps) => {},
});

function usePageEditorContext() {
  const context = useContext(PageEditorContext);
  if (!context) {
    throw new Error(
      "usePageEditorContext must be used within PageEditorProvider!"
    );
  }
  return context;
}

function PageEditorProvider(props: any) {
  const [pageEditButtonsConfig, setEditButtonsConfig] = useState<IPageEditProps>();
  const value = { pageEditButtonsConfig, setEditButtonsConfig };
  return <PageEditorContext.Provider value={value} {...props} />;
}

export { PageEditorProvider, usePageEditorContext };
