import React from "react";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { SpinnerSize } from "office-ui-fabric-react";
import { useSelector } from "react-redux";
import UserPrompt from "./UserPrompt/UserPrompt";
import Response from "./Response/Response/Response";
import ChatBotTypingLoader from "../../../../../assets/prompt/UIElements/ChatBotTypingLoader/ChatBotTypingLoader";
import { IConversationMessage } from "../../../../../models/KxPromptModels";
import { MessageSender } from "../../../../../store/types";
import "./Conversation.scss";
import { RootState } from "../../../../../store/reducers";
import { SharedService } from "../../../../../services/SharedService";
import { Constants } from "../../../../../helpers/Constants";

/**
 * Interface for Conversation component props
 */
interface IConversationProps {
  isLoading: boolean;
  messages: IConversationMessage[];
  handleModifyResponse: (modifyOpion: string) => void;
  setScrollOnModifyClick: (isOptionSelected: boolean) => void;
}

/**
 * Conversation Component
 *
 * This component represents the conversation area, displaying user prompts and bot responses.
 * It also includes a loader when the bot is typing.
 */

const Conversation: React.FC<IConversationProps> = ({
  isLoading,
  messages,
  handleModifyResponse,
  setScrollOnModifyClick,
}) => {
  const _sharedService = SharedService.GetInstance();
  const loaderLabel =
    _sharedService.Data.Labels[
      Constants.LabelKeys.KXPrompt_ConversationLoaderText
    ];
  const { promptResponse, loadingDPNUserImage } = useSelector(
    (state: RootState) => state.promptResponseReducer
  );
  const { kxResultsFromHistoryLoading } = useSelector(
    (state: RootState) => state.promptConversationReducer
  );

  //Styling for conversation box loader
  const stackTokens: IStackTokens = {
    childrenGap: 20,
  };

  const styles: React.CSSProperties = {
    zIndex: 1000001,
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "6%",
  };

  /**
   * Render the Conversation component.
   *
   * @returns {JSX.Element} - The rendered Conversation component.
   */
  return (
    <div className="conversation_container">
      {kxResultsFromHistoryLoading ||
      (loadingDPNUserImage && promptResponse?.is_updated_from_history) ? (
        <Stack
          tokens={stackTokens}
          style={styles}
          className="prompt_conversation_PageLoader"
        >
          <Spinner label={loaderLabel} size={SpinnerSize.large} />
        </Stack>
      ) : (
        <>
          {messages?.map((conversation: IConversationMessage, idx: number) =>
            conversation.sender === MessageSender.USER &&
            typeof conversation.text === "string" ? (
              <UserPrompt conversationText={conversation.text} />
            ) : (
              <Response
                conversation={conversation}
                handleModifyResponse={handleModifyResponse}
                setScrollOnModifyClick={setScrollOnModifyClick}
                idx={idx}
                isLastMessage={idx === messages?.length - 1}
              />
            )
          )}
          {(isLoading || loadingDPNUserImage) && <ChatBotTypingLoader />}
        </>
      )}
    </div>
  );
};

/**
 * Export the Conversation component as default
 */
export default Conversation;
