import {  SHOW_FEATURED_ITEMS, ShowFeaturedItemsTypeActions } from "../types";
import { CardData } from "../../models/SharedModels";

// TypeScript infers that this function is returning SendMessageAction

export function showFeaturedItemsActions(type: any,show: boolean):  ShowFeaturedItemsTypeActions{
  return {
    type: type,
    payload: show
  }
}


