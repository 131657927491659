import React, { lazy, Suspense } from 'react';

const LazyHomePage = lazy(() => import(/* webpackChunkName: "homepage" */'./displayPage'));

const Display = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyHomePage {...props} />
  </Suspense>
);

export default Display;
