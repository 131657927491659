import React, { useState, useEffect, useCallback } from "react";
import { KXTaxonomyTreeBuilder, TableId } from "../../../services/KXTaxonomyTreeBuilder";
import { TreeBuilder } from "../../../services/TreeBuilder";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import styles from './checkboxTree.module.scss';
import { SharedService } from "../../../services/SharedService";
import { KXNSTreeBuilder } from "../../../services/KXNSTreeBuilder";
import { NSTags } from "../../../models/IShared";

export interface Node {
    Id: any;
    ExternalId: any;
    ParentExternalId?: any;
    ParentId?: any;
    Parent: any;
    IsDeleted: boolean;
    Level?: number;
    Taxonomy_Id?: any;
    Name: any;
    FullPath?: any;
    Nodes?: Node[] | null;
    children?: Node[] | null;
    label: String;
    value: String;
    disabled?:boolean
}

interface Props {
    modalView: boolean;
    onmodalviewChange: () => void;
    checkedTreeValues: string[];
    multipleSelect: boolean;
    onCheck: (value: any) => any;
    onSaveChanges: (value: []) => void;
    onCancelClick: () => void;
    tableID?: number | null;
    memberFirmId?: number | null;
    treeType: string;
    tagType?: number | null;
    heading: string;
    flatArrayData?: any[];
    multipleMemberFirmId?:boolean;
    multipleMemberFirmIdsData?:number[]
    flatData?:any,
}


export const CheckBoxTree: React.FC<Props> = (props) => {
    let [expanded, setExpanded] = useState<string[]>([]);
    let [currentNode, setCurentNode] = useState<any>([]);


    let [treeNodes, setTreeNodes] = useState<any>(null);
    const _sharedService = SharedService.GetInstance();
    
    useEffect(()=>{
        treeData();
       },[props.treeType])

    useEffect(()=>{
        treeData();
    },[props.flatArrayData])

    const treeData = async () => {
        let treeBuilder:any;
        let getTreeFromApi: any;
        if (props.treeType == 'CountryType' || props.treeType == 'BusinessType' || props.treeType == 'IndustryType' || props.treeType == 'BusinessLocalType' || props.treeType == 'Topic' || props.treeType == 'BusinessLocalTypeNC') {
            treeBuilder = new TreeBuilder();
        }else if (props.treeType == 'NSTags') {
            if (props.tagType == NSTags.AllianceAndPartnerShip) {
                treeBuilder = new KXNSTreeBuilder();
                getTreeFromApi = treeBuilder?.init(NSTags.AllianceAndPartnerShip,props.flatData);
            } else if (props.tagType == NSTags.Classification,props.flatData) {
                treeBuilder = new KXNSTreeBuilder();
                getTreeFromApi = treeBuilder?.init(NSTags.Classification,props.flatData);
            } else if (props.tagType == NSTags.DeloitteAssetsAndTools) {
                treeBuilder = new KXNSTreeBuilder();
                getTreeFromApi = treeBuilder?.init(NSTags.DeloitteAssetsAndTools,props.flatData);
            } else if (props.tagType == NSTags.Disclaimer) {
                treeBuilder = new KXNSTreeBuilder();
                getTreeFromApi = treeBuilder?.init(NSTags.Disclaimer,props.flatData);
            } else if (props.tagType == NSTags.Method) {
                treeBuilder = new KXNSTreeBuilder();
                getTreeFromApi = treeBuilder?.init(NSTags.Method,props.flatData);
            } else if (props.tagType == NSTags.TargetAudience) {
                treeBuilder = new KXNSTreeBuilder();
                getTreeFromApi = treeBuilder?.init(NSTags.TargetAudience,props.flatData);
            } else if (props.treeType == 'NSTags' && props.tagType == NSTags.UsageGuidelines) {
                treeBuilder = new KXNSTreeBuilder();
                getTreeFromApi = treeBuilder?.init(NSTags.UsageGuidelines,props.flatData);
            } else if (props.treeType == 'NSTags' && props.tagType == NSTags.MethodDocumentType) {
                treeBuilder = new KXNSTreeBuilder();
                getTreeFromApi = treeBuilder?.init(NSTags.MethodDocumentType,props.flatData);
            }
        } else {
            // treeBuilder = new KXTaxonomyTreeBuilder();
            treeBuilder = new TreeBuilder();
        }
        if(props.tableID != null && props.memberFirmId != null){
            if(props.multipleMemberFirmId){
                props.multipleMemberFirmIdsData?.map((id)=>{
                    getTreeFromApi = treeBuilder?.init(props.tableID, id,props.flatData);
                })
            }else{
                getTreeFromApi = treeBuilder?.init(props.tableID, props.memberFirmId,props.flatData);
            }
        }
        try {
            if (props.treeType == 'Badges' && props.flatData && props.flatData.length>0) {
                let tree = props.flatData;
                if(tree){
                    tree.sort((a:any,b:any) => {
                        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                        if (nameA < nameB) //sort string ascending
                        return -1;
                        if (nameA > nameB)
                        return 1;
                        return 0;
                    })
                    setTreeNodes(tree);
                }
            } else if (props.treeType == 'NSTags') {
                let tree = await getTreeFromApi;
                setTreeNodes(tree);
            }if(props.treeType == 'BusinessLocalType'){
                let allData:any= localStorage.getItem("__dkm__GetAllData");
                let AllSynapticaTagsData = allData!=null?JSON.parse(allData).data:"";
                let finalData:any[] = [];
                let requests:Promise<string>[] = [];
                let structuredTreeData:any[] = [];
                let treeBLAU = await treeBuilder?.init(props.tableID, 2243,AllSynapticaTagsData.MemberFirmIdBLAU,true);
                structuredTreeData.push(treeBLAU);
                let tree = await treeBuilder?.init(props.tableID, 2348,AllSynapticaTagsData.MemberFirmIdBLCA,true)
                structuredTreeData.push(tree);
                let treeBLUK = await treeBuilder?.init(props.tableID, 2494,AllSynapticaTagsData.MemberFirmIdBLUK,true);
                structuredTreeData.push(treeBLUK);
                let treeBLUS = await treeBuilder?.init(props.tableID, 2768,AllSynapticaTagsData.MemberFirmIdBLUS,true);
                structuredTreeData.push(treeBLUS);
                let treeBLDE = await treeBuilder?.init(props.tableID, 2470,AllSynapticaTagsData.MemberFirmIdBLDE,true);
                structuredTreeData.push(treeBLDE);
                let treeGlobal = await treeBuilder?.init(props.tableID, 1,AllSynapticaTagsData.buisinessGlobalList,true);
                structuredTreeData.push(treeGlobal);
    
                    if(structuredTreeData && structuredTreeData.length>0){
                    structuredTreeData.forEach((res:any) => {
                        let obj = {
                            id:res[0].LocationId,
                            data : res
                        }
                        finalData.push(obj);
                   
                    if(finalData){
                        let allFirmsData:any[] = [];
                        let memberFirmName:string;
                        finalData.map((data,index)=>{
                            if(data.id == 2348){
                                memberFirmName = "Canada"
                            }else if(data.id == 2494){
                                memberFirmName = "United Kingdom"
                            }else if(data.id == 2768){
                                memberFirmName = "United States"
                            }else if(data.id == 2470){
                                memberFirmName = "Germany"
                            }else if(data.id == 2243){
                                memberFirmName = "Australia"
                            }else if(data.id == 1){
                                memberFirmName = "Global"
                            }
                            let newParentNode:Node[] = [
                                {
                                    ExternalId: 0,
                                    FullPath: memberFirmName,
                                    Id: index + data.id,
                                    IsDeleted: false,
                                    Name: memberFirmName,
                                    Nodes: null,
                                    Parent: null,
                                    ParentId: null,
                                    children: data.data,
                                    label: memberFirmName,
                                    value: memberFirmName,
                                    disabled:true
                                }
                            ]
                            allFirmsData.push(...newParentNode);
                        });
                        setTreeNodes(allFirmsData);
                    }
                 });
                }
                 
            }
             else {
                 if (props.treeType == 'CountryType' || props.treeType == 'BusinessType' || props.treeType == "IndustryType" || props.treeType == 'ConceptType' || props.treeType == 'BusinessLocalTypeNC'|| props.treeType == 'Topic'){
                    let tree = await treeBuilder?.init(props.tableID, props.memberFirmId,props.flatData,true);
                    setTreeNodes(tree);
                }else if(props.treeType == 'ContentType'){
                    let tree:any = await getTreeFromApi;
                    setTreeNodes(tree);
                }
                // else{
                //     let tree:any = await getTreeFromApi;
                //     setTreeNodes(tree);
                // }
               
            }
            if (props.flatArrayData && props.flatArrayData.length > 0) {
                props.flatArrayData.map((data) => {
                    let currentNodeName = data.Name;
                    let currentExpandedNodes = currentNode;
                    currentExpandedNodes.push(currentNodeName);
                })
            }
        } catch (e) {
            console.log(e);

        }
    }

    const checkedData = (checked: string[], targetNode: any) => {
        if(props.treeType == 'BusinessLocalType' || props.treeType == 'CountryType' || props.treeType == 'BusinessType' || props.treeType == "IndustryType"|| props.treeType == 'ConceptType' || props.treeType == 'NSTags' || props.treeType =='Badges'|| props.treeType == 'BusinessLocalTypeNC'|| props.treeType == 'Topic' || props.treeType == 'ContentType'){
            let value = targetNode.value;
            props.onCheck([value]);
            setCurentNode([targetNode]);
        }else{
            let value = targetNode.label;
            props.onCheck([value]);
            setCurentNode([targetNode]);
        } 
    }

    const onSave = () => {
        props.onSaveChanges(currentNode);
        if(props.treeType == 'BusinessLocalType'){
            props.onmodalviewChange();
        }
    }

    const onCancel = () => {
        props.onmodalviewChange();
        props.onCancelClick();
    }

    return (
        <div>
            {props.modalView && <div id="myModal" className="modal">
                <div className="add-modal-content">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.onmodalviewChange}>
                        <i className="icon-kx-close"></i>
                    </button>
                    <div className="modal-header">
                        <h3 className="modal-title">
                            {props.heading}
                        </h3>
                    </div>
                    <div className="modal-body">
                        {treeNodes != null && <CheckboxTree
                            nodes={treeNodes}
                            checked={props.checkedTreeValues}
                            expanded={expanded}
                            showNodeIcon={false}
                            onCheck={(checked: string[], targetNode: any) => checkedData(checked, targetNode)}
                            onClick={(node: any) => checkedData([], node)}
                            onExpand={expanded => setExpanded(expanded)}
                            noCascade={true}
                            checkModel='all'
                            showExpandAll={true}
                            icons={{
                                check: <i className="icon-kx-checkbox-checked" />,
                                uncheck: <i className="icon-kx-checkbox-unchecked" />,
                                halfCheck: <i className="icon-kx-checkbox-checked" />,
                                expandClose: <i className="icon-kx-chevron-down" />,
                                expandOpen: <i className="icon-kx-chevron-up" />,
                            }}
                        />}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className={styles.secondary} onClick={onSave}>Save changes</button>
                        <button type="button" className={styles.info} data-dismiss="modal" onClick={onCancel}>Cancel</button>
                    </div>
                </div>

            </div>}
        </div>
    )
}
