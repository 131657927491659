import React from "react";
import "./CountryRestriction.scss";
import CountryRestrictionIcon from "../../../../../assets/prompt/countryrestriction.svg";
import AppHeader from "../AppHeader/AppHeader";
import { SharedService } from "../../../../../services/SharedService";
import { Constants } from "../../../../../helpers/Constants";

/**
 * CountryRestriction Component
 *
 * This component represents the page displayed when the application is restricted in a specific country.
 * It displays an icon and a heading indicating the country restriction.
 */
const CountryRestriction: React.FC = () => {
  const _sharedService = SharedService.GetInstance();

  /**
   * Render the CountryRestriction component.
   *
   * @returns {JSX.Element} - The rendered CountryRestriction component.
   */
  return (
    <>
      <AppHeader pagetype="restrictedCountryPage" />
      <div className="country-restriction-container">
        <img
          src={CountryRestrictionIcon}
          alt="Icon"
          className="country-restriction-icon"
        />
        <span className="country-restriction-label">
          {
            _sharedService.Data.Labels[
              Constants.LabelKeys.KXPrompt_CountryNotSupported
            ]
          }
        </span>
      </div>
    </>
  );
};

/**
 * Export the CountryRestriction component as default
 */
export default CountryRestriction;
