import React, { lazy, Suspense } from 'react';

const LazySubscriptionPage = lazy(() => import(/* webpackChunkName: "personalcollection" */'./subscriptionPage'));

const SubscriptionPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazySubscriptionPage {...props} />
  </Suspense>
);

export default SubscriptionPage;