import { APPLY_FILTERS_STATE } from "../types";
import { IApplyFiltersState } from "../../models/IShared";

const initialState: IApplyFiltersState = {
  isApply: false,
};

export default function applyFiltersReducer(
  state: IApplyFiltersState = initialState,
  action: any
): any {
  switch (action.type) {
    case APPLY_FILTERS_STATE:
      return {
        isApply: action.payload.isApply,
      };
    default:
      return state;
  }
}
