import { SEARCH_STATE } from '../types';
import { CardSchema } from '../../models/SharedModels';
import { IRefiner, Category } from '../../models/IShared';

const setSearchState = (renderRefiners?: boolean, 
    renderSearch?: boolean, 
    searchText? : string, 
    searchResults?: CardSchema[],
    suggestion?: string,
    totalCount?: number,
    refiners? : IRefiner[],
    categories?:  Category[],
    reSearchFlag?: boolean,
    navigation?: boolean ) => {
  return {
    type: SEARCH_STATE,
    payload: {
      renderRefiners: renderRefiners,
      renderSearch: renderSearch,
      searchText: searchText,
      searchResults: searchResults,
      suggestion: suggestion,
      totalCount: totalCount,
      refiners: refiners,
      categories: categories,
      reSearchFlag: reSearchFlag,
      navigation: navigation
    }
  }
}

export default setSearchState