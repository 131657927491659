import React, { useEffect, useState } from "react";
import {
  StorageHandler,
  StorageType,
} from "../../../../../../helpers/StorageHandler";
import { Constants } from "../../../../../../helpers/Constants";
import "./UserPrompt.scss";
import NoUserProfile from "../../../../../../assets/prompt/media/conversation/noUserProfile1.png";
interface IUserPromptProps {
  conversationText: string;
}

/**
 * UserPrompt Component
 *
 * This component represents a user prompt in the conversation.
 * It displays the user's profile image along with the provided conversation text.
 */

const UserPrompt: React.FunctionComponent<IUserPromptProps> = ({
  conversationText,
}) => {
  const [userImage, setUserImage] = useState("");

  useEffect(() => {
    const getUserImage = async () => {
      const response = await StorageHandler.Get(
        Constants.SessionStorageItems.User,
        StorageType.Local
      );
      setUserImage(response.kxp_pictureurl);
    };
    getUserImage();
  }, []);

  /**
   * Render the UserPrompt component.
   *
   * @returns {JSX.Element} - The rendered UserPrompt component.
   */
  return (
    <div className="userprompt_container flex-centered">
      <img src={userImage ? userImage : NoUserProfile} alt="user" />
      <span className="text">{conversationText}</span>
    </div>
  );
};

/**
 * Export the UserPrompt component as default
 */
export default UserPrompt;
