import fetchIntercept from 'fetch-intercept';

const initInterceptor = (accessToken: any) => {

    fetchIntercept.register({
        request: function (url, config) {
            // Modify the url or config here
            // Modify the url or config here

            let withDefaults = config ? config : {};

            withDefaults.headers = withDefaults.headers || new Headers({
                'Authorization': 'Bearer ' + accessToken,
                "Content-Type": "application/json",
            });

            return [url, withDefaults];
        },

        requestError: function (error) {
            // Called when an error occured during another 'request' interceptor call
            return Promise.reject(error);
        },

        response: function (response) {
            // Modify the reponse object
            return response;
        },

        responseError: function (error) {
            // Handle an fetch error
            return Promise.reject(error);
        }
    });
}

export default initInterceptor;