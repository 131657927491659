import { ISuggestion, IRecentSearch } from "./IShared";
import {Constants} from '../helpers/Constants';
import { CardSchema } from "./SharedModels";

export class Suggestion implements ISuggestion{
    Id: number;    
    Text: string;
    Phrase: string;
    RefinerId: number;
    Count: number;
    InternalUrl: string;    
    SuggestionType: string;
    SourceType: string;
    Type: string;
    isCuratedCollection: boolean;
    footer: string[];
    cardData: any; 
    ImageUrl: string;    
    TotalFollowers: string;
    Resources?: number;
    Description?: string;
    Views?: number;
    
    constructor(data: any, searchFor: string, viewAll:string) {
        this.Id = data.hasOwnProperty("Id") ? data.Id : 0;
        this.Text = data.hasOwnProperty("Text") ? data.Text : '';                                
        this.RefinerId = data.hasOwnProperty("RefinerId") ? data.RefinerId : 0;
        this.Count = data.hasOwnProperty("Count") ? data.Count : 0;
        this.Phrase = data.hasOwnProperty("Phrase") ? data.Phrase : '';
        this.InternalUrl = data.hasOwnProperty("InternalUrl") ? data.InternalUrl : '';
        this.SourceType = data.hasOwnProperty("SourceType") ? data.SourceType : '';
        this.SuggestionType = data.hasOwnProperty("SuggestionType") ? data.SuggestionType : '';
        this.Type = data.hasOwnProperty("Type") ? data.Type : '';
        this.isCuratedCollection = data.hasOwnProperty("isCuratedCollection") ? data.isCuratedCollection : false;        
        this.ImageUrl = data.hasOwnProperty("ImageUrl") ? data.ImageUrl : '';
        this.TotalFollowers = data.hasOwnProperty("TotalFollowers") ? data.TotalFollowers : '58';
        this.Resources = data.hasOwnProperty("Resources") && data.Resources ? data.Resources : '21';
        this.Description = data.hasOwnProperty("Description") && data.Description ? data.Description : '';
        this.Views = data.hasOwnProperty("Views") && data.Views ? data.Views : '564';

        this.Text = 
            (this.SuggestionType == Constants.SugestionType.Refiners ?
            viewAll.replace('{}', '<b>' + this.Text.split('|')[0] + '</b>') + '<span>&nbsp;' + 
            (this.Text.split('|')[1] == Constants.General.BusinessInternalName ? Constants.General.BusinessDisplayName : this.Text.split('|')[1]) + 
            '</span>' :
            (this.SuggestionType == Constants.SugestionType.Suggestions ?                 
            searchFor.replace('{}',this.Text) : this.Text));        
        
        this.footer = [];
        this.cardData = this.SuggestionType == Constants.SugestionType.Match ? [
           new CardSchema(data.indexSchema)] : [];
     }
}

export class RecentSearch implements IRecentSearch
{
    Id: number;
    Phrase: string;
    
    constructor(data:any){
        this.Id = data.hasOwnProperty("Id") ? data.Id : '';
        this.Phrase = data.hasOwnProperty("Phrase") ? data.Phrase : '';
    }
}