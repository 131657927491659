import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  IContextualMenuProps,
  IContextualMenuItemProps,
} from "office-ui-fabric-react/lib/ContextualMenu";
import { CommandBarButton } from "office-ui-fabric-react/lib/Button";
import { Image } from "office-ui-fabric-react/lib/Image";
import "./contextMenu.scss";
import {
  DirectionalHint,
  TooltipHost,
} from "office-ui-fabric-react/lib/components/Tooltip";
import { SharedService } from "../../../services/SharedService";
import { Constants } from "../../../helpers/Constants";
import {
  IContextMenu,
  IOpener,
  IContextMenuItem,
} from "../../../models/IShared";
import { Link as RouteLink } from "react-router-dom";
import { isEmpty } from "lodash";
import { CommonFunctions } from "../../../helpers/CommonFunctions";

export interface MenuProps {
  items: IContextMenuItem[];
  customClass?: string;
  opener?: IOpener[];
  notifications?: string;
  doNotLayer?: boolean;
  handle?: (event: any) => void;
  analyticsType?: string;
}

export const ContextMenu: React.FunctionComponent<MenuProps> = (props) => {
  const onDismiss = (e: any) => {
    if (e.type === "resize") {
      e.defaultPrevented = true;
      return;
    }
  };

  // useEffect(() => {
  //   let dashItems = document.querySelectorAll(".dash-item");
  //   if (!isEmpty(dashItems)) {
  //     dashItems.forEach((item: any) => {
  //       item.onmouseenter = (evt: any) => {
  //         setBgColor("#e7f6ff");
  //       };
  //       item.onmouseleave = (evt: any) => {
  //         setBgColor("inherit");
  //       };
  //     });
  //   }

  //   let setBgColor = (color: string) => {
  //     dashItems.forEach((item: any) => {
  //       item.style.backgroundColor = color;
  //     });
  //   };
  // });

  const elementRef: any = useRef(null);

  const menuProps: IContextualMenuProps = useMemo(
    () => ({
      items: props.items,
      className: props.customClass,
      isBeakVisible: false,
      doNotLayer: true,
      useTargetAsMinWidth: true,
      // onItemClick: (e: any, item: any) => setItemLink(item),
      alignTargetEdge: true,
      calloutProps: {
        preventDismissOnLostFocus: false,
        preventDismissOnResize: true,
        preventDismissOnScroll: true,
      },
      onDismiss: onDismiss,
      contextualMenuItemAs: (itemProps: IContextualMenuItemProps) => {
        let analyticalProps = {};
        if (props.analyticsType && props.analyticsType !== "notification") {
          analyticalProps = {
            [`digitaldata-${props.analyticsType}`]: itemProps.item.linkText,
          };
        }

        if (props.analyticsType && props.analyticsType === "notification") {
          analyticalProps = {
            [`digitaldata-${props.analyticsType}`]: "",
          };
        }

        return (
          <div
            {...analyticalProps}
            onClick={
              props.notifications && itemProps.item.url
                ? () => window.open(itemProps.item.url, itemProps.item.target)
                : undefined
            }
          >
            {itemProps.item.linkText && itemProps.item.isUrlInternal ? (
              <RouteLink
                to={itemProps.item.url}
                target={itemProps.item.target}
                className="RLink"
                onClick={() => {
                  elementRef.current!.click();
                }}
              >
                {itemProps.item.linkText}
              </RouteLink>
            ) : itemProps.item.url && itemProps.item.linkText ? (
              <a
                style={{
                  display: "block",
                }}
                className="linkContextMenu"
                onClick={() =>
                  window.open(itemProps.item.url, itemProps.item.target)
                }
              >
                {itemProps.item.linkText}
              </a>
            ) : itemProps.item.handler ? (
              <a
                style={{
                  display: "block",
                  outline: "none",
                  padding: "11px 20px",
                }}
                onClick={() => itemProps.item.handler()}
              >
                {itemProps.item.linkText}
              </a>
            ) : (
              ""
            )}

            <div className="contextual-menu-custom-container">
              {itemProps.item.iconImage && (
                <Image
                  className="contextual-menu-custom-icon"
                  src={itemProps.item.iconImage}
                />
              )}
              {(itemProps.item.title || itemProps.item.text) && (
                <div className="contextual-menu-custom-wrapper">
                  {itemProps.item.title && (
                    <span className="contextual-menu-custom-title">
                      {itemProps.item.title}
                    </span>
                  )}
                  {itemProps.item.text && (
                    <span className="contextual-menu-custom-text">
                      {itemProps.item.text}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      },
    }),
    [props.items, props.customClass]
  );
  const onRenderText = () => {
    return (
      <span className="button-custom-content">
        <TooltipHost
          content={props.opener ? props.opener[0].text : ""}
          directionalHint={DirectionalHint.bottomCenter}
        >
          {props.opener ? (
            props.opener[0].type == "icon" ? (
              <i className={props.opener[0].icon}></i>
            ) : (
              <img
                width={30}
                height={30}
                src={
                  SharedService.GetInstance().Data.CurrentUser.kxp_pictureurl
                    ? SharedService.GetInstance().Data.CurrentUser
                        .kxp_pictureurl
                    : CommonFunctions.changeUrlSafari(SharedService.SpDomainUrl +
                      SharedService.spSiteUrl +
                      "/DKXKXImagesLibrary/People/Unknown-person.jpg")
                }
              />
            )
          ) : null}
        </TooltipHost>
      </span>
    );
  };

  const onMouseEnter = (e: any) => {
    updateIsNotificationHovered(true);
  };

  const onMouseLeave = (e: any) => {
    updateIsNotificationHovered(false);
  };

  const [isNotificationClicked, updateIsNotificationClicked] = useState(false);
  const [isNotificationHovered, updateIsNotificationHovered] = useState(false);

  return (
    <>
      <div className={`contex-wrapper ${props.customClass}`}>
        <CommandBarButton
          className="contextmenu-opener"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          text={`${props.opener ? props.opener[0].icon : ""}`}
          menuProps={menuProps}
          onRenderText={onRenderText}
        />
        {props.notifications &&
          props.notifications !== "0" &&
          (isNotificationClicked || isNotificationHovered ? null : (
            <span className="notifications">{props.notifications}</span>
          ))}
      </div>
      <span ref={elementRef}></span>
    </>
  );
};
