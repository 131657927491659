import React from "react";
import { useId } from "@fluentui/react-hooks";
import { Modal } from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import openIcon from "../../../../../assets/prompt/ion_open-outline.svg";
import { Constants } from "../../../../../helpers/Constants";
import { SharedService } from "../../../../../services/SharedService";
import "./TryPromptByKXPopup.scss";

/**
 * Interface for TryPromptByKXPopup component props
 */
interface TryPromptByKXPopupProps {
  open: boolean;
  closeHandler: () => void;
  backgroundImage: string;
}

/**
 * TryPromptByKXPopup Component
 *
 * This component represents the Popup which contains Marketing of Prompt by KX
 * and is also used to redirect user to 'Prompt by KX'.
 */
const TryPromptByKXPopup: React.FC<TryPromptByKXPopupProps> = ({
  open,
  closeHandler,
  backgroundImage,
}) => {
  const _sharedService = SharedService.GetInstance();
  const titleId = useId("aboutModalTitle");
  /**
   * Function to redirect the user to the 'Prompt By KX' in a new tab
   */
  const redirect = () => {
    window.open("/prompt");
    closeHandler();
  };

  const modalStyle: React.CSSProperties = {
    textAlign: "center",
    position: "relative",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "12px 24px 28px",
  };

  const cardContainerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: "16px",
    marginTop: "20px",
  };

  const cardStyle: React.CSSProperties = {
    padding: "24px",
    height: "112px",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)",
    borderRadius: "8px",
    border: "none",
    boxShadow: "none",
    color: "#fff",
    fontSize: "14px",
    textAlign: "left",
  };

  const buttonContainerStyle: React.CSSProperties = {
    position: "relative",
    marginTop: "28px",
  };

  const buttonRowStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  };

  const transparentButtonStyle: React.CSSProperties = {
    background: "transparent",
    color: "#fff",
    border: "1px solid #fff",
    borderRadius: "23px",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
    height: "35px",
  };

  const gradientButtonStyle: React.CSSProperties = {
    background:
      "linear-gradient(269.69deg, #00ABAB -0.05%, #43B02A 0.98%, #0097A9 99.78%)",
    color: "#fff",
    borderRadius: "23px",
    display: "flex",
    alignItems: "center",
    border: "none",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
    height: "35px",
    cursor: "pointer",
    padding: "0 10px 0 14px",
  };

  const whiteText1: React.CSSProperties = {
    color: "#fff",
    fontSize: "28px",
    fontWeight: 600,
    marginBottom: "4px",
  };

  const whiteText2: React.CSSProperties = {
    color: "#fff",
    fontSize: "20px",
    fontWeight: 400,
  };

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={open}
      onDismiss={closeHandler}
      isBlocking={false}
      containerClassName="tryPrompt_modal"
    >
      <div style={modalStyle}>
        <h2 style={whiteText1}>
          {
            _sharedService.Data.Labels[
              Constants.LabelKeys.KXPrompt_TryPromptPopupLabel
            ]
          }
        </h2>
        <h3 style={whiteText2}>
          {
            _sharedService.Data.Labels[
              Constants.LabelKeys.KXPrompt_TryPromptPopupSubtitle
            ]
          }
        </h3>
        <div style={cardContainerStyle}>
          <div style={cardStyle} className="popup_card">
            {
              _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_TryPromptPopupCardLabelOne
              ]
            }
          </div>
          <div style={cardStyle} className="popup_card">
            {
              _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_TryPromptPopupCardLabelTwo
              ]
            }
          </div>
          <div style={cardStyle} className="popup_card">
            {
              _sharedService.Data.Labels[
                Constants.LabelKeys.KXPrompt_TryPromptPopupCardLabelThree
              ]
            }
          </div>
        </div>
        <div style={buttonContainerStyle}>
          <div style={buttonRowStyle}>
            <PrimaryButton
              style={transparentButtonStyle}
              onClick={closeHandler}
            >
              {_sharedService.Data.Labels[Constants.LabelKeys.KXPrompt_NotNow]}
            </PrimaryButton>
            <div
              digitaldata-prompt="KX Home>Try Prompt pop-up"
              onClick={redirect}
              style={gradientButtonStyle}
            >
              {
                _sharedService.Data.Labels[
                  Constants.LabelKeys.KXPrompt_TryPromptLabel
                ]
              }
              <img
                src={openIcon}
                alt="Open Icon"
                onClick={redirect}
                style={{ marginLeft: "4px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

/**
 * Export the TryPromptByKXPopup component as default
 */
export default TryPromptByKXPopup;
