import * as React from "react";
import { Shimmer, ThemeProvider, mergeStyles } from "@fluentui/react";

const wrapperClass = mergeStyles({
  padding: 2,
  selectors: {
    "& > .ms-Shimmer-container": {
      margin: "10px 0",
    },
  },
});

interface IResultsLoaderProps {}

/**
 * ResultsLoader Component
 *
 * This component represents the loader for results in Prompt by KX.
 */
const ResultsLoader: React.FunctionComponent<IResultsLoaderProps> = () => {
  return (
    <div className="results-list-loader">
      <ThemeProvider className={wrapperClass}>
        <div style={{ padding: "1.5rem 0" }}>
          <Shimmer
            width="90%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
          <Shimmer
            width="75%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
          <Shimmer
            width="50%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
        </div>

        <div style={{ padding: "1.5rem 0" }}>
          <Shimmer
            width="50%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
          <Shimmer
            width="75%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
          <Shimmer
            width="90%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
        </div>
        <div style={{ padding: "1.5rem 0" }}>
          <Shimmer
            width="90%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
          <Shimmer
            width="75%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
          <Shimmer
            width="50%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
        </div>
        <div style={{ padding: "1.5rem 0" }}>
          <Shimmer
            width="50%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
          <Shimmer
            width="75%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
          <Shimmer
            width="90%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
        </div>
        <div style={{ padding: "1.5rem 0" }}>
          <Shimmer
            width="90%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
          <Shimmer
            width="75%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
          <Shimmer
            width="50%"
            styles={{ root: { margin: "10px 0 10px 1.25rem" } }}
          />
        </div>
      </ThemeProvider>
    </div>
  );
};

export default ResultsLoader;
