import React, { useState } from "react";
import { IResultsResponse } from "../../../../../models/KxPromptModels";
import { Constants } from "../../../../../helpers/Constants";
import { SharedService } from "../../../../../services/SharedService";
import Vector from "../../../../../assets/prompt/Vector.svg";
import QuickViewIcon from "../../../../../assets/prompt/quick-view-result.svg";
import { Button } from "@material-ui/core";
import { IPromptResultCitationTracker } from "../../../../../models/IAnalytics";
import { AnalyticsService } from "../../../../../services/AnalyticsService";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import QuickViewWrapper from "../QuickViewWrapper/QuickViewWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getQuickViewResponse } from "../../../../../store/actions/promptQuickViewAction";
import { RootState } from "../../../../../store/reducers";
import ArrowSeparator from "../../../../../assets/prompt/dpn-profile-separtor.svg";
import PromptTooltip from "../../../../../assets/prompt/UIElements/PromptToolTip/PromptToolTip";
import { useDisableResultsOnModifySelected } from "../../promptContextAPIs/DisableResultsOnModifySelected";
import { TooltipBase } from "../../../../shared/tooltipBase/tooltipBase";

/**
 * Interface for Result component props
 */
interface IResultProps {
  resultsItem: IResultsResponse;
  index: number;
  handleCheckboxChange: (index: number) => void;
  selectedCheckboxCount: number;
  defaultKxResults: IResultsResponse[];
  position: number;
}

/**
 * Result Component
 *
 * This component represents the single result item in results section on right hand side
 * within Prompt by KX.
 */
const Result: React.FunctionComponent<IResultProps> = ({
  resultsItem,
  index,
  handleCheckboxChange,
  selectedCheckboxCount,
  defaultKxResults,
  position,
}) => {
  const [showQuickView, setShowQuickView] = useState<boolean>(false);
  const _sharedService = SharedService.GetInstance();
  const dispatch = useDispatch();
  const { quickViewResponse, loadingResponse, error } = useSelector(
    (state: RootState) => state.promptQuickViewReducer
  );
  const { promptResponse } = useSelector(
    (state: RootState) => state.promptResponseReducer
  );

  const { disableResultsOnModifySelected } =
    useDisableResultsOnModifySelected();

  /**
   * Handler to format date
   * @param date - Date String
   * @returns formatted date string
   */
  const renderDate = (date: string) => {
    const formattedDate = new Date(date)
      .toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
      .replace(",", "");
    return formattedDate;
  };

  /**
   * Handler to render the formatted date
   * @param date - Date String
   * @returns formatted date or empty string
   */
  const renderDateForDocument = (date: string | undefined) => {
    if (date) {
      const formattedDate = new Date(date).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      return formattedDate;
    }
    return "";
  };

  /**
   * Handler to shortened the month in date
   * @param date - String Date
   * @returns formatted date string with shortened month
   */
  const renderMonthYear = (date: string | undefined) => {
    if (date) {
      const dateObject = new Date(date);
      const dateFormatted = dateObject.toLocaleString("en-US", {
        month: "short",
        year: "numeric",
      });
      const shortenedMonth = dateFormatted.split(" ")[0].slice(0, 3);

      return `${shortenedMonth} ${dateFormatted.split(" ")[1]}`;
    }
    return "";
  };

  /**
   * Handler to track result citation
   * @param resultItemObj
   * @param isViewRestrictions
   */
  const handleResultCitationTracking = (
    resultItemObj: IResultsResponse,
    isViewRestrictions: boolean
  ) => {
    const resultCitationTrackerObj: IPromptResultCitationTracker = {
      action: isViewRestrictions ? "View Restrictions" : "View Full Content",
      displayArea: "Right rail",
      position: position.toString(),
      title: resultsItem.title,
      url: resultItemObj.appurl,
      sourceSystem: resultItemObj.source,
      sourceContentType: resultItemObj?.globalcontenttype
        ? resultItemObj?.globalcontenttype[0].name
        : "",
      contentID: resultItemObj.appid,
    };
    AnalyticsService.trackPromptResultCitation(resultCitationTrackerObj);
  };

  /**
   * Handler to open link url and track it for metrics
   * @param resultItemObj
   */
  const handleUsageGuidlinesForResult = (resultItemObj: IResultsResponse) => {
    handleResultCitationTracking(resultItemObj, true);
    if (resultItemObj?.appurl)
      window.open(resultItemObj?.appurl, "_blank", "noopener,noreferrer");
  };

  /**
   * Handler to open quick view popup
   * @param resultItemObj - Interface for result response item object
   */
  const popupOpenHandler = (resultItemObj: IResultsResponse) => {
    setShowQuickView(true);
    dispatch(
      getQuickViewResponse({
        appid: resultItemObj?.appid,
        source: resultItemObj?.source,
      })
    );
  };

  /**
   * Handler to format industry data
   * @param resultItem
   * @returns formatted industry data
   */
  const formatClientIndustry = (resultItem: IResultsResponse) => {
    if (resultItem?.industry) {
      // Check if the array is empty
      if (resultItem?.industry?.length === 0) {
        return "";
      }

      // Split the fullpath string using '->'
      const splitPath = resultItem?.industry[0]?.fullpath?.split("->");

      // Check if there are at least two elements, otherwise return the first or an empty string
      return splitPath.length >= 2 ? splitPath[1] : splitPath[0] || "";
    }
  };

  /**
   * Handler to render global content type
   * @param resultItem
   * @returns global content type
   */
  const renderGlobalContentType = (resultItem: IResultsResponse) => {
    const globalcontenttype =
      resultItem?.globalcontenttype &&
      resultItem?.globalcontenttype?.length !== 0
        ? resultItem?.globalcontenttype[0].name
        : "";
    return globalcontenttype;
  };

  /**
   * Handler to render global text for DPN source type
   * @param globalText - String text
   * @returns {JSX.Element} - Global Text Segment component
   */
  const renderGlobalTextForDPN = (globalText: string | undefined) => {
    if (globalText) {
      const globalTextSegments = globalText.split("->");
      return globalTextSegments.map((segment, index) => (
        <>
          {segment}
          {index < globalTextSegments.length - 1 && (
            <img
              style={{
                paddingLeft: "0.2rem",
                paddingRight: "0.2rem",
                transform: "scale(0.9)",
              }}
              src={ArrowSeparator}
              alt="->"
            />
          )}
        </>
      ));
    }
    return <></>;
  };

  /**
   * Handler for rendering global Lcsp
   * @param globalLcsp - String
   * @returns name or empty string
   */
  const rendeGloabalLcsp = (globalLcsp: string | undefined) => {
    if (globalLcsp) {
      const parts = globalLcsp.split("|");
      //Extracting only the names from splitted globalLscp
      const finalValue = parts.map((item) => item.split(";")[1]).join(", ");
      return finalValue;
    }
    return "";
  };

  /**
   * Handler to render subtitle
   * @param resultItem
   * @returns {JSX.Element} - The rendered subtitle based on source type
   */
  const renderSubtitle = (resultItem: IResultsResponse) => {
    switch (resultItem.source) {
      case "Quals":
        return (
          <div className="sub-title">
            <PromptTooltip
              content={`${resultsItem?.source} | ${renderMonthYear(
                resultsItem?.qualprojectstartdate
              )} -
              ${renderMonthYear(resultsItem?.qualprojectenddate)} | ${
                resultsItem?.geography?.[0]?.name || ""
              } 
              ${resultsItem?.pdicity ? " - " + resultsItem.pdicity : ""}
              `}
              wrapperClassName="promptTooltip-subTitle withRestriction"
            >
              <div className="results-item-sublabel">
                {`${resultsItem?.source} | ${renderMonthYear(
                  resultsItem?.qualprojectstartdate
                )} -
                ${renderMonthYear(resultsItem?.qualprojectenddate)} | ${
                  resultsItem?.geography?.[0]?.name || ""
                }
                ${resultsItem?.pdicity ? " - " + resultsItem.pdicity : ""}
                `}
              </div>
            </PromptTooltip>
            <div className="ug-info-icon">
              {resultsItem?.isusageguidelinesapplicable ? (
                <PromptTooltip
                  content={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_ReviewRestrictionsPopup
                    ]
                  }
                  wrapperClassName="promptTooltip-usageGuidelines"
                  direction="top-right"
                >
                  <img
                    className="icon"
                    src={Vector}
                    alt=""
                    onClick={() => handleUsageGuidlinesForResult(resultsItem)}
                  />
                </PromptTooltip>
              ) : (
                <></>
              )}
            </div>
          </div>
        );

      case "DPN":
        return (
          <>
            <div className="sub-title">
              <PromptTooltip
                content={`${resultsItem?.geography?.[0]?.name || ""} ${
                  resultsItem?.pdicity ? " - " + resultsItem.pdicity : ""
                }`}
                wrapperClassName="promptTooltip-subTitle max-content"
              >
                <div className="results-item-sublabel">
                  {`${resultsItem?.geography?.[0]?.name || ""} ${
                    resultsItem?.pdicity ? " - " + resultsItem.pdicity : ""
                  }`}
                </div>
              </PromptTooltip>
              <div className="ug-info-icon"></div>
            </div>
            <div className="sub-title">
              <PromptTooltip
                content={`${resultsItem?.pdilevelsglobaltext} ${
                  resultsItem?.pdifunctionsglobaltext && `->`
                } ${resultsItem?.pdifunctionsglobaltext}`}
                wrapperClassName="promptTooltip-subTitle prompt_dpn"
              >
                <div className="results-item-sublabel">
                  {resultsItem?.pdilevelsglobaltext}
                  {resultsItem?.pdifunctionsglobaltext && (
                    <img
                      style={{
                        paddingLeft: "0.2rem",
                        paddingRight: "0.2rem",
                        transform: "scale(0.9)",
                      }}
                      src={ArrowSeparator}
                      alt="->"
                    />
                  )}
                  {renderGlobalTextForDPN(resultsItem?.pdifunctionsglobaltext)}
                </div>
              </PromptTooltip>
            </div>
            {resultsItem?.kxp_role !== null &&
              resultsItem?.kxp_role?.trim().length !== 0 && (
                <div className="sub-title">
                  <PromptTooltip
                    content={`Role: ${resultsItem?.kxp_role}`}
                    wrapperClassName="promptTooltip-subTitle prompt_dpn"
                  >
                    <div className="results-item-sublabel">
                      {`Role: ${resultsItem?.kxp_role}`}
                    </div>
                  </PromptTooltip>
                </div>
              )}
          </>
        );

      case "KX Clients":
        return (
          <>
            {(resultsItem?.geography?.[0]?.name?.trim()?.length !== 0 ||
              resultsItem?.sortbydate?.trim()?.length !== 0) && (
              <div className="sub-title">
                <PromptTooltip
                  content={`HQ: ${
                    resultsItem?.geography?.[0]?.name || ""
                  } | ${renderMonthYear(resultsItem?.sortbydate)}`}
                  wrapperClassName="promptTooltip-subTitle kx_docs"
                >
                  <div className="results-item-sublabel">
                    <b>HQ:</b> {resultsItem?.geography?.[0]?.name || ""} |{" "}
                    {renderMonthYear(resultsItem?.sortbydate)}
                  </div>
                </PromptTooltip>
              </div>
            )}
            {resultsItem?.globallcsp?.trim().length !== 0 && (
              <div className="sub-title">
                <PromptTooltip
                  content={`Global LCSP: ${rendeGloabalLcsp(
                    resultsItem?.globallcsp
                  )}`}
                  wrapperClassName="promptTooltip-subTitle"
                >
                  <div className="results-item-sublabel">
                    <b>Global LCSP:</b>{" "}
                    {rendeGloabalLcsp(resultsItem?.globallcsp)}
                  </div>
                </PromptTooltip>
                <div className="ug-info-icon"></div>
              </div>
            )}
            <div className="content">{formatClientIndustry(resultsItem)}</div>
          </>
        );

      case "KX Assets":
        return (
          <div className="sub-title">
            <PromptTooltip
              content={`${renderGlobalContentType(
                resultsItem
              )} | ${renderDateForDocument(resultsItem?.sortbydate)} | ${
                resultsItem?.geography?.[0]?.name || ""
              }`}
              wrapperClassName="promptTooltip-subTitle"
            >
              <div className="results-item-sublabel">
                {renderGlobalContentType(resultsItem)}|{" "}
                {renderDateForDocument(resultsItem?.sortbydate)} |{" "}
                {resultsItem?.geography?.[0]?.name || ""}
              </div>
            </PromptTooltip>
            <div className="ug-info-icon">
              {resultsItem?.isusageguidelinesapplicable ? (
                <PromptTooltip
                  content={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_ReviewRestrictionsPopup
                    ]
                  }
                  wrapperClassName="promptTooltip-usageGuidelines"
                  direction="top-right"
                >
                  <img
                    className="icon"
                    src={Vector}
                    alt=""
                    onClick={() => handleUsageGuidlinesForResult(resultsItem)}
                  />
                </PromptTooltip>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      default:
        return (
          <div className="sub-title">
            <PromptTooltip
              content={`${resultsItem?.source} | ${renderDate(
                resultsItem?.sortbydate
              )} | ${resultsItem?.geography?.[0]?.name || ""}`}
              wrapperClassName="promptTooltip-subTitle kx_docs"
            >
              <div className="results-item-sublabel">
                {`${resultsItem?.source} | ${renderDate(
                  resultsItem?.sortbydate
                )} | ${resultsItem?.geography?.[0]?.name || ""}`}
              </div>
            </PromptTooltip>
            <div className="ug-info-icon">
              {resultsItem?.isusageguidelinesapplicable ? (
                <PromptTooltip
                  content={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_ReviewRestrictionsPopup
                    ]
                  }
                  wrapperClassName="promptTooltip-usageGuidelines"
                  direction="top-right"
                >
                  <img
                    className="icon"
                    src={Vector}
                    alt=""
                    onClick={() => handleUsageGuidlinesForResult(resultsItem)}
                  />
                </PromptTooltip>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div
      key={index}
      className="results-item-container"
      data-testid={`prompt-result-item-container-${index}`}
    >
      <div className="title">
        <PromptTooltip
          content={`
            ${
              !disableResultsOnModifySelected
                ? selectedCheckboxCount === 5 && !resultsItem.ischecked
                  ? _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_UnableToAddCitation
                    ]
                  : !resultsItem.ischecked
                  ? _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_IncludeResultPopup
                    ]
                  : defaultKxResults.find(
                      (kxResult) =>
                        kxResult.citation_no === resultsItem.citation_no
                    )?.ischecked
                  ? _sharedService.Data.Labels[
                      promptResponse?.contain_specialist
                        ? Constants.LabelKeys
                            .KXPrompt_ResultNotAllowedSpecialist
                        : Constants.LabelKeys.KXPrompt_ResultIncludedPopup
                    ]
                  : ""
                : ""
            }
              `}
          wrapperClassName="promptTooltip-checkbox"
          direction="bottom-right"
        >
          <Checkbox
            key={index}
            onChange={(e, isChecked) => handleCheckboxChange(index)}
            checked={resultsItem.ischecked}
            data-testid="prompt-result-is-checked"
            disabled={
              (!resultsItem.ischecked && selectedCheckboxCount === 5) ||
              promptResponse?.contain_specialist ||
              disableResultsOnModifySelected // Flag to disable the regenerate response and results checkbox if modify response is selected
            }
            className="prompt-result-checkbox"
          />
        </PromptTooltip>
        <TooltipBase
          content={`${resultsItem?.title}`}
          wrapperClassName="prompt_tooltip"
          directionalHint={5}
        >
          <a
            href={resultsItem?.appurl}
            target={"_blank"}
            rel="noopener noreferrer"
            className="results-label-link"
            onClick={() => handleResultCitationTracking(resultsItem, false)}
          >
            <span className={"index-selected"}>{`${index}.`}</span>
            <span
              className={
                resultsItem.source === "Quals" ||
                resultsItem.source === "KX Assets"
                  ? "label"
                  : "label selected"
              }
            >{`${resultsItem?.title}`}</span>
          </a>
        </TooltipBase>
      </div>
      {renderSubtitle(resultsItem)}
      {resultsItem?.source !== "KX Clients" && (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: resultsItem?.description,
            }}
            className="content"
          />
          <div className="quickview-result-container">
            <Button
              className="quickview-result"
              onClick={() => popupOpenHandler(resultsItem)}
              startIcon={<img src={QuickViewIcon} alt="open quick view" />}
            >
              {
                _sharedService.Data.Labels[
                  Constants.LabelKeys.KXPrompt_QuickView
                ]
              }
            </Button>
          </div>
        </>
      )}

      {!loadingResponse &&
        quickViewResponse &&
        quickViewResponse?.Results.length > 0 &&
        quickViewResponse?.Results[0]?._source?.appid ===
          resultsItem?.appid && (
          <QuickViewWrapper
            result={quickViewResponse}
            featured={false}
            loadingAll={false}
            displayArea="Right rail"
            position={position}
          />
        )}
    </div>
  );
};

export default Result;
