import { PrimaryButton } from "@fluentui/react/lib/Button";
import React from "react";
import { SharedService } from "../../../services/SharedService";

export interface SingleFileRecordProps {
  file: File;
  onDelete: (file: File) => void;
}

export function SingleFileRecord({ file, onDelete }: SingleFileRecordProps) {
  const sharedService = SharedService.GetInstance();
  const attachmentType = file.name.toLowerCase().split(".").pop();

  let attachementIconImage = sharedService.attachmentIcons.filter(
    (item) => item.AttachementType === attachmentType
  )[0]?.IconImageClass;

  if (!attachementIconImage) {
    attachementIconImage = sharedService.attachmentIcons.filter(
      (item) => item.AttachementType === "general"
    )[0].IconImageClass;
  }

  return (
    <div className="file-list">
      <div className="doctype">
        <i className={attachementIconImage} style={{ fontSize: 30 }}></i>{" "}
        <div className="filename">{file.name}</div>
      </div>
      <span>
        <i className="icon-kx-close" onClick={() => onDelete(file)}></i>
      </span>
    </div>
  );
}

export default SingleFileRecord;
