import {SearchBoxDataActionTypes, SET_SEARCHBOX_DATA } from '../types'

const initialState = {
  searchBoxData: []
}

export function searchBoxDataReducer(
  state = {},
  action: SearchBoxDataActionTypes
): any {
  switch (action.type) {
    case SET_SEARCHBOX_DATA:
      return action.payload
    default:
      return state
  }
}