import React, { useState, useEffect } from "react";
import { IUserNeedDropdownOption } from "../../../models/IShared";
import { SharedService } from "../../../services/SharedService";
import { Constants } from "../../../helpers/Constants";
import { DropdownBase } from "../dropdownBase/dropdownBase";
import { useSelector, useDispatch } from "react-redux";
import setUserNeed from "../../../store/actions/userNeedsActions";
import { IDropdownOption } from "office-ui-fabric-react";
import { ModalBase } from "../modalBase/modalBase";
import setUserNeedsModal from "../../../store/actions/userNeedsModalActions";
import { isEmpty } from "lodash";
import { useUserNeedContext } from "../contextAPIs/userNeedContext";
import { CommonFunctions } from "../../../helpers/CommonFunctions";

var selecedNeedTitle = "";
var selectedNeedSubTitle = "";

interface IState {
  userNeeds: IUserNeedDropdownOption[];
  selectedUserNeed: IUserNeedDropdownOption;
}
const infoLogo = require("../../../assets/images/info-green.png");

const UserNeeds: React.FC = () => {
  const [state, setState] = useState<IState>();
  const _showUserModal: any = useSelector((s) => s);

  const _userNeeds: any = useSelector((state) => state);

  const dispatch = useDispatch();

  const _sharedService = SharedService.GetInstance();

  const { setUserNeedId } = useUserNeedContext();

  useEffect(() => {
    let _sharedService = SharedService.GetInstance();
    async function fetchData() {
      // Get user needs from Db
      let userNeedsData: any = await _sharedService.Actions.Get.GetUserNeeds();
      if (!isEmpty(userNeedsData)) {
        let options: IUserNeedDropdownOption[] = [];

        userNeedsData.forEach((data: any) => {
          var text = _sharedService.Data.Labels[data.TitleLabel];
          options.push({
            key: `${data.Id}`,
            text: text,
            data: {
              subText: _sharedService.Data.Labels[data.SubTitleLabel],
            },
          });
        });

        // Get user selected need from local storage
        let userNeed = CommonFunctions.URLSearch().get(Constants.QueryStringParameters.UserNeeds) || await _sharedService.Actions.Get.GetSelectedUserNeed();

        let selectedNeed = userNeed
          ? options.filter((need) => need.key == userNeed)[0]
          : options[0];

        // let selectedNeed =
        //   needId && needId !== -1
        //     ? options.filter((need) => need.key == `${needId}`)[0]
        //     : options[0];

        setState({
          userNeeds: options,
          selectedUserNeed: selectedNeed,
        });
        dispatch(setUserNeed(selectedNeed));
        setUserNeedId(userNeed ? parseInt(userNeed) : parseInt(options[0].key));
      }
    }

    // if (needId !== -1) {
    fetchData();
    // }
  }, []);

  var userNeedChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => {
    if (state) {
      let userNeed = CommonFunctions.URLSearch().get(Constants.QueryStringParameters.UserNeeds);
      if(userNeed) {
        var uri = new URLSearchParams(window.location.search); 
        uri.delete(Constants.QueryStringParameters.UserNeeds); 
        if(uri.toString().length > 0) window.history.replaceState({}, document.title, window.location.origin + window.location.pathname + "?" + uri.toString());            
        else window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);       
      }

      //Persist to local storage
      if (option ?.key) {
        _sharedService.PersistUserNeed(option.key as number);
      }

      var selectedNeed = state.userNeeds.filter((x) => x.key == option ?.key)[0];
      selecedNeedTitle = selectedNeed.text;      
      selectedNeedSubTitle = selectedNeed.data.subText;
      dispatch(setUserNeed(selectedNeed));
      setState({
        ...state,
        selectedUserNeed: selectedNeed,
      });
      if (option ?.key) {
        setUserNeedId(option.key as number);
      }
    }    
  };

  const setSubTitle = (selectedNeed: any) => {
    selecedNeedTitle = selectedNeed.text;
              if (selecedNeedTitle) {
                switch (selecedNeedTitle) {
                  case "Explore":
                    selectedNeedSubTitle =
                      _sharedService.Data.Labels[
                      Constants.LabelKeys.KeyExploreModalText
                      ];
                    break;
                  case "Learn":
                    selectedNeedSubTitle =
                      _sharedService.Data.Labels[
                      Constants.LabelKeys.KeyLearnModalText
                      ];
                    break;
                  case "Sell":
                    selectedNeedSubTitle =
                      _sharedService.Data.Labels[
                      Constants.LabelKeys.KeySellModalText
                      ];
                    break;
                  case "Deliver":
                    selectedNeedSubTitle =
                      _sharedService.Data.Labels[
                      Constants.LabelKeys.KeyDeliverModalText
                      ];
                    break;
                  case "Connect":
                    selectedNeedSubTitle =
                      _sharedService.Data.Labels[
                      Constants.LabelKeys.KeyConnectModalText
                      ];
                    break;
                }
              }
              selectedNeedSubTitle = selectedNeedSubTitle
                ? selectedNeedSubTitle
                : selectedNeed.data.subText;
  }

  var onRenderOption = (option: any): any => {
    return (
      <div className="options">
        <div>
          {option.data && option.data.icon && (
            <i className={option.data.icon} />
          )}
          <span className="main-text">
            {option.text ? option.text : option.title}
          </span>
          {option.data && option.data.subText && (
            <span className="sub-text">{option.data.subText}</span>
          )}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setUserNeedsModal(true));   
            if (state) {
              var selectedNeed = state.userNeeds.filter((x) => x.key == option?.key)[0];
              setSubTitle(selectedNeed);              
              //dispatch(setUserNeed(selectedNeed));              
            }
          }}
        >
          <img src={infoLogo} />
        </div>
      </div>
    );
  };
  return (
    <div>
      {state ? (
        <div>
          <DropdownBase
            options={state.userNeeds}
            selectedKey={state.selectedUserNeed.key}
            // selectedKey={`${needId}`}
            onChange={userNeedChange}
            label={
              _sharedService.Data.Labels[Constants.SearchLabels.UserNeedTitle]
            }
            onRenderOption={onRenderOption}
            className="kx-dropdown"
          />

          <ModalBase
            className="userNeedsModal"
            button={{
              text: _sharedService.Data.Labels[Constants.LabelKeys.OkLabel],
            }}
            title={
              _sharedService.Data.Labels[
              Constants.LabelKeys.KeyGeneralTitleModal
              ]
            }
            isOpen={_showUserModal["userNeedsModalReducer"].userNeedModal}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: selectedNeedSubTitle.replace(/(?:\r\n|\r|\n)/g, '<br>')

              }}
            ></span>
          </ModalBase>
        </div>
      ) : (
          ""
        )}
    </div>
  );
};

export default UserNeeds;
