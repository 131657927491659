export enum StorageType {
    Session = 0,
    Local = 1,
    //TODO: Cookie = 2
}

export class StorageHandler {
    private static hostName: string | undefined = window && window.location && window.location.host && window.location.host.split('.').shift();
    private static memoryStore: any = {};

    // add value to storage by storage type
    public static Add(key: string, item: any, storageType: StorageType) {
        const fullKey = key + '_' + this.hostName;

        switch (storageType) {
            case StorageType.Session:
                sessionStorage.setItem(fullKey, JSON.stringify({ exp: new Date().getTime() + 1 * 12 * 60 * 60 * 1000, data: item }));
                StorageHandler.memoryStore[fullKey] = item;
                break;
            default:
                localStorage.setItem(fullKey, JSON.stringify({ exp: new Date().getTime() + 1 * 24 * 60 * 60 * 1000, data: item }));
                this.memoryStore[fullKey] = item;
                break;
        }
    }

    // get one item by key from storage by storage key and type
    public static Get(key: string, storageType: StorageType, delegate?: (...props: any[]) => Promise<any>,override?:boolean, ...delegateProps: any[]): Promise<any> {
        const fullKey = key + '_' + this.hostName;
        return new Promise((resolve) => {
            let storageData;
            if (!!this.memoryStore && !!this.memoryStore[fullKey]&&!override)
                return resolve(this.memoryStore[fullKey]);
            else {
                switch (storageType) {
                    case StorageType.Session:
                        storageData = sessionStorage.getItem(fullKey);
                        break;
                    default:
                        storageData = localStorage.getItem(fullKey);
                        break;
                }

                if (!!storageData && JSON.parse(storageData).exp > new Date().getTime()&&!override) {
                    storageData = JSON.parse(storageData);
                    this.memoryStore[fullKey] = storageData.data;
                    return resolve(storageData.data);

                }
                else {
                    if (!!delegate)
                        return delegate(...delegateProps).then(result => {
                            if(result){
                                StorageHandler.Add(key, result, storageType);
                                this.memoryStore[fullKey] = result;
                                return resolve(result);
                            }else{
                                 resolve(null);
                            }
                        });

                    else resolve(null);
                }
            }
        });
    }

    // remove value from storage by storage type
    public static Remove(key: string, storageType: StorageType) {
        const fullKey = key + '_' + this.hostName;
        if (!!this.memoryStore && !!this.memoryStore[fullKey])
            this.memoryStore[fullKey] = null;
        switch (storageType) {
            case StorageType.Session:
                sessionStorage.removeItem(fullKey);
                break;
            default:
                localStorage.removeItem(fullKey);
                break;
        }
    }

    // clear storage (remove all items by storage type)
    public static Clear(storageType: StorageType) {
        switch (storageType) {
            case StorageType.Session:
                return sessionStorage.clear();
            default:
                return localStorage.clear();
        }
    }
}
