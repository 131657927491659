import React, { lazy, Suspense } from 'react';

const LazyCardsWrapper= lazy(() => import('./cardsWrapper'));

const CardsWrapper = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyCardsWrapper {...props} />
  </Suspense>
);

export default CardsWrapper;
