import { ITagsData } from "../../models/IShared";
import {  LOAD_TAGS_DATA, loadTagDataTypeActions } from "../types";

// TypeScript infers that this function is returning SendMessageAction

export function setLoadTagsDataAction(TagsDataState:ITagsData[]):  loadTagDataTypeActions{
  return {
    type: LOAD_TAGS_DATA,
    payload: TagsDataState
  }
}


