import React, { useState, useRef, useEffect, FC } from "react";
import { TooltipBase } from "../../../../shared/tooltipBase/tooltipBase";

interface IQuestionProps {
  text: string;
  isClickable: boolean;
  onTextClick: (text: string) => void;
}

const Question: FC<IQuestionProps> = ({ text, isClickable, onTextClick }) => {
  const questionRef = useRef<HTMLDivElement | null>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      const element = questionRef.current;
      if (element) {
        setIsTruncated(element.scrollHeight > element.clientHeight);
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => window.removeEventListener("resize", checkTruncation);
  }, [text]);

  const handleClick = () => {
    if (isClickable) {
      onTextClick(text);
    }
  };

  return (
    <div
      className={`question ${isClickable ? "" : "disabled"}`}
      onClick={handleClick}
      style={{ cursor: isClickable ? "pointer" : "not-allowed" }}
      digitaldata-prompt="Body>Suggested next prompt"
    >
      <TooltipBase
        isHidden={!isTruncated}
        content={text}
        wrapperClassName="prompt_tooltip"
      >
        <div className="fl-question-text" ref={questionRef}>
          {text}
        </div>
      </TooltipBase>
    </div>
  );
};

export default Question;
