import React, { useRef, useState } from 'react';
import Swiper from 'react-id-swiper';
import { SwiperCustomSlide } from './swiperCustomSlide';
import './swiperCustom.scss';

export interface SwiperCustomProps {
    className?: string
}

export const SwiperCustom: React.FunctionComponent<SwiperCustomProps> = props => {
    const [isFirst, setFirstState] = useState(false);
    const [isLast, setLastState] = useState(false);
    const ref1 = useRef<any>(null);

    const goNext = (e: any) => {
        if (ref1.current !== null && ref1.current !== null && ref1.current.swiper !== null) {
            ref1.current.swiper.slideNext();
            switchState(ref1.current.swiper);
        }
        if (e)
            e.stopPropagation();
    };

    const goPrev = (e: any) => {
        if (ref1.current !== null && ref1.current.swiper !== null) {
            ref1.current.swiper.slidePrev();
            switchState(ref1.current.swiper);
        }
        if (e)
            e.stopPropagation();

    };

    const switchState = (state: any) => {
        state.isBeginning ? setFirstState(true) : setFirstState(false);
        state.isEnd ? setLastState(true) : setLastState(false);

    }

    return (
        <div className={`swiper-custom-container ${isLast ? 'no-overlay no-overlay-right' : ''} ${isFirst ? 'no-overlay no-overlay-left' : ''} ${props.className ? props.className : ''}`}>

            <Swiper
                ref={ref1}
                slidesPerView={'auto'}
                slidesPerGroup={4}
                on={{
                    'init': (swiper: any) => { switchState(swiper) },
                    'slideChange': (swiper: any) => { switchState(swiper) },
                    'resize': (swiper: any) => { switchState(swiper) }
                }}
                watchOverflow={true}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }}
                renderPrevButton={() => <button className={`swiper-button-prev ${isFirst ? 'disabled' : ''}`} onClick={goPrev}>Prev</button>}
                renderNextButton={() => <button className={`swiper-button-next ${isLast ? 'disabled' : ''}`} onClick={goNext}>Next</button>}
                {...props}
            >
                {React.Children.map(props.children as Element[], (child: Element) => {
                    return <SwiperCustomSlide content={child} />
                })}
            </Swiper>
        </div>
    )
}