import * as React from "react";
import { ILastViewedCard } from "../../../../shared/lastViewed/lastviewed";
import { SharedService } from "../../../../../services/SharedService";
import { useDispatch, useSelector } from "react-redux";
import { LastViewedActions } from "../../../../../store/actions/LastViewedActions";
import { ADD_DATA_TO_LAST_VIEWED } from "../../../../../store/types";
import { CardData, Follower } from "../../../../../models/SharedModels";
import { Constants } from "../../../../../helpers/Constants";
import { CommonFunctions } from "../../../../../helpers/CommonFunctions";
import { ModalBase } from "../../../../shared/modalBase/modalBase";
import { ModalGeneric } from "../../../../shared/modalBase/modalGeneric";
import {
  clearQuickViewData,
  clearQuickViewLoading,
} from "../../../../../store/actions/promptQuickViewAction";

/**
 * Interface for QuickViewModal component props
 */
interface IQuickViewModalProps {
  items?: any;
  overflowItems?: any[];
  onShow?: any;
  onHide?: any;
  cardData: CardData | null;
  type: any;
  openModalEdit?: any;
  ifPin?: any;
  explainData?: any;
  toggle?: boolean;
}

/**
 * QuickViewModal Component
 *
 * This component represents Quick View modal.
 */
const QuickViewModal: React.FunctionComponent<IQuickViewModalProps> = (
  props
) => {
  const ref = React.useRef<any>(null);
  let _sharedService: SharedService = SharedService.GetInstance();
  var userMail = SharedService.GetInstance().Data.CurrentUser.kxp_email;
  const dispatch = useDispatch();
  let anyRef;
  const lastViewedState = useSelector(
    (state: any) => state.lastViewedReducer.cards
  );
  let _configurations = _sharedService.Data.Configuration;

  const saveLastViewed = () => {
    let _configurations = SharedService.GetInstance().Data.Configuration;
    let ctCorelationJson = SharedService.GetInstance().GetConfigurationMapping(
      props.cardData ? props.cardData.allData : null
    );
    let configCT = JSON.parse(_configurations.ContentTypesMapping);

    let ctMapping = configCT[ctCorelationJson.JsonMapping];

    var title = ctMapping.metadataCards["line1"];
    var desc = ctMapping.metadataCards["line3"];

    let _obj: ILastViewedCard = {
      key: lastViewedState.length,
      imageUrl: props.cardData ? props.cardData.image : "",
      text: props.cardData ? props.cardData.allData[title] : "",
      footerText: [
        props.cardData ? props.cardData.allData.globalcontenttype : "",
      ],
      showSecondaryText: true,
      id: props.cardData ? props.cardData.allData.id : -1,
    };

    let tempArr: any = [];
    tempArr = tempArr.concat(lastViewedState);

    let isExist: boolean = false;

    for (let i: number = 0; i < tempArr.length; i++) {
      if (tempArr[i].id == _obj.id) {
        tempArr.splice(0, 0, tempArr[i]);
        tempArr.splice(i + 1, 1);
        isExist = true;
        break;
      }
    }

    if (!isExist) {
      tempArr.unshift(_obj);
      if (tempArr.length > 10) tempArr.splice(tempArr.length - 1, 1);
      //tempArr.push(_obj);
    }
    dispatch(LastViewedActions(ADD_DATA_TO_LAST_VIEWED, tempArr));
    dispatch(clearQuickViewData());
  };

  React.useEffect(() => {
    const linkClickHandler = () => {
      dispatch(clearQuickViewLoading());
      ref.current.showModal();
      if (props.cardData)
        SharedService.GetInstance().Actions.Post.PostLastView(
          new Follower(
            props.cardData.allData.source != Constants.Images
              ? props.cardData.sourceId
              : props.cardData.allData.img_Id,
            props.cardData.allData.source != Constants.Images
              ? props.cardData.allData.internalcontenttype
              : Constants.Images
          )
        );
      CommonFunctions.doTrackingMiniCard(
        Constants.MiniCardActions.QuickView,
        props.cardData
      );
    };
    linkClickHandler();
  }, [props.cardData]);

  return (
    <>
      {props.cardData ? (
        <div>
          <ModalBase ref={ref} onHideModal={saveLastViewed} title="" opener="">
            <ModalGeneric
              ifPin={props.ifPin}
              openModalEdit={props.openModalEdit}
              className="quickView"
              cardData={{ ...props.cardData, isQuickView: true }}
              explainData={props.explainData}
            />
          </ModalBase>
        </div>
      ) : null}
    </>
  );
};

export default QuickViewModal;
