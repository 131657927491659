import React, { useState, useEffect, Fragment, useRef } from "react";
import { Stack, Text, Link } from "office-ui-fabric-react";
import { OverflowSetBase } from "../../shared/overflowSetBase/overflowSetBase";
import { SwiperCustom } from "../../shared/swiperCustom/swiperCustom";
import useHover from "@react-hook/hover";
import { TooltipBase } from "../tooltipBase/tooltipBase";
import "./cardPeople.scss";
import { con } from "../cardBase/cardBase";
import { CardData, PersonalCollection } from "../../../models/SharedModels";
import { Constants } from "../../../helpers/Constants";
import { SharedService } from "../../../services/SharedService";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { ButtonsResizeGroup } from "../buttonsResizeGroup/buttonsResizeGroup";
import { IMiniCardTracker } from "../../../models/IAnalytics";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";
import starIcon from "../../../assets/images/starGreen.svg";
import { useSelector } from "react-redux";
import { ModalBase } from "../modalBase/modalBase";
import { SearchBox } from "office-ui-fabric-react";
import { CardType, ICategory } from "../../../models/IShared";



export interface PeopleCardProps {
  collection: any[];
  slider?: boolean;
}

export interface PeopleCardContentProps {
  slider?: boolean;
  explainData?: any;
  card: any;
  categoryMove?: (e: any, cardData: CardData) => void;
  chosenItems?: any[];
  openModalEdit?: any;
}

export const PeopleCard: React.FunctionComponent<PeopleCardProps> = (props) => {
  const ConditionalWrap = (con: con) =>
    con.condition ? con.wrap(con.children) : con.children;
  return (
    <Stack horizontal>
      <ConditionalWrap
        condition={props.slider}
        wrap={(children) => {
          return <SwiperCustom>{children}</SwiperCustom>;
        }}
      >
        {props.collection.map((item, i) => {
          return <PeopleCardContent card={item} key={i} />;
        })}
      </ConditionalWrap>
    </Stack>
  );
};

export const PeopleCardContent: React.FunctionComponent<PeopleCardContentProps> = (
  props
) => {
  const target = useRef(null);
  const [isMenuOpened, setIsOpened] = useState(false);
  const [card, setCard] = useState(props.card);
  const [containerHeight, setContainerHeight] = useState<any>(null);
  const [containerWidth, setContainerWidth] = useState<any>(null);
  const [isPromotedResult, setIsPromotedResult] = useState(props.card.allData.isPromotedResult);
  const [isDescriptionOverflow, setIsDescriptionOverflow] = useState(false);
  const cardDescriptionElement = useRef(null);
  const containerRef = useRef<any>(null);
  const [isOpenPinModal, setIsOpenPinModal] = useState<boolean>(false);
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [existName, setExistName] = useState<boolean>(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [newCategory, setNewCategory] = useState<string>("");
  const [filteredCategories, setFilteredCategories] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const _sharedService = SharedService.GetInstance();
  const [pinState, setPinState] = useState<boolean>(props.card.ifPinCard);

  var createNewCategoryAndPin = async () => {
    if (newCategory) {
      var existName = await SharedService.GetInstance().Actions.Get.GetIfExistCategoryPersonalCollection(newCategory);
      if (!existName)
        SharedService.GetInstance().Actions.Get.AddPersonalCollectionCategory(newCategory).then((data: any) => {
          setSelectedCategoryId(data)
          savePinToCategory(data)
        })
      else setExistName(true)
    } else {
      setExistName(true)
    }

  }

  var onClickCategory = (e: any, id: number) => {
    var list = document.getElementsByClassName("categoriesList");
    for (var i = 0; i < list.length; i++) {
      var el = list[i]
      if (el && el.classList.contains("selectedCategory")) {
        el.classList.remove("selectedCategory");
      }
    }
    setSelectedCategoryId(id)
    e.currentTarget.classList.add("selectedCategory")
  }

  var savePinToCategory = (categoryId: any = selectedCategoryId) => {
    const category = categories.find((item: any) => item.Id === selectedCategoryId);
    SharedService.GetInstance().Actions.Post.PinUnPin(
      new PersonalCollection(
        props.card.allData.source,
        category ? category.CategoryName : "Uncategorized",
        props.card.linkToItem,
        props.card.allData.title,
        "",
        props.card.allData.Img,
        props.card.allData.id,
        props.card.allData.internalcontenttype,
        category ? category.Id : categoryId
      )
    )
      .then(async (response) => {
        if (response) {
          CommonFunctions.doTracking(Constants.MiniCardActions.Pin, props.card);
          setIsOpenPinModal(false);
          updatePin();
        }
      });
  }

  const updatePin = () => {
    setPinState(prev => !prev)
    if (pinState) {
      props.card.ifPinCard = false;
      setCreateNew(false);
    } else {
      props.card.ifPinCard = true;
    }
    props.card.overflowItemsData[0].overflowItems = props.card.overflowItemsData[0].overflowItems.map((overflowItem: any) => {
      let overFlowItem1 = JSON.parse(JSON.stringify(overflowItem));
      switch (overflowItem.key) {
        case Constants.ActionsButtonsKeys.PinToCollection:
          if (props.card.ifPinCard) {
            overFlowItem1.name = SharedService.GetInstance().Data.Labels[Constants.ActionsButtonsNames.unPin]
          } else {
            overFlowItem1.name = SharedService.GetInstance().Data.Labels[Constants.ActionsButtonsNames.pin_to_Collection]
          }
          return overFlowItem1;
        default:
          return overFlowItem1;
      }
    })
  }

  const openModalAndGetCategories = () => {
    if (!pinState) {
      _sharedService.Actions.Get.GetPersonalCollectionCategories().then((categories) => {
        var isSelected = false
        categories.map(async (category: ICategory, index: number) => {
          if (category.Count < 100 && isSelected == false) {
            category.isSelected = true
            isSelected = true;
          }
        })
        setCategories(categories)
        setFilteredCategories(categories)
        setIsOpenPinModal(true);
      })
    } else {
      SharedService.GetInstance().Actions.Post.PinUnPin(
        new PersonalCollection(
          props.card.allData?.source,
          "uncategorized",
          props.card.linkToItem,
          "",
          props.card.allData?.Img,
          "",
          "",
        )
      ).then((response) => {
        updatePin();
        CommonFunctions.doTracking(Constants.MiniCardActions.Unpin, props.card);
      });
    }
  }

  const trackerMiniCard = () => {
    let cardTracker: IMiniCardTracker = {} as IMiniCardTracker;
    let contentID =
      props.card.displayArea &&
        props.card.displayArea === Constants.DisplayAreas.Featured &&
        props.card.linkToItem.indexOf("id=") !== -1
        ? props.card.linkToItem.substring(
          props.card.linkToItem.indexOf("id=") + 3,
          props.card.linkToItem.length
        )
        : "";
    cardTracker.action = Constants.MiniCardActions.ViewFullContent;
    if (
      props.card.displayArea &&
      props.card.displayArea === Constants.DisplayAreas.Featured
    )
      cardTracker.contentID = contentID ? contentID : props.card.sourceId ? `${props.card.sourceId}` : "";
    else
      cardTracker.contentID = props.card.sourceId
        ? `${props.card.sourceId}`
        : "";
    cardTracker.displayArea = props.card.allData && props.card.allData.isPromotedResult ? Constants.DisplayAreas.PromotedResults : props.card.displayArea
      ? props.card.displayArea
      : "";
    cardTracker.isExternal = props.card.linkToItem
      ? props.card.linkToItem.indexOf(window.location.hostname) === -1
      : props.card.allData.IfExternal
        ? props.card.allData.IfExternal
        : true;
    cardTracker.position = props.card.position
      ? `${props.card.position}`
      : "";
    cardTracker.url = props.card.linkToItem;
    cardTracker.title = props.card.title.text;
    cardTracker.sourceSystem =
      props.card.allData.source || props.card.allData.Type;
    cardTracker.sourceContentType = props.card.allData.apptype
      ? props.card.allData.apptype
      : props.card.allData.internalcontenttype;
    cardTracker.isMiniCard = true;
    if (
      cardTracker.sourceContentType &&
      cardTracker.sourceContentType.indexOf(";") !== -1
    ) {
      cardTracker.sourceContentType = cardTracker.sourceContentType.substring(
        cardTracker.sourceContentType.lastIndexOf(";") + 1,
        cardTracker.sourceContentType.length
      );
    }

    if (
      cardTracker.sourceSystem &&
      cardTracker.sourceSystem.indexOf(";") !== -1
    ) {
      cardTracker.sourceSystem = "";
    }
    AnalyticsService.trackMiniCard(cardTracker);
  }

  const goToContent = (url: string, openInNewTab?: boolean) => {
    if (url) {
      trackerMiniCard();
      CommonFunctions.redirectLink(openInNewTab, url, history);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      var height = containerRef ? containerRef.current.clientHeight : "";
      var width = containerRef ? containerRef.current.clientWidth : "";
      if (height > width) {
        var newHeight = 70
        var newWidth = 70
        setContainerHeight(newHeight + "px")
        setContainerWidth(newWidth + "px")
      }
      else if (height < width) {
        var newHeight = 70
        var newWidth = 70
        setContainerHeight(newHeight + "px")
        setContainerWidth(newWidth + "px")
      }
      else if (height == width) {
        var newHeight = 70
        var newWidth = 70
        setContainerHeight(newHeight + "px")
        setContainerWidth(newWidth + "px")
      }
    }
  }, [containerRef])
  const onShow = () => {
    setIsOpened(true);
  };

  const onHide = () => {
    setIsOpened(false);
  };

  let history = useHistory();

  const get_badgesCounts = () => {
    let badgesCounts = props.card?.allData?.badges?.length
    if (badgesCounts)
      return badgesCounts.toString() + (badgesCounts < 2 ? " Badge" : " Badges");
    else
      return '';
  }

  const getDescriptionElement = (desc: string): JSX.Element => {
    return (
      <div
        ref={cardDescriptionElement}
        className={
          props.card.size == "lg"
            ? "card-base__text_featured"
            : "card-base__text"
        }
        dangerouslySetInnerHTML={{ __html: desc || "" }}
      >
      </div>
    );
  };

  const getHighlightedElement = (description: string, text: any, highlightedDes: any): JSX.Element => {
    const hitDesc = highlightedDes && highlightedDes.length ? highlightedDes.join("") : highlightedDes;
    return (
      <div
        ref={cardDescriptionElement}
        className={props.card.size == "lg" ? "card-base__text_featured" : "card-base__text textStrip"}
      >
        {hitDesc && hitDesc.length ?
          <>
            <div dangerouslySetInnerHTML={{ __html: hitDesc }}></div>
          </>
          :
          <div
            ref={cardDescriptionElement}
            className={props.card.size == "lg" ? "card-base__text_featured" : "card-base__text"}
            dangerouslySetInnerHTML={{ __html: description ? description : "" }}
          >
          </div>
        }
      </div>
    )
  };

  const getTheRelevantValueToolTip = (data: string): any => {
    let trimText = CommonFunctions.getTextFromHtml(data, 400);
    if (trimText) {
      var arr = trimText.split(";");
      return arr.length > 1 ? arr[1] : arr[arr.length - 1];
    } else {
      return data;
    }
  };

  const getHighlightedTitleElement = (title: string, text: any, highlightedTitle: any): JSX.Element => {
    return (
      <div className="card-base__text trim-2l textStrip">
        {props.card.title.icon && !props.card.IsRemoved &&
          <i className={props.card.title.icon}></i>}
        <>
          {highlightedTitle && highlightedTitle.length ?
            <div
              dangerouslySetInnerHTML={{ __html: highlightedTitle }}
            ></div>
            :
            <div className="card-base__text trim-2l">
              {props.card.title.icon && !props.card.IsRemoved &&
                <i className={props.card.title.icon}></i>
              }
              {title && title != "" ? title : title}
            </div>
          }
        </>
      </div>
    )
  };

  useEffect(() => {
    if (card.overflowItemsData) {
      //insert the on click method
      var index =
        card.overflowItemsData[0] &&
        card.overflowItemsData[0].overflowItems.find(
          (item: any) => item.key == Constants.ActionsButtonsKeys.Follow
        );
      if (index)
        index.onClick = (event: any, original: any) =>
          CommonFunctions.followUnFllow(
            event,
            original,
            card.internalcontenttype,
            card.sourceId,
            card,
            card.email
          );
    }
  }, []);

  var askMeTags = CommonFunctions.returnArrNoEmpty(
    props.card.allData[
    "kxp_askmeabouttext"
    ])
  askMeTags.label = 'Ask Me About: '
  var roles = CommonFunctions.returnRoles(props.card.allData);
  props.card.footer = props.card.isHorizontal ? [props.card.allData['kxp_recentactivitiesmodified']] : props.card.footer;
  var tempArr: any[] = props.chosenItems ? JSON.parse(JSON.stringify(props.chosenItems)) : [];
  var index = tempArr.findIndex((carddata: any) => props.card.allData && carddata.allData && ((carddata.allData.id &&
    props.card.allData.id && props.card.allData.id === carddata.allData.id) || (!props.card.allData.id && carddata.allData.title &&
      props.card.allData.title && props.card.allData.title === carddata.allData.title)))
  return (
    <div className="ms-Grid-col" key={props.card.key} ref={target}>
      <div className={`people-card ${props.card.isHorizontal ? "horizontal horizontalpeopleListView" : ""} ${props.categoryMove ? "categoryChoose" : ""} ${index != -1 ? "greenBorder" : ""}  
        ${isPromotedResult ? "promotedResultPeople" : ""}`}
        onClick={(e) => {
          if (props.categoryMove)
            props.categoryMove(e, props.card)
          else {
            e?.stopPropagation();
            if (e?.ctrlKey) {
              goToContent(card.linkToItem, true);
            } else {
              goToContent(card.linkToItem, card.openNewTab);
            }
          }
        }}
      >

        {props.card.overflowItemsData && props.card.overflowItemsData.length > 0 &&
          props.card.overflowItemsData[0].overflowItems && (
            <div className={`card-menu ${isMenuOpened ? "is-opened" : ""}`}>
              <OverflowSetBase
                cardData={props.card}
                items={props.card.overflowItemsData[0].items}
                overflowItems={props.card.overflowItemsData[0].overflowItems}
                onShow={onShow}
                onHide={onHide}
                type={props.card.type}
                openModalEdit={props.openModalEdit}
              />
            </div>
          )}
        {isPromotedResult && !props.card.isHorizontal && (
          <Link href={""} className="people-card__tagPromoted">
            <img src={starIcon} style={{ marginRight: '4px' }} />
            Best Match
          </Link>
        )}
        <Stack.Item className="people-card__image">
          <img ref={containerRef} style={{ visibility: 'hidden', position: 'absolute' }} src={!isEmpty(props.card.imageData) ? props.card.imageData : card.image} />

          <div
            className="img"
            style={{
              height: containerHeight,
              width: containerWidth,
              backgroundImage: `url("${!isEmpty(props.card.imageData) ? props.card.imageData : card.image
                }")`,
            }}
          ></div>
          <div className="people-images-card">
            <div style={{ display: 'flex' }}>
              <h4 className="people-card__title name">
                {props.card.title}
                {!props.card.IsRemoved && (
                  !!props.card.allData.internalcontenttype &&
                  <Link href={props.card.tag ? props.card.tag[0].tagUrl : ""} className="card-basetype-people">
                    {props.card.allData.internalcontenttype == "Video"
                      ? props.card.allData.kxe_videoduration
                      :
                      props.card.allData.internalcontenttype == Constants.DPN ? props.card.allData.internalcontenttype
                        : props.card.size == "lg"
                          ? props.card.tag[0].tagName
                          : SharedService.GetInstance().Data.Labels[
                          props.card.tag[0].tagName
                          ]}
                  </Link>
                )}

              </h4>
              {props.card.badge && !props.card.IsRemoved && (
                <Link className="people-card__badge">
                  <i className={`icon-kx-${props.card.badge}`}></i>
                </Link>
              )}
              {props.card.ifPinCard && (
                <Link className="people-card__pin">
                  <i className={`icon-kx-pin`}></i>
                </Link>
              )}
            </div>
            <Text block className="people-card__subtitle">
              {props.card.subTitle}
            </Text>

          </div>
          {props.card.isHorizontal ?
            <div className="people-images-card">
              <div style={{ display: 'flex' }}>
                <h4 className="people-card__title one">{props.card && props.card.title ? getHighlightedTitleElement(props.card.title, props.card.searchedTerm, props.card.allData.highlight?.titletext) : props.card.title}
                  {!props.card.IsRemoved && (
                    !!props.card.allData.internalcontenttype &&
                    <Link href={props.card.tag ? props.card.tag[0].tagUrl : ""} className="card-basetype-people">
                      {props.card.allData.internalcontenttype == "Video"
                        ? props.card.allData.kxe_videoduration
                        :
                        props.card.allData.internalcontenttype == Constants.DPN ? props.card.allData.internalcontenttype
                          : props.card.size == "lg"
                            ? props.card.tag[0].tagName
                            : SharedService.GetInstance().Data.Labels[
                            props.card.tag[0].tagName
                            ]}
                    </Link>
                  )}
                </h4>
                {props.card.badge && !props.card.IsRemoved && (
                  <Link className="people-card__badge">
                    <i className={`icon-kx-${props.card.badge}`}></i>
                  </Link>
                )}
                {props.card.ifPinCard && (
                  <Link className="people-card__pin">
                    <i className={`icon-kx-pin`}></i>
                  </Link>
                )}
              </div>
              <Text block className="people-card__subtitle location">
                {props.card.subTitle}
              </Text>
            </div>
            : <div className="gridpeoplecard">
              {props.card.badge && !props.card.IsRemoved && (
                <Link className="people-card__badge gridbadge">
                  <i className={`icon-kx-${props.card.badge}`}></i>
                </Link>
              )}
              {props.card.ifPinCard && (
                <Link className="people-card__pin">
                  <i className={`icon-kx-pin`}></i>
                </Link>
              )}
            </div>}
        </Stack.Item>
        <Stack.Item className="people-card__content">
          {!props.card.isHorizontal && (
            <Fragment>
              <Text block className="people-card__text-first">
                {props.card.textFirst}
              </Text>
              <Text block className="people-card__text-third">
                {Array.isArray(props.card.textSecond)
                  ? CommonFunctions.stringFromArr(props.card.textSecond)
                  : props.card.textSecond}
              </Text>
            </Fragment>
          )}{" "}
          {props.card.isHorizontal && (
            <>
              {isPromotedResult && props.card.isHorizontal && (
                <Link href={""} className="people-card__tagPromoted">
                  {/* <img src={starIcon} style={{marginRight:'4px'}} /> */}
                  Best Match
                </Link>
              )}
              <h4 className="people-card__title two">
                <Link className="people_card_all">
                  {getHighlightedTitleElement(`${props.card.allData['title']} ${(props.card.subTitle)}`, props.card.allData.searchedTerm, props.card.allData.highlight?.titletext)}
                  {/* <Link> {props.card && props.card.title} ? {getHighlightedTitleElement(`${props.card.allData['title']} ${(props.card.subTitle)}`, props.card.searchedTerm, (`${props.card.allData['title']} ${(props.card.subTitle)}`))}:(`${props.card.allData['title']} ${(props.card.subTitle)}`)</Link> */}
                  {!props.card.IsRemoved && (
                    !!props.card.allData.internalcontenttype &&
                    <Link href={props.card.tag ? props.card.tag[0].tagUrl : ""} className="card-basetype-people">
                      {props.card.allData.internalcontenttype == "Video"
                        ? props.card.allData.kxe_videoduration
                        :
                        props.card.allData.internalcontenttype == Constants.DPN ? props.card.allData.internalcontenttype
                          : props.card.size == "lg"
                            ? props.card.tag[0].tagName
                            : SharedService.GetInstance().Data.Labels[
                            props.card.tag[0].tagName
                            ]}
                    </Link>
                  )}
                </Link>
              </h4>
              <div className="peoplemapingitems">
                <ul>
                  {props.card.allData.kxp_recentactivitiesmodified &&
                    <li>
                      <Text block className="people-card__subtitle location-dpn">
                        {props.card.allData.kxp_recentactivitiesmodified ? props.card.allData.kxp_recentactivitiesmodified : ""}
                      </Text>
                    </li>
                  }
                  {props.card.subTitle &&
                    <li>
                      <Text block className="people-card__subtitle location-dpn">
                        {props.card.subTitle}
                      </Text>
                    </li>
                  }

                </ul>
                {props.card.badge && !props.card.IsRemoved &&
                  <div className="people_badge">
                    <TooltipBase content={get_badgesCounts()} >
                      <Link className="card-basebadge">
                        <i className={`icon-kx-${props.card.badge ? props.card.badge : "featured-solid"}`}></i>
                      </Link>
                    </TooltipBase>
                  </div>
                }
              </div>
            </>
          )}
          {props.card.isHorizontal && (
            <Fragment>
              <Text block className="people-card__text">
                {props.card.textFirst}
              </Text>
              <Text block className="people-card__text rolein-dpn">
                {roles}
              </Text>
              <Text block className="people-card__text secondtext-card">
                {Array.isArray(props.card.textSecond)
                  ? CommonFunctions.stringFromArr(props.card.textSecond)
                  : props.card.textSecond}
              </Text>
              <Text block className="people-card__text people-industery">
                {props.card.allData['allDataIndustry']}
              </Text>
              <p className="card-base__text peoplecontenttext">
                <Link>
                  {isDescriptionOverflow ? (
                    <TooltipBase
                      content={getTheRelevantValueToolTip(props.card.allData.description)}
                    >
                      {props.card.isHorizontal ? getHighlightedElement(props.card.allData.description, props.card.searchedTerm, props.card.allData.highlight ? props.card.allData.highlight?.description : []) : getDescriptionElement(props.card.allData.description)}
                    </TooltipBase>
                  ) : (
                    <>
                      {props.card.isHorizontal ? getHighlightedElement(props.card.allData.description, props.card.searchedTerm, props.card.allData.highlight ? props.card.allData.highlight?.description : []) : getDescriptionElement(props.card.allData.description)}
                    </>
                  )}
                </Link>
              </p>
            </Fragment>

          )}
          {props.card.isHorizontal && askMeTags.length > 0 && (
            <div className="links hiddenDownTablet">
              <ButtonsResizeGroup items={askMeTags} />
            </div>
          )}
        </Stack.Item>
        <Stack.Item className="people-card__footer">
          {props.card.isHorizontal && (
            <ul>
              {props.card.footer.map((footerItem: any) => {
                return <li key={footerItem}>{footerItem}</li>;
              })}
            </ul>
          )}
        </Stack.Item>

      </div>
      {props.card.isHorizontal ?
        <div className="listviewpin">
          <ul>
            <li>
              <a onClick={openModalAndGetCategories}>{pinState ? <><i className="icon-kx-pinned-solid"></i> {_sharedService.Data.Labels["Unpin"]} </> : <><i className="icon-kx-pin"></i>Pin</>}</a>
            </li>
            <li>
              {props.card &&
                props.card.overflowItemsData &&
                props.card.overflowItemsData[0] &&
                props.card.overflowItemsData[0].overflowItems &&
                !props.card.IsRemoved && (
                  <div
                    className={`moreoptionslist ${isMenuOpened ? "is-opened" : ""}`}
                  >
                    <OverflowSetBase
                      type={props.card.ctName}
                      cardData={props.card}
                      overflowItems={
                        props.card.overflowItemsData[0].overflowItems
                      }
                      onShow={onShow}
                      onHide={onHide}
                      openModalEdit={props.openModalEdit}
                      explainData={props.explainData}
                      more={true}
                    />

                  </div>
                )}
            </li>
          </ul>
        </div> : null}
      <ModalBase
        onHideModal={() => { setIsOpenPinModal(false); setCreateNew(false); setExistName(false) }}
        isTwoButtons={true}
        button={{
          text: SharedService.GetInstance().Data.Labels[
            Constants.LabelKeys.CancelCapitalButton
          ],
          function: () => {
            setIsOpenPinModal(false); setCreateNew(false); setExistName(false)
          },

        }}
        SecondButton={{
          text: SharedService.GetInstance().Data.Labels[Constants.LabelKeys.SaveCapitalButton],
          function: async () => {
            if (createNew) {
              createNewCategoryAndPin()
            } else {
              savePinToCategory(selectedCategoryId)
            }
          },
          noHideModal: true
        }}
        title={
          "Pin To"
        }
        className="categoryModal"
        isOpen={isOpenPinModal}
      >
        <div style={{ width: '100%' }}>
          <div className="moreThan100">{SharedService.GetInstance().Data.Labels[Constants.LabelKeys.PinNewItemPopUp]}</div>
          <div style={{ display: 'flex' }}>
            <SearchBox
              className={"search-default search--lg newCategory"}
              onChange={() => { }}
              defaultValue={searchTerm}
              value={searchTerm}
              placeholder={"Search Here"}
              disableAnimation
            /></div>
          <button
            className="moveButtonCreate"
            onClick={async (e) => {
              var existNameDefault = true;
              var index = 0;
              var name = "Category 0"
              while (existNameDefault == true) {
                index++
                existNameDefault = await SharedService.GetInstance().Actions.Get.GetIfExistCategoryPersonalCollection(name + index);
              }
              setNewCategory(name + index)
              var list = document.getElementsByClassName("categoriesList");
              for (var i = 0; i < list.length; i++) {
                var el = list[i]
                if (el && el.classList.contains("selectedCategory")) {
                  el.classList.remove("selectedCategory");
                }
              }
              setCreateNew(true)
            }}
          >
            <i className="icon-kx-plus" style={{ paddingRight: "3px" }} />
            Create new Category
          </button>
          <div id="categorieslistAll" className="categorieslistAll">
            {createNew ? <>
              <input maxLength={255} value={newCategory} onChange={(e: any) => { setNewCategory(e.target.value); }} className="categoriesList">
              </input> {existName ? <div className="errorExist">
                This category already exist , Please select another name.
              </div> : null}</> : null}
            {filteredCategories && filteredCategories.length > 0 ? filteredCategories.map((category: ICategory) => {
              var count = category.Count
              return <button disabled={count >= 100 || createNew} onClick={(e: any) => onClickCategory(e, category.Id)} className={category.isSelected ? "categoriesList selectedCategory" : "categoriesList"}>
                {category.Label + " (" + category.Count + ")"}
              </button>
            }) : null}
          </div>
        </div>
      </ModalBase>
    </div>
  );
};
