import React, { useState, useEffect, useRef } from "react";
import styles from "./knowledgeItemHeader.module.scss";
import { ICategory, IKnowledgeItemHeader } from "../../../models/IShared";
import { SharedService } from "../../../services/SharedService";
import {
  Follower,
  CardData,
  PersonalCollection,
} from "../../../models/SharedModels";
import { Banner } from "../banner/banner";
import {
  Stack,
  Link,
  Text,
  IOverflowSetItemProps,
  OverflowSet,
  IDropdownOption,
  SearchBox,
  DirectionalHint,
} from "office-ui-fabric-react";
import { ScrollbarCustom } from "../scrollbarCustom/scrollbarCustom";
import { ModalBase } from "../../shared/modalBase/modalBase";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { Constants } from "../../../helpers/Constants";
import { DropdownBase } from "../dropdownBase/dropdownBase";
import { BreadcrumbBase } from "../breadcrumbBase/breadcrumbBase";
import { BreadcrumbCustom } from "../breadcrumbCustom/breadcrumbCustom";
import { BreadcrumbCustomModern } from "../breadcrumbCustomModern/breadcrumbCustomModern";
import { DropdownMultilevel } from "../dropdownMultiLevel/dropdownMultiLevel";
import { TooltipBase } from "../tooltipBase/tooltipBase";
import { useSelector } from "react-redux";
import LinkGenerator from "../linkGenerator/linkGenerator";
import "../../shared/sectionHeading/sectionHeading.scss";
import "./knowledgeItemHeader.module.scss";
import useWindowDimensions from "../../../hooks/UseWindowDimensions";

interface IKnowledgeItemHeaderState {
  followerCount: number;
  contentTypesMapping: any;
  knowledgeItemHeaderprops: IKnowledgeItemHeader;
  ifFollower: boolean;
  ifPin: boolean;
}
interface IKnowledgeItemHeaderProps {
  contentTypeName?: any;
  linktoItem?: any;
  img?: any;
  id?: string;
  itemTitle?: string;
  desc?: string;
  modified?: string;
  cardData?: CardData;
  isPracticeProfile?: boolean;
  tags?: any;
  geographyOptions?: any;
  breadrumbs?: any;
  analyticsData?: any;
  WhyImportant?: any;
  practiceTypeOptions?: any;
  isArchived?: boolean;
  followers?: any;
  onChangePracticeType?: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | any,
    index?: number
  ) => void | any;
  onOpenPanelClick?: (val: boolean) => void | any;
  bannerImage?: string;
  modernUi?: boolean;
}

const KnowledgeItemHeader: React.FC<IKnowledgeItemHeaderProps> = (props) => {
  const _sharedService = SharedService.GetInstance();
  const [state, setState] = useState<IKnowledgeItemHeaderState>();
  const [indexKey, setIndexKey] = useState<any>(0);
  const [whyImportant, setWhyImportant] = useState<any>(null);
  const [practiceType, setPracticeType] = useState<string>();
  const [isOverflow, setIsOverflow] = useState<boolean | null>(null);

  const [categories, setCategories] = useState<any>([]);
  const [filteredCategories, setFilteredCategories] = useState<any>([]);
  const [isOpenPinModal, setIsOpenPinModal] = useState<boolean>(false);
  const [cardToPin, setCardToPin] = useState<any>([]);
  const [cardsState, setCardsState] = useState<any>([]);
  const [pinListener, setPinListener] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [newCategory, setNewCategory] = useState<string>("");
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [existName, setExistName] = useState<boolean>(false);
  const descriptionElement = useRef(null);
  const _userNeeds: any = useSelector((s: any) => s.userNeedsReducer);
  const [showAddToSubscription, setShowAddToSubscription] = useState(false);
  const [titleIsEllipsis, setTitleIsEllipsis] = useState(false);
  const [abstractIsEllipsis, setAbstractIsEllipsis] = useState(false);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    let _sharedService = SharedService.GetInstance();
    let _configurations = _sharedService.Data.Configuration;
    var contentTypesMapping = _configurations
      ? JSON.parse(_configurations.ContentTypesMapping)
      : [];
    props.WhyImportant !== undefined
      ? setWhyImportant(
        CommonFunctions.replaceImageRelative(props.WhyImportant)
      )
      : _sharedService.Search.Get.GetHelpText(
        Constants.HelpTextKeys.WhyIsThisTopicImportant
      ).then((response) => {
        return setWhyImportant(
          CommonFunctions.replaceImageRelative(response)
        );
      }); //GET THE TEXT OF WHY IMPORTANT
    let knowledgeItemHeaderprops: IKnowledgeItemHeader = {
      metadataHeader:
        contentTypesMapping[
          props.contentTypeName
            ? props.contentTypeName
            : Constants.ContentTypeProps.ContentTypeName
        ].metadataHeader,
      actionButtons:
        contentTypesMapping[
          props.contentTypeName
            ? props.contentTypeName
            : Constants.ContentTypeProps.ContentTypeName
        ].actionButtons,
      breadcrumbs:
        contentTypesMapping[
          props.contentTypeName
            ? props.contentTypeName
            : Constants.ContentTypeProps.ContentTypeName
        ].breadcrumbs,
      helpModal:
        contentTypesMapping[
          props.contentTypeName
            ? props.contentTypeName
            : Constants.ContentTypeProps.ContentTypeName
        ].helpModal,
    };

    var ifFollowCt = _sharedService.Actions.Get.GetIfFollowCt(
      true,
      props.id ? props.id : "",
      props.contentTypeName ? props.contentTypeName : "Test"
    );
    var ifPinCt = _sharedService.Actions.Get.GetIfPinCt(props.linktoItem);
    Promise.all([ifFollowCt, ifPinCt]).then((values) => {
      var ifFollowData: any = values[0];
      var ifPinData: any = values[1];
      var overFlowItemsData = changeCardData(
        JSON.parse(
          JSON.stringify(
            knowledgeItemHeaderprops.actionButtons[0].overflowItems
          )
        ),
        knowledgeItemHeaderprops,
        false,
        ifFollowData,
        ifPinData
      );
      knowledgeItemHeaderprops.actionButtons[0].overflowItems =
        overFlowItemsData;
      knowledgeItemHeaderprops.actionButtons[0].overflowItems =
        knowledgeItemHeaderprops.actionButtons[0].overflowItems
          ? knowledgeItemHeaderprops.actionButtons[0].overflowItems.filter(
            (action: any) => {
              return action.key !== "EditItem" && action.key !== "DeleteItem";
            }
          )
          : knowledgeItemHeaderprops.actionButtons.overflowItems;
      if (
        (window.location.href.indexOf(Constants.Routing.CompareItems) !== -1 ||
          window.location.href.indexOf(Constants.Routing.TaxonomyManagment) !==
          -1) &&
        knowledgeItemHeaderprops.actionButtons &&
        knowledgeItemHeaderprops.actionButtons.length > 0 &&
        knowledgeItemHeaderprops.actionButtons[0].overflowItems &&
        knowledgeItemHeaderprops.actionButtons[0].overflowItems.length > 0
      ) {
        knowledgeItemHeaderprops.actionButtons[0].overflowItems =
          knowledgeItemHeaderprops.actionButtons[0].overflowItems.filter(
            (item: any) => {
              return item.key !== Constants.ActionsButtonsKeys.CopyLink;
            }
          );
      }
      setState({
        followerCount: props.followers ? props.followers : 0,
        contentTypesMapping: contentTypesMapping,
        knowledgeItemHeaderprops: knowledgeItemHeaderprops,
        ifFollower: ifFollowData,
        ifPin: ifPinData,
      });
    });

    if (props.contentTypeName !== Constants.ExplorePage && props.id)
      SharedService.GetInstance().Actions.Post.PostLastView(
        new Follower(
          props.id ? props.id : "",
          props.contentTypeName ? props.contentTypeName : "Test"
        )
      ); //WHEN GET IN TO PAGE INSERT TO LAST VIEWED DB
  }, []);

  useEffect(() => {
    let practiceType = CommonFunctions.URLSearch().get(
      Constants.QueryStringParameters.Practice
    );
    switch (practiceType) {
      case "i":
        setPracticeType(Constants.PracticeType.Industries);
        break;
      case "b":
        setPracticeType(Constants.PracticeType.Businesses);
        break;
      default:
        setPracticeType(Constants.PracticeType.Businesses);
        break;
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [CommonFunctions.URLSearch().get(Constants.QueryStringParameters.Practice),]);

  useEffect(() => {
    let element: any = descriptionElement.current;

    if (element && element.scrollHeight > element.clientHeight) {
      setIsOverflow(true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [state?.knowledgeItemHeaderprops]);

  const addToSubscription = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let sourceId = urlParams.get("id") || "";
    _sharedService.Subscription.Get.AddFollowToSubscription(sourceId);
    setShowAddToSubscription(false);
  };

  const changeCardData = (
    overflowItems: any,
    card: any,
    ifFeatured: boolean,
    ifFollow: boolean,
    ifPin: boolean
  ) => {
    var overFlowItemsNew = overflowItems
      .map((overflowItem: any) => {
        let overFlowItem1 = JSON.parse(JSON.stringify(overflowItem));
        if (overflowItem.key !== Constants.ActionsButtonsKeys.MetaData) {
          switch (overflowItem.key) {
            case Constants.ActionsButtonsKeys.PinToCollection:
              if (ifPin) {
                overFlowItem1.name =
                  SharedService.GetInstance().Data.Labels[
                  Constants.ActionsButtonsNames.unPin
                  ];
              } else {
                overFlowItem1.name =
                  SharedService.GetInstance().Data.Labels[
                  Constants.ActionsButtonsNames.pin_to_Collection
                  ];
              }
              return overFlowItem1;
            case Constants.ActionsButtonsKeys.Follow:
              if (ifFollow) {
                overFlowItem1.name =
                  SharedService.GetInstance().Data.Labels[
                  Constants.ActionsButtonsNames.unFollow
                  ];
                overFlowItem1.iconProps.className = "icon-kx-minus";
              } else {
                overFlowItem1.name =
                  SharedService.GetInstance().Data.Labels[
                  Constants.ActionsButtonsNames.follow
                  ];
              }
              return overFlowItem1;
            case Constants.ActionsButtonsKeys.CopyLink:
              overFlowItem1.name =
                SharedService.GetInstance().Data.Labels[
                Constants.ActionsButtonsNames.copy_Link
                ];
              return overFlowItem1;
            default:
              return overFlowItem1;
          }
        }
      })
      .filter((data: any) => data !== undefined);
    return overFlowItemsNew;
  };

  const onRenderItem = (
    item: IOverflowSetItemProps,
    cardData: any
  ): JSX.Element => {
    let analyticsAttribute = "";
    let linkText = "";

    switch (item.name) {
      case SharedService.GetInstance().Data.Labels[
        Constants.ActionsButtonsNames.pin_to_Collection
      ]:
        analyticsAttribute = "Pin";
        linkText =
          SharedService.GetInstance().Data.Labels[
          Constants.ActionsButtonsNames.pin_to_Collection
          ];
        break;

      case SharedService.GetInstance().Data.Labels[
        Constants.ActionsButtonsNames.unPin
      ]:
        analyticsAttribute = "Unpin";
        linkText =
          SharedService.GetInstance().Data.Labels[
          Constants.ActionsButtonsNames.unPin
          ];
        break;

      case SharedService.GetInstance().Data.Labels[
        Constants.ActionsButtonsNames.copy_Link
      ]:
        analyticsAttribute = "Copy";
        linkText =
          SharedService.GetInstance().Data.Labels[
          Constants.ActionsButtonsNames.copy_Link
          ];
        break;

      case SharedService.GetInstance().Data.Labels[
        Constants.ActionsButtonsNames.copied
      ]:
        linkText =
          SharedService.GetInstance().Data.Labels[
          Constants.ActionsButtonsNames.copied
          ];
        break;

      case SharedService.GetInstance().Data.Labels[
        Constants.ActionsButtonsNames.follow
      ]:
        analyticsAttribute = "Follow";
        linkText =
          SharedService.GetInstance().Data.Labels[
          Constants.ActionsButtonsNames.follow
          ];
        break;

      case SharedService.GetInstance().Data.Labels[
        Constants.ActionsButtonsNames.unFollow
      ]:
        analyticsAttribute = "Unfollow";
        linkText =
          SharedService.GetInstance().Data.Labels[
          Constants.ActionsButtonsNames.unFollow
          ];
        break;

      default:
        break;
    }

    return (
      <div>
        <Link
          digitaldata-kiph={analyticsAttribute}
          role="menuitem"
          onClick={(e: any) => {
            switch (item.key) {
              case Constants.ActionsButtonsKeys.PinToCollection:
                pinUnPin(
                  e,
                  item,
                  1,
                  props.contentTypeName,
                  props.desc ? props.desc : "",
                  props.img,
                  props.linktoItem,
                  props.itemTitle ? props.itemTitle : "",
                  props.cardData,
                  props.id,
                  props.contentTypeName
                );
                break;
              case Constants.ActionsButtonsKeys.Follow:
                followUnFllow(
                  e,
                  item,
                  1,
                  props.cardData ? props.cardData : cardData
                );
                break;

              case Constants.ActionsButtonsKeys.CopyLink:
                //copyLink(e, item);
                e.persist();
                setTimeout(() => copyLink(e, item, true), 3000);
                break;
              default:
                break;
            }
          }}
        >
          {item.iconProps && (
            <i className={item.iconProps.className + " icon-kx icons"}></i>
          )}
          {linkText}
        </Link>
      </div>
    );
  };

  const copyLink = (event: any, original: any, second?: boolean) => {
    if (state) {
      var card = state.knowledgeItemHeaderprops;
      original = CommonFunctions.copyLink(
        event,
        original,
        props.analyticsData,
        false,
        undefined,
        _userNeeds
      );
      var index = card.actionButtons[0].overflowItems.findIndex(
        (data: any) => data.key == Constants.ActionsButtonsKeys.CopyLink
      );
      card.actionButtons[0].overflowItems[index] = original;
      setState({
        followerCount: state.followerCount,
        contentTypesMapping: state.contentTypesMapping,
        knowledgeItemHeaderprops: card,
        ifFollower: state.ifFollower,
        ifPin: state.ifPin,
      });
      if (second) setIndexKey(indexKey + 2);
      else setIndexKey(indexKey + 1);
    }
  };

  const followUnFllow = (
    event: any,
    original: any,
    i: number,
    card: CardData
  ) => {
    if (state) {
      original = CommonFunctions.followUnFllow(
        event,
        original,
        props.contentTypeName ? props.contentTypeName : "Test",
        props.id ? props.id : "",
        props.analyticsData,
        undefined,
        false
      );
      var cardNew = state.knowledgeItemHeaderprops;
      var index = cardNew.actionButtons[0].overflowItems.findIndex(
        (data: any) => data.key === "Follow"
      );
      cardNew.actionButtons[0].overflowItems[index] = original;
      setState({
        followerCount: state.followerCount,
        contentTypesMapping: state.contentTypesMapping,
        knowledgeItemHeaderprops: cardNew,
        ifFollower: !state.ifFollower,
        ifPin: state.ifPin,
      });
      setIndexKey(indexKey + 1);

      setShowAddToSubscription(
        original.key === Constants.LabelKeys.Follow && state.ifFollower === false
      );
    }
  };

  const pinUnPin = async (
    event: any,
    original: any,
    i: number,
    ctName: string,
    desc: string,
    img: string,
    linkToItem: string,
    title: string,
    card: any,
    id?: string,
    contentType?: string
  ) => {
    if (state) {
      let cardItem: any = JSON.parse(JSON.stringify(props));
      cardItem.ifPinCard = false;
      original = await CommonFunctions.pinUnPin(
        event,
        original,
        cardItem,
        openModalAndGetCategories,
        false
      );
      var cardNew = state.knowledgeItemHeaderprops;
      var index = cardNew.actionButtons[0].overflowItems.findIndex(
        (data: any) => data.key === "PinToCollection"
      );
      cardNew.actionButtons[0].overflowItems[index] = original;
      setState({
        followerCount: state.followerCount,
        contentTypesMapping: state.contentTypesMapping,
        knowledgeItemHeaderprops: cardNew,
        ifFollower: state.ifFollower,
        ifPin: state.ifPin,
      });
      setIndexKey(indexKey + 1);
    }
  };
  const openModalAndGetCategories = (
    original: any,
    card: any,
    doTracking: any
  ) => {
    return new Promise((resolve, reject) => {
      if (
        card.linktoItem &&
        original &&
        original.name ===
        SharedService.GetInstance().Data.Labels[
        Constants.ActionsButtonsNames.pin_to_Collection
        ]
      ) {
        _sharedService.Actions.Get.GetPersonalCollectionCategories().then(
          (categories) => {
            var isSelected = false;
            categories.map(async (category: ICategory, index: number) => {
              if (category.Count < 100 && isSelected === false) {
                category.isSelected = true;
                isSelected = true;
              }
            });
            setCategories(categories);
            setFilteredCategories(categories);
            setIsOpenPinModal(true);
            setCardToPin(card);
            resolve(original);
          }
        );
      } else if (card.linktoItem && original) {
        SharedService.GetInstance()
          .Actions.Post.PinUnPin(
            new PersonalCollection(
              card.analyticsData.allData.source,
              "uncategorized",
              card.linktoItem,
              card.analyticsData.allData.Title,
              card.desc,
              card.img,
              "",
              ""
            )
          )
          .then((response) => {
            if (original) {
              original.name =
                SharedService.GetInstance().Data.Labels[
                Constants.ActionsButtonsNames.pin_to_Collection
                ];
              card.ifPinCard = false;
            }
            //IF FAILED CHANGE TO THE OPPOSITE OPTIONS :PIN TO UN PIN
            // Adobe Analytics
            if (doTracking)
              CommonFunctions.doTracking(Constants.MiniCardActions.Pin, card);
            resolve(original);
          });
      } else resolve(original);
    });
  };
  var searchTermChanged = (event: any) => {
    let _uInput = event && event.target && event.target.value;
    if (_uInput.length <= 255) {
      setSearchTerm(_uInput);
      var categoriesParse = JSON.parse(JSON.stringify(categories));
      var filteredCategories = categoriesParse.filter((category: ICategory) => {
        return category.Label.indexOf(_uInput) !== -1;
      });
      setFilteredCategories(filteredCategories);
    } else {
      setError("top 255 chars");
    }
  };
  var onClickCategory = (e: any, id: number) => {
    var list = document.getElementsByClassName("categoriesList");
    for (var i = 0; i < list.length; i++) {
      var el = list[i];
      if (el && el.classList.contains("selectedCategory")) {
        el.classList.remove("selectedCategory");
      }
    }
    setSelectedCategoryId(id);
    e.currentTarget.classList.add("selectedCategory");
  };
  var createNewCategoryAndPin = async (cardsState: any) => {
    if (newCategory) {
      var existName =
        await SharedService.GetInstance().Actions.Get.GetIfExistCategoryPersonalCollection(
          newCategory
        );
      if (!existName)
        SharedService.GetInstance()
          .Actions.Get.AddPersonalCollectionCategory(newCategory)
          .then((data: any) => {
            setSelectedCategoryId(data);
            savePinToCategory(cardsState, data);
          });
      else setExistName(true);
    } else {
      setExistName(true);
    }
  };
  var savePinToCategory = (
    cardsState: any,
    categoryId: any = selectedCategoryId
  ) => {
    var card = JSON.parse(JSON.stringify(props));
    if (card) {
      card.ifPinCard = false;
      SharedService.GetInstance()
        .Actions.Post.PinUnPin(
          new PersonalCollection(
            card.analyticsData.allData.source,
            "uncategorized",
            card.linktoItem,
            card.analyticsData.allData.Title,
            card.desc,
            card.bannerImage,
            card.id,
            card.contentTypeName,
            categoryId
          )
        )
        .then(async (response) => {
          if (
            card &&
            state &&
            state.knowledgeItemHeaderprops.actionButtons &&
            state.knowledgeItemHeaderprops.actionButtons.length &&
            state.knowledgeItemHeaderprops.actionButtons[0].overflowItems
          ) {
            state.knowledgeItemHeaderprops.actionButtons[0].overflowItems.forEach(
              (item: any) => {
                if (
                  item.name ===
                  SharedService.GetInstance().Data.Labels[
                  Constants.ActionsButtonsNames.pin_to_Collection
                  ]
                )
                  item.name =
                    SharedService.GetInstance().Data.Labels[
                    Constants.ActionsButtonsNames.unPin
                    ];
              }
            );
            //await checkIfPinnedByCurrentUser(card);
            setCardsState(cardsState);
            setPinListener(true);
            setIsOpenPinModal(false);
          }
        });
    }
  };

  const changeGeography = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | any,
    index?: number
  ) => {
    window.location.href = option.href;
  };

  const changePracticeType = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption | any,
    index?: number
  ) => {
    setPracticeType(option.key);
    if (props.onChangePracticeType !== undefined) {
      props.onChangePracticeType(event, option, index);
    }
  };

  const getDescriptionElement = () => {
    return (
      <div className="banner__text" ref={descriptionElement}>
        {getDescription()}
      </div>
    );
  };

  const getDescription = () => {
    return (state && state.knowledgeItemHeaderprops.metadataHeader.abstract) ||
      !props.cardData
      ? props.desc
      : "";
  };

  const checkIfTitleEllipsis = (
    e: React.MouseEvent<HTMLHeadingElement, MouseEvent>
  ) => {
    if (e.currentTarget.clientHeight + 1 < e.currentTarget.scrollHeight) {
      // This + 1 is because the line height in CSS. Keep it as need
      setTitleIsEllipsis(true);
    } else {
      setTitleIsEllipsis(false);
    }
  };

  const checkIfAbstractEllipsis = (
    e: React.MouseEvent<HTMLHeadingElement, MouseEvent>
  ) => {
    if (e.currentTarget.clientHeight < e.currentTarget.scrollHeight) {
      setAbstractIsEllipsis(true);
    } else {
      setAbstractIsEllipsis(false);
    }
  };

  const getHtmlWithoutImage = (html: string) => {
    const regex = /<img[^>]*>/g;
    const result = html.replace(regex, "");
    return result;
  };

  const isMobile = width <= 768;

  return (
    <div className={styles.KnowledgeItem}>
      {state ? (
        <>
          <Banner
            background={
              props.bannerImage !== undefined
                ? props.bannerImage
                : require("../../../assets/images/banner-home.jpg").default
            }
            className="banner--simple"
            modernUi={props.modernUi}
            isMobile={isMobile}
          >
            {props.isPracticeProfile ? (
              <>
                <Stack horizontal>
                  <Stack.Item grow={1}>
                    <BreadcrumbBase items={props.breadrumbs} />
                  </Stack.Item>
                </Stack>
                <Stack horizontal>
                  <Stack.Item grow={1}>
                    <BreadcrumbCustom
                      items={[
                        {
                          text: SharedService.GetInstance().Data.Labels[
                            Constants.LabelKeys.PracticeProfileLabel
                          ],
                          key: "PracticeProfileLabelKey",
                          href: window.location.href,
                        },
                      ]}
                    />
                  </Stack.Item>
                </Stack>
              </>
            ) : (
              <>
                {props.modernUi ? (
                  <Stack horizontal>
                    {props.tags && props.tags.length > 0 ? (
                      <Stack.Item
                        grow={1}
                        className="breadcrumb-modern-ui-wrapper"
                      >
                        <BreadcrumbCustomModern item={props.tags} />
                        {state.knowledgeItemHeaderprops.metadataHeader
                          .modified && (
                            <div className="breadcrumb-modern-ui-date">
                              {state.knowledgeItemHeaderprops.metadataHeader
                                .modified
                                ? CommonFunctions.DateFormatter(
                                  new Date(props.modified ? props.modified : "")
                                )
                                : ""}
                            </div>
                          )}
                      </Stack.Item>
                    ) : (
                      <Stack.Item grow={1}></Stack.Item>
                    )}
                    {!isMobile &&
                      ((props.practiceTypeOptions !== undefined &&
                        props.practiceTypeOptions.length > 0) ||
                        (state.knowledgeItemHeaderprops.actionButtons &&
                          state.knowledgeItemHeaderprops.actionButtons[0] &&
                          state.knowledgeItemHeaderprops.actionButtons[0]
                            .overflowItems.length > 0)) && (
                        <Stack.Item className="ms-Grid-col ms-sm12 ms-xl6 right-aligned-content-wrapper">
                          <Stack horizontal className="right-aligned-content">
                            <OverflowSet
                              className="new-ms-OverflowSet"
                              aria-label={
                                SharedService.GetInstance().Data.Labels[
                                Constants.LabelKeys.ContextualMenuLabel
                                ]
                              }
                              role="menubar"
                              key={indexKey}
                              items={
                                state.knowledgeItemHeaderprops.actionButtons[0]
                                  .overflowItems
                              }
                              onRenderOverflowButton={() => null}
                              onRenderItem={(item: any) =>
                                onRenderItem(
                                  item,
                                  state.knowledgeItemHeaderprops.metadataHeader
                                )
                              }
                              doNotContainWithinFocusZone={true}
                            />
                          </Stack>
                        </Stack.Item>
                      )}
                  </Stack>
                ) : (
                  <Stack horizontal>
                    {props.tags && props.tags.length > 0 ? (
                      <Stack.Item grow={1}>
                        <BreadcrumbCustom items={props.tags} />
                      </Stack.Item>
                    ) : (
                      <Stack.Item grow={1}></Stack.Item>
                    )}
                  </Stack>
                )}
              </>
            )}
            <Stack horizontal className={"banner__heading banner__heading--lg"}>
              {props.modernUi ? (
                <>
                  <Stack.Item grow={1} align="center">
                    <div className="banner-header-title-wrapper">
                      <TooltipBase
                        content={titleIsEllipsis ? props.itemTitle : null}
                        wrapperClassName={"modern-ui-tooltip-wrapper"}
                        directionalHint={DirectionalHint.bottomCenter}
                      >
                        <h1
                          className="bannerHeader"
                          onMouseEnter={(e) => checkIfTitleEllipsis(e)}
                        >
                          {props.itemTitle}
                        </h1>
                      </TooltipBase>
                    </div>
                    <div className="banner-header-abstract-wrapper">
                      <TooltipBase
                        content={abstractIsEllipsis ? getDescription() : null}
                        wrapperClassName={"modern-ui-tooltip-wrapper"}
                        directionalHint={DirectionalHint.bottomCenter}
                      >
                        <div
                          className="banner__text"
                          ref={descriptionElement}
                          onMouseEnter={(e) => checkIfAbstractEllipsis(e)}
                        >
                          {getDescription()}
                        </div>
                      </TooltipBase>
                    </div>
                    {((props.WhyImportant &&
                      props.WhyImportant.trim().length > 0) ||
                      (whyImportant &&
                        whyImportant[0] &&
                        whyImportant[0].dkxDescription)) && (
                        <>
                          <span
                            className="banner-description-collection"
                            dangerouslySetInnerHTML={{
                              __html: props.WhyImportant
                                ? getHtmlWithoutImage(whyImportant)
                                : whyImportant[0].dkxDescription,
                            }}
                          ></span>
                          <ModalBase
                            analyticsType="Topic Importance – Read More"
                            button={{
                              text: SharedService.GetInstance().Data.Labels[
                                Constants.LabelKeys.OkLabel
                              ],
                            }}
                            title={
                              SharedService.GetInstance().Data.Labels[
                              Constants.LabelKeys
                                .practiceProfileWhyTopicImportant
                              ]
                            }
                            className="modern-ui-banner-dialog"
                            opener={`<p class="banner-description-collection-opener">${SharedService.GetInstance().Data.Labels[
                              Constants.LabelKeys.DescriptionReadMore
                            ]
                              }</p>`}
                            openerClass="banner__link"
                          >
                            <div className="modal__body">
                              <ScrollbarCustom>
                                {whyImportant ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: props.WhyImportant
                                        ? whyImportant
                                        : whyImportant[0].dkxDescription,
                                    }}
                                  ></span>
                                ) : null}
                              </ScrollbarCustom>
                            </div>
                          </ModalBase>
                        </>
                      )}
                  </Stack.Item>
                  {!isMobile && (
                    <div
                      digitaldata-rightrail="Expand right rail"
                      className="find-more-resources-button-wrapper"
                      onClick={() => {
                        props.onOpenPanelClick && props.onOpenPanelClick(true);
                      }}
                    >
                      <i className="icon-kx-chevron-left" />
                      <div className="find-more-resources-button">
                        {
                          SharedService.GetInstance().Data.Labels[
                          Constants.LabelKeys.RHRbtn
                          ]
                        }
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Stack.Item grow={1} align="center">
                    <h1 className="bannerHeader">{props.itemTitle}</h1>
                    <Text block>
                      {isOverflow ? (
                        <TooltipBase content={getDescription()}>
                          {getDescriptionElement()}
                        </TooltipBase>
                      ) : (
                        getDescriptionElement()
                      )}
                    </Text>
                  </Stack.Item>
                  {state.knowledgeItemHeaderprops.helpModal &&
                    !props.isPracticeProfile && (
                      <Stack.Item
                        align="center"
                        shrink={0}
                        className="hiddenDownTablet"
                      >
                        <ModalBase
                          analyticsType="Why is this topic important"
                          button={{
                            text: SharedService.GetInstance().Data.Labels[
                              Constants.LabelKeys.OkLabel
                            ],
                          }}
                          title={
                            SharedService.GetInstance().Data.Labels[
                            Constants.LabelKeys
                              .practiceProfileWhyTopicImportant
                            ]
                          }
                          opener={
                            '<i class="icon-kx-question-circle"></i> <span>' +
                            SharedService.GetInstance().Data.Labels[
                            Constants.LabelKeys
                              .practiceProfileWhyTopicImportant
                            ] +
                            "</span>"
                          }
                          openerClass="banner__link"
                        >
                          <div className="modal__body">
                            <ScrollbarCustom>
                              {whyImportant ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: props.WhyImportant
                                      ? whyImportant
                                      : whyImportant[0].dkxDescription,
                                  }}
                                ></span>
                              ) : null}
                            </ScrollbarCustom>
                          </div>
                        </ModalBase>
                      </Stack.Item>
                    )}
                </>
              )}
            </Stack>
            {(isMobile || !props.modernUi) &&
              (props.isPracticeProfile ||
                (props.practiceTypeOptions !== undefined &&
                  props.practiceTypeOptions.length > 0) ||
                (state.knowledgeItemHeaderprops.actionButtons &&
                  state.knowledgeItemHeaderprops.actionButtons[0] &&
                  state.knowledgeItemHeaderprops.actionButtons[0].overflowItems
                    .length > 0)) ? (
              <Stack horizontal wrap className="banner__footer ms-Grid-row">
                {!isMobile && (
                  <Stack.Item
                    grow={1}
                    align="center"
                    className="ms-Grid-col ms-sm12 ms-xl6"
                  >
                    {!props.isPracticeProfile &&
                      (state.knowledgeItemHeaderprops.metadataHeader.followers ||
                        state.knowledgeItemHeaderprops.metadataHeader
                          .modified) ? (
                      <div className="footer-meta">
                        <ul>
                          <li>
                            {state &&
                              state.knowledgeItemHeaderprops.metadataHeader
                                .followers
                              ? state.followerCount + " followers"
                              : null}
                          </li>
                          <li>
                            {state.knowledgeItemHeaderprops.metadataHeader
                              .modified
                              ? CommonFunctions.DateFormatter(
                                new Date(props.modified ? props.modified : "")
                              )
                              : ""}
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Stack.Item>
                )}
                <Stack.Item className="ms-Grid-col ms-sm12 ms-xl6">
                  <Stack horizontal className="right-aligned-content">
                    <OverflowSet
                      className="new-ms-OverflowSet"
                      aria-label={
                        SharedService.GetInstance().Data.Labels[
                        Constants.LabelKeys.ContextualMenuLabel
                        ]
                      }
                      role="menubar"
                      key={indexKey}
                      items={
                        state.knowledgeItemHeaderprops.actionButtons[0]
                          .overflowItems
                      }
                      onRenderOverflowButton={() => null}
                      onRenderItem={(item: any) =>
                        onRenderItem(
                          item,
                          state.knowledgeItemHeaderprops.metadataHeader
                        )
                      }
                      doNotContainWithinFocusZone={true}
                    />
                    {props.practiceTypeOptions !== undefined &&
                      props.practiceTypeOptions.length > 0 ? (
                      <DropdownBase
                        options={props.practiceTypeOptions}
                        onChange={changePracticeType}
                        selectedKey={practiceType}
                      />
                    ) : (
                      <></>
                    )}

                    {props.isPracticeProfile ? (
                      <DropdownMultilevel
                        data={props.geographyOptions}
                        labelIcon="icon-kx-change-geo"
                        onChange={changeGeography}
                      />
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Stack.Item>
              </Stack>
            ) : (
              <></>
            )}

            {!props.modernUi && props.isArchived && (
              <Stack
                horizontal
                wrap
                className="banner__archivalMessageContainer ms-Grid-row"
              >
                <Stack.Item
                  grow={1}
                  align="center"
                  className="ms-Grid-col ms-sm12 ms-xl6"
                >
                  <Text
                    className="banner__archivalMessageText"
                    variant="large"
                    block
                  >
                    {
                      <LinkGenerator
                        linkTemplateText={
                          SharedService.GetInstance().Data.Labels[
                          Constants.LabelKeys.ArchiveItemMessage
                          ]
                        }
                      ></LinkGenerator>
                    }
                  </Text>
                </Stack.Item>
              </Stack>
            )}
            {props.modernUi && isMobile && (
              <div
                digitaldata-rightrail="Expand right rail"
                className="find-more-resources-button-wrapper"
                onClick={() => {
                  props.onOpenPanelClick && props.onOpenPanelClick(true);
                }}
              >
                <div className="find-more-resources-button">
                  {
                    _sharedService.Data.Labels[
                    Constants.LabelKeys.CollectionBannerFindMoreBtn
                    ]
                  }
                </div>
                <i className="icon-kx-chevron-right" />
              </div>
            )}
          </Banner>
          {props.modernUi && props.isArchived && (
            <div className="archival-message-wrapper">
              <div className="ms-Grid">
                <LinkGenerator
                  linkTemplateText={
                    SharedService.GetInstance().Data.Labels[
                    Constants.LabelKeys.ArchiveItemMessage
                    ]
                  }
                ></LinkGenerator>
              </div>
            </div>
          )}
        </>
      ) : null}
      <ModalBase
        onHideModal={() => {
          setIsOpenPinModal(false);
          setCreateNew(false);
          setExistName(false);
        }}
        isTwoButtons={true}
        button={{
          text: SharedService.GetInstance().Data.Labels[
            Constants.LabelKeys.CancelCapitalButton
          ],
          function: () => {
            setIsOpenPinModal(false);
            setCreateNew(false);
            setExistName(false);
          },
        }}
        SecondButton={{
          text: SharedService.GetInstance().Data.Labels[
            Constants.LabelKeys.SaveCapitalButton
          ],
          function: async () => {
            if (createNew) {
              createNewCategoryAndPin(cardsState);
            } else {
              savePinToCategory(cardsState);
            }
          },
          noHideModal: true,
        }}
        title={"Pin To"}
        className="categoryModal"
        isOpen={isOpenPinModal}
      >
        <div style={{ width: "100%" }}>
          <div className="moreThan100">
            {
              SharedService.GetInstance().Data.Labels[
              Constants.LabelKeys.PinNewItemPopUp
              ]
            }
          </div>
          <div style={{ display: "flex" }}>
            <SearchBox
              className={"search-default search--lg newCategory"}
              onChange={searchTermChanged}
              defaultValue={searchTerm}
              value={searchTerm}
              placeholder={"Search Here"}
              disableAnimation
            />
          </div>
          <button
            className="moveButtonCreate"
            onClick={async (e) => {
              var existNameDefault = true;
              var index = 0;
              var name = "Category 0";
              while (existNameDefault === true) {
                index++;
                existNameDefault =
                  await SharedService.GetInstance().Actions.Get.GetIfExistCategoryPersonalCollection(
                    name + index
                  );
              }
              setNewCategory(name + index);
              var list = document.getElementsByClassName("categoriesList");
              for (var i = 0; i < list.length; i++) {
                var el = list[i];
                if (el && el.classList.contains("selectedCategory")) {
                  el.classList.remove("selectedCategory");
                }
              }
              setCreateNew(true);
            }}
          >
            <i className="icon-kx-plus" style={{ paddingRight: "3px" }} />
            Create new Category
          </button>

          <div className="categorieslistAll">
            {createNew ? (
              <>
                <input
                  maxLength={255}
                  value={newCategory}
                  onChange={(e: any) => {
                    setNewCategory(e.target.value);
                  }}
                  className="categoriesList"
                ></input>{" "}
                {existName ? (
                  <div className="errorExist">
                    This category already exist , Please select another name.
                  </div>
                ) : null}
              </>
            ) : null}
            {filteredCategories && filteredCategories.length > 0
              ? filteredCategories.map((category: ICategory) => {
                var count = category.Count;
                return (
                  <button
                    disabled={count >= 100 || createNew}
                    onClick={(e: any) => onClickCategory(e, category.Id)}
                    className={
                      category.isSelected
                        ? "categoriesList selectedCategory"
                        : "categoriesList"
                    }
                  >
                    {category.Label + " (" + category.Count + ")"}
                  </button>
                );
              })
              : null}
          </div>
        </div>
      </ModalBase>

      {showAddToSubscription && (
        <ModalBase
          onHideModal={() => {
            setShowAddToSubscription(false);
          }}
          isOpen={true}
          isTwoButtons={true}
          withCancelBtn={true}
          SecondButton={{
            text: _sharedService.Data.Labels[
              Constants.LabelKeys.YesButtonLabel
            ],
            function: () => {
              addToSubscription();
            },
          }}
          button={{
            text: _sharedService.Data.Labels[Constants.LabelKeys.NoButtonLabel],
            function: () => {
              setShowAddToSubscription(false);
            },
          }}
          title={
            _sharedService.Data.Labels[
            Constants.LabelKeys.CollectionAddedToList
            ]
          }
        >
          <span className="text-center">
            <LinkGenerator
              linkTemplateText={
                _sharedService.Data.Labels[
                Constants.LabelKeys.CollectionAddToSubscription
                ]
              }
            ></LinkGenerator>
          </span>
        </ModalBase>
      )}
    </div>
  );
};
export default KnowledgeItemHeader;
