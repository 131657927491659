import { ADD_DATA_TO_INTERESTS, manageMyInterestsState, ManageMyInterestsTypeActions } from '../types'

const initialState: manageMyInterestsState = {
  interests: []
}


export function manageMyInterestsReducer( state = initialState, action: ManageMyInterestsTypeActions) {
  switch (action.type) {
    case ADD_DATA_TO_INTERESTS:
    return {
        ...state,
        interests: action.payload
        }

    default:
      return state
  }
}
