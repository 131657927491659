import {
  IConversationHistoryResponseObj,
  IConversationKxHistoryResponseObj,
  IConversationMessage,
} from "../../models/KxPromptModels";
import {
  ADD_PROMPT_MESSAGE,
  CLEAR_PROMPT_MESSAGE,
  GET_ALL_CONVERSATION_HISTORY_REQUEST,
  GET_ALL_CONVERSATION_HISTORY_SUCCESS,
  GET_ALL_CONVERSATION_HISTORY_ERROR,
  MessageSender,
  REMOVE_LAST_SERVER_PROMPT_MESSAGE,
  GET_KX_RESULTS_HISTORY_ERROR,
  GET_KX_RESULTS_HISTORY_REQUEST,
  GET_KX_RESULTS_HISTORY_SUCCESS,
  RESET_SIDEBAR_HISTORY_ITEM_ACTIVE,
  SET_SIDEBAR_HISTORY_ITEM_ACTIVE,
  LOAD_CONVERSATION_FROM_HISTORY,
  SET_FOLLOWUP_PROMPTS,
  CLEAR_FOLLOWUP_PROMPTS,
  RESET_ALL_CONVERSATION_HISTORY_ERROR,
  RESET_KX_RESULTS_HISTORY_ERROR,
} from "../types";
import { ConversationActionTypes } from "../actions/promptConversationAction";

export interface IConversationList {
  messages: Array<IConversationMessage>;
  followUpPrompts: Array<string>;
  conversationHistory: Array<IConversationHistoryResponseObj>;
  conversationHistoryLoading: boolean;
  error: string | undefined;
  kxResultsFromHistory: Array<IConversationKxHistoryResponseObj>;
  kxResultsFromHistoryLoading: boolean;
  kxResultsFromHistoryError: string | undefined;
  sidebarHistoryItemActiveKey: string | undefined;
}

const initialState: IConversationList = {
  messages: [] as IConversationMessage[],
  followUpPrompts: [] as Array<string>,
  conversationHistory: [] as IConversationHistoryResponseObj[],
  conversationHistoryLoading: false,
  error: undefined,
  kxResultsFromHistory: [] as Array<IConversationKxHistoryResponseObj>,
  kxResultsFromHistoryLoading: false,
  kxResultsFromHistoryError: undefined,
  sidebarHistoryItemActiveKey: undefined,
};

const removeLastServerMessage = (messages: IConversationMessage[]) => {
  if (
    messages.length > 1 &&
    messages[messages.length - 1].sender === MessageSender.SERVER
  )
    messages.pop();
  return messages;
};

const promptConversationReducer = (
  state = initialState,
  action: ConversationActionTypes
): IConversationList => {
  switch (action.type) {
    case ADD_PROMPT_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case CLEAR_PROMPT_MESSAGE:
      return {
        ...state,
        messages: [] as IConversationMessage[],
        followUpPrompts: [],
      };
    case SET_FOLLOWUP_PROMPTS:
      return {
        ...state,
        followUpPrompts: action.payload,
      };
    case CLEAR_FOLLOWUP_PROMPTS:
      return {
        ...state,
        followUpPrompts: [],
      };
    case REMOVE_LAST_SERVER_PROMPT_MESSAGE:
      return {
        ...state,
        messages: removeLastServerMessage(state.messages),
      };
    case SET_SIDEBAR_HISTORY_ITEM_ACTIVE:
      return {
        ...state,
        sidebarHistoryItemActiveKey: action.payload,
      };
    case RESET_SIDEBAR_HISTORY_ITEM_ACTIVE:
      return {
        ...state,
        sidebarHistoryItemActiveKey: undefined,
      };
    case LOAD_CONVERSATION_FROM_HISTORY:
      return {
        ...state,
        messages: action.payload,
      };
    case GET_ALL_CONVERSATION_HISTORY_REQUEST:
      return {
        ...state,
        conversationHistoryLoading: true,
        conversationHistory: [] as IConversationHistoryResponseObj[],
        error: undefined,
      };
    case GET_ALL_CONVERSATION_HISTORY_SUCCESS:
      return {
        ...state,
        conversationHistoryLoading: false,
        conversationHistory: action.payload,
        error: undefined,
      };
    case GET_ALL_CONVERSATION_HISTORY_ERROR:
      return {
        ...state,
        conversationHistoryLoading: false,
        conversationHistory: [] as IConversationHistoryResponseObj[],
        error: action.payload,
      };
    case RESET_ALL_CONVERSATION_HISTORY_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case GET_KX_RESULTS_HISTORY_REQUEST:
      return {
        ...state,
        kxResultsFromHistoryLoading: true,
        kxResultsFromHistory: [] as Array<IConversationKxHistoryResponseObj>,
        kxResultsFromHistoryError: undefined,
      };
    case GET_KX_RESULTS_HISTORY_SUCCESS:
      return {
        ...state,
        kxResultsFromHistoryLoading: false,
        kxResultsFromHistory: action.payload,
        followUpPrompts:
          action.payload[action.payload.length - 1].followupprompts,
        kxResultsFromHistoryError: undefined,
      };
    case GET_KX_RESULTS_HISTORY_ERROR:
      return {
        ...state,
        kxResultsFromHistoryLoading: false,
        kxResultsFromHistory: [] as Array<IConversationKxHistoryResponseObj>,
        kxResultsFromHistoryError: action.payload,
      };
    case RESET_KX_RESULTS_HISTORY_ERROR:
      return {
        ...state,
        kxResultsFromHistoryError: undefined,
      };
    default:
      return state;
  }
};

export default promptConversationReducer;
