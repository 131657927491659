import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link as RouteLink, Redirect } from "react-router-dom";
import { HeaderNav } from "../headerNav/headerNav";
import { ContextMenu } from "../contextMenu/contextMenu";
import { DropdownBase } from "../dropdownBase/dropdownBase";
import { Constants } from "../../../helpers/Constants";
import logo from "../../../assets/images/header-logo.svg";
import "./header.scss";
import { SharedService } from "../../../services/SharedService";
import SearchBoxGlobal from "../searchBoxGlobal/searchBoxGlobal";
import UserNeeds from "../userNeeds/userNeeds";
import { Events, NSTags } from "../../../models/IShared";
import { useSelector, useDispatch } from "react-redux";
import { KxState, MenuElement } from "../../../store/types";
import { setMenuElements } from "../../../store/actions/menuActions";
import Notifications from "./components/notifications";
import { MessageBarDefault } from "../../shared/messageBar/messageBar";
import UserProfileDropdown from "./components/userProfileDropdown";
import setRefiners from "../../../store/actions/refinersActions";
import setRefinersOld from "../../../store/actions/refinersOldActions";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { MessageBarError } from "../../shared/errorMessageBar/errorMessageBar";
import { setLoadTagsDataAction } from "../../../store/actions/TagsDataAction";
import { setGetAllDataAction } from "../../../store/actions/AllDataAction";
import { ITagsData } from "../../../models/ITags";
import { setNSTagsDataAction } from "../../../store/actions/NSTagsDataAction";

const hostName: string | undefined =
  window &&
  window.location &&
  window.location.host &&
  window.location.host.split(".").shift();

const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      var element = document.getElementsByClassName('ms-Layer--fixed');
      if (!ref.current || ref.current.contains(event.target) || (element && element.length > 0 && element[0].contains(event.target))) {
        return;
      }

      handler(event);
    };

    document.addEventListener(Events.MouseDown, listener);
    document.addEventListener(Events.TouchStart, listener);

    return () => {
      document.removeEventListener(Events.MouseDown, listener);
      document.removeEventListener(Events.TouchStart, listener);
    };
  }, [ref, handler]);
};
interface IProps {
  setShowCompareArea: () => void;
}

export const Header: React.FunctionComponent<IProps> = (props: IProps) => {
  var _sharedService = SharedService.GetInstance();

  const ref = useRef<any>();
  const [isOpened, setOpenedState] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isHomePageVar, setIsHomePage] = useState(false);
  const [menuItemsDash, setMenuItemsDash] = useState<any>(null);
  const [menuItemsUser, setMenuItemsUser] = useState<any>(null);
  const menuElementsState = useSelector((state: KxState) => state.menu);
  const [dropdownOptionsLang, setDropdownOptionsLang] = useState<any>([]);
  const [tagsDataValue, setTagsDataValue] = useState<any>([]);
  const [getAllDataValue, setGetAllDataValue] = useState<any>([]);

  const dispatch = useDispatch();

  const burgerClickHandle = () => {
    setOpenedState(!isOpened);
  };

  const burgerClose = () => {
    setOpenedState(false);
  };

  useOnClickOutside(ref, () => {
    if (isOpened) {
      burgerClose();
    }
  });

  useEffect(() => {
    let _configurations = _sharedService.Data.Configuration;
    var MenuElementsOptions: MenuElement[] = _configurations
      ? JSON.parse(_configurations.MenuElementsOptions)
      : [];
    var MenuItemsDash: any = _configurations
      ? JSON.parse(_configurations.MenuItemsDash)
      : null;
    dispatch(setMenuElements(MenuElementsOptions));
    setMenuItemsDash(MenuItemsDash);
    var MenuItemsUser: any = _configurations
      ? JSON.parse(_configurations.MenuItemsUser)
      : null;
    setMenuItemsUser(MenuItemsUser);
    setDropdownOptionsLang([
      {
        key: "EN",
        text: _sharedService.Data.Labels[Constants.LabelKeys.KeyEnglishUS],
      },
      {
        key: "ES",
        text: _sharedService.Data.Labels[Constants.LabelKeys.KeySpanishES],
      },
      {
        key: "FR",
        text: _sharedService.Data.Labels[Constants.LabelKeys.KeyFrenchFR],
      },
      {
        key: "ZH",
        text: _sharedService.Data.Labels[Constants.LabelKeys.KeyChineseZH],
      },
      {
        key: "JA",
        text: _sharedService.Data.Labels[Constants.LabelKeys.KeyJapaneseJA],
      },
    ]);
  }, []);
  
  useEffect(() => {
    setIsHomePage(isHomePage())
  }, [window.location.href])

  const isHomePage = (): boolean => {
    let url = window.location.href.substring(0, window.location.href.length - 1);
    let tempUrl = url;
    if (url.indexOf('?') !== -1) {
      let index = url.indexOf('?');
      tempUrl = url.substring(0, index);
    }

    if (tempUrl === window.location.origin || tempUrl === window.location.origin + "/") {
      dispatch(setRefiners([]));
      dispatch(setRefinersOld([]));
      return true;
    }
    return false;
  };

  return (
    <>
      <header className="header" ref={ref}>
        <div className="container">
          <div className="header-top d-flex">
            <button
              className={`nav-opener hiddenUpTablet ${isOpened ? "nav-is-opened" : ""
                }`}
              onClick={burgerClickHandle}
            >
              <span></span>
            </button>
            <RouteLink to="/" className="logo">
              <img
                src={logo}
                alt={
                  _sharedService.Data.Labels[Constants.LabelKeys.WebsiteName]
                }
              />
            </RouteLink>
            <SearchBoxGlobal
              isHomePage={isHomePage()}
              classSize="hiddenDownDesktop"
              inHeader={true}
              setShowCompareArea={props.setShowCompareArea}
            ></SearchBoxGlobal>
            {menuItemsDash ? (
              <div className="header-context hiddenDownTablet">
                <ContextMenu
                  items={menuItemsDash.items}
                  opener={menuItemsDash.opener}
                  customClass={menuItemsDash.customClass}
                  analyticsType={"wafflemenu"}
                />
                <Notifications className="header-contextual-menu"></Notifications>
                <UserProfileDropdown></UserProfileDropdown>
                {/* <ContextMenu
                  items={menuItemsUser.items}
                  opener={menuItemsUser.opener}
                  customClass={menuItemsUser.customClass}
                /> */}
              </div>
            ) : (
              ""
            )}
          </div>
         <div className="header-bottom d-flex">
            <div
              className={`header-nav-container ${isOpened ? "nav-is-opened" : ""
                }`}
            >
              {menuElementsState ? (
                <HeaderNav
                  navMainLinks={menuElementsState.menuElements}
                  handle={burgerClose}
                />
              ) : null}
              {menuItemsDash && menuItemsUser ? (
                <div className="mobile-nav-extra hiddenUpMobile">
                  <DropdownBase

                    selectedKey={_sharedService.lang}
                    options={dropdownOptionsLang}
                    onChange={(e, item) => {
                      SharedService.UpdateLabelsLang(
                        item ? item.key.toString() : "EN"
                      );
                    }}
                    labelIcon="icon-kx-language"
                  />
                  <ContextMenu
                    items={menuItemsDash.items}
                    opener={menuItemsDash.opener}
                    customClass={menuItemsDash.customClass}
                    handle={burgerClose}
                  />
                  <UserProfileDropdown
                    additionalClass="nav-user"
                    handle={burgerClose}
                  ></UserProfileDropdown>
                  {/* <ContextMenu
                    items={menuItemsUser.items}
                    opener={menuItemsUser.opener}
                    customClass={`${menuItemsUser.customClass} nav-user`}
                    handle={burgerClose}
                  /> */}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="header-bottom-dropdowns">
              <UserNeeds></UserNeeds>
              <SearchBoxGlobal
                isHomePage={false}
                classSize="hiddenUpTablet hiddenDownTablet"
                setShowCompareArea={props.setShowCompareArea}
              ></SearchBoxGlobal>
              {menuItemsDash && menuItemsUser ? (
                <div className="hiddenUpMobile d-flex">
                  {isHomePageVar ? null : <button id="searchButton" onClick={() => setIsSearchOpen(!isSearchOpen)} className="header-search-button hiddenUpMobile">
                    <i className="icon-kx-explore"></i>
                  </button>}

                  <Notifications
                    className={menuItemsDash.customClass}
                  ></Notifications>
                </div>
              ) : (
                ""
              )}
              <div className="hiddenDownTablet d-flex">
                <DropdownBase
                  selectedKey={CommonFunctions.URLSearch().get(Constants.QueryStringParameters.Lang) ? CommonFunctions.URLSearch().get(Constants.QueryStringParameters.Lang) : _sharedService.lang}
                  options={dropdownOptionsLang}
                  onChange={(e, item) => {
                    SharedService.UpdateLabelsLang(
                      item ? item.key.toString() : "EN"
                    );
                  }}
                  labelIcon="icon-kx-language"
                  className="hiddenDownTablet"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div>
        <MessageBarDefault />
      </div>
      <div>
        <MessageBarError />
      </div>
      {!isHomePageVar ?
        <div id="wrapperSearch" className={isSearchOpen ? "isOpen" : ""}>
          <SearchBoxGlobal
            isHomePage={false}
            classSize="hiddenUpTablet"
            setShowCompareArea={props.setShowCompareArea}
          ></SearchBoxGlobal>

        </div> : null}
    </>
  );
};
