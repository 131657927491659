import React, { useState, useEffect, useRef } from "react";
import { Card } from "@uifabric/react-cards";
import { Link, Stack } from "office-ui-fabric-react";
import { TooltipBase } from "../tooltipBase/tooltipBase";
import { OverflowSetBase } from "../overflowSetBase/overflowSetBase";
import { SwiperCustom } from "../swiperCustom/swiperCustom";
import "./cardBase.scss";
import { CardType, ICategory } from "../../../models/IShared";
import { SharedService } from "../../../services/SharedService";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { CardData, PersonalCollection } from "../../../models/SharedModels";
import { Constants } from "../../../helpers/Constants";
import { PeopleCardContent } from "../cardPeople/cardPeople";
import LinkGenerator from "../linkGenerator/linkGenerator";
import { IMiniCardTracker } from "../../../models/IAnalytics";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { CollectionYouFollowActions } from "../../../store/actions/CollectionYouFollowActions";
import { ADD_DATA_TO_COLLECTION, ADD_DATA_TO_DPN } from "../../../store/types";
import { DPNActions } from "../../../store/actions/DPNActions";
import { ImageCardContent } from "../cardImage/cardImage";
import starIcon from "../../../assets/images/starGreen.svg";
import { ModalBase } from "../modalBase/modalBase";

import { SearchBox } from "office-ui-fabric-react";
import { ShimmerWrapper } from "../shimmerWrapper/shimmerWrapper";

export interface CardBaseProps {
  slider?: boolean;
  collection: any[];
  isLoading: boolean;
  dataForShimmer: any;
  isHorizontal?: boolean;
  featured: boolean;
  modernUi?: boolean;
  archiveRemoval?: (cardData: CardData) => void;
  categoryMove?: (e: any, cardData: CardData) => void;
  chosenItems?: any[];
  openModalEdit?: any;
  explainData?: any;
  setShowSubscribe?: any;
  setActionsFlag?: any;
}

export interface con {
  condition: any;
  wrap(children: any): any;
  children: any;
}

export const CardBase: React.FunctionComponent<CardBaseProps> = (props) => {
  const [cardsState, setCardsState] = useState(props.collection);
  const ConditionalWrap = (con: con) =>
    con.condition ? con.wrap(con.children) : con.children;

  useEffect(() => {
    setCardsState(props.collection);
    props.setActionsFlag(props.isLoading);
  }, [props.collection]);

  // const actionDataForTabs = (
  //   event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  //   newValue?: any
  // ) => {
  //   props.setActions(newValue);
  // };

  return (
    <Stack horizontal wrap>
      <ConditionalWrap
        condition={props.slider}
        wrap={(children: any) => {
          return <SwiperCustom>{children}</SwiperCustom>;
        }}
      >
        {props.isLoading &&
          props.dataForShimmer?.map((data: any) => {
            return (
              <div className={props.modernUi ? "" : "ms-Grid-col"} style={{ padding: "0px" }}>
                <ShimmerWrapper
                  horizontal={props.isHorizontal}
                  featured={props.featured}
                  modernUi={props.modernUi}
                />
              </div>
            );
          })}
        {!props.isLoading &&
          cardsState.map((card: CardData, i: number) => {
            if (card.ctName == Constants.DPN)
              return <PeopleCardContent
                card={card} key={i}
                categoryMove={props.categoryMove}
                chosenItems={props.chosenItems}
                openModalEdit={props.openModalEdit} />;
            else if ((card.ctName == Constants.Images || card.allData.img_Id) && !card.IsRemoved)
              return <ImageCardContent
                card={card} key={i}
                categoryMove={props.categoryMove}
                chosenItems={props.chosenItems}
                openModalEdit={props.openModalEdit}
                explainData={props.explainData && i < props.explainData.length ? props.explainData[i] : {}} />;
            return (
              <CardBaseContent
                card={card}
                key={i}
                archiveRemoval={props.archiveRemoval}
                categoryMove={props.categoryMove}
                chosenItems={props.chosenItems}
                openModalEdit={props.openModalEdit}
                explainData={props.explainData && i < props.explainData.length ? props.explainData[i] : {}}
                setShowSubscribe={props.setShowSubscribe}
                modernUi={props.modernUi} />
            );
          })}
      </ConditionalWrap>
    </Stack>
  );
};

interface CardBaseContentProps {
  card: CardData;
  archiveRemoval?: (cardData: CardData) => void;
  categoryMove?: (e: any, cardData: CardData) => void;
  chosenItems?: any[];
  openModalEdit?: any;
  explainData?: any;
  setShowSubscribe?: any;
  modernUi?: boolean;
}

export const CardBaseContent: React.FunctionComponent<CardBaseContentProps> = (
  props
) => {
  let history = useHistory();
  const [metadataListView, setMetadataListView] = useState<any>(null);
  const dispatch = useDispatch();
  const titleElement = useRef(null);
  const cardDescriptionElement = useRef(null);
  const otherContentElement = useRef(null);
  const [isMenuOpened, setIsOpened] = useState(false);
  const [isDescriptionOverflow, setIsDescriptionOverflow] = useState(false);
  const [isTitleOverflow, setIsTitleOverflow] = useState(false);
  const [isOtherContentOverflow, setisOtherContentOverflow] = useState(false);
  const [isPromotedResult, setIsPromotedResult] = useState(props.card.allData.isPromotedResult);
  const _sharedService = SharedService.GetInstance();
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [categories, setCategories] = useState<any>([]);
  const [filteredCategories, setFilteredCategories] = useState<any>([]);
  const [isOpenPinModal, setIsOpenPinModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [newCategory, setNewCategory] = useState<string>("");
  const [existName, setExistName] = useState<boolean>(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);
  const [pinState, setPinState] = useState<boolean>(props.card.ifPinCard);

  const collectionListRef = useSelector(
    (state: any) => state.collectionReducer.cards
  );

  const DPNListRef = useSelector(
    (state: any) => state.dpnReducer.cards
  );

  var ctMapping: any;
  var tags: any[] = [];

  var createNewCategoryAndPin = async () => {
    if (newCategory) {
      var existName = await SharedService.GetInstance().Actions.Get.GetIfExistCategoryPersonalCollection(newCategory);
      if (!existName)
        SharedService.GetInstance().Actions.Get.AddPersonalCollectionCategory(newCategory).then((data: any) => {
          setSelectedCategoryId(data)
          savePinToCategory(data)
        })
      else setExistName(true)
    } else {
      setExistName(true)
    }

  }

  var onClickCategory = (e: any, id: number) => {
    var list = document.getElementsByClassName("categoriesList");
    for (var i = 0; i < list.length; i++) {
      var el = list[i]
      if (el && el.classList.contains("selectedCategory")) {
        el.classList.remove("selectedCategory");
      }
    }
    setSelectedCategoryId(id)
    e.currentTarget.classList.add("selectedCategory")
  }

  var savePinToCategory = (categoryId: any = selectedCategoryId) => {
    const category = categories.find((item: any) => item.Id === selectedCategoryId);
    SharedService.GetInstance().Actions.Post.PinUnPin(
      new PersonalCollection(
        props.card.allData.source,
        category ? category.CategoryName : "Uncategorized",
        props.card.linkToItem,
        props.card.allData.title,
        "",
        props.card.allData.Img,
        props.card.allData.id,
        props.card.allData.internalcontenttype,
        category ? category.Id : categoryId
      )
    )
      .then(async (response) => {
        if (response) {
          CommonFunctions.doTracking(Constants.MiniCardActions.Pin, props.card);
          setIsOpenPinModal(false);
          updatePin();
        }
      });
  }

  const updatePin = () => {
    setPinState(prev => !prev)
    if (pinState) {
      props.card.ifPinCard = false;
      setCreateNew(false);
    } else {
      props.card.ifPinCard = true;
    }
  }

  const openModalAndGetCategories = () => {
    if (!pinState) {
      _sharedService.Actions.Get.GetPersonalCollectionCategories().then((categories) => {
        var isSelected = false
        categories.map(async (category: ICategory, index: number) => {
          if (category.Count < 100 && isSelected == false) {
            category.isSelected = true
            isSelected = true;
          }
        })
        setCategories(categories)
        setFilteredCategories(categories)
        setIsOpenPinModal(true);
      })
    } else {
      SharedService.GetInstance().Actions.Post.PinUnPin(
        new PersonalCollection(
          props.card.allData?.source,
          "uncategorized",
          props.card.linkToItem,
          "",
          props.card.allData?.Img,
          "",
          "",
        )
      ).then((response) => {
        updatePin();
        CommonFunctions.doTracking(Constants.MiniCardActions.Unpin, props.card);
      });
    }
  }

  const trackerMiniCard = () => {
    let cardTracker: IMiniCardTracker = {} as IMiniCardTracker;
    let contentID =
      props.card.displayArea &&
        props.card.displayArea === Constants.DisplayAreas.Featured &&
        props.card.linkToItem.indexOf("id=") !== -1
        ? props.card.linkToItem.substring(
          props.card.linkToItem.indexOf("id=") + 3,
          props.card.linkToItem.length
        )
        : "";
    cardTracker.action = Constants.MiniCardActions.ViewFullContent;
    if (
      props.card.displayArea &&
      props.card.displayArea === Constants.DisplayAreas.Featured
    )
      cardTracker.contentID = contentID ? contentID : props.card.sourceId ? `${props.card.sourceId}` : "";
    else
      cardTracker.contentID = props.card.sourceId
        ? `${props.card.sourceId}`
        : "";
    cardTracker.displayArea = props.card.allData && props.card.allData.isPromotedResult ? Constants.DisplayAreas.PromotedResults : props.card.displayArea
      ? props.card.displayArea
      : "";
    cardTracker.isExternal = props.card.linkToItem
      ? props.card.linkToItem.indexOf(window.location.hostname) === -1
      : props.card.allData.IfExternal
        ? props.card.allData.IfExternal
        : true;
    cardTracker.position = props.card.position
      ? `${props.card.position}`
      : "";
    cardTracker.url = props.card.linkToItem;
    cardTracker.title = props.card.title.text;
    cardTracker.sourceSystem =
      props.card.allData.source || props.card.allData.Type;
    cardTracker.sourceContentType = props.card.allData.apptype
      ? props.card.allData.apptype
      : props.card.allData.internalcontenttype;
    cardTracker.isMiniCard = true;
    if (
      cardTracker.sourceContentType &&
      cardTracker.sourceContentType.indexOf(";") !== -1
    ) {
      cardTracker.sourceContentType = cardTracker.sourceContentType.substring(
        cardTracker.sourceContentType.lastIndexOf(";") + 1,
        cardTracker.sourceContentType.length
      );
    }

    if (
      cardTracker.sourceSystem &&
      cardTracker.sourceSystem.indexOf(";") !== -1
    ) {
      cardTracker.sourceSystem = "";
    }
    AnalyticsService.trackMiniCard(cardTracker);
  }

  const goToContent = (url: string, openInNewTab?: boolean) => {
    if (url) {
      trackerMiniCard();
      CommonFunctions.redirectLink(openInNewTab, url, history);
    }
  };

  useEffect(() => {
    let element1: any = titleElement.current;
    let element2: any = cardDescriptionElement.current;
    let element3: any = otherContentElement.current;

    if (element1 && element1.scrollHeight > element1.clientHeight + 1) {
      setIsTitleOverflow(true);
    }
    if (element2 && element2.scrollHeight > element2.clientHeight + 1) {
      setIsDescriptionOverflow(true);
    }
    if (element3 && element3.scrollHeight > element3.clientHeight + 1) {
      setisOtherContentOverflow(true);
    }

    let _configurations = SharedService.GetInstance().Data.Configuration;

    var metadataListView;
    let ctCorelationJson = SharedService.GetInstance().GetConfigurationMapping(
      props.card.allData
    );
    let configCT = JSON.parse(_configurations.ContentTypesMapping);

    ctMapping = configCT[ctCorelationJson.JsonMapping];
    if (ctMapping) {
      metadataListView = ctMapping.metadataListView;
    }
    setMetadataListView(metadataListView);

    ctMapping = configCT[ctCorelationJson.JsonMapping];
    if (props.card.overflowItemsData) {
      //insert the on click method
      var index =
        props.card.overflowItemsData[0] &&
        props.card.overflowItemsData[0].overflowItems.find(
          (item: any) => item.key == Constants.ActionsButtonsKeys.Follow
        );
      if (index)
        index.onClick = (event: any, original: any) => {
          if (props.card.allData.internalcontenttype == "Curated Collection") {
            var newCollection = CommonFunctions.saveCollection(
              props.card.allData,
              ctMapping,
              collectionListRef
            );
            setTimeout(() => {
              dispatch(
                CollectionYouFollowActions(
                  ADD_DATA_TO_COLLECTION,
                  newCollection
                )
              );
            }, 0);
          }
          if (props.card.allData.internalcontenttype == "DPN") {
            var newDPN = CommonFunctions.saveDPN(
              props.card.allData,
              ctMapping,
              DPNListRef
            );
            setTimeout(() => {
              dispatch(
                DPNActions(
                  ADD_DATA_TO_DPN,
                  newDPN
                )
              );
            }, 0);
          }
          var result = CommonFunctions.followUnFllow(
            event,
            original,
            props.card.allData.internalcontenttype,
            props.card.sourceId,
            props.card
          );

          props.setShowSubscribe(result.key == Constants.LabelKeys.Follow && result.name == Constants.LabelKeys.Unfollow, props.card.sourceId)
        };
      if (props.card.isHorizontal) {
        props.card.overflowItemsData = props.card.overflowItemsData
        props.card.overflowItemsData[0] &&
          props.card.overflowItemsData[0].overflowItems.filter(
            (item: any) => item.key !== Constants.ActionsButtonsKeys.PinToCollection
          )
      }
    }
  }, [collectionListRef]);

  const onShow = () => {
    setIsOpened(true);
  };

  const onHide = () => {
    setIsOpened(false);
  };

  const getTitleElement = (): JSX.Element => {
    return (
      <div ref={titleElement} className="card-base__text trim-2l">
        {props.card.title.icon && !props.card.IsRemoved && (
          <i className={props.card.title.icon}></i>
        )}
        {props.card.title.text && props.card.title.text != "" ? props.card.title.text : props.card.allData.title}
      </div>
    );
  };

  const getDescriptionElement = (): JSX.Element => {
    return (
      <div
        ref={cardDescriptionElement}
        className={
          props.card.size == "lg"
            ? "card-base__text_featured"
            : "card-base__text"
        }
      >
        {getTheRelevantValue(props.card.desc)}
      </div>
    );
  };

  const getHighlightedElement = (description: string, text: any, highlightedDes: any): JSX.Element => {
    const hitDesc = highlightedDes.length ? highlightedDes.join("") : highlightedDes;
    return (
      <div
        ref={cardDescriptionElement}
        className={props.card.size == "lg" ? "card-base__text_featured" : "card-base__text textStrip"}
      >
        {hitDesc && hitDesc.length ?
          <>
            <div dangerouslySetInnerHTML={{ __html: hitDesc }} ></div>
          </>
          :
          <div
            ref={cardDescriptionElement}
            className={props.card.size == "lg" ? "card-base__text_featured" : "card-base__text"}
            dangerouslySetInnerHTML={{ __html: description ? description : "" }}
          >
          </div>
        }
      </div>
    )
  };

  const getHighlightedTitleElement = (title: string, text: any, highlightedTitle: any): JSX.Element => {
    return (
      <div ref={titleElement} className="card-base__text trim-2l textStrip">
        {props.card.title.icon && !props.card.IsRemoved &&
          <i className={props.card.title.icon}></i>}
        <>
          {highlightedTitle && highlightedTitle.length ?
            <div
              dangerouslySetInnerHTML={{ __html: highlightedTitle }}
            ></div>
            :
            <div ref={titleElement} className="card-base__text trim-2l">
              {props.card.title.icon && !props.card.IsRemoved &&
                <i className={props.card.title.icon}></i>
              }
              {props.card.title.text && props.card.title.text != "" ? props.card.title.text : props.card.allData.title}
            </div>
          }
        </>
      </div>
    )
  };

  const getOtherContentElement = (): JSX.Element => {
    return (
      <div ref={otherContentElement} className="card-base__text location_new one trim-2l">
        {getTheRelevantValue(props.card.otherContent)}
      </div>
    );
  };

  const getTheRelevantValue = (data: string): any => {
    var arr = data ? data.split(";") : null;
    if (arr && arr.length > 1) {
      var fullPath = arr[1].split("->");
      if (fullPath.length > 1) {
        return fullPath[1];
      } else if (fullPath.length > 0) {
        return fullPath[0];
      } else {
        return arr[arr.length - 1];
      }
    }
    return arr ? arr[arr.length - 1] : "";
  };

  const getTheRelevantValueToolTip = (data: string): any => {
    let trimText = CommonFunctions.getTextFromHtml(data, 400);
    if (trimText) {
      var arr = trimText.split(";");
      return arr.length > 1 ? arr[1] : arr[arr.length - 1];
    } else {
      return data;
    }
  };

  var conceptTagsAdd = CommonFunctions.returnArrNoEmpty(
    props.card.allData["concepttags"]
  );

  var contentTagsAdd = CommonFunctions.returnArrNoEmpty(
    props.card.allData["contenttags"]
  );

  var topicTagsAdd = CommonFunctions.returnArrNoEmpty(
    props.card.allData["topictags"]
  );

  tags = conceptTagsAdd.concat(contentTagsAdd);
  if (props.card.isHorizontal && metadataListView) {
    var dates = Array.isArray(metadataListView.field2[0].field)
      ? props.card.footer[0].includes("-") ? props.card.footer[0] : props.card.footer[0] + "-" + props.card.footer[1]
      : props.card.allData[metadataListView.field2[0].field];
    var geo =
      metadataListView.field5[0].field == "geography" || metadataListView.field2[0].field == "geography" || props.card.isHorizontal && props.card.allData.geography ? props.card.allData.geography : "" ||
        metadataListView.field5[0].field == "kxq_leadprojectmemberfirm"
        ? props.card.isHorizontal && props.card.allData.geography ? props.card.allData.geography : props.card.allData[metadataListView.field5[0].field]
        : "";
    props.card.footer =
      props.card.isHorizontal && metadataListView
        ? [dates, geo]
        : props.card.footer;
  }
  var tempArr: any[] = props.chosenItems ? JSON.parse(JSON.stringify(props.chosenItems)) : [];
  var index = tempArr.findIndex((carddata: any) => props.card.allData && carddata.allData && ((carddata.allData.id &&
    props.card.allData.id && props.card.allData.id === carddata.allData.id) || (!props.card.allData.id && carddata.allData.title &&
      props.card.allData.title && props.card.allData.title === carddata.allData.title)))


  const get_badgesCount = () => {
    let badgesCount = props.card?.allData?.badges?.length
    if (badgesCount)
      return badgesCount.toString() + (badgesCount < 2 ? " Badge" : " Badges");
    else
      return '';
  }

  return (
    <div className={`ms-Grid-col d-flex horizontal ${props.card.size === "lg" ? (props.modernUi ? "ms-md4 ms-sm12 ms-Grid-col--lg" : "ms-md6 ms-sm12 ms-Grid-col--lg") : ""}  ${props.card.IsRemoved ? "card-base-disable" : ""}`} key={props.card.key}>
      <Card
        horizontal={props.card.isHorizontal}
        className={`card-base listcards ${props.card.size === "lg" ? "card-base--lg" : ""
          } 
        ${props.card.isHorizontal ? "horizontal horizontalListView" : ""} ${isPromotedResult ? "promotedResult" : ""} ${props.card.ctName === "image" ? "card-image" : ""
          }${props.categoryMove ? "categoryChoose" : ""} ${index != -1 ? "greenBorder" : ""}`}
      >
        <Card.Item className="cardbanner">
          <div className="cardbanner"
            onClick={(e) => {
              if (props.categoryMove)
                props.categoryMove(e, props.card)
              else {
                if (!props.card.IsRemoved) {
                  e?.stopPropagation();
                  if (e?.ctrlKey) {
                    goToContent(props.card.linkToItem, true);
                  } else {
                    goToContent(props.card.linkToItem, props.card.openNewTab);
                  }
                }
              }
            }}>
            <Card.Item fill className="card-base__images">
              <div
                className={`card-base__images-grid ${props.card.IsRemoved ? "boxShadow" : ""
                  } ${props.card.IsRemoved ? "disabled" : ""}`}
              >
                {props.card.type === CardType.Single && (
                  <div
                    className="image-column image-column--single"
                    style={{
                      backgroundImage: `url("${props.card.image}")`,
                    }}
                  >
                    {props.card.IsRemoved && (
                      <div className="archive-container">
                        <div className="archive-div">
                          <span className="archive-span">

                            <LinkGenerator
                              linkTemplateText={
                                SharedService.GetInstance().Data.Labels[
                                Constants.LabelKeys.ArchiveItemMessage
                                ]}
                            ></LinkGenerator>
                          </span>
                          <label className="archive-label">
                            <i className={"icon-kx-pinned-solid"}></i>
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                props.archiveRemoval!(props.card);
                              }}
                              className="archive-unpin-label">
                              {
                                SharedService.GetInstance().Data.Labels[
                                Constants.LabelKeys.UnpinFromCollection
                                ]
                              }
                            </span>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {!props.card.IsRemoved && (
                !!props.card.allData.internalcontenttype &&
                <Link href={props.card.tag ? props.card.tag[0].tagUrl : ""} className="card-base__tag">
                  {props.card.allData.internalcontenttype == "Video"
                    ? props.card.allData.kxe_videoduration
                    :
                    props.card.allData.internalcontenttype == Constants.DPN ? props.card.allData.internalcontenttype
                      : props.card.size == "lg"
                        ? props.card.tag[0].tagName
                        : SharedService.GetInstance().Data.Labels[
                        props.card.tag[0].tagName
                        ]}
                </Link>
              )}
              {isPromotedResult && !props.card.isHorizontal && (
                <Link href={""} className="card-base__tagPromoted">
                  <img src={starIcon} style={{ marginRight: '4px' }} />
                  Best Match
                </Link>
              )}
              <h4 className="card-base__title card-basetitle">
                <Link>
                  {isTitleOverflow ?
                    <TooltipBase content={props.card.title.text && props.card.title.text != "" ? props.card.title.text : props.card.allData.title}>
                      {props.card.isHorizontal ? <>
                        {props.card.allData?.highlight && props.card.allData?.highlight?.titletext ? getHighlightedTitleElement(props.card.allData.title, searchTerm, props.card.allData?.highlight?.titletext) : getTitleElement()}
                      </> : ""
                      }
                    </TooltipBase>
                    :
                    <>
                      {props.card.isHorizontal ? <>
                        {props.card.allData?.highlight && props.card.allData?.highlight?.titletext ? getHighlightedTitleElement(props.card.allData.title, searchTerm, props.card.allData?.highlight?.titletext) : getTitleElement()}
                      </>
                        : ""}
                    </>
                  }

                </Link>
                {!props.card.IsRemoved && (
                  !!props.card.allData.internalcontenttype &&
                  <Link href={props.card.tag ? props.card.tag[0].tagUrl : ""} className="card-basetype-new">
                    {props.card.allData.internalcontenttype == "Video"
                      ? props.card.allData.kxe_videoduration
                      :
                      props.card.allData.internalcontenttype == Constants.DPN ? props.card.allData.internalcontenttype
                        : props.card.size == "lg"
                          ? props.card.tag[0].tagName
                          : SharedService.GetInstance().Data.Labels[
                          props.card.tag[0].tagName
                          ]}
                  </Link>
                )}
              </h4>
              {props.card.badge && !props.card.IsRemoved && (
                <Link className="card-base__badge">
                  <i className={`icon-kx-${props.card.badge}`}></i>
                </Link>
              )}
              {props.card.ifPinCard && (
                <Link className="card-base__badge">
                  <i className={`icon-kx-pin`}></i>
                </Link>
              )}
            </Card.Item>
            <Card.Section className={`card-base__content ${props.card.IsRemoved ? "archive-opacity" : ""}`}>
              <Card.Section className="card-base__content-section">
                {isPromotedResult && props.card.isHorizontal && (
                  <Link href={""} className="card-bastmatch">
                    <img src={starIcon} style={{ marginRight: '4px' }} />
                    Best Match
                  </Link>
                )}
                <h4 className="card-base__title card-basetitle">
                  <Link>
                    {isTitleOverflow ? (
                      <TooltipBase content={props.card.title.text && props.card.title.text != "" ? props.card.title.text : props.card.allData.title}>
                        {props.card.isHorizontal ?
                          <>
                            {props.card.allData?.highlight && props.card.allData?.highlight.titletext ? getHighlightedTitleElement(props.card.allData.title, searchTerm, props.card.allData?.highlight?.titletext) : getTitleElement()}
                          </>
                          : getTitleElement()
                        }
                      </TooltipBase>
                    ) : (
                      <>
                        {props.card.isHorizontal ?
                          <>
                            {props.card.allData?.highlight && props.card.allData.highlight?.titletext ? getHighlightedTitleElement(props.card.allData.title, searchTerm, props.card.allData?.highlight?.titletext) : getTitleElement()}
                          </>
                          :
                          getTitleElement()
                        }
                      </>
                    )}
                  </Link>
                  <span className="contenttype-new">
                    {!props.card.IsRemoved && (
                      !!props.card.allData.internalcontenttype &&
                      <Link href={props.card.tag ? props.card.tag[0].tagUrl : ""} className="card-basetype-new">
                        {props.card.allData.internalcontenttype == "Video"
                          ? props.card.allData.kxe_videoduration
                          :
                          props.card.allData.internalcontenttype == Constants.DPN ? props.card.allData.internalcontenttype
                            : props.card.size == "lg"
                              ? props.card.tag[0].tagName
                              : SharedService.GetInstance().Data.Labels[
                              props.card.tag[0].tagName
                              ]}
                      </Link>
                    )}</span>
                </h4>
                <div className="mapingitems">
                  <ul>
                    {props.card.footer
                      ? props.card.footer.map((footerItem: any, i: number) => {
                        if (Array.isArray(footerItem)) {
                          footerItem = CommonFunctions.stringFromArr(footerItem);
                        }
                        if (props.card.allData.internalcontenttype == "Video" && i === 0){
                          return <li> {props.card.allData.createdFull}</li>;
                        }
                        return <li key={i}>{footerItem}</li>;
                      })
                      : props.card.footer}
                  </ul>

                  {props.card.title.icon && !props.card.IsRemoved && (
                    <TooltipBase content="Collection">
                      <span className="titleicon">
                        <i className={props.card.title.icon}></i>
                      </span>
                    </TooltipBase>
                  )}
                  {props.card.badge && !props.card.IsRemoved &&
                    <TooltipBase content={get_badgesCount()} >
                      <Link className="card-basebadge">
                        <i className={`icon-kx-${props.card.badge}`}></i>
                      </Link>
                    </TooltipBase>
                  }
                </div>
                {props.card.subTitle && !props.card.IsRemoved &&
                  <TooltipBase content={props.card.subTitle}>
                    <p className="card-base__text one">
                      <Link>{getTheRelevantValue(props.card.subTitle)}</Link>
                    </p>
                  </TooltipBase>
                }
                {!props.card.isHorizontal && props.card.desc && (
                  <p className="card-base__text two homepage_desc">
                    <Link>
                      {isDescriptionOverflow ?
                        <TooltipBase
                          content={getTheRelevantValueToolTip(props.card.desc)}
                        >
                          {getDescriptionElement()}
                        </TooltipBase>
                        :
                        getDescriptionElement()
                      }
                    </Link>
                    {props.card.isHorizontal}
                  </p>
                )}

                {props.card.otherContent &&
                  !props.card.IsRemoved &&
                  !props.card.isHorizontal && (
                    <p className="card-base__text three 1234">
                      <Link>
                        {isOtherContentOverflow ? (
                          <TooltipBase
                            content={getTheRelevantValueToolTip(
                              props.card.otherContent
                            )}
                          >
                            {getOtherContentElement()}
                          </TooltipBase>
                        ) : (
                          getOtherContentElement()
                        )}
                      </Link>
                    </p>
                  )}

                {props.card.allData.description && props.card.isHorizontal &&
                  <p className="card-base__text newtextbox two">
                    <>
                      {props.card.isHorizontal ? getHighlightedElement(props.card.allData.description, props.card.searchedTerm, props.card?.allData?.highlight ? props.card.allData?.highlight?.description : []) : getDescriptionElement()}
                    </>
                  </p>
                }


                {props.card.isHorizontal &&
                  metadataListView &&
                  Object.keys(metadataListView).map((key, index) => {
                    if (
                      index > 1 &&
                      metadataListView[key][0].field != "contenttags" &&
                      metadataListView[key][0].field != "concepttags" &&
                      metadataListView[key][0].field != "topictags" &&
                      metadataListView[key][0].field != "geography" &&
                      metadataListView[key][0].field != "allDataIndustry" &&
                      metadataListView[key][0].field != "allDataClients" &&
                      metadataListView[key][0].field != "kxq_leadprojectmemberfirm" &&
                      metadataListView[key][0].field != "kxc_globallcsp" &&
                      !Array.isArray(metadataListView[key][0].field)
                    ) {
                      if (
                        metadataListView[key][0].label &&
                        props.card.allData[metadataListView[key][0].field]
                      )
                        return (
                          <p className="card-base__text geo_name" key={`textKey${index}`}>
                            {/* {metadataListView[key][0].label} */}
                            {metadataListView[key][0].label +
                              Array.isArray(
                                props.card.allData[metadataListView[key][0].field]
                              )
                              ? CommonFunctions.stringFromArr(
                                props.card.allData[metadataListView[key][0].field]
                              )
                              : props.card.allData[metadataListView[key][0].field]}
                          </p>
                        );
                      else
                        return (
                          <p className="card-base__text topic_industery" key={`textKey${index}`}>
                            {Array.isArray(
                              props.card.allData[metadataListView[key][0].field]
                            )
                              ? CommonFunctions.stringFromArr(
                                props.card.allData[metadataListView[key][0].field]
                              )
                              : props.card.allData[metadataListView[key][0].field]}
                          </p>
                        );
                    }
                  })}




                {!props.card.otherContent && !props.card.IsRemoved &&
                  <p className="card-base__text location_qual">
                    <Link>
                      {isOtherContentOverflow ? (
                        <TooltipBase
                          content={getTheRelevantValueToolTip(
                            props.card.otherContent
                          )}
                        >
                          {getOtherContentElement()}
                        </TooltipBase>
                      ) : (
                        getOtherContentElement()
                      )}
                    </Link>
                  </p>
                }
              </Card.Section>

              <Card.Section horizontal className="card-base__footer">
                <ul className="footerdeta">
                  {props.card.footer
                    ? props.card.footer.map((footerItem: any, i: number) => {
                      if (Array.isArray(footerItem)) {
                        footerItem = CommonFunctions.stringFromArr(footerItem);
                      }
                      return <li key={i}>{footerItem}</li>;
                    })
                    : props.card.footer}
                </ul>
                {props.card &&
                  props.card.overflowItemsData &&
                  props.card.overflowItemsData[0] &&
                  props.card.overflowItemsData[0].overflowItems &&
                  !props.card.IsRemoved && props.card.isHorizontal ?
                  <div className={`card-menu card-menu-pin ${isMenuOpened ? "is-opened" : ""}`}>
                    <OverflowSetBase
                      type={props.card.ctName}
                      cardData={props.card}
                      items={props.card.overflowItemsData[0]?.items}
                      onShow={onShow}
                      onHide={onHide}
                      openModalEdit={props.openModalEdit}
                      ifPin={props.card.ifPinCard}
                      explainData={props.explainData}
                    />
                  </div>
                  : null}

                {props.card &&
                  props.card.overflowItemsData &&
                  props.card.overflowItemsData[0] &&
                  props.card.overflowItemsData[0].overflowItems &&
                  !props.card.IsRemoved && !props.card.isHorizontal ?
                  <div className={`card-menu card-menu-pin ${isMenuOpened ? "is-opened" : ""}`}>
                    <OverflowSetBase
                      type={props.card.ctName}
                      cardData={props.card}
                      items={props.card.overflowItemsData[0]?.items || []}
                      overflowItems={
                        props.card.overflowItemsData[0]?.overflowItems || []
                      }
                      onShow={onShow}
                      onHide={onHide}
                      openModalEdit={props.openModalEdit}
                      ifPin={props.card.ifPinCard}
                      explainData={props.explainData}
                    />
                  </div> : null}
              </Card.Section>
            </Card.Section>
          </div>

          {props.card.isHorizontal ?
            <div className="listviewpin">
              <ul>
                <li>
                  <a onClick={openModalAndGetCategories}>
                    {pinState ?
                      <>
                        <i className="icon-kx-pinned-solid"></i> {_sharedService.Data.Labels["Unpin"]}
                      </>
                      :
                      <>
                        <i className="icon-kx-pin"></i>Pin
                      </>
                    }
                  </a>
                </li>
                <li>
                  {props.card &&
                    props.card.overflowItemsData &&
                    props.card.overflowItemsData[0] &&
                    props.card.overflowItemsData[0].overflowItems &&
                    !props.card.IsRemoved && (
                      <div
                        className={`moreoptionslist ${isMenuOpened ? "is-opened" : ""}`}
                      >
                        <OverflowSetBase
                          type={props.card.ctName}
                          cardData={props.card}
                          overflowItems={
                            props.card.overflowItemsData[0].overflowItems
                          }
                          onShow={onShow}
                          onHide={onHide}
                          openModalEdit={props.openModalEdit}
                          ifPin={pinState}
                          explainData={props.explainData}
                          more={true}
                        />

                      </div>
                    )}
                </li>
              </ul>
            </div> : null}
        </Card.Item>
      </Card>
      <ModalBase
        onHideModal={() => { setIsOpenPinModal(false); setCreateNew(false); setExistName(false) }}
        isTwoButtons={true}
        button={{
          text: SharedService.GetInstance().Data.Labels[
            Constants.LabelKeys.CancelCapitalButton
          ],
          function: () => {
            setIsOpenPinModal(false); setCreateNew(false); setExistName(false)
          },

        }}
        SecondButton={{
          text: SharedService.GetInstance().Data.Labels[Constants.LabelKeys.SaveCapitalButton],
          function: async () => {
            if (createNew) {
              createNewCategoryAndPin()
            } else {
              savePinToCategory(selectedCategoryId)
            }
          },
          noHideModal: true
        }}
        title={
          "Pin To"
        }
        className="categoryModal"
        isOpen={isOpenPinModal}
      >
        <div style={{ width: '100%' }}>
          <div className="moreThan100">{SharedService.GetInstance().Data.Labels[Constants.LabelKeys.PinNewItemPopUp]}</div>
          <div style={{ display: 'flex' }}>
            <SearchBox
              className={"search-default search--lg newCategory"}
              onChange={() => { }}
              defaultValue={searchTerm}
              value={searchTerm}
              placeholder={"Search Here"}
              disableAnimation
            /></div>
          <button
            className="moveButtonCreate"
            onClick={async (e) => {
              var existNameDefault = true;
              var index = 0;
              var name = "Category 0"
              while (existNameDefault == true) {
                index++
                existNameDefault = await SharedService.GetInstance().Actions.Get.GetIfExistCategoryPersonalCollection(name + index);
              }
              setNewCategory(name + index)
              var list = document.getElementsByClassName("categoriesList");
              for (var i = 0; i < list.length; i++) {
                var el = list[i]
                if (el && el.classList.contains("selectedCategory")) {
                  el.classList.remove("selectedCategory");
                }
              }
              setCreateNew(true)
            }}
          >
            <i className="icon-kx-plus" style={{ paddingRight: "3px" }} />
            Create new Category
          </button>

          <div id="categorieslistAll" className="categorieslistAll">
            {createNew ? <>
              <input maxLength={255} value={newCategory} onChange={(e: any) => { setNewCategory(e.target.value); }} className="categoriesList">
              </input> {existName ? <div className="errorExist">
                This category already exist , Please select another name.
              </div> : null}</> : null}
            {filteredCategories && filteredCategories.length > 0 ? filteredCategories.map((category: ICategory) => {
              var count = category.Count
              return <button disabled={count >= 100 || createNew} onClick={(e: any) => onClickCategory(e, category.Id)} className={category.isSelected ? "categoriesList selectedCategory" : "categoriesList"}>
                {category.Label + " (" + category.Count + ")"}
              </button>

            }) : null}
          </div>
        </div>
      </ModalBase>
    </div>
  );
};

