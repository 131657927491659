import React, { FormEvent, useEffect, useState } from 'react';
import {
  Stack,
  Text,
  IStackTokens,
  StackItem,
  Icon,
  PrimaryButton,
  DefaultButton,
} from '@fluentui/react';
import { Dropdown, IDropdownOption, ITag, LabelBase, TextField } from 'office-ui-fabric-react';
import { useHistory } from 'react-router-dom';
import { DateRangeType } from '@fluentui/react-date-time';
import { useBoolean } from "@fluentui/react-hooks";
import { Constants } from '../../../helpers/Constants';
import { PeoplePickerBase } from '../../shared/peoplePickerBase/peoplePickerbase';
import { mergeStyles } from '@fluentui/react';

import './editForm.scss';
import AuditLog from './auditLog';
import { SharedService } from '../../../services/SharedService';
import { InputTextbox } from '../../shared/inputTextbox/inputTextbox';
import { PopupDialog } from '../../shared/popupDialog/popupDialog';
import _ from 'lodash';
import { CustomDropdown, } from '../../shared/customDropdown/customDropdown';
import { NewDatePickerBase } from '../../shared/newDatepickerBase/newDatePickerBase';
import { AnalyticsService } from '../../../services/AnalyticsService';
import { CommonFunctions } from '../../../helpers/CommonFunctions';
import { IPageTracker, IPersonalizationTracker } from '../../../models/IAnalytics';
import { useSelector } from 'react-redux';
import { CheckBoxTree } from '../../shared/checkboxTree/checkboxTree';
import { ToastNotification } from '../../shared/toastNotification/toastNotification';

type updateDocAdminObj = {
  documentId: string | number,
  contentPublisher: any,
  qaResource: any
  followUpDate: Date,
  contentManger: any,
  contentManagers: any,
  contentApprover: any,
  plannedArchiveDate: Date,
  // updateRequestedDate: Date,
  searchKeywords: string,
  systemSource: string,
  contributionTeam: string,
  publishingPriority: string,
  slaContribution: number,
  slaUpdateRequest: number,
  publishingNotes: {
    publishingNotesText: string,
    publishingNotesBy: string,
  },
  publishingInstruction: {
    publishingInstructionText: string,
    publishingInstructionBy: string,
  },
  approverComment: {
    commentText: string,
    commentBy: string,
  },
  subStatus: number,
  documentstatus: number,
  contributorCountryId: number,
  requestedDate: Date;
}

export interface DocumentAdminProps {
  docId: any;
  editformData: any;
  systemSourceOptions: any;
  InitialDocumentAdminData: any;
  setdocumentAdmin: () => void;
  onSubmitDocAdminForm: (type: string | number | undefined, docAdminData: any) => void;
  getDocAdminData: (Data: any) => void;
  EditFormDocAdminData: updateDocAdminObj | null;
  contentDate: Date | undefined;
  contentTypeId: number;
  globalClientServiceId: number;
  rolegroups: {
    productAdministrators: boolean;
    collectionCurators: boolean;
    knowledgeServicesProfessionals: boolean;
    publishers: boolean;
    publishersDeliveryExcellence: boolean;
    publishingTeamLeaders: boolean;
  };
  savedStatus: string;
  activityData: (value?: any) => void;
  countryList: any;
  load: () => void;
  editFormStatus: number | null;
  onDocAdminValidation: () => void;
  editFormRequiredAlert: boolean;
  setContentPublisherInvalidText: (value: string) => void;
  setQaResourceInvalidText: (value: string) => void;
  setContentManagerInvalidText: (value: string) => void;
  setContentApproverInvalidText: (value: string) => void;
  setDeletedDashActivity: (value: any) => void;
  contributorCountry: any;
}

const DocumentAdmin: React.FunctionComponent<DocumentAdminProps> = (props) => {
  let history = useHistory();
  const containerStackTokens: IStackTokens = { childrenGap: 10 };
  const options: IDropdownOption[] = [
    { key: 'to', text: 'To' },
    { key: 'from', text: 'From' },
  ];
  const publishingStatusOptions: IDropdownOption[] = [
    { key: 10, text: 'None' },
    { key: 2, text: 'Approval Requested' },
    { key: 3, text: 'Approval Received' },
    { key: 4, text: 'Pending Info' },
    { key: 9, text: 'Received Info' },
    { key: 5, text: 'Ready to QA' },
    { key: 6, text: 'QA in Process' },
    { key: 7, text: 'Rework Needed' },
    { key: 8, text: 'On Hold' },
    { key: 11, text: 'Images Ready for QA' }
  ];
  const [docAdmin, setDocAdmin] = useState<boolean>(true);
  const [selectedFollowUpDate, setSelectedFollowUpDate] = useState<Date>();
  const [showFollowUpFromDateCalendar, setShowFollowUpFromDateCalendar] = useState<boolean>(false);
  const [showFollowUpCalender, setShowFollowUpCalender] = useState(false);

  const [selectedArchiveDate, setSelectedArchiveDate] = useState<Date>();
  const [showArchiveFromDateCalendar, setShowArchiveFromDateCalendar] = useState<boolean>(false);
  const [showArchiveCalender, setShowArchiveCalender] = useState(false);

  const [showRequestedFromDateCalendar, setShowRequestedFromDateCalendar] = useState<boolean>(false);
  const [showRequestedCalender, setShowRequestedCalender] = useState(false);
  const [documentAdminData, setdocumentAdminData] = useState<any>()
  const [contributionDate, setContributionDate] = useState<any>()
  const [selectedContentPublisher, setSelectedContentPublisher] = useState<any | null>(null);
  const [defaultContentPublisher, setDefaultContentPublisher] = useState<any | null>(null);
  const [selectedQAResource, setSelectedQAResource] = useState<any | null>(null);
  const [defaultQAResource, setDefaultQAResource] = useState<any | null>(null);
  const [selectedContentManager, setSelectedContentManager] = useState<any | null>(null);
  const [defaultContentManager, setDefaultContentManager] = useState<any | null>(null);
  const [selectedContentApprover, setSelectedContentApprover] = useState<any | null>(null);
  const [defaultContentApprover, setDefaultContentApprover] = useState<any | null>(null);
  const [publishNotestext, setPublishNotesText] = useState<string | undefined>('')
  const [approverCommenttext, setApproverCommentText] = useState<string | undefined>('')
  const [publishInstructiontext, setPublishInstructionText] = useState<string | undefined>('')
  const [documentStatus, setDocumnetStatus] = useState<string | number | undefined>()
  const [publishingStatus, setPublishingStatus] = useState<string | number>()
  const [requestedDate, setRequestedDate] = useState<any>();
  const [systemSource, setSystemSource] = useState<string | number>()
  const [systemSourcedropDownOptions, setSystemSourceDropDownOptions] = useState<any[]>([]);
  const [createdDate, setCreatedDate] = useState<any>()
  const [modifiedDate, setModifiedDate] = useState<any>()
  const [publishedDate, setPublishedDate] = useState<any>()
  const [searchKeywordText, setSearchKeywordsText] = useState<string>()
  const [publisherInstructiontLength, setPublisherInstructiontLength] = useState<number>(4);
  const [pISeeMoreExpanded, setPISeeMoreExpanded] = useState<boolean>(false);
  const [approverCommentsLength, setApproverCommentsLength] = useState<number>(4);
  const [aCSeeMoreExpanded, setACSeeMoreExpanded] = useState<boolean>(false);
  const [PublisherNotesLength, setPublisherNotesLength] = useState<number>(4);
  const [pNSeeMoreExpanded, setPNSeeMoreExpanded] = useState<boolean>(false);
  const [datacollected, setdatacollected] = useState<boolean>(false);
  const [hideDialogCancel, { toggle: toggleHideDialogCancel }] = useBoolean(true);
  const [hideDialogDelete, { toggle: toggleHideDialogDelete }] = useBoolean(true);
  const _sharedService = SharedService.GetInstance();
  const [archiveDateNearFlag, setArchiveDateNearFlag] = useState<boolean>(false);
  const userdetails = _sharedService.Data.CurrentUser;
  const moment = require('moment');
  const systemSourceOptions: IDropdownOption[] = systemSourcedropDownOptions;
  const [docStatusOptions, setDocStatusOptions] = useState<IDropdownOption[]>([]);
  const [hideDialogArchive, { toggle: toggleHideDialogArchive }] = useBoolean(true);
  const [hideDialogUnarchive, { toggle: toggleHideDialogUnarchive }] = useBoolean(true);
  const [contributingTeam, setContributingTeam] = useState<string | null>("");
  const [removeActivity, setRemoveActivity] = useState<boolean>(false);
  const [selectedActivity, setSelectedActivity] = useState<any[]>([]);
  const [intialActivity, setIntialActivity] = useState<ITag[]>([]);
  const [userRoleGroups, setUserRoleGroups] = useState<any>(null);
  const [userPrivileges, setUserPrivileges] = useState<any>(null);
  const [currentUser, setCurrentUser] = useState<any>();
  // const [contributorCountrylist, setContributorCountrylist] = useState<[]>([]);
  const [selctedContributorCountryValue, setSelectedContributorCountryValue] = useState<any[]>([]);
  const [contributorCountryId, setContributorCountryId] = useState<number | null>();
  const [checkedContributorCountryValues, setCheckedContributorCountryValues] = useState<string[]>([]);
  const [contributorCountryModal, setContributorCountryModal] = useState<boolean>(false);
  const [savedContributorCountryTreeValues, setSavedContributorCountryTreeValues] = useState<any[]>();
  const _userNeeds: any = useSelector((s: any) => s.userNeedsReducer);
  const [statusPriorArchival, setStatusPriorArchival] = useState<number | null>();
  const [requiredAlerts, setRequiredAlerts] = useState(false);
  const [deleteActivityId, setDeleteActivityId] = useState<any>();
  //const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);

  useEffect(() => {
    if (userdetails && userdetails.kxp_email) {
      _sharedService.GetPeopleData(userdetails.kxp_email).then((res) => {
        setCurrentUser(res[0]);
      })
    }

  }, [userdetails])

  useEffect(() => {

    // let countryOfOriginTableId = Constants.TableIdsForTree.CountryOfOrigin.tableId;
    // let memeberFirmId1 = Constants.TableIdsForTree.CountryOfOrigin.memberFirmId;
    // let COOList:any;
    // SharedService.GetInstance()
    //   .GetTaxonomyTreeValues(countryOfOriginTableId, memeberFirmId1)
    //   .then((result) => {
    //     if (result) {
    //       setContributorCountrylist(result);
    //       COOList=result;
    //     }
    //   });

    _sharedService.GetUserRoleGroupsAndPrivileges().then((response: any) => {
      setUserRoleGroups(response.roleGroups);
      setUserPrivileges(response.userPrivileges);
    })
    let docAdminData: any;
    docAdminData = props.InitialDocumentAdminData !== null && props.InitialDocumentAdminData !== undefined ? props.InitialDocumentAdminData : {};

    setdocumentAdminData(docAdminData);
    setSystemSourceDropDownOptions(props.systemSourceOptions)
    if (props.editformData && props.editformData.systemSource != null) {
      setSystemSource(props.editformData.systemSource)
    }
    if (props.EditFormDocAdminData == null) {
      if (Object.keys(docAdminData).length > 0 && docAdminData != null && docAdminData != undefined && docAdminData.contentPublisher != null) {
        setDefaultContentPublisher(docAdminData.contentPublisher);
      }
      // else {
      //   if (props.editformData.contributingTeam == "Publishers") {
      //     setDefaultContentPublisher(props.editformData.createdBy);
      //   }
      // }
    } else {
      if (
        props.EditFormDocAdminData != null &&
        props.EditFormDocAdminData.contentPublisher != null
      ) {
        setDefaultContentPublisher(
          props.EditFormDocAdminData.contentPublisher
        );
      }
    }

    if (props.EditFormDocAdminData == null) {
      if (docAdminData != null && docAdminData != undefined && Object.keys(docAdminData).length > 0 && docAdminData.qaResource != null) {
        setDefaultQAResource(docAdminData.qaResource);
      }
    } else {
      if (
        props.EditFormDocAdminData != null &&
        props.EditFormDocAdminData.qaResource != null
      ) {
        setDefaultQAResource(props.EditFormDocAdminData.qaResource);
      }
    }

    if (props.EditFormDocAdminData == null) {
      if (docAdminData != null && docAdminData != undefined && Object.keys(docAdminData).length > 0 && docAdminData.contentManagers != null) {
        let data = docAdminData.contentManagers;
        setContentManager(data);
        let structData: any[] = []
        data.map((data: any) => {
          let obj = {
            name: data.displayName ? data.displayName : data.fullName,
            key: data.email
          }
          structData.push(obj)
        })
        setDefaultContentManager(structData);
      }
    } else {
      if (
        props.EditFormDocAdminData != null &&
        props.EditFormDocAdminData.contentManagers != null
      ) {
        let data = props.EditFormDocAdminData.contentManagers;
        setContentManager(data);
        let structData: any[] = []
        data.map((data: any) => {
          let obj = {
            name: data.displayName ? data.displayName : data.fullName,
            key: data.email
          }
          structData.push(obj)
        })
        setDefaultContentManager(structData);
      }
    }

    if (props.EditFormDocAdminData == null) {
      if (Object.keys(docAdminData).length > 0 && docAdminData != null && docAdminData != undefined && docAdminData.contentApprover != null) {
        setDefaultContentApprover(docAdminData.contentApprover);
      }
    } else {
      if (
        props.EditFormDocAdminData != null &&
        props.EditFormDocAdminData.contentApprover != null
      ) {
        setDefaultContentApprover(props.EditFormDocAdminData.contentApprover);
      }
    }
    setdatacollected(true);
    if (props.EditFormDocAdminData == null) {
      if (docAdminData != null && docAdminData != undefined && docAdminData.followUpDate) {
        const followUpDate = defaultDateFormat(docAdminData.followUpDate)
        setSelectedFollowUpDate(followUpDate)
      }
      if (docAdminData != null && docAdminData != undefined && docAdminData.plannedArchiveDate) {
        const archiveDate = defaultDateFormat(docAdminData.plannedArchiveDate)
        setSelectedArchiveDate(archiveDate)
      }
      if (docAdminData != null && docAdminData != undefined) {
        setSearchKeywordsText(docAdminData.searchKeywords);
      }
    }

    let contributiondate = convertDate(props.editformData.contributedDate)
    setContributionDate(contributiondate);
    let createdate = convertDate(props.editformData.createdDate)
    setCreatedDate(createdate)
    if (props.editformData.modifiedDate) {
      let modfieddate = convertDate(props.editformData.modifiedDate)
      setModifiedDate(modfieddate)
    }
    if (props.editformData.publishedRecordIndexedTimeStamp) {
      let publisheddate = convertDate(props.editformData.publishedRecordIndexedTimeStamp)
      setPublishedDate(publisheddate)
    }
    setPublishingStatus(props.editformData.subStatus)

    if (props.EditFormDocAdminData == null) {
      if (props.editformData.contributorCountryId !== null) {
        setContributorCountryId(props.editformData.contributorCountryId)
        var defaultContributorCountryObj = [
          { name: setCountryHierarcy(props.editformData.contributorCountry), key: props.editformData.contributorCountry.id },
          // { name: props.editformData.contributorCountry.name, key: props.editformData.contributorCountry.id },
        ]
        setSelectedContributorCountryValue(defaultContributorCountryObj);

      }
    }
    else if (
      props.EditFormDocAdminData != null &&
      props.EditFormDocAdminData.contributorCountryId != null
    ) {
      props.countryList && props.countryList.map((item: any) => {
        if (props.EditFormDocAdminData && item.Id == props.EditFormDocAdminData.contributorCountryId) {
          setContributorCountryId(item.Id);
          setSelectedContributorCountryValue([
            { name: setCountryHierarcy(item), key: item.Id },
            // { name: item.Name, key: item.Id },
          ]);
        }
      });
    }
    if (props.editformData.requestedDate) {
      const updateRequestDate = defaultDateFormat(props.editformData.requestedDate)
      setRequestedDate(updateRequestDate)
    }

    if (docAdminData != null && docAdminData != undefined && Object.keys(docAdminData).length == 0 && props.editformData && props.editformData.publishInstructions) {
      setPublishInstructionText(props.editformData.publishInstructions)
    }

    // if (props.editformData.contributingTeam && props.editformData.contributingTeam != null && props.editformData.contributingTeam == 'Curation') {
    //   setContributingTeam('Curation')
    // }

    if (props.editformData.contributingTeam && props.editformData.contributingTeam != '') {
      if (props.editformData.contributingTeam.includes('@')) {
        setContributingTeam('')
      }
      else {
        setContributingTeam(props.editformData.contributingTeam)
      }
    }
    setDocumnetStatus(props.editformData.status)
    // effectFunc();
  }, [props.EditFormDocAdminData, props.editformData]);

  const setCountryHierarcy = (selectedCountry: any) => {
    if (selectedCountry) {
      selectedCountry = { name: selectedCountry.name || selectedCountry.Name, id: selectedCountry.id || selectedCountry.Id, value: selectedCountry.value || selectedCountry.Value }
    }
    let hierarcy = selectedCountry.name;
    let allCountrylist = props.countryList;
    selectedCountry.id = selectedCountry.id ? selectedCountry.id : selectedCountry.value;
    selectedCountry = allCountrylist.find((m: any) => m.Id === selectedCountry.id);
    hierarcy = selectedCountry?.Name;

    if (allCountrylist === null || allCountrylist === undefined || allCountrylist.length === 0) {
      return hierarcy;
    }
    if (selectedCountry?.ParentId > 0) {
      let parentItem: any = allCountrylist.find((m: any) => m.Id === selectedCountry.ParentId);
      let l1_ParentItemName: string = "";
      if (selectedCountry.Level && selectedCountry.Level === 3) {
        let level1_parentItem: any = allCountrylist.find((m: any) => m.Id === parentItem?.ParentId);
        l1_ParentItemName = level1_parentItem?.Name + ">";
      }
      hierarcy = l1_ParentItemName + parentItem?.Name + ">" + selectedCountry.Name;
    }
    if (selectedCountry) {
      selectedCountry.Hierarcy = hierarcy;
    }
    return hierarcy;
  }

  useEffect(() => {
    if (userRoleGroups !== null) {
      effectFunc();
    }
  }, [userRoleGroups]);

  useEffect(() => {
    if (props.editformData.status === 7) {
      setDocStatusOptions([
        // { key: 1, text: 'Draft' },
        { key: 2, text: 'Contributed' },
        { key: 4, text: 'Assigned' },
        { key: 5, text: 'In Process' },
        { key: 3, text: 'Published' },
        { key: 6, text: 'Update Requested' },
        { key: 7, text: 'Archive' },
        { key: 8, text: 'Unarchive' },
      ])
    } else {
      setDocStatusOptions([
        // { key: 1, text: 'Draft' },
        { key: 2, text: 'Contributed' },
        { key: 4, text: 'Assigned' },
        { key: 5, text: 'In Process' },
        { key: 3, text: 'Published' },
        { key: 6, text: 'Update Requested' },
        { key: 7, text: 'Archive' },
      ])
    }

    // if (props.editformData.status === 3) {
    //   if (!props.rolegroups.collectionCurators && !props.rolegroups.knowledgeServicesProfessionals) {
    //     setDocStatusOptions([
    //       { key: 1, text: 'Draft' },
    //       { key: 2, text: 'Contributed' },
    //       { key: 4, text: 'Assigned' },
    //       { key: 5, text: 'In Process' },
    //       { key: 3, text: 'Published' },
    //       { key: 6, text: 'Update Requested' },
    //       { key: 7, text: 'Archive' },
    //     ])
    //   }
    // } else if (props.editformData.status === 7) {
    //   if (!props.rolegroups.collectionCurators && !props.rolegroups.knowledgeServicesProfessionals) {
    //     setDocStatusOptions([
    //       { key: 1, text: 'Draft' },
    //       { key: 2, text: 'Contributed' },
    //       { key: 4, text: 'Assigned' },
    //       { key: 5, text: 'In Process' },
    //       { key: 3, text: 'Published' },
    //       { key: 6, text: 'Update Requested' },
    //       { key: 7, text: 'Archive' },
    //       { key: 8, text: 'Unarchive' },
    //     ])
    //   }
    // } else {
    //   // if (!props.rolegroups.collectionCurators && !props.rolegroups.knowledgeServicesProfessionals) {
    //   //   setDocStatusOptions([
    //   //     { key: 1, text: 'Draft' },
    //   //     { key: 2, text: 'Contributed' },
    //   //     { key: 4, text: 'Assigned' },
    //   //     { key: 5, text: 'In Process' },
    //   //     { key: 3, text: 'Published' },
    //   //     { key: 6, text: 'Update Requested' },
    //   //     { key: 7, text: 'Archive' },
    //   //   ])
    //   // }else{
    //     setDocStatusOptions([
    //       { key: 1, text: 'Draft' },
    //       { key: 2, text: 'Contributed' },
    //       { key: 4, text: 'Assigned' },
    //       { key: 5, text: 'In Process' },
    //       { key: 3, text: 'Published' },
    //       { key: 6, text: 'Update Requested' }
    //     ])
    //   // }
    // }
    setDocumnetStatus(props.editformData.status);
  }, [props.rolegroups])

  useEffect(() => {
    if (props.EditFormDocAdminData) {
      if (props.EditFormDocAdminData.publishingInstruction != null) {
        setPublishInstructionText(
          props.EditFormDocAdminData.publishingInstruction
            .publishingInstructionText
        );
      }
      if (props.EditFormDocAdminData.approverComment != null) {
        setApproverCommentText(
          props.EditFormDocAdminData.approverComment.commentText
        );
      }
      if (props.EditFormDocAdminData.publishingNotes != null) {
        setPublishNotesText(
          props.EditFormDocAdminData.publishingNotes.publishingNotesText
        );
      }
      if (props.EditFormDocAdminData.followUpDate) {
        const followUpDate = defaultDateFormat(props.EditFormDocAdminData.followUpDate)
        setSelectedFollowUpDate(followUpDate)
      }
      if (props.EditFormDocAdminData.plannedArchiveDate) {
        const archiveDate = defaultDateFormat(props.EditFormDocAdminData.plannedArchiveDate)
        setSelectedArchiveDate(archiveDate)
      }
      if (props.EditFormDocAdminData.searchKeywords) {
        setSearchKeywordsText(props.EditFormDocAdminData.searchKeywords)
      }
      // if (props.EditFormDocAdminData.contributorCountryId) {
      //   contributorCountrylist.map((item: any) => {
      //     if (props.EditFormDocAdminData && item.Id == props.EditFormDocAdminData.contributorCountryId) {
      //       setContributorCountryId(item.Id);
      //       setSelectedContributorCountryValue([
      //         { name: item.Name, key: item.Id },
      //       ]);
      //     }
      //   });
      // }
    }
  }, [props.EditFormDocAdminData]);

  useEffect(() => {
    if (props.editformData?.contributorCountry) {
      setContributorCountryId(props.editformData.contributorCountry.id);
      let name = setCountryHierarcy(props.editformData.contributorCountry);
      setSelectedContributorCountryValue([
        { name: name, key: props.editformData.contributorCountry.Id },
      ]);
      setCheckedContributorCountryValues([props.editformData.contributorCountry.id]);
    } else {
      setSelectedContributorCountryValue([]);
      setCheckedContributorCountryValues([]);
      setContributorCountryId(null);
    }
  }, [props.contributorCountry])


  const effectFunc = () => {
    CommonFunctions.SetLanguage();
    _sharedService.Actions.Get.GetMyInterests().then((res) => {
      if (res != null && res.length > 0) {
        _sharedService.Data.CurrentUser.isInterest = true;
      }
    });
    //analytics
    if (_userNeeds && _userNeeds.userNeeds && _userNeeds.userNeeds.text) {
      AnalyticsService.resetPageContext();
      let pageTracker: IPageTracker = {} as IPageTracker;
      let personalizationTracker: IPersonalizationTracker = {} as IPersonalizationTracker;
      pageTracker = {
        pageInfo: {
          breadCrumbs: "",
          contentID: "",
          contentTitle: props.editformData.title !== undefined ? props.editformData.title : _sharedService.Data.Labels["KXDocuments_PublisherDashboard"],
          genericPageName: Constants.PageTitles.DocumentEditPublishingInformation,
          language: _sharedService.lang,
          sourceContentType: "",
          sourceSystem: "",
          docAge: "",
          isPublisher: userRoleGroups !== null ? (userRoleGroups.publishers || userRoleGroups.productAdministrators || userRoleGroups.publishingTeamLeaders || userRoleGroups.collectionCurators || userRoleGroups.knowledgeServicesProfessionals || userRoleGroups.publishersDeliveryExcellence) : false,
          primaryCategory: Constants.PageTitles.KXDocuments,
          pageName: `KX|${_sharedService.Data.Labels["KXDocuments_PublisherDashboard"]}`
        },
      };

      personalizationTracker = {
        need: _userNeeds.userNeeds.text,
        hasInterests: false,
      };
      AnalyticsService.trackPageLoad(pageTracker, personalizationTracker);
    }

  }

  useEffect(() => {
    if (props.editformData.dashActivityId != null || undefined) {
      let data = props.editformData.dashActivityId;
      let newSet = [{ name: data.toString(), key: data.toString() }];
      setSelectedActivity(newSet);
      setIntialActivity(newSet);
    }
    if (props.InitialDocumentAdminData === null || (props.editformData.publishInstructions === null && props.InitialDocumentAdminData && props.InitialDocumentAdminData.publishingInstruction && props.InitialDocumentAdminData.publishingInstruction.length === 0)) {
      setPublishInstructionText(props.editformData.publishInstructions);
    }

  }, [props.editformData]);

  useEffect(() => {
    setStatusPriorArchival(props.editFormStatus);
  }, [props.editFormStatus])

  useEffect(() => {
    const checkDate = (date: Date) => {
      const currdate = new Date();
      const daycount = moment(date).diff(currdate, 'days');
      if (daycount <= 60) {
        setArchiveDateNearFlag(true)
      } else {
        setArchiveDateNearFlag(false)
      }
    }
    if (selectedArchiveDate) {
      checkDate(selectedArchiveDate)
    }
  }, [selectedArchiveDate]);

  const connectActivity = (items:any) => {
    props.activityData(items);
    //setDisableAddBtn(true);
  }

  const disconActivity = () => {
    if (intialActivity.length != 0) {
      let activity: Number = Number(intialActivity[0].key);
      let obj: any = {
        KMIP: "",
        status: "Not Started",
        actid: activity
      }
      // _sharedService.removeActivityIntegratedDocument(obj);
      setDeleteActivityId(obj)
      props.setDeletedDashActivity(obj)
    }
    selectedActivity.pop();
    props.activityData(selectedActivity);
    setRemoveActivity(true);
    //setDisableAddBtn(false);
  }

  const setActivity = (items: []) => {
    if (items.length == 0) {
      disconActivity();
    } else {
      setSelectedActivity(items);
      connectActivity(items); 
    }
  }

  const defaultDateFormat = (DateData: Date) => {
    const moment = require('moment');
    var date = moment.utc(DateData).local().format("MM-DD-YYYY");
    // var time = moment().format("HH:mm");
    // var datetime = date + " " + time;
    // var dateformat = new Date(datetime);
    var dateformat = new Date(date);
    return dateformat;
  }

  const defaultDateTimeFormat = (DateData: Date) => {
    const moment = require('moment');
    var date = moment.utc(DateData).local().format("MM-DD-YYYY");
    var time = moment.utc(DateData).local().format("hh:mmA");
    var datetime = date + " " + time;
    var dateformat = new Date(datetime);
    return datetime;
  }

  const convertDate = (date: Date) => {
    const moment = require('moment');
    let strdate = moment.utc(date).local().format('D MMM YYYY');
    return strdate
  }

  const setcontentPublisher = (items: any) => {
    setSelectedContentPublisher(items);
  };

  const setQAResource = (items: any) => {
    setSelectedQAResource(items);
  };

  const setContentManager = (items: any) => {
    let currentSelectedContentManagers = selectedContentManager;
    if (items && items.length > 0) {
      const uniqueContentManagers = items.reduce((accumulator: any, current: any) => {
        if (!accumulator.find((item: any) => item.email === current.email)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      let arr: any = []
      uniqueContentManagers.map((manager: any) => {
        let obj = {
          name: manager.displayName,
          key: manager.email
        }
        arr.push(obj)
      })
      setSelectedContentManager(uniqueContentManagers);
    } else {
      setSelectedContentManager([])
    }
  };

  const setContentApprover = (items: any) => {
    setSelectedContentApprover(items);
  };

  const PublishNotes = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newText: string | undefined
  ) => {
    setPublishNotesText(newText)
    //props.AdditionalFormData.publishInstructions = newText;
  };

  const ApproverComment = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newText: string | undefined
  ) => {
    setApproverCommentText(newText);
  };

  const PublishingInstruction = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newText: string | undefined
  ) => {
    setPublishInstructionText(newText)
  };

  const onDocumentStatusChange = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined): void => {
    setDocumnetStatus(option?.key);
  };

  const onPublishingStatusChange = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined): void => {
    setPublishingStatus(option?.key);
  };

  const onSystemSourceChange = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined): void => {
    setSystemSource(option?.key);
  };

  const searchKeywordsData = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {

    setSearchKeywordsText(newValue)
  };

  const toastNotificationClicked = () => {
    setRequiredAlerts(false);
  };

  const sendGetDocAdminData = () => {
    let data: any = {
      documentId: props.docId,
      contentPublisher: selectedContentPublisher,
      qaResource: selectedQAResource,
      followUpDate: selectedFollowUpDate ? new Date(selectedFollowUpDate) : null,
      contentManger: selectedContentManager,
      contentManagers: selectedContentManager,
      contentApprover: selectedContentApprover,
      plannedArchiveDate: selectedArchiveDate,
      searchKeywords: searchKeywordText,
      systemSource: systemSource,
      contributionTeam: props.InitialDocumentAdminData?.contributionTeam,
      publishingPriority: props.InitialDocumentAdminData?.publishingPriority,
      typeOfRequest: "",
      slaContribution: 0,
      slaUpdateRequest: 0,
      publishingNotes: {
        publishingNotesText: publishNotestext,
        publishingNotesBy: currentUser?.onPremisesSamAccountName,
      },
      publishingInstruction: {
        publishingInstructionText: publishInstructiontext,
        publishingInstructionBy: currentUser?.onPremisesSamAccountName,
      },
      approverComment: {
        commentText: approverCommenttext,
        commentBy: currentUser?.onPremisesSamAccountName,
      },
      subStatus: publishingStatus,
      documentstatus: documentStatus,
      contributorCountryId: contributorCountryId,
      requestedDate: requestedDate,

    }
    if (statusPriorArchival != 7) {
      data.statusPriorArchival = statusPriorArchival;
    } else if (props.editformData.statusPriorArchival != 0) {
      data.statusPriorArchival = props.editformData.statusPriorArchival
    }
    // props.getDocAdminData(data)
    return data;
  }

  const isValidDocumentAdmin = (documentAdminData: any) => {
    if (documentAdminData && documentAdminData.plannedArchiveDate)
      return true;

    if (!props.editFormRequiredAlert) {
      setRequiredAlerts(true);
      props.onDocAdminValidation();
    }
    return false;
  }

  const onSaveDocAdmin = () => {
    if (documentStatus === 7) {
      toggleHideDialogArchive();
      return
    } else if (documentStatus === 8) {
      toggleHideDialogUnarchive();
      return
    } else {
      const onSavedata = sendGetDocAdminData();
      if (!isValidDocumentAdmin(onSavedata)) {
        return;
      };
      props.getDocAdminData(onSavedata);
      props.onSubmitDocAdminForm(documentStatus, onSavedata);
      setDeleteActivityId(null)
    }
  }

  const backToEditForm = () => {
    const onSavedata = sendGetDocAdminData()
    props.getDocAdminData(onSavedata);
  }

  const CalculateArchiveYear = () => {
    if (props.contentTypeId && props.contentDate) {
      var primaryglobalbusiness: number;
      if (props.globalClientServiceId == undefined) { primaryglobalbusiness = 0 }
      else {
        primaryglobalbusiness = props.globalClientServiceId;
      }
      _sharedService.GetArchiveYear(props.contentTypeId, primaryglobalbusiness).then((archiveYear: number) => {
        if (archiveYear > 0) {
          let newDate: Date = moment(props.contentDate).add(archiveYear, 'years');
          let newDateFormat = defaultDateFormat(newDate)
          setSelectedArchiveDate(newDateFormat)
        }
      })
    }
  }

  const onPISeeMoreClick = () => {
    setPublisherInstructiontLength(documentAdminData.publishingInstruction.length);
    setPISeeMoreExpanded(true);
  }

  const onPISeeMoreCollapse = () => {
    setPublisherInstructiontLength(4);
    setPISeeMoreExpanded(false);
  }

  const onACSeeMoreClick = () => {
    setApproverCommentsLength(documentAdminData.approverComment.length);
    setACSeeMoreExpanded(true);
  }

  const onACSeeMoreCollapse = () => {
    setApproverCommentsLength(4);
    setACSeeMoreExpanded(false);
  }

  const onPNSeeMoreClick = () => {
    setPublisherNotesLength(documentAdminData.publishingNotes.length);
    setPNSeeMoreExpanded(true);
  }

  const onPNSeeMoreCollapse = () => {
    setPublisherNotesLength(4);
    setPNSeeMoreExpanded(false);
  }

  const onCancelEditForm = () => {
    toggleHideDialogCancel();
  }

  const onDeleteEditForm = () => {
    toggleHideDialogDelete();
  }

  const onClickDeleteYes = () => {
    const onSavedata = sendGetDocAdminData()
    // props.onSubmitDocAdminForm(7, onSavedata);
    props.load();
    _sharedService.removeDocument(props.docId).then(resp => {
      if (resp) {
        props.load();
        props.onSubmitDocAdminForm(9, onSavedata);
        history.push(Constants.Routing.myKxContribution);
      }
    });
  }

  const onClickDeleteNo = () => {
    toggleHideDialogDelete();
  };

  const onClickCancelYes = () => {
    let gotoPublisherDashboard = userRoleGroups?.productAdministrators || userRoleGroups?.publishingTeamLeaders || userRoleGroups?.publishers
    if (gotoPublisherDashboard)
      history.push(Constants.Routing.publisherDashBoard);
    else
      history.push(Constants.Routing.myKxContribution);
    toggleHideDialogCancel();
  };

  const onClickCancelNo = () => {
    toggleHideDialogCancel();
  };

  const onClickArchiveYes = () => {
    toggleHideDialogArchive();
    const onSavedata = sendGetDocAdminData()
    if (!isValidDocumentAdmin(onSavedata)) {
      return;
    };
    props.getDocAdminData(onSavedata);
    props.onSubmitDocAdminForm(documentStatus, onSavedata);
  }

  const onClickArchivehNo = () => {
    toggleHideDialogArchive();
  }

  const onClickUnArchiveYes = () => {
    toggleHideDialogUnarchive();
    const onSavedata = sendGetDocAdminData()
    if (!isValidDocumentAdmin(onSavedata)) {
      return;
    };
    props.getDocAdminData(onSavedata);
    props.onSubmitDocAdminForm(documentStatus, onSavedata);
  }

  const onClickUnArchivehNo = () => {
    toggleHideDialogUnarchive();
  }

  useEffect(() => {
    if (documentStatus && documentStatus !== 5) {
      setPublishingStatus(10)
    }

  }, [documentStatus])

  const setContributorCountrySelection = (items: any) => {
    if (items.length > 0) {
      props.countryList.map((item: any) => {
        if (item.Id == items[0].id) {
          setContributorCountryId(item.Id);
        }
      });
    }
    if (items && items.length > 0) {
      items[0].name = setCountryHierarcy(items[0]);
    }
    setSelectedContributorCountryValue(items);
    if (items.length == 0) {
      setCheckedContributorCountryValues([]);
      setContributorCountryId(null);
    } else {
      setCheckedContributorCountryValues([items[0].id]);
    }
  };

  const contributorCountryCancelClick = () => {
    if (selctedContributorCountryValue.length > 0) {
      setCheckedContributorCountryValues([selctedContributorCountryValue[0].id]);
    } else {
      setCheckedContributorCountryValues([]);
    }
  };

  const saveContributorCountry = (currentNode: any) => {
    props.countryList.map((item: any) => {
      if (item.Id == currentNode[0].value) {
        setContributorCountryId(item.Id);
      }
    });
    setContributorCountryModal(!contributorCountryModal);
    if (checkedContributorCountryValues == undefined) {
      setSelectedContributorCountryValue([]);
    } else {
      setSavedContributorCountryTreeValues(checkedContributorCountryValues);
      setSelectedContributorCountryValue([
        { name: setCountryHierarcy(currentNode[0]), key: currentNode[0].value },
      ]);
      var obj = [
        { name: setCountryHierarcy(currentNode[0]), key: currentNode[0].value },
      ]
      console.log("selected country", obj);
    }
  };

  return (
    <React.Fragment>
      <>
        {requiredAlerts && (
          <ToastNotification
            message={_sharedService.Data.Labels["KXDocuments_DocumentAdmin_Alert"]}
            onCloseClick={toastNotificationClicked}
          />
        )}
      </>
      <main className="main-container ms-depth-8">
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-xl12 ms-sm12">
              <Stack horizontal verticalAlign="start" tokens={containerStackTokens}>
                <StackItem>
                  <Text className="text text--Xxl text--Bold text--LightDark editformtitle">
                    {props.editformData.title}
                  </Text>
                </StackItem>
              </Stack>
            </div>
          </div>

          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-xl12 ms-sm12">
              {docAdmin ?
                <Stack horizontal horizontalAlign="end" verticalAlign="center" tokens={containerStackTokens}>
                  {/* documentStatus ? documentStatus : undefined */}
                  {/* && props.savedStatus == "Archived"  */}
                  {(userPrivileges && userPrivileges.isShowDeleteBtn) &&
                    <PrimaryButton
                      className="ms-Button--sm"
                      text="Delete Document"
                      onClick={onDeleteEditForm}
                    />}
                  <DefaultButton
                    className="ms-Button--sm ms-Button--noBg btn-info"
                    text={_sharedService.Data.Labels["KXDocument_Cancel button_PublisherEditForm"]}
                    // onClick={() => { history.push(Constants.Routing.publisherDashBoard); }}
                    onClick={onCancelEditForm}
                  />
                  <PrimaryButton
                    className="ms-Button--sm"
                    text={_sharedService.Data.Labels["KXDocument_Submit Changes button_PublisherEditForm"]}
                    onClick={onSaveDocAdmin}
                  />
                </Stack> : ''}
            </div>
          </div>
        </div>
      </main>

      <main className="main-container">
        <div className="ms-Grid" dir="ltr">

          <div className="ms-Grid-col ms-xl9 ms-sm12">
            {docAdmin ? (
              <section className="content-section mt-20">
                <div className="docadmin">
                  <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                    <StackItem>
                      {docAdmin ? (
                        <Text className="text text--Xl text--Bold text--LightDark">
                          {_sharedService.Data.Labels["KXDocument_Publishing Information Subtitle_PublisherEditForm"]}
                        </Text>
                      ) : (
                        <Text className="text text--Xl text--Bold text--LightDark">
                          {_sharedService.Data.Labels["KXDocuments_DocumentAdmin_AuditLog"]} {props.docId}
                        </Text>
                      )}
                    </StackItem>
                  </Stack>
                </div>

                <div className="p-16 doc-admin-form">
                  <Stack wrap horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack wrap horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi">{_sharedService.Data.Labels["KXDocument_Document ID_PublisherEditForm"]} :</Text>
                      </StackItem>
                      <Text className="text text--Bold ml-10 w-180">
                        {props.docId}
                      </Text>
                    </Stack>

                    <Stack wrap horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className='w-150'>
                        <Text className="text text--Semi w-180"> {_sharedService.Data.Labels["KXDocument_Document Status_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <StackItem className="dropDown w-180">
                        <Dropdown
                          placeholder="--Select--"
                          options={docStatusOptions}
                          selectedKey={
                            documentStatus ? documentStatus : undefined
                          }
                          onChange={onDocumentStatusChange}
                        />
                      </StackItem>
                    </Stack>
                  </Stack>

                  <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack wrap horizontal verticalAlign="center" tokens={containerStackTokens}>
                      {datacollected && <>
                        <StackItem className="w-150">
                          <Text className="text text--Semi">
                            {_sharedService.Data.Labels["KXDocument_Content Publisher_PublisherEditForm"]}:
                          </Text>
                        </StackItem>
                        <StackItem className="publisherFields w-180">
                          <PeoplePickerBase
                            dataList={[]}
                            suggestionsHeaderText="Existing Content Publisher"
                            ContentType={false}
                            itemlimit={1}
                            defaultObj={
                              selectedContentPublisher
                                ? selectedContentPublisher
                                : ""
                            }
                            defaultValue={defaultContentPublisher ? [
                              {
                                name: defaultContentPublisher.displayName ? defaultContentPublisher.displayName : defaultContentPublisher.fullName,
                                key: defaultContentPublisher.email,
                              },
                            ] : []}
                            contentPublisher={true}
                            onSelectedItemChange={setcontentPublisher}
                            contentPublisherInvalidInput={(value: string) => props.setContentPublisherInvalidText(value)}
                          />
                        </StackItem></>}
                    </Stack>

                    <Stack wrap className="mt-10" horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className='w-150'>
                        <Text className="text text--Semi w-180"> {_sharedService.Data.Labels["KXDocument_Publishing Status_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <StackItem className="dropDown w-180">
                        <Dropdown
                          placeholder="--Select--"
                          options={publishingStatusOptions}
                          selectedKey={
                            publishingStatus ? publishingStatus : undefined
                          }
                          onChange={onPublishingStatusChange}
                          disabled={documentStatus !== 5}
                        />
                      </StackItem>
                    </Stack>
                  </Stack>

                  <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack horizontal verticalAlign="center" tokens={containerStackTokens}>
                      {datacollected && <>
                        <StackItem className="w-150">
                          <Text className="text text--Semi">{_sharedService.Data.Labels["KXDocument_QA Resource_PublisherEditForm"]}:</Text>
                        </StackItem>
                        <StackItem className="publisherFields w-180">
                          <PeoplePickerBase
                            dataList={[]}
                            suggestionsHeaderText="Existing QA Resource"
                            ContentType={false}
                            itemlimit={1}
                            defaultObj={
                              selectedQAResource
                                ? selectedQAResource
                                : ""
                            }
                            defaultValue={defaultQAResource ? [
                              {
                                name: defaultQAResource.displayName ? defaultQAResource.displayName : defaultQAResource.fullName,
                                key: defaultQAResource.email,
                              },
                            ] : []}
                            qaResource={true}
                            onSelectedItemChange={setQAResource}
                            qaResourceInvalidInput={(value) => props.setQaResourceInvalidText(value)}
                          />
                        </StackItem> </>}
                    </Stack>

                    <Stack wrap className="mt-10" horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className='w-150'>
                        <Text className="text text--Semi w-180">{_sharedService.Data.Labels["KXDocument_Follow-up Date_PublisherEditForm"]}:</Text>
                      </StackItem>
                      <StackItem className='w-180'>
                        <NewDatePickerBase
                          dateRangeType={DateRangeType.Day}
                          showGoToToday={true}
                          setSelectedDate={(date: any) => setSelectedFollowUpDate(date)}
                          setFromShowCalendar={setShowFollowUpFromDateCalendar}
                          setToShowCalendar={setShowFollowUpCalender}
                          showCalender={showFollowUpCalender}
                          selectedDate={selectedFollowUpDate}
                          placeHolder="Select Date"
                          dateType="DD MMM YYYY"
                          className='w-100'
                          showClearButton={selectedFollowUpDate ? true : false}
                          clearBtnClassName="followupBtnClass"
                          onClearBtnClick={() => setSelectedFollowUpDate(undefined)}
                        ></NewDatePickerBase>
                      </StackItem>
                    </Stack>
                  </Stack>

                  <Stack horizontal verticalAlign="start" tokens={containerStackTokens}>
                    <StackItem className="labelhead">
                      <Text className="text text--Semi">
                        {_sharedService.Data.Labels["KXDocument_Publishing Instructions_PublisherEditForm"]}:
                      </Text>
                    </StackItem>
                    <StackItem className="w-100 textarea-admin">
                      <TextField
                        className="enableScroll"
                        placeholder="Provide additional information to the publishing team."
                        multiline
                        rows={3}
                        resizable={false}
                        value={publishInstructiontext}
                        onChange={PublishingInstruction}
                      />
                    </StackItem>
                  </Stack>
                  {documentAdminData && documentAdminData.publishingInstruction ?
                    <ul className='docAdminComments'>
                      {documentAdminData &&
                        documentAdminData.publishingInstruction &&
                        documentAdminData.publishingInstruction.length > 0 &&
                        documentAdminData.publishingInstruction.slice(0, publisherInstructiontLength).map(
                          (item: any, i: number) => (
                            <li>
                              {item.publishingInstructionBy} (
                              {item.publishingInstructionDate !== null ? defaultDateTimeFormat(item.publishingInstructionDate) : ""}):{" "}
                              {item.publishingInstructionText}
                            </li>
                          )
                        )}
                      {documentAdminData && documentAdminData.publishingInstruction && documentAdminData.publishingInstruction.length > 4 && !pISeeMoreExpanded && <a onClick={onPISeeMoreClick} style={{ cursor: 'pointer' }}><Icon iconName="CaretDownSolid8" /> Show more </a>}
                      {documentAdminData && documentAdminData.publishingInstruction && documentAdminData.publishingInstruction.length > 4 && pISeeMoreExpanded && <a onClick={onPISeeMoreCollapse} style={{ cursor: 'pointer' }}><Icon iconName="CaretUpSolid8" /> Show less </a>}
                    </ul>
                    : ""
                  }

                  {datacollected &&
                    <Stack horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem >
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_Content Manager_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <StackItem className="publisherFields" style={{ width: '81%', marginLeft: 33 }}>
                        {<PeoplePickerBase
                          dataList={[]}
                          suggestionsHeaderText="Existing Content Manager"
                          ContentType={false}
                          defaultObj={
                            selectedContentManager
                              ? selectedContentManager
                              : ""
                          }
                          defaultValue={defaultContentManager}
                          contentManager={true}
                          onSelectedItemChange={setContentManager}
                          contentManagerInvalidInput={(value) => props.setContentManagerInvalidText(value)}
                        />}
                      </StackItem>
                    </Stack>
                  }

                  <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>


                    <Stack horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_Content Approver_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <StackItem className="publisherFields w-180">
                        {datacollected && <PeoplePickerBase
                          dataList={[]}
                          suggestionsHeaderText="Existing Content Approver"
                          ContentType={false}
                          itemlimit={1}
                          defaultObj={
                            selectedContentApprover
                              ? selectedContentApprover
                              : ""
                          }
                          // defaultValue={defaultContentPublisher}
                          defaultValue={defaultContentApprover ? [
                            {
                              name: defaultContentApprover.displayName ? defaultContentApprover.displayName : defaultContentApprover.fullName,
                              key: defaultContentApprover.email,
                            },
                          ] : []}
                          contentApprover={true}
                          onSelectedItemChange={setContentApprover}
                          contentApproverInvalidInput={(value) => props.setContentApproverInvalidText(value)}
                        />}
                      </StackItem>
                    </Stack>
                  </Stack>

                  <Stack horizontal verticalAlign="start" tokens={containerStackTokens}>
                    <StackItem className="labelhead">
                      <Text className="text text--Semi">
                        {_sharedService.Data.Labels["KXDocument_Approver Comments_PublisherEditForm"]}:
                      </Text>
                    </StackItem>
                    <StackItem className="w-100 textarea-admin">
                      <TextField
                        className="enableScroll"
                        placeholder="Provide additional information to the publishing team."
                        multiline
                        rows={3}
                        resizable={false}
                        value={approverCommenttext}
                        onChange={ApproverComment}
                      />
                    </StackItem>

                  </Stack>
                  {documentAdminData && documentAdminData.approverComment ?
                    <ul className='docAdminComments'>
                      {documentAdminData &&
                        documentAdminData.approverComment &&
                        documentAdminData.approverComment.length > 0 &&
                        documentAdminData.approverComment.slice(0, approverCommentsLength).map(
                          (item: any, i: number) => (
                            <li>
                              {item.commentBy} (
                              {defaultDateTimeFormat(item.commentDate)}):{" "}
                              {item.commentText}
                            </li>
                          )
                        )}
                      {documentAdminData && documentAdminData.approverComment && documentAdminData.approverComment.length > 4 && !aCSeeMoreExpanded && <a onClick={onACSeeMoreClick} style={{ cursor: 'pointer' }}><Icon iconName="CaretDownSolid8" /> Show more </a>}
                      {documentAdminData && documentAdminData.approverComment && documentAdminData.approverComment.length > 4 && aCSeeMoreExpanded && <a onClick={onACSeeMoreCollapse} style={{ cursor: 'pointer' }}> <Icon iconName="CaretUpSolid8" /> Show less </a>}
                    </ul>
                    : ""
                  }

                  <Stack wrap className="mt-20 dashactive" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_KX Dash Activity ID_PublisherEditForm"]}: </Text>
                      </StackItem>

                      <StackItem>
                        <CustomDropdown selected={setActivity} removeActivity={removeActivity} incomingActivity={selectedActivity} />
                      </StackItem>
                    </Stack>

                    <Stack horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> Bulk Id: </Text>
                      </StackItem>
                      <StackItem className="w-180">
                        <Text className="text text--Bold">
                          {(props.InitialDocumentAdminData && props.InitialDocumentAdminData.bulkId != undefined) ? props.InitialDocumentAdminData ? props.InitialDocumentAdminData.bulkId : '' : ''}
                        </Text>
                      </StackItem>
                    </Stack>
                  </Stack>

                  <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    {/* <Stack horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_Contributor Country_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <Text className="text text--Bold ml-10">
                        {props.editformData.countryOfOrigin
                          ? props.editformData.countryOfOrigin.name
                          : ""}
                      </Text>
                    </Stack> */}

                    <Stack horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_Contributor Country_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <StackItem>
                        <PeoplePickerBase
                          dataList={props.countryList}
                          itemlimit={1}
                          suggestionsHeaderText="Existing Contributor Country"
                          placeholder="Select Contributor Country"
                          ContentType={false}
                          countryOfOrigin={true}
                          selectedItems={selctedContributorCountryValue}
                          onSelectedItemChange={setContributorCountrySelection}
                        />
                      </StackItem>
                      {(selctedContributorCountryValue == undefined ||
                        selctedContributorCountryValue.length < 1 ||
                        selctedContributorCountryValue == null) && (
                          <StackItem className="setContentType">
                            <i
                              className="icon-kx-plus text--Md c-p"
                              onClick={() => {
                                setContributorCountryModal(true);
                              }}
                            ></i>
                          </StackItem>
                        )}
                    </Stack>

                    <Stack horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_Contribution Date_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <Text className="text text--Bold w-180"> {contributionDate ? contributionDate : ""} </Text>
                    </Stack>
                  </Stack>

                  <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_Planned Archive Date_PublisherEditForm"]}
                          <span className="editForm-required">*</span>:
                        </Text>
                      </StackItem>
                      <StackItem className='w-180'>
                        <NewDatePickerBase
                          dateRangeType={DateRangeType.Day}
                          showGoToToday={true}
                          setSelectedDate={setSelectedArchiveDate}
                          setFromShowCalendar={setShowArchiveFromDateCalendar}
                          setToShowCalendar={setShowArchiveCalender}
                          showCalender={showArchiveCalender}
                          selectedDate={selectedArchiveDate}
                          dateInWords={true}
                          placeHolder="Select Date"
                          dateType="DD MMM YYYY"
                          className='w-100'
                          showClearButton={selectedArchiveDate ? true : false}
                          onClearBtnClick={() => setSelectedArchiveDate(undefined)}
                          clearBtnClassName={"plannedArchivalDateBtn"}
                        />
                      </StackItem>
                      <b> <a onClick={CalculateArchiveYear} style={{ cursor: 'pointer' }}>Calculate</a></b>
                    </Stack>

                    <Stack wrap className="mt-10" horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_Update Requested Date_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <StackItem className="w-180">
                        <NewDatePickerBase
                          dateRangeType={DateRangeType.Day}
                          showGoToToday={true}
                          setSelectedDate={setRequestedDate}
                          setFromShowCalendar={
                            setShowRequestedFromDateCalendar
                          }
                          setToShowCalendar={setShowRequestedCalender}
                          showCalender={showRequestedCalender}
                          selectedDate={requestedDate}
                          dateInWords={true}
                          placeHolder="Select Date"
                          dateType="DD MMM YYYY"
                          className='w-100'
                          showClearButton={requestedDate ? true : false}
                          onClearBtnClick={() => setRequestedDate(null)}
                          clearBtnClassName={"requestDateBtn"}
                        />

                      </StackItem>
                    </Stack>
                  </Stack>

                  {archiveDateNearFlag && <p>{_sharedService.Data.Labels["KXDocument_Admin_ArchivalMessage"]}</p>}
                  <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi">
                          {_sharedService.Data.Labels["KXDocument_Search Keywords_PublisherEditForm"]}:
                        </Text>
                      </StackItem>
                      <StackItem className="publisherFields w-180">
                        <InputTextbox
                          id="searchKeywords"
                          //placeholder="Enter Title"
                          value={searchKeywordText}
                          onChange={searchKeywordsData}
                        />
                      </StackItem>
                    </Stack>
                    <Stack horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_Requested By_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <Text className="text text--Bold w-180"> {(props.editformData && props.editformData.requestedBy && props.editformData.requestedBy.displayName) ? props.editformData.requestedBy.displayName : ""} </Text>
                    </Stack>
                  </Stack>

                  <Stack horizontal verticalAlign="start" tokens={containerStackTokens}>
                    <StackItem className="labelhead">
                      <Text className="text text--Semi">
                        {_sharedService.Data.Labels["KXDocument_Publishing Notes_PublisherEditForm"]}:
                      </Text>
                    </StackItem>
                    <StackItem className="w-100 textarea-admin">
                      <TextField
                        className="enableScroll"
                        placeholder="Provide additional information to the publishing team."
                        multiline
                        rows={2}
                        resizable={false}
                        value={publishNotestext}
                        onChange={PublishNotes}
                      />
                    </StackItem>
                  </Stack>
                  {documentAdminData && documentAdminData.publishingNotes ?
                    <ul className='docAdminComments'>
                      {documentAdminData &&
                        documentAdminData.publishingNotes &&
                        documentAdminData.publishingNotes.length > 0 &&
                        documentAdminData.publishingNotes.slice(0, PublisherNotesLength).map(
                          (item: any, i: number) => (
                            <li>
                              {item.publishingNotesBy} (
                              {defaultDateTimeFormat(item.publishingNotesDate)}):{" "}
                              {item.publishingNotesText}
                            </li>
                          )
                        )}
                      {documentAdminData && documentAdminData.publishingNotes && documentAdminData.publishingNotes.length > 4 && !pNSeeMoreExpanded && <a onClick={onPNSeeMoreClick} style={{ cursor: 'pointer' }}><Icon iconName="CaretDownSolid8" /> Show more </a>}
                      {documentAdminData && documentAdminData.publishingNotes && documentAdminData.publishingNotes.length > 4 && pNSeeMoreExpanded && <a onClick={onPNSeeMoreCollapse} style={{ cursor: 'pointer' }}> <Icon iconName="CaretUpSolid8" /> Show less </a>}
                    </ul>
                    : ""
                  }

                  <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack wrap horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_System Source_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <StackItem className="dropDown">
                        <Dropdown
                          placeholder="--Select--"
                          options={systemSourceOptions}
                          selectedKey={
                            systemSource ? systemSource : undefined
                          }
                          onChange={onSystemSourceChange}
                        />
                      </StackItem>
                    </Stack>

                    <Stack wrap horizontal verticalAlign="center" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi">{_sharedService.Data.Labels["KXDocument_Published Date_PublisherEditForm"]}:</Text>
                      </StackItem>
                      <Text className="text text--Bold ml-10 w-180 d-inline-block"> {publishedDate} </Text>
                    </Stack>
                  </Stack>

                  <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack wrap horizontal verticalAlign="start" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi">
                          {_sharedService.Data.Labels["KXDocument_Number of Attachments_PublisherEditForm"]}:
                        </Text>
                      </StackItem>
                      <Text className="text text--Bold">
                        {props.editformData.attachmentDetails
                          ? props.editformData.attachmentDetails.length
                          : 0}
                      </Text>
                    </Stack>

                    <Stack wrap horizontal verticalAlign="start" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_Contribution Team_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <Text className="text text--Bold ml-10 w-180 d-inline-block">
                        {contributingTeam}
                      </Text>
                    </Stack>
                  </Stack>

                  <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack wrap horizontal verticalAlign="start" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_Publishing Priority_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <div className="text text--Bold"> {props.editformData.publishingPriority ? props.editformData.publishingPriority : "Low"} </div>
                    </Stack>

                    <Stack wrap horizontal verticalAlign="start" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_Type of Request_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <div className="text text--Bold ml-10 w-180 d-inline-block">{props.editformData.typeOfRequest}</div>
                    </Stack>
                  </Stack>

                  {/* <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack wrap horizontal verticalAlign="start" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_SLA (Contribution)_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <Text className="text text--Bold w-150"> 2 </Text>
                    </Stack>

                    <Stack wrap horizontal verticalAlign="start" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi"> {_sharedService.Data.Labels["KXDocument_SLA (Update Request)_PublisherEditForm"]}: </Text>
                      </StackItem>
                      <Text className="text text--Bold ml-10 w-180 d-inline-block"> 3 </Text>
                    </Stack>
                  </Stack> */}

                  <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack wrap horizontal verticalAlign="start" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi">{_sharedService.Data.Labels["KXDocument_Created By_PublisherEditForm"]}:</Text>
                      </StackItem>
                      <Text className="text text--Bold">
                        {props.editformData.createdBy
                          ? props.editformData.createdBy.displayName
                          : ""}
                      </Text>
                    </Stack>

                    <Stack wrap horizontal verticalAlign="start" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi">{_sharedService.Data.Labels["KXDocument_Created Date_PublisherEditForm"]}:</Text>
                      </StackItem>
                      <Text className="text text--Bold ml-10 w-180 d-inline-block">
                        {createdDate ? createdDate : ""}
                      </Text>
                    </Stack>
                  </Stack>

                  <Stack wrap className="mt-20" horizontal horizontalAlign="space-between" verticalAlign="center" tokens={containerStackTokens}>
                    <Stack wrap horizontal verticalAlign="start" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi">{_sharedService.Data.Labels["KXDocument_Modified By_PublisherEditForm"]}:</Text>
                      </StackItem>
                      <Text className="text text--Bold">
                        {props.editformData.modifiedBy
                          ? props.editformData.modifiedBy.displayName
                          : ""}
                      </Text>
                    </Stack>

                    <Stack wrap horizontal verticalAlign="start" tokens={containerStackTokens}>
                      <StackItem className="w-150">
                        <Text className="text text--Semi">{_sharedService.Data.Labels["KXDocument_Modified Date_PublisherEditForm"]}:</Text>
                      </StackItem>
                      <Text className="text text--Bold ml-10 w-180 d-inline-block">
                        {modifiedDate ? modifiedDate : ""}
                      </Text>
                    </Stack>
                  </Stack>
                </div>
              </section>
            ) : (
              <AuditLog documentId={props.docId} documentStatus={docStatusOptions} publishingStatus={publishingStatusOptions} />
            )}
          </div>

          <div className="ms-Grid-col ms-xl3 ms-sm12">
            <main className="pl-10">
              <Stack horizontalAlign="start" tokens={containerStackTokens}>
                <StackItem
                  className="c-p"
                  onClick={() => {
                    props.setdocumentAdmin();
                    sendGetDocAdminData();
                    backToEditForm()
                  }}
                >
                  <i className="icon-kx-chevron-left text--Sm text--Bold text--Link"></i>
                  <Text className="text text--Bold text--Link ml-10">
                    {_sharedService.Data.Labels["KXDocument_Back to Edit form RHN_PublisherEditForm"]}
                  </Text>
                </StackItem>
                {docAdmin ? (
                  <Text className="text text--Semi">
                    {_sharedService.Data.Labels["KXDocument_Publishing Information RHN_PublisherEditForm"]}
                  </Text>
                ) : (
                  <Text className="text text--Semi">{_sharedService.Data.Labels["KXDocument_Audit Log RHN_PublisherEditForm"]}</Text>
                )}
                {docAdmin ? (
                  <StackItem
                    className="c-p"
                    onClick={() => setDocAdmin(false)}
                  >
                    <i className="icon-kx-file-text text--Sm text--Bold text--Link"></i>
                    <Text className="text text--Bold text--Link ml-10">
                      {_sharedService.Data.Labels["KXDocument_Audit Log RHN_PublisherEditForm"]}
                    </Text>
                  </StackItem>
                ) : (
                  <StackItem
                    className="c-p"
                    onClick={() => {
                      setDocAdmin(true);
                    }}
                  >
                    <i className="icon-kx-file-text text--Sm text--Bold text--Link"></i>
                    <Text className="text text--Bold text--Link ml-10">
                      {_sharedService.Data.Labels["KXDocument_Publishing Information RHN_PublisherEditForm"]}
                    </Text>
                  </StackItem>
                )}
              </Stack>
            </main>
          </div>

        </div>

      </main>
      <div className="checkboxModal">
        <CheckBoxTree
          checkedTreeValues={checkedContributorCountryValues}
          onCheck={setCheckedContributorCountryValues}
          modalView={contributorCountryModal}
          onmodalviewChange={() => setContributorCountryModal(!contributorCountryModal)}
          onCancelClick={contributorCountryCancelClick}
          onSaveChanges={saveContributorCountry}
          multipleSelect={false}
          tableID={Constants.TableIdsForTree.CountryOfOrigin.tableId}
          memberFirmId={
            Constants.TableIdsForTree.CountryOfOrigin.memberFirmId
          }
          treeType="CountryType"
          heading={
            "Select the country of origin. Please be as specific as possible."
          }
          flatArrayData={props.countryList}
          flatData={props.countryList}
        />
      </div>

      <PopupDialog
        title={_sharedService.Data.Labels["KXDocuments_UserEditForm_Cancel_Lose unsaved changes_Title"]}
        subText={_sharedService.Data.Labels["KXDocuments_UserEditForm_Cancel_Lose unsaved changes_Subtext"]}
        hideDialog={hideDialogCancel}
        toggleHideDialog={toggleHideDialogCancel}
        btn1text="Yes"
        onClickBtn1={onClickCancelYes}
        btn2text="No"
        onClickBtn2={onClickCancelNo}
        hideTopBtn={true}
      />
      <PopupDialog
        title={_sharedService.Data.Labels["KXDocuments_UserEditForm_Delete Document"]}
        //subText="Are you sure you want to cancel? You will lose all your progress."
        hideDialog={hideDialogDelete}
        toggleHideDialog={toggleHideDialogDelete}
        btn1text="Yes"
        onClickBtn1={onClickDeleteYes}
        btn2text="No"
        onClickBtn2={onClickDeleteNo}
        hideTopBtn={true}
      />

      <PopupDialog
        title={_sharedService.Data.Labels["KXDocuments_UserEditForm_Archive Document_Title"]}
        subText={_sharedService.Data.Labels["KXDocuments_UserEditForm_Archive Document_Subtext"]}
        hideDialog={hideDialogArchive}
        toggleHideDialog={toggleHideDialogArchive}
        btn1text="Ok"
        onClickBtn1={onClickArchiveYes}
        btn2text="Cancel"
        onClickBtn2={onClickArchivehNo}
        hideTopBtn={true}
      />

      <PopupDialog
        title={_sharedService.Data.Labels["KXDocuments_UserEditForm_Unarchive Document_Title"]}
        subText={_sharedService.Data.Labels["KXDocuments_UserEditForm_Unarchive Document_Subtext"]}
        hideDialog={hideDialogUnarchive}
        toggleHideDialog={toggleHideDialogUnarchive}
        btn1text="Ok"
        onClickBtn1={onClickUnArchiveYes}
        btn2text="Cancel"
        onClickBtn2={onClickUnArchivehNo}
        hideTopBtn={true}
      />
    </React.Fragment>
  );
}

export default DocumentAdmin;