import * as React from "react";
import "./MenuOption.scss";

/**
 * This Interface represents the structure of response modifier menu item
 */
interface IResponseModifierMenu {
  id: number;
  modifierLabel: string;
  modifierPrompt: string;
}

/**
 * Interface for MenuOption component props
 */
interface IMenuOptionProps {
  menuList: Array<string | IResponseModifierMenu>;
  clickHandler: (option: string) => void;
  className?: string;
  analyticsIdentifier?: string;
}

/**
 * MenuOption Component
 *
 * This component represents a menu with options.
 * It is typically used in conjunction with other components like GeneralResponse.
 */
const MenuOption: React.FunctionComponent<IMenuOptionProps> = ({
  menuList,
  clickHandler,
  className,
  analyticsIdentifier,
}) => {
  /**
   * Render Menu Options
   *
   * This function maps through the menuList and renders each option as a clickable span element.
   * It also attaches the clickHandler function to handle the click event for each option.
   *
   * @param {Array<string>} menuList - The list of menu options to be rendered.
   */
  const renderMenuOptions = () => {
    const renderAnayticsIdentifier = (typeIndex: string) => {
      switch (typeIndex) {
        case "Bad Response-0":
          return "Tools>Bad Response|Inaccurate";
        case "Bad Response-1":
          return "Tools>Bad Response|Irrelevant";
        case "Bad Response-2":
          return "Tools>Bad Response|Repetitive";
        case "Modify-0":
          return "Tools>Modify|Make shorter";
        case "Modify-1":
          return "Tools>Modify|Make longer";
        case "Modify-2":
          return "Tools>Modify|Bulleted";
      }
    };

    return menuList.map((item, idx) => (
      <span
        className="item"
        onClick={() =>
          clickHandler(typeof item === "string" ? item : item.modifierPrompt)
        }
        key={idx}
        digitaldata-prompt={renderAnayticsIdentifier(
          `${analyticsIdentifier}-${idx}`
        )}
      >
        {typeof item === "string" ? item : item.modifierLabel}
      </span>
    ));
  };

  /**
   * Render the MenuOption component.
   *
   * @returns {JSX.Element} - The rendered MenuOption component.
   */
  return (
    <div className={`menuList_container ${className}`}>
      {renderMenuOptions()}
    </div>
  );
};

/**
 * Export the MenuOption component as default
 */
export default MenuOption;
