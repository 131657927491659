import { REMOVE_ARCHIVED, ADD_DATA_TO_PERSONAL_COLLECTION, personallCollectionState, PersonalCollectionTypeActions } from '../types'

const initialState: personallCollectionState = {
  cards: []
}


export function personalCollectionReducer( state = initialState, action: PersonalCollectionTypeActions) {
  switch (action.type) {
    case ADD_DATA_TO_PERSONAL_COLLECTION:
    return {
        ...state,
        cards: action.payload
        }

    case REMOVE_ARCHIVED:
      return  {
        ...state,
        cards: action.payload
        }

    default:
      return state
  }
}
