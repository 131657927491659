import React from "react";
import { CommonFunctions } from "../../../helpers/CommonFunctions";

interface ILinkGeneratorProps {
  linkTemplateText: string;
}

let fillTemplate = function (templateString: string): JSX.Element {
  // eslint-disable-next-line no-new-func
  if(!CommonFunctions.isIE()){

    let fn = new Function(
      "return FormatStringWithLinks`" + templateString + "`;"
    );
    let elementArr: JSX.Element[] = fn();
    return <>{elementArr.map((element) => element)}</>;
  }
  else{
    return <></>
  }
};

const LinkGenerator: React.FC<ILinkGeneratorProps> = (props) => {
  return fillTemplate(props.linkTemplateText);
};

export default LinkGenerator;
