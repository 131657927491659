import { Stylesheet, TextField } from "office-ui-fabric-react";
import React from "react";
import styles from "./inputTextbox.module.scss";

export interface inputTextboxProps {

    id?: string;
    placeholder?: string;
    onChange?:any;
    value?:any;
}


export const InputTextbox: React.FunctionComponent<inputTextboxProps> = (props) => {

  return (
    <TextField id={props.id} placeholder={props.placeholder} value={props.value} onChange={props.onChange}/>
  );
};