import React, { createContext, useState, useContext } from "react";

interface ResponseAnalyticsTrackerContextProps {
  responseTriggered: boolean;
  setResponseTriggered: (triggered: boolean) => void;
}

const ResponseAnalyticsTrackerContext =
  createContext<ResponseAnalyticsTrackerContextProps>({
    responseTriggered: false,
    setResponseTriggered: () => {},
  });

export const ResponseAnalyticsTrackerProvider: React.FC = ({ children }) => {
  const [responseTriggered, setResponseTriggered] = useState(false);

  return (
    <ResponseAnalyticsTrackerContext.Provider
      value={{ responseTriggered, setResponseTriggered }}
    >
      {children}
    </ResponseAnalyticsTrackerContext.Provider>
  );
};

export const useResponseAnalyticsTracker = () => {
  const context = useContext(ResponseAnalyticsTrackerContext);
  if (!context) {
    throw new Error(
      "useResponseAnalyticsTracker must be used within a ResponseAnalyticsTrackerProvider"
    );
  }
  return context;
};
