import { IOverflowSetItemProps, Link, OverflowSet, SearchBox, Stack } from "office-ui-fabric-react";
import React, { useState, useEffect } from "react";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { Constants } from "../../../helpers/Constants";
import { ICategory, OverflowActions } from "../../../models/IShared";
import { CardData, CardSchema, ICollectionCardData, IPeopleCardData, PersonalCollection } from "../../../models/SharedModels";
import { AnalyticsService } from "../../../services/AnalyticsService";
import { SharedService } from "../../../services/SharedService";
import { CheckboxBase } from "../checkboxBase/checkboxBase";
import useProtectedActionButtons from "../customHooks/useProtectedActionButtons";
import { ModalBase } from "../modalBase/modalBase";
import { TagList } from "../tagList/tagList";
import { TooltipBase } from "../tooltipBase/tooltipBase";
import "./compareArea.scss";
import "../../shared/sectionHeading/sectionHeading.scss";

export interface CompareContentPageItemProps {
    card: CardSchema;
    itemIndex: number;
    isChecked: boolean;
    removeHandler: (event:any, itemIndex: number) => void;
    handleCheck: (item: CardSchema, isChecked: boolean) => void;
    setTagsHeight: (childClassName:string, parentClassname:string, parentHeadingName:string) => void;
  }

export const CompareContentPageItem: React.FunctionComponent<CompareContentPageItemProps> = (props) => {
  const _sharedService = SharedService.GetInstance();
  const [cardData, setCardData] = useState<any>(props.card);
  const [indexKey, setIndexKey] = useState<any>(0);
  const [categories, setCategories] = useState<any>([]);
  const [filteredCategories, setFilteredCategories] = useState<any>([]);
  const [isOpenPinModal, setIsOpenPinModal] = useState<boolean>(false);
  const [cardToPin, setCardToPin] = useState<any>([]);
  const [cardsState, setCardsState] = useState<any>([]);
  const [pinListener, setPinListener] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [newCategory, setNewCategory] = useState<string>("");
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [existName, setExistName] = useState<boolean>(false);
  const [isNameEmpty, setIsNameEmpty] = useState<boolean>(false);
  const [isNameCharLimitExceeded, setIsNameCharLimitExceeded] = useState<boolean>(false);

  const protectedOverflowButtons = useProtectedActionButtons(
    props.card.listTitle,
    props.card.overflowItemsData[0].overflowItems
  );
  props.card.allData.concepttags = props.card.allData.concepttags ? props.card.allData.concepttags.filter( function(tag:any) { return tag.text ? tag.text.trim() !== "" : tag.name.trim() !== "" }) : [];
  props.card.allData.contenttags = props.card.allData.contenttags ? props.card.allData.contenttags.filter( function(tag:any) { return tag.text ? tag.text.trim() !== "" : tag.name.trim() !== "" }) : [];
  const tags = props.card.allData.contenttags && props.card.allData.concepttags ? [...props.card.allData.contenttags, ...props.card.allData.concepttags]
  : props.card.allData.contenttags && !props.card.allData.concepttags ? props.card.allData.contenttags 
  : !props.card.allData.contenttags && props.card.allData.concepttags ? props.card.allData.concepttags 
  : [];
  
  useEffect(() => {
    let setCardProperties = async () => {
      props.card.isChecked = false;
      await checkIfPinnedByCurrentUser(props.card);
      var pinCount= await _sharedService.Actions.Get.GetPinCount(
        props.card.linkToItem
      );
      props.card.numberOfPins = pinCount;

      var application=SharedService.GetInstance().Data.Applications.filter((app:any)=>{
        return app.Title === props.card.allData.source;
      })
      let badgesNew= props.card.allData.badges.map((badge:any) => {
        if(badge.id && badge.id !== " ")
        return {
          ExternalId:badge.id,
          BadgeSource:application[0].IdApp
        }
      })
      let data = await SharedService.GetInstance().SiteSettings.Read.GetBadgesFromApi(badgesNew);
      if(data && data.length > 0){
        var badgesNew2 = props.card.allData.badges.slice(0).reverse().map((badge:any,index:number)=>{
          if(index<3){

            var exist=data.filter((badgeApi:any)=>badgeApi.title === badge.name);
            if(exist&&exist.length>0)
            return exist[0]
          }
        }).filter((data:any)=>data)
        props.card.allData.badgesDB = badgesNew2;
      }
      else 
        props.card.allData.badgesDB=[];
      
      if(props.card.allData.topictags && props.card.allData.topictags.length > 0)
        props.card.allData.topictags = props.card.allData.topictags.filter( function(tag:any) { return tag.text ? tag.text.trim() !== "" : tag.name.trim() !== "" });
      
        setCardsState(props.card);
      if(props.card.allData.badgesDB.length > 0) {
        setTimeout(() => {
          props.setTagsHeight("badges", "compare-content-page-item badge", "compare-content-page-table-headings-child-badges");
        }, 2000);
      }
    };
    setCardProperties();
  }, []);
  const onRenderItem = (
    item: IOverflowSetItemProps,
    cardData: any
  ): JSX.Element => {
    return (
      <div>
        <Link
          role="menuitem"
          onClick={(e: any) => {
            switch (item.key) {
              case Constants.ActionsButtonsKeys.PinToCollection:
                pinUnPin(e, item, cardData);
                break;
              case Constants.ActionsButtonsKeys.CopyLink:
                //copyLink(e, item);
                e.persist();
                setTimeout(() => copyLink(e, item, true, false), 3000);
                break;
            }
          }}
        >
          {item.iconProps && (
            <i className={item.iconProps.className + " icon-kx"}></i>
          )}
          {item.name}
        </Link>
      </div>
    );
  };
  const copyLink = (
    event: any,
    original: any,
    second?: boolean,
    doTracking: boolean = false
  ) => {
    original = CommonFunctions.copyLink(event, original, props.card, doTracking);
    var index = props.card.overflowItemsData[0].overflowItems.findIndex(
      (data: any) => data.key == Constants.ActionsButtonsKeys.CopyLink
    );
    props.card.overflowItemsData[0].overflowItems[index] = original;
    setCardData(props.card);
    if (second) setIndexKey(indexKey + 2);
    else setIndexKey(indexKey + 1);
    compareItemTracking(Constants.MiniCardActions.CopyLink, props.card);
  };
  const pinUnPin = async (event: any, original: any, card: CardData) => {
    original = await CommonFunctions.pinUnPin(
      event,
      original,      
      card,
      openModalAndGetCategories,
      false
    );
    var cardNew = card;
    var index = cardNew.overflowItemsData[0].overflowItems.findIndex(
      (data: any) => data.key == Constants.ActionsButtonsKeys.PinToCollection
    );
    cardNew.overflowItemsData[0].overflowItems[index] = original;
    setCardData(card);
    setIndexKey(indexKey + 1);
  };
  const getNoExplainOverflowItems = (
    items: OverflowActions[],
  ) => {
    let filteredItems = items.filter((item) => {
      if (
        (item.key && item.key !== Constants.ActionsButtonsKeys.CopyLink &&
          item.key !== Constants.ActionsButtonsKeys.PinToCollection
         )) {
        return false;
      }
      return true;
    });
    return filteredItems;
  };
  const titleClickHandler = (event: any, card: CardSchema) => {
    compareItemTracking(Constants.MiniCardActions.ViewFullContent, card);
    
    window.open(card.allData.appurl, '_blank');
  }
  const compareItemTracking = (action:string, card:CardSchema) => {
      let compareTracker = {
        action: action,
        title: card.allData.title,
        url: card.allData.appurl,
        position: (props.itemIndex + 1).toString(),
        sourceSystem: card.allData.internalcontenttype,
        sourceContentType: card.allData.apptype,
        contentID: card.allData.appid
      };
    AnalyticsService.trackComparePage(compareTracker);
  }
  const openModalAndGetCategories = (original: any, card: any, doTracking: any) => {
    return new Promise((resolve, reject) => {
      if ( card && card.linkToItem && 
        original &&
        original.name ==
        SharedService.GetInstance().Data.Labels[
        Constants.ActionsButtonsNames.pin_to_Collection
        ]
      ) {
        _sharedService.Actions.Get.GetPersonalCollectionCategories().then((categories) => {
          var isSelected=false
          categories.map(async (category: ICategory, index: number) => {
  
            if(category.Count<100&&isSelected==false){
              category.isSelected=true
              isSelected=true;
            }
          })
          setCategories(categories)
          setFilteredCategories(categories)
          setIsOpenPinModal(true);
          setCardToPin(card);
          resolve(original);
        })
      } else if ( card && card.linkToItem && original) {
        SharedService.GetInstance()
          .Actions.Post.PinUnPin(
            new PersonalCollection(
              card.ctName,
              "uncategorized",
              card.linkToItem,
              card.source === Constants.DPN || (card.allData && card.allData.source === Constants.DPN) ? card.title : card.title.text ? card.title.text : card.allData.title,
              card.desc,
              card.image,
              card.sourceId,
              card.allData.internalcontenttype
            )
          )
          .then((response) => {
           
            if (original) {
              if (
                original &&
                original.name !==
                  SharedService.GetInstance().Data.Labels[
                    Constants.ActionsButtonsNames.pin_to_Collection
                  ]
              ) {
                compareItemTracking(Constants.MiniCardActions.Unpin, props.card);
              }
              original.name =
                SharedService.GetInstance().Data.Labels[
                Constants.ActionsButtonsNames.pin_to_Collection
                ];
              card.ifPinCard=false
            }
            resolve(original);
          });
      }else resolve(original);
  
    }) 
    

  };
  var searchTermChanged = (
  event: any
  ) => {
  let _uInput = event && event.target && event.target.value;
  if(_uInput.length<=255){

      setSearchTerm(_uInput)
      var categoriesParse = JSON.parse(JSON.stringify(categories));
      var filteredCategories = categoriesParse.filter((category: ICategory) => {
      return category.Label.indexOf(_uInput) != -1
      })
      setFilteredCategories(filteredCategories);
  }else{
      setError("top 255 chars")
  }

  };
  var onClickCategory = (e: any,id:number) => {
  var list = document.getElementsByClassName("categoriesList");
  for (var i = 0; i < list.length; i++) {
      var el = list[i]
      if (el && el.classList.contains("selectedCategory")) {
      el.classList.remove("selectedCategory");
      }
  }
  setSelectedCategoryId(id)
  e.currentTarget.classList.add("selectedCategory")
  }
  var createNewCategoryAndPin=async(cardsState:any)=>{
    if (newCategory) {
      var existName = await SharedService.GetInstance().Actions.Get.GetIfExistCategoryPersonalCollection(newCategory);
      if (!existName) {
        setIsNameEmpty(false);
        setExistName(false);
        if(newCategory.length < 255) {
          setIsNameCharLimitExceeded(false);
          SharedService.GetInstance().Actions.Get.AddPersonalCollectionCategory(newCategory).then((data: any) => {
            setSelectedCategoryId(data)
            savePinToCategory(cardsState,data)
          })
        }
        else setIsNameCharLimitExceeded(true);
      }
      else {
        setIsNameEmpty(false);
        setExistName(true);
        setIsNameCharLimitExceeded(false);
      }
    } else {
      setExistName(false);
      setIsNameEmpty(true);
      setIsNameCharLimitExceeded(false);
    }
  }
  var savePinToCategory=(cardsState:any,categoryId:any=selectedCategoryId)=>{
    var card:any= props.card;
    if(card && card.linkToItem) {
      SharedService.GetInstance().Actions.Post.PinUnPin(
          new PersonalCollection(
          card.ctName,
          "uncategorized",
          card.linkToItem,
          card.source === Constants.DPN || (card.allData && card.allData.source === Constants.DPN) ? card.title : card.title && card.title.text ? card.title.text : card.allData.title,
          card.desc,
          card.image,
          card.sourceId,
          card.allData.internalcontenttype,
          categoryId
          )
      )
      .then(async (response) => {
          if (card.overflowItemsData) {
            await checkIfPinnedByCurrentUser(card);
            setCardsState(cardsState);
            setPinListener(true);
            setIsOpenPinModal(false);
            compareItemTracking(Constants.MiniCardActions.Pin, props.card);
          }
      });
    }
  }
  var checkIfPinnedByCurrentUser = async (card:CardSchema) => {
    let pins = await SharedService.GetInstance().Actions.Get.GetIfPins([card.linkToItem]);
    var ifPin =
    pins && pins.length > 0
        ? pins.filter((pin: any) => pin.LinkToItem == card.linkToItem)
        : [];

    if (ifPin.length > 0) {
      var indexPin =
          card.overflowItemsData[0] &&
          card.overflowItemsData[0].overflowItems.find(
          (item: any) => item.key == Constants.ActionsButtonsKeys.PinToCollection
          );
      if (indexPin){
          indexPin.name =
          SharedService.GetInstance().Data.Labels[
          Constants.ActionsButtonsNames.unPin
          ];
          card.ifPinCard=true
      }else{
          card.ifPinCard=false

      }
    }
  }
  return (
    <div className="compare-content-page-item-container">
      <div className='compare-content-page-item-title'>
        <div className='title-text'>
        <TooltipBase content={props.card.allData.title}>
          <Link target='_blank' onClick={(e: any) => {titleClickHandler(e, props.card)}}>{props.card.allData.title}</Link>
        </TooltipBase>
        </div>
        <div className='action-btns'>
          <CheckboxBase 
              className='compare-content-page-item-chkbox'
              key={props.itemIndex}
              label=''
              isChecked={props.isChecked }
              onGroupChange={() => props.handleCheck(props.card, props.isChecked)}
          />
          <Link className="compare-item-remove" onClick={(e: any) => {props.removeHandler(e, props.itemIndex)}}>{_sharedService.Data.Labels[Constants.LabelKeys.ComparePageRemoveText]}</Link>
        </div>
      </div>
      <div className='compare-content-page-item'>
          <span>{props.card.allData.globalcontenttype ? props.card.allData.globalcontenttype : "N/A"}</span>
      </div>
      <div className='compare-content-page-item badge'>
      {props.card.allData.badgesDB && props.card.allData.badgesDB.length > 0 ? (
          <div className="badges"style={{"height" : 36.5*props.card.allData.badgesDB.length + "px"}}>
          <div className="ms-Grid-row">
            {props.card.allData.badgesDB && props.card.allData.badgesDB.length > 0 ? props.card.allData.badgesDB.map((badge: any) => {
              return badge.title.trim() !== "" ? (
                <div className="ms-Grid-col ms-lg4">
                  <Stack horizontal>
                    <TooltipBase content={<div ><h3>{badge.title}</h3><div>{badge.description}</div></div>}>
                        <div className="heading-icon">
                          <img alt='' width="24px" src={SharedService.SpDomainUrl + SharedService.spSiteUrl + badge.iconImg} />
                        </div>
                    </TooltipBase>
                        <p className="compare-page-item-badge-title">{badge.title}</p>
                  </Stack>
                </div>
              ) : null;
            }): "N/A"}
          </div>
        </div>
      ) : "N/A"}
      </div>
      <div className='compare-content-page-item attachments'>
          <span>{props.card.allData.kxa_attachments ? props.card.allData.kxa_attachments : "N/A"}</span>
      </div>
      <div className='compare-content-page-item-description'>
      {props.card.allData.globalcontenttype === 'Quals' ?
          <TooltipBase content={props.card.allData.kxq_basicprojectsummary ? props.card.allData.kxq_basicprojectsummary.replace(/<[^>]*>/g, '') : "N/A"}>
            <span dangerouslySetInnerHTML={{ __html: props.card.allData.kxq_basicprojectsummary ? props.card.allData.kxq_basicprojectsummary : "N/A" }}></span>
          </TooltipBase>
          : <TooltipBase content={props.card.allData.kxa_abstract ? props.card.allData.kxa_abstract : "N/A"}>
              <span dangerouslySetInnerHTML={{ __html: props.card.allData.kxa_abstract ? props.card.allData.kxa_abstract : "N/A" }}></span>
            </TooltipBase>
      }
      </div>
      <div className='compare-content-page-item'>
          <span>{props.card.allData.globalcontenttype === 'Quals' 
          ? (
          props.card.allData.kxq_projectstartdate && props.card.allData.kxq_projectenddate 
          ? props.card.allData.kxq_projectstartdate + "-" + props.card.allData.kxq_projectenddate 
          : props.card.allData.kxq_projectstartdate ? props.card.allData.kxq_projectstartdate 
          : props.card.allData.kxq_projectenddate ? props.card.allData.kxq_projectenddate  : "N/A"
          ) 
          : props.card.allData.kxa_contentdate 
          ? props.card.allData.kxa_contentdate
          : "N/A"}</span>
      </div>
      <div className='compare-content-page-item'>
          <span>{props.card.allData.geography ? props.card.allData.geography : "N/A"}</span>
      </div>
      <div className='compare-content-page-item'>
      {props.card.allData.globalcontenttype === 'Quals' ?
          <span>{props.card.allData.kxq_viewableclientname ? props.card.allData.kxq_viewableclientname : "N/A"}</span>
          : <span>{props.card.allData.kxa_client ? props.card.allData.kxa_client : "N/A"}</span>
      }
      </div>
      <div className='compare-content-page-item'>
          <span>{props.card.allData.clientservice && props.card.allData.clientservice.trim() !== "" ? props.card.allData.clientservice : "N/A"}</span>
      </div>
      <div className='compare-content-page-item'>
          <span>{props.card.allData.industry && props.card.allData.industry.trim() !== "" ? props.card.allData.industry : "N/A"}</span>
      </div>
      <div className='compare-content-page-item'>
          <span>{props.card.numberOfPins ? props.card.numberOfPins : '0'}</span>
      </div>
      <div className='compare-content-page-item topics'>
      {props.card.allData.topictags && props.card.allData.topictags.length > 0 ? (
          <div className="tags-section topics">
          <div className="tags">
            <TagList tagListData={props.card.allData.topictags} itemsVisible={8} />
          </div>
        </div>
      ) : "N/A"}
      </div>
      <div className='compare-content-page-item'>
      {props.card.allData.globalcontenttype === 'Quals' ?
          <span>{props.card.allData.kxq_externalqualuse ? props.card.allData.kxq_externalqualuse : "N/A"}</span>
          : <span>{props.card.allData.kxe_use ? props.card.allData.kxe_use : "N/A"}</span>
      }
      </div>
      <div className='compare-content-page-item'>
      {props.card.allData.globalcontenttype === 'Quals' && props.card.allData.kxq_contributor.indexOf(';') !== -1 ?
          <Link target='_blank' onClick={(e: any) => {compareItemTracking('Author',props.card);window.open(SharedService.dpnLink + props.card.allData.kxq_contributor.split(';')[2], '_blank')}}>{props.card.allData.kxq_contributor.split(';')[1]}</Link>
          : props.card.allData.kxa_author.indexOf(';') !== -1 ? 
          <Link target='_blank' onClick={(e: any) => {compareItemTracking('Author',props.card);window.open(SharedService.dpnLink + props.card.allData.kxa_author.split(';')[2], '_blank')}}>{props.card.allData.kxa_author.split(';')[1]}</Link>
          : "N/A"
      }
      </div>
      <div className='compare-content-page-item tags'>
      {tags && tags.length > 0 ? (
          <div className="tags-section tags">
          <div className="tags">
            <TagList tagListData={tags} itemsVisible={8} />
          </div>
        </div>
      ): "N/A"}
      </div>
      <div className='compare-content-page-item-bottom'>
        <OverflowSet
              className="new-ms-OverflowSet"
              aria-label={
                SharedService.GetInstance().Data.Labels[
                Constants.LabelKeys.ContextualMenuLabel
                ]
              }
              role="menubar"
              key={props.card.key}
              items={getNoExplainOverflowItems(protectedOverflowButtons)}
              onRenderOverflowButton={() => null}
              onRenderItem={(item) =>
                onRenderItem(item, props.card)
              }
              doNotContainWithinFocusZone={true}
            />
      </div>
      <ModalBase
        onHideModal={() => {setIsOpenPinModal(false);setCreateNew(false);setExistName(false)}}
        isTwoButtons={true}
        button={{
          text: SharedService.GetInstance().Data.Labels[
            Constants.LabelKeys.CancelCapitalButton
          ],
          function: () => {setIsOpenPinModal(false);setCreateNew(false);setExistName(false)
          },

        }}
        SecondButton={{
          text: SharedService.GetInstance().Data.Labels[Constants.LabelKeys.SaveCapitalButton],
          function: async () => {
            if(createNew){
              createNewCategoryAndPin(cardsState)
            }else{
              savePinToCategory(cardsState)
            }
          },
          noHideModal: true
        }}
        title={
          "Pin To"
        }
        className="categoryModal"
        isOpen={isOpenPinModal}
      >
        <div style={{ width: '100%' }}>
        <div className="moreThan100">{SharedService.GetInstance().Data.Labels[Constants.LabelKeys.PinNewItemPopUp]}</div>
          <div style={{ display: 'flex' }}>
            <SearchBox
              className={"search-default search--lg newCategory"}
              onChange={searchTermChanged}
              defaultValue={searchTerm}
              value={searchTerm}
              placeholder={"Search Here"}
              disableAnimation
            /></div>
          <button
            className="moveButtonCreate"
            onClick={async(e) => {
              var existNameDefault=true;
              var index=0;
              var name="Category 0"
              while(existNameDefault==true){
                index++
                existNameDefault = await SharedService.GetInstance().Actions.Get.GetIfExistCategoryPersonalCollection(name+index);
              }
              setNewCategory(name+index)
              var list = document.getElementsByClassName("categoriesList");
              for (var i = 0; i < list.length; i++) {
                var el = list[i]
                if (el && el.classList.contains("selectedCategory")) {
                  el.classList.remove("selectedCategory");
                }
              }
              setCreateNew(true)
            }}
          >
            <i className="icon-kx-plus" style={{ paddingRight: "3px" }} />
            Create new Category
               </button>
              
          <div className="categorieslistAll">
          {createNew?<><input maxLength={255}  value={newCategory} onChange={(e:any)=>{
                setNewCategory(e.target.value)
              }} className="categoriesList">
              
            </input> {existName ? <div className="errorExist">
              This category already exist , Please select another name.
            </div> : isNameEmpty ? <div className="errorExist">
              Please enter category name.
            </div> : isNameCharLimitExceeded ? <div className="errorExist">
              Category name length should be less than 255 characters.
            </div> : null}</>:null}
            {filteredCategories && filteredCategories.length > 0 ? filteredCategories.map((category: ICategory) => {
              
              var count=category.Count
              return <button disabled={count>=100||createNew} onClick={(e:any)=>onClickCategory(e,category.Id)} className={category.isSelected?"categoriesList selectedCategory":"categoriesList"}>
                {category.Label+" ("+category.Count+")"}
              </button>
              
            }) : null}
          </div>
        </div>
      </ModalBase>
    </div>
  );
};