import React from 'react';
import { IErrorBoundaryProps, IErrorBoundaryState } from './errorBoundaryModels';
import { LogHandler } from '../../../helpers/LogHandler';


export default class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }



    componentDidCatch(errorMessage: any, infoCallStack: any) {
        // Display fallback UI
        this.setState({ hasError: true });

        // You can also log the error to an error reporting service
        LogHandler.WriteLog(errorMessage, infoCallStack.componentStack);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return<div className='something_error'>
                <div className='someerror'>
                    <h1>Something went wrong</h1>
                    <p>Please Try Again or report an issue to support.</p>
                </div>
            </div> ;
        }
        return this.props.children;
    }
}