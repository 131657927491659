import React from 'react';
import { SharedService } from '../../../../services/SharedService';
import styles from "../newContribution.module.scss";



export interface TaxonomyTableProps {
  tableTitle: string;
  radioButtonGroupname: string;
  checkedTaxonomyValue: any[];
  removeTaxonomyItem: (i: number, item: any[]) => void;
  checkedTaxonomyRadioValue: any;
  setCheckedTaxonomyRadioValue: (item: any) => void;
  page?: string;
}

export const TaxonomyTable: React.FunctionComponent<TaxonomyTableProps> = (props) => {

  let labelsKey = SharedService.scopeNotesLabelKey;
  var labelsData: string | null = localStorage.getItem(labelsKey);
  var labels = labelsData != null ? JSON.parse(labelsData) : {};
  const _sharedService = SharedService.GetInstance();

  return (
    <div className={[styles.lableing, 'lableing'].join(" ")}>
      <div className={[styles.business, 'business'].join(" ")}>
        <div className={styles.selectbusiness}>{props.tableTitle}</div>
        {props.page == 'edit' ? <div className={styles.selectradio}>{_sharedService.Data.Labels["KXDocument_Select Primary_UserEditForm"]}</div> : <div className={styles.selectradio}>{_sharedService.Data.Labels["KXDocument_primary service subheading_NewContribution"]}</div>}
      </div>
      <div>
        {props.checkedTaxonomyValue &&
          props.checkedTaxonomyValue.length > 0 &&
          props.checkedTaxonomyValue.map((item, i) => (
            <div className={[styles.businesstitle, 'businesstitle'].join(" ")}>
              <span>
                {" "}
                <div className={styles.title}>
                  {item.Name}
                  <i
                    className="icon-kx-close"
                    onClick={() => {
                      props.removeTaxonomyItem(i, item);
                    }}
                  ></i>
                </div>{" "}
              </span>
              <input
                type="radio"
                value={item.Name}
                name={props.radioButtonGroupname}
                checked={
                  props.checkedTaxonomyRadioValue ? props.checkedTaxonomyRadioValue.Name == item.Name : false
                }
                onChange={() =>
                  props.setCheckedTaxonomyRadioValue(item)
                }
              />
            </div>
          ))}
      </div>
    </div>
  );
};

