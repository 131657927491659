import React, { useEffect } from "react";
import {
  IMessageBarStyles,
  Link,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";
import "./errorMessageBar.scss";
import { SharedService } from "../../../services/SharedService";
import { UserAlertItem } from "../../../models/SharedModels";
import LinkGenerator from "../linkGenerator/linkGenerator";
import { Constants } from "../../../helpers/Constants";
import { isEmpty } from "lodash";

interface IBarState {
  switchVisibility?: () => void;
  messageBody: string;
}

const styles: Partial<IMessageBarStyles> = {
  expand: {
    marginTop: "4px",
    marginBottom: "4px",
  },
};

const DefaultBar = (props: IBarState) => (
  <MessageBar
    className="no-icon custom-error-msg"
    onDismiss={props.switchVisibility}
    dismissButtonAriaLabel={
      SharedService.GetInstance().Data.Labels[
        Constants.LabelKeys.CloseMenuItemText
      ]
    }
    isMultiline={false}
    overflowButtonAriaLabel={
      SharedService.GetInstance().Data.Labels[Constants.LabelKeys.SeeMoreLabel]
    }
    messageBarType={MessageBarType.error}
    truncated={true}
    styles={styles}
  >
    <LinkGenerator linkTemplateText={props.messageBody}></LinkGenerator>
  </MessageBar>
);

export const MessageBarError: React.FunctionComponent = () => {
  const [isVisible, setVisibility] = React.useState<string | boolean>(false);
  const [alert, setMessage] = React.useState<{
    message: string;
    alertID: number;
  }>({ message: "", alertID: 0 });
  const _sharedService = SharedService.GetInstance();

  const switchVisibility =  () => {
    localStorage.setItem('IsErrorBannerVisible', 'No');
    setVisibility(false);
  }

  useEffect(() => {
    let labelsKey = SharedService.scopeNotesLabelKey;
    var labelsData: string | null = localStorage.getItem(labelsKey);
    var labels = labelsData != null ? JSON.parse(labelsData) : {};
    window.addEventListener('storage', () => {
        let isShow = localStorage.getItem('IsErrorBannerVisible');
        if(isShow == 'Yes'){
            setMessage({ alertID: 1, message: _sharedService.Data.Labels["KXDocument_Warning_NewContribution_ErrorOccured"] ? _sharedService.Data.Labels["KXDocument_Warning_NewContribution_ErrorOccured"] :"" });
            setVisibility(true);
        }
        
    })
  }, []);

  return (
    <>
      {isVisible && (
        <div className="messageBar-container-error">
          <div className="container">
            <DefaultBar
              switchVisibility={switchVisibility}
              messageBody={alert.message}
            />
          </div>
        </div>
      )}
    </>
  );
};
