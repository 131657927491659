import { IQuickViewResultResponseObj } from "../../models/KxPromptModels";
import { DataActionTypes } from "../actions/promptQuickViewAction";
import {
  CLEAR_QUICK_VIEW_DATA,
  CLEAR_QUICK_VIEW_LOADING,
  GET_QUICK_VIEW_RESULTS_ERROR,
  GET_QUICK_VIEW_RESULTS_REQUEST,
  GET_QUICK_VIEW_RESULTS_SUCCESS,
  RESET_QUICK_VIEW_RESULTS_ERROR,
} from "../types";

export interface IQuickViewResponseState {
  quickViewResponse: IQuickViewResultResponseObj | null | undefined;
  loadingResponse: boolean;
  loadingQuickViewModal: boolean;
  error: string | null | undefined;
}

const initialState: IQuickViewResponseState = {
  quickViewResponse: null,
  loadingResponse: false,
  loadingQuickViewModal: false,
  error: null,
};

const promptQuickViewReducer = (
  state = initialState,
  action: DataActionTypes
): IQuickViewResponseState => {
  switch (action.type) {
    case GET_QUICK_VIEW_RESULTS_REQUEST:
      return {
        ...state,
        loadingResponse: true,
        loadingQuickViewModal: true,
        error: null,
        quickViewResponse: null,
      };
    case GET_QUICK_VIEW_RESULTS_SUCCESS:
      return {
        ...state,
        loadingResponse: false,
        error: null,
        quickViewResponse: action.payload,
      };
    case GET_QUICK_VIEW_RESULTS_ERROR:
      return {
        ...state,
        loadingResponse: false,
        loadingQuickViewModal: false,
        error: action.payload,
        quickViewResponse: null,
      };
    case RESET_QUICK_VIEW_RESULTS_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_QUICK_VIEW_DATA:
      return {
        ...state,
        quickViewResponse: null,
      };
    case CLEAR_QUICK_VIEW_LOADING:
      return {
        ...state,
        loadingQuickViewModal: false,
      };

    default:
      return state;
  }
};

export default promptQuickViewReducer;
