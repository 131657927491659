import React, { useState, useImperativeHandle } from 'react';
import { useId } from '@uifabric/react-hooks';
import {
  getTheme,
  Modal,
  IconButton,
  IIconProps,
  Stack,
  Link,
  IModalProps,
  PrimaryButton
} from 'office-ui-fabric-react';
import './modalBase.scss';
import { useDispatch } from 'react-redux';
import setUserNeedsModal from '../../../store/actions/userNeedsModalActions';
import { SharedService } from '../../../services/SharedService';
import { Constants } from '../../../helpers/Constants';
import { IDigitalDataAttr } from '../../../models/IShared';

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const errorIcon: IIconProps = { iconName: 'error' };

export interface modalButton {
  text?: string,
  function?: any,
  noHideModal?: any
}


export interface ModalBaseProps extends IModalProps {
  isOpen?: boolean,
  title?: string,
  opener?: any,
  ref?: any,
  openerClass?: string,
  button?: modalButton,
  SecondButton?: modalButton,
  isTwoButtons?: boolean,
  withCancelBtn?: boolean,
  onHideModal?: any,
  analyticsType?: string,
  needIcon?: boolean,
  tracker?: boolean,
  bulkUpdate?: boolean,
  digitalData?: IDigitalDataAttr,
  modernUi?:boolean,
  isCollectionsExport?: boolean;
  isErrorModal?: boolean;
}

export const ModalBase: React.FunctionComponent<ModalBaseProps> = React.forwardRef((props, ref) => {
  const [isModalOpen, setModalState] = useState(props.isOpen ? true : false);
  const dispatch = useDispatch();
  const infoLog = require('../../../assets/images/info.png').default

  const showModal = () => {
    setModalState(true)
  }

  const hideModal = () => {
    setModalState(false);
    dispatch(setUserNeedsModal(false));
    if (props.onHideModal)
      props.onHideModal();
  }

  const runSecondButton = () => {
    if (props.SecondButton) {
      props.SecondButton.function();
      if (!props.SecondButton.noHideModal)
        hideModal();
    }
  }

  useImperativeHandle(ref, () => {
    return {
      showModal: showModal
    };
  });

  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)
  const titleId = useId('title');
  
  let digitalDataAttr:any;

  if(props.digitalData)
     digitalDataAttr={[props.digitalData.AttrName]:props.digitalData.AttrValue};

  return (
    <div className="modal-link">
      {props.opener && (
        <Link onClick={showModal} className={props.openerClass} digitaldata-kiph={props.analyticsType} dangerouslySetInnerHTML={{ __html: props.opener }} />
      )}
      <Modal
        className={props.className}
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        scrollableContentClassName=' '
        allowTouchBodyScroll
        {...props}
      >
        <Stack horizontal 
        className={`modal__heading ${props.isTwoButtons ? 'ms-div-modal' : ''} ${ props.isCollectionsExport?'collectionsExportModalBase':''}` } >
          {props.needIcon ? (props.tracker ? <Stack.Item grow={1} >
            <div style={{ textAlign: 'center' }}>
              <img src={infoLog} style={{ marginBottom: "21px" }} />
            </div>
            <span className="modal__heading-text textCenter" id={titleId}>{props.title}</span>

          </Stack.Item> : null) :
            <Stack.Item grow={1}>
              <span className="modal__heading-text textCenter" id={titleId}>{props.title}</span>
            </Stack.Item>}

          {props.withCancelBtn ? "" :
            <Stack.Item className={props.needIcon ? "left" : ""}>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel={SharedService.GetInstance().Data.Labels[Constants.LabelKeys.ClosePopupModal]}
                onClick={hideModal}
              />
            </Stack.Item>}
        </Stack>

        {props.isCollectionsExport && <hr className='collections-export-modal-base-header-hr'></hr>}

        <div className={`modal__bodyAll ${props.isCollectionsExport?'collections-export-modal-base-body':''}`}
          style={{
            overflow: props && props.bulkUpdate ? "auto" : "initial", // Apply overflow conditionally
            justifyContent: props.isErrorModal ? 'center' : 'initial',
          }}>
          {props.needIcon && !props.tracker &&
            <Stack.Item grow={1}>
              <img src={infoLog} style={{ marginBottom: "21px", marginLeft: "45%" }} />
              <span className="modal__heading-text textCenter" id={titleId}>{props.title}</span>
            </Stack.Item>}
          {props.children}
        </div>

        {props.isCollectionsExport && <hr className='collections-export-modal-base-body-hr'></hr>}

        {props.isTwoButtons ?
          <div className={"ms-div-Button-second"} style={{ padding: '8px 0px' }}>
            <Stack>
              <Stack.Item align="center" className={"ms-Button-grey"}>
                <PrimaryButton text={props.button && props.button.text} className={props.needIcon ? "ms-Button--med" : "ms-Button--lg"} onClick={props.button?.function || hideModal} />
              </Stack.Item>
            </Stack>

            <Stack>
              <Stack.Item align="center" className={"ms-Button-second"}>
               <div {...(props.modernUi && digitalDataAttr &&  {  ...digitalDataAttr })}>
                <PrimaryButton text={props.SecondButton && props.SecondButton.text ? props.SecondButton.text: SharedService.GetInstance().Data.Labels[Constants.LabelKeys.OkLabel]} className={props.needIcon?"ms-Button--med":"ms-Button--lg"} onClick={props.SecondButton && props.SecondButton.function? runSecondButton:hideModal} />
                </div>
              </Stack.Item>
            </Stack>
          </div>
          : props.button ?
            <Stack>
              <Stack.Item align="center" className={`${props.isCollectionsExport ?'collection-export-primary-btn':''}`}>
                <PrimaryButton text={props.button && props.button.text} className="ms-Button--lg" onClick={props.button?.function || hideModal} />
              </Stack.Item>
            </Stack> : null}
      </Modal>
    </div>
  );
});

const theme = getTheme();
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};