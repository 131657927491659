import React, { useState, useEffect } from 'react';
import { ScrollbarCustom } from '../scrollbarCustom/scrollbarCustom';
import { Link, Text, Stack, PrimaryButton, OverflowSet, IOverflowSetItemProps } from 'office-ui-fabric-react';
import { SharedService } from '../../../services/SharedService';
import { CommonFunctions } from '../../../helpers/CommonFunctions';
import { Constants } from '../../../helpers/Constants';
import { PersonalCollection } from '../../../models/SharedModels';
import { useHistory } from 'react-router';

export interface ModalVideoProps {
    cardData: any
    metadataQuickView: any;
    indexKey: any;
    onRenderItem(item: any, card: any): any
}

export const ModalVideo: React.FunctionComponent<ModalVideoProps> = props => {
    let history = useHistory();

    const getTheRelevantValue = (data: string): any => {
        var arr = data.split(";");
        if (arr && arr.length > 1) {
         return arr[1]
        }
        return arr[arr.length - 1];
      };

    return (

        <div className="modal__bodyVideo">
            {props.metadataQuickView && props.cardData != null ?
                <>
                    <ScrollbarCustom>
                        <span className="content-tag">{props.cardData.allData.kxe_videoduration}</span>
                        <div className="heading-inner">
                            <span className="image" style={{ backgroundImage: `url("${props.cardData.image}")` }}></span>
                            <h2>{props.cardData.allData[props.metadataQuickView.field1[0].field]}</h2>
                        </div>
                        {props.metadataQuickView.field2 ?
                            <span className="notes">{
                                props.metadataQuickView.field2.map((field: any) => {
                                    if (field.field)
                                        return props.cardData.allData[field.field]
                                })
                            }</span> : null}
                        <div className="embed-video">
                        <iframe width="560" height="315" src={props.cardData.linkToItem} style={{border:'none'}}></iframe>                        </div>
                        <p>{props.cardData.allData[props.metadataQuickView.field3[0].field]}</p>
                        {
                            Object.keys(props.metadataQuickView).map((key, index) => {
                                if (index > 2 && props.metadataQuickView[key][0].label && props.cardData.allData[props.metadataQuickView[key][0].field] && props.cardData.allData[props.metadataQuickView[key][0].field] != " ") {
                                    return <Text block><b>{props.metadataQuickView[key][0].label}</b>
                                        {getTheRelevantValue(props.cardData.allData[props.metadataQuickView[key][0].field])}</Text>
                                }
                            })
                        }
                    </ScrollbarCustom>
                    <div className="modal__bottom-bar">
                        <Stack className="centered-buttons">
                            <Stack.Item align="center">
                                <PrimaryButton onClick={(e) => {
                                    CommonFunctions.doTrackingMiniCard(Constants.MiniCardActions.ViewFullContent, props.cardData);
                                    if (e) e.stopPropagation();
                                    CommonFunctions.redirectLink(
                                        props.cardData.ctName == Constants.DPN
                                            ? true
                                            : props.cardData.openNewTab,
                                        props.cardData.linkToItem,
                                        history
                                    );
                                }} text={props.metadataQuickView.buttonLabel} className="ms-Button--xl centerButt" />
                            </Stack.Item>
                        </Stack>

                        <OverflowSet
                            className="new-ms-OverflowSet"
                            aria-label={SharedService.GetInstance().Data.Labels[Constants.LabelKeys.ContextualMenuLabel]}
                            role="menubar"
                            key={props.indexKey}
                            items={props.cardData.overflowItemsData[0].overflowItems}
                            onRenderOverflowButton={() => null}
                            onRenderItem={(item) => props.onRenderItem(item, props.cardData)}
                            doNotContainWithinFocusZone={true}
                        />


                    </div>

                </>
                : null}
        </div>
    )
}