import React, { useState, useEffect, useRef } from "react";
import {
  ISource,
  ISuggestion,
  IRecentSearch,
  ISearchData,
  ISearchState,
  IContexualSearchBoxStyles,
  IGeneralSearch,
} from "../../../models/IShared";
import { SharedService } from "../../../services/SharedService";
import { Constants } from "../../../helpers/Constants";
import { Stack, IDropdownOption, Spinner } from "office-ui-fabric-react";
import {
  SearchBox,
  ISearchBoxStyles,
} from "office-ui-fabric-react/lib/SearchBox";
import { DropdownBase } from "../dropdownBase/dropdownBase";
import { PivotBase } from "../pivotBase/pivotBase";
import { useSelector, useDispatch } from "react-redux";
import setShowRecent from "../../../store/actions/searchBoxActions";
import RecentSearches from "../recentSearches/recentSearches";
import "./searchBoxGlobal.scss";
import styles from "../recentSearches/recentSearches.module.scss";
import { pure } from "recompose";
import setRefiners from "../../../store/actions/refinersActions";
import { stat } from "fs";
import setSearchState from "../../../store/actions/searchActions";
import { Stats } from "webpack";
import { AnalyticsService } from "../../../services/AnalyticsService";
import {
  ISearchBoxTracker,
  ISearchElseWhereTracker,
} from "../../../models/IAnalytics";
import { showFeaturedItemsActions } from "../../../store/actions/showFeaturedItems";
import {
  SHOW_FEATURED_ITEMS,
  SHOW_RECOMENDED_FOR_YOU,
} from "../../../store/types";
import { showRecomendedForYouActions } from "../../../store/actions/showRecomendedForYou";
import { CommonFunctions } from "../../../helpers/CommonFunctions";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";
import { ScrollbarCustom } from "../scrollbarCustom/scrollbarCustom";
import { ModalBase } from "../modalBase/modalBase";
import setRefinersOld from "../../../store/actions/refinersOldActions";
import { open } from "inspector";
import { setSearchBoxData } from "../../../store/actions/SearchBoxCardDataAction";

const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: {
    border: "none",
    height: "100%",
    borderRadius: "5px 0 0 5px",
    fontSize: 16,
    padding: "0 28px 0 26px",
    flexGrow: 1,
    outline: "none",
  },
};
const contexualSearchBoxStyles: Partial<IContexualSearchBoxStyles> = {
  root: {
    border: "none",
    height: "35px",
    boxShadow:
      "0 2px 6px 0 rgba(0, 0, 0, 0.07), 0 2px 4px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    fontSize: 16,
    padding: "0 28px 0 26px",
    flexGrow: 1,
    outline: "none",
  },
};

interface IProps {
  isHomePage: boolean;
  classSize?: string;
  functionRef?: any;
  isContextualSearch?: boolean;
  children?: any;
  inHeader?: boolean;
  pageName?: string;
  setShowCompareArea?: () => void;
  trackSearchBoxResults?:any;
  modernUi?:boolean;
}

const SearchBoxGlobal: React.FC<IProps> = (props: IProps) => {
  const [sources, setSources] = useState<ISource[]>([]);
  const [ddActive, setDDActive] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>(
    new URLSearchParams(window.location.search) ?.get("q") || ""
  );
  const [selectedSuggestion, setSelectedSuggestion] = useState<ISuggestion>(
    {} as ISuggestion
  );
  const [selectedSource, setSelectedSource] = useState<ISource>({} as ISource);
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
  const [activeSuggestion, setActiveSuggestion] = useState<number>(-1);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [searches, setSearches] = useState<IRecentSearch[]>([]);
  const [activeSearch, setActiveSearch] = useState<number>(-1);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const forceClose = useRef(false);
  const lastCallId = useRef(0);

  const [isRecenttMode, setRecenttMode] = useState<boolean>(false);
  const [isSuggestionClicked, setIsSuggestionClicked] = useState<boolean>(
    false
  );
  const dispatch = useDispatch();
  const _searchState: ISearchState = useSelector((s: any) => s.searchReducer);

  let hadlerTimeout: any;
  let recSearchTimeout: any;

  const numberOfLetters: number = 2;

  const _showRecent: any = useSelector((s) => s);
  const _userNeeds: any = useSelector((s) => s);
  const [showExtNavConfirmPopUp, setShowExtNavConfirmPopUp] = useState<boolean>(
    false
  );
  const _sharedService = SharedService.GetInstance();

  //let lastCallId: number = 0;
  let history = useHistory();

  useEffect(() => {
    // Execute a function when the user releases a key on the keyboard
    document.addEventListener("keyup", (event) => {
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        // Trigger the button element with a click
        setDDActive(false);
        dispatch(showFeaturedItemsActions(SHOW_FEATURED_ITEMS, false));
        dispatch(showRecomendedForYouActions(SHOW_RECOMENDED_FOR_YOU, false));
      }
    });
    let _sharedService = SharedService.GetInstance();
    let _configurations = _sharedService.Data.Configuration;
    var result = JSON.parse(_configurations.SourceOptions);

    var mappedOptions: ISource[] = [];
    result.options.map((option: ISource) => {
      option.data = { icon: "icon-kx-external-link2" };
      option.key = option.id;
      option.text = _sharedService.Data.Labels[option.title] ? _sharedService.Data.Labels[option.title] : "";
      option.title = _sharedService.Data.Labels[option.title] ? _sharedService.Data.Labels[option.title] : "";
      mappedOptions.push(option);
    });

    setSources(mappedOptions);
    setSelectedSource(mappedOptions[0]);
  }, []);

  useEffect(() => {
    setUserInput(new URLSearchParams(window.location.search) ?.get("q") || "");
  }, [new URLSearchParams(window.location.search) ?.get("q")]);

  const wrapperRef = useRef(null);

  const useOutsideAlerter = (ref: any) => {
    const handleClickOutside = (event: any) => {
      var quickViewOpen = document.getElementsByClassName("ms-Modal");
      var actionsButtonActive = document.getElementsByClassName(
        "ms-ContextualMenu-container"
      );
      if (
        ref.current &&
          !ref ?.current.contains(event.target) &&
          quickViewOpen.length == 0 &&
          actionsButtonActive.length == 0
      ) {
        setDDActive(false);
        //setState({ ddActive: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside); // Bind the event listene
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  };
  useOutsideAlerter(wrapperRef);

  var sourceChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) => {
    setSelectedSource(sources ?.filter((x) => x.id == option ?.key)[0]);
  };

  var showResults = (suggestionFromAutocomplete: ISuggestion) => {
    setIsSuggestionClicked(false);
    //add to recent searches
    setShowExtNavConfirmPopUp(false);
    if (!props.isContextualSearch) {
      if (suggestionFromAutocomplete.Phrase.length > 0) {
        var _sharedService = SharedService.GetInstance();
        var recentSearch: IRecentSearch = {
          Id: undefined,
          Phrase: suggestionFromAutocomplete.Phrase,
        };
        _sharedService.Search.Post.AddRecentSearches(recentSearch);
      }
      var selectedItemUrl;
      //redirect
      if (selectedSource ?.id.toString() == "1") {
        //KX internal redirect
        let _configurations = SharedService.GetInstance().Data.Configuration;
        let contentTypesMappingUrls = _configurations
          ? JSON.parse(_configurations.CTMappingUrl)
          : []; //get the linktoitem to internal content

        if (suggestionFromAutocomplete.SuggestionType == Constants.SugestionType.Match) {
          selectedItemUrl = isRecenttMode
            ? suggestionFromAutocomplete.InternalUrl + "&mode=Recent"
            : suggestionFromAutocomplete.InternalUrl;
          var metaDataCards;
          if (selectedItemUrl == "" || selectedItemUrl == undefined) {
            let ctCorelationJson = SharedService.GetInstance().GetConfigurationMapping(suggestionFromAutocomplete.cardData[0]);
            let configCT = JSON.parse(_configurations.ContentTypesMapping);

            let contentTypesMapping=configCT[ctCorelationJson.JsonMapping]
            if (contentTypesMapping) {
              metaDataCards = contentTypesMapping.metadataCards;
            }
            var cardLinks = CommonFunctions.ifExternal(
              suggestionFromAutocomplete.cardData[0],
              contentTypesMapping,
              contentTypesMappingUrls
            );
            let relatedLinkSp = cardLinks && cardLinks.length > 0 ? cardLinks[0] : "";
            selectedItemUrl = relatedLinkSp;
          }
          // external if DPN profile link       
          var url = CommonFunctions.isValidUrl(selectedItemUrl);
          let openNewTab = url && url.pathname.indexOf("profile")
              ? true
              : metaDataCards
                ? metaDataCards.openNewTab
                : false;

          // do tracking // critical bug id - 27
          var itemsPart = suggestions.filter((x) => x.SuggestionType == Constants.SugestionType.Match);
          var index = itemsPart.findIndex((itemR) => itemR.Phrase == suggestionFromAutocomplete.Phrase);

          let searchBoxTracker: ISearchBoxTracker = {} as ISearchBoxTracker;
          searchBoxTracker = {
            searchTerm: userInput || "",
            title: suggestionFromAutocomplete.Phrase,
            position: index || index === 0 ? (++index).toString() : "",
            url: selectedItemUrl,
            sourceSystem:
              !isEmpty(suggestionFromAutocomplete.cardData) &&
                suggestionFromAutocomplete.cardData[0].internalcontenttype
                ? suggestionFromAutocomplete.cardData[0].internalcontenttype
                : "",
            sourceContentType:
              !isEmpty(suggestionFromAutocomplete.cardData) &&
                suggestionFromAutocomplete.cardData[0].apptype
                ? suggestionFromAutocomplete.cardData[0].apptype
                : "",
            contentID: !isEmpty(suggestionFromAutocomplete.cardData)
              ? suggestionFromAutocomplete.cardData[0].appid
              : "",
          };
          if (suggestionFromAutocomplete.InternalUrl.toLowerCase().indexOf('search?q') === -1)
            AnalyticsService.trackSearchBox(searchBoxTracker);
            dispatch(setSearchBoxData(searchBoxTracker as any));

          if(selectedItemUrl.indexOf(Constants.Routing.Collection) > 0){
            selectedItemUrl = SharedService.uiUrl + selectedItemUrl.slice(selectedItemUrl.indexOf(Constants.Routing.Collection));            
            openNewTab = false;
          }
          if(selectedItemUrl.indexOf(Constants.Routing.PracticeProfile) > 0 ){
             selectedItemUrl = SharedService.uiUrl + selectedItemUrl.slice(selectedItemUrl.indexOf(Constants.Routing.PracticeProfile));             
            openNewTab = false;
          }
          CommonFunctions.redirectLink(openNewTab, selectedItemUrl, history);
        } 
        else {
          url = isRecenttMode
            ? suggestionFromAutocomplete.InternalUrl + "&mode=Recent"
            : suggestionFromAutocomplete.InternalUrl;
          selectedItemUrl = suggestionFromAutocomplete.InternalUrl;
          if (url != undefined) {
            if(isHomePage() || window.location.href.indexOf(Constants.Routing.Search) == -1) {
              dispatch(setRefiners([]));
              dispatch(setRefinersOld([]));
            }
            history.replace(url);
          }

          if (_searchState.searchText != suggestionFromAutocomplete.Phrase) {
            if (isHomePage()) {
              dispatch(setRefiners([]));
              dispatch(setRefinersOld([]));
            }
            dispatch(
              setSearchState(
                true,
                true,
                suggestionFromAutocomplete.Phrase.length == 0 ? '#empty#' : encodeURIComponent(suggestionFromAutocomplete.Phrase),
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                !_searchState.reSearchFlag
              )
            );
          }
        }
      } else {
        //external redirect
        let searchElseWhereTracker: ISearchElseWhereTracker = {} as ISearchElseWhereTracker;
        searchElseWhereTracker = {
          searchTerm: userInput || "",
          searchElsewhere: selectedSource ?.title.toString(),
        };

        AnalyticsService.trackSearchElsewhere(searchElseWhereTracker);

        let redirectUrl =
          selectedSource ?.url +
            (suggestionFromAutocomplete.Phrase.length
              ? (suggestionFromAutocomplete.Phrase)
              : "*") +
            (selectedSource ?.urlEnd ? selectedSource ?.urlEnd : "");

        selectedItemUrl = redirectUrl;
        window.open(redirectUrl, "_blank");
        return; 
      }

      setSelectedSuggestion(suggestionFromAutocomplete);
      if (suggestionFromAutocomplete.SuggestionType != Constants.SugestionType.Match) {
        dispatch(setSearchState(true, true, encodeURIComponent(suggestionFromAutocomplete.Phrase)));
      }
      if (suggestionFromAutocomplete.SuggestionType === Constants.SugestionType.Refiners) {
        setUserInput('');
      }
    } else {
      props.functionRef(suggestionFromAutocomplete.Phrase, true, false, false, 0, suggestionFromAutocomplete.Phrase.length == 0);
    }
  };

  var searchBtnOnClick = () => {
    const source = selectedSource;
    const userInputAsSuggestion: ISuggestion = {
      Id: 0,
      Text: encodeURIComponent(userInput),
      Phrase: encodeURIComponent(userInput),
      RefinerId: 0,
      Count: 0,
      InternalUrl: source ?.url + (userInput.length ? encodeURIComponent(userInput) : ""),
      SuggestionType: isSuggestionClicked ? Constants.SugestionType.Match : "",
      SourceType: source ?.key as string,
      Type: "",
      isCuratedCollection: false,
      footer: [],
      cardData: {},
      ImageUrl: "",
      TotalFollowers: "",
    };
    //dispatch(setRefiners([]));
    forceClose.current = true;
    setDDActive(false);
    showResults(userInputAsSuggestion);
    setSelectedSuggestion(userInputAsSuggestion);
    setShowSuggestions(false);
  };

  var getSuggestions = async (userInput: string, timeoutId: number) => {
    if (timeoutId == lastCallId.current) {
      var searchData: ISearchData = {
        Phrase: decodeURIComponent(userInput.replaceAll('"', '').replaceAll("'", '')),
        Source: selectedSource as ISource,
        UserNeedId: _userNeeds["userNeedsReducer"].userNeeds.key,
        isContextual: props.isContextualSearch,
        userEmail: _sharedService.Data.CurrentUser.kxp_email,
        UserGeoFullPath: _sharedService.Data.CurrentUser.geographyFullPath,
        UserClientServiceFullPath:
          _sharedService.Data.CurrentUser.clientServiceFullPath,
      };
      let searchObj: IGeneralSearch = {
        render: false,
        text: searchData.Phrase,
        filters: [],
        isGlobal: true,
        sourceId: 0,
        userNeedId: searchData.UserNeedId,
        from: 0,
        IsExplain: CommonFunctions.getMinicardsSearchMetaConfig()?.boostingExplained.enabled,
        size: 4,
        sortOrder: "",
        URL: window.location.href,
        userEmail: _sharedService.Data.CurrentUser.kxp_email,
        UserClientServiceFullPath: searchData.UserClientServiceFullPath,
        UserGeographyFullPath: searchData.UserGeoFullPath,
        fromDate: "",
        toDate: "",
        isImages: false
      };
      
      var promotedResults = _sharedService.Search.Post.SearchGetPromotedResults(searchObj,true);
      var suggestions1 = _sharedService.Search.Post.GetSuggestions(decodeURIComponent(userInput.replaceAll('"', '').replaceAll("'", '')));
      var autocomplete = _sharedService.Search.Post.GetAutocompleteOptions(searchData,
        _sharedService.Data.Labels)
      Promise.all([suggestions1, autocomplete, promotedResults]).then((values) => {
        var suggestions: any = values[0];
        var response: any = values[1];
        var promotedSearchResults = values[2];
        var promotesResultsArr : any[] = [];
        promotedSearchResults.searchResults.forEach((item: any, index:number) => {
          promotesResultsArr.push(formatPromotedResults(item,index));
        });

        var suggestionsArr = response.filter((data: any) => {
          return data.SuggestionType == 'Refiners'
        })
        var resultsArr = response.filter((data: any) => {
          return data.SuggestionType != 'Refiners'
        })
        if (suggestions.length > 1 && suggestionsArr.length > 1) {
          suggestions = suggestions.slice(0, 3);
          suggestionsArr = suggestionsArr.slice(0, 3)
        } else if (suggestions.length > 1) {
          suggestions = suggestions.slice(0, 6);

        } else if (suggestionsArr.length > 1) {
          suggestionsArr = suggestionsArr.slice(0, 6)

        }
        
        response = suggestions.concat(suggestionsArr).concat(promotesResultsArr.slice(0,4).concat(resultsArr.slice(0, 4 - promotesResultsArr.length)));
        if (timeoutId == lastCallId.current && !forceClose.current) {
          setDDActive(response.length > 0);
        } else {
          setDDActive(false);
        }
        setActiveSuggestion(-1);
        setSuggestions(response);
        setShowSuggestions(response.length ? true : false);
        setShowLoader(false);
        setActiveSearch(-1);
      });
    }
  };
  var formatPromotedResults = (item:any, index:number) => {
    let promotedItem:any = {
      Id: 0,
      Text: item.title,
      Phrase: item.title,
      Count: 0,
      ImageUrl: "",
      InternalUrl: item.appurl,
      RefinerId: 0,
      SourceType: item.apptype,
      SuggestionType: Constants.SugestionType.Match,
      TotalFollowers: "",
      Type: item.source,
      isCuratedCollection: false,
      footer: [item.source],
      cardData: [item],
      index: index,
      isPromotedResult: true
    }
    return promotedItem;
  };

  var onSearchBoxChange = (event: any) => {
    // if (event && event.target) {

    let _uInput = event && event.target && event.target.value;
    _uInput = _uInput ? _uInput : "";

    let timeoutId: number = lastCallId.current + 1;
    lastCallId.current = (lastCallId.current + 1);
    forceClose.current = false;
    setDDActive(_uInput.length == 0);
    setUserInput(_uInput);

    if (_uInput.length == 0) setShowSuggestions(false);

    clearTimeout(hadlerTimeout);
    clearTimeout(recSearchTimeout);
    hadlerTimeout = setTimeout(() => {
      if (timeoutId == lastCallId.current) {
        if (_uInput && _uInput.length >= numberOfLetters) {
          setShowLoader(true);
          getSuggestions(_uInput, timeoutId);
        }
      }
    }, 300);
  };

  var onSearchBoxEnterClicked = () => {
    if (!props.isContextualSearch) {
      if (selectedSource.id && selectedSource.id.toString() === "1") {
        if (ddActive) {
          if ((activeSuggestion as number) > -1 && suggestions.length > 0) {
            suggestionsListOnClick(suggestions[activeSuggestion as number]);
          } else if ((activeSearch as number) > -1) {
            //enter on search
            let selectedItem = searches && searches[activeSearch as number];
            dispatch(setShowRecent(false));
            setUserInput((selectedItem as IRecentSearch).Phrase.trim());
          } else {
            //enter on input
            dispatch(setShowRecent(false));
            searchBtnOnClick();
          }
        } else {
          if ((activeSuggestion as number) > -1) {
            //enter on suggestion
            let selectedItem =
              suggestions && suggestions[activeSuggestion as number];

            showResults(selectedItem as ISuggestion);
            setUserInput((selectedItem as IRecentSearch).Phrase.trim());
          } else {
            //enter on input
            searchBtnOnClick();
          }
        }
      } else {
        setShowExtNavConfirmPopUp(true);
      }
    } else {
      if (userInput.length > 0) {
        var recentSearch: IRecentSearch = {
          Id: undefined,
          Phrase: userInput,
        };
        var _sharedService = SharedService.GetInstance();
        _sharedService.Search.Post.AddRecentSearches(recentSearch);
      }
      props.functionRef(userInput, false, undefined, undefined, undefined, userInput == '');
    }
  };

  var onSearchBoxKeyDown = (event: any) => {
    //enter
    if (event.keyCode === 13) {
      if (_showRecent["showRecentReducer"].showRecent) {
        if ((activeSearch as number) > -1) {
          //enter on search
          let selectedItem = searches && searches[activeSearch as number];
          dispatch(setShowRecent(false));

          setUserInput((selectedItem as IRecentSearch).Phrase.trim());
        } else {
          //enter on input
          dispatch(setShowRecent(false));
          searchBtnOnClick();
        }
      } else {
        if ((activeSuggestion as number) > -1) {
          //enter on suggestion
          let selectedItem =
            suggestions && suggestions[activeSuggestion as number];

          showResults(selectedItem as ISuggestion);
          setSelectedSuggestion(selectedItem);
          setUserInput((selectedItem as IRecentSearch).Phrase.trim());
        } else {
          //enter on input
          searchBtnOnClick();
        }
      }
    }
    //up
    else if (event.keyCode === 38) {
      if (_showRecent["showRecentReducer"].showRecent) {
        if (activeSearch === -1) {
          return;
        }
        if (activeSearch === 0) {
          setActiveSearch(searches.length - 1);
        } else {
          setActiveSearch(activeSearch - 1);
        }
        if (activeSuggestion === -1) {
          return;
        }
        if (activeSuggestion === 0) {
          setActiveSuggestion(suggestions.length - 1);
        } else {
          setActiveSuggestion(activeSuggestion - 1);
        }
      } else {
        if (activeSuggestion === 0) {
          setActiveSuggestion(suggestions.length - 1);
        } else {
          setActiveSuggestion(activeSuggestion - 1);
        }
      }
    }
    //down
    else if (event.keyCode === 40) {
      if (_showRecent["showRecentReducer"].showRecent) {
        if (activeSearch === (searches as IRecentSearch[]).length - 1) {
          setActiveSearch(0);
        } else {
          setActiveSearch(activeSearch + 1);
        }
        if (activeSuggestion === (suggestions as ISuggestion[]).length - 1) {
          setActiveSuggestion(0);
        } else {
          setActiveSuggestion(activeSuggestion + 1);
        }
      } else {
        if (activeSuggestion === (suggestions as ISuggestion[]).length - 1) {
          setActiveSuggestion(0);
        } else {
          setActiveSuggestion(activeSuggestion + 1);
        }
      }
    }
  };

  function suggestionsListOnClick(item: ISuggestion): any {
    setShowSuggestions(false);
    setDDActive(false);
    setIsSuggestionClicked(true);
    dispatch(showFeaturedItemsActions(SHOW_FEATURED_ITEMS, false));
    dispatch(showRecomendedForYouActions(SHOW_RECOMENDED_FOR_YOU, false));
    var itemsPart = suggestions.filter(
      (x) => x.SuggestionType == Constants.SugestionType.Match
    );
    var index = itemsPart.findIndex((itemR) => itemR.Id == item.Id);
    item.index = index;
    if (item.SuggestionType == Constants.SugestionType.Refiners) {
      //item.InternalUrl += "&q=" + userInput;
    } else {
      setUserInput((item as IRecentSearch).Phrase.trim());
    }
    showResults(item);
    setSelectedSuggestion(item);
  }

  var onHoverOption = (item: ISuggestion, index: number) => {
    //setActiveSuggestion(index);
  };

  var recentSearchSelected = (suggestionFromRecent: IRecentSearch) => {
    setRecenttMode(true);
    setUserInput(suggestionFromRecent.Phrase.trim());
  };

  var onSearchBoxFocus = (event: any) => {
    let _uInput = userInput ? userInput : "";
    forceClose.current = false;
    if (_uInput.length == 0) {
      // show search again only for empty searchbox

      if (ddActive) return; // if dd already active

      event.target.setAttribute("autocomplete", "off");
      event.target.setAttribute(
        "style",
        "outline: none; outline-width: 0; box-shadow: none;-moz-box-shadow: none;-webkit-box-shadow: none"
      );

      if (!showSuggestions) {
        clearTimeout(recSearchTimeout);

        recSearchTimeout = setTimeout(() => {
          _sharedService.Search.Post.GetRecentSearches().then((response) => {
            var uniqueSuggestions: any[] = [];
            //remove duplicated categories
            response.forEach((suggestion: any) => {
              var contain = false;
              for (var i = 0; i < uniqueSuggestions.length; i++) {
                if (uniqueSuggestions[i].Phrase == suggestion.Phrase) {
                  contain = true;
                }
              }
              //if phrase not exists in uniqueSuggestions we add the phrase
              if (!contain) {
                uniqueSuggestions.push(suggestion);
              }
            });
            setDDActive(true);
            setSearches(uniqueSuggestions);
            dispatch(setShowRecent(true, isHomePage()));
          });
        }, 300);
      }
    }
  };

  var onClear = (event: any) => {
    // setSearches([]);
    setShowSuggestions(false);
    setDDActive(false);
    // setUserInput('');
    // if(props.functionRef) {
    //   props.functionRef('',false, false, undefined, undefined, true);
    // }
  };
  var onHoverTrigger=(event:any)=>{
    let searchBoxData: ISearchBoxTracker = {} as ISearchBoxTracker;
   
    var selectedItemUrl;
    //redirect
    if (selectedSource ?.id.toString() == "1") {
      //KX internal redirect
      let _configurations = SharedService.GetInstance().Data.Configuration;
      let contentTypesMappingUrls = _configurations
        ? JSON.parse(_configurations.CTMappingUrl)
        : []; //get the linktoitem to internal content

      if (event.SuggestionType == Constants.SugestionType.Match) {
        selectedItemUrl = isRecenttMode
          ? event.InternalUrl + "&mode=Recent"
          : event.InternalUrl;
        var metaDataCards;
        if (selectedItemUrl == "" || selectedItemUrl == undefined) {
          let ctCorelationJson = SharedService.GetInstance().GetConfigurationMapping(event.cardData[0]);
          let configCT = JSON.parse(_configurations.ContentTypesMapping);

          let contentTypesMapping=configCT[ctCorelationJson.JsonMapping]
          if (contentTypesMapping) {
            metaDataCards = contentTypesMapping.metadataCards;
          }
          var cardLinks = CommonFunctions.ifExternal(
            event.cardData[0],
            contentTypesMapping,
            contentTypesMappingUrls
          );
          let relatedLinkSp = cardLinks && cardLinks.length > 0 ? cardLinks[0] : "";
          selectedItemUrl = relatedLinkSp;
        }
      }
    }
        // external if DPN profile link       
        var url = CommonFunctions.isValidUrl(selectedItemUrl);
    var itemsPart = suggestions.filter((x) => x.SuggestionType == Constants.SugestionType.Match);
    var index = itemsPart.findIndex((itemR) => itemR.Phrase == event.Phrase);

    searchBoxData = {
      searchTerm: userInput || "",
      title: event.Phrase,
      position: index || index === 0 ? (++index).toString() : "",
      url:selectedItemUrl,
      sourceSystem:
        !isEmpty(event.cardData) &&
        event.cardData[0].internalcontenttype
          ? event.cardData[0].internalcontenttype
          : "",
      sourceContentType:
        !isEmpty(event.cardData) &&
        event.cardData[0].apptype
          ? event.cardData[0].apptype
          : "",
      contentID: !isEmpty(event.cardData)
        ? event.cardData[0].appid
        : "",
    };
    if (event.InternalUrl.toLowerCase().indexOf('search?q') === -1)
    dispatch(setSearchBoxData(searchBoxData as any));
     // AnalyticsService.trackSearchBox(searchBoxTracker);
      
    return event;
  }

  var renderSuggestions = () => {
    var refinersPart = suggestions ?.filter(
      (x) => x.SuggestionType != Constants.SugestionType.Match
    );
    var itemsPart = suggestions ?.filter(
      (x) => x.SuggestionType == Constants.SugestionType.Match
    );
    var _activeSuggestion =
      activeSuggestion >= refinersPart.length
        ? activeSuggestion - refinersPart.length
        : -1;

    return (
      <div
        className={`search-suggestions ${
          !props.isContextualSearch ? "" : "contexualSearch"
          }`}
      >
        <div className="search-suggestions--full">
          <ul className="search-suggestions__list search-suggestions__list--full">
            {refinersPart ?.map((item: ISuggestion, index) => {
              return (
                <li
                  key={index}
                  className={
                    styles[
                    index === activeSuggestion ? "listItemActive" : "listItem"
                    ]
                  }
                  onClick={() => suggestionsListOnClick(item)}
                  onMouseEnter={() => onHoverOption(item, index)}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.Text }}></span>
                </li>
              );
            })}
          </ul>
          {!props.isContextualSearch && (
            <div className="search-tabs">
              <PivotBase
                pivotData={itemsPart}
                active={_activeSuggestion}
                onClick={suggestionsListOnClick}
                onHoverEvent={onHoverTrigger}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const isHomePage = (): boolean => {
    if(props.setShowCompareArea)
      props.setShowCompareArea();
    let url = window.location.href.substring(0,window.location.href.length - 1);
    let tempUrl = url;
    if(url.indexOf('?') !== -1) {
      let index = url.indexOf('?');
      tempUrl = url.substring(0,index);
    }

    if (tempUrl === window.location.origin || tempUrl === window.location.origin + "/") {
      return true;
    }
    return false;
  };

  var onRenderOption = (option: any): any => {
    return (
      <div className="options">
        <div style={{ display: "flex", alignItems: "center" }}>
          {option.data && option.data.icon && option.key !== 1 && (
            <i className={option.data.icon} />
          )}
          <span className="main-text">
            {option.text ? option.text : option.title}
          </span>
          {option.data && option.data.subText && (
            <span className="sub-text">{option.data.subText}</span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      ref={wrapperRef}
      id="searchIdMain"
      className={`search-main ${props.classSize ? props.classSize : ""} ${
        ddActive ? "search-opened" : ""
        }${!props.isContextualSearch ? "" : "search-wrapper"} 
      ${props.inHeader ? (isHomePage() ? "hidden" : "") : ""}`}
    >
      <Stack
        className={`input-area ${
          !props.isContextualSearch ? "" : "contexualSearch"
          }`}
      >
        <SearchBox
          className={!props.isContextualSearch ? "no-icon" : "no-icon contexualSearchHeight"}
          styles={
            props.isContextualSearch
              ? contexualSearchBoxStyles
              : searchBoxStyles
          }
          placeholder={
            !props.isContextualSearch
              ? _sharedService.Data.Labels[
              Constants.SearchLabels.SearchPlaceholder
              ]
              : props.pageName == Constants.PageNames.ExploreCollection
                ? _sharedService.Data.Labels[
                Constants.LabelKeys
                  .SearchExploreCollectionsByNameOrKeywordsLabel
                ]
                : props.pageName == Constants.PageTitles.CuratedCollection
                  ? _sharedService.Data.Labels[
                  Constants.LabelKeys.SearchCollectionsByNameOrKeywordsLabel
                  ]
                  : _sharedService.Data.Labels[
                  Constants.LabelKeys
                    .SearchPracticeProfilesByNameOrKeywordsLabel
                  ]
          }
          onFocus={onSearchBoxFocus}
          onClear={onClear}
          onChange={onSearchBoxChange}
          onKeyDown={onSearchBoxKeyDown}
          // on Click={inputOnClick}
          onSearch={onSearchBoxEnterClicked}
          value={userInput}
          disableAnimation
        />
        {!props.isContextualSearch && (
          <DropdownBase
            options={sources as ISource[]}
            onChange={sourceChange}
            type="search"
            label={_sharedService.Data.Labels[Constants.LabelKeys.SearchLabel]}
            classSize={`${
              props.classSize ? props.classSize : ""
              } input-area-dropdown`}
            onRenderOption={onRenderOption}
          />
        )}
      </Stack>

      <button type="button" className={props.isContextualSearch ? "contexual" : ""}
        // className={ userInput.length == 0 && props.isContextualSearch ? "contexual disabled" : (props.isContextualSearch ? "contexual" : "") }
        // disabled={ userInput.length == 0 && props.isContextualSearch }
        onClick={selectedSource.id && selectedSource.id.toString() == "1"
          ? searchBtnOnClick : () => { setShowExtNavConfirmPopUp(true); }} >
        {!props.modernUi &&
          <i className="icon-kx-explore"></i>
        }
        <span className="hiddenDownTablet">
          {_sharedService.Data.Labels[props.modernUi ? Constants.LabelKeys.SearchResourcesBtn : Constants.LabelKeys.FindLabel]}
        </span>
      </button>

      {showExtNavConfirmPopUp ? (
        <Stack.Item align="center" className="hiddenDownTablet">
          <ModalBase
            isTwoButtons={true}
            withCancelBtn={false}
            onHideModal={() => {
              setShowExtNavConfirmPopUp(false);
            }}
            button={{
              text:
                _sharedService.Data.Labels[
                Constants.LabelKeys.ExternalSearchPopupCancelBtn
                ],
              function: () => {
                setShowExtNavConfirmPopUp(false);
              },
            }}
            SecondButton={{
              text:
                _sharedService.Data.Labels[
                Constants.LabelKeys.ExternalSearchPopupOkBtn
                ],
              function: searchBtnOnClick,
            }}
            title={
              _sharedService.Data.Labels[
              Constants.LabelKeys.SearchExternalSourceMessage
              ]
            }
            isOpen={showExtNavConfirmPopUp}
          >
            <ScrollbarCustom>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                  selectedSource ?.id.toString()=="2"?
                    _sharedService.Data.Labels[
                    Constants.LabelKeys.SearchExternalSourceDPNMessage
                    ]:selectedSource ?.id.toString()=="3"? _sharedService.Data.Labels[
                      Constants.LabelKeys.SearchExternalSourceQualsMessage
                      ]:selectedSource ?.id.toString()=="5"? _sharedService.Data.Labels[
                        Constants.LabelKeys.SearchExternalSourceDeloitteMessage
                        ]:"",
                }}
                style={{ textAlign: "center", display: "block" }}
              ></span>
            </ScrollbarCustom>
          </ModalBase>
        </Stack.Item>
      ) : null}
      {showLoader && !props.isContextualSearch ? (
        <div
          className={`search-suggestions ${
            !props.isContextualSearch ? "" : "contexualSearch"
            }`}
        >
          <div className="search-suggestions--default">
            <Spinner
              label={
                _sharedService.Data.Labels[Constants.LabelKeys.LoadingLabel]
              }
            />
          </div>
        </div>
      ) : (
          <React.Fragment />
        )}
      {ddActive ? (
        !props.isContextualSearch && showSuggestions && userInput.length >= numberOfLetters ? (
          renderSuggestions()
        ) : (
            <RecentSearches
              isContextualSearch={props.isContextualSearch}
              isHomePage={isHomePage()}
              searches={searches as IRecentSearch[]}
              activeSearch={activeSearch as number}
              onRecentSearchSelected={recentSearchSelected}
            ></RecentSearches>
          )
      ) : (
          <React.Fragment />
        )}
      {props.children}
    </div>
  );

  return <div></div>;
};

export default pure(SearchBoxGlobal);
