import { SHOW_FEATURED_ITEMS, ShowFeaturedItemsState, ShowFeaturedItemsTypeActions } from '../types'

const initialState: ShowFeaturedItemsState = {
  show: true
}


export function showFeaturedItemsReducer( state = initialState, action: ShowFeaturedItemsTypeActions) {
  switch (action.type) {
    case SHOW_FEATURED_ITEMS:
    return {
        ...state,
        show: action.payload
        }
    default:
      return state
  }
}
