import React, { lazy, Suspense } from 'react';

const LazyDrafts = lazy(() => import('./taxonomyManagment'));

const Drafts = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyDrafts {...props} />
  </Suspense>
);

export default Drafts;
