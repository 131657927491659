import * as React from "react";
import { Link } from "react-router-dom";
import { IPeopleResponse } from "../../ConversationConstants";
import LocationIcon from "../../../../../../../assets/prompt/media/conversation/location.svg";
import NoUserProfile from "../../../../../../../assets/prompt/media/conversation/noUserProfile.svg";
import BadgeIcon from "../../../../../../../assets/prompt/media/conversation/badge.svg";
import "./PeopleResponse.scss";
import { TooltipBase } from "../../../../../../shared/tooltipBase/tooltipBase";
import { SharedService } from "../../../../../../../services/SharedService";
import { Constants } from "../../../../../../../helpers/Constants";

/**
 * Interface for PeopleResponse component props
 */
interface IPeopleResponseProps {
  conversation: IPeopleResponse[];
  specialistProfileContainerRef?: React.RefObject<HTMLDivElement> | null;
}

/**
 * PeopleResponse Component
 *
 * This component represents a response containing information about people.
 * It displays details such as name, image, location, designation, and description for each person.
 */

const PeopleResponse: React.FunctionComponent<IPeopleResponseProps> = ({
  conversation,
  specialistProfileContainerRef,
}) => {
  const _sharedService = SharedService.GetInstance();

  /**
   * This function Formats array of badges into a JSX element.
   * @param {Array<string>} badges - Array of badge strings.
   * @returns {JSX.Element} - Formatted JSX element containing badge divs.
   */
  const formatBadgeContent = (badges: Array<string>) => {
    return (
      <div>
        {badges.map((badge) => (
          <div>{badge}</div>
        ))}
      </div>
    );
  };

  /**
   * Render the PeopleResponse component.
   *
   * @returns {JSX.Element} - The rendered PeopleResponse component.
   */
  return (
    <div className="peoples_container" ref={specialistProfileContainerRef}>
      <span className="specialist_text">
        {
          _sharedService.Data.Labels[
            Constants.LabelKeys.KXPrompt_FieldSpecialistsLabel
          ]
        }
      </span>
      {conversation?.map((people: IPeopleResponse) => (
        <div className="people_card">
          <div className="people_image">
            <img src={people.imageLink || NoUserProfile} alt={people.name} />
          </div>
          <div className="people_info">
            <div className="name_location flex-centered">
              <div className="flex-centered">
                <Link
                  to={{ pathname: people.appUrl }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="name">{people.name}</span>
                </Link>
                {people.badges.length > 0 && (
                  <TooltipBase
                    content={formatBadgeContent(people.badges)}
                    directionalHint={5}
                    wrapperClassName="prompt_tooltip"
                  >
                    <img src={BadgeIcon} alt="badge" />
                  </TooltipBase>
                )}
              </div>
              <div className="location flex-centered">
                {people.country && <img src={LocationIcon} alt="location" />}
                <span className="location_text">
                  {people.country ? `${people.country} - ${people.city}` : ""}
                </span>
              </div>
            </div>
            <div className="designation">
              <span className="designation_text">{people.designation}</span>
            </div>
            <div className="about">
              <span className="about_text">{people.description}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

/**
 * Export the PeopleResponse component as default
 */
export default PeopleResponse;
