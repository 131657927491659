import * as React from "react";
import RightActive from "../../../../../assets/prompt/media/conversation/right-arrow-active.svg";
import RightInactive from "../../../../../assets/prompt/media/conversation/right-arrow-disabled.svg";
import LeftActive from "../../../../../assets/prompt/media/conversation/left-arrow-active.svg";
import LeftInactive from "../../../../../assets/prompt/media/conversation/left-arrow-disabled.svg";
import "./FollowUpQuestions.scss";
import { Constants } from "../../../../../helpers/Constants";
import { SharedService } from "../../../../../services/SharedService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/reducers";
import { TooltipBase } from "../../../../shared/tooltipBase/tooltipBase";
import Question from "./Question";

/**
 * Interface for FollowUpQuestions component props
 */
interface IFollowUpQuestionsProps {
  followUpQuestions: string[];
  handleFollowUpQuestion: (question: string) => void;
}

/**
 * FollowUpQuestions Component
 *
 * This component displays a list of follow-up questions.
 *
 * @param {IFollowUpQuestionsProps} followUpQuestions - The list of follow-up questions to display.
 * @param {function} handleFollowUpQuestion - Callback function to handle the selection of a follow-up question.
 */

const FollowUpQuestions: React.FunctionComponent<IFollowUpQuestionsProps> = ({
  followUpQuestions,
  handleFollowUpQuestion,
}) => {
  const _sharedService = SharedService.GetInstance();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { loadingResponse } = useSelector(
    (state: RootState) => state.promptResponseReducer
  );

  /**
   * This function handles Arrow button click to switch between Follow up questions
   * IT takes a parameter 'direction' with type "left" or "right"
   */
  const handleArrowClick = (direction: "left" | "right") => {
    let newIndex = direction === "left" ? currentIndex - 2 : currentIndex + 2;
    newIndex = Math.max(0, Math.min(newIndex, followUpQuestions.length - 2));
    setCurrentIndex(newIndex);
  };

  const isLeftArrowDisabled = currentIndex === 0;
  const isRightArrowDisabled = currentIndex >= followUpQuestions.length - 2;

  /**
   * Render the FollowUpQuestions component.
   *
   * @returns {JSX.Element} - The rendered FollowUpQuestions component.
   */
  return (
    <div className="followUpQuestion_container flex-centered">
      {isLeftArrowDisabled ? (
        <div className={`arrow disabled left`}>
          <img className="inactive" src={LeftInactive} />
        </div>
      ) : (
        <TooltipBase
          content={
            _sharedService.Data.Labels[
              Constants.LabelKeys.KXPromot_FollowQuestionArrowToolTip
            ]
          }
          wrapperClassName="prompt_tooltip"
        >
          <div className="arrow left" onClick={() => handleArrowClick("left")}>
            <img src={LeftActive} />
          </div>
        </TooltipBase>
      )}
      {followUpQuestions
        .slice(currentIndex, currentIndex + 2)
        .map((question, idx) => {
          return (
            <Question
              key={idx}
              text={question}
              isClickable={!loadingResponse}
              onTextClick={() =>
                !loadingResponse && handleFollowUpQuestion(question)
              }
            />
          );
        })}
      {isRightArrowDisabled ? (
        <div className={`arrow disabled`}>
          <img className="inactive" src={RightInactive} />
        </div>
      ) : (
        <TooltipBase
          content={
            _sharedService.Data.Labels[
              Constants.LabelKeys.KXPromot_FollowQuestionArrowToolTip
            ]
          }
          wrapperClassName="prompt_tooltip"
        >
          <div className="arrow" onClick={() => handleArrowClick("right")}>
            <img src={RightActive} />
          </div>
        </TooltipBase>
      )}
    </div>
  );
};

export default FollowUpQuestions;
