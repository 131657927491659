import { SET_SHOW_RECENT } from '../types';

const setShowRecent = (showRecent: boolean, isHomePage?: boolean) => {
  return {
    type: SET_SHOW_RECENT,
    payload:  {
      showRecent: showRecent,
      isHomePage: isHomePage
    }

  }
}

export default setShowRecent