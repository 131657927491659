import React, {useState} from 'react';
import { Link, StackItem } from 'office-ui-fabric-react';
import {HeaderDropdown} from '../headerDropdown/headerDropdown';
import OutsideClickHandler from 'react-outside-click-handler';

import './headerNav.scss';
import { CommonFunctions } from '../../../helpers/CommonFunctions';
import { useHistory } from 'react-router-dom';
import setRefiners from "../../../store/actions/refinersActions";
import setRefinersOld from '../../../store/actions/refinersOldActions';
import { useDispatch, useSelector } from 'react-redux';
import { ISearchState } from '../../../models/IShared';
import setSearchState from '../../../store/actions/searchActions';
import { Link as RouteLink } from "react-router-dom";

export interface HeaderNavProps {
    handle?: () => void;
    navMainLinks:any;
}

export const HeaderNav: React.FunctionComponent<HeaderNavProps> = props => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(-1);
    let history = useHistory();
    const _searchState: ISearchState = useSelector((s: any) => s.searchReducer);

    const getElementIndex = (element: any) => {
        const nodes = [...element.parentNode.childNodes];
        return nodes.indexOf(element);
    }

    const handleClick = (event:any) => {
        if (event) {
            event.preventDefault();
        }

        const curParentNode = event.target.parentNode;
        const parent = curParentNode.tagName === 'LI' ? curParentNode : curParentNode.parentNode;
        const newValue = getElementIndex(parent) === value ? -1 : getElementIndex(parent);

        setValue(newValue);
    }

    const onOutsideClick = () => {setValue(-1)};

    const onLinkClick = (linkUrl:string) => {   
        if(window.location.pathname != linkUrl) {
            dispatch(setRefiners([]));
            dispatch(setRefinersOld([]));
        }

        CommonFunctions.redirectLink(CommonFunctions.ifExternalUrl(linkUrl),linkUrl,history);
        if(props.handle){
            props.handle();
        }

        dispatch(setSearchState(true, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, !_searchState.navigation));

        onOutsideClick();
    };

    return (
        <StackItem>
            <OutsideClickHandler onOutsideClick={onOutsideClick}>
                <ul className="header-nav">
                    {
                        props.navMainLinks.length>0?props.navMainLinks.map((navMainLink:any, i:number) => {
                            return (
                                <li key={navMainLink.linkName + Date.now()} className={value === i ? 'selected' : ''}>
                                { 
                                    navMainLink.drop ?
                                    <Link onClick={navMainLink.drop ? handleClick : undefined} href={navMainLink.linkUrl}>{navMainLink.linkName} {navMainLink.drop ? <i className="icon-kx-chevron-down"></i> : null}</Link>
                                    : <RouteLink digitaldata-topnavmenu={`${navMainLink.linkName}`} to={navMainLink.linkUrl} onClick={()=>onLinkClick(navMainLink.linkUrl)} > {navMainLink.linkName} </RouteLink>
                                }
                                {
                                    navMainLink.drop ? 
                                        <HeaderDropdown navLinkName={navMainLink.linkName} dropdownLinks={navMainLink.dropLinks} handler={onLinkClick} />
                                    : null
                                }
                                </li>
                            );
                        }):null
                    }
                </ul>
            </OutsideClickHandler>
        </StackItem>
    );
}