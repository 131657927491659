import { DATE_CREARED, SetDateCleared } from '../types'

const initialState: any = {
  isCleared: false
}

export default function dateClearedReducer(state = initialState, action: SetDateCleared): any {
  switch (action.type) {
    case DATE_CREARED:
      return {
        ...state,
        isCleared: action.payload
      }
    default:
      return state
  }
}