import { TextField } from "@fluentui/react/lib/TextField";
import React, { useState, useEffect, KeyboardEvent } from "react";
import "./HomeSearchPage.scss";
import Sparkle from "../../../../../assets/prompt/media/conversation/sparkle.svg";
import SendDisableIcon from "../../../../../assets/prompt/media/conversation/sendDisable.svg";
import SendActiveIcon from "../../../../../assets/prompt/media/conversation/sendActive.svg";
import SendBtnAnim from "../../../../../assets/prompt/media/conversation/KX_SendButtonAnimation_HiRes.gif"; // Import the GIF
import Conversation from "../Conversation/Conversation";
import { SharedService } from "../../../../../services/SharedService";
import {
  IConversationMessage,
  IPromptRequestObj,
  feedbackStatus,
  feedbacktype,
} from "../../../../../models/KxPromptModels";
import {
  getPromptResponse,
  clearPromptResponse,
  setModifiedResponseSelected,
  setPromptResponse,
  setDPNImageLoading,
  resetPromptResponseError,
  resetInfoMessage,
  setContainSpecialist,
} from "../../../../../store/actions/promptResponseAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/reducers";
import { MessageSender } from "../../../../../store/types";
import {
  addMessage,
  clearFollowupPrompt,
  clearMessage,
  getAllConversationHistory,
  loadConversationFromHistory,
  resetSidebarHistoryItemActive,
  setFollowupPrompt,
} from "../../../../../store/actions/promptConversationAction";
import FollowUpQuestions from "../FollowUpQuestions/FollowUpQuestions";
import { Constants } from "../../../../../helpers/Constants";
import TopicModal from "../TopicModal/TopicModal";
import NewTopicIcon from "../../../../../assets/prompt/newtopicicon.svg";
import { IPromptChatInteractionTracker } from "../../../../../models/IAnalytics";
import { AnalyticsService } from "../../../../../services/AnalyticsService";
import { TooltipBase } from "../../../../shared/tooltipBase/tooltipBase";
import { useResponseAnalyticsTracker } from "../../promptContextAPIs/ResponseAnalyticTrackerContext";
import { IPeopleResponse } from "../Conversation/ConversationConstants";
import { useDisableResultsOnModifySelected } from "../../promptContextAPIs/DisableResultsOnModifySelected";
import { toast } from "react-toastify";

/**
 * HomeSearchPage Component
 *
 * This component represents the center pane where users primarily interact with Gen AI.
 */
const HomeSearchPage = () => {
  const dispatch = useDispatch();
  const [showWelcome, setShowWelcome] = useState(true);
  const [text, setText] = useState("");
  const [isNewClicked, setIsNewClicked] = useState(false);
  const [scrollOnModifyClick, setScrollOnModifyClick] = useState(false);
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(false);
  const [isSending, setIsSending] = useState(false); // State to manage GIF animation
  const conversationRef = React.useRef<HTMLDivElement>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { responseTriggered, setResponseTriggered } =
    useResponseAnalyticsTracker();

  const { setDisableResultsOnModifySelected } =
    useDisableResultsOnModifySelected();
  const _sharedService = SharedService.GetInstance();
  const {
    promptResponse,
    loadingResponse,
    error,
    promptResponseOnModifySelected,
    infoMessages,
  } = useSelector((state: RootState) => state.promptResponseReducer);
  const {
    messages,
    kxResultsFromHistory,
    kxResultsFromHistoryLoading,
    conversationHistoryLoading,
    followUpPrompts,
    conversationHistory,
  } = useSelector((state: RootState) => state.promptConversationReducer);
  const ChatIntialSuggestedPrompts: string[] = JSON.parse(
    _sharedService.Data.Labels[
      Constants.LabelKeys.KXPrompt_ChatIntialSuggestedPrompts
    ]
  );

  /**
   * Handles the change event for input elements (text input or textarea).
   * @param e - The React form event triggered by the input change.
   * @param newValue - Optional parameter representing the new value for the input element.
   *                  If not provided, the default value is an empty string.
   */
  const inputChangeHandler = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    const value = newValue || ""; // Ensure max 500 chars
    setText(value);
  };

  /**
   * getSessionId - Retrieves the session ID from the latest message or prompt response.
   *
   * @returns {string} - The session ID, or an empty string if not found.
   */
  const getSessionId = () => {
    if (messages.length >= 2) {
      return messages[messages.length - 1].sessionId;
    } else if (promptResponse?.sessionid) return promptResponse?.sessionid;
    else return "";
  };

  useEffect(() => {
    if (error) {
      toast.dismiss(); // added individually because if we add dismiss and clear queue at the top. Then it wont render the toasts we want to display
      toast.clearWaitingQueue(); // added individually because if we add dismiss and clear queue at the top. Then it wont render the toasts we want to display
      toast.error(error);
      dispatch(resetPromptResponseError());
    } else if (promptResponse?.contain_specialist) {
      return;
    } else if (infoMessages.length > 0) {
      toast.dismiss(); // added individually because if we add dismiss and clear queue at the top. Then it wont render the toasts we want to display
      toast.clearWaitingQueue(); // added individually because if we add dismiss and clear queue at the top. Then it wont render the toasts we want to display
      //Handling multiple info messages to be shown as separate toast message
      const messageList = infoMessages;
      messageList.forEach((message, index) => {
        const delayBaseDuration = message.length > 120 ? 8000 : 5000;
        toast.success(message, {
          autoClose: delayBaseDuration,
        });
      });
      dispatch(resetInfoMessage());
    }
  }, [error, infoMessages]);

  useEffect(() => {
    if (isNewClicked) {
      sendButtonClickHandler();
      setIsNewClicked(false);
    }
  }, [isNewClicked]);

  /**
   * Sets people profile responses based on the given conversation message.
   * This function processes the prompt response, retrieves user data, and fetches profile images.
   *
   * @param message - The conversation message containing the prompt response.
   * @returns The updated conversation message with people profile data.
   */
  const setPeopleProfileResponse = async (message: IConversationMessage) => {
    if (promptResponse) {
      dispatch(setDPNImageLoading(true));
      const usersData: IPeopleResponse[] = promptResponse?.kxresults
        ?.slice(0, 5)
        .map((result) => ({
          id: String(result?.citation_no),
          imageLink: null,
          name: result?.title || "",
          designation: result?.pdifunctionsglobaltext || result?.kxp_role || "",
          country: result?.pdicountry || "",
          city: result?.pdicity || "",
          badges: result?.badges?.map((badge) => badge.name) || [],
          description: result?.description || "",
          accountName: result?.accountname || "",
          appUrl: result?.appurl || "",
        }));

      const usersAccountNameList = usersData.map((user) => user.accountName);

      const responses = await Promise.all(
        usersAccountNameList.map(
          async (user) => await _sharedService.getDPNImgData(user)
        )
      );

      const finalResponses = responses.map((response) => {
        if (response instanceof Error || response?.error) {
          return null;
        } else {
          return response?.stringPhoto;
        }
      });

      const finalUsersData: IPeopleResponse[] = usersData?.map(
        (userData, idx) => ({
          ...userData,
          imageLink: finalResponses[idx] || null,
        })
      );
      message.text = finalUsersData;

      dispatch(setDPNImageLoading(false));
      return message as IConversationMessage;
    }
  };

  useEffect(() => {
    const setPromptResponseFn = async () => {
      if (promptResponse) {
        let updateMessage: IConversationMessage = {
          text: promptResponse?.summary || "",
          sender: MessageSender.SERVER,
          usageGuidelines:
            promptResponse?.usageguidelines_citations_links &&
            promptResponse?.usageguidelines_citations_links?.length !== 0
              ? promptResponse?.usageguidelines_citations_links.join()
              : "",
          sessionId: promptResponse?.sessionid || "",
          conversationId: promptResponse?.conversation_id || "",
          contain_specialist: promptResponse?.contain_specialist,
          feedbackStatus: -1,
          feedbacktype: "",
          clarification_question:
            promptResponse?.clarification_question || false,
        };
        if (promptResponse?.contain_specialist) {
          dispatch(getAllConversationHistory());
          const message = await setPeopleProfileResponse(updateMessage);
          if (message) {
            updateMessage = message;
          }
        }
        if (
          typeof updateMessage.text === "string" &&
          updateMessage.text.length === 0
        ) {
        } else {
          dispatch(addMessage(updateMessage));
          promptResponse?.followup_questions
            ? dispatch(setFollowupPrompt(promptResponse.followup_questions))
            : dispatch(setFollowupPrompt([]));
        }
        if (promptResponseOnModifySelected) {
          dispatch(setModifiedResponseSelected(false));
          dispatch(setPromptResponse(undefined));
        }
        // Stop the sending animation
        setIsSending(false);
      }
    };
    setPromptResponseFn();
  }, [promptResponse]);

  useEffect(() => {
    if (responseTriggered && promptResponse) {
      const getTitleFromHistoryObject = conversationHistory.find(
        (conversationObj) =>
          conversationObj.sessionid === promptResponse?.sessionid
      )?.title;

      if (getTitleFromHistoryObject) {
        const getCitationsData = promptResponse?.kxresults
          .filter((kxResult) => kxResult.ischecked)
          .map(
            (checkKxResult) => `${checkKxResult.source} ${checkKxResult.appid}`
          );

        const formattedCitationData = getCitationsData?.join(" | ");

        const chatInteractionTracker: IPromptChatInteractionTracker = {
          conversationID: promptResponse?.sessionid || getSessionId(),
          topicName: getTitleFromHistoryObject,
          citations: formattedCitationData || "",
        };
        AnalyticsService.trackPromptChatInteraction(chatInteractionTracker);
        setResponseTriggered(false);
      }
    }
  }, [promptResponse, conversationHistory]);

  useEffect(() => {
    const loadMessageForConversation = async () => {
      if (!kxResultsFromHistoryLoading && kxResultsFromHistory.length > 0) {
        const messageFromHistory: IConversationMessage[] = [];
        kxResultsFromHistory?.forEach(async (kxResult, idx) => {
          const userConversationObj: IConversationMessage = {
            text: kxResult?.userprompt,
            sender: MessageSender.USER,
            usageGuidelines:
              kxResult?.usageguidelines_citations_links &&
              kxResult?.usageguidelines_citations_links?.length !== 0
                ? kxResult?.usageguidelines_citations_links.join()
                : "",
            sessionId: kxResult?.session_id,
            conversationId: kxResult?.id,
          };

          let serverConversationObj: IConversationMessage = {
            text: kxResult?.botresponse,
            sender: MessageSender.SERVER,
            usageGuidelines:
              kxResult?.usageguidelines_citations_links &&
              kxResult?.usageguidelines_citations_links?.length !== 0
                ? kxResult?.usageguidelines_citations_links.join()
                : "",
            sessionId: kxResult?.session_id,
            conversationId: kxResult?.id,
            feedbackStatus: kxResult?.feedbackStatus as feedbackStatus,
            feedbacktype: kxResult?.feedbacktype as feedbacktype,
            clarification_question: kxResult?.clarification_question || false,
          };
          if (
            idx === kxResultsFromHistory?.length - 1 &&
            kxResult?.contain_specialist &&
            promptResponse?.kxresults &&
            promptResponse.kxresults.length > 0
          ) {
            //Setting contain specialists coming from history
            dispatch(setContainSpecialist(kxResult.contain_specialist));
            const updatedConversationObj = await setPeopleProfileResponse(
              serverConversationObj
            );
            if (updatedConversationObj) {
              serverConversationObj = updatedConversationObj;
            }
          }

          messageFromHistory.push(userConversationObj);
          messageFromHistory.push(serverConversationObj);

          idx === kxResultsFromHistory?.length - 1 &&
            dispatch(loadConversationFromHistory(messageFromHistory));
        });
      }
    };
    loadMessageForConversation();
  }, [kxResultsFromHistory]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (conversationRef.current) {
        conversationRef.current.scrollTop =
          conversationRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [messages, scrollOnModifyClick]);

  useEffect(() => {
    if (messages.length === 0) {
      setShowWelcome(true);
      setText("");
    } else {
      setIsNewButtonVisible(true);
    }
  }, [messages]);

  useEffect(() => {
    //Setting welcome visibility to false when conversation is being fetched from history
    //Or prompt response data is being fetched
    if (kxResultsFromHistoryLoading || loadingResponse) {
      setShowWelcome(false);
    }
  }, [kxResultsFromHistoryLoading, loadingResponse]);

  /**
   * Handles the click event when the send button is clicked.
   * Triggers the sending of the user's prompt and updates the UI accordingly.
   *
   * @param {string} prompt - The user's input prompt to be sent.
   */
  const sendButtonClickHandler = (prompt = text) => {
    if (prompt.trim().length < 2 || prompt.length > 500) {
      return;
    }
    setText("");
    dispatch(clearFollowupPrompt());
    const updateMessage: IConversationMessage = {
      text: prompt,
      sender: MessageSender.USER,
      usageGuidelines: "",
      sessionId: getSessionId(),
      conversationId: "",
    };
    const payloadData: IPromptRequestObj = {
      userprompt: prompt,
      sessionid: getSessionId(),
      overridesettings: {
        top: 5,
        search_mode: "hybrid",
      },
    };
    dispatch(addMessage(updateMessage));
    setResponseTriggered(true);
    dispatch(getPromptResponse(payloadData));

    // Start the sending animation
    setIsSending(true);
  };

  /**
   * Clears the click message and displays a success toast if the associated conversation history has a title.
   */
  const clearButtonClickMessage = () => {
    toast.dismiss();
    toast.clearWaitingQueue();
    const conversationHistoryObj = conversationHistory.find(
      (history) =>
        history.sessionid === messages[messages.length - 1]?.sessionId
    );

    conversationHistoryObj?.title &&
      toast.success(
        `${conversationHistoryObj?.title} ${
          _sharedService.Data.Labels[
            Constants.LabelKeys.KXPrompt_CurrentChatHistorySaved
          ]
        }`,
        {
          autoClose: 8000,
        }
      );
  };

  /**
   * Handler for the click event on the "New Topic" button.
   * Performs various actions to reset and clear state, preparing the interface for a new conversation topic.
   */
  const newTopicButtonClickHandler = () => {
    clearButtonClickMessage();
    dispatch(resetSidebarHistoryItemActive());
    dispatch(clearPromptResponse());
    dispatch(getAllConversationHistory());
    dispatch(clearMessage());
    setDisableResultsOnModifySelected(false); // Flag to disable the regenerate response and results checkbox if modify response is selected
    setIsNewClicked(true);
  };

  /**
   * Function  open warning popup that appears on the click of "New Button".
   */
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  /**
   * Function to handle the primary button click associated with closing the warning dialog.
   */
  const handlePrimaryButtonClick = () => {
    setDialogOpen(false);
  };

  /**
   * Function to handle the Secondary button click associated with the warning dialog
   */
  const handleSecondaryButtonClick = () => {
    setDialogOpen(false);
    newTopicButtonClickHandler();
  };

  /**
   * Handles follow-up questions by updating the text input, enabling response modification,
   * and marking the request as new.
   * @param {string} question - The follow-up question to be processed.
   * @returns
   */
  const handleFollowUpQuestion = (question: string) => {
    if (loadingResponse) {
      return;
    }
    setText(question);
    setDisableResultsOnModifySelected(false); // Flag to disable the regenerate response and results checkbox if modify response is selected
    setIsNewClicked(true);
  };

  /**
   * Handles the modification of a response based on the selected modifyOption.
   * @param modifyOption - The string representing the modification option.
   */
  const handleModifyResponse = (modifyOption: string) => {
    dispatch(setModifiedResponseSelected(true));
    promptResponse && dispatch(setPromptResponse(promptResponse));
    setText(modifyOption);
    setDisableResultsOnModifySelected(true); // Flag to disable the regenerate response and results checkbox if modify response is selected
    sendButtonClickHandler(modifyOption);
  };

  /**
   * Handles keyboard events for input elements, specifically the 'Enter' key.
   * @param {KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>} event - The keyboard event object.
   */
  const keyDownHandler = (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") {
      setDisableResultsOnModifySelected(false); // Flag to disable the regenerate response and results checkbox if modify response is selected
      sendButtonClickHandler();
    }
  };

  /**
   * Render the HomeSearchPage component.
   *
   * @returns {JSX.Element} - The rendered HomeSearchPage component.
   */
  return (
    <div className="homepage-container">
      <div className="parent-container">
        {showWelcome && (
          <div className="welcome-container">
            <img
              className="label-icon"
              src={Sparkle}
              alt="try ai search label"
            />
            <h1 className="header-label1">
              {
                _sharedService.Data.Labels[
                  Constants.LabelKeys.KXPrompt_WelcomeText1
                ]
              }
            </h1>

            <span className="welcome-message">
              {
                _sharedService.Data.Labels[
                  Constants.LabelKeys.KXPrompt_WelcomeText3
                ]
              }
            </span>
            <div className="initial-prompts-container">
              <span className="heading">
                {
                  _sharedService.Data.Labels[
                    Constants.LabelKeys.KXPrompt_InitialPromptHeading
                  ]
                }
              </span>
              <div className="initial-prompts">
                {ChatIntialSuggestedPrompts.map((prompt) => (
                  <div className="prompt" key={prompt}>
                    <div className="side-border" />
                    <span>{prompt}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {!showWelcome && (
          <div
            className="main-content-container"
            ref={conversationRef}
            style={{
              height:
                followUpPrompts?.length > 0
                  ? "calc(100% - 160px)"
                  : "calc(100% - 100px)",
            }}
          >
            <Conversation
              isLoading={loadingResponse}
              messages={messages}
              handleModifyResponse={handleModifyResponse}
              setScrollOnModifyClick={setScrollOnModifyClick}
            />
          </div>
        )}
        <div className="request-input-container">
          {!showWelcome &&
            !kxResultsFromHistoryLoading &&
            followUpPrompts?.length > 0 && (
              <FollowUpQuestions
                followUpQuestions={followUpPrompts}
                handleFollowUpQuestion={handleFollowUpQuestion}
              />
            )}
          <div className="input_wrapper flex-centered">
            <div className="input">
              <TextField
                borderless
                disabled={loadingResponse}
                value={text}
                onChange={inputChangeHandler}
                className="search-input"
                inputClassName="input-class"
                placeholder={
                  messages?.length === 0
                    ? _sharedService.Data.Labels[
                        Constants.LabelKeys.KXPrompt_InitialInputPlaceholder
                      ]
                    : _sharedService.Data.Labels[
                        Constants.LabelKeys.KXPrompt_ModifyInputPlaceholder
                      ]
                }
                onKeyDown={keyDownHandler}
              />
              <div
                className={`${
                  text.length >= 2 &&
                  text.trim().length >= 2 &&
                  text.length <= 500
                    ? "active"
                    : "disabled"
                } send-button flex-centered`}
                onClick={() => {
                  setDisableResultsOnModifySelected(false); // Flag to disable the regenerate response and results checkbox if modify response is selected
                  sendButtonClickHandler();
                }}
              >
                {/* Conditionally render the regular send button or the GIF animation */}
                {!loadingResponse && (
                  <TooltipBase
                    content={
                      _sharedService.Data.Labels[
                        Constants.LabelKeys.KXPrompt_CharacterLimitLabel
                      ]
                    }
                    wrapperClassName="prompt_tooltip"
                    isHidden={text.length <= 500}
                  >
                    <img
                      src={
                        text.length >= 2 &&
                        text.trim().length >= 2 &&
                        text.length <= 500
                          ? SendActiveIcon
                          : SendDisableIcon
                      }
                      alt="send"
                    />
                  </TooltipBase>
                )}
                {loadingResponse && (
                  <div className="sending-gif-div">
                    <img
                      src={SendBtnAnim} // Use the GIF animation source here
                      alt="sending"
                      className="sending-gif"
                    />
                  </div>
                )}
              </div>
            </div>
            {messages.length > 0 && (
              <div>
                <div
                  className={`new_button flex-centered ${
                    isNewButtonVisible ? "active" : ""
                  }`}
                  onClick={() =>
                    loadingResponse || conversationHistoryLoading
                      ? undefined
                      : handleOpenDialog()
                  }
                  digitaldata-prompt="Body>New topic"
                  style={{
                    cursor:
                      loadingResponse || conversationHistoryLoading
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  <TooltipBase
                    content={
                      _sharedService.Data.Labels[
                        Constants.LabelKeys.KXPrompt_NewPopupInfo
                      ]
                    }
                    wrapperClassName="prompt_tooltip"
                  >
                    {
                      _sharedService.Data.Labels[
                        Constants.LabelKeys.KXPrompt_NewLabel
                      ]
                    }
                  </TooltipBase>
                </div>
                <TopicModal
                  isOpen={isDialogOpen}
                  title={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_NewTopic
                    ]
                  }
                  subText={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_NewTopicSubtext
                    ]
                  }
                  icon={NewTopicIcon}
                  primaryButtonText={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_NoButton
                    ]
                  }
                  secondaryButtonText={
                    _sharedService.Data.Labels[
                      Constants.LabelKeys.KXPrompt_SaveAndProceedButton
                    ]
                  }
                  onPrimaryButtonClick={handlePrimaryButtonClick}
                  onSecondaryButtonClick={handleSecondaryButtonClick}
                />
              </div>
            )}
          </div>
        </div>
        <div className="max-char-container">
          {
            _sharedService.Data.Labels[
              Constants.LabelKeys.KXPrompt_CharacterLimitLabel
            ]
          }
        </div>
      </div>
    </div>
  );
};

/**
 * Export the HomeSearchPage component as default
 */
export default HomeSearchPage;
