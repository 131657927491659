import { REFINERSOLD } from "../types";
import { IRefiner } from "../../models/IShared";

const setRefinersOld = (refiners: IRefiner[]) => {
  return {
    type: REFINERSOLD,
    payload: refiners
  }
}

export default setRefinersOld;