import React, { useState, useRef } from "react";
import { CardData } from "../../../../../models/SharedModels";
import { Constants } from "../../../../../helpers/Constants";
import { PeopleCardContent } from "../../../../shared/cardPeople/cardPeople";
import { ImageCardContent } from "../../../../shared/cardImage/cardImage";
import { Card } from "@uifabric/react-cards";
import { Link, TooltipBase } from "office-ui-fabric-react";
import { CommonFunctions } from "../../../../../helpers/CommonFunctions";
import { ButtonsResizeGroup } from "../../../../shared/buttonsResizeGroup/buttonsResizeGroup";
import { SharedService } from "../../../../../services/SharedService";
import { CardType } from "../../../../../models/IShared";
import LinkGenerator from "../../../../shared/linkGenerator/linkGenerator";
import { IMiniCardTracker } from "../../../../../models/IAnalytics";
import {
  ADD_DATA_TO_COLLECTION,
  ADD_DATA_TO_DPN,
} from "../../../../../store/types";
import { DPNActions } from "../../../../../store/actions/DPNActions";
import { CollectionYouFollowActions } from "../../../../../store/actions/CollectionYouFollowActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AnalyticsService } from "../../../../../services/AnalyticsService";
import starIcon from "../../../../../assets/images/starGreen.svg";
import { OverflowSetBase } from "../../../../shared/overflowSetBase/overflowSetBase";
import QuickViewModal from "./QuickViewModal";
import { PeopleModal } from "./PeopleModal";
import { ImageModal } from "./ImageModal";

/**
 * Interface for QuickView component props
 */
interface IQuickViewProps {
  collection: any[];
  isLoading: boolean;
  dataForShimmer: any;
  featured: boolean;
  slider?: boolean;
  isHorizontal?: boolean;
  modernUi?: boolean;
  archiveRemoval?: (cardData: CardData) => void;
  categoryMove?: (e: any, cardData: CardData) => void;
  chosenItems?: any[];
  openModalEdit?: any;
  explainData?: any;
  setShowSubscribe?: any;
  setActionsFlag?: any;
}

/**
 * QuickView Component
 *
 * This component represents the Quick View popup incorporating all the other sections
 * based upon diff. sources within results section in 'Prompt by KX'.
 */
const QuickView: React.FunctionComponent<IQuickViewProps> = (props) => {
  const [cardsState, setCardsState] = React.useState(props.collection);
  React.useEffect(() => {
    // localStorage.setItem("test", JSON.stringify(props.collection));
    setCardsState(props.collection);
  }, [props.collection]);

  return (
    <div>
      {!props.isLoading &&
        cardsState.map((card: CardData, i: number) => {
          if (card.ctName == Constants.DPN)
            return (
              <PeopleModal
                card={card}
                key={i}
                categoryMove={props.categoryMove}
                chosenItems={props.chosenItems}
                openModalEdit={props.openModalEdit}
              />
            );
          else if (
            (card.ctName == Constants.Images || card.allData.img_Id) &&
            !card.IsRemoved
          )
            return (
              <ImageModal
                card={card}
                key={i}
                categoryMove={props.categoryMove}
                chosenItems={props.chosenItems}
                openModalEdit={props.openModalEdit}
                explainData={
                  props.explainData && i < props.explainData.length
                    ? props.explainData[i]
                    : {}
                }
              />
            );
          return (
            <CardBaseContent
              card={card}
              key={i}
              archiveRemoval={props.archiveRemoval}
              categoryMove={props.categoryMove}
              chosenItems={props.chosenItems}
              openModalEdit={props.openModalEdit}
              explainData={
                props.explainData && i < props.explainData.length
                  ? props.explainData[i]
                  : {}
              }
              setShowSubscribe={props.setShowSubscribe}
              modernUi={props.modernUi}
            />
          );
        })}
    </div>
  );
};

export default QuickView;

/**
 * Interface for CardBaseContent component props
 */
interface CardBaseContentProps {
  card: CardData;
  archiveRemoval?: (cardData: CardData) => void;
  categoryMove?: (e: any, cardData: CardData) => void;
  chosenItems?: any[];
  openModalEdit?: any;
  explainData?: any;
  setShowSubscribe?: any;
  modernUi?: boolean;
}

/**
 * CardBaseContent Component
 *
 * This component represents the card based detail section for Quick View popup 
 * within results section in 'Prompt by KX'.
 */
export const CardBaseContent: React.FunctionComponent<CardBaseContentProps> = (
  props
) => {
  const [metadataListView, setMetadataListView] = useState<any>(null);
  const target = useRef(null);
  const dispatch = useDispatch();
  const [isMenuOpened, setIsOpened] = useState(false);
  const collectionListRef = useSelector(
    (state: any) => state.collectionReducer.cards
  );
  const DPNListRef = useSelector((state: any) => state.dpnReducer.cards);
  var ctMapping: any;

  // useEffect(() => {
  //   let _configurations = SharedService.GetInstance().Data.Configuration;

  //   var metadataListView;
  //   let ctCorelationJson = SharedService.GetInstance().GetConfigurationMapping(
  //     props.card.allData
  //   );
  //   let configCT = JSON.parse(_configurations.ContentTypesMapping);

  //   ctMapping = configCT[ctCorelationJson.JsonMapping];
  //   if (ctMapping) {
  //     metadataListView = ctMapping.metadataListView;
  //   }
  //   setIfPin(props.card.ifPinCard)
  //   setMetadataListView(metadataListView);
  // }, []);

  React.useEffect(() => {
    let _configurations = SharedService.GetInstance().Data.Configuration;

    var metadataListView;
    let ctCorelationJson = SharedService.GetInstance().GetConfigurationMapping(
      props.card.allData
    );
    let configCT = JSON.parse(_configurations.ContentTypesMapping);

    ctMapping = configCT[ctCorelationJson.JsonMapping];
    if (ctMapping) {
      metadataListView = ctMapping.metadataListView;
    }
    setMetadataListView(metadataListView);

    ctMapping = configCT[ctCorelationJson.JsonMapping];
    if (props.card.overflowItemsData) {
      //insert the on click method
      var index =
        props.card.overflowItemsData[0] &&
        props.card.overflowItemsData[0].overflowItems.find(
          (item: any) => item.key == Constants.ActionsButtonsKeys.Follow
        );
      if (index)
        index.onClick = (event: any, original: any) => {
          if (props.card.allData.internalcontenttype == "Curated Collection") {
            var newCollection = CommonFunctions.saveCollection(
              props.card.allData,
              ctMapping,
              collectionListRef
            );
            setTimeout(() => {
              dispatch(
                CollectionYouFollowActions(
                  ADD_DATA_TO_COLLECTION,
                  newCollection
                )
              );
            }, 0);
          }
          if (props.card.allData.internalcontenttype == "DPN") {
            var newDPN = CommonFunctions.saveDPN(
              props.card.allData,
              ctMapping,
              DPNListRef
            );
            setTimeout(() => {
              dispatch(DPNActions(ADD_DATA_TO_DPN, newDPN));
            }, 0);
          }
          var result = CommonFunctions.followUnFllow(
            event,
            original,
            props.card.allData.internalcontenttype,
            props.card.sourceId,
            props.card
          );

          props.setShowSubscribe(
            result.key == Constants.LabelKeys.Follow &&
              result.name == Constants.LabelKeys.Unfollow,
            props.card.sourceId
          );
        };
    }
  }, [collectionListRef]);

  /**
   * Handler to show modal
   */
  const onShow = () => {
    setIsOpened(true);
  };

  /**
   * Handler to hide modal
   */
  const onHide = () => {
    setIsOpened(false);
  };

  if (props.card.isHorizontal && metadataListView) {
    var dates = Array.isArray(metadataListView.field2[0].field)
      ? props.card.footer[0] + "-" + props.card.footer[1]
      : props.card.allData[metadataListView.field2[0].field];
    var geo =
      metadataListView.field5[0].field == "geography" ||
      metadataListView.field5[0].field == "kxq_leadprojectmemberfirm"
        ? props.card.allData[metadataListView.field5[0].field]
        : "";
    props.card.footer =
      props.card.isHorizontal && metadataListView
        ? [dates, geo]
        : props.card.footer;
  }

  return (
    <div ref={target}>
      <Card>
        <Card.Section horizontal className="card-base__footer">
          {
            <div className={`card-menu  ${isMenuOpened ? "is-opened" : ""}`}>
              <QuickViewModal
                type={props.card.ctName}
                cardData={props.card}
                items={props.card.overflowItemsData[0].items}
                overflowItems={props.card.overflowItemsData[0].overflowItems}
                onShow={onShow}
                onHide={onHide}
                openModalEdit={props.openModalEdit}
                explainData={props.explainData}
              />
            </div>
          }
        </Card.Section>
      </Card>
    </div>
  );
};
