import React, { createContext, useState, useContext } from "react";

const UserNeedContext = createContext({
  needId: -1,
  setUserNeedId: (needId: number) => {},
});
function useUserNeedContext() {
  const context = useContext(UserNeedContext);
  if (!context)
    throw new Error("useUserNeedContext must be used within UserNeedProvider!");
  return context;
}

function UserNeedProvider(props: any) {
  const [needId, setUserNeedId] = useState(-1);
  const value = { needId, setUserNeedId };
  return <UserNeedContext.Provider value={value} {...props} />;
}

export { UserNeedProvider, useUserNeedContext };
