import { ADD_DATA_TO_LAST_VIEWED, lastViewedState, LastViewedActionsTypeActions } from '../types'

const initialState: lastViewedState = {
  cards: []
}


export function lastViewedReducer( state = initialState, action: LastViewedActionsTypeActions) {
  switch (action.type) {
    case ADD_DATA_TO_LAST_VIEWED:
    return {
        ...state,
        cards: action.payload
        }
    default:
      return state
  }
}
