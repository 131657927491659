import { ADD_DATA_TO_COLLECTION, collectionState, CollectionActionsTypeActions } from '../types'
import { CardData } from '../../models/SharedModels';

const initialState: collectionState = {
  cards: []
}


export function collectionYouFollowReducer( state = initialState, action: CollectionActionsTypeActions) {
  switch (action.type) {
    case ADD_DATA_TO_COLLECTION:
    return {
        ...state,
        cards: action.payload
        }
    default:
      return state
  }
}
